import { createIcon } from '@chakra-ui/react';

export const NewAttentionIcon = createIcon({
  displayName: 'NewAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M8.35007 11.12C8.14007 10.87 7.63007 10.38 7.21007 10V12.54H6.49007V9.92C6.00007 10.69 5.38007 11.4 4.82007 11.84C4.70007 11.67 4.48007 11.43 4.32007 11.3C5.04007 10.83 5.83006 9.93 6.33006 9.05H4.54005V8.42H6.49007V7.37H4.51006V6.74H9.15005V7.37H7.21007V8.42H9.07007V9.05H7.21007V9.29C7.59007 9.57 8.56005 10.36 8.79005 10.57L8.35007 11.12ZM9.03006 5.1H4.65005V4.48H6.49007V3.47H7.21007V4.48H9.03006V5.1ZM5.64006 6.73C5.60006 6.34 5.44006 5.74 5.25006 5.29L5.83006 5.14C6.04006 5.59 6.21006 6.18 6.26006 6.56L5.64006 6.73ZM7.28006 6.57C7.46006 6.17 7.69006 5.55 7.78006 5.12L8.46007 5.28C8.27007 5.78 8.07007 6.34 7.88007 6.7L7.28006 6.57ZM13.5601 7.48H12.4401V12.5H11.7101V7.49H10.2201V7.74C10.2201 9.23 10.0301 11.23 8.63007 12.62C8.54007 12.46 8.27007 12.2 8.10007 12.1C9.37007 10.86 9.51006 9.13 9.51006 7.73V4.25L10.0501 4.42C11.0701 4.2 12.1901 3.88 12.8301 3.56L13.4301 4.11C12.5801 4.49 11.3301 4.79 10.2201 5.01V6.79H13.5601V7.49V7.48Z"
        fill="#5D6771"
      />
      <path
        d="M17.93 11.07C17.68 10.69 17.08 9.94001 16.62 9.40001C16.39 10.53 15.91 11.63 14.91 12.45C14.82 12.29 14.58 12.03 14.41 11.91C15.68 10.96 16.01 9.42001 16.09 8.06001H14.47V7.39001H16.12V5.75001H14.68V5.09001H16.12V3.54001H16.81V5.09001H18.22V5.75001H16.81V7.39001H18.4V8.06001H16.78C16.78 8.25001 16.75 8.45001 16.73 8.64001C17.15 9.06001 18.19 10.23 18.44 10.52L17.93 11.07ZM22.72 11.73C22.94 11.73 22.98 11.58 23.01 10.41C23.15 10.53 23.44 10.64 23.63 10.69C23.57 12.05 23.39 12.39 22.8 12.39H21.99C21.3 12.39 21.16 12.17 21.16 11.47V9.35001H20.27C20.09 10.86 19.58 11.95 17.88 12.55C17.81 12.39 17.61 12.12 17.46 11.99C18.98 11.5 19.41 10.58 19.57 9.35001H18.77V3.83001H23.03V9.36001H21.84V11.48C21.84 11.71 21.88 11.74 22.09 11.74H22.72V11.73ZM19.46 5.47001H22.31V4.50001H19.46V5.47001ZM19.46 7.07001H22.31V6.09001H19.46V7.07001ZM19.46 8.68001H22.31V7.68001H19.46V8.68001Z"
        fill="#5D6771"
      />
      <path
        d="M7.04002 15.48H7.75003V24.56H7.04002V21.9C6.12002 22.16 5.20004 22.42 4.46004 22.62L4.28003 21.88C4.50003 21.83 4.75003 21.77 5.02003 21.7V16.6H5.71004V21.52C6.13004 21.41 6.58002 21.29 7.04002 21.17V15.48ZM13.18 16.33C12.85 18.6 12.17 20.43 11.25 21.85C11.87 22.75 12.65 23.47 13.61 23.94C13.44 24.07 13.19 24.36 13.07 24.56C12.15 24.06 11.39 23.37 10.77 22.51C10.09 23.38 9.30003 24.06 8.44003 24.55C8.33003 24.37 8.12002 24.06 7.93002 23.93C8.81002 23.47 9.63003 22.75 10.33 21.82C9.57003 20.52 9.05002 18.92 8.68002 17.12L9.38004 16.99C9.68004 18.54 10.14 19.95 10.79 21.12C11.5 19.97 12.04 18.56 12.35 16.94H8.19003V16.21H12.56L12.69 16.17L13.18 16.33Z"
        fill="#5D6771"
      />
      <path
        d="M23.12 19.03C22.72 20.26 22.17 21.41 21.47 22.39C21.78 23.32 22.18 23.85 22.65 23.85C22.91 23.85 23.03 23.37 23.09 22.14C23.24 22.3 23.5 22.46 23.69 22.52C23.57 24.1 23.31 24.57 22.58 24.57C21.85 24.57 21.34 24.01 20.95 23.07C20.45 23.65 19.9 24.16 19.28 24.57C19.16 24.4 18.93 24.17 18.75 24.02C18.97 23.89 19.18 23.74 19.38 23.58H17.58V24.58H16.92V23.58H14.78V23H16.92V22.46H15.17V20.03H16.91V19.49H14.93V18.93H16.91V18.3H17.59V18.93H19.6V19.49H17.59V20.03H19.39V22.46H17.58V23H19.64V23.36C20.01 23.04 20.36 22.68 20.67 22.28C20.36 21.21 20.17 19.82 20.06 18.27H14.66V17.64H16.91V16.84H15.11V16.24H16.91V15.44H17.62V16.24H19.42V16.84H17.62V17.64H20.03C19.99 16.94 19.97 16.21 19.97 15.46H20.72C20.71 16.22 20.72 16.95 20.76 17.64H23.56V18.27H20.79C20.88 19.51 21.02 20.61 21.22 21.5C21.74 20.68 22.16 19.77 22.45 18.81L23.12 19.03ZM15.73 21.03H16.96V20.47H15.73V21.03ZM15.73 22.02H16.96V21.45H15.73V22.02ZM18.81 20.47H17.54V21.03H18.81V20.47ZM18.81 21.45H17.54V22.02H18.81V21.45ZM22.75 17.34C22.47 16.95 21.88 16.35 21.37 15.94L21.9 15.58C22.41 15.97 23.02 16.54 23.29 16.93L22.75 17.34Z"
        fill="#5D6771"
      />
    </g>
  ),
});
