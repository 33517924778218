import { useQuery } from '@apollo/client';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Typography, useZodForm } from '@kkhs/hakari-ui';
import { useFormContext } from 'react-hook-form';
import { AdoptedMedicalMaterialAddDialog } from '@/features/adoptedMedicalMaterialAdd';
import { AdoptedMedicineAddDialog } from '@/features/adoptedMedicineCreate';
import {
  GroupPharmacyStocksByMedicineTable,
  useGroupPharmacyStocksByMedicine,
} from '@/features/groupPharmacyStocksByMedicine';
import { OwnTakeTransferRequestDialog } from '@/features/ownTakeTransferRequestCreate';
import { graphql } from '@/gql';
import { SearchSection } from './SearchSection';
import { FieldValues, defaultValues, schema } from './schema';

export function useGroupPharmacyStocksTabForm() {
  return useZodForm({ schema, defaultValues });
}

type Props = {
  medicineId: string;
};

const queryDoc = graphql(`
  query GroupPharmacyStocksTab {
    ogasRegistrationInfo {
      hasOgasRegistration
      parentGroupId
    }
  }
`);

export function GroupPharmacyStocksTab({ medicineId }: Props) {
  const { data, loading: isLoadingOgasRegistrationInfo } = useQuery(queryDoc);
  const hasOgasRegistration = data?.ogasRegistrationInfo.hasOgasRegistration ?? false;
  const defaultOrganizationGroupId = data?.ogasRegistrationInfo.parentGroupId ?? null;

  const { watch } = useFormContext<FieldValues>();
  const currentOrganizationGroupId = watch('organizationGroupId');

  // NOTE: 初回表示のみ defaultOrganizationGroupId が参照されるように (初回のみ currentOrganizationGroupId は undefined)
  //       タブ移動した場合は直前の値 (currentOrganizationGroupId) を参照
  const organizationGroupId =
    currentOrganizationGroupId === undefined
      ? defaultOrganizationGroupId
      : currentOrganizationGroupId;

  const { groupPharmacyStocks, isLoading } = useGroupPharmacyStocksByMedicine({
    medicineId,
    fetchPolicy: 'cache-and-network',
    filter: {
      organizationGroupId: hasOgasRegistration ? organizationGroupId : undefined,
    },
    skipFetch: isLoadingOgasRegistrationInfo,
  });

  return (
    <>
      {hasOgasRegistration && <SearchSection defaultOrganizationGroupId={organizationGroupId} />}
      <Box px={6} pb={4} pt={2}>
        {groupPharmacyStocks?.hasMore && (
          <Flex height="33px">
            <Typography variant="body" fontWeight="normal" alignSelf="center">
              件数が多いため100件まで表示しています
            </Typography>
          </Flex>
        )}
        <GroupPharmacyStocksByMedicineTable
          medicineId={medicineId}
          groupPharmacyStocks={groupPharmacyStocks?.othersMedicineStockListItems ?? []}
          isLoading={isLoading}
          noRowsRenderer={noRowsRenderer}
          renderOwnTakeTransferRequestDialog={({ isOpen, onClose, onSuccess, row }) => (
            <OwnTakeTransferRequestDialog
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={onSuccess}
              targets={row ? [row] : []}
              userMonitoringKey="医薬品詳細モーダル_他店舗在庫タブ_小分け依頼_SUBMITTED"
            />
          )}
          renderAdoptedMedicineCreateDialog={({ isOpen, onClose, onSuccess, row }) => (
            <AdoptedMedicineAddDialog
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={onSuccess}
              target={{
                id: row.medicine.id,
                medicineName: row.medicine.medicineName,
                defaultMedicinePackageUnitId: undefined,
                stockUnitSymbol: row.medicine.stockUnitSymbol,
                unitOutline: row.medicine.usageGroup.unitOutline,
                // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
                shortUnitOutline: row.medicine.usageGroup.shortUnitOutline,
              }}
              dialogDescription={
                <Typography variant="body">
                  下記の医薬品は採用薬にないため、小分け依頼を出せません。
                  <br />
                  小分け依頼を出す場合は、採用薬として登録してください。
                </Typography>
              }
              userMonitoringKey="医薬品詳細モーダルの他店舗の在庫タブから医薬品を登録"
            />
          )}
          renderAdoptedMedicalMaterialCreateDialog={({ isOpen, onClose, onSuccess, row }) => (
            <AdoptedMedicalMaterialAddDialog
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={onSuccess}
              target={{
                id: row.medicine.id,
                medicineName: row.medicine.medicineName,
                defaultMedicinePackageUnitId: undefined,
                stockUnitSymbol: row.medicine.stockUnitSymbol,
                unitOutline: row.medicine.usageGroup.unitOutline,
                // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
                shortUnitOutline: row.medicine.usageGroup.shortUnitOutline,
              }}
              userMonitoringKey="医薬品詳細モーダルの他店舗の在庫タブから医薬品を登録"
            />
          )}
        />
      </Box>
    </>
  );
}

function noRowsRenderer() {
  return (
    <Stack align="center" pt="178px">
      <Typography variant="body">他店舗在庫はありません</Typography>
    </Stack>
  );
}
