import { useMutation } from '@apollo/client';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { showErrorToast } from '@kkhs/hakari-ui';
import { RefObject } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { graphql } from '@/gql';
import { FieldValues } from '../schema';

const mutationDoc = graphql(`
  mutation DeleteStockOperation($input: DeleteStockOperationInput!) {
    deleteStockOperation(input: $input) {
      userErrors {
        ... on ValidationError {
          message
          path
        }
        ... on StockOperationNotExistError {
          message
          path
          id
        }
        ... on ClosedStockOperationError {
          message
          path
          latestStockCloseDate
        }
      }
    }
  }
`);
type Props = {
  cancelRef: RefObject<HTMLButtonElement>;
  onSuccess: () => Promise<void>;
  onCancel: () => void;
};

export function StockOperationDeleteButtonGroup({ cancelRef, onSuccess, onCancel }: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext<FieldValues>();
  const [deleteStockOperation, { loading, called, reset }] = useMutation(mutationDoc);

  const submit: SubmitHandler<FieldValues> = async ({ id }) => {
    await deleteStockOperation({
      variables: {
        input: { id },
      },
      onError: () => {
        showErrorToast({
          id: 'delete-stock-operation-error',
          title: 'エラーが発生しました',
        });
        reset();
      },
      onCompleted: async (d) => {
        if (d.deleteStockOperation.userErrors.length > 0) {
          if (d.deleteStockOperation.userErrors[0]?.__typename === 'ClosedStockOperationError') {
            showErrorToast({
              id: 'delete-closed-stock-operation-error',
              title: '棚卸日以前のため編集・削除できません',
            });
            // Datadogにログを送信
            userMonitoringRepository.sendEvent({
              key: '棚卸以前の日付で在庫調整の入庫区分を削除',
              contexts: { pharmacyId, musubiCode },
            });
            reset();
            return;
          }
          showErrorToast({
            id: 'delete-stock-operation-error',
            title: 'エラーが発生しました',
          });
          reset();
        } else {
          await onSuccess();
        }
      },
    });
  };
  // TODO: ButtonGroupを外から受け取る
  return (
    <ButtonGroup>
      <Button w="118px" variant="ghost" ref={cancelRef} onClick={onCancel} isDisabled={loading}>
        キャンセル
      </Button>
      <Button
        w="146px"
        onClick={handleSubmit(submit)}
        isLoading={loading || called}
        isDisabled={loading || !isValid}
      >
        変更を保存する
      </Button>
    </ButtonGroup>
  );
}
