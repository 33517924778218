import { useEffect } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';

type Props = {
  children: JSX.Element;
};

export function CommonUserMonitoringRoot({ children }: Props) {
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  useEffect(() => {
    const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
    const sendEvent = () => {
      userMonitoringRepository.sendEvent({
        key: '共通_ブラウザの戻る進む_CLICKED',
        contexts: { pharmacyId, musubiCode },
      });
    };

    window.addEventListener('popstate', sendEvent);

    return () => window.removeEventListener('popstate', sendEvent);
  }, [musubiCode, pharmacyId]);

  return children;
}
