import {
  Box,
  BoxProps,
  IconButton,
  PlacementWithLogical,
  Popover,
  PopoverBodyProps,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { useState, useMemo, ReactNode, useCallback, useEffect, useRef } from 'react';
import { getScrollableParentContainer } from '../../../utils';
import { PopoverContent } from '../PopoverContent';

export type InfoPopoverProps = {
  /** PopoverTrigger */
  children: ReactNode;
  /** 表示する内容 */
  content: string | ReactNode;
  /** 展開方向 */
  placement?: PlacementWithLogical;
  /**
   * Portal or not
   * https://chakra-ui.com/docs/components/popover#rendering-the-popover-in-a-portal
   */
  isPortal?: boolean;
  /** 親要素をスクロール時（スクロール可能な親要素がない場合は画面全体）に閉じる */
  closeOnScroll?: boolean;
  wrapperBoxProps?: BoxProps;
} & Pick<PopoverProps, 'defaultIsOpen' | 'isOpen' | 'onOpen' | 'onClose'> &
  Pick<PopoverContentProps, 'w'> &
  Pick<PopoverBodyProps, 'p'>;

/**
 * info ラベルをクリックしたらポップアップを表示するコンポーネント
 * @param {ReactNode} children Popover trigger
 * @param {Pick<PopoverContentProps, 'w'>} w Popover width
 * @param {Pick<PopoverBodyProps, 'p'>} p Popover padding
 * @param {string | ReactNode} content 表示する内容
 * @param {PlacementWithLogical | undefined} placement 展開方向
 * @param {boolean | undefined} defaultIsOpen デフォルトで Popover が開いているかのフラグ
 * @param {boolean | undefined} isPortal デフォルトで Popover をポータルでレンダリングするかのフラグ
 * @returns Popover
 */

const TRANSITION_DURATION = 0.15;

export function InfoPopover({
  children,
  w = 'auto',
  p = 'auto',
  content,
  placement = 'top',
  defaultIsOpen = false,
  isPortal = false,
  closeOnScroll = false,
  isOpen: isOpenProp = undefined,
  onOpen: onOpenProp = undefined,
  onClose: onCloseProp = undefined,
  wrapperBoxProps,
}: InfoPopoverProps) {
  const [transitionDuration, setTransitionDuration] = useState<number>(TRANSITION_DURATION);
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: isOpenProp,
    defaultIsOpen,
    onOpen: onOpenProp,
    onClose: onCloseProp,
  });

  const animationVariants = useMemo(
    () => ({
      exit: {
        opacity: 0,
        scale: 0.95,
        transition: {
          duration: transitionDuration,
          ease: 'easeOut',
        },
      },
      enter: {
        opacity: 1,
        scale: 1,
        transition: {
          duration: transitionDuration,
          ease: 'easeOut',
        },
      },
    }),
    [transitionDuration],
  );

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (closeOnScroll && onClose) {
      // スクロールで閉じる場合のみTransitionDurationを0にしてTransitionを無効化する
      setTransitionDuration(0);
      onClose();
      // 次に開く場合はTransitionするように戻す
      setTransitionDuration(TRANSITION_DURATION);
    }
  }, [closeOnScroll, onClose]);

  useEffect(() => {
    if (closeOnScroll) {
      const scrollableParent = getScrollableParentContainer(ref.current);
      scrollableParent.addEventListener('scroll', handleScroll);
      return () => {
        scrollableParent.removeEventListener('scroll', handleScroll);
      };
    }
    return undefined;
  }, [closeOnScroll, handleScroll]);

  return (
    <Box ref={ref} display="flex" {...wrapperBoxProps}>
      <Popover
        placement={placement}
        defaultIsOpen={defaultIsOpen}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="infoPopover"
            variant="ghost"
            minW={0}
            h="auto"
            color="inherit"
            fontSize="inherit"
            fontWeight="inherit"
            lineHeight="inherit"
            _focus={{ outline: 'none' }}
            _active={{ outline: 'none' }}
            _hover={{ backgroundColor: 'inherit' }}
          >
            {children}
          </IconButton>
        </PopoverTrigger>
        {isPortal ? (
          <Portal>
            <PopoverContent w={w} content={content} p={p} variants={animationVariants} />
          </Portal>
        ) : (
          <PopoverContent w={w} content={content} p={p} variants={animationVariants} />
        )}
      </Popover>
    </Box>
  );
}
