import { MutableSnapshot } from 'recoil';
import {
  useCopyState,
  useReset,
  useSetOrganizationGroups,
  useSetPharmacyStaff,
  useUpdateStatus,
} from './hooks';
import { AuthStatus, OrganizationGroups, PharmacyStaff } from './types';

type AuthActions = {
  useUpdateStatus: () => (value: AuthStatus) => void;
  useSetPharmacyStaff: () => (pharmacyStaff: PharmacyStaff | undefined) => void;
  useSetOrganizationGroups: () => (organizationGroups: OrganizationGroups | undefined) => void;
  /** RecoilRoot 間での値の同期に利用する */
  useCopyState: () => (snapshot: MutableSnapshot) => void;
  useReset: () => () => void;
};

export const authActions: AuthActions = {
  useUpdateStatus,
  useSetPharmacyStaff,
  useSetOrganizationGroups,
  useCopyState,
  useReset,
};
