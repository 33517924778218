import * as RadioGroup from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';
import { RadioGroupItem } from './RadioGroupItem';
import { root } from './index.css';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';

type Option = {
  value: string | number | undefined;
  label: string;
};

type Props = Omit<ComponentPropsWithoutRef<typeof RadioGroup.Root>, 'className'> & {
  options: Option[];
  /** ラジオボタンのサイズを指定 */
  size?: 'md' | 'sm';
  /** 各選択肢の最小幅を px で指定 */
  itemMinWidth?: string;
  /** 各選択肢間の幅を数値で指定（pxに変換されます） */
  spacing?: number;
  /**
   * 選択肢が横並びか、もしくは縦並びかを指定
   * default: horizontal
   */
  orientation?: 'horizontal' | 'vertical';
  /** onBlur時に実行したい関数を指定 */
  onBlur?: () => void;
};

/**
 * API Reference
 * https://www.radix-ui.com/docs/primitives/components/radio-group#api-reference
 */
export const RadioGroupV2 = forwardRef<ElementRef<typeof RadioGroup.Root>, Props>(
  (
    { options, size = 'md', itemMinWidth, spacing, orientation = 'horizontal', onBlur, ...rest },
    ref,
  ) => (
    <RadioGroup.Root
      className={root}
      style={{ gap: spacing, flexDirection: orientation === 'horizontal' ? 'row' : 'column' }}
      orientation={orientation}
      {...rest}
      onBlur={onBlur}
      ref={ref}
    >
      {options.map((item) => (
        <RadioGroupItem
          key={item.label}
          value={item.value?.toString() ?? ''}
          label={item.label}
          size={size}
          itemMinWidth={itemMinWidth}
        />
      ))}
    </RadioGroup.Root>
  ),
);

RadioGroupV2.displayName = 'RadioGroupV2';
