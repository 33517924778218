import { Center, Skeleton, Stack, Tbody, Tr, Td } from '@chakra-ui/react';
import { captureMessage } from '@sentry/nextjs';
import { MedicineTransactionListItemsQuery } from '@/gql/docs';
import { MedicineTransactionConsumptionRow } from './MedicineTransactionConsumptionRow';
import { MedicineTransactionReplenishmentRow } from './MedicineTransactionReplenishmentRow';

type Props = {
  edges: MedicineTransactionListItemsQuery['medicineTransactionListItems']['edges'];
  stockUnitSymbol: string;
  /** データのローディング状態 */
  isLoading: boolean;
};

export function MedicineTransactionListItemsTableBody({
  edges,
  stockUnitSymbol,
  isLoading,
}: Props) {
  if (isLoading) {
    return (
      <Tbody>
        <Tr>
          <Td colSpan={9} p="0">
            <Center bg="white" h="60">
              <Stack w="full" p="5">
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
              </Stack>
            </Center>
          </Td>
        </Tr>
      </Tbody>
    );
  }
  return (
    <Tbody data-testid="入出庫履歴">
      {edges.map(({ node }) => {
        switch (node.transaction.transactionType) {
          case 'replenishment':
            return (
              <MedicineTransactionReplenishmentRow
                key={node.id}
                stockUnitSymbol={stockUnitSymbol}
                node={{ ...node, transactionType: 'replenishment' }} // NOTE: node={node}だと型を絞り込んでくれないので、transactionTypeを明示的に指定
              />
            );
          case 'consumption':
            return (
              <MedicineTransactionConsumptionRow
                key={node.id}
                stockUnitSymbol={stockUnitSymbol}
                node={{ ...node, transactionType: 'consumption' }} // NOTE: node={node}だと型を絞り込んでくれないので、transactionTypeを明示的に指定
              />
            );
          default:
            captureMessage(`Invalid TransactionType - ${node.transaction.transactionType}`);
            return null;
        }
      })}
    </Tbody>
  );
}
