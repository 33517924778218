import { Stack } from '@chakra-ui/react';
import { Typography, InfiniteScrollTable } from '@kkhs/hakari-ui';
import { PrescriptionFrequencyType } from '@/entities/prescription';
import {
  PatientByMedicineListItemsQuery,
  PatientByMedicineListItemsSort,
  PatientByMedicineListItemsSortField,
} from '@/gql/apollo';
import { PatientsTableBody } from './PatientsTableBody';
import { PatientsTableHeader } from './PatientsTableHeader';

type Props = {
  hasMore?: boolean;
  loadMore: () => void;
  edges: PatientByMedicineListItemsQuery['patientByMedicineListItems']['edges'];
  scrollParentRef: React.MutableRefObject<null>;
  stockUnitSymbol: string;
  isLoading: boolean;
  prescriptionTypeMap?: { [key: string]: PrescriptionFrequencyType | undefined };
  handleSortBy: (fieldName: PatientByMedicineListItemsSortField) => void;
  sortBy?: PatientByMedicineListItemsSort;
};

export function PatientsTable({
  hasMore,
  loadMore,
  edges,
  scrollParentRef,
  stockUnitSymbol,
  isLoading,
  prescriptionTypeMap,
  sortBy,
  handleSortBy,
}: Props) {
  if (!isLoading && edges.length === 0) {
    return (
      // TODO: 実際のディスプレイのサイズに合わせて調整
      <Stack align="center" pt="160px">
        <Typography variant="body">処方患者情報はありません</Typography>
      </Stack>
    );
  }

  return (
    <InfiniteScrollTable
      hasMore={hasMore}
      loadMore={loadMore}
      useWindow={false}
      getScrollParent={() => scrollParentRef.current}
    >
      <PatientsTableHeader sortBy={sortBy} handleSortBy={handleSortBy} />
      <PatientsTableBody
        edges={edges}
        isLoading={isLoading && edges.length === 0}
        stockUnitSymbol={stockUnitSymbol}
        prescriptionTypeMap={prescriptionTypeMap}
      />
    </InfiniteScrollTable>
  );
}
