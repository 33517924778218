/* eslint-disable no-empty-function,no-useless-constructor */
import Big from 'big.js';
import { RoundingMethod } from '@/gql/docs';
import { calculatePriceV2 } from '@/shared/utils';
import { TAX_RATE as 税率 } from '../constants';

export class ファルマ売却明細金額 {
  constructor(public 金額: Big, public 数量: Big) {}

  static create(金額: number, 数量: number) {
    return new ファルマ売却明細金額(new Big(金額), new Big(数量));
  }

  // itemRoundingMethod = null は丸め込みしない
  小計を計算(丸め込み方法: RoundingMethod | null) {
    return calculatePriceV2(this.金額.times(this.数量), 丸め込み方法);
  }

  // 税額を計算する際は、小計に丸め込みを適用しない
  税額を計算() {
    return this.小計を計算(null).times(税率);
  }
}
