import { useZodForm } from '@kkhs/hakari-ui';
import { useMemo } from 'react';
import { DefaultValues } from 'react-hook-form';
import { toOperatedMedicines } from './toOperatedMedicines';
import {
  UpdateStoringStockOperationByExternalTradeFieldValues,
  updateStoringStockOperationByExternalTradeSchema,
  UpdateStoringStockOperationByOtherFieldValues,
  updateStoringStockOperationByOtherSchema,
  UpdateStoringStockOperationBySubdividedPurchaseFieldValues,
  updateStoringStockOperationBySubdividedPurchaseSchema,
  UpdateStoringStockOperationByTransferFieldValues,
  UpdateStoringStockOperationByTransferRequestFieldValues,
  updateStoringStockOperationByTransferRequestSchema,
  updateStoringStockOperationByTransferSchema,
  UpdateStoringStockOperationFieldValues,
} from '../../../schema';
import { StoringStockOperation } from '../types';

type Args = {
  stockOperation: StoringStockOperation;
  defaultPriceConfig: UpdateStoringStockOperationFieldValues['defaultPriceConfig'];
};

export function useStoringStockOperationForm({ stockOperation, defaultPriceConfig }: Args) {
  const form = useMemo(() => {
    /** 共通の defaultValues */
    const commonDefaultValues = {
      /** stock_operations.id */
      id: stockOperation.id,
      targetDate: new Date(stockOperation.targetDate),
      note: stockOperation.note ?? '',
      defaultPriceConfig,
      operatedMedicines: toOperatedMedicines(stockOperation),
    } as const;

    switch (stockOperation.__typename) {
      case 'StoringStockOperationBySubdividedPurchase': {
        // NOTE: 小分けは external
        const defaultValues: DefaultValues<UpdateStoringStockOperationBySubdividedPurchaseFieldValues> =
          {
            counterpartyName: stockOperation.counterpartyName,
            feeAmount: stockOperation.feeAmount,
            containerAmount: stockOperation.containerAmount,
            stockOperationPriceConfig: defaultPriceConfig.external,
            ...commonDefaultValues,
          };
        return {
          schema: updateStoringStockOperationBySubdividedPurchaseSchema,
          defaultValues,
        };
      }
      case 'StoringStockOperationByTransfer': {
        // NOTE: 法人内入庫は internal
        const defaultValues: DefaultValues<UpdateStoringStockOperationByTransferFieldValues> = {
          counterpartyId: stockOperation.counterpartyId,
          counterpartyName: stockOperation.counterpartyName,
          // NOTE: 法人内入庫の場合は、薬局のデフォルトの金額設定をセットしているが、参照している箇所はない。
          stockOperationPriceConfig: defaultPriceConfig.internal,
          ...commonDefaultValues,
        };
        return {
          schema: updateStoringStockOperationByTransferSchema,
          defaultValues,
        };
      }
      case 'StoringStockOperationByTransferRequest': {
        // NOTE: 法人内入庫（依頼ベース）は internal
        const defaultValues: DefaultValues<UpdateStoringStockOperationByTransferRequestFieldValues> =
          {
            pharmacyId: stockOperation.pharmacyId,
            stockOperationPriceConfig: defaultPriceConfig.internal,
            ...commonDefaultValues,
          };
        return {
          schema: updateStoringStockOperationByTransferRequestSchema,
          defaultValues,
        };
      }
      case 'StoringStockOperationByExternalTrade': {
        // NOTE: 法人間入庫は external
        const defaultValues: DefaultValues<UpdateStoringStockOperationByExternalTradeFieldValues> =
          {
            counterpartyId: stockOperation.storingOriginOnCreated?.counterpartyId ?? null,
            counterpartyName: stockOperation.storingOriginOnCreated?.counterpartyName ?? null,
            feeAmount: stockOperation.feeAmount,
            containerAmount: stockOperation.containerAmount,
            stockOperationPriceConfig:
              // NOTE: 法人間入庫で、作成時に法人間の取引先を利用していた場合、その取引先の priceConfig を使う
              stockOperation.storingOriginOnCreated?.origin === 'externalCounterparty'
                ? stockOperation.storingOriginOnCreated.priceConfig
                : defaultPriceConfig.external,
            storingOriginOnCreated: stockOperation.storingOriginOnCreated,
            ...commonDefaultValues,
          };
        return {
          schema: updateStoringStockOperationByExternalTradeSchema,
          defaultValues,
        };
      }
      case 'StoringStockOperationByOther': {
        // NOTE: その他入庫は external
        const defaultValues: DefaultValues<UpdateStoringStockOperationByOtherFieldValues> = {
          counterpartyId: stockOperation.storingOriginOnCreated?.counterpartyId ?? null,
          counterpartyName: stockOperation.storingOriginOnCreated?.counterpartyName ?? null,
          feeAmount: null,
          containerAmount: null,
          stockOperationPriceConfig:
            // NOTE: その他入庫で、作成時に法人間の取引先を利用していた場合、その取引先の priceConfig を使う
            stockOperation.storingOriginOnCreated?.origin === 'externalCounterparty'
              ? stockOperation.storingOriginOnCreated.priceConfig
              : defaultPriceConfig.external,
          storingOriginOnCreated: stockOperation.storingOriginOnCreated,
          ...commonDefaultValues,
        };
        return {
          schema: updateStoringStockOperationByOtherSchema,
          defaultValues,
        };
      }
      default:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw new Error(`unreachable: ${(stockOperation as any)?.__typename}`);
    }
  }, [defaultPriceConfig, stockOperation]);

  const useFormReturn = useZodForm({
    schema: form.schema,
    defaultValues: form.defaultValues,
  });

  return useFormReturn;
}
