import { UpdateStoringStockOperationBySubdividedPurchaseInput } from '@/gql/docs';
import { dateToAWSDate } from '@/shared/utils';
import { updateStoringStockOperationBySubdividedPurchaseSchema } from '../schema';

export const storingBySubdividedPurchaseSchemaToInput =
  updateStoringStockOperationBySubdividedPurchaseSchema.transform(
    ({
      id,
      targetDate,
      counterpartyName,
      feeAmount,
      containerAmount,
      note,
      stockOperationPriceConfig: { shouldShowTax },
      operatedMedicines,
    }): UpdateStoringStockOperationBySubdividedPurchaseInput => ({
      id,
      targetDate: dateToAWSDate(targetDate),
      counterpartyName,
      feeAmount,
      containerAmount,
      taxRate: shouldShowTax ? 'ten' : undefined,
      note,
      operatedMedicines: operatedMedicines.flatMap((item) =>
        item.lots.map((lot) => ({
          medicineId: item.medicineId,
          stockUnitQuantity: lot.stockUnitQuantity,
          costPrice: item.costPrice,
          lotNumber: lot.lotNumber,
          expirationDate: lot.expirationDate ? dateToAWSDate(lot.expirationDate) : undefined,
        })),
      ),
    }),
  );
