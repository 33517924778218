import { createIcon } from '@chakra-ui/react';

export const CrossIcon = createIcon({
  displayName: 'CrossIcon',
  viewBox: '0 0 12 12',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 0L0 1.5L4.5 6L0 10.5L1.5 12L6 7.5L10.5 12L12 10.5L7.5 6L12 1.5L10.5 0L6 4.5L1.5 0Z"
      fill="currentColor"
    />
  ),
});
