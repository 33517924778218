import { UpdateStoringStockOperationFieldValues } from '../../ui/schema';

/** 操作ごとに指定できるロット数の最大値 */
const MAX_LOT_COUNT_BY_STOCK_OPERATION = 5;

/**
 * ロット数が最大であるかのフラグを返す
 */
export function isLotCountMax(
  operatedMedicines: UpdateStoringStockOperationFieldValues['operatedMedicines'],
) {
  return (
    operatedMedicines.reduce((prev, current) => prev + current.lots.length, 0) ===
    MAX_LOT_COUNT_BY_STOCK_OPERATION
  );
}
