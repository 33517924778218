import { createIcon } from '@chakra-ui/react';

export const PatientInfoIcon = createIcon({
  displayName: 'PatientInfoIcon',
  viewBox: '0 0 60 60',
  path: (
    <>
      <path
        d="M45.9964 16.2149C45.6875 15.4843 45.1736 14.8665 44.5263 14.4282C43.879 13.9911 43.0907 13.7334 42.2525 13.7334H38.7533V13.1298H33.6815C33.7045 12.9657 33.7174 12.7965 33.7174 12.626C33.7173 10.6265 32.0908 9 30.0913 9C28.0918 9 26.4653 10.6265 26.4653 12.626C26.4653 12.7965 26.4781 12.9657 26.5012 13.1298H21.4294V13.7334H17.9302C17.3726 13.7334 16.8343 13.8476 16.3485 14.0539C15.6167 14.3628 14.9989 14.8767 14.5605 15.524C14.1234 16.1726 13.8658 16.9596 13.8658 17.7979V46.9369C13.8658 47.4945 13.9799 48.0315 14.185 48.5186C14.4951 49.2491 15.0091 49.867 15.6564 50.3053C16.3038 50.7436 17.0919 51 17.9302 51H42.2525C42.81 51 43.3483 50.8872 43.8354 50.6796C44.566 50.372 45.1838 49.8567 45.6221 49.2094C46.0592 48.5621 46.3169 47.7739 46.3169 46.9369V17.7979C46.3169 17.2391 46.2028 16.702 45.9964 16.2149ZM30.0913 11.2802C30.8335 11.2802 31.4371 11.8839 31.4371 12.626C31.4371 12.8042 31.4012 12.9733 31.3371 13.1298H28.8441C28.7813 12.9734 28.7454 12.8042 28.7454 12.626C28.7455 11.8839 29.3505 11.2802 30.0913 11.2802ZM43.8995 46.9369C43.8995 47.1677 43.8533 47.3804 43.77 47.5765C43.647 47.87 43.4355 48.1238 43.1728 48.3032C42.9087 48.4801 42.5985 48.5826 42.2525 48.5826H17.9302C17.6994 48.5826 17.4854 48.5364 17.2893 48.4544C16.9958 48.33 16.742 48.1186 16.5638 47.8558C16.3856 47.5918 16.2831 47.2816 16.2831 46.9368V17.7979C16.2831 17.5671 16.3293 17.3531 16.4125 17.1569C16.5369 16.8621 16.7458 16.6096 17.0098 16.4315C17.2739 16.2546 17.584 16.1521 17.9301 16.1507H21.4292V16.6545C21.4292 17.6004 22.1957 18.3669 23.1429 18.3669H37.0395C37.9867 18.3669 38.7532 17.6004 38.7532 16.6545V16.1507H42.2524C42.4831 16.1507 42.6971 16.1969 42.8933 16.2802C43.1868 16.4046 43.4406 16.6135 43.6188 16.8775C43.7956 17.1427 43.8982 17.4517 43.8995 17.7978V46.9369Z"
        fill="currentColor"
      />
      <path d="M36.8658 38H22.8658V39H36.8658V38Z" fill="currentColor" />
      <path d="M36.8658 42H22.8658V43H36.8658V42Z" fill="currentColor" />
      <path
        d="M25.9009 30.3509C27.281 29.7895 28.6026 29.5088 29.8658 29.5088C31.1289 29.5088 32.4389 29.7895 33.7956 30.3509C35.1757 30.8889 35.8658 31.6023 35.8658 32.4912V34H23.8658V32.4912C23.8658 31.6023 24.5441 30.8889 25.9009 30.3509ZM31.971 27.1228C31.3863 27.7076 30.6845 28 29.8658 28C29.0471 28 28.3453 27.7076 27.7605 27.1228C27.1757 26.538 26.8833 25.8363 26.8833 25.0175C26.8833 24.1988 27.1757 23.4971 27.7605 22.9123C28.3453 22.3041 29.0471 22 29.8658 22C30.6845 22 31.3863 22.3041 31.971 22.9123C32.5558 23.4971 32.8482 24.1988 32.8482 25.0175C32.8482 25.8363 32.5558 26.538 31.971 27.1228Z"
        fill="currentColor"
      />
    </>
  ),
});
