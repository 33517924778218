import { createIcon } from '@chakra-ui/react';

export const StocktakeIconAlt = createIcon({
  displayName: 'StocktakeIconAlt',
  viewBox: '0 0 48 48',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9998 18L33 8L36 11L22.9998 24.0003L16 17L19 14L22.9998 18ZM40 33V36V40H36H12H8V36V33V28L12 24H17L12 29L12 36H36V29L31 24H36L40 28V33ZM32 28H16V32H32V28Z"
      fill="currentColor"
    />
  ),
});
