import { createIcon } from '@chakra-ui/react';

export const ImportIcon = createIcon({
  displayName: 'ImportIcon',
  viewBox: '0 0 48 48',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 4V15.9999L32 10L35 13L23.9998 24.0003L13 13L16 10L22 16.0001L22 4H26ZM40 36V33V28L36 24H31L36 29V36H12L12 29L17 24H12L8 28V33V36V40H12H36H40V36ZM32 28H16V32H32V28Z"
      fill="currentColor"
    />
  ),
});
