import { useTheme, WithCSSVar } from '@chakra-ui/react';
import { StylesConfig } from 'react-select';
import { Theme } from '../../../../theme';
import { SelectOptionType, SelectSize } from '../../types';

const heights = {
  xs: 6,
  sm: 8,
  md: 10,
  lg: 12,
} as const;

const radius = {
  xs: 'sm',
  sm: 'base',
  md: 'md',
  lg: 'lg',
} as const;

const paddings = {
  xs: 1,
  sm: 1,
  md: 4,
  lg: 4,
} as const;

const dropdownIndicatorIconSize = {
  xs: 4,
  sm: 4,
  md: 6,
  lg: 6,
} as const;

const clearIndicatorIconSize = {
  xs: 2,
  sm: 2,
  md: 3,
  lg: 3,
} as const;

const getControlBackgroundColor = ({
  isInvalid,
  hasChanged,
  theme,
}: {
  isInvalid: boolean;
  hasChanged: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: WithCSSVar<any>;
}) => {
  if (isInvalid) return theme.colors.red[50];
  if (hasChanged) return theme.colors.gray[100];
  return 'white';
};

const getOptionBackgroundColor = ({
  isDisabled,
  isSelected,
  theme,
}: {
  isDisabled: boolean;
  isSelected: boolean;
  theme: Theme;
}) => {
  if (isDisabled) return theme.colors.gray[100];
  if (isSelected) return theme.colors.primary[100];
  return 'white';
};

export function useSelectStyles<T = string>({
  size = 'md',
  isWordWrapped,
  isInvalid,
  isNumeric,
  isDynamicOptionWidth = false,
  hasChanged = false,
}: {
  size: SelectSize;
  isWordWrapped: boolean;
  isInvalid: boolean;
  isNumeric?: boolean;
  isDynamicOptionWidth?: boolean;
  /** 編集済みフィールドの style を変えたい場合に利用する */
  hasChanged?: boolean;
}): StylesConfig<SelectOptionType<T>, false> {
  const theme = useTheme<Theme>();

  const selectStyles: StylesConfig<SelectOptionType<T>, false> = {
    input: (base) => ({
      ...base,
      color: 'inherit',
      lineHeight: 1,
      gridArea: '1 / 1 / 2 / 3',
      margin: 0,
      alignSelf: 'stretch',
      display: 'block',
      whiteSpace: 'pre',
      input: {
        textAlign: isNumeric ? 'end' : 'start',
      },
    }),
    control: (base, { isDisabled }) => ({
      ...base,
      height: 'auto',
      minHeight: theme.sizes[heights[size]],
      borderColor: isDisabled ? theme.colors.gray[100] : theme.colors.gray[300],
      borderRadius: theme.radii[radius[size]],
      boxSizing: 'border-box',
      padding: 2.5,
      backgroundColor: getControlBackgroundColor({ isInvalid, hasChanged, theme }),
    }),
    option: (base, { isSelected, isDisabled }) => ({
      ...base,
      fontSize: theme.fontSizes.md,
      minHeight: '32px',
      padding: `${theme.space['1']} ${theme.space['2']}`,
      color: isDisabled ? theme.colors.gray[500] : theme.colors.slate[700],
      backgroundColor: getOptionBackgroundColor({ isDisabled, isSelected, theme }),
      textAlign: isNumeric ? 'end' : 'start',
      ':active': {
        ...base[':active'],
        backgroundColor: theme.colors.primary[200],
      },
      whiteSpace: isDynamicOptionWidth ? 'nowrap' : 'pre-line',
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'grid',
      height: '100%',
      fontSize: theme.fontSizes[size],
      color: theme.colors.slate[700],
      padding: `0 0 0 ${theme.space[paddings[size]]}`,
    }),
    singleValue: (base) => ({
      ...base,
      marginLeft: 0,
      marginRight: 0,
      whiteSpace: isWordWrapped ? 'normal' : 'pre',
      position: 'relative',
      top: 0,
      transform: 'none',
      gridArea: '1 / 1 / 2 / 3',
      textAlign: isNumeric ? 'end' : 'start',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      width: isDynamicOptionWidth ? 'auto' : base.width,
    }),
    menu: (base) => ({
      ...base,
      width: isDynamicOptionWidth ? 'auto' : '100%',
    }),
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '3px',
        backgroundColor: theme.colors.gray[200],
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: theme.colors.primary[500],
        borderRadius: 100,
      },
    }),
    placeholder: () => ({
      fontSize: theme.fontSizes[size],
      color: theme.colors.slate[300],
      gridArea: '1 / 1 / 2 / 3',
      textAlign: isNumeric ? 'end' : 'start',
    }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: (_, { isDisabled }) => ({
      paddingRight: theme.space[1],
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: isDisabled ? theme.colors.primary[300] : undefined,
      },
    }),
    noOptionsMessage: (base) => ({
      ...base,
      fontSize: theme.fontSizes.md,
      whiteSpace: isDynamicOptionWidth ? 'nowrap' : 'pre-line',
    }),
    clearIndicator: () => ({
      width: theme.sizes[clearIndicatorIconSize[size]],
      height: theme.sizes[clearIndicatorIconSize[size]],
    }),
    dropdownIndicator: () => ({
      width: theme.sizes[dropdownIndicatorIconSize[size]],
      height: theme.sizes[dropdownIndicatorIconSize[size]],
    }),
  };
  return selectStyles;
}
