import { createIcon } from '@chakra-ui/react';

export const TabletPtpIcon = createIcon({
  displayName: 'TabletPtpIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.65 9.756c0-.6.48-1.106 1.212-1.106h14.276c.733 0 1.212.505 1.212 1.106v20.488c0 .6-.48 1.106-1.212 1.106H12.862c-.732 0-1.212-.505-1.212-1.106V9.756zm1.212-2.406c-1.378 0-2.512 1.017-2.512 2.406v20.488c0 1.39 1.134 2.406 2.512 2.406h14.276c1.378 0 2.512-1.016 2.512-2.406V9.756c0-1.39-1.134-2.406-2.512-2.406H12.862zm1.788 5.626a1.399 1.399 0 112.798 0 1.399 1.399 0 01-2.798 0zm1.399-2.7a2.699 2.699 0 100 5.398 2.699 2.699 0 000-5.397zm0 8.325a1.399 1.399 0 100 2.798 1.399 1.399 0 000-2.798zM13.35 20a2.699 2.699 0 115.398 0 2.699 2.699 0 01-5.398 0zm2.699 5.626c-.753 0-1.399.645-1.399 1.398 0 .754.646 1.4 1.399 1.4s1.399-.646 1.399-1.4c0-.753-.646-1.398-1.4-1.398zm-2.699 1.398c0-1.47 1.228-2.698 2.699-2.698 1.471 0 2.699 1.227 2.699 2.698 0 1.472-1.228 2.7-2.7 2.7-1.47 0-2.698-1.228-2.698-2.7zm10.699-1.398c-.753 0-1.399.645-1.399 1.398 0 .754.646 1.4 1.399 1.4s1.399-.646 1.399-1.4c0-.753-.646-1.398-1.4-1.398zm-2.699 1.398c0-1.47 1.228-2.698 2.699-2.698 1.471 0 2.699 1.227 2.699 2.698 0 1.472-1.228 2.7-2.7 2.7-1.47 0-2.698-1.228-2.698-2.7zM22.65 20a1.399 1.399 0 112.798 0 1.399 1.399 0 01-2.798 0zm1.399-2.699a2.699 2.699 0 100 5.398 2.699 2.699 0 000-5.398zm0-5.724a1.399 1.399 0 100 2.797 1.399 1.399 0 000-2.797zm-2.699 1.399a2.699 2.699 0 115.398 0 2.699 2.699 0 01-5.398 0z"
      fill="currentColor"
    />
  ),
});
