import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';

type Props = {
  label: string;
  value?: string;
};

export function TextItemLabel({ label, value }: Props) {
  return (
    <Stack spacing={0.5}>
      <Typography variant="body2" color="slate.500">
        {label}
      </Typography>
      <Typography data-test-label={label} variant="body">
        {value ?? '-'}
      </Typography>
    </Stack>
  );
}
