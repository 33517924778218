import { HStack, useRadioGroup, UseRadioGroupProps, Text, StackProps } from '@chakra-ui/react';
import { RadioItem } from './RadioItem';

type Option = {
  value: string | undefined;
  label: string;
};

type RowProps = {
  options: Option[];
  itemMinWidth?: string;
  spacing?: StackProps['spacing'];
  onBlur?: () => void;
} & UseRadioGroupProps;

export function RadioGroup(props: RowProps) {
  const { options, itemMinWidth, isDisabled, onBlur, spacing = 4 } = props;
  const { getRootProps, getRadioProps } = useRadioGroup(props);
  const group = getRootProps();

  return (
    <HStack spacing={spacing} {...group} onBlur={onBlur}>
      {options.map((item) => {
        const radio = getRadioProps({ value: item.value });

        return (
          <RadioItem
            minW={itemMinWidth}
            key={item.label}
            value={item.value}
            isDisabled={isDisabled}
            label={<Text color={isDisabled ? 'gray.500' : undefined}>{item.label}</Text>}
            {...radio}
          />
        );
      })}
    </HStack>
  );
}
