import { Box, HStack, Stack } from '@chakra-ui/react';
import { Typography, InfoIcon, InfoPopover } from '@kkhs/hakari-ui';
import { isNullOrUndefined, round } from '@kkhs/hakari-utils';
import { format, parseISO } from 'date-fns';
import {
  ConvertedStockUnitQuantityWithSymbol,
  StockUnitQuantityWithSymbol,
} from '@/entities/medicine';
import { getPrescriptionFrequencyType } from '@/entities/prescription';
import { PRIMARY_WHOLESALE_NOT_CONFIGURED_TEXT } from '@/entities/primaryWholesale';
import { MedicineSummaryTabQuery } from '@/gql/apollo';
import { OrderPointSetting } from '@/gql/docs';
import { formatAWSDate } from '@/shared/utils';
import { IdentifiedPatientContent } from './IdentifiedPatientContent';
import { MedicineOrderPointInfoPopover } from './MedicineOrderPointInfoPopover';
import { OrderPointForecastCaption } from './OrderPointForecastCaption';
import { StandardStockUnitQuantityContent } from './StandardStockUnitQuantityContent';
import { TextItemLabel, NumberItemLabel, DateItemLabel, AttentionItemLabel } from '../itemLabels';

type Props = {
  /** 医薬品基本情報 */
  medicine: MedicineSummaryTabQuery['medicines'][number];
  /** 発注点設定のモード */
  orderPointSettingMode: OrderPointSetting['mode'];
  /** レセコン入力単位での数量と単位 */
  convertedAdoptedMedicineUnitQuantities: MedicineSummaryTabQuery['adoptedMedicines']['edges'][number]['node']['convertedAdoptedMedicineUnitQuantities'];
} & Pick<
  MedicineSummaryTabQuery,
  | 'prescriptionFrequencySummary'
  | 'identifiedPatientListItems'
  | 'currentMonthMedicinePurchaseUnitQuantity'
  | 'orderPlans'
  | 'monthlyPrescribedUnitQuantities'
  | 'orderPointForecast'
>;

export function MedicineSummaryTabContent({
  medicine,
  prescriptionFrequencySummary,
  identifiedPatientListItems,
  currentMonthMedicinePurchaseUnitQuantity,
  orderPlans,
  monthlyPrescribedUnitQuantities,
  orderPointForecast,
  orderPointSettingMode,
  convertedAdoptedMedicineUnitQuantities,
}: Props) {
  const prescriptionFrequencyType = getPrescriptionFrequencyType({
    prescribedPatientsNumber: prescriptionFrequencySummary?.prescribedPatientsNumber,
    hasIdentifiedPatient: prescriptionFrequencySummary?.hasIdentifiedPatient,
  });
  const is発注点がマイナス =
    orderPointForecast?.unitQuantity !== undefined && orderPointForecast.unitQuantity < 0;

  return (
    <HStack spacing={5} alignItems="baseline">
      <Stack flex={1}>
        <Typography variant="body" fontWeight="bold">
          医薬品基本情報
        </Typography>
        <Stack
          bg="white"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="base"
          px={6}
          py={4}
        >
          <TextItemLabel label="YJコード" value={medicine.yjCode} />
          <NumberItemLabel label="薬価" value={medicine.standardUnitPrice} unitSymbol="円" />
          <DateItemLabel
            label="経過措置期限日付"
            value={medicine.transitionalMeasureExpireDate ?? undefined}
          />
          <AttentionItemLabel
            label="注意ラベル"
            value={{
              attentions: medicine.attentions,
              isNarcotic: medicine.isNarcotic,
              isStimulant: medicine.isStimulant,
              isPoison: medicine.isPoison,
              isColdPlace: medicine.isColdPlace,
              isRefrigeration: medicine.isRefrigeration,
              isFrozen: medicine.isFrozen,
            }}
          />
          <TextItemLabel
            label="店舗設定卸"
            value={medicine.primaryWholesale?.shortName ?? PRIMARY_WHOLESALE_NOT_CONFIGURED_TEXT}
          />
        </Stack>
      </Stack>
      <Stack flex={1} data-testid="在庫情報カラム">
        <Typography variant="body" fontWeight="bold">
          在庫情報
        </Typography>
        <Stack
          bg="white"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="base"
          spacing={3}
          px={6}
          py={4}
        >
          <Stack
            borderBottom="1px"
            borderColor="gray.200"
            spacing={0}
            pb={2}
            data-testid="在庫数量ブロック"
          >
            <Typography variant="body">在庫数量</Typography>
            <StockUnitQuantityWithSymbol
              variant="h1"
              quantity={medicine.stockUnitQuantity}
              stockUnitSymbol={medicine.stockUnitSymbol}
              spacing={0.5}
            />
            {!isNullOrUndefined(convertedAdoptedMedicineUnitQuantities) && (
              <ConvertedStockUnitQuantityWithSymbol
                fontSize="15px"
                convertedStockUnitQuantity={
                  convertedAdoptedMedicineUnitQuantities.stockUnitQuantity
                }
                convertedStockUnitSymbol={convertedAdoptedMedicineUnitQuantities.stockUnitSymbol}
              />
            )}
          </Stack>
          <Stack
            borderBottom="1px"
            borderColor="gray.200"
            spacing={0}
            pb={2}
            data-testid="発注点ブロック"
          >
            <HStack spacing={1}>
              <Typography variant="body">発注点</Typography>
              <MedicineOrderPointInfoPopover prescriptionFrequencyType={prescriptionFrequencyType}>
                <InfoIcon color="slate.500" boxSize={3} />
              </MedicineOrderPointInfoPopover>
            </HStack>
            <StockUnitQuantityWithSymbol
              variant="h1"
              quantity={orderPointForecast?.unitQuantity}
              stockUnitSymbol={medicine.stockUnitSymbol}
              spacing={0.5}
              dataTestId="発注点"
              showHyphenForQuantity={is発注点がマイナス}
            />
            {!isNullOrUndefined(orderPointForecast) &&
              !isNullOrUndefined(orderPointForecast.convertedUnitQuantities) && (
                <ConvertedStockUnitQuantityWithSymbol
                  fontSize="15px"
                  convertedStockUnitQuantity={
                    orderPointForecast.convertedUnitQuantities.unitQuantity
                  }
                  convertedStockUnitSymbol={
                    orderPointForecast.convertedUnitQuantities.stockUnitSymbol
                  }
                  dataTestId="レセコン変換発注点"
                  showHyphenForQuantity={
                    orderPointForecast.convertedUnitQuantities.unitQuantity < 0
                  }
                />
              )}
            {!is発注点がマイナス && (
              <OrderPointForecastCaption
                prescriptionFrequencyType={prescriptionFrequencyType}
                orderPointForecast={orderPointForecast}
                hasStockoutPreventionLabel={medicine.attentions.includes(
                  'stockoutPreventionTarget',
                )}
                orderPointSettingMode={orderPointSettingMode}
              />
            )}
          </Stack>
          <StandardStockUnitQuantityContent
            stockUnitSymbol={medicine.stockUnitSymbol}
            prescriptionFrequencyType={prescriptionFrequencyType}
            orderPointForecast={orderPointForecast}
          />
          <IdentifiedPatientContent
            stockUnitSymbol={medicine.stockUnitSymbol}
            prescriptionFrequencyType={prescriptionFrequencyType}
            identifiedPatients={identifiedPatientListItems}
          />
        </Stack>
      </Stack>
      <Stack flex={1} spacing={4}>
        <Stack>
          <Typography variant="body" fontWeight="bold">
            発注情報
          </Typography>
          <Stack
            bg="white"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="base"
            spacing={3}
            px={6}
            py={4}
          >
            <Stack borderBottom="1px" borderColor="gray.200" spacing={0} pb={2}>
              <HStack spacing={1}>
                <Typography variant="body">今月の購入数量</Typography>
                <InfoPopover content="納品＋手動調整 (小分け) ＋手動調整 (法人内) ＋手動調整 (法人間)">
                  <InfoIcon color="slate.500" boxSize={3} />
                </InfoPopover>
              </HStack>
              <StockUnitQuantityWithSymbol
                variant="h1"
                quantity={currentMonthMedicinePurchaseUnitQuantity}
                stockUnitSymbol={medicine.stockUnitSymbol}
                spacing={0.5}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="body">発注予約</Typography>
              {orderPlans.edges.length === 0 ? (
                <Typography variant="h1" textAlign="end">
                  -
                </Typography>
              ) : (
                orderPlans.edges.map(({ node }) => (
                  <HStack key={node.id} justify="space-between" alignItems="start">
                    <Box>
                      <Typography variant="body">
                        {formatAWSDate(node.targetDate, { showDayOfWeek: true })}
                      </Typography>
                      <Typography variant="body2" color="slate.500" lineHeight={1}>
                        {node.wholesale.shortName}
                      </Typography>
                    </Box>
                    <StockUnitQuantityWithSymbol
                      variant="h2"
                      quantity={round(
                        node.boxQuantity * node.medicinePackageUnit.oneBoxQuantity,
                        3,
                      )}
                      stockUnitSymbol={medicine.stockUnitSymbol}
                      spacing={0.5}
                    />
                  </HStack>
                ))
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="body" fontWeight="bold">
            毎月の処方量実績
          </Typography>
          <Stack
            bg="white"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="base"
            px={6}
            py={4}
          >
            <Stack spacing={0.5}>
              {monthlyPrescribedUnitQuantities.map(({ yearMonth, unitQuantity }) => (
                <HStack key={yearMonth} justify="space-between">
                  <Typography variant="body">
                    {format(parseISO(yearMonth), 'yyyy年MM月')}
                  </Typography>
                  <StockUnitQuantityWithSymbol
                    variant="h2"
                    quantity={unitQuantity}
                    stockUnitSymbol={medicine.stockUnitSymbol}
                    spacing={0.5}
                  />
                </HStack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </HStack>
  );
}
