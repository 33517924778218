import { createIcon } from '@chakra-ui/react';

export const FewPrescriptionFrequencyIcon = createIcon({
  displayName: 'FewPrescriptionFrequencyIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 8C13 10.2091 11.2091 12 9 12C6.79086 12 5 10.2091 5 8C5 5.79086 6.79086 4 9 4C11.2091 4 13 5.79086 13 8ZM16 20H9H2C2 15 4 14 9 14C14 14 16 15 16 20ZM14.6681 9.97261C14.4385 10.6325 14.0968 11.24 13.666 11.7722C14.0832 11.9197 14.5321 12 14.9998 12C17.209 12 18.9998 10.2091 18.9998 8C18.9998 5.79086 17.209 4 14.9998 4C14.5321 4 14.0832 4.08027 13.666 4.22778C14.0968 4.76005 14.4385 5.3675 14.6681 6.02739C14.776 6.00937 14.8868 6 14.9998 6C16.1044 6 16.9998 6.89543 16.9998 8C16.9998 9.10457 16.1044 10 14.9998 10C14.8868 10 14.776 9.99062 14.6681 9.97261ZM10 20.0003H9H8V20.0003H10V20.0003ZM17.6708 16.1676C19.4763 16.5057 20 17.4674 20 20.0003H22C22 15.6397 20.4788 14.3215 16.7729 14.0557C17.1817 14.6066 17.4742 15.2993 17.6708 16.1676Z"
      fill="currentColor"
    />
  ),
});
