import { Box, Flex, HStack, ModalHeader, Stack } from '@chakra-ui/react';
import { AdoptionStoppedIcon, NumberText, Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined, round } from '@kkhs/hakari-utils';
import {
  MedicineTypeIcon,
  StockUnitQuantityWithSymbol,
  ConvertedStockUnitQuantityWithSymbol,
  StockUnitSymbolIcon,
} from '@/entities/medicine';
import { FragmentType, getFragment, graphql } from '@/gql';
import { formatAWSDate } from '@/shared/utils';

const fragmentDoc = graphql(`
  fragment AdoptedMedicalModalHeader_AdoptedMedicine on AdoptedMedicine {
    id
    medicine {
      id
      pharmacyId
      medicineName
      medicineType
      stockUnitQuantity
      stockUnitSymbol
      adoptionStoppedDate
      usageGroup {
        id
        unitOutline
        # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
        shortUnitOutline
      }
    }
    # 置き場所
    storages {
      id
      name
    }
    # 同 YJ コードの医薬品の合計在庫数量
    stockUnitQuantityByYjCode

    # レセコン入力単位での数量と単位
    convertedAdoptedMedicineUnitQuantities {
      # レセコン入力単位(単位)
      stockUnitSymbol
      # 在庫数（YJコード+需要タイプごとの在庫数の合計）
      stockUnitQuantity
    }
  }
`);

type Props = {
  fragment?: FragmentType<typeof fragmentDoc>;
};

export function AdoptedMedicalModalHeader({ fragment: _fragment }: Props) {
  const fragment = getFragment(fragmentDoc, _fragment);
  return (
    <ModalHeader p={0} borderBottom="1px" borderColor="gray.200">
      <HStack px={6} py={4} justifyContent="space-between" alignItems="flex-start">
        <Flex alignItems="center" justifyContent="flex-start">
          <MedicineTypeIcon
            color="primary.500"
            type={fragment?.medicine.medicineType}
            h={12}
            w={12}
          />
          <Stack spacing={1} pl={2}>
            <HStack alignItems="flex-start">
              <Typography data-testid="医薬品名" variant="h3">
                {fragment?.medicine.medicineName}
              </Typography>
              {fragment?.medicine.adoptionStoppedDate && (
                <Box bg="red.50" px={2} py={0.5} borderRadius="base">
                  <Flex alignItems="center">
                    <AdoptionStoppedIcon color="red.500" mr={0.5} boxSize="18px" />
                    <Typography variant="body2" color="red.500" whiteSpace="nowrap">
                      採用停止
                    </Typography>
                    <Typography variant="body" color="red.500" ml={1}>
                      {`${formatAWSDate(fragment.medicine.adoptionStoppedDate)}〜`}
                    </Typography>
                  </Flex>
                </Box>
              )}
            </HStack>
            <Typography data-testid="需要タイプ" variant="body2" color="slate.500">
              {fragment?.medicine.usageGroup.shortUnitOutline}
            </Typography>
          </Stack>
        </Flex>
        <Stack w="252px" spacing={0} borderLeftWidth={1} pl={4}>
          {!isNullOrUndefined(fragment) && !isNullOrUndefined(fragment.medicine) && (
            <HStack justify="space-between">
              <Typography variant="body2" color="slate.500" fontWeight="normal">
                在庫数量
              </Typography>
              <HStack spacing={2}>
                <StockUnitQuantityWithSymbol
                  variant="h2"
                  quantity={fragment.medicine.stockUnitQuantity}
                  stockUnitSymbol={fragment.medicine.stockUnitSymbol}
                  spacing={0.5}
                />
                {!isNullOrUndefined(fragment) &&
                  !isNullOrUndefined(fragment.convertedAdoptedMedicineUnitQuantities) && (
                    <ConvertedStockUnitQuantityWithSymbol
                      fontSize="15px"
                      fontWeight="initial"
                      convertedStockUnitQuantity={
                        fragment.convertedAdoptedMedicineUnitQuantities.stockUnitQuantity
                      }
                      convertedStockUnitSymbol={
                        fragment.convertedAdoptedMedicineUnitQuantities.stockUnitSymbol
                      }
                    />
                  )}
              </HStack>
            </HStack>
          )}
          {!isNullOrUndefined(fragment) && (
            <HStack justify="space-between">
              <Typography variant="body2" color="slate.300" fontWeight="normal" lineHeight="14px">
                同一YJコードの合計在庫数量
              </Typography>
              <HStack spacing={1}>
                <NumberText color="slate.300" fontWeight="normal" size="xs" lineHeight="14px">
                  {round(fragment.stockUnitQuantityByYjCode, 3)}
                </NumberText>
                <StockUnitSymbolIcon
                  name={fragment.medicine.stockUnitSymbol}
                  color="slate.500"
                  position="relative"
                  top="1px"
                  boxSize={4}
                />
              </HStack>
            </HStack>
          )}
        </Stack>
      </HStack>
    </ModalHeader>
  );
}
