import type { CurrentPharmacy } from '@/entities/currentPharmacy';

export type IFeatureFlagRepository = {
  useSetupFeatureFlag: IFeatureFlagRepository.UseSetupFeatureFlag;
  useFlags: IFeatureFlagRepository.UseFlags;
  Provider: IFeatureFlagRepository.Provider;
};

/**
 * ファルマ促進バナーの種類を定義する
 */
export const pharmarketPromotionInfobarOptions = {
  disabled: 'disabled',
  standard: 'standard',
  medicinePriceChangeCampaign: 'medicine_price_change_campaign',
  imageOnly: 'image_only',
} as const;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace IFeatureFlagRepository {
  export type Context = { pharmacy: CurrentPharmacy };
  export type ContextSetter = (context: Context) => void;
  export type UseSetupFeatureFlag = () => { setContext: ContextSetter; setupDone: boolean };

  export type PharmarketPromotionInfobarOption =
    (typeof pharmarketPromotionInfobarOptions)[keyof typeof pharmarketPromotionInfobarOptions];

  /**
   * hakari-frontend内で使われるフラグを定義する
   */
  export type FlagSet = {
    appMinimumVersion?: string;
    isEnableMaintenanceMode?: boolean;
    tmpChangePrescriptionForecastDateEnabled?: boolean;
    tmpDataImportEnabled?: boolean;
    tmpEstimateDeliveryPriceEnabled?: boolean;
    tmpManageStockEnabled?: boolean;
    tmpPharmacySettingEnabled?: boolean;
    tmpPreMadeEnabled?: boolean;
    tmpReportEnabled?: boolean;
    tmpWholesaleSettingEnabled?: boolean;
    tmpClientCertificateEnabled?: boolean;
    tmpEditMedicineTransactionsEnabled?: boolean;
    tmpTradingSummaryByWholesaleEnabled?: boolean;
    tmpPharmarketInfobar?: PharmarketPromotionInfobarOption;
    tmpProposedWholesaleEnabled?: boolean;
    tmpShippingStockDialogV2Enabled?: boolean;
    tmpOnboardingPageEnabled?: boolean;
    tmpHideOrderProposalEditInfoEnabled: boolean;
    tmpSuzukenModelTargetFilterEnabled: boolean;
    tmpStoringStockDialogV2Enabled: boolean;
  };
  export const DefaultFlagSet: { [K in keyof FlagSet]-?: FlagSet[K] } = {
    appMinimumVersion: '2023.01.01.1',
    isEnableMaintenanceMode: false,
    tmpChangePrescriptionForecastDateEnabled: false,
    tmpDataImportEnabled: false,
    tmpEstimateDeliveryPriceEnabled: false,
    tmpManageStockEnabled: false,
    tmpPharmacySettingEnabled: false,
    tmpPreMadeEnabled: false,
    tmpReportEnabled: false,
    tmpWholesaleSettingEnabled: false,
    tmpClientCertificateEnabled: false,
    tmpEditMedicineTransactionsEnabled: false,
    tmpTradingSummaryByWholesaleEnabled: false,
    tmpPharmarketInfobar: 'disabled',
    tmpProposedWholesaleEnabled: false,
    tmpShippingStockDialogV2Enabled: false,
    tmpOnboardingPageEnabled: false,
    tmpHideOrderProposalEditInfoEnabled: false,
    tmpSuzukenModelTargetFilterEnabled: false,
    tmpStoringStockDialogV2Enabled: false,
  };
  export type UseFlags = () => { [K in keyof FlagSet]-?: FlagSet[K] };

  export type Provider = React.FC<{ children: JSX.Element }>;
}
