import { HStack, Stack } from '@chakra-ui/react';
import { Typography, ErrorTooltip, NumberTextInput } from '@kkhs/hakari-ui';
import { FieldPath, FieldValues, UseFormReturn, useController } from 'react-hook-form';
import { MAX_FEE_AMOUNT } from '../../constants';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isDisabled?: boolean;
  control: UseFormReturn<T>['control'];
};

/**
 * 手数料フォームコンポーネント
 */
export function FeeAmountForm<T extends FieldValues>({
  name,
  isDisabled = false,
  control,
}: Props<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });
  return (
    <Stack spacing={1}>
      <Typography variant="body" fontWeight="bold">
        手数料
      </Typography>
      <HStack spacing={1}>
        <ErrorTooltip label={error?.message} isOpen={!!error?.message} placement="bottom-start">
          <NumberTextInput
            size="sm"
            w="80px"
            value={value ?? undefined}
            min={0}
            max={MAX_FEE_AMOUNT}
            onChange={onChange}
            isDisabled={isDisabled}
          />
        </ErrorTooltip>
        <Typography variant="body">円</Typography>
      </HStack>
    </Stack>
  );
}
