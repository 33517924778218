import { FormControl, FormLabel, HStack } from '@chakra-ui/react';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import {
  ExternalCounterpartyAndOrderableWholesaleSelect,
  ExternalCounterpartyAndOrderableWholesaleSelectOption,
} from '../ExternalCounterpartyAndOrderableWholesaleSelect';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  isDisabled?: boolean;
  onChange: (value: ExternalCounterpartyAndOrderableWholesaleSelectOption) => void;
  onAfterClear: () => void;
  onAfterCreate: (newValue: string) => void;
  helperElement: React.ReactElement | undefined;
  noOptionsMessage: string | undefined;
  formLabelRightElement: React.ReactElement;
  defaultValue: ExternalCounterpartyAndOrderableWholesaleSelectOption | null;
} & Pick<UseFormReturn<T>, 'control'>;

export function ExternalCounterpartyAndOrderableWholesaleSelectForm<T extends FieldValues>({
  name,
  label,
  isDisabled = false,
  onChange: passedOnChange,
  onAfterClear,
  onAfterCreate,
  helperElement,
  noOptionsMessage = '',
  formLabelRightElement,
  control,
  defaultValue,
}: Props<T>) {
  return (
    <FormControl w="240px">
      <HStack justifyContent="space-between">
        <FormLabel fontWeight="bold">{label}</FormLabel>
        {formLabelRightElement}
      </HStack>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <div data-testid="external-counterparty-and-orderable-wholesale-select">
            <ExternalCounterpartyAndOrderableWholesaleSelect
              defaultValue={
                defaultValue
                  ? {
                      label: defaultValue.name,
                      value: defaultValue,
                    }
                  : undefined
              }
              size="sm"
              placeholder="-"
              noOptionsMessage={noOptionsMessage}
              onChange={passedOnChange}
              onClear={() => {
                onChange(null);
                onAfterClear();
              }}
              onCreate={(newValue) => {
                onChange(null);
                onAfterCreate(newValue);
              }}
              isDisabled={isDisabled}
            />
          </div>
        )}
      />
      {helperElement}
    </FormControl>
  );
}
