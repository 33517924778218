import { z } from 'zod';
import {
  counterpartyAddressSchema,
  counterpartyBuildingNameSchema,
  counterpartyFaxNumberSchema,
  counterpartyNameSchema,
  counterpartyPharmacyLicenseNumberSchema,
  counterpartyPhoneNumberSchema,
  counterpartyZipCodeSchema,
  roundingMethodSchema,
  taxCalculationTargetSchema,
} from '@/entities/counterparty';
import {
  containerAmountSchema,
  feeAmountSchema,
  slipTitleSchema,
} from '@/entities/stockOperationPriceConfig';

export const createExternalCounterpartySchema = z.object({
  name: counterpartyNameSchema,
  zipCode: counterpartyZipCodeSchema,
  address: counterpartyAddressSchema,
  buildingName: counterpartyBuildingNameSchema,
  phoneNumber: counterpartyPhoneNumberSchema,
  faxNumber: counterpartyFaxNumberSchema,
  pharmacyLicenseNumber: counterpartyPharmacyLicenseNumberSchema,
  shouldShowTax: z.union([z.literal('true'), z.literal('false')]),
  totalRoundingMethod: roundingMethodSchema,
  itemRoundingMethod: roundingMethodSchema.or(z.literal('none')),
  taxRoundingMethod: roundingMethodSchema.or(z.literal('none')),
  defaultPriceRatePercent: z
    .number({ required_error: '必須の入力項目です' })
    .min(0, '出庫額算出率には0から100の値を入力してください')
    .max(100, '出庫額算出率には0から100の値を入力してください'),
  slipTitle: slipTitleSchema,
  taxCalculationTarget: taxCalculationTargetSchema.nullable(),
  containerAmount: containerAmountSchema.optional(),
  containerAmountEnabled: z.boolean(),
  feeAmount: feeAmountSchema.optional(),
  feeAmountEnabled: z.boolean(),
  /** 総額端数処理が非活性かどうか */
  isTotalRoundingMethodDisabled: z.boolean(),
});
export type CreateExternalCounterpartyFieldValues = z.infer<
  typeof createExternalCounterpartySchema
>;
