import { Skeleton, Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { ShippingStockOperationDeleteDialogQuery } from '@/gql/docs';

type Props = {
  counterpartyName:
    | Extract<
        ShippingStockOperationDeleteDialogQuery['stockOperation'],
        { __typename: 'ShippingStockOperationByExternalTrade' }
      >['counterpartyName']
    | undefined;
  isLoaded?: boolean;
};

export function StockOperationShippingCounterpartyNameLabel({
  counterpartyName,
  isLoaded = true,
}: Props) {
  return (
    <Stack w="300px" spacing={1}>
      <Typography variant="body" fontWeight="bold">
        出庫先
      </Typography>
      <Skeleton isLoaded={isLoaded}>
        <Typography variant="body">{counterpartyName ?? '-'}</Typography>
      </Skeleton>
    </Stack>
  );
}
