import { createIcon } from '@chakra-ui/react';

export const OintmentForDispensingIcon = createIcon({
  displayName: 'OintmentForDispensingIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.467 8.75a.738.738 0 00-.717.717v3.163h22.467V9.467a.738.738 0 00-.718-.717H9.467zm-2.217.717A2.238 2.238 0 019.467 7.25H30.5a2.238 2.238 0 012.218 2.217v3.73a.933.933 0 01-.266.668.933.933 0 01-.668.265h-.106v15.636c0 1.305-.987 2.584-2.4 2.584H10.75c-1.398 0-2.4-1.201-2.4-2.584V14.13h-.168a.933.933 0 01-.667-.265.933.933 0 01-.266-.668v-3.73zm2.6 4.663h20.327v15.636c0 .651-.48 1.084-.9 1.084H10.75c-.435 0-.9-.389-.9-1.084v-1.268h9.232V15.626H9.85V14.13zm0 2.996v9.872h7.733v-9.872H9.85z"
      fill="currentColor"
    />
  ),
});
