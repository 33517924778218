import { ComponentProps } from 'react';
import { ShippingStockOperationDeleteFooterLayout } from './ShippingStockOperationDeleteFooterLayout';

type Props = Omit<ComponentProps<typeof ShippingStockOperationDeleteFooterLayout>, 'loading'>;

export function LoadingShippingStockOperationDeleteFooter({ onClose, cancelRef }: Props) {
  return (
    <ShippingStockOperationDeleteFooterLayout onClose={onClose} cancelRef={cancelRef} loading />
  );
}
