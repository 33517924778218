import { createIcon } from '@chakra-ui/react';

export const FnPatientIcon = createIcon({
  displayName: 'FnPatientIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM10 15C10 14.6792 10.0189 14.3629 10.0556 14.0521C9.4296 14.0154 8.7457 14 8 14C2 14 0 15 0 20H8H11.7546C10.6567 18.6304 10 16.8919 10 15Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15C12 11.6863 14.6863 9 18 9C21.3137 9 24 11.6863 24 15C24 18.3137 21.3137 21 18 21C14.6863 21 12 18.3137 12 15ZM19 10.5V16H17V10.5H19ZM19 19.5V17.5H17V19.5H19Z"
        fill="#D63E29"
      />
    </>
  ),
});
