import { Td, Wrap, WrapItem, Link } from '@chakra-ui/react';
import { Row } from '../../types';

const transferStatusMapper = {
  ownTake: {
    label: '小分け(進行中あり)',
    href: '/stock/transfer-requests/?tab=0',
  },
  rejectedOwnTake: {
    label: '小分け(融通不可あり)',
    href: '/stock/transfer-requests/?tab=0',
  },
  othersTake: {
    label: '他店の小分け(進行中あり)',
    href: '/stock/transfer-requests/?tab=1',
  },
  rejectedOthersTake: {
    label: '他店の小分け(融通不可あり)',
    href: '/stock/transfer-requests/?tab=1',
  },
  ownGive: {
    label: '引き取り(進行中あり)',
    href: '/stock/transfer-requests/?tab=2',
  },
  rejectedOwnGive: {
    label: '引き取り(融通不可あり)',
    href: '/stock/transfer-requests/?tab=2',
  },
  othersGive: {
    label: '他店の引き取り(進行中あり)',
    href: '/stock/transfer-requests/?tab=3',
  },
  rejectedOthersGive: {
    label: '他店の引き取り(融通不可あり)',
    href: '/stock/transfer-requests/?tab=3',
  },
  unstored: {
    label: '法人内入庫(計上待ちあり)',
    href: '/stock/transfer-requests/trading/?tab=0',
  },
  unshipped: {
    label: '法人内出庫(出庫待ちあり)',
    href: '/stock/transfer-requests/trading/?tab=1',
  },
};

export function TransferStatusLinkCell({ transferRequestStatusLabels }: Row) {
  return (
    <Td pl={0} pr={1} style={{ paddingTop: 4 }} colSpan={8} borderTopWidth={0} isNumeric>
      <Wrap justify="flex-end" spacingX={3} spacingY={1}>
        {transferRequestStatusLabels.map((transferRequestStatusLabel) => {
          const transferStatus = transferStatusMapper[transferRequestStatusLabel];
          if (!transferStatus) return null;
          return (
            <WrapItem key={transferRequestStatusLabel}>
              <Link
                href={transferStatus.href}
                color="slate.500"
                textDecoration="underline"
                target="_blank"
              >
                {transferStatus.label}
              </Link>
            </WrapItem>
          );
        })}
      </Wrap>
    </Td>
  );
}
