import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { recoilAtomKeyPrefix, recoilSelectorKeyPrefix } from '@/shared/constants';

/** 医薬品の採用設定タブのインデックス */
export const ADOPTED_MEDICAL_PRODUCT_MODAL_TABS = [
  {
    key: 'MEDICINE_SUMMARY',
    value: '概要',
  },
  {
    key: 'MEDICINE_DETAIL',
    value: '医薬品情報',
  },
  {
    key: 'PATIENTS',
    value: '処方患者',
  },
  {
    key: 'OTHER_PHARMACY_STOCKS',
    value: '他店舗在庫',
  },
  {
    key: 'MEDICINE_TRANSACTIONS',
    value: '入出庫履歴',
  },
  {
    key: 'MEDICINE_EDIT',
    value: '医薬品の採用設定',
  },
] as const;
export type AdoptedMedicalProductModalTabKey =
  (typeof ADOPTED_MEDICAL_PRODUCT_MODAL_TABS)[number]['key'];

type AdoptedMedicalProductModalState = {
  /** 採用薬モーダルの開閉状態 */
  isOpen: boolean;
  /** 採用薬モーダルの対象 medicineId */
  medicineId: string | undefined;
  /** 採用薬モーダルを開いた状態が必須買い画面かどうか */
  isMustBuy?: boolean;
  /** 採用薬モーダルの初期に開くタブのキー */
  defaultTabKey?: AdoptedMedicalProductModalTabKey;
};

type AdoptedMedicalProductModalSelectors = {
  useValue: () => AdoptedMedicalProductModalState;
};

type AdoptedMedicalProductModalActions = {
  /** 医薬品モーダルを開くアクションを返す */
  useOpen: () => (openParams: OpenParams) => void;
  /** 医薬品モーダルを閉じるアクションを返す */
  useClose: () => () => void;
};

type OpenParams = {
  medicineId: string;
  isMustBuy?: boolean;
  defaultTabKey?: AdoptedMedicalProductModalTabKey;
};

const adoptedMedicalProductModalState = atom<AdoptedMedicalProductModalState>({
  key: `${recoilAtomKeyPrefix.ADOPTED_MEDICAL_PRODUCT}/modal`,
  default: {
    isOpen: false,
    medicineId: undefined,
  },
});

const adoptedMedicalProductModalSelector = selector<AdoptedMedicalProductModalState>({
  key: `${recoilSelectorKeyPrefix.ADOPTED_MEDICAL_PRODUCT}/modal`,
  get: ({ get }) => get(adoptedMedicalProductModalState),
});

export const adoptedMedicalProductModalSelectors: AdoptedMedicalProductModalSelectors = {
  useValue: () => useRecoilValue(adoptedMedicalProductModalSelector),
};

export const adoptedMedicalProductModalActions: AdoptedMedicalProductModalActions = {
  useOpen: () => {
    const setRecoilState = useSetRecoilState(adoptedMedicalProductModalState);
    return useCallback(
      ({ medicineId, isMustBuy, defaultTabKey = 'MEDICINE_SUMMARY' }: OpenParams) => {
        setRecoilState((prev) => ({
          ...prev,
          isOpen: true,
          medicineId,
          isMustBuy,
          defaultTabKey,
        }));
      },
      [setRecoilState],
    );
  },

  useClose: () => {
    const [{ medicineId }, setRecoilState] = useRecoilState(adoptedMedicalProductModalState);
    const apolloClient = useApolloClient();
    return useCallback(() => {
      setRecoilState((prev) => ({ ...prev, isOpen: false, medicineId: undefined }));
      // NOTE:
      //   医薬品モーダル上でタブを切り替えた際には refetch したくないので、fetchPolicy を cache-first にしている。
      //   （fetchPolicy を cache-and-network にすると、 タブ切り替え時にページネーションのリクエストが実行されるケースがあり体験が悪い。）
      //   そのため、タブを閉じて再度開いても cache 利用する形になる。タブを開き直した際には、最新のデータを取り直したいため、
      //   ダイアログを閉じるタイミングで cache を削除する。
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'patientByMedicineListItems' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'medicineTransactionListItems' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'medicines' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'prescriptionFrequencySummary' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'identifiedPatientListItems' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'othersMedicineStockListItems' });
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'medicineOrders',
        args: {
          filter: {
            medicineId,
            statusList: ['waiting', 'preProcessing', 'processing'],
          },
        },
      });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'monthlyPrescribedUnitQuantities' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'orderPointForecast' });
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'currentMonthMedicinePurchaseUnitQuantity',
      });
    }, [apolloClient.cache, medicineId, setRecoilState]);
  },
};
