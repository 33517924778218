import { TableCellProps, Td } from '@chakra-ui/react';

export function CellBase({ children, ...rest }: TableCellProps) {
  return (
    <Td
      pl={1}
      pr={1}
      verticalAlign="top"
      style={{ paddingBottom: 0 }}
      borderBottomWidth={0}
      {...rest}
    >
      {children}
    </Td>
  );
}
