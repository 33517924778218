import { forwardRef } from 'react';
import { ForwardRefType } from '../types';

export const ChevronDownIcon: ForwardRefType<'svg'> = forwardRef(
  ({ color = 'currentColor', ...rest }, ref) => (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      style={{ ...rest.style, flexShrink: 0 }}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 14L18 8L20 10L11.9999 18L4 10L6 8L11.9999 14Z"
        fill={color}
      />
    </svg>
  ),
);

ChevronDownIcon.displayName = 'ChevronDownIcon';
