import { setContext } from '@apollo/client/link/context';

/**
 * ログイン薬局をセットする Apollo Link
 */
export const createCurrentPharmacyLink = (pharmacyId?: string) =>
  setContext((_, { headers = {} }) => {
    if (pharmacyId === undefined) {
      return { headers };
    }
    return {
      headers: {
        ...headers,
        'x-pharmacy-id': pharmacyId,
      },
    };
  });
