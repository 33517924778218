import { createIcon } from '@chakra-ui/react';

export const FilmUnitSymbolIcon = createIcon({
  displayName: 'Film',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.51 10.3799C11.6491 8.85848 12.2474 7.00018 12.21 5.09994H13.44V4.31994H8.91002C9.09463 3.76438 9.22195 3.1914 9.29002 2.60994V2.49994L8.47002 2.43994V2.52994C8.26572 4.23075 7.59809 5.84274 6.54002 7.18994V7.24994L7.00002 7.91994L7.08002 7.82994C7.37666 7.48351 7.63795 7.10831 7.86002 6.70994C8.14762 8.00232 8.67323 9.22989 9.41002 10.3299C8.48358 11.2424 7.37179 11.9449 6.15002 12.3899H6.06002L6.39002 13.1999H6.48002C7.792 12.7161 8.99024 11.9672 10 10.9999C10.8201 11.949 11.8462 12.6981 13 13.1899H13.09L13.51 12.3899H13.4C12.284 11.9716 11.2905 11.2806 10.51 10.3799ZM10 9.68994C9.21808 8.47213 8.68137 7.11339 8.42002 5.68994C8.50002 5.50994 8.58002 5.31994 8.66002 5.11994H11.42C11.4562 6.75716 10.9577 8.36155 10 9.68994V9.68994ZM6.45002 9.32994C6.06214 8.83627 5.72698 8.30337 5.45002 7.73994V13.1999H4.65002V7.64994C4.25875 8.70174 3.70171 9.68416 3.00002 10.5599V10.6699L2.52002 9.86994C3.49673 8.59143 4.21355 7.134 4.63002 5.57994H2.69002V4.81994H4.69002V2.44994H5.49002V4.81994H7.12002V5.57994H5.46002V6.18994C5.86251 7.11229 6.40192 7.9686 7.06002 8.72994L7.12002 8.79994L6.51002 9.41994L6.45002 9.32994Z"
        fill="currentColor"
      />
    </svg>
  ),
});
