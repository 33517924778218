import { createIcon } from '@chakra-ui/react';

export const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 14 14',
  path: (
    <path
      d="M4 0.25C3.58579 0.25 3.25 0.585786 3.25 1V1.75H2.25C1.14543 1.75 0.25 2.64543 0.25 3.75V4.75H13.75V3.75C13.75 2.64543 12.8546 1.75 11.75 1.75H10.75V1C10.75 0.585786 10.4142 0.25 10 0.25C9.58579 0.25 9.25 0.585786 9.25 1V1.75H4.75V1C4.75 0.585786 4.41421 0.25 4 0.25ZM1.75 6.25H0.25V12.25C0.25 13.0784 0.921573 13.75 1.75 13.75H12.25C13.0784 13.75 13.75 13.0784 13.75 12.25V6.25H12.25V12.25H1.75V6.25ZM3.25 7.75V6.25H4.75V7.75H3.25ZM7.75 6.25H6.25V7.75H7.75V6.25ZM10.75 6.25V7.75H9.25V6.25H10.75ZM4.75 10.75V9.25H3.25V10.75H4.75ZM6.25 9.25H7.75V10.75H6.25V9.25ZM9.25 9.25V10.75H10.75V9.25H9.25Z"
      fill="currentColor"
    />
  ),
});
