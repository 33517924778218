export const reactBigCalendarStyle = {
  /* 全体のstyle */
  '.rbc-month-view': {
    border: 'none',
  },
  '.rbc-off-range-bg': {
    background: '#fafafa',
  },
  /* 曜日表示箇所 */
  '.rbc-month-header': {
    borderTop: '1px solid #d7dae7',
    borderBottom: '1px solid #d7dae7',
    padding: '8px 0',
  },
  '.rbc-header': {
    border: 'none',
    '& + .rbc-header': {
      borderLeft: 'none',
    },
    p: {
      fontSize: '11px',
      fontWeight: 'normal',
    },
  },
  /* 日付 */
  '.rbc-date-cell': {
    paddingTop: '5px',
    display: 'flex',
    justifyContent: 'flex-end',
    p: {
      fontSize: '12px',
    },
  },
  '.rbc-today': {
    backgroundColor: '#f7fcfc',
  },
  /*
    名前
    各イベントを表示する行。この行の高さで1セルに表示可能な件数が変わる。
  */
  '.rbc-row-segment': {
    padding: '0 8px 1px 8px',
    height: '1rem',
  },
  /* read more */
  '.rbc-show-more': {
    fontSize: '11px',
    fontWeight: 'normal',
    color: 'slate.700',
    textDecoration: 'underline',

    '&:hover, &:focus': {
      color: 'slate.700',
    },
  },

  /* popup */
  '.rbc-overlay': {
    maxHeight: '240px',
    overflow: 'auto',
  },
};
