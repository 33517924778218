import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

/**
 * 画面解像度を Datadog にロギングするカスタムフック
 */
export function useDisplaySizeLogger() {
  // NOTE: resize 時に呼び出される回数を抑えるために、debounced させています
  const handleLog = useDebouncedCallback(() => {
    if (typeof window === 'undefined') return;
    datadogLogs.logger.log(
      'Window and Display Information',
      {
        screen: {
          width: window.screen.width,
          height: window.screen.height,
        },
        inner: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      },
      'info',
    );
  }, 1000);

  useEffect(() => {
    // NOTE: 初期ロード時の計測
    handleLog();

    // NOTE: 表示サイズが変更されたときの計測
    window.addEventListener('resize', handleLog);
    return () => {
      window.removeEventListener('resize', handleLog);
    };
  }, [handleLog]);
}
