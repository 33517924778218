import { StoringStockOperationTypename } from '@/entities/stockOperation';
import { TransactionReason } from '@/gql/docs';

/**
 * 入庫区分
 */
type StoringReason = 'subdividedPurchase' | 'transfer' | 'tradeWithCompany' | 'other';

export function toStoringReason(
  typename: StoringStockOperationTypename,
): Extract<TransactionReason, StoringReason> {
  switch (typename) {
    // 小分け
    case 'StoringStockOperationBySubdividedPurchase':
      return 'subdividedPurchase';
    // 法人内
    case 'StoringStockOperationByTransfer':
      return 'transfer';
    // 法人内(依頼ベース)
    case 'StoringStockOperationByTransferRequest':
      return 'transfer';
    // 法人間
    case 'StoringStockOperationByExternalTrade':
      return 'tradeWithCompany';
    // その他
    case 'StoringStockOperationByOther':
      return 'other';
    default:
      throw new Error(`unknown typename: ${typename}`);
  }
}
