import { HStack } from '@chakra-ui/react';
import { DynamicSizedNumberText, Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { calculateSubTotalAmount } from '@/entities/stockOperation';
import { UpdateStoringStockOperationFieldValues } from '../../schema';

type Props = {
  /** 単位薬価 */
  unitPrice: number | null;
  /** 入庫数量 */
  stockUnitQuantity: number | null;
  /** 入庫金額処理設定 */
  stockOperationPriceConfig: UpdateStoringStockOperationFieldValues['stockOperationPriceConfig'];
  /** 閲覧者が管理者権限かどうか */
  isManager: boolean;
};

/**
 * 入庫金額
 * - 管理者権限の場合: 入庫金額を表示する
 * - 一般権限の場合: 入庫原価非表示のため入庫金額は計算不可(ハイフンを表示する)
 */
export function SubTotalAmount({
  unitPrice,
  stockUnitQuantity,
  stockOperationPriceConfig,
  isManager,
}: Props) {
  const subTotalAmount = calculateSubTotalAmount({
    unitPrice,
    stockUnitQuantity,
    stockOperationPriceConfig,
  });

  if (!isManager) {
    return <Typography variant="body2">-</Typography>;
  }
  return (
    <HStack spacing={1}>
      {!isNullOrUndefined(subTotalAmount) ? (
        <DynamicSizedNumberText variant="body" mapping={{ 13: '15px', 14: '11px' }}>
          {subTotalAmount}
        </DynamicSizedNumberText>
      ) : (
        <Typography variant="body2">-</Typography>
      )}
      <Typography variant="body2">円</Typography>
    </HStack>
  );
}
