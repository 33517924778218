import { createIcon } from '@chakra-ui/react';

export const OrderIcon = createIcon({
  displayName: 'OrderIcon',
  viewBox: '0 0 60 60',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2951 9C21.2766 9 19.7106 10.1556 19.107 11.8852H14.1147C13.5625 11.8852 13.1147 12.333 13.1147 12.8852V15.0327H10.9672C9.36575 15.0327 8 16.3985 8 17.9999V48.0327C8 49.6342 9.36575 50.9999 10.9672 50.9999H35.7541C37.3555 50.9999 38.7213 49.6342 38.7213 48.0327V17.9999C38.7213 16.3985 37.3555 15.0327 35.7541 15.0327H33.6065V12.8852C33.6065 12.333 33.1588 11.8852 32.6065 11.8852H27.6085C26.9702 10.1273 25.2777 9 23.2951 9ZM33.6065 17.0327V18.2623C33.6065 18.8146 33.1588 19.2623 32.6065 19.2623H14.1147C13.5625 19.2623 13.1147 18.8146 13.1147 18.2623V17.0327H10.9672C10.4703 17.0327 10 17.503 10 17.9999V48.0327C10 48.5296 10.4703 48.9999 10.9672 48.9999H35.7541C36.251 48.9999 36.7213 48.5296 36.7213 48.0327V17.9999C36.7213 17.503 36.251 17.0327 35.7541 17.0327H33.6065ZM20.8736 13.0373C21.0613 11.817 21.9558 11 23.2951 11C24.6817 11 25.667 11.8626 25.8477 13.0373C25.9227 13.5251 26.3425 13.8852 26.8361 13.8852H31.6065V17.2623H15.1147V13.8852H19.8852C20.3788 13.8852 20.7986 13.5251 20.8736 13.0373ZM13.7698 25.6691H32.7698V23.8691H13.7698V25.6691ZM13.7698 37.6691H32.7698V35.8691H13.7698V37.6691ZM32.7698 43.6691H13.7698V41.8691H32.7698V43.6691ZM13.7698 31.6691H32.7698V29.8691H13.7698V31.6691ZM49.0786 18.0494V16.2974H48.9803V18.0494H49.0786ZM46.9803 18.2234V14.2974H51.0786V18.2323C52.1501 18.6325 52.8852 19.6498 52.8852 20.8855V39.9019C52.8852 41.1376 52.1501 42.1549 51.0786 42.5551V45.5026H50V48.5571C50 49.1094 49.5523 49.5571 49 49.5571C48.4477 49.5571 48 49.1094 48 48.5571V45.5026H46.9803V42.5764C45.8769 42.1919 45.1147 41.1601 45.1147 39.9019V20.8855C45.1147 19.7608 45.8568 18.6433 46.9803 18.2234ZM47.1147 21.0187V38.7812H50.8852V21.0187H47.1147Z"
    />
  ),
});
