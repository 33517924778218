import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

/**
 * setValue をデバウンスする useState
 * @param initialState 初期値
 * @param wait コールバックの呼び出し間隔(ミリ秒)
 * @returns value, debouncedSetValue
 */

// TODO: useDebouncedCallback のテストを書く
// https://github.com/kkhs/hakari-frontend/issues/10378

export function useDebouncedState<T>(initialState: T, wait: number) {
  const [value, setValue] = useState<T>(initialState);
  const debouncedSetValue = useDebouncedCallback((newValue: T) => {
    setValue(newValue);
  }, wait);
  return [value, debouncedSetValue] as const;
}
