import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { repositoryContainerGetter } from '@/di/repository';

/**
 * 表示する画面
 * - `DEFAULT`: (通常状態)のため、メンテナンスモード画面を表示しない
 * - `UNDER_MAINTENANCE`: メンテナンスモード画面を表示
 * - `MAINTENANCE_DONE`: メンテナンスモード復帰画面を表示
 */
type MaintenanceStatus = 'DEFAULT' | 'MAINTENANCE' | 'MAINTENANCE_DONE';

// 自動復帰ロックフラグ(後述)をONにするまでの時間
const durationForLock = 10_000;

/**
 * メンテナンスモード設定による表示画面の切り替えを管理する
 * 状態と表示する画面の関係はこちら(テーブル)を参照
 * https://www.figma.com/file/I4Ue3joP1TJb9hG1KhBjgt/%E3%83%A1%E3%83%B3%E3%83%86%E3%83%8A%E3%83%B3%E3%82%B9%E3%83%A2%E3%83%BC%E3%83%89-(frontend)?type=whiteboard&node-id=538-549&t=2X5ikuZ91H0htYiY-4
 *
 * @returns 表示する画面
 */
export const useMaintenanceStatus = (): MaintenanceStatus => {
  const { useFlags } = repositoryContainerGetter.featureFlag();
  /*
   * 「自動復帰ロックフラグ」
   * メンテナンスモード終了時に自動復帰を止めて、メンテナンス完了ページを見せるべきか？を管理するフラグ
   * バックエンドへの負荷を考慮して、メンテナンモードに入ってから一定時間後に自動復帰を止めるため
   * 設定は false => true の一方向のみに変化する
   */
  const [isBlockAutoReturn, setIsBlockAutoReturn] = useState(false);

  // メンテナンスモードに10s以上入っている場合は、自動復帰を止める
  const handleMaintenanceFlagChange = useDebouncedCallback((newFlagValue: boolean) => {
    // 既にブロックされている場合は何もしない
    if (isBlockAutoReturn) return;
    // メンテナンスモードに設定されていなければ、ロックしない
    if (!newFlagValue) return;

    setIsBlockAutoReturn(newFlagValue);
  }, durationForLock);

  const { isEnableMaintenanceMode: isEnabled } = useFlags();

  useEffect(() => {
    // LDの設定値が変更されたら、(debounceにより)の継続時間のカウントを開始する
    handleMaintenanceFlagChange(isEnabled);
  }, [isEnabled, handleMaintenanceFlagChange]);

  if (isEnabled) return 'MAINTENANCE';
  return isBlockAutoReturn ? 'MAINTENANCE_DONE' : 'DEFAULT';
};
