import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MedicineLotStockSelect } from '@/entities/medicineLotStock';
import { UpdateStoringStockOperationFieldValues as FieldValues } from '../../schema';

type Props = {
  medicineId: string;
  rowIndex: number;
  lotIndex: number;
  defaultLotNumber?: string;
};

export function LotStockSelect({ medicineId, rowIndex, lotIndex, defaultLotNumber }: Props) {
  const { control, setValue } = useFormContext<FieldValues>();
  const defaultValue = useMemo(
    () =>
      defaultLotNumber
        ? {
            value: { lotNumber: defaultLotNumber, oldestExpirationDate: null },
            label: defaultLotNumber,
          }
        : undefined,
    [defaultLotNumber],
  );

  return (
    <Controller
      name={`operatedMedicines.${rowIndex}.lots.${lotIndex}.lotNumber`}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <MedicineLotStockSelect
          size="sm"
          medicineId={medicineId}
          defaultValue={defaultValue}
          onChange={(v) => {
            onChange(v.lotNumber);
            setValue(
              `operatedMedicines.${rowIndex}.lots.${lotIndex}.expirationDate`,
              v.oldestExpirationDate ? parseISO(v.oldestExpirationDate) : null,
            );
          }}
          onCreate={(inputValue) => {
            onChange(inputValue);
            setValue(`operatedMedicines.${rowIndex}.lots.${lotIndex}.expirationDate`, null);
          }}
          onClear={() => {
            onChange(null);
            setValue(`operatedMedicines.${rowIndex}.lots.${lotIndex}.expirationDate`, null);
          }}
          isInvalid={!!error?.message}
        />
      )}
    />
  );
}
