import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { authAtom } from '../authAtom';
import { PharmacyStaff } from '../types';

export const useSetPharmacyStaff = () => {
  const setState = useSetRecoilState(authAtom);
  return useCallback(
    (pharmacyStaff: PharmacyStaff | undefined) =>
      setState((prev) => ({ ...prev, status: 'signedIn', pharmacyStaff })),
    [setState],
  );
};
