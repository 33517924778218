import { NumberInput, NumberInputField, NumberInputProps } from '@chakra-ui/react';

type Props = Omit<NumberInputProps, 'onChange'> & {
  /** 数字が変更された際の callback */
  onChange: (value: number | null) => void;
};

export function NumberTextInput({ onChange, ...numberInputProps }: Props) {
  return (
    <NumberInput
      onChange={(_, numValue) => onChange(Number.isFinite(numValue) ? numValue : null)}
      {...numberInputProps}
    >
      <NumberInputField textAlign="right" pr={1} />
    </NumberInput>
  );
}
