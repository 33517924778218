/* eslint-disable no-underscore-dangle */
import { Box } from '@chakra-ui/react';
import { forwardRef, ReactElement, Ref } from 'react';
import SelectCreatable, { CreatableProps } from 'react-select/creatable';
import { useSelectStyles } from '../hooks';
import { ClearIndicator, DropdownIndicator } from '../indicators';
import { IconOption } from '../option/IconOption';
import { SelectInstanceType, SelectOptionType, SelectProps } from '../types';

export type CreatableSelectProps<T = string> = SelectProps<T> & {
  formatCreateLabel?: CreatableProps<
    SelectOptionType<T>,
    false,
    { options: SelectOptionType<T>[] }
  >['formatCreateLabel'];
  /** 値を追加した際の callback */
  onCreate?: (value: string) => void;
  ['aria-labelledby']?: string;
};

function _CreatableSelect<T = string>(
  {
    size = 'md',
    onMouseOver,
    onChange,
    onClear,
    onCreate,
    isDisabled = false,
    isWordWrapped = false,
    hiddenDropdownIndicator = false,
    placeholder = '選択してください',
    noOptionsMessage = '選択肢がありません',
    isInvalid = false,
    menuPortalTarget = document.body,
    ...rest
  }: Omit<CreatableSelectProps<T>, 'ref'>,
  ref?: Ref<SelectInstanceType<T>>,
) {
  const selectStyles = useSelectStyles<T>({ size, isWordWrapped, isInvalid });
  return (
    <Box onMouseOver={onMouseOver} onTouchStart={onMouseOver}>
      <SelectCreatable<SelectOptionType<T>>
        {...rest}
        ref={ref}
        placeholder={placeholder}
        components={{
          DropdownIndicator: hiddenDropdownIndicator ? undefined : DropdownIndicator,
          ClearIndicator,
          Option: IconOption,
        }}
        styles={selectStyles}
        menuPortalTarget={menuPortalTarget}
        onChange={(opt, actionMeta) => {
          if (actionMeta.action === 'clear') {
            onClear?.();
          } else if (actionMeta.action === 'create-option') {
            if (opt?.label) {
              onCreate?.(opt.label);
            }
          } else if (opt) {
            onChange(opt.value);
          }
        }}
        noOptionsMessage={() => noOptionsMessage}
        isDisabled={isDisabled}
        isClearable
      />
    </Box>
  );
}

export const CreatableSelect = forwardRef(_CreatableSelect) as <T = string>(
  props: Omit<CreatableSelectProps<T>, 'ref'> & {
    ref?: Ref<SelectInstanceType<T>>;
  },
) => ReactElement;
