import { createIcon } from '@chakra-ui/react';

export const ChevronUpIcon = createIcon({
  displayName: 'ChevronUpIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 10L6 16L4 14L12.0001 6L20 14L18 16L12.0001 10Z"
      fill="currentColor"
    />
  ),
});
