import { useRouter } from 'next/router';

/**
 * pathname からルーティングの状態を返すカスタムフック
 * - isSsoPathname: pathnameの先頭に /sso/ が含まれていた場合にtrueを返す
 * - isPublicErrorPathname: pathnameの先頭に /error/ が含まれていた場合にtrueを返す
 * - isOrganizationGruopAdmin: pathnameの先頭に /organization-group-admin が含まれていた場合にtrueを返す
 */
export function useIdentifyPathname() {
  const { pathname } = useRouter();
  const isSsoPathname = !!pathname.match(/^\/sso\/.+/);
  const isPublicErrorPathname = !!pathname.match(/^\/error\/.+/);
  const isOrganizationGroupAdmin = !!pathname.match(/^\/org-management.*/);
  return {
    isSsoPathname,
    isPublicErrorPathname,
    isOrganizationGroupAdmin,
  };
}
