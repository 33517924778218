import { Box, HStack } from '@chakra-ui/react';
import { Typography, WarningOutlineIcon } from '@kkhs/hakari-ui';

export function StockOperationDeleteAlert() {
  return (
    <Box borderTopWidth={1}>
      <HStack bg="red.50" borderRadius="base" m={4} px={4} py={2}>
        <WarningOutlineIcon w={6} h={6} color="red.500" />
        <Typography variant="body" fontWeight="bold" color="red.500">
          医薬品をすべて削除すると、入庫情報も削除されます。一度削除すると復元はできません。
        </Typography>
      </HStack>
    </Box>
  );
}
