import { createIcon } from '@chakra-ui/react';

export const MedicineMakerIcon = createIcon({
  displayName: 'MedicineMakerIcon',
  viewBox: '0 0 12 12',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2.20898H9V10.209H8V3.20898H4V10.209H3V2.20898ZM7 6.20898H5V7.20898H7V6.20898ZM5 8.20898H7V10.209H5V8.20898ZM7 4.20898H5V5.20898H7V4.20898Z"
      fill="currentColor"
    />
  ),
});
