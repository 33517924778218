import { useQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { graphql } from '@/gql';
import { getProposedWholesale } from '../../functions';

const queryDoc = graphql(`
  query OrganizationManagementProposedWholesale($medicineId: ID!) {
    proposedWholesales(medicineId: $medicineId) {
      medicinePackageUnitId
      wholesale {
        id
        shortName
        # ここでnameを取得しているが、nameは使用していないため、不要なフィールドの取得となっている
        name
      }
    }
  }
`);

// AIに学習させる画面のようなYJコード粒度から本ダイアログを開く場合は需要タイプは複数存在するため、需要タイプとそれにひもづく規格容量が選択されるまではundefinedをとる
type Props = {
  medicineId?: string;
  medicinePackageUnitId?: string;
};

export function OrganizationManagementProposedWholesale({
  medicineId,
  medicinePackageUnitId,
}: Props) {
  const { data } = useQuery(queryDoc, {
    variables: medicineId
      ? {
          medicineId,
        }
      : undefined,
    skip: !medicineId,
  });

  const getLabel = (): string => {
    if (!medicineId || !medicinePackageUnitId || !data) return '-';

    const proposedWholesale = getProposedWholesale(medicinePackageUnitId, data.proposedWholesales);
    return proposedWholesale ? proposedWholesale.shortName : 'なし';
  };

  return (
    <Stack>
      <Typography variant="body2" fontWeight="bold">
        本部推奨卸
      </Typography>
      <Typography variant="body">{getLabel()}</Typography>
    </Stack>
  );
}
