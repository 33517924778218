import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';

export const schema = z.object({
  consumptionChecked: z.boolean(),
  replenishmenthecked: z.boolean(),
  fromDate: z.date().optional(),
  toDate: z.date().optional(),
});

export type FieldValues = z.infer<typeof schema>;

export const defaultValues: DefaultValues<FieldValues> = {
  consumptionChecked: true,
  replenishmenthecked: true,
  fromDate: undefined,
  toDate: undefined,
};
