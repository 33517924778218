import { createIcon } from '@chakra-ui/react';

export const HistoryIcon = createIcon({
  displayName: 'HistoryIcon',
  viewBox: '0 0 48 48',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 42C35.9411 42 44 33.9411 44 24C44 14.0589 35.9411 6 26 6C16.0589 6 8 14.0589 8 24H2L10 32L18 24H12C12 16.268 18.268 10 26 10C33.732 10 40 16.268 40 24C40 31.732 33.732 38 26 38C22.134 38 18.634 36.433 16.1005 33.8995L13.2721 36.7279C16.5294 39.9853 21.0294 42 26 42ZM28 24V14H24V26L31 33L34 30L28 24Z"
      fill="currentCursor"
    />
  ),
});
