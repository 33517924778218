import { UpdateStoringStockOperationByTransferRequestInput } from '@/gql/docs';
import { dateToAWSDate } from '@/shared/utils';
import { updateStoringStockOperationByTransferRequestSchema } from '../schema';

export const storingByTransferRequestSchemaToInput =
  updateStoringStockOperationByTransferRequestSchema.transform(
    ({
      id,
      targetDate,
      pharmacyId,
      note,
      operatedMedicines,
    }): UpdateStoringStockOperationByTransferRequestInput => ({
      id,
      targetDate: dateToAWSDate(targetDate),
      pharmacyId,
      note,
      operatedMedicines: operatedMedicines.flatMap((item) =>
        item.lots.map((lot) => ({
          medicineId: item.medicineId,
          stockUnitQuantity: lot.stockUnitQuantity,
          costPrice: item.costPrice,
          lotNumber: lot.lotNumber,
          expirationDate: lot.expirationDate ? dateToAWSDate(lot.expirationDate) : undefined,
        })),
      ),
    }),
  );
