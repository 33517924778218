import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Button, VStack, HStack, Stack, Skeleton } from '@chakra-ui/react';
import { Link, Select, Typography, theme } from '@kkhs/hakari-ui';
import { captureMessage } from '@sentry/nextjs';
import { useEffect, useMemo, useState } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { CurrentPharmacy } from '@/entities/currentPharmacy';
import { useLogout } from '@/features/auth';
import { getFragment } from '@/gql';
import { CurrentPharmacySelectOptionsQuery, PharmacyFragmentDoc } from '@/gql/docs';
import { pharmacyEvents } from 'src/shared/constants/pharmacy';
import { useOrganizationGroups } from '../../../../store/auth/hooks';

// 薬局リストが存在しない時のデバッグ用に追加
async function captureNoPharmaciesException() {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  const base64Url = token.split('.')[1];
  const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  const json = base64
    ? decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      )
    : undefined;
  const obj = json ? JSON.parse(json) : undefined;

  captureMessage(`No pharamcies allowed to signIn. sub=${obj.sub}`);
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace PharmacySelectOption {
  export type オンボ済 = {
    type: 'onboarded';
    data: CurrentPharmacy;
  };
  export type 未オンボ = {
    type: 'not-onboarded';
    data: CurrentPharmacySelectOptionsQuery['onboardingPharmacies'][number];
  };
  export type Type = PharmacySelectOption['type'];
}
type PharmacySelectOption = PharmacySelectOption.オンボ済 | PharmacySelectOption.未オンボ;

const usePharmacySelect = (
  data: CurrentPharmacySelectOptionsQuery | undefined,
  onboardingEnableFlag: boolean | undefined,
) => {
  const [selectedPharmacy, setSelectedPharmacy] = useState<PharmacySelectOption | undefined>(
    undefined,
  );

  const オンボ済み薬局: PharmacySelectOption.オンボ済[] = (
    data?.pharmaciesAllowedToSignIn.edges || []
  ).map((e) => ({
    type: 'onboarded',
    data: getFragment(PharmacyFragmentDoc, e.node),
  }));
  const 未オンボ薬局: PharmacySelectOption.未オンボ[] = (
    (onboardingEnableFlag && data?.onboardingPharmacies) ||
    []
  ).map((d) => ({
    type: 'not-onboarded',
    data: d,
  }));
  const pharmacies: PharmacySelectOption[] = useMemo(
    () => [...オンボ済み薬局, ...未オンボ薬局],
    [オンボ済み薬局, 未オンボ薬局],
  );

  const changePharmacy = (pharmacyId: string) => {
    const found = pharmacies.find((p) => p.data.id === pharmacyId);
    setSelectedPharmacy(found);
  };

  const toOnboardingPage = () => {
    window.location.href = '/onboarding';
  };

  // 薬局選択を確定する
  const clickConfirmButton = () => {
    if (!selectedPharmacy) return;
    if (selectedPharmacy.type === 'onboarded') {
      Hub.dispatch('currentPharmacy', {
        event: pharmacyEvents.SET,
        data: selectedPharmacy.data,
      });
    } else {
      // ONBページに飛ばす
      toOnboardingPage();
    }
  };

  const clearPharmacy = () => setSelectedPharmacy(undefined);

  useEffect(() => {
    if (pharmacies.length === 0) captureNoPharmaciesException();
    if (pharmacies.length !== 1) return;
    const pharmacy = pharmacies[0];
    if (!pharmacy) return;
    if (pharmacy.type === 'onboarded') {
      // 選択可能な薬局が1つで、オンボ済みの場合は、自動的にその薬局が選択される
      Hub.dispatch('currentPharmacy', {
        event: pharmacyEvents.SET,
        data: pharmacy.data,
      });
    } else {
      // 選択可能な薬局が1つで、未オンボの場合は、自動的にオンボページに飛ばす
      toOnboardingPage();
    }
  }, [pharmacies]);

  const options = useMemo(
    () =>
      pharmacies.map((p) => ({
        value: p.data.id,
        label: p.data.name,
      })),
    [pharmacies],
  );

  return {
    options,
    confirmDisabled: !selectedPharmacy,
    clearPharmacy,
    changePharmacy,
    clickConfirmButton,
  };
};

type Props = {
  data: CurrentPharmacySelectOptionsQuery | undefined;
  loading: boolean;
};

export function CurrentPharmacySelect({ data, loading }: Props) {
  const logout = useLogout();
  const { isOrganizationGroupsAdmin } = useOrganizationGroups();

  const { useFlags } = repositoryContainerGetter.featureFlag();
  const flags = useFlags();

  const { options, confirmDisabled, clearPharmacy, changePharmacy, clickConfirmButton } =
    usePharmacySelect(data, flags?.tmpOnboardingPageEnabled);

  return (
    <Stack bg="base.default" minH="100vh" alignItems="center" justifyContent="center" spacing={6}>
      <VStack
        bg="white"
        w="400px"
        p={10}
        alignItems="stretch"
        spacing={8}
        borderRadius="md"
        overflow="hidden"
      >
        <Typography as="h1" variant="h2">
          薬局を選択してください
        </Typography>
        <Skeleton data-testid="pharmacy-select" isLoaded={!loading}>
          <Select onChange={changePharmacy} onClear={clearPharmacy} options={options} isClearable />
        </Skeleton>
        <HStack width="full" justify="flex-end">
          <Button variant="ghost" onClick={logout}>
            ログアウト
          </Button>
          <Button ml={3} onClick={clickConfirmButton} isDisabled={confirmDisabled}>
            確定する
          </Button>
        </HStack>
      </VStack>
      {isOrganizationGroupsAdmin && (
        <Link
          style={{
            textDecoration: 'underline',
            textDecorationColor: theme.colors.slate[500],
            textUnderlineOffset: '2px',
          }}
          href="/org-management/proposed-wholesale"
        >
          <Typography variant="body" textAlign="center" color="slate.500">
            本部管理へ
          </Typography>
        </Link>
      )}
    </Stack>
  );
}
