import { createIcon } from '@chakra-ui/react';

export const CMUnitSymbolIcon = createIcon({
  displayName: 'CM',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.10999 4.31004C3.48999 3.93004 3.97999 3.74004 4.56999 3.74004C5.07999 3.74004 5.56999 3.88004 6.02999 4.15004L6.12999 4.21004L6.49999 3.33004L6.40999 3.28004C5.85999 2.96004 5.23999 2.79004 4.54999 2.79004C3.94999 2.79004 3.41999 2.93004 2.96999 3.20004C2.52999 3.47004 2.14999 3.85004 1.85999 4.33004C1.56999 4.80004 1.34999 5.36004 1.20999 5.99004C1.06999 6.61004 0.98999 7.28004 0.98999 7.98004C0.98999 9.04004 1.12999 9.97004 1.39999 10.74C1.66999 11.52 2.06999 12.13 2.59999 12.55C3.11999 12.97 3.75999 13.19 4.49999 13.19C5.17999 13.19 5.76999 13.05 6.23999 12.79L6.29999 12.76V11.81L6.14999 11.88C5.93999 11.98 5.69999 12.07 5.43999 12.14C5.17999 12.22 4.88999 12.25 4.56999 12.25C4.01999 12.25 3.54999 12.08 3.16999 11.74C2.78999 11.4 2.48999 10.9 2.27999 10.27C2.06999 9.63004 1.95999 8.86004 1.95999 7.99004C1.95999 7.22004 2.05999 6.50004 2.24999 5.85004C2.43999 5.21004 2.72999 4.69004 3.10999 4.30004V4.31004Z"
        fill="currentColor"
      />
      <path
        d="M11.48 11.43L9.16 3H8V13H8.92V5.81C8.92 5.62 8.9 4.93 8.9 4.83L11.06 13H11.86L14.07 4.8C14.06 5.23 14.05 5.57 14.05 5.81V13H14.97V3H13.8L11.47 11.43H11.48Z"
        fill="currentColor"
      />
    </svg>
  ),
});
