import { Box, Circle, HStack, useRadio, UseRadioProps } from '@chakra-ui/react';

type ItemProps = {
  label: React.ReactElement;
  minW?: string;
} & UseRadioProps;

export function RadioItem(props: ItemProps) {
  const { label, minW } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box minW={minW} as="label">
      <input {...input} />
      <HStack
        {...checkbox}
        cursor="pointer"
        _disabled={{
          opacity: 0.4,
          cursor: 'not-allowed',
        }}
      >
        <Circle
          {...checkbox}
          minW={4}
          minH={4}
          bg="white"
          borderColor="primary.600"
          borderWidth="2px"
          _checked={{
            bg: 'primary.600',
          }}
          _disabled={{
            borderColor: 'gray.200',
          }}
        >
          <Circle
            {...checkbox}
            minW={3}
            minH={3}
            _checked={{
              bg: 'primary.600',
              borderColor: 'white',
              borderWidth: '2px',
              '&[data-disabled]': {
                bg: 'gray.200',
              },
            }}
          />
        </Circle>
        <Box>{label}</Box>
      </HStack>
    </Box>
  );
}
