import { createIcon } from '@chakra-ui/react';

export const UserIcon = createIcon({
  displayName: 'UserIcon',
  viewBox: '0 0 20 21',
  path: (
    <path
      fill="currentColor"
      d="M3.99061 14.845C5.46166 17.0046 7.46479 18.0844 10 18.0844C12.5352 18.0844 14.5383 17.0046 16.0094 14.845C15.9781 13.9686 15.2739 13.2331 13.8967 12.6384C12.5196 12.0437 11.2207 11.7464 10 11.7464C8.77934 11.7464 7.48044 12.0437 6.10329 12.6384C4.72613 13.2018 4.02191 13.9373 3.99061 14.845ZM12.1127 4.75108C11.518 4.1564 10.8138 3.85906 10 3.85906C9.18623 3.85906 8.482 4.1564 7.88732 4.75108C7.29264 5.34576 6.99531 6.04999 6.99531 6.86376C6.99531 7.67753 7.29264 8.38176 7.88732 8.97644C8.482 9.57111 9.18623 9.86845 10 9.86845C10.8138 9.86845 11.518 9.57111 12.1127 8.97644C12.7074 8.38176 13.0047 7.67753 13.0047 6.86376C13.0047 6.04999 12.7074 5.34576 12.1127 4.75108ZM2.9108 3.81212C4.88263 1.84029 7.2457 0.85437 10 0.85437C12.7543 0.85437 15.1017 1.84029 17.0423 3.81212C19.0141 5.75265 20 8.10007 20 10.8544C20 13.6087 19.0141 15.9717 17.0423 17.9436C15.1017 19.8841 12.7543 20.8544 10 20.8544C7.2457 20.8544 4.88263 19.8841 2.9108 17.9436C0.970266 15.9717 0 13.6087 0 10.8544C0 8.10007 0.970266 5.75265 2.9108 3.81212Z"
    />
  ),
});
