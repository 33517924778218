import { useQuery } from '@apollo/client';
import { Box, FormControl, FormLabel, Skeleton, Stack, useTheme } from '@chakra-ui/react';
import { RequiredTag, SelectV2, Typography } from '@kkhs/hakari-ui';
import { useMemo } from 'react';
import { FieldPath, FieldValues, UseFormReturn, useController } from 'react-hook-form';
import { FragmentType, getFragment, graphql } from '@/gql';

const queryDoc = graphql(`
  query OrderableWholesaleSelectV2Form($medicineId: ID) {
    orderableWholesales(medicineId: $medicineId) {
      id
      shortName
    }
  }
`);

export const defaultWholesaleFragmentDoc = graphql(`
  fragment OrderableWholesaleSelectV2Form_Wholesale on Wholesale {
    id
    shortName
  }
`);

type OrderableWholesaleSelectV2Option = {
  id: string;
  shortName: string;
};

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  medicineId?: string;
  defaultWholesale?: FragmentType<typeof defaultWholesaleFragmentDoc>;
  /** 必須ラベルを表示するか */
  isRequired?: boolean;
} & Pick<UseFormReturn<T>, 'control'>;

export function OrderableWholesaleSelectV2Form<T extends FieldValues>({
  name,
  label,
  medicineId,
  defaultWholesale,
  isRequired = false,
  control,
}: Props<T>) {
  const {
    field: { onChange, ...fieldProps },
  } = useController({
    name,
    control,
  });

  const { data, loading } = useQuery(queryDoc, {
    variables: { medicineId },
    fetchPolicy: 'no-cache',
  });
  const options = useMemo(
    () =>
      data?.orderableWholesales.map((fragment) => ({
        value: fragment,
        label: fragment.shortName,
      })) ?? [],
    [data],
  );

  const defaultValue = useMemo(() => {
    const fragment = getFragment(defaultWholesaleFragmentDoc, defaultWholesale);
    return fragment
      ? {
          value: fragment,
          label: fragment.shortName,
        }
      : undefined;
  }, [defaultWholesale]);

  // Chakra UI のテーマで最も高い z-index を使用する
  const {
    zIndices: { tooltip },
  } = useTheme();

  return (
    <FormControl isRequired={isRequired}>
      <Stack spacing={2}>
        <FormLabel
          htmlFor="wholesaleId"
          requiredIndicator={isRequired ? <RequiredTag ml={2} mt={0} /> : undefined}
          display="flex"
          alignItems="center"
        >
          <Typography variant="body2" fontWeight="bold" as="span">
            {label}
          </Typography>
        </FormLabel>
        <Box w="300px">
          <Skeleton isLoaded={!loading}>
            <SelectV2<OrderableWholesaleSelectV2Option>
              {...fieldProps}
              options={options}
              defaultValue={defaultValue}
              onChange={({ id }) => onChange(id)}
              zIndex={tooltip}
            />
          </Skeleton>
        </Box>
      </Stack>
    </FormControl>
  );
}
