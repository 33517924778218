import {
  PopoverArrow,
  PopoverBody,
  PopoverContent as ChakraPopoverContent,
  PopoverContentProps,
  PopoverBodyProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Typography } from '../../Typography';

type Props = {
  content: string | ReactNode;
} & Pick<PopoverContentProps, 'w' | 'variants'> &
  Pick<PopoverBodyProps, 'p'>;

export function PopoverContent({ w, content, p, variants }: Props) {
  return (
    <ChakraPopoverContent
      w={w}
      border="none"
      boxShadow="popover"
      variants={variants}
      _focus={{ outline: 'none' }}
      _focusVisible={{ outline: 'none' }}
    >
      <PopoverArrow />
      <PopoverBody textAlign="start" p={p}>
        {typeof content === 'string' ? (
          <Typography variant="body2" whiteSpace="normal" color="slate.500">
            {content}
          </Typography>
        ) : (
          content
        )}
      </PopoverBody>
    </ChakraPopoverContent>
  );
}
