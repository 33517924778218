import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';

/** リロード発動までの最大時間(秒)  */
const MAX_WAITING_TIME = 180;

export function useWaitingTime(): number {
  const currentPharmacy = currentPharmacySelectors.useValue();

  if (isNullOrUndefined(currentPharmacy?.id)) {
    return MAX_WAITING_TIME + 5;
  }
  const pharmacyIdNumber = parseInt(currentPharmacy!.id, 10);
  const waitingTime = pharmacyIdNumber % MAX_WAITING_TIME;
  return waitingTime;
}
