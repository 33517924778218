import { InternalRefetchQueriesInclude } from '@apollo/client';
import {
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Stack,
  Grid,
  Divider,
  AlertDialogHeader,
} from '@chakra-ui/react';
import {
  Typography,
  AlertDialogContent,
  ButtonGroup,
  useZodForm,
  showErrorToast,
  showSuccessToast,
} from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { DefaultValues, FormProvider } from 'react-hook-form';
import {
  AddressForm,
  BuildingNameForm,
  CounterpartyNameForm,
  PharmacyLicenseNumberForm,
  PhoneAndFaxNumberForm,
  ZipCodeForm,
} from '@/entities/counterparty';
import { useCreateInternalCounterpartyMutation } from '@/gql/apollo';
import { CreateInternalCounterpartyFieldValues, createInternalCounterpartySchema } from './schema';

const defaultValues: DefaultValues<CreateInternalCounterpartyFieldValues> = {
  name: '',
  zipCode: '',
  address: '',
  buildingName: '',
  phoneNumber: '',
  faxNumber: '',
  pharmacyLicenseNumber: '',
};

type Props = {
  onClose: () => void;
  cancelRef: React.MutableRefObject<null>;
  refetchQueries?: InternalRefetchQueriesInclude;
};

export function DialogContent({ onClose, cancelRef, refetchQueries }: Props) {
  const useFormReturn = useZodForm({
    schema: createInternalCounterpartySchema,
    defaultValues,
  });
  const { register, getValues, handleSubmit, reset: resetForm, formState } = useFormReturn;
  const { isValid, isDirty, isSubmitting } = formState;

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const [mutation, { loading, called, reset }] = useCreateInternalCounterpartyMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      if ((d.createInternalCounterparty.errors?.length ?? 0) !== 0) {
        showErrorToast({
          id: 'create-Internal-counterparty-error',
          title: d.createInternalCounterparty.errors?.[0]?.message ?? 'エラーが発生しました',
        });
        reset();
        return;
      }
      showSuccessToast({
        id: 'create-Internal-counterparty-success',
        title: '取引先を登録しました',
      });
      handleClose();
    },
    refetchQueries,
  });

  const submit = useCallback(async () => {
    const { name, zipCode, address, buildingName, phoneNumber, faxNumber, pharmacyLicenseNumber } =
      getValues();
    await mutation({
      variables: {
        input: {
          name,
          zipCode: zipCode || null,
          address: address || null,
          buildingName: buildingName || null,
          phoneNumber: phoneNumber || null,
          faxNumber: faxNumber || null,
          pharmacyLicenseNumber: pharmacyLicenseNumber || null,
          // TODO: https://github.com/kkhs/hakari-frontend/issues/3800
          status: 'active',
        },
      },
    });
  }, [getValues, mutation]);

  return (
    <FormProvider {...useFormReturn}>
      <AlertDialogContent shouldAlert={isDirty && !called}>
        <AlertDialogHeader borderBottom="1px" borderColor="gray.200">
          <Typography variant="h2">取引先情報（法人内）</Typography>
        </AlertDialogHeader>
        <AlertDialogBody p="0">
          <Stack spacing={6} p={6}>
            <Stack spacing={4} divider={<Divider borderColor="gray.200" />}>
              <Grid templateColumns="160px 1fr" alignItems="baseline" rowGap={4}>
                <CounterpartyNameForm<CreateInternalCounterpartyFieldValues>
                  name="name"
                  register={register}
                  formState={formState}
                />
                <ZipCodeForm<CreateInternalCounterpartyFieldValues>
                  name="zipCode"
                  register={register}
                  formState={formState}
                />
                <AddressForm<CreateInternalCounterpartyFieldValues>
                  name="address"
                  register={register}
                  formState={formState}
                />
                <BuildingNameForm<CreateInternalCounterpartyFieldValues>
                  name="buildingName"
                  register={register}
                  formState={formState}
                />
                <PhoneAndFaxNumberForm<CreateInternalCounterpartyFieldValues>
                  phoneNumberFieldName="phoneNumber"
                  faxNumberFieldName="faxNumber"
                  register={register}
                  formState={formState}
                />
                <PharmacyLicenseNumberForm<CreateInternalCounterpartyFieldValues>
                  name="pharmacyLicenseNumber"
                  register={register}
                  formState={formState}
                />
              </Grid>
            </Stack>
          </Stack>
        </AlertDialogBody>
        <AlertDialogFooter borderTop="1px" borderColor="gray.200" py={6}>
          <ButtonGroup ml="auto">
            <Button
              ref={cancelRef}
              variant="ghost"
              onClick={handleClose}
              isDisabled={isSubmitting || loading}
            >
              キャンセル
            </Button>
            <Button
              onClick={handleSubmit(submit)}
              isDisabled={!isValid}
              isLoading={isSubmitting || loading}
            >
              登録する
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </FormProvider>
  );
}
