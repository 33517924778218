import { Button, ButtonGroup } from '@chakra-ui/react';

type Props = {
  cancelRef: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  isDisabled: boolean;
};

export function StockOperationUpdateButtonGroup({
  cancelRef,
  onClose,
  onSubmit,
  isLoading,
  isDisabled,
}: Props) {
  return (
    <ButtonGroup>
      <Button w="118px" variant="ghost" ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
        キャンセル
      </Button>
      <Button w="146px" onClick={onSubmit} isLoading={isLoading} isDisabled={isDisabled}>
        変更を保存する
      </Button>
    </ButtonGroup>
  );
}
