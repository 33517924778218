import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { AdoptedMedicalProductType } from '@/entities/adoptedMedicalProduct';
import { graphql } from '@/gql';

const queryDoc = graphql(`
  query AdoptedMedicalProductModalContent($medicineId: ID!) {
    adoptedMedicines(filter: { medicineId: $medicineId }, first: 1) {
      edges {
        node {
          medicine {
            id
            pharmacyId
            stockUnitSymbol
            isMedicalMaterial
          }
          ...AdoptedMedicalModalHeader_AdoptedMedicine
        }
      }
    }
  }
`);

export function useAdoptedMedicalModalContent({ medicineId }: { medicineId: string }) {
  const { data } = useQuery(queryDoc, {
    variables: { medicineId },
  });
  const adoptedMedicalProduct = data?.adoptedMedicines.edges[0]?.node;
  const stockUnitSymbol = adoptedMedicalProduct?.medicine.stockUnitSymbol;
  const medicalProductType: AdoptedMedicalProductType | undefined = useMemo(() => {
    if (!adoptedMedicalProduct?.medicine) {
      return undefined;
    }
    return adoptedMedicalProduct.medicine.isMedicalMaterial ? 'medicalMaterial' : 'medicine';
  }, [adoptedMedicalProduct?.medicine]);

  return {
    adoptedMedicalProduct,
    stockUnitSymbol,
    medicalProductType,
  };
}
