import { createIcon } from '@chakra-ui/react';

export const PharmacyIcon = createIcon({
  displayName: 'PharmacyIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4H18V14H6L6 20H4V10L6 8L6 4ZM11 12H13V10H15V8H13V6H11L11 8L9 8V10H11L11 12ZM18 8L20 10V20H18V10V8ZM11 20V18H13V20H15V16H13H11H9V20H11Z"
      fill="currentColor"
    />
  ),
});
