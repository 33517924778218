import { AlertDialogOverlay } from '@chakra-ui/react';
import { AlertDialog } from '@kkhs/hakari-ui';
import { useRef } from 'react';
import { OwnTakeTransferRequestTargetFragment } from '@/gql/docs';
import { Actions } from '@/repository/userMonitoring/interface';
import { DialogContent } from './DialogContent';

export type AdjustTarget = {
  medicineId: string;
  stockUnitQuantity: number;
  stockUnitSymbol: string;
};

export type Props = {
  isOpen: boolean;
  targets: Omit<OwnTakeTransferRequestTargetFragment, '__typename'>[];
  onClose: () => void;
  onSuccess: () => void;
  userMonitoringKey:
    | Actions['医薬品詳細モーダル_他店舗在庫タブ_小分け依頼_SUBMITTED']['key']
    | Actions['おすすめ発注_他店不動在庫ポップオーバー_小分け依頼_SUBMITTED']['key']
    | Actions['在庫一覧_他店舗在庫タブ_小分け依頼_SUBMITTED']['key'];
};

export function OwnTakeTransferRequestDialog({
  isOpen,
  onClose,
  targets,
  onSuccess,
  userMonitoringKey,
}: Props) {
  const cancelRef = useRef(null);
  return (
    <AlertDialog
      size="5xl"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay data-testid="request-transfer-stock-dialog">
        {isOpen && (
          <DialogContent
            onClose={onClose}
            targets={targets}
            cancelRef={cancelRef}
            onSuccess={onSuccess}
            userMonitoringKey={userMonitoringKey}
          />
        )}
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
