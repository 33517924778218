import { Flex } from '@chakra-ui/react';
import { NumberText } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { ConvertedStockUnitQuantityWithSymbol, StockUnitSymbolIcon } from '@/entities/medicine';
import { CellBase } from './CellBase';
import { Row } from '../../types';

export function NearExpirationUnitQuantityCell({
  medicine: { stockUnitSymbol },
  nearExpirationUnitQuantity,
  convertedOthersMedicineStockUnitQuantities,
}: Row) {
  return (
    <CellBase isNumeric data-testid="期限間近/期限切れ数ブロック">
      <Flex justifyContent="flex-end">
        <NumberText data-testid="期限間近/期限切れ数" inline>
          {nearExpirationUnitQuantity}
        </NumberText>
        <StockUnitSymbolIcon name={stockUnitSymbol} boxSize={4} />
      </Flex>
      {!isNullOrUndefined(convertedOthersMedicineStockUnitQuantities) && (
        <ConvertedStockUnitQuantityWithSymbol
          fontSize="12px"
          paddingBottom={1}
          convertedStockUnitQuantity={
            convertedOthersMedicineStockUnitQuantities.nearExpirationUnitQuantity
          }
          convertedStockUnitSymbol={convertedOthersMedicineStockUnitQuantities.stockUnitSymbol}
        />
      )}
    </CellBase>
  );
}
