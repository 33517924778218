import { HStack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { useFormContext, useWatch } from 'react-hook-form';
import { StockUnitQuantityWithSymbol } from '@/entities/medicine';
import { UpdateStoringStockOperationFieldValues as FieldValues } from '../../schema';

type Props = {
  stockUnitSymbol: string;
  rowIndex: number;
};

export function TotalStockUnitQuantity({ stockUnitSymbol, rowIndex }: Props) {
  const { control } = useFormContext<FieldValues>();
  // NOTE: useWatch した値が undefined になる場合は空配列を返す
  const lots =
    useWatch({
      control,
      name: `operatedMedicines.${rowIndex}.lots`,
    }) ?? [];
  const totalStockUnitQuantity = lots.reduce(
    (prev, current) => prev + current.stockUnitQuantity,
    0,
  );

  return (
    <HStack>
      <Typography variant="body" fontWeight="bold">
        入庫数量
      </Typography>
      <StockUnitQuantityWithSymbol
        variant="h2"
        quantity={totalStockUnitQuantity}
        stockUnitSymbol={stockUnitSymbol}
        spacing={1}
      />
    </HStack>
  );
}
