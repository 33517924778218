import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { MedicineAttentionIcons } from '@/entities/adoptedMedicine';
import { MedicineAttentionsFragment } from '@/gql/apollo';

type Props = {
  value: MedicineAttentionsFragment;
  label: string;
};

export function AttentionItemLabel({ value, label }: Props) {
  return (
    <Stack spacing={0.5}>
      <Typography variant="body2" color="slate.500">
        {label}
      </Typography>
      <MedicineAttentionIcons fragment={value} />
    </Stack>
  );
}
