import { ApolloProvider } from '@apollo/client';
import { useMemo } from 'react';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { createGraphQLClient } from '@/shared/lib/apolloClient';

type Props = {
  children: React.ReactNode;
};

export function ApolloRoot({ children }: Props) {
  const currentPharmacy = currentPharmacySelectors.useValue();
  const appSyncApiClient = useMemo(
    () =>
      createGraphQLClient({
        pharmacyId: currentPharmacy?.id,
        apiUrl: process.env.GRAPHQL_API_URL,
        stockSaleApiUrl: process.env.STOCK_SALE_API_URL,
      }),
    [currentPharmacy?.id],
  );
  return <ApolloProvider client={appSyncApiClient}>{children}</ApolloProvider>;
}
