import * as RadioGroup from '@radix-ui/react-radio-group';
import { forwardRef, useId } from 'react';
import { itemWrapper, item, indicator, label as labelClass } from './index.css';
import { Typography } from '../../../../components';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';

type Props = Omit<ComponentPropsWithoutRef<typeof RadioGroup.Item>, 'className'> & {
  label: string;
  size?: 'md' | 'sm';
  itemMinWidth?: string;
};

/**
 * API Reference
 * https://www.radix-ui.com/docs/primitives/components/radio-group#item
 */
export const RadioGroupItem = forwardRef<ElementRef<typeof RadioGroup.Item>, Props>(
  ({ label, size, itemMinWidth, ...rest }, ref) => {
    const uuid = useId();
    return (
      <div className={itemWrapper} style={{ minWidth: itemMinWidth }}>
        <RadioGroup.Item className={item} {...rest} id={`${uuid}-${label}`} ref={ref}>
          <RadioGroup.Indicator className={indicator} forceMount />
        </RadioGroup.Item>
        <label className={labelClass} htmlFor={`${uuid}-${label}`}>
          {/* TODO: Typographyコンポーネントを整理したら差し替える */}
          <Typography variant={size === 'md' ? 'body' : 'body2'} color="inherit">
            {label}
          </Typography>
        </label>
      </div>
    );
  },
);

RadioGroupItem.displayName = 'RadioGroupItem';
