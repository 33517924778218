import { Typography } from '@kkhs/hakari-ui';
import { CellBase } from './CellBase';
import { Row } from '../../types';

export function ImmovableDaysCell({ immovablyDays }: Row) {
  return (
    <CellBase isNumeric>
      {immovablyDays !== null && immovablyDays >= 90 ? (
        <>
          あり
          <br />(
          <Typography variant="body" fontWeight="bold" as="span">
            {immovablyDays}
          </Typography>
          日)
        </>
      ) : (
        'なし'
      )}
    </CellBase>
  );
}
