import {
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  ButtonGroup,
  Stack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import {
  AlertDialogContent,
  Typography,
  StockTransferImportIcon,
  WarningOutlineIcon,
  useZodForm,
  showErrorToast,
  showSuccessToast,
} from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { DefaultValues, FormProvider, useFieldArray } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { useCreateTransferRequestsMutation } from '@/gql/apollo';
import { OwnTakeTransferRequestTargetFragment } from '@/gql/docs';
import { dateToAWSDate } from '@/shared/utils';
import { MedicineRow } from './MedicineRow';
import { SearchRequestMedicine } from './SearchRequestMedicine';
import { FieldValues, schema } from './schema';
import { Props as DialogProps } from './index';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  targets: OwnTakeTransferRequestTargetFragment[];
  cancelRef: React.RefObject<HTMLButtonElement>;
} & Pick<DialogProps, 'userMonitoringKey'>;

export function DialogContent({
  onClose,
  targets,
  cancelRef,
  onSuccess,
  userMonitoringKey,
}: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const defaultValues: DefaultValues<FieldValues> = {
    requestMedicines: targets.map((target) => ({
      requestType: 'take',
      medicineId: target.medicine.id,
      medicineName: target.medicine.medicineName,
      unitOutline: target.medicine.usageGroup.unitOutline,
      // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
      shortUnitOutline: target.medicine.usageGroup.shortUnitOutline,
      stockUnitSymbol: target.medicine.stockUnitSymbol,
      stockUnitPrice: target.medicine.stockUnitPrice,
      stockUnitQuantity: target.medicine.stockUnitQuantity,
      nearExpirationUnitQuantity: target.nearExpirationUnitQuantity,
      transferDate: undefined,
      targetPharmacyId: target.pharmacy.id,
      unitQuantity: 0,
      lotNumber: undefined,
      expirationDate: undefined,
      requestMemo: '',
      adoptionStoppedDate: target.medicine.adoptionStoppedDate ?? undefined,
    })),
  };

  const useFormReturn = useZodForm({
    schema,
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset: resetForm,
    formState: { isValid, isDirty },
  } = useFormReturn;
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'requestMedicines',
  });
  const [mutation, { loading, called, reset }] = useCreateTransferRequestsMutation();
  const onSubmit = async () => {
    const values = getValues();
    const input = values.requestMedicines.map((value) => ({
      requestType: value.requestType,
      medicineId: value.medicineId,
      pharmacyId: value.targetPharmacyId,
      transferDate: value.transferDate ? dateToAWSDate(value.transferDate) : undefined,
      unitQuantity: value.unitQuantity,
      expirationDate: value.expirationDate ? dateToAWSDate(value.expirationDate) : undefined,
      requestMemo: value.requestMemo,
    }));

    await mutation({
      variables: { input },
      onError: () => {
        showErrorToast({
          id: 'create-transfer-request-error',
          title: 'エラーが発生しました。',
        });
        reset();
      },
      onCompleted: (res) => {
        if (res.createTransferRequests.errors && res.createTransferRequests.errors.length > 0) {
          showErrorToast({
            id: 'create-transfer-request-error',
            title: res.createTransferRequests.errors[0]?.message ?? 'エラーが発生しました',
          });
          reset();
        } else {
          userMonitoringRepository.sendEvent({
            key: userMonitoringKey,
            contexts: { pharmacyId, musubiCode },
          });
          showSuccessToast({
            id: 'create-transfer-request-success',
            title: '小分け依頼が完了しました',
          });
          onSuccess();
          onClose();
        }
      },
    });
  };
  const handleClose = useCallback(() => {
    onClose();
    resetForm();
  }, [onClose, resetForm]);

  /** NOTE: 選択した医薬品の中に採用停止薬の有無を判断する */
  const hasAdoptionStopped = fields.some((field) => !!field.adoptionStoppedDate);

  return (
    <AlertDialogContent shouldAlert={isDirty && !called}>
      <FormProvider {...useFormReturn}>
        <AlertDialogHeader borderBottomWidth="1px" borderColor="gray.200" p={0}>
          <HStack borderBottomWidth="1px" borderColor="gray.200" px={6} pt={4} pb={2}>
            <StockTransferImportIcon color="slate.300" w={12} h={12} />
            <Typography variant="h2">小分け依頼を出す</Typography>
          </HStack>
          <Stack px={6} py={4}>
            <Typography variant="body" fontWeight="normal">
              選択した店舗へ以下医薬品の小分け依頼を出します。
              <br />
              依頼先店舗に承諾されると入庫手続きを進めることができます。
            </Typography>
            {hasAdoptionStopped && (
              <HStack bg="red.50" borderRadius="base" px={4} py={2}>
                <WarningOutlineIcon color="red.500" boxSize={6} />
                <Typography variant="body" color="red.500">
                  採用停止中の医薬品です。問題がない場合は、後続の手続きを行ってください。
                </Typography>
              </HStack>
            )}
          </Stack>
        </AlertDialogHeader>
        <AlertDialogBody p={0}>
          <HStack bg="gray.50" borderBottomWidth="1px" borderColor="gray.200" px={6} py={4}>
            <SearchRequestMedicine addMedicine={prepend} fields={fields} />
          </HStack>
          <Stack divider={<Divider borderColor="gray.200" />} minH="200px">
            {fields?.map((field, index) => (
              <MedicineRow key={field.id} field={field} index={index} remove={remove} />
            ))}
          </Stack>
        </AlertDialogBody>
        <AlertDialogFooter borderTop="1px" borderColor="gray.200">
          <ButtonGroup spacing={4}>
            <Button
              variant="ghost"
              color="primary.500"
              w="118px"
              ref={cancelRef}
              onClick={handleClose}
              isDisabled={loading || called}
            >
              キャンセル
            </Button>
            <Button
              w="118px"
              isDisabled={!isValid}
              isLoading={loading || called}
              onClick={handleSubmit(onSubmit)}
            >
              依頼を出す
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </FormProvider>
    </AlertDialogContent>
  );
}
