import isSameDay from 'date-fns/isSameDay';
import { jstNow } from './jstNow';

/**
 * JST の現在時刻と同日か
 */
export function isJstToday(jstDate: Date) {
  const jstToday = jstNow();
  return isSameDay(jstToday, jstDate);
}
