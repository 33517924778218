/* eslint-disable no-underscore-dangle */
import { Box } from '@chakra-ui/react';
import { forwardRef, ReactElement, ReactNode, Ref } from 'react';
import ReactSelect from 'react-select';
import { useSelectStyles } from '../hooks';
import { ClearIndicator, DropdownIndicator } from '../indicators';
import { IconOption } from '../option/IconOption';
import { SingleValueOption } from '../option/SingleValueOption';
import { SelectProps, SelectInstanceType, SelectOptionType } from '../types';

function _Select<T = string>(
  {
    size = 'md',
    onMouseOver,
    onChange,
    onClear,
    isDisabled = false,
    isClearable = false,
    isWordWrapped = false,
    hiddenDropdownIndicator = false,
    placeholder = '選択してください',
    noOptionsMessage = '選択肢がありません',
    isInvalid = false,
    menuPortalTarget = document.body,
    isNumeric = false,
    hasChanged = false,
    isDynamicOptionWidth = false,
    renderSingleValueNotice,
    renderOptionNotice,
    ...rest
  }: Omit<SelectProps<T>, 'ref'> & {
    isNumeric?: boolean;
    isDynamicOptionWidth?: boolean;
    renderSingleValueNotice?: (data: SelectOptionType<T>) => ReactNode;
    renderOptionNotice?: (data: SelectOptionType<T>) => ReactNode;
  },
  ref?: Ref<SelectInstanceType<T>>,
) {
  const selectStyles = useSelectStyles<T>({
    size,
    isWordWrapped,
    isInvalid,
    isNumeric,
    isDynamicOptionWidth,
    hasChanged,
  });
  return (
    <Box onMouseOver={onMouseOver} onTouchStart={onMouseOver}>
      <ReactSelect<SelectOptionType<T>>
        {...rest}
        ref={ref}
        placeholder={placeholder}
        components={{
          DropdownIndicator: hiddenDropdownIndicator ? undefined : DropdownIndicator,
          ClearIndicator,
          Option: (props) => IconOption({ ...props, renderOptionNotice }),
          SingleValue: (props) => SingleValueOption({ ...props, renderSingleValueNotice }),
        }}
        styles={selectStyles}
        menuPortalTarget={menuPortalTarget}
        onChange={(opt, actionMeta) => {
          if (actionMeta.action === 'clear') {
            onClear?.();
          } else if (opt) {
            onChange(opt.value);
          }
        }}
        noOptionsMessage={() => noOptionsMessage}
        isDisabled={isDisabled}
        isClearable={isClearable}
        menuPosition="fixed"
      />
    </Box>
  );
}

export const Select = forwardRef(_Select) as <T = string>(
  props: Omit<SelectProps<T>, 'ref'> & {
    ref?: Ref<SelectInstanceType<T>>;
    isNumeric?: boolean;
    /**
     * Optionの幅を内包のコンテンツにあわせて変わるようにする
     */
    isDynamicOptionWidth?: boolean;
    /**
     * SingleValueの下部に追加のコンポーネントをレンダリングする
     */
    renderSingleValueNotice?: (data: SelectOptionType<T>) => ReactNode;
    /**
     * 各Optionの下部に追加のコンポーネントをレンダリングする
     */
    renderOptionNotice?: (data: SelectOptionType<T>) => ReactNode;
  },
) => ReactElement;
