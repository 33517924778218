import { Box, HStack, Stack, Table, Tbody, Td, Th, Thead, Tr, chakra } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { StockUnitSymbolIcon } from '@/entities/medicine';
import {
  StockOperationReasonLabel,
  calculateSubTotalAmount,
  StockOperationShippingNoteLabel,
  StockOperationShippingTargetDateLabel,
  TaxLabel,
} from '@/entities/stockOperation';
import { StockSalePriceConfig } from '@/gql/docs';
import { formatAWSDate } from '@/shared/utils';
import { groupMedicinesByMedicineId } from './groupMedicinesByMedicineId';
import { currentRoleSelectors } from '../../../../../../store/currentRole';
import { ShippingStockAlert } from '../../ShippingStockAlert';
import { toShippingReason } from '../toShippingReason';
import { ShippingStockOperation } from '../types';

const StyledTh = chakra(Th, {
  baseStyle: () => ({
    p: 1,
    pl: 0,
    pb: 2,
  }),
});

const StyledTd = chakra(Td, {
  baseStyle: () => ({
    p: 1,
    pr: 2,
    pl: 0,
  }),
});

type Props = {
  stockOperation: Extract<
    ShippingStockOperation,
    {
      __typename: 'ShippingStockOperationByStockSale';
    }
  >;
  stockSalePriceConfig: StockSalePriceConfig;
};

export function ShippingStockOperationByStockSaleBody({
  stockOperation,
  stockSalePriceConfig: stockOperationPriceConfig,
}: Props) {
  const isManager = currentRoleSelectors.useIsManager();
  const transactionReason = toShippingReason(stockOperation.__typename);
  const medicines = groupMedicinesByMedicineId(stockOperation.operatedMedicines);

  return (
    <Box>
      <ShippingStockAlert message="出庫情報の編集は削除のみ行うことができます。削除した出庫情報は復元できません。" />
      <Stack px={6} pt={3} pb={4}>
        <HStack spacing={4} alignItems="baseline">
          <StockOperationReasonLabel
            transactionType="consumption"
            transactionReason={transactionReason}
            w="80px"
          />
          <StockOperationShippingTargetDateLabel targetDate={stockOperation.targetDate} />
          <Stack w="300px" spacing={1}>
            <Typography variant="body" fontWeight="bold">
              出庫先
            </Typography>
            <Typography variant="body">株式会社Pharmarket</Typography>
          </Stack>
          <TaxLabel w="100px" />
        </HStack>
        <StockOperationShippingNoteLabel note="-" />
      </Stack>
      {medicines.map((medicine) => (
        <Box key={medicine.medicineId} px={6} py={3} borderTopWidth={1}>
          <Stack spacing={3}>
            <HStack justifyContent="space-between" alignItems="baseline">
              <Stack spacing={0.5}>
                <Typography variant="body" fontWeight="bold">
                  {medicine.name}
                </Typography>
                <Typography variant="body2">{medicine.usageGroup.shortUnitOutline}</Typography>
              </Stack>
              <HStack>
                <Typography variant="body" fontWeight="bold">
                  出庫数量
                </Typography>
                <HStack spacing={0}>
                  <Typography variant="body" fontWeight="bold">
                    {medicine.stockUnitQuantitySum}
                  </Typography>
                  <StockUnitSymbolIcon
                    name={medicine.stockUnitSymbol}
                    color="slate.700"
                    position="relative"
                    top="1px"
                    boxSize={4}
                  />
                </HStack>
              </HStack>
            </HStack>
            <Table size="sm" variant="unstyled">
              <Thead>
                <Tr>
                  <StyledTh w="128px">
                    <Typography variant="body2" fontWeight="bold">
                      単位薬価
                    </Typography>
                  </StyledTh>
                  <StyledTh w="128px">
                    <Typography variant="body2" fontWeight="bold">
                      出庫単価
                    </Typography>
                  </StyledTh>
                  <StyledTh w="128px">
                    <Typography variant="body2" fontWeight="bold">
                      出庫金額
                    </Typography>
                  </StyledTh>
                  <StyledTh w="128px">
                    <HStack spacing={1}>
                      <Typography variant="body2" fontWeight="bold">
                        ロット番号
                      </Typography>
                    </HStack>
                  </StyledTh>
                  <StyledTh w="136px">
                    <Typography variant="body2" fontWeight="bold">
                      有効期限
                    </Typography>
                  </StyledTh>
                  <StyledTh w="184px" pl={4}>
                    <Typography variant="body2" fontWeight="bold">
                      出庫数量
                    </Typography>
                  </StyledTh>
                  <StyledTh pr={0} />
                </Tr>
              </Thead>
              <Tbody>
                {medicine.lots.map((lot, index) => (
                  <Tr key={lot.id}>
                    <StyledTd>
                      <HStack spacing={0.5}>
                        {index === 0 && (
                          <>
                            <Typography variant="body" style={{ fontSize: '14px' }} isNumeric>
                              {medicine.stockUnitPrice}
                            </Typography>
                            <Typography variant="body2">円</Typography>
                          </>
                        )}
                      </HStack>
                    </StyledTd>
                    <StyledTd>
                      <HStack spacing={0.5}>
                        {index === 0 && (
                          <>
                            {isManager && !isNullOrUndefined(lot.costPrice) ? (
                              <Typography variant="body" style={{ fontSize: '14px' }} isNumeric>
                                {lot.costPrice}
                              </Typography>
                            ) : (
                              <Typography variant="body">-</Typography>
                            )}
                            <Typography variant="body2">円</Typography>
                          </>
                        )}
                      </HStack>
                    </StyledTd>
                    <StyledTd>
                      <HStack spacing={0.5}>
                        {isManager ? (
                          <Typography variant="body" style={{ fontSize: '14px' }} isNumeric>
                            {calculateSubTotalAmount({
                              unitPrice: lot.costPrice ?? null,
                              stockUnitQuantity: lot.stockUnitQuantity,
                              stockOperationPriceConfig,
                            })}
                          </Typography>
                        ) : (
                          <Typography variant="body">-</Typography>
                        )}
                        <Typography variant="body2">円</Typography>
                      </HStack>
                    </StyledTd>
                    <StyledTd>
                      <Typography variant="body">{lot.lotNumber ?? '-'}</Typography>
                    </StyledTd>
                    <StyledTd>
                      <Typography variant="body">
                        {formatAWSDate(lot.expirationDate) ?? '-'}
                      </Typography>
                    </StyledTd>
                    <StyledTd pl={4}>
                      <Typography variant="body">{lot.stockUnitQuantity}</Typography>
                    </StyledTd>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Stack>
        </Box>
      ))}
    </Box>
  );
}
