import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { sleep } from '@kkhs/hakari-utils';

/**
 * 指定時間waitしてからリクエストする Apollo Link
 *
 * context.delayが指定されている場合、delayだけwaitする
 */
export const createRetryWithDelayLink = (): ApolloLink =>
  setContext(async (_, prevContext) => {
    if (typeof prevContext.delay === 'number') {
      await sleep(prevContext.delay);
    }

    return prevContext;
  });
