import { createIcon } from '@chakra-ui/react';

export const MinusSquareIcon = createIcon({
  displayName: 'MinusSquareIcon',
  viewBox: '0 0 16 17',
  path: (
    <>
      <rect
        x="1"
        y="1.5"
        width="14"
        height="14"
        rx="1"
        stroke="currentColor"
        strokeWidth="2"
        fill="transparent"
      />
      <path d="M4 7.5H12V9.5H4V7.5Z" fill="currentColor" />
    </>
  ),
});
