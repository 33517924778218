import { createIcon } from '@chakra-ui/react';

export const ColdAttentionIcon = createIcon({
  displayName: 'ColdAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M9.39001 17.22C9.95001 16.48 10.78 15.24 11.39 14.16L11.94 14.65C11.38 15.67 10.65 16.84 10.03 17.74L9.39001 17.22ZM11.26 12.06C10.91 11.61 10.17 10.95 9.54001 10.49L10.05 9.98002C10.67 10.41 11.44 11.04 11.79 11.48L11.26 12.06ZM15.33 9.46002C16.15 10.7 17.49 11.96 18.7 12.6C18.55 12.79 18.37 13.05 18.24 13.28C17.06 12.54 15.72 11.28 15 10.19C14.34 11.29 13.1 12.63 11.72 13.5C11.63 13.32 11.44 13.04 11.3 12.88C12.67 12.06 13.99 10.62 14.6 9.46002H15.33ZM17.81 16.72C17.81 17.1 17.74 17.32 17.44 17.45C17.15 17.56 16.67 17.57 15.96 17.57C15.93 17.35 15.83 17.06 15.73 16.85C16.29 16.87 16.75 16.87 16.9 16.85C17.05 16.85 17.09 16.81 17.09 16.69V14.85H14.91V18.51H14.18V14.85H12.3V14.15H17.81V16.71V16.72ZM16.77 12.38V13.07H13.27V12.38H16.77Z"
        fill="#5D6771"
      />
    </g>
  ),
});
