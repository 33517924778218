import { useDemandTakeoverToMedicineCountQuery } from '@/gql/apollo';

type Props = {
  medicineId: string;
};

export function useDemandTakeoverToMedicineCount({ medicineId }: Props) {
  const { data, loading: isLoading } = useDemandTakeoverToMedicineCountQuery({
    variables: { medicineId },
  });

  return {
    count: data?.demandTakeoverToMedicines.length,
    isLoading,
  };
}
