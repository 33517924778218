type Options = {
  /**
   * エラーメッセージ
   */
  message?: string;
};

/**
 * 網羅チェックしきれていない場合に型エラーを出すための関数
 * @param _ neverなはずの値
 * @ref https://typescript-jp.gitbook.io/deep-dive/type-system/discriminated-unions#chekkunodewogeru
 */
export function assertNever(_: never, options?: Options): never {
  const messageNonNull = options?.message ?? 'Unexpected value. Should have been never.';
  throw new Error(messageNonNull);
}
