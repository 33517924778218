/**
 * 引数に渡した HTMLElement の、最も近いスクロールできる親要素を返却する関数
 * 見つからない場合は Document を返却する
 * @param element スクロールできる直近の親要素を探したい子要素を指定する
 */
export function getScrollableParentContainer(element: HTMLElement | null) {
  // 子要素が見つからない場合はとりあえず Document を返す
  if (!element) return document;

  // 指定された子要素の親要素を取得する
  let parent = element.parentElement;

  // 親要素の overflow プロパティが 'auto' あるいは 'scroll' の場合、その親要素を返却する
  // そうでない場合は、さらにその親要素に遡る
  while (parent) {
    const { overflow } = window.getComputedStyle(parent);
    if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')) {
      return parent;
    }
    parent = parent.parentElement;
  }

  // スクロールできる親要素が見つからない場合は Document を返却する
  return document;
}
