import { z } from 'zod';
import {
  stockOperationExternalPriceConfigSchema,
  stockOperationInternalPriceConfigSchema,
} from '@/entities/counterparty';
import { lotNumberSchema } from '@/entities/medicinePackageUnit';
import { MAX_UNIT_QUANTITY, MIN_UNIT_QUANTITY } from '@/entities/stockOperation';
import { MAX_CONTAINER_AMOUNT, MAX_FEE_AMOUNT } from '@/entities/stockOperationPriceConfig';

/** 入庫元名の最大文字数 */
const MAX_COUNTERPARTY_NAME_STRINGS = 32;
/** 備考の最大文字数 */
const MAX_NOTE_STRINGS = 55;

const counterpartyIdSchema = z.string();
const counterpartyNameSchema = z
  .string()
  .max(
    MAX_COUNTERPARTY_NAME_STRINGS,
    `入庫元名は${MAX_COUNTERPARTY_NAME_STRINGS}文字以内で入力してください`,
  );
const feeAmountSchema = z
  .number()
  .int('整数で入力してください')
  .min(0, '手数料には0以上の値を入力してください')
  .max(MAX_FEE_AMOUNT, `手数料は${MAX_FEE_AMOUNT}以下で入力してください`)
  .nullish();
const containerAmountSchema = z
  .number()
  .int('整数で入力してください')
  .min(0, '容器代には0以上の値を入力してください')
  .max(MAX_CONTAINER_AMOUNT, `容器代は${MAX_CONTAINER_AMOUNT}以下で入力してください`)
  .nullish();

/** 入庫共通スキーマ */
const updateStoringStockOperationCommonSchema = z.object({
  /** stock_operations.id */
  id: z.string(),
  targetDate: z.date(),
  note: z.string().max(MAX_NOTE_STRINGS, `${MAX_NOTE_STRINGS}文字以内で入力してください`),
  defaultPriceConfig: z.object({
    internal: stockOperationInternalPriceConfigSchema,
    external: stockOperationExternalPriceConfigSchema,
  }),
  // TODO: undefinedとなる可能性があるため optional にする
  operatedMedicines: z
    .array(
      z.object({
        /** stock_operated_medicines.id */
        id: z.string().nullable(),
        medicineId: z.string(),
        medicineName: z.string(),
        unitOutline: z.string(),
        // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
        shortUnitOutline: z.string(),
        stockUnitSymbol: z.string(),
        stockUnitPrice: z.number(),
        costPrice: z.number().nullish(),
        lots: z
          .array(
            z.object({
              defaultLotNumber: lotNumberSchema.optional(),
              lotNumber: lotNumberSchema.nullable(),
              expirationDate: z.date().nullable(),
              stockUnitQuantity: z.number().min(MIN_UNIT_QUANTITY).max(MAX_UNIT_QUANTITY),
            }),
          )
          .min(1),
      }),
    )
    .min(1)
    .max(5),
});

/** 小分け入庫スキーマ */
export const updateStoringStockOperationBySubdividedPurchaseSchema = z
  .object({
    // NOTE: 取引先を指定しない場合は null となる
    counterpartyName: counterpartyNameSchema.nullable(),
    feeAmount: feeAmountSchema,
    containerAmount: containerAmountSchema,
    stockOperationPriceConfig: stockOperationExternalPriceConfigSchema,
  })
  .merge(updateStoringStockOperationCommonSchema);

export type UpdateStoringStockOperationBySubdividedPurchaseFieldValues = z.infer<
  typeof updateStoringStockOperationBySubdividedPurchaseSchema
>;

/** 法人内入庫スキーマ */
export const updateStoringStockOperationByTransferSchema = z
  .object({
    // NOTE: 取引先を指定しない場合は null となる
    counterpartyId: counterpartyIdSchema.nullable(),
    counterpartyName: counterpartyNameSchema.nullable(),
    stockOperationPriceConfig: stockOperationInternalPriceConfigSchema,
  })
  .merge(updateStoringStockOperationCommonSchema);

export type UpdateStoringStockOperationByTransferFieldValues = z.infer<
  typeof updateStoringStockOperationByTransferSchema
>;

/** 法人内(依頼ベース)入庫スキーマ */
export const updateStoringStockOperationByTransferRequestSchema = z
  .object({
    pharmacyId: z.string(),
    stockOperationPriceConfig: stockOperationInternalPriceConfigSchema,
  })
  .merge(updateStoringStockOperationCommonSchema);

export type UpdateStoringStockOperationByTransferRequestFieldValues = z.infer<
  typeof updateStoringStockOperationByTransferRequestSchema
>;

/** 法人間入庫スキーマ */
export const updateStoringStockOperationByExternalTradeSchema = z
  .object({
    // NOTE: 取引先を指定しない場合は null となる
    counterpartyId: counterpartyIdSchema.nullable(),
    counterpartyName: counterpartyNameSchema.nullable(),
    feeAmount: feeAmountSchema,
    containerAmount: containerAmountSchema,
    stockOperationPriceConfig: stockOperationExternalPriceConfigSchema,
    // NOTE: 作成時の取引先情報を保持する
    storingOriginOnCreated: z
      .union([
        z.object({
          origin: z.literal('externalCounterparty'),
          counterpartyId: counterpartyIdSchema,
          counterpartyName: counterpartyNameSchema,
          priceConfig: stockOperationExternalPriceConfigSchema,
        }),
        z.object({
          origin: z.literal('userInput'),
          counterpartyId: z.null(),
          counterpartyName: counterpartyNameSchema,
        }),
      ])
      .nullable(),
  })
  .merge(updateStoringStockOperationCommonSchema);

export type UpdateStoringStockOperationByExternalTradeFieldValues = z.infer<
  typeof updateStoringStockOperationByExternalTradeSchema
>;

/** その他入庫スキーマ */
export const updateStoringStockOperationByOtherSchema = z
  .object({
    // NOTE: 取引先を指定しない場合は null となる
    counterpartyId: counterpartyIdSchema.nullable(),
    counterpartyName: counterpartyNameSchema.nullable(),
    feeAmount: feeAmountSchema,
    containerAmount: containerAmountSchema,
    stockOperationPriceConfig: stockOperationExternalPriceConfigSchema,
    // NOTE: 作成時の取引先情報を保持する
    storingOriginOnCreated: z
      .union([
        z.object({
          origin: z.literal('externalCounterparty'),
          counterpartyId: counterpartyIdSchema,
          counterpartyName: counterpartyNameSchema,
          priceConfig: stockOperationExternalPriceConfigSchema,
        }),
        z.object({
          // NOTE: 取引卸を選択した場合も、userInput として扱う
          origin: z.literal('userInput'),
          counterpartyId: z.null(),
          counterpartyName: counterpartyNameSchema,
        }),
      ])
      .nullable(),
  })
  .merge(updateStoringStockOperationCommonSchema);

export type UpdateStoringStockOperationByOtherFieldValues = z.infer<
  typeof updateStoringStockOperationByOtherSchema
>;

/** 入庫全区分 FieldValues */
export type UpdateStoringStockOperationFieldValues =
  | UpdateStoringStockOperationBySubdividedPurchaseFieldValues
  | UpdateStoringStockOperationByTransferFieldValues
  | UpdateStoringStockOperationByTransferRequestFieldValues
  | UpdateStoringStockOperationByExternalTradeFieldValues
  | UpdateStoringStockOperationByOtherFieldValues;
