import { HttpLink, ApolloLink } from '@apollo/client';

export function createHttpLink({
  apiUrl,
  stockSaleApiUrl,
}: {
  /** API URL */
  apiUrl: string;
  /** 在庫売却 API URL */
  stockSaleApiUrl: string;
}): ApolloLink {
  return ApolloLink.split(
    (operation) => operation.getContext().api === 'stockSale',
    new HttpLink({ uri: stockSaleApiUrl }),
    new HttpLink({ uri: apiUrl }),
  );
}
