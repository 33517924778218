import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react';
import { useState, useCallback } from 'react';

/**
 * Chakra UI の useDisclosure をラップしたカスタムフック
 * - ローカルステートとして target を保持
 * - 編集ダイアログ等でのユースケースを想定
 */
export function useDisclosureWithTarget<T = string>(props: UseDisclosureProps) {
  const [target, setTarget] = useState<T | undefined>(undefined);
  const disclosure = useDisclosure(props);
  const onOpen = useCallback(
    (value: T | undefined) => {
      setTarget(value);
      disclosure.onOpen();
    },
    [disclosure],
  );
  return {
    ...disclosure,
    onOpen,
    target,
  };
}
