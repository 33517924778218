import { FormControl, HStack, Stack, Box, Button, IconButton, FormLabel } from '@chakra-ui/react';
import { ErrorTooltip, Typography, MinusSquareIcon, PlusSquareIcon } from '@kkhs/hakari-ui';
import { FieldArrayWithId, FieldErrors, FieldValue, FieldValues } from 'react-hook-form';
import { StorageSelect } from '../StorageSelect';

type Props<T extends FieldValues, U extends FieldValue<T>> = {
  fields: FieldArrayWithId<T, U, 'key' | 'id' | 'name'>[];
  errors: FieldErrors<T>[U];
  changeStorage: (i: number, nextValue: { id: string; name: string }) => void;
  addStorage: () => void;
  deleteStorage: (i: number) => void;
};
export function StorageSelects<T extends FieldValues, U extends FieldValue<T>>({
  fields,
  errors,
  changeStorage,
  addStorage,
  deleteStorage,
}: Props<T, U>) {
  return (
    <Box>
      <FormLabel>
        <Typography variant="body2" fontWeight="bold" as="span">
          置き場所
        </Typography>
      </FormLabel>
      <Stack spacing={3} flex="1">
        {fields.map((storage, i) => (
          <FormControl key={storage.key}>
            <HStack>
              <ErrorTooltip placement="top" label={errors[i]?.id?.message} isOpen={!!errors[i]}>
                <HStack spacing={2}>
                  <Box w="300px">
                    <StorageSelect
                      defaultValue={
                        storage.id
                          ? {
                              value: { id: storage.id, name: storage.name },
                              label: storage.name,
                            }
                          : undefined
                      }
                      onChange={(v) => changeStorage(i, v)}
                      isInvalid={!!errors[i]}
                      placeholder="入力してください"
                    />
                  </Box>
                  <IconButton
                    aria-label="置き場所を一つ削除"
                    icon={<MinusSquareIcon color="gray.300" boxSize={4} />}
                    size="sm"
                    variant="unstyled"
                    onClick={() => deleteStorage(i)}
                  />
                </HStack>
              </ErrorTooltip>
            </HStack>
          </FormControl>
        ))}
        <Button
          leftIcon={<PlusSquareIcon color="primary.300" w={5} h={5} />}
          color="slate.300"
          size="sm"
          variant="link"
          alignSelf="flex-start"
          letterSpacing={1}
          mt={1.5}
          onClick={addStorage}
        >
          新規の置き場所を追加
        </Button>
      </Stack>
    </Box>
  );
}
