import {
  Box,
  Center,
  Flex,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useMemo, useRef, useState } from 'react';
import { TbodyTr } from './TbodyTr';
import { Typography } from '../../Typography';
import { TableSortIcon } from '../Table';
import { RendererProps, VirtualizedTableV2Props } from '../types';

function loadingRenderer({ fallbackText = 'データをロードしています。' }: RendererProps) {
  return (
    <Center h="100%">
      <HStack>
        <Spinner color="primary.500" emptyColor="gray.200" />
        <Typography variant="body">{fallbackText}</Typography>
      </HStack>
    </Center>
  );
}

export function VirtualizedTableV2<T extends object>({
  height,
  data,
  sorting: defaultSorting,
  columns,
  estimateSize = 14,
  loading,
  loadingText,
  noRowsRenderer,
  customTbodyTr,
}: VirtualizedTableV2Props<T>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting ?? []);
  const colspan = columns.length;
  const memoizedData = useMemo(() => data ?? [], [data]);
  const table = useReactTable({
    data: memoizedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    debugTable: process.env.NODE_ENV === 'development',
  });
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();
  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => estimateSize,
    measureElement: (element) => element.getBoundingClientRect().height,
  });
  const isLoadingOrNoRows = loading || data?.length === 0;

  function renderBody() {
    if (loading)
      return (
        <Tr>
          <Td colSpan={colspan}>{loadingRenderer({ fallbackText: loadingText })}</Td>
        </Tr>
      );
    if (data?.length === 0 && noRowsRenderer)
      return (
        <Tr>
          <Td colSpan={colspan}>{noRowsRenderer()}</Td>
        </Tr>
      );
    return virtualizer.getVirtualItems().map((virtualRow) => {
      const row = rows[virtualRow.index] as Row<T>;
      return (
        <TbodyTr
          customComponent={() => customTbodyTr?.(row)}
          row={row}
          key={virtualRow.key}
          data-index={virtualRow.index}
          ref={virtualizer.measureElement}
          style={{
            position: 'absolute',
            width: '100%',
            display: 'flex',
            transform: `translateY(${virtualRow.start}px)`,
            minHeight: estimateSize,
          }}
        >
          {row?.getVisibleCells().map((cell) => {
            const width = cell.column.columnDef.meta?.width;
            return (
              <Td
                key={cell.id}
                style={{
                  width,
                  flex: width ? 'none' : 1,
                  textAlign: cell.column.columnDef.meta?.alignRight ? 'right' : 'left',
                }}
                pl="5px"
                pr="5px"
                _first={{ pl: 4 }}
                _last={{ pr: 4 }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            );
          })}
        </TbodyTr>
      );
    });
  }

  return (
    <Box ref={tableContainerRef} style={{ height, overflow: 'auto', contain: 'strict' }}>
      <Box style={{ height: isLoadingOrNoRows ? '100%' : virtualizer.getTotalSize() }}>
        <Table
          size="sm"
          style={{
            tableLayout: 'fixed',
            height: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
          }}
        >
          <Thead bg="white" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sortProps = !header.column.columnDef.meta?.disableDefaultSort
                    ? {
                        cursor: header.column.getCanSort() ? 'pointer' : 'auto',
                        onClick: header.column.getToggleSortingHandler(),
                      }
                    : {};
                  const isAlignRight = header.column.columnDef.meta?.alignRight;
                  const width = header.column.columnDef.meta?.width;
                  const isThVerticalAlignCenter =
                    header.column.columnDef.meta?.thVerticalAlignCenter;
                  return (
                    <Th
                      key={header.id}
                      style={{
                        width,
                        flex: width ? 'none' : 1,
                        textAlign: header.column.columnDef.meta?.alignRight ? 'right' : 'left',
                        lineHeight: '125%',
                        verticalAlign: 'top',
                      }}
                      pl="5px"
                      pr="5px"
                      _first={{ pl: 4 }}
                      _last={{ pr: 4 }}
                    >
                      <Flex
                        alignItems="center"
                        justifyContent={isAlignRight ? 'flex-end' : 'flex-start'}
                        h={isThVerticalAlignCenter ? '100%' : 'auto'}
                      >
                        <Box
                          display="inline-block"
                          style={{ textAlign: isAlignRight ? 'right' : 'left' }}
                          {...sortProps}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Box>
                        {!header.column.columnDef.meta?.disableDefaultSort && (
                          <Flex
                            style={{ alignSelf: 'stretch', alignItems: 'center' }}
                            {...sortProps}
                          >
                            {header.column.getCanSort() && (
                              <Box ml={1}>
                                <TableSortIcon sortStatus={header.column.getIsSorted()} />
                              </Box>
                            )}
                          </Flex>
                        )}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>{renderBody()}</Tbody>
        </Table>
      </Box>
    </Box>
  );
}
