import { Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';
import { forwardRef, Ref, VFC } from 'react';
import { SearchIcon } from '../icons';

type Props = {
  ref?: Ref<HTMLInputElement>;
  size?: 'xs' | 'sm' | 'md' | 'lg';
} & InputProps;

export const SearchInput: VFC<Props> = forwardRef<HTMLInputElement, Omit<Props, 'ref'>>(
  ({ size = 'md', ...rest }: Omit<Props, 'ref'>, ref) => (
    <InputGroup size={size}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="slate.300" />
      </InputLeftElement>
      <Input
        ref={ref}
        variant="outline"
        borderWidth="1px"
        borderColor="gray.300"
        borderRadius="base"
        px="2.5"
        py="1"
        {...rest}
      />
    </InputGroup>
  ),
);
SearchInput.displayName = 'SearchInput';
