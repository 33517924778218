import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

type Props<T = string> = UseDisclosureProps & {
  /** 複数ターゲット */
  multipleTargets: T[];
  /** 単独ダイアログでリクエスト成功した場合の処理 */
  onSuccessSingle?: () => void | Promise<void>;
  /** 複数ダイアログでリクエスト成功した場合の処理 */
  onSuccessMultiple?: () => void | Promise<void>;
};

/**
 * Chakra UI の useDisclosure をラップしたカスタムフック
 * - ローカルステートとして target, isMultiple を保持
 * - 編集ダイアログ等でのユースケースを想定
 */
export function useDisclosureWithTargets<T = string>({
  multipleTargets,
  onSuccessSingle,
  onSuccessMultiple,
  ...rest
}: Props<T>) {
  const [isMultiple, setIsMultiple] = useState(false);
  const [target, setTarget] = useState<T | undefined>();
  const disclosure = useDisclosure(rest);

  /** リクエスト成功した場合の処理 */
  const onSuccess = useCallback(async () => {
    if (isMultiple) {
      await onSuccessMultiple?.();
    } else {
      await onSuccessSingle?.();
    }
    disclosure.onClose();
  }, [disclosure, isMultiple, onSuccessSingle, onSuccessMultiple]);

  /** 単独でダイアログを開いた場合の処理 */
  const onOpen = useCallback(
    (value: T) => {
      setTarget(value);
      setIsMultiple(false);
      disclosure.onOpen();
    },
    [disclosure],
  );

  /** 複数指定でダイアログを開いた場合の処理 */
  const onOpenMultipleTargets = useCallback(() => {
    setIsMultiple(true);
    disclosure.onOpen();
  }, [disclosure, setIsMultiple]);

  const targets = useMemo(() => {
    if (isMultiple) {
      return multipleTargets;
    }
    return target === undefined ? [] : [target];
  }, [isMultiple, multipleTargets, target]);

  return {
    ...disclosure,
    onOpen,
    onOpenMultipleTargets,
    onSuccess,
    targets,
    isOpenedByMultipleTargets: isMultiple,
  };
}
