import { createIcon } from '@chakra-ui/react';

export const WarningTriangleIcon = createIcon({
  displayName: 'WarningTriangleIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3201 4.02687C11.2225 2.65771 12.76 2.65771 13.6624 4.02687L22.5751 17.6167C23.5777 19.1443 22.7199 21 20.9039 21H3.16768C1.35172 21 0.404745 19.1443 1.40742 17.6167L10.3201 4.02687Z"
        fill="currentColor"
      />
      <path d="M13 16H11V18H13V16ZM13 8H11V14H13V8Z" fill="#321D00" />
    </>
  ),
});
