import { convertToJst } from '@kkhs/hakari-utils';
import { parseISO, isToday } from 'date-fns';
import { AWSDateTime } from '../aws';

/**
 * datetime が日本時間(JST)で今日かどうかを判定する関数
 * @param {AWSDateTime} datetime 日時
 * @returns {boolean} 日本時間 (JST) で今日かどうか
 */
export function isJstTodayAWSDateTime(datetime: AWSDateTime): boolean {
  return isToday(convertToJst(parseISO(datetime)));
}
