import { useEffect, useState } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';

const fixVersion = '2024/02/28';
/**
 * feature flagをinitializeする
 * @returns {boolean} initializeが成功したか
 */
export const useInitializeFeatureFlags = () => {
  const { useSetupFeatureFlag } = repositoryContainerGetter.featureFlag();
  const { setContext, setupDone } = useSetupFeatureFlag();
  const currentPharmacy = currentPharmacySelectors.useValue();

  // LDが初期化されず固まる現象が見られるので、n秒後に強制的に初期化が完了したとみなす
  const [state, setState] = useState(false);
  useEffect(
    () => {
      setTimeout(() => {
        const interval1時のSetupDone = setupDone;
        repositoryContainerGetter.userMonitoring().sendLog(`LDが初期化 ${3_000} ms 後`, {
          result3: interval1時のSetupDone,
          fixVersion,
        });
        setState(true);
        if (setupDone) return;

        setTimeout(() => {
          const interval2時のSetupDone = setupDone;
          repositoryContainerGetter.userMonitoring().sendLog(`LDが初期化 ${10_000} ms 後`, {
            result3: interval1時のSetupDone,
            result10: interval2時のSetupDone,
            fixVersion,
          });
          if (setupDone) return;

          setTimeout(() => {
            repositoryContainerGetter.userMonitoring().sendLog(`LDが初期化 ${30_000} ms 後`, {
              result3: interval1時のSetupDone,
              result10: interval2時のSetupDone,
              result30: setupDone,
              fixVersion,
            });
          }, 20_000);
        }, 7_000);
      }, 3_000);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [],
  );

  useEffect(() => {
    if (currentPharmacy?.musubiCode) {
      setContext({ pharmacy: currentPharmacy });
    }
  }, [currentPharmacy, setContext]);

  return state || setupDone;
};
