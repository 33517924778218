import { useCallback, useEffect, useState } from 'react';
import { getScrollableParentContainer } from '../../../utils';

/**
 * 提供された ref（HTMLElement） の位置情報からスタイルを返却するカスタムフック
 */

type UseGetStyles = {
  ref: React.RefObject<HTMLElement>;
  offset?: number;
  zIndex?: number;
};

export function useGetStyles({ ref, offset = 4, zIndex }: UseGetStyles) {
  const [styles, setStyles] = useState({
    width: 0,
    left: 0,
    top: 0,
    zIndex,
    transform: `translate(0, 0)`,
  });

  const update = useCallback(() => {
    if (ref.current) {
      const { width, left, bottom } = ref.current.getBoundingClientRect();
      setStyles((prev) => ({
        ...prev,
        width,
        transform: `translate(${left}px, ${bottom + offset}px)`,
      }));
    }
  }, [ref, offset]);

  useEffect(() => {
    const scrollableParent = getScrollableParentContainer(ref.current);
    window.addEventListener('resize', update);
    scrollableParent.addEventListener('scroll', update);
    update();

    return () => {
      window.removeEventListener('resize', update);
      scrollableParent.removeEventListener('scroll', update);
    };
  }, [update, ref]);

  return {
    styles,
    update,
  };
}
