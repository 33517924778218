import { forwardRef } from 'react';
import { root } from './index.css';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';

type Props = {
  size?: 'md' | 'sm' | 'xs';
} & Omit<ComponentPropsWithoutRef<'div'>, 'className'>;

export const SpinnerV2 = forwardRef<ElementRef<'div'>, Props>(({ size = 'md', ...rest }, ref) => (
  <div className={root({ size })} {...rest} ref={ref} />
));

SpinnerV2.displayName = 'SpinnerV2';
