import { GridItem, HStack, Skeleton, Stack } from '@chakra-ui/react';
import { RadioGroup, Typography } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  /** 消費税表示の field name */
  shouldShowTaxFieldName: FieldPath<T>;
  /** 消費税計算単位の field name */
  taxCalculationTargetFieldName: FieldPath<T>;
  /** 消費税端数処理の field name */
  taxRoundingMethodFieldName: FieldPath<T>;
  isLoading?: boolean;
  showCalculationTarget?: boolean;
  isDisabled: boolean;
  /** 消費税表示する/しないが変わった際の callback */
  onAfterChangeShouldShowTax: (value: 'true' | 'false') => void;
} & Pick<UseFormReturn<T>, 'control'>;

export function TaxForm<T extends FieldValues>({
  shouldShowTaxFieldName,
  taxCalculationTargetFieldName,
  taxRoundingMethodFieldName,
  isLoading = false,
  showCalculationTarget = false,
  isDisabled,
  onAfterChangeShouldShowTax,
  control,
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Typography variant="body" fontWeight="bold">
          消費税表示
        </Typography>
      </GridItem>
      <GridItem>
        <HStack>
          <Skeleton isLoaded={!isLoading}>
            <Controller
              name={shouldShowTaxFieldName}
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  options={[
                    { value: 'true', label: '表示する' },
                    { value: 'false', label: '表示しない' },
                  ]}
                  value={value}
                  onChange={(nextValue) => {
                    onChange(nextValue);
                    onAfterChangeShouldShowTax(nextValue as 'true' | 'false');
                  }}
                  itemMinWidth="28"
                />
              )}
            />
          </Skeleton>
        </HStack>
        {showCalculationTarget && (
          <HStack pt="4">
            <Stack spacing={1}>
              <Typography
                variant="body2"
                fontWeight="bold"
                color={isDisabled ? 'gray.200' : undefined}
              >
                消費税計算単位
              </Typography>
              <Skeleton isLoaded={!isLoading}>
                <Controller
                  name={taxCalculationTargetFieldName}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      options={[
                        { value: 'operation', label: '伝票ごと' },
                        { value: 'item', label: '明細ごと' },
                      ]}
                      isDisabled={isDisabled}
                      value={value ?? undefined}
                      onChange={onChange}
                      itemMinWidth="28"
                    />
                  )}
                />
              </Skeleton>
            </Stack>
          </HStack>
        )}
        <HStack pt="4">
          <Stack spacing={1}>
            <Typography
              variant="body2"
              fontWeight="bold"
              color={isDisabled ? 'gray.200' : undefined}
            >
              消費税端数処理
            </Typography>
            <Skeleton isLoaded={!isLoading}>
              <Controller
                name={taxRoundingMethodFieldName}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    options={[
                      { value: 'none', label: '処理しない' },
                      { value: 'round', label: '四捨五入' },
                      { value: 'roundDown', label: '切り捨て' },
                      { value: 'roundUp', label: '切り上げ' },
                    ]}
                    isDisabled={isDisabled}
                    value={value}
                    onChange={onChange}
                    itemMinWidth="28"
                  />
                )}
              />
            </Skeleton>
          </Stack>
        </HStack>
      </GridItem>
    </>
  );
}
