import { StockOperationReason } from '@/gql/docs';

/**
 * 出庫単価（税抜）を取得する
 * - 廃棄の場合、出庫単価は登録できないため何も返さない
 * - 法人内出庫,法人間出庫,その他出庫の場合は設定された値を返す
 */
export function getPrice({
  operationMedicine,
  reason,
}: {
  operationMedicine: {
    price?: number | null;
    medicineStockUnitPrice: number;
  };
  /** 区分 */
  reason: StockOperationReason;
}) {
  if (reason === 'disposal') {
    return undefined;
  }
  return operationMedicine.price;
}
