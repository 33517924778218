import { AppRoot } from '@/app';
// eslint-disable-next-line no-restricted-imports
import type { AppProps } from 'next/app';

function App({ Component, pageProps }: AppProps) {
  return (
    <AppRoot>
      <Component {...pageProps} />
    </AppRoot>
  );
}

export default App;
