import { AdoptedMedicineSearchResultsQuery } from '@/gql/apollo';
import { UpdateStoringStockOperationFieldValues as FieldValues } from '../../schema';

type Args = {
  data: AdoptedMedicineSearchResultsQuery | undefined;
  currentOperatedMedicines: FieldValues['operatedMedicines'];
};

export function filterByAdjustmentSearchResults({ data, currentOperatedMedicines }: Args) {
  /** 選択中の medicineId 配列 */
  const selectedOperatedMedicineIds = currentOperatedMedicines.map(({ medicineId }) => medicineId);

  return data?.adoptedMedicines.edges.filter(
    ({ node }) =>
      // 選択中の医薬品を除く
      !selectedOperatedMedicineIds.includes(node.medicine.id),
  );
}
