import { Stack, StackProps } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { TransactionReason, TransactionType } from '@/gql/docs';
import { getTransactionReasonLabel } from '../../functions';
import { DisposalReason } from '../../models/disposalReason';

type Props = {
  transactionType: TransactionType;
  transactionReason: TransactionReason;
  transactionSubreason?: DisposalReason;
} & StackProps;

export function StockOperationReasonLabel({
  transactionType,
  transactionReason,
  transactionSubreason,
  w = '120px',
  ...rest
}: Props) {
  return (
    <Stack w={w} spacing={1} {...rest}>
      <Typography variant="body" fontWeight="bold">
        {transactionType === 'replenishment' ? '入庫区分' : '出庫区分'}
      </Typography>
      <Typography variant="body">
        {getTransactionReasonLabel({
          type: transactionType,
          reason: transactionReason,
          subreason: transactionSubreason,
        })}
      </Typography>
    </Stack>
  );
}
