export function splitArray<A>(arr: Array<A>, size: number): Array<Array<A>> {
  if (size <= 0) {
    throw new Error('Invalid division size');
  }

  const ret: Array<Array<A>> = [];
  const n = Math.floor((arr.length - 1) / size);
  for (let i = 0; i < n + 1; i += 1) {
    const b = i * size;
    const e = b + size > arr.length ? arr.length : b + size;
    ret.push(arr.slice(b, e));
  }
  return ret;
}
