import { createIcon } from '@chakra-ui/react';

export const BlisterUnitSymbolIcon = createIcon({
  displayName: 'Blister',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1927_1795)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.40002 2.1H6.40002V1H7.00002V2.1C7.00002 6.29 4.46002 7.21 2.47002 7.5L2.20002 6.75C5.71002 6.39 6.10002 3.95 6.20002 2.9H1.40002V2.1ZM9.39998 1.5H8.59998V5H9.39998V1.5ZM9.39993 7.5C11.3299 7.41 13.3999 6.79 13.3999 4.42V1.5H12.5999V4.44C12.5999 5.74 11.7399 6.63 9.29993 6.69L9.39993 7.5ZM8.00002 1H7.40002V2.9H8.00002V1ZM7.40002 10.87C8.20002 10.23 8.69002 9.47 9.04002 8.5V8.51H13C13 12.89 9.57002 14.19 8.09002 14.51L7.85002 13.81C9.17002 13.55 10.14 13 10.85 12.32C10.35 11.9 9.54002 11.32 8.46002 10.81C8.09002 11.22 7.80003 11.43 7.80003 11.43L7.40002 10.87ZM8.90002 10.24C9.41002 10.51 10.54 11.13 11.33 11.77V11.76C11.93 10.97 12.24 10.07 12.3 9.19H9.50002C9.32002 9.6 9.11002 9.95 8.90002 10.24ZM15 12.2H12.4V13H15V12.2ZM6.49998 8.5C6.35998 9.98 5.76998 11.1 5.04998 11.95C5.90998 12.69 6.89998 13.72 6.89998 13.72L6.19997 14.3C6.19997 14.3 5.28998 13.25 4.49998 12.52C3.06998 13.85 1.39998 14.3 1.39998 14.3L1.09998 13.56C1.09998 13.56 4.76998 12.7 5.56998 9.3H1.39998V8.5H6.49998Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1927_1795">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
});
