import { Box, HStack } from '@chakra-ui/react';
import { Typography, WarningOutlineIcon } from '@kkhs/hakari-ui';

type Props = {
  message: string;
};

export function ShippingStockAlert({ message }: Props) {
  return (
    <Box borderBottomWidth={1}>
      <HStack bg="red.50" borderRadius="base" m={4} px={4} py={2}>
        <WarningOutlineIcon w={6} h={6} color="red.500" />
        <Typography variant="body" color="red.500">
          {message}
        </Typography>
      </HStack>
    </Box>
  );
}
