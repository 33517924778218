import { useMemo } from 'react';
import { calculateTotalAmount, calculateTotalTaxAmount } from '@/entities/stockOperation';
import { ファルマ売却伝票金額, isStockSalePriceConfig } from '@/entities/stockOperation/models';
import {
  ShippingDefaultPriceConfig,
  ShippingStockOperation,
} from '../../ShippingDialogContent/types';

type Props = {
  stockOperation?: ShippingStockOperation;
  defaultPriceConfig?: ShippingDefaultPriceConfig;
};

export function useTotalAmount({ stockOperation, defaultPriceConfig }: Props) {
  const totalAmount = useMemo(() => {
    if (
      !defaultPriceConfig ||
      !stockOperation ||
      // NOTE: 廃棄の場合は金額を表示しない
      stockOperation.__typename === 'ShippingStockOperationByDisposal'
    ) {
      return {
        totalAmount: null,
        totalTaxAmount: null,
        showTotalAmount: false,
      };
    }

    const operationMedicines = stockOperation.operatedMedicines.map((item) => ({
      price: item.costPrice,
      medicineStockUnitPrice: item.stockUnitPrice,
      stockUnitQuantity: item.stockUnitQuantity,
    }));

    switch (stockOperation.__typename) {
      case 'ShippingStockOperationByExternalTrade': {
        // NOTE: 法人間の場合、作成時の金額設定があれば利用し、なければ defaultPriceConfig の external のPriceConfigを利用する
        const stockOperationPriceConfig =
          stockOperation.shippingOriginOnCreated?.priceConfig ??
          defaultPriceConfig.external.stockOperationPriceConfig;

        const totalTaxAmount = calculateTotalTaxAmount({
          operationMedicines,
          taxCalculationTarget: stockOperationPriceConfig.taxCalculationTarget,
          itemRoundingMethod: stockOperationPriceConfig.itemRoundingMethod,
          taxRoundingMethod: stockOperationPriceConfig.taxRoundingMethod,
          feeAmount: stockOperation.feeAmount ?? undefined,
          containerAmount: stockOperation.containerAmount ?? undefined,
        });
        return {
          totalAmount: calculateTotalAmount({
            operationMedicines,
            totalTaxAmount,
            stockOperationPriceConfig,
            feeAmount: stockOperation.feeAmount ?? undefined,
            containerAmount: stockOperation.containerAmount ?? undefined,
            reason: 'externalTrade',
          }),
          totalTaxAmount,
          showTotalAmount: true,
          showTax: stockOperationPriceConfig.shouldShowTax,
        };
      }
      case 'ShippingStockOperationByStockSale': {
        const stockOperationPriceConfig = stockOperation.stockSalePriceConfig;
        if (!isStockSalePriceConfig(stockOperationPriceConfig)) {
          throw new Error(
            `StockSalePriceConfigのフィールドに予期せぬ値が含まれています: ${stockOperationPriceConfig}`,
          );
        }

        const { 合計金額, 内税 } = ファルマ売却伝票金額
          .create(operationMedicines.map((m) => ({ 金額: m.price, 数量: m.stockUnitQuantity })))
          .税込み合計金額を計算(stockOperationPriceConfig);

        return {
          totalAmount: 合計金額,
          totalTaxAmount: 内税,
          showTotalAmount: true,
          showTax: stockOperationPriceConfig.shouldShowTax,
        };
      }
      case 'ShippingStockOperationByOther': {
        // NOTE: その他の場合、作成時の金額設定があれば利用し、なければ defaultPriceConfig の external のPriceConfigを利用する
        const stockOperationPriceConfig =
          stockOperation.shippingOriginOnCreated?.priceConfig ??
          defaultPriceConfig.external.stockOperationPriceConfig;

        const totalTaxAmount = calculateTotalTaxAmount({
          operationMedicines,
          taxCalculationTarget: stockOperationPriceConfig.taxCalculationTarget,
          itemRoundingMethod: stockOperationPriceConfig.itemRoundingMethod,
          taxRoundingMethod: stockOperationPriceConfig.taxRoundingMethod,
        });
        return {
          totalAmount: calculateTotalAmount({
            operationMedicines,
            totalTaxAmount,
            stockOperationPriceConfig,
            reason: 'other',
          }),
          totalTaxAmount,
          showTotalAmount: true,
          showTax: stockOperationPriceConfig.shouldShowTax,
        };
      }
      case 'ShippingStockOperationByTransfer':
      case 'ShippingStockOperationByTransferRequest': {
        return {
          totalAmount: calculateTotalAmount({
            operationMedicines,
            totalTaxAmount: null,
            // NOTE: 法人内の場合、defaultPriceConfig の internal のPriceConfigを利用する
            stockOperationPriceConfig: defaultPriceConfig.internal.stockOperationPriceConfig,
            reason: 'transfer',
          }),
          totalTaxAmount: null,
          showTotalAmount: true,
        };
      }
      default:
        return {
          totalAmount: null,
          totalTaxAmount: null,
          showTotalAmount: false,
        };
    }
  }, [defaultPriceConfig, stockOperation]);
  return totalAmount;
}
