import { Tag, TagProps } from '@chakra-ui/react';

export function RequiredTag({ mt = 0.5, ...rest }: TagProps) {
  return (
    <Tag
      bg="red.500"
      color="white"
      fontSize="10px"
      size="xs"
      px={1.5}
      py={0.5}
      mt={mt}
      borderRadius="2px"
      {...rest}
    >
      必須
    </Tag>
  );
}
