import { UseRadioGroupProps, Box, useRadioGroup, Text } from '@chakra-ui/react';
import { RadioItem } from './RadioItem';

type Option = {
  value: string | undefined;
  label: string;
  // 注意: 非活性状態の選択肢が選択されて初期表示されることがあるため、defaultValueに活性状態の選択肢を指定しておく必要があります。
  isDisabled?: boolean;
};

export type RowProps = {
  options: Option[];
} & UseRadioGroupProps;

export function RowRadioButtonGroup(props: RowProps) {
  const { options } = props;

  const { getRootProps, getRadioProps } = useRadioGroup(props);
  const group = getRootProps();

  return (
    <Box {...group} borderColor="gray.300" borderTopWidth="1px" defaultValue="1">
      {options.map((item) => {
        const radio = getRadioProps({ value: item.value });

        return (
          <RadioItem
            key={item.label}
            label={<Text>{item.label}</Text>}
            isDisabled={item.isDisabled}
            {...radio}
          />
        );
      })}
    </Box>
  );
}
