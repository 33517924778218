import { atom, selector, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { OgasRegistrationInfo as OgasRegistrationInfoDoc } from '@/gql/docs';
import { recoilAtomKeys, recoilSelectorKeys } from '@/shared/constants';
import { localStorageEffect } from '../../lib/recoil';

type OgasRegistrationInfo = Omit<OgasRegistrationInfoDoc, '__typename'>;

type OgasRegistrationInfoSelector = {
  useValue: () => OgasRegistrationInfo | undefined;
};

type OgasRegistrationInfoActions = {
  useSetState: () => (ogasRegistrationInfo: OgasRegistrationInfo) => void;
  useReset: () => () => void;
};

const ogasRegistrationInfo = atom<OgasRegistrationInfo>({
  key: recoilAtomKeys.OGAS_REGISTRATION_INFO_STATE,
  default: undefined,
  effects_UNSTABLE: [
    localStorageEffect<OgasRegistrationInfo>(recoilAtomKeys.OGAS_REGISTRATION_INFO_STATE),
  ],
});

const ogasRegistrationInfoSelector = selector<OgasRegistrationInfo>({
  key: recoilSelectorKeys.OGAS_REGISTRATION_INFO,
  get: ({ get }) => get(ogasRegistrationInfo),
});

export const ogasRegistrationInfoSelectors: OgasRegistrationInfoSelector = {
  useValue: () => useRecoilValue(ogasRegistrationInfoSelector),
};

export const ogasRegistrationInfoActions: OgasRegistrationInfoActions = {
  useSetState: () => useSetRecoilState(ogasRegistrationInfo),
  useReset: () => useResetRecoilState(ogasRegistrationInfo),
};
