import { createIcon } from '@chakra-ui/react';

export const AdoptionRestartIcon = createIcon({
  displayName: 'AdoptionRestartIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4602 1.61091C14.3123 -0.536971 10.8299 -0.536971 8.68198 1.61091L1.61091 8.68198C-0.53697 10.8299 -0.536971 14.3123 1.61091 16.4602C3.3745 18.2237 6.03778 18.5393 8.12331 17.4068C8.78809 21.1542 12.0617 24 16 24C20.4183 24 24 20.4183 24 16C24 12.0617 21.1542 8.78805 17.4068 8.12328C18.5393 6.03775 18.2237 3.37449 16.4602 1.61091ZM14.9529 8.0679L15.0459 7.97487C16.4128 6.60804 16.4128 4.39196 15.0459 3.02513C13.6791 1.65829 11.463 1.65829 10.0962 3.02513L6.9107 6.21062L10.704 10.0039C11.8682 8.97476 13.3345 8.27947 14.9529 8.0679ZM9.99729 10.7114L6.20359 6.91773L3.02513 10.0962C1.65829 11.463 1.65829 13.6791 3.02513 15.0459C4.39196 16.4128 6.60804 16.4128 7.97487 15.0459L8.06793 14.9529C8.27905 13.338 8.9718 11.8745 9.99729 10.7114ZM19.357 11.643C18.4282 10.9263 17.2639 10.5 16 10.5C12.9631 10.5 10.5011 12.9614 10.5 15.998H9L11.5 18.498L14 15.998H12C12.0011 13.7898 13.7915 12 16 12C16.8492 12 17.6365 12.2646 18.2841 12.7159L19.357 11.643ZM16 21.5C14.7361 21.5 13.5718 21.0737 12.643 20.357L13.7159 19.2841C14.3635 19.7354 15.1508 20 16 20C18.2091 20 20 18.2091 20 16L18 16L20.5 13.5L23 16L21.5 16C21.5 19.0376 19.0376 21.5 16 21.5Z"
        fill="currentColor"
      />
    </svg>
  ),
});
