import { useQuery } from '@apollo/client';
import { Box, FormControl, Skeleton, Stack, useTheme } from '@chakra-ui/react';
import { SelectV2 } from '@kkhs/hakari-ui';
import { FieldPath, FieldValues, UseFormReturn, useController } from 'react-hook-form';
import { graphql } from '@/gql';

const queryDoc = graphql(`
  query OrganizationGroupSelect {
    organizationGroups {
      organizationGroups {
        id
        groupName
      }
    }
  }
`);

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  control: UseFormReturn<T>['control'];
  onClear: () => void;
  /** 必須ラベルを表示するか */
  isRequired?: boolean;
  defaultOrganizationGroupId: string | null;
};

export function OrganizationGroupSelect<T extends FieldValues>({
  name,
  control,
  onClear,
  isRequired = false,
  defaultOrganizationGroupId,
}: Props<T>) {
  const {
    field: { onChange, ...fieldProps },
  } = useController<T>({
    name,
    control,
  });
  // Chakra UI のテーマで最も高い z-index を使用する
  const {
    zIndices: { tooltip },
  } = useTheme();
  const { data, loading } = useQuery(queryDoc);

  const organizationGroups = data?.organizationGroups.organizationGroups;

  const options =
    organizationGroups?.map((g) => ({
      value: g.id,
      label: g.groupName,
    })) ?? [];

  const organizationGroup = organizationGroups?.find((g) => g.id === defaultOrganizationGroupId);
  const defaultValue = organizationGroup
    ? {
        value: organizationGroup.id,
        label: organizationGroup.groupName,
      }
    : undefined;

  return (
    <FormControl isRequired={isRequired}>
      <Stack spacing={2}>
        <Box w="280px">
          <Skeleton isLoaded={!loading}>
            <SelectV2<string>
              {...fieldProps}
              options={options}
              defaultValue={defaultValue}
              onChange={onChange}
              zIndex={tooltip}
              size="sm"
              onClear={onClear}
            />
          </Skeleton>
        </Box>
      </Stack>
    </FormControl>
  );
}
