import { relayStylePagination } from '@apollo/client/utilities';
// fixme: satisfies使えるようになったらinMemoryCacheConfigに対して使う
// import type { InMemoryCacheConfig as BaseConfig } from '@apollo/client';

export type InMemoryCacheConfig = typeof inMemoryCacheConfig;

export const inMemoryCacheConfig = {
  possibleTypes: {
    TradeSlip: ['TransferSlip', 'ExternalTradeSlip'],
  },
  typePolicies: {
    Query: {
      fields: {
        adoptedMedicines: relayStylePagination(['filter']),
        adoptedMedicineCostPriceListItems: relayStylePagination(['filter']),
        counterparties: relayStylePagination(['filter']),
        costPriceMasters: relayStylePagination(['filter']),
        medicineAdoptionListItems: relayStylePagination(['filter']),
        medicineMustBuyListItems: relayStylePagination(),
        medicineOrders: relayStylePagination(['filter', 'first']),
        medicineOrderCandidateListItems: relayStylePagination(['filter']),
        medicineOrderProposalListItems: relayStylePagination(['filter']),
        medicineReceivings: relayStylePagination(['filter']),
        medicineStockListItems: relayStylePagination(['filter', 'sortBy']),
        medicineStockListItemsV2: relayStylePagination(['filter', 'sortBy']),
        medicineTransactionListItems: relayStylePagination(['medicineId', 'filter']),
        patientByMedicineListItems: relayStylePagination(['medicineId', 'sortBy']),
        patientSummariesV2: relayStylePagination(['filter']),
        prescriptions: relayStylePagination(['filter']),
        prescriptionForecastListItems: relayStylePagination(['filter']),
        shippingDetailReports: relayStylePagination(),
        stockMedicineReports: relayStylePagination(),
        stocktakes: relayStylePagination(),
        storageListItems: relayStylePagination(['filter']),
        storingDetailReports: relayStylePagination(),
        tradeSlips: relayStylePagination(['filter']),
        tradeSlipsV2: relayStylePagination(['filter']),
        ownGiveTransferRequests: relayStylePagination(),
        othersGiveTransferRequests: relayStylePagination(),
        ownTakeTransferRequests: relayStylePagination(),
        othersTakeTransferRequests: relayStylePagination(),
        unstoredTransferRequests: relayStylePagination(),
        storedTransferRequests: relayStylePagination(),
        unshippedTransferRequests: relayStylePagination(),
        workingStocktakeMedicineListItemsV2: relayStylePagination(),
        unstockedMedicineReceivingsBySlip: relayStylePagination([
          'receiveDate',
          'slipNumber',
          'wholesaleId',
        ]),
      },
    },
    Medicine: {
      keyFields: ['id', 'pharmacyId'],
    },
  },
}; // satisfies BaseConfig;
