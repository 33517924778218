import { HStack, Tag } from '@chakra-ui/react';
import { ImportIcon, Typography } from '@kkhs/hakari-ui';

export function StoringStockOperationUpdateHeading() {
  return (
    <HStack spacing={4}>
      <HStack>
        <ImportIcon color="slate.300" boxSize={12} />
        <Typography variant="h2">在庫を増やす (入庫)</Typography>
      </HStack>
      <Tag w="46px" bg="white" borderWidth={1} borderColor="slate.300" borderRadius="none">
        <Typography
          align="center"
          variant="body"
          fontWeight="bold"
          color="slate.300"
          whiteSpace="nowrap"
        >
          編集
        </Typography>
      </Tag>
    </HStack>
  );
}
