import { HStack } from '@chakra-ui/react';
import { Typography, WarningOutlineIcon } from '@kkhs/hakari-ui';

export function StockOperationShippingTradeSettingAlert() {
  return (
    <HStack spacing={1}>
      <WarningOutlineIcon boxSize="18px" color="red.500" />
      <Typography variant="body2" color="gray.700">
        取引先ごとの設定は、最新の取引先設定が適用されます。
      </Typography>
    </HStack>
  );
}
