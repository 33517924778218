import { HStack, NumberInput, NumberInputField } from '@chakra-ui/react';
import { ErrorTooltip, Typography } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

/** 原価の最大金額 */
export const MAX_COST_PRICE = 99999999.99;

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isManager: boolean;
};

/**
 * 入庫原価フォーム
 * - 管理者権限の場合: フォームを表示する
 * - 一般権限の場合: 入庫原価編集不可のためフォームを表示しない(ハイフンを表示する)
 */
export function CostPriceForm<T extends FieldValues>({ name, isManager }: Props<T>) {
  const { control } = useFormContext<T>();

  if (!isManager) {
    return (
      <Typography variant="body2" pt={2}>
        -
      </Typography>
    );
  }
  return (
    <HStack spacing={1}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <ErrorTooltip label={error?.message} isOpen={!!error?.message} placement="bottom-start">
            <NumberInput
              size="sm"
              borderColor="gray.300"
              defaultValue={value ?? undefined}
              w="120px"
              textAlign="right"
              min={0}
              max={MAX_COST_PRICE}
              precision={2}
              inputMode="numeric"
              flexGrow="initial"
              onChange={(_, numValue) => onChange(Number.isFinite(numValue) ? numValue : undefined)}
            >
              <NumberInputField
                borderRadius={4}
                paddingX={2}
                paddingY={1}
                color="slate.700"
                textAlign="right"
                fontSize="15px"
              />
            </NumberInput>
          </ErrorTooltip>
        )}
      />
      <Typography variant="body2">円</Typography>
    </HStack>
  );
}
