import { createIcon } from '@chakra-ui/react';

export const CapsuleSeparatelyIcon = createIcon({
  displayName: 'CapsuleSeparatelyIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.219 9.949c.687 1.757-.172 3.666-1.826 4.329l-.014.005-7.393 2.784c-1.721.685-3.552-.345-4.213-2.036-.672-1.72.069-3.706 1.847-4.337l7.387-2.781c1.72-.685 3.551.345 4.212 2.036zm-2.34 3.027c.931-.378 1.444-1.473 1.036-2.518-.434-1.111-1.528-1.59-2.395-1.243l-.014.005-2.459.926c.164.118.328.252.484.404.473.459.889 1.092 1.017 1.93.066.43.053.895-.052 1.393l2.383-.897zm-8.773-.97l3.203-1.205a4.088 4.088 0 01.256.104c.31.138.682.349.99.65.304.293.536.662.61 1.137.066.436.008 1.028-.354 1.815l-.151.057-3.174 1.195-.014.005c-.866.348-1.96-.131-2.394-1.243-.423-1.081.072-2.178 1.014-2.51l.014-.005zm9.02 4.464c-1.764-.253-3.461.97-3.745 2.836-.274 1.795.796 3.602 2.63 3.87l7.787 1.285c1.856.342 3.49-1.008 3.768-2.833.273-1.795-.797-3.602-2.63-3.87l-7.795-1.286-.014-.002zm-2.361 3.047c.168-1.108 1.16-1.801 2.156-1.662l2.512.414a3.88 3.88 0 00-.741 1.18 3.376 3.376 0 00-.085 2.18c.06.21.134.408.217.592l-2.592-.428-.015-.002c-.924-.133-1.632-1.095-1.452-2.274zm5.892 3.006l3.376.558.015.002c.982.184 1.959-.518 2.134-1.666.18-1.18-.528-2.142-1.453-2.274l-.014-.002-3.346-.552-.159-.026c-.707.5-1.054.984-1.214 1.395a1.977 1.977 0 00-.042 1.29 3.455 3.455 0 00.575 1.114c.046.061.09.115.128.161zm-11.89-2.633c-1.401-1.101-3.484-.89-4.662.583-1.134 1.418-1.111 3.518.343 4.667l6.101 5.006c1.436 1.224 3.526.873 4.68-.57 1.134-1.417 1.11-3.517-.343-4.666l-6.108-5.01-.011-.01zm-3.57 1.458c.701-.876 1.907-.98 2.7-.361l1.968 1.615a3.88 3.88 0 00-1.233.651A3.376 3.376 0 0012.47 25.1c-.053.211-.087.42-.107.62l-2.032-1.666-.011-.01c-.735-.576-.867-1.763-.121-2.695zm3.6 5.55l2.646 2.17.011.01c.76.65 1.956.53 2.681-.376.746-.932.614-2.119-.12-2.696l-.012-.009-2.621-2.15-.125-.103c-.863.08-1.405.325-1.75.602-.374.3-.577.686-.68 1.095a3.455 3.455 0 00-.06 1.253c.01.076.02.144.03.204z"
      fill="currentColor"
    />
  ),
});
