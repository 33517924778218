import { FormControl, HStack, FormLabel } from '@chakra-ui/react';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { GroupPharmacySelect } from '../GroupPharmacySelect';

type Props<T extends FieldValues> = {
  label: string;
  name: FieldPath<T>;
  isDisabled?: boolean;
  control: UseFormReturn<T>['control'];
};

export function GroupPharmacySelectForm<T extends FieldValues>({
  label,
  name,
  isDisabled = false,
  control,
}: Props<T>) {
  return (
    <FormControl w="240px">
      <HStack justify="space-between">
        <FormLabel fontWeight="bold">{label}</FormLabel>
      </HStack>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <GroupPharmacySelect
            size="sm"
            placeholder="-"
            value={value}
            onChange={onChange}
            onClear={() => {
              onChange(null);
            }}
            isDisabled={isDisabled}
          />
        )}
      />
    </FormControl>
  );
}
