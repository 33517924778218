import { createIcon } from '@chakra-ui/react';

export const ForecastAccuracyMiddleIcon = createIcon({
  displayName: 'ForecastAccuracyMiddleIcon',
  viewBox: '0 0 30 30',
  path: (
    <path
      d="M14.922 10.848l4.852 8.728H10.07l4.852-8.728zm0-4.848L6 22h18L14.922 6z"
      fill="currentColor"
    />
  ),
});
