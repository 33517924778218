import { useApolloClient } from '@apollo/client';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import { EditIcon, showSuccessToast } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { ExternalCounterpartyCreateDialogLink } from '@/features/externalCounterpartyCreate';
import { InternalCounterpartyCreateDialogLink } from '@/features/internalCounterpartyCreate';
import {
  StockOperationDeleteAlert,
  StockOperationDeleteFooter,
} from '@/features/storingStockOperationDelete';
import { StoringStockOperationUpdateDialog } from '@/features/storingStockOperationUpdate';
import { MedicineTransactionListItemsDocument } from '@/gql/docs';

type Props = {
  stockOperationId: string;
};

export function StockOperationEditButton({ stockOperationId }: Props) {
  const apolloClient = useApolloClient();

  const editStockOperationDialogDisclosure = useDisclosure({
    id: 'edit-stock-operation-dialog-disclosure',
  });
  const onSuccess = useCallback(async () => {
    showSuccessToast({
      id: 'delete-stock-operation-success',
      title: '入出庫履歴を削除しました',
    });
    await apolloClient.refetchQueries({
      include: [MedicineTransactionListItemsDocument],
    });
    editStockOperationDialogDisclosure.onClose();
  }, [apolloClient, editStockOperationDialogDisclosure]);

  return (
    <>
      <IconButton
        size="xs"
        variant="unstyled"
        icon={<EditIcon boxSize={6} color="primary.600" />}
        aria-label="入出庫履歴編集"
        onClick={editStockOperationDialogDisclosure.onOpen}
      />
      <StoringStockOperationUpdateDialog
        isOpen={editStockOperationDialogDisclosure.isOpen}
        onClose={editStockOperationDialogDisclosure.onClose}
        transactionType="replenishment"
        stockOperationId={stockOperationId}
        renderStockOperationDeleteFooter={({ cancelRef, onClose }) => (
          <StockOperationDeleteFooter
            stockOperationId={stockOperationId}
            cancelRef={cancelRef}
            onSuccess={onSuccess}
            onCancel={onClose}
          />
        )}
        renderStockOperationDeleteAlert={() => <StockOperationDeleteAlert />}
        externalCounterpartyCreateDialogLink={<ExternalCounterpartyCreateDialogLink />}
        internalCounterpartyCreateDialogLink={<InternalCounterpartyCreateDialogLink />}
      />
    </>
  );
}
