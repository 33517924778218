import { createIcon } from '@chakra-ui/react';

export const RecentRegisteredAttentionIcon = createIcon({
  displayName: 'RecentRegisteredAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M8.62997 11.12C8.41997 10.87 7.90998 10.38 7.48997 10V12.54H6.76997V9.92C6.27997 10.69 5.65998 11.4 5.09998 11.84C4.97998 11.67 4.75998 11.43 4.59998 11.3C5.31998 10.83 6.10998 9.93 6.60998 9.05H4.81998V8.42H6.76997V7.37H4.78998V6.74H9.42998V7.37H7.48997V8.42H9.34998V9.05H7.48997V9.29C7.86998 9.57 8.83998 10.36 9.06998 10.57L8.62997 11.12ZM9.30997 5.1H4.92998V4.48H6.76997V3.47H7.48997V4.48H9.30997V5.1ZM5.91998 6.73C5.87998 6.34 5.71998 5.74 5.52998 5.29L6.10998 5.14C6.31998 5.59 6.48998 6.18 6.53998 6.56L5.91998 6.73ZM7.55997 6.57C7.73997 6.17 7.96997 5.55 8.05997 5.12L8.73997 5.28C8.54997 5.78 8.34998 6.34 8.15998 6.7L7.55997 6.57ZM13.84 7.48H12.72V12.5H11.99V7.49H10.5V7.74C10.5 9.23 10.31 11.23 8.90998 12.62C8.81998 12.46 8.54997 12.2 8.37997 12.1C9.64997 10.86 9.78998 9.13 9.78998 7.73V4.25L10.33 4.42C11.35 4.2 12.47 3.88 13.11 3.56L13.71 4.11C12.86 4.49 11.61 4.79 10.5 5.01V6.79H13.84V7.49V7.48Z"
        fill="#5D6771"
      />
      <path
        d="M18.21 11.07C17.96 10.69 17.36 9.94001 16.9 9.40001C16.67 10.53 16.19 11.63 15.19 12.45C15.1 12.29 14.86 12.03 14.69 11.91C15.96 10.96 16.29 9.42001 16.37 8.06001H14.75V7.39001H16.4V5.75001H14.96V5.09001H16.4V3.54001H17.09V5.09001H18.5V5.75001H17.09V7.39001H18.68V8.06001H17.06C17.06 8.25001 17.03 8.45001 17.01 8.64001C17.43 9.06001 18.47 10.23 18.72 10.52L18.21 11.07ZM23 11.73C23.22 11.73 23.26 11.58 23.29 10.41C23.43 10.53 23.72 10.64 23.91 10.69C23.85 12.05 23.67 12.39 23.08 12.39H22.27C21.58 12.39 21.44 12.17 21.44 11.47V9.35001H20.55C20.37 10.86 19.86 11.95 18.16 12.55C18.09 12.39 17.89 12.12 17.74 11.99C19.26 11.5 19.69 10.58 19.85 9.35001H19.05V3.83001H23.31V9.36001H22.12V11.48C22.12 11.71 22.16 11.74 22.37 11.74H23V11.73ZM19.74 5.47001H22.59V4.50001H19.74V5.47001ZM19.74 7.07001H22.59V6.09001H19.74V7.07001ZM19.74 8.68001H22.59V7.68001H19.74V8.68001Z"
        fill="#5D6771"
      />
      <path
        d="M6.57 20.69V23.7C6.57 24.11 6.48 24.31 6.23 24.43C5.99 24.54 5.61 24.57 4.99 24.56C4.96 24.39 4.87 24.08 4.77 23.88C5.2 23.88 5.59 23.88 5.71 23.88C5.83 23.88 5.88 23.85 5.88 23.7V20.91L4.69 21.3L4.5 20.58C4.88 20.48 5.36 20.33 5.88 20.17V18.12H4.63V17.44H5.88V15.44H6.57V17.44H7.64V18.12H6.57V19.96C6.9 19.86 7.23 19.75 7.56 19.64L7.66 20.33L6.57 20.69ZM11.53 21C12.09 21.96 13.04 22.9 13.92 23.37C13.76 23.49 13.54 23.75 13.42 23.93C12.56 23.39 11.66 22.42 11.06 21.41V24.5H10.36V21.42C9.71 22.48 8.74 23.42 7.76 23.96C7.64 23.77 7.42 23.5 7.25 23.38C8.27 22.91 9.27 21.99 9.88 21H7.83V20.33H10.36V19.23H11.06V20.33H13.62V21H11.53ZM13.34 16.16C11.97 16.57 9.78 16.79 7.98 16.9C7.96 16.72 7.87 16.46 7.79 16.29C9.54 16.18 11.67 15.94 12.83 15.59L13.34 16.16ZM8.64 19.34C8.55 18.83 8.26 18.05 7.93 17.45L8.52 17.28C8.87 17.86 9.18 18.63 9.28 19.13L8.64 19.34ZM10.4 19.02C10.39 18.54 10.19 17.75 9.93 17.15L10.57 17.03C10.84 17.62 11.04 18.38 11.07 18.86L10.4 19.02ZM11.68 19.37C12.1 18.72 12.65 17.65 12.94 16.9L13.64 17.16C13.23 18 12.69 18.99 12.25 19.61L11.68 19.37Z"
        fill="#5D6771"
      />
      <path
        d="M23.22 23.56C23.22 24.04 23.09 24.26 22.77 24.39C22.43 24.51 21.85 24.52 20.91 24.51C20.88 24.31 20.76 23.99 20.66 23.79C21.36 23.82 22.04 23.81 22.22 23.81C22.42 23.81 22.49 23.74 22.49 23.55V21.52H19.8V24.47H19.06V21.52H16.56C16.41 22.63 16.07 23.77 15.31 24.62C15.2 24.47 14.9 24.22 14.74 24.13C15.84 22.89 15.94 21.13 15.94 19.74V16.14H23.22V23.56ZM19.06 20.81V19.15H16.67V19.74C16.67 20.07 16.67 20.44 16.63 20.81H19.06ZM16.67 16.85V18.45H19.06V16.85H16.67ZM22.49 16.85H19.8V18.45H22.49V16.85ZM22.49 20.81V19.15H19.8V20.81H22.49Z"
        fill="#5D6771"
      />
    </g>
  ),
});
