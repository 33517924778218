import { datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { format as f } from 'date-fns';
import { IUserMonitoring } from '@/repository/userMonitoring/interface';
import pkg from '../../../../package.json';

const ENV = process.env.APP_ENV;

const DATADOG_DOMAINS = {
  local: 'localhost',
  dev: 'dev.stock.musubi.app',
  stg: 'stg.stock.musubi.app',
  onb: 'onb.stock.musubi.app',
  demo: 'demo.stock.musubi.app',
  prod: 'stock.musubi.app',
} as const;

type AvailableEnv = keyof typeof DATADOG_DOMAINS;
function isAvailableEnv(e: string): e is AvailableEnv {
  return Object.keys(DATADOG_DOMAINS).includes(e);
}

const piiUrlPatterns: string[] = [`patient/detail`, `medicine/detail`];
const maskUrl = (urlString: string) => {
  const url = new URL(urlString);
  if (url.searchParams.has('patientId')) {
    url.searchParams.set('patientId', 'MASKED');
  }
  return url.toString();
};
const DATADOG_CLIENT_ID_TOKEN = 'pubdb866395aae2f354d11e185da5bee025';
const applicationId = '60ae5719-ace6-405b-afb0-ea38f3e900ef';

const format = {
  年月: (年月: Date) => f(年月, 'yyyy-MM'),
};

export const DatadogRepository: IUserMonitoring = {
  init: () => {
    if (!isAvailableEnv(ENV)) return;

    const initConfig = {
      clientToken: DATADOG_CLIENT_ID_TOKEN,
      site: 'datadoghq.com',
      service: DATADOG_DOMAINS[ENV],
      env: ENV,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      version: pkg.version,
    };

    // datadog ブラウザログ収集SDKの初期化
    datadogLogs.init(initConfig);

    const is個人情報を含む = (url: string) =>
      !!piiUrlPatterns.some((pattern) => url.includes(pattern));
    const rumInitConfig: RumInitConfiguration = {
      applicationId,
      replaySampleRate: 0,
      beforeSend: (e) => {
        if (e.view.referrer && is個人情報を含む(e.view.referrer)) {
          e.view.referrer = maskUrl(e.view.referrer);
        }

        if (is個人情報を含む(e.view.url)) {
          e.view.url = maskUrl(e.view.url);
        }
        return !is個人情報を含む(e.view.url);
      },
      ...initConfig,
    };

    // datadog RUM SDKの初期化
    datadogRum.init(rumInitConfig);
  },

  updateUserAttribute: (context) => {
    datadogRum.clearUser();
    datadogLogs.clearUser();

    Object.entries(context).forEach(([key, value]) => {
      datadogRum.setUserProperty(key, value);
      datadogLogs.setUserProperty(key, value);
    });
  },

  sendEvent: (action) => {
    if (action.key === '患者検索ページの日にちの先送りをClick') {
      datadogRum.addAction(action.key, {
        ...action.contexts,
        押したときに開いていた年月: format.年月(action.contexts.押したときに開いていた年月),
      });
      return;
    }
    datadogRum.addAction(action.key, { ...action.contexts });
  },

  sendLog: (message, context, level = 'info') => {
    datadogLogs.logger[level](message, { ...context });
  },
};
