import { Link, useDisclosure } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { InternalCounterpartyCreateDialog } from '../InternalCounterpartyCreateDialog';

export function InternalCounterpartyCreateDialogLink() {
  const createInternalCounterpartyDialogDisclosure = useDisclosure({
    id: 'create-internal-counterparty-dialog',
  });
  return (
    <>
      <Link fontSize="11px" onClick={createInternalCounterpartyDialogDisclosure.onOpen}>
        <Typography variant="body2" color="primary.600" fontWeight="bold">
          取引先を登録する
        </Typography>
      </Link>
      <InternalCounterpartyCreateDialog
        isOpen={createInternalCounterpartyDialogDisclosure.isOpen}
        onClose={createInternalCounterpartyDialogDisclosure.onClose}
      />
    </>
  );
}
