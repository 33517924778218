import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { useOrganizationGroups } from '../../../store/auth/hooks';
import { AdoptedMedicalProductModalRoot } from '../AdoptedMedicalProductModalRoot';
import { FeatureFlagRoot } from '../FeatureFlagRoot';
import { MaintenanceRoot } from '../MaintenanceRoot';
import { VersionControlRoot } from '../VersionControlRoot';

type Props = {
  children: JSX.Element;
};

export function OrganizationManagementRoot({ children }: Props) {
  const { replace } = useRouter();
  const { isOrganizationGroupsAdmin } = useOrganizationGroups();
  const currentPharmacy = currentPharmacySelectors.useValue();

  useEffect(() => {
    if (!isOrganizationGroupsAdmin) {
      if (currentPharmacy) {
        replace('/404');
      } else {
        replace('/');
      }
    }
  }, [currentPharmacy, isOrganizationGroupsAdmin, replace]);

  if (!isOrganizationGroupsAdmin) {
    return null;
  }
  return (
    <FeatureFlagRoot>
      <MaintenanceRoot>
        <AdoptedMedicalProductModalRoot>
          <VersionControlRoot>{children}</VersionControlRoot>
        </AdoptedMedicalProductModalRoot>
      </MaintenanceRoot>
    </FeatureFlagRoot>
  );
}
