import { Theme, Tooltip, TooltipProps, useTheme } from '@chakra-ui/react';

type Props = TooltipProps;

export function ErrorTooltip(props: Props) {
  const {
    colors: { red },
  } = useTheme<Theme>();

  return (
    <Tooltip
      bg={red[50]}
      color={red[500]}
      borderRadius="base"
      border="1px solid"
      borderColor={red[500]}
      arrowShadowColor={red[500]}
      p={2}
      hasArrow
      {...props}
    />
  );
}
