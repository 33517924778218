import { GridItem, HStack, Input, Skeleton, Stack, Text } from '@chakra-ui/react';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  phoneNumberFieldName: FieldPath<T>;
  faxNumberFieldName: FieldPath<T>;
  isLoading?: boolean;
} & Pick<UseFormReturn<T>, 'register' | 'formState'>;

export function PhoneAndFaxNumberForm<T extends FieldValues>({
  phoneNumberFieldName,
  faxNumberFieldName,
  isLoading = false,
  register,
  formState: { errors },
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Text fontWeight="bold">電話・FAX番号</Text>
      </GridItem>
      <GridItem>
        <HStack align="flex-start">
          <Stack spacing={1} w="140px">
            <Text fontSize="sm" fontWeight="bold">
              電話
            </Text>
            <Skeleton isLoaded={!isLoading} borderRadius="md">
              <Input {...register(phoneNumberFieldName)} placeholder="03-0000-0000" />
              {errors?.phoneNumber?.message && (
                <Text fontSize="sm" color="red.500">
                  {errors.phoneNumber.message}
                </Text>
              )}
            </Skeleton>
          </Stack>
          <Stack spacing={1} w="140px">
            <Text fontSize="sm" fontWeight="bold">
              FAX
            </Text>
            <Skeleton isLoaded={!isLoading} borderRadius="md">
              <Input {...register(faxNumberFieldName)} placeholder="03-0000-0000" />
              {errors?.faxNumber?.message && (
                <Text fontSize="sm" color="red.500">
                  {errors.faxNumber.message}
                </Text>
              )}
            </Skeleton>
          </Stack>
        </HStack>
      </GridItem>
    </>
  );
}
