import { Button, Td } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { DemandTakeoverToMedicineFragment } from '@/gql/apollo';

type Props = {
  fragment: DemandTakeoverToMedicineFragment;
  onSelect: (fragment: DemandTakeoverToMedicineFragment) => void;
};

export function DemandTakeoverToMedicinesTableActionCell({ fragment, onSelect }: Props) {
  if (!fragment.medicine.isAdopted) {
    return (
      <Td verticalAlign="top" pl={6} pr={6}>
        <Typography variant="body2">未採用</Typography>
      </Td>
    );
  }
  if (fragment.isTakable) {
    return (
      <Td verticalAlign="top" pl={6} pr={6}>
        <Button size="sm" width="full" onClick={() => onSelect(fragment)}>
          指定する
        </Button>
      </Td>
    );
  }
  return (
    <Td verticalAlign="top" pl={6} pr={6}>
      <Typography variant="body2">
        この医薬品から需要予測を引き継いでいます。指定は、引き継ぎの取り消し後に行なえます。
      </Typography>
    </Td>
  );
}
