import { createIcon } from '@chakra-ui/react';

export const WarningIcon = createIcon({
  displayName: 'WarningIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13.5 18H10.5V15H13.5V18ZM13.5 13H10.5V6H13.5V13Z"
      fill="currentColor"
    />
  ),
});
