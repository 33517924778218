import { Center } from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpIcon, ChevronUpDownIcon } from '../../../icons';

type Props = {
  sortStatus: 'desc' | 'asc' | false;
};

function renderIcon(sortStatus: Props['sortStatus']) {
  switch (sortStatus) {
    case 'asc':
      return <ArrowUpIcon color="primary.500" boxSize={3} />;
    case 'desc':
      return <ArrowDownIcon color="primary.500" boxSize={3} />;
    case false:
      return <ChevronUpDownIcon color="primary.500" boxSize={4} />;
    default:
      throw new Error('invalid sort status');
  }
}

export function TableSortIcon({ sortStatus }: Props) {
  return <Center boxSize={4}>{renderIcon(sortStatus)}</Center>;
}
