import { BaseMutationOptions } from '@apollo/client';
import {
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  HStack,
  Stack,
  Box,
  Divider,
  AlertDialogHeader,
} from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { FormProvider } from 'react-hook-form';
import { InitialStockUnitQuantityForm } from '@/entities/stock';
import { StoragesForm } from '@/entities/storage';
import { Actions } from '@/repository/userMonitoring/interface';
import { useForm } from './hooks';
import { FieldValues } from './schema';
import { AdoptedMedicineAddTarget } from './types';
import { CostPriceForm, PrimaryWholesaleForm, MedicinePackageUnitForm } from '../form';

type Props = {
  onClose: () => void;
  /** 採用薬登録成功後の callback */
  onSuccess?: () => void;
  /** 採用薬登録失敗後の callback */
  onFailure?: () => void;
  target: AdoptedMedicineAddTarget;
  refetchQueries: BaseMutationOptions['refetchQueries'];
  cancelRef: React.MutableRefObject<null>;
  dialogDescription?: React.ReactChild;
  userMonitoringKey?:
    | Actions['医薬品詳細モーダルの他店舗の在庫タブから医薬品を登録']['key']
    | Actions['在庫を計上する画面から医薬品を登録']['key']
    | Actions['必須買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['安心買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['特定の医薬品発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['アプリ発注の不動在庫日数ポップオーバーから医薬品を登録']['key'];
};

export function DialogContent({
  onClose,
  onSuccess,
  onFailure,
  target,
  refetchQueries,
  cancelRef,
  dialogDescription,
  userMonitoringKey,
}: Props) {
  const { submit, isSubmitting, isValid, useFormReturn } = useForm({
    onClose,
    onSuccess,
    onFailure,
    target,
    refetchQueries,
    userMonitoringKey,
  });
  const { control } = useFormReturn;

  return (
    <FormProvider {...useFormReturn}>
      <AlertDialogContent minW="1040px" minH="496px">
        <AlertDialogHeader px={6} py={4}>
          <Typography variant="h2">医薬品を登録する</Typography>
        </AlertDialogHeader>
        <Divider borderColor="gray.300" />
        <AlertDialogBody p={0}>
          <Stack spacing={4} py={5}>
            <Stack px={6} spacing={4}>
              {dialogDescription}
              <Stack spacing={1}>
                <Typography variant="body2" fontWeight="bold" mt={1}>
                  医薬品名
                </Typography>
                <Typography variant="h2" fontWeight="bold">
                  {target.medicineName}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2" fontWeight="bold">
                  需要タイプ
                </Typography>
                <Typography variant="body">{target.shortUnitOutline}</Typography>
              </Stack>
              <Stack spacing={3}>
                <Stack w="720px">
                  <MedicinePackageUnitForm
                    medicineId={target.id}
                    showRequired={!target.defaultMedicinePackageUnitId}
                  />
                </Stack>
                <HStack spacing={6} mt={1} alignItems="baseline">
                  <Stack w="300px" spacing={1}>
                    <PrimaryWholesaleForm isRequired />
                  </Stack>
                  <Box>
                    <InitialStockUnitQuantityForm<FieldValues>
                      name="unitQuantity"
                      control={control}
                      stockUnitSymbol={target.stockUnitSymbol}
                    />
                  </Box>
                  <Box>
                    <StoragesForm />
                  </Box>
                </HStack>
                <CostPriceForm stockUnitSymbol={target.stockUnitSymbol} />
              </Stack>
            </Stack>
          </Stack>
        </AlertDialogBody>
        <Divider borderColor="gray.300" />
        <AlertDialogFooter pr={6}>
          <Button ref={cancelRef} variant="ghost" onClick={onClose}>
            戻る
          </Button>
          <Button onClick={submit} ml={5} w="160px" isLoading={isSubmitting} isDisabled={!isValid}>
            登録して完了する
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </FormProvider>
  );
}
