import { createIcon } from '@chakra-ui/react';

export const PatchIcon = createIcon({
  displayName: 'PatchIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      d="M31.8 9.8l-7-1.9-1.4-.4-5.3-1.4c-.7-.2-1.6-.1-2.3.3-.5.3-.9.7-1.2 1.2H9.2C7.4 7.6 6 9 6 10.7v17.6c0 1.8 1.4 3.2 3.2 3.2h11.1l5.3 1.4c.3.1.6.1.9.1.5 0 1-.1 1.5-.4.7-.4 1.3-1.1 1.5-1.9l4.6-17c.4-1.6-.6-3.4-2.3-3.9zM9.2 30c-.9 0-1.7-.7-1.7-1.7V10.7c0-.9.7-1.7 1.7-1.7h4.9L9.6 25.3c-.2.8-.2 1.7.3 2.4.4.7 1.1 1.3 1.9 1.5l2.9.8H9.2zm23.4-16.6l-4.6 17c-.1.4-.4.8-.8 1-.4.2-.8.3-1.3.1l-1.2-.3-4.3-1.2-8.2-2.2c-.4-.1-.8-.4-1-.8-.2-.4-.3-.8-.1-1.3l4.5-16.6.1-.3c.1-.4.4-.8.8-1 .1-.1.2-.1.3-.1.1 0 .3-.1.4-.1.1 0 .3 0 .4.1h.1l5.5 1.5 1.1.3 2.1.6 5 1.4c.9 0 1.4 1 1.2 1.9z"
      fill="currentColor"
    />
  ),
});
