import { normalizeMedicineSearchKeywordForNameKana } from '@/entities/medicine';
import { useDemandTakeoverToMedicinesQuery } from '@/gql/apollo';

type Props = {
  medicineId: string;
  medicineSearchKeyword?: string;
};

export function useDemandTakeoverToMedicines({ medicineId, medicineSearchKeyword }: Props) {
  const { data, loading: isLoading } = useDemandTakeoverToMedicinesQuery({
    variables: { medicineId },
  });

  const demandTakeoverToMedicines = data?.demandTakeoverToMedicines.filter(
    (f) =>
      // NOTE: 検索文字列が、医薬品名、メーカー名、短縮需要タイプ名、YJコードにマッチするか
      `${f.medicine.medicineName} ${f.medicine.usageGroup.shortUnitOutline} ${f.medicine.yjCode} ${f.medicine.makerName}`.includes(
        medicineSearchKeyword ?? '',
      ) ||
      // NOTE: 検索文字列を半角カナに変換した値が、医薬品名カナ（半角）にマッチするか
      f.medicine.nameKana.includes(
        normalizeMedicineSearchKeywordForNameKana(medicineSearchKeyword ?? ''),
      ),
  );

  return { demandTakeoverToMedicines, isLoading };
}
