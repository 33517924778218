import { createIcon } from '@chakra-ui/react';

export const PillUnitSymbolIcon = createIcon({
  displayName: 'Pill',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.56 9.77996C12.51 10.9 12.48 11.57 12.46 11.78C12.41 12.2 12.24 12.2 12.04 12.2H11.27C11.03 12.2 10.96 12.2 10.96 11.9V4.45996H7.37001V2.45996H6.51001V4.45996H2.75001V5.25996H6.48001C6.44638 6.10985 6.34607 6.95578 6.18001 7.78996C5.52446 7.30576 4.81671 6.89654 4.07001 6.56996L4.00001 6.53996L3.43001 7.19996H3.54001C4.36925 7.63119 5.1678 8.11901 5.93001 8.65996C5.29471 10.2733 4.06292 11.5803 2.49001 12.31H2.39001L2.92001 13.05H3.00001C4.65812 12.2706 5.9665 10.9016 6.67001 9.20996C7.32148 9.68266 7.91256 10.2334 8.43001 10.85L8.51001 10.94L9.10001 10.26L9.00001 10.19C8.38716 9.51755 7.71807 8.89864 7.00001 8.33996C7.23934 7.33011 7.37008 6.29759 7.39001 5.25996H10.1V12C10.1 12.72 10.35 13 11.1 13H12.18C13.33 13 13.34 12.66 13.47 9.99996V9.93996L12.61 9.56996L12.56 9.77996Z"
        fill="currentColor"
      />
    </svg>
  ),
});
