import { Box, Stack, FormLabel, useTheme, HStack } from '@chakra-ui/react';
import { NumberTextInput, SelectV2, Typography } from '@kkhs/hakari-ui';
import { useRef } from 'react';
import { StockUnitSymbolIcon } from '@/entities/medicine';
import { OrderPointSetting, OrderPointSettingMode } from '@/gql/docs';

type Props = {
  stockUnitSymbol: string;
  orderPointSettingMode: {
    value: OrderPointSetting['mode'];
    onChange: (mode: OrderPointSettingMode) => void;
  };
  fixedValue: {
    defaultValue: number | null;
    onChange: (value: number | null) => void;
  };
  factor: {
    defaultValue: number | null;
    onChange: (value: number | null) => void;
  };
};

const options: { value: OrderPointSettingMode; label: string }[] = [
  { value: 'auto', label: '自動' },
  { value: 'fixed', label: '固定発注点を設定する' },
  { value: 'factor', label: '発注点の係数を設定する' },
];

export function OrderPointSettingField({
  stockUnitSymbol,
  orderPointSettingMode,
  fixedValue,
  factor,
}: Props) {
  // Chakra UI のテーマで最も高い z-index を使用する
  const {
    zIndices: { tooltip },
  } = useTheme();

  const bottomRef = useRef<HTMLDivElement>(null);

  const handleChangeOrderPointSettingMode = (mode: OrderPointSettingMode) => {
    orderPointSettingMode.onChange(mode);
    /**
     * モードを自動から固定や係数に切り替えたとき、ディスプレイのサイズによってはテキストフィールドの出現に気づかない可能性があるため
     * モード変更時に画面最下部までスクロールする。
     * 要素が出現する（スクロールバーの長さが変わる）のを待ってからスクロールするため、setTimeout を使用しています。
     */
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  };

  return (
    <Stack spacing="11px" align="flex-start">
      <FormLabel htmlFor="orderPointId">
        <Typography variant="body2" fontWeight="bold">
          発注点設定
        </Typography>
      </FormLabel>
      <Box>
        <Box w="213px" mb={4}>
          <SelectV2<OrderPointSettingMode>
            options={options}
            onChange={handleChangeOrderPointSettingMode}
            zIndex={tooltip}
            defaultValue={options.find((option) => option.value === orderPointSettingMode.value)}
          />
        </Box>
        {orderPointSettingMode.value === 'fixed' && (
          <HStack alignItems="flex-end" spacing={1} mb="8px">
            <Box w="91px">
              <NumberTextInput
                defaultValue={fixedValue.defaultValue ?? undefined}
                onChange={fixedValue.onChange}
                min={0}
                max={999999.9}
                precision={1}
              />
            </Box>
            <StockUnitSymbolIcon name={stockUnitSymbol} color="slate.500" />
          </HStack>
        )}
        {orderPointSettingMode.value === 'factor' && (
          <HStack spacing={2} mb="10px">
            <Typography variant="body2" fontWeight="bold" lineHeight="14px" textAlign="center">
              発注点
            </Typography>
            <Typography variant="body2" textAlign="center" lineHeight="14px">
              =
            </Typography>
            <Typography variant="body2" lineHeight="14px" textAlign="center">
              基準在庫量
            </Typography>
            <Typography variant="body2" lineHeight="14px">
              ×
            </Typography>
            <Box w="60px">
              <NumberTextInput
                defaultValue={factor.defaultValue ?? undefined}
                onChange={factor.onChange}
                min={0}
                max={9.9}
                precision={1}
              />
            </Box>
            <Typography variant="body2" lineHeight="14px">
              ＋
            </Typography>
            <Typography variant="body2" lineHeight="14px">
              特定患者の予測処方量
            </Typography>
          </HStack>
        )}
        {orderPointSettingMode.value !== 'auto' && (
          <Typography variant="body" color="red.500">
            発注点設定は、翌日に発注おすすめに反映されます。
          </Typography>
        )}
        <Box ref={bottomRef} />
      </Box>
    </Stack>
  );
}
