import { Link, useDisclosure } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { ExternalCounterpartyCreateDialog } from '../ExternalCounterpartyCreateDialog';

export function ExternalCounterpartyCreateDialogLink() {
  const createExternalCounterpartyDialogDisclosure = useDisclosure({
    id: 'create-external-counterparty-dialog',
  });
  return (
    <>
      <Link fontSize="11px" onClick={createExternalCounterpartyDialogDisclosure.onOpen}>
        <Typography variant="body2" color="primary.600" fontWeight="bold">
          取引先を登録する
        </Typography>
      </Link>
      <ExternalCounterpartyCreateDialog
        isOpen={createExternalCounterpartyDialogDisclosure.isOpen}
        onClose={createExternalCounterpartyDialogDisclosure.onClose}
      />
    </>
  );
}
