import { HStack, Stack } from '@chakra-ui/react';
import { RadioGroup, Typography } from '@kkhs/hakari-ui';
import format from 'date-fns/format';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { AWSDate } from '@/shared/utils';
import { AdoptionChangeAlert } from './AdoptionChangeAlert';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  /** 直近の発注予約日 */
  latestOrderPlanDate?: AWSDate;
  /** 現在、採用停止中かどうか */
  currentAdoptionStopped: boolean;
  /** 変更後の値が、採用停止中かどうか */
  nextAdoptionStopped: boolean;
} & Pick<UseFormReturn<T>, 'control' | 'formState'>;

export function StopOrRestartForm<T extends FieldValues>({
  name,
  latestOrderPlanDate,
  currentAdoptionStopped,
  nextAdoptionStopped,
  control,
  formState,
}: Props<T>) {
  // NOTE: 発注予約があるものは、採用薬停止できないため、このフラグを利用する
  const notStoppable = !currentAdoptionStopped && !!latestOrderPlanDate;
  const { dirtyFields } = formState;

  return (
    <HStack spacing={12} h="28px">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <RadioGroup
            spacing={12}
            options={[
              { value: 'false', label: '採用中' },
              { value: 'true', label: '採用停止' },
            ]}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isDisabled={notStoppable}
          />
        )}
      />
      {notStoppable && (
        <Stack spacing={0} lineHeight={1.25} color="slate.700">
          <Typography variant="caption">{`${format(
            new Date(latestOrderPlanDate),
            'M/d',
          )}に発注予約があります。`}</Typography>
          <Typography variant="caption">
            停止する場合は、発注を取り消すか、発注後に停止してください。
          </Typography>
        </Stack>
      )}
      {dirtyFields[name] && <AdoptionChangeAlert nextAdoptionStopped={nextAdoptionStopped} />}
    </HStack>
  );
}
