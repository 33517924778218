export const TAX_RATE = 0.1;

/** 選択できる医薬品の最小値 */
// NOTE: 今後の対応でロット数のみチェックするので削除する予定
export const MIN_OPERATION_MEDICINES_COUNT = 1;
/** 選択できる医薬品の最大値 */
// NOTE: 今後の対応でロット数のみチェックするので削除する予定
export const MAX_OPERATION_MEDICINES_COUNT = 5;

/** 入出庫数量の最小値 */
export const MIN_UNIT_QUANTITY = 0.001;
/** 入出庫数量の最大値 */
export const MAX_UNIT_QUANTITY = 999999.999;

/** 指定できるロット数の最大値 */
// NOTE: 今後の対応で100件にする予定
export const MAX_LOT_COUNT = 5;
