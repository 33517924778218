import { Td } from '@chakra-ui/react';
import { TableCellProps } from '../../types';

export type VirtualizedTableCellProps = TableCellProps;

export function VirtualizedTd(props: VirtualizedTableCellProps) {
  return (
    <Td
      as="div"
      pl="5px"
      pr="5px"
      display="inline-flex"
      flexDirection="column"
      justifyContent="center"
      {...props}
    />
  );
}
