import { z } from 'zod';
import {
  counterpartyAddressSchema,
  counterpartyBuildingNameSchema,
  counterpartyFaxNumberSchema,
  counterpartyNameSchema,
  counterpartyPharmacyLicenseNumberSchema,
  counterpartyPhoneNumberSchema,
  counterpartyZipCodeSchema,
} from '@/entities/counterparty';

export const createInternalCounterpartySchema = z.object({
  name: counterpartyNameSchema,
  zipCode: counterpartyZipCodeSchema,
  address: counterpartyAddressSchema,
  buildingName: counterpartyBuildingNameSchema,
  phoneNumber: counterpartyPhoneNumberSchema,
  faxNumber: counterpartyFaxNumberSchema,
  pharmacyLicenseNumber: counterpartyPharmacyLicenseNumberSchema,
});
export type CreateInternalCounterpartyFieldValues = z.infer<
  typeof createInternalCounterpartySchema
>;
