export const numberTextTheme = {
  defaultProps: {
    size: 'sm',
  },
  sizes: {
    xs: {
      fontSize: '0.6875rem', // 11px
    },
    sm: {
      fontSize: '0.75rem', // 12px
    },
    md: {
      fontSize: '0.9375rem', // 15px
    },
    lg: {
      fontSize: '1.125rem', // 18px
    },
    xl: {
      fontSize: '1.5rem', // 24px
    },
  },
};
