import { AlertDialog, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { useRef } from 'react';
import { ShippingDialogContent } from './ShippingDialogContent';
import { useShippingStockOperation } from './hooks';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  stockOperationId: string;
};

export function ShippingStockOperationDeleteDialog({ isOpen, onClose, stockOperationId }: Props) {
  const cancelRef = useRef(null);

  const { shippingStockOperation, defaultPriceConfig, stockSalePriceConfig, loading } =
    useShippingStockOperation({
      isOpen,
      stockOperationId,
    });

  return (
    <AlertDialog
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <ShippingDialogContent
            onClose={onClose}
            cancelRef={cancelRef}
            loading={loading}
            stockOperation={shippingStockOperation}
            defaultPriceConfig={defaultPriceConfig}
            stockSalePriceConfig={stockSalePriceConfig}
          />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
