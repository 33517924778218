import { ShippingStockOperationTypename } from '@/entities/stockOperation';
import { LoadedShippingStockOperationDeleteFooter } from './LoadedShippingStockOperationDeleteFooter';
import { LoadingShippingStockOperationDeleteFooter } from './LoadingShippingStockOperationDeleteFooter';

type Props = {
  stockOperationId?: string;
  typename?: ShippingStockOperationTypename;
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
  loading: boolean;
};

export function ShippingStockOperationDeleteFooter({
  stockOperationId,
  typename,
  onClose,
  cancelRef,
  loading,
}: Props) {
  if (!stockOperationId || !typename || loading) {
    return <LoadingShippingStockOperationDeleteFooter cancelRef={cancelRef} onClose={onClose} />;
  }
  return (
    <LoadedShippingStockOperationDeleteFooter
      cancelRef={cancelRef}
      onClose={onClose}
      stockOperationId={stockOperationId}
      typename={typename}
    />
  );
}
