import { Button, useDisclosure, Box } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { useFormContext } from 'react-hook-form';
import { DemandTakeoverToMedicinesDialog } from './DemandTakeoverToMedicinesDialog';
import { useDemandTakeoverToMedicineCount } from './hooks';
import { useDemandTakeoverMedicine } from './hooks/useDemandTakeoverMedicine';

type Props = {
  defaultDemandTakeoverMedicineId?: string;
  sourceMedicine: {
    id: string;
    name: string;
    unitOutline: string;
    // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
    shortUnitOutline: string;
  };
};

export function DemandTakeoverToMedicineFormContent({
  defaultDemandTakeoverMedicineId,
  sourceMedicine,
}: Props) {
  const { watch, setValue } = useFormContext<{
    demandTakeoverToMedicine?: {
      id: string;
      name: string;
      unitOutline: string;
      // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
      shortUnitOutline: string;
    } | null;
  }>();
  const demandTakeoverToMedicine = watch('demandTakeoverToMedicine');
  const { isLoading: isLoadingDemandTakeoverMedicine } = useDemandTakeoverMedicine({
    medicineId: defaultDemandTakeoverMedicineId,
    onLoaded: (d) => {
      // NOTE: すでにセットされていたら再度 setValue しない
      if (d?.id === demandTakeoverToMedicine?.id) return;
      setValue(
        'demandTakeoverToMedicine',
        d
          ? {
              id: d.id,
              name: d.medicineName,
              unitOutline: d.usageGroup.unitOutline,
              // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
              shortUnitOutline: d.usageGroup.shortUnitOutline,
            }
          : undefined,
      );
    },
  });

  const { count: demandTakeoverMedicineCount, isLoading: isLoadingCount } =
    useDemandTakeoverToMedicineCount({
      medicineId: sourceMedicine.id,
    });

  const demandTakeoverToMedicinesDialogDisclosure = useDisclosure({
    id: 'demand-takeover-to-medicine-dialog',
  });

  if (isLoadingDemandTakeoverMedicine || isLoadingCount) {
    // TODO
    return null;
  }

  if (demandTakeoverMedicineCount === 0) {
    return (
      <Typography variant="body" color="slate.300">
        切り替え可能な同効薬はありません
      </Typography>
    );
  }

  return (
    <>
      {demandTakeoverToMedicine ? (
        <>
          <Box>
            <Typography variant="body" fontWeight="bold">
              {demandTakeoverToMedicine?.name}
            </Typography>
            {/* 短縮需要タイプを表示する */}
            <Typography variant="body2">{demandTakeoverToMedicine?.shortUnitOutline}</Typography>
          </Box>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setValue('demandTakeoverToMedicine', null, { shouldDirty: true })}
          >
            指定した医薬品を削除する
          </Button>
        </>
      ) : (
        <>
          <Typography variant="body" color="slate.300">
            医薬品は指定されていません
          </Typography>
          <Button
            variant="outline"
            size="sm"
            onClick={demandTakeoverToMedicinesDialogDisclosure.onOpen}
          >
            切り替え先の医薬品を指定する
          </Button>
        </>
      )}
      <DemandTakeoverToMedicinesDialog
        isOpen={demandTakeoverToMedicinesDialogDisclosure.isOpen}
        onClose={demandTakeoverToMedicinesDialogDisclosure.onClose}
        sourceMedicine={sourceMedicine}
      />
    </>
  );
}
