import { createIcon } from '@chakra-ui/react';

export const SheetUnitSymbolIcon = createIcon({
  displayName: 'Sheet',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.40005 5.23C5.18005 10.7 4.34005 11.91 1.28005 12.6L1.16005 11.6C4.00005 11.16 4.61005 9.35 5.56005 4.76L6.40005 5.23ZM3.23005 8L3.53005 7.11C2.84308 6.49307 2.05037 6.00524 1.19005 5.67H1.08005L0.800049 6.44H0.880049C1.70052 6.76486 2.45334 7.23961 3.10005 7.84L3.23005 8ZM3.72005 5.23L3.85005 5.35L4.14005 4.42C3.44657 3.8067 2.64673 3.32544 1.78005 3H1.67005L1.40005 3.79H1.49005C2.30542 4.12626 3.05908 4.59603 3.72005 5.18V5.23ZM6.50005 9H10V8H6.50005V9ZM11.8 6.09V3H11V13H11.8V7C13.0418 7.73431 14.2134 8.5812 15.3 9.53L15.6 8.62C14.4076 7.67016 13.1363 6.82376 11.8 6.09V6.09Z"
        fill="currentColor"
      />
    </svg>
  ),
});
