import { StockSalePriceConfig } from '@/gql/docs';
import { EmptyDialogBody } from './EmptyDialogBody';
import { LoadingDialogBody } from './LoadingDialogBody';
import { ShippingStockOperationByDisposalBody } from './ShippingStockOperationByDisposalBody';
import { ShippingStockOperationByExternalTradeBody } from './ShippingStockOperationByExternalTradeBody';
import { ShippingStockOperationByOtherBody } from './ShippingStockOperationByOtherBody';
import { ShippingStockOperationByStockSaleBody } from './ShippingStockOperationByStockSaleBody';
import { ShippingStockOperationByTransferBody } from './ShippingStockOperationByTransferBody';
import { ShippingStockOperationByTransferRequestBody } from './ShippingStockOperationByTransferRequestBody';
import { ShippingDefaultPriceConfig, ShippingStockOperation } from '../types';

type Args = {
  stockOperation?: ShippingStockOperation;
  defaultPriceConfig?: ShippingDefaultPriceConfig;
  stockSalePriceConfig?: StockSalePriceConfig;
  loading: boolean;
};

export function useShippingDialogContent({
  stockOperation,
  defaultPriceConfig,
  stockSalePriceConfig,
  loading,
}: Args) {
  if (loading) {
    return {
      renderDialogContent: () => <LoadingDialogBody />,
    };
  }

  if (!stockOperation || !defaultPriceConfig) {
    return {
      renderDialogContent: () => <EmptyDialogBody />,
    };
  }

  switch (stockOperation.__typename) {
    case 'ShippingStockOperationByDisposal': {
      return {
        renderDialogContent: () => (
          <ShippingStockOperationByDisposalBody stockOperation={stockOperation} />
        ),
      };
    }
    case 'ShippingStockOperationByExternalTrade': {
      return {
        renderDialogContent: () => (
          <ShippingStockOperationByExternalTradeBody
            stockOperation={stockOperation}
            defaultPriceConfig={defaultPriceConfig}
          />
        ),
      };
    }
    case 'ShippingStockOperationByStockSale': {
      if (!stockSalePriceConfig) {
        return {
          renderDialogContent: () => <EmptyDialogBody />,
        };
      }
      return {
        renderDialogContent: () => (
          <ShippingStockOperationByStockSaleBody
            stockOperation={stockOperation}
            stockSalePriceConfig={stockSalePriceConfig}
          />
        ),
      };
    }
    case 'ShippingStockOperationByOther': {
      return {
        renderDialogContent: () => (
          <ShippingStockOperationByOtherBody
            stockOperation={stockOperation}
            defaultPriceConfig={defaultPriceConfig}
          />
        ),
      };
    }
    case 'ShippingStockOperationByTransfer': {
      return {
        renderDialogContent: () => (
          <ShippingStockOperationByTransferBody
            stockOperation={stockOperation}
            defaultPriceConfig={defaultPriceConfig}
          />
        ),
      };
    }
    case 'ShippingStockOperationByTransferRequest': {
      return {
        renderDialogContent: () => (
          <ShippingStockOperationByTransferRequestBody
            stockOperation={stockOperation}
            defaultPriceConfig={defaultPriceConfig}
          />
        ),
      };
    }

    default:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new Error(`unreachable: ${(stockOperation as any)?.__typename}`);
  }
}
