import { createIcon } from '@chakra-ui/react';

export const RollUnitSymbolIcon = createIcon({
  displayName: 'Roll',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.06001 9.66995C3.76099 9.31561 4.41309 8.87192 5.00001 8.34995V8.74995H10V9.99995H4.62001V12.27C4.62001 13.05 5.11001 13.11 5.62001 13.11H10.81C11.92 13.11 12.03 12.72 12.34 10.89V10.8L11.49 10.56V10.67C11.26 12.32 11.2 12.32 10.57 12.32H5.83001C5.52001 12.32 5.45001 12.32 5.45001 12.12V10.78H10.86V8.25995C11.5075 8.82567 12.2271 9.30315 13 9.67995H13.09L13.49 8.99995H13.39C12.3143 8.50144 11.3436 7.80227 10.53 6.93995H13.4V6.10995H9.92001C9.66069 5.70584 9.44927 5.27293 9.29001 4.81995H12.92V4.04995H10.71C10.95 3.63995 11.26 3.04995 11.41 2.71995L11.47 2.59995L10.6 2.44995V2.51995C10.51 2.69995 10 3.69995 9.79001 4.04995H7.82001C7.97758 3.56846 8.1045 3.07748 8.20001 2.57995V2.45995H7.36001V2.53995C7.29186 3.05425 7.17123 3.56022 7.00001 4.04995H6.12001C5.90548 3.49235 5.60888 2.96992 5.24001 2.49995L4.43001 2.73995L4.51001 2.85995C4.79473 3.25126 5.04564 3.66609 5.26001 4.09995H3.14001V4.86995H6.65001C6.4478 5.32196 6.20338 5.75387 5.92001 6.15995H2.65001V6.91995H5.31001C4.56174 7.77024 3.64144 8.45194 2.61001 8.91995H2.51001L3.00001 9.70995L3.06001 9.66995ZM6.86001 6.10995C7.12623 5.70362 7.3507 5.27142 7.53001 4.81995H8.47001C8.61317 5.27079 8.80779 5.70365 9.05001 6.10995H6.86001ZM6.34001 6.86995H9.57001C9.86975 7.26889 10.2044 7.64035 10.57 7.97995H5.38001C5.73286 7.63969 6.05417 7.26817 6.34001 6.86995V6.86995Z"
        fill="currentColor"
      />
    </svg>
  ),
});
