export function checkDuplicatedStorageNames(storages: Array<{ name: string }>): Array<number> {
  const nameIndexesMap = storages.reduce<{ [key: string]: number[] }>((acc, cur, i) => {
    if (acc[cur.name] !== undefined) {
      acc[cur.name] = [...(acc[cur.name] as number[]), i];
    } else {
      acc[cur.name] = [i];
    }
    return acc;
  }, {});
  return Object.values(nameIndexesMap)
    .filter((indexes) => indexes.length > 1)
    .flat();
}
