import { Center, Spinner } from '@chakra-ui/react';

export function LoadingDialogBody() {
  return (
    <Center minH="421px">
      {/* TODO: https://github.com/kkhs/hakari-frontend/issues/10992  */}
      <Spinner size="lg" thickness="3px" color="primary.500" emptyColor="gray.200" />
    </Center>
  );
}
