import { Skeleton, Stack, Tbody, Tr, Td, Center } from '@chakra-ui/react';
import { Row } from './Row';
import {
  RenderAdoptedMedicalMaterialCreateDialog,
  RenderAdoptedMedicineCreateDialog,
  RenderOwnTakeTransferRequestDialog,
  Row as RowType,
} from '../types';

type Props = {
  rows: ReadonlyArray<RowType> | undefined;
  isLoading: boolean;
  medicineId: string;
  renderOwnTakeTransferRequestDialog: RenderOwnTakeTransferRequestDialog;
  renderAdoptedMedicineCreateDialog: RenderAdoptedMedicineCreateDialog;
  renderAdoptedMedicalMaterialCreateDialog: RenderAdoptedMedicalMaterialCreateDialog;
};

export function GroupPharmacyStocksByMedicineTableBody({
  rows,
  isLoading,
  medicineId,
  renderOwnTakeTransferRequestDialog,
  renderAdoptedMedicineCreateDialog,
  renderAdoptedMedicalMaterialCreateDialog,
}: Props) {
  if (isLoading) {
    return (
      <Tbody>
        <Tr>
          <Td colSpan={9} p="0">
            <Center bg="white">
              <Stack w="full" py="5">
                {[...Array(8)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Skeleton key={i} height="60px" />
                ))}
              </Stack>
            </Center>
          </Td>
        </Tr>
      </Tbody>
    );
  }

  return (
    <Tbody>
      {rows?.map((row, index) => (
        <Row
          key={row.id}
          row={row}
          index={index}
          medicineId={medicineId}
          renderOwnTakeTransferRequestDialog={renderOwnTakeTransferRequestDialog}
          renderAdoptedMedicineCreateDialog={renderAdoptedMedicineCreateDialog}
          renderAdoptedMedicalMaterialCreateDialog={renderAdoptedMedicalMaterialCreateDialog}
        />
      ))}
    </Tbody>
  );
}
