import { Box, HStack, Stack, Table, Tbody, Td, Th, Thead, Tr, chakra } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { StockUnitSymbolIcon } from '@/entities/medicine';
import {
  StockOperationReasonLabel,
  calculateSubTotalAmount,
  calculateSubTotalTaxAmount,
  StockOperationShippingCounterpartyNameLabel,
  StockOperationShippingNoteLabel,
  StockOperationShippingTargetDateLabel,
  StockOperationShippingTradeSettingAlert,
  TaxLabel,
} from '@/entities/stockOperation';
import { formatAWSDate } from '@/shared/utils';
import { groupMedicinesByMedicineId } from './groupMedicinesByMedicineId';
import { currentRoleSelectors } from '../../../../../../store/currentRole';
import { ShippingStockAlert } from '../../ShippingStockAlert';
import { toShippingReason } from '../toShippingReason';
import { ShippingDefaultPriceConfig, ShippingStockOperation } from '../types';

const StyledTh = chakra(Th, {
  baseStyle: () => ({
    p: 1,
    pl: 0,
    pb: 2,
  }),
});

const StyledTd = chakra(Td, {
  baseStyle: () => ({
    p: 1,
    pr: 2,
    pl: 0,
  }),
});

type Props = {
  stockOperation: Extract<
    ShippingStockOperation,
    {
      __typename: 'ShippingStockOperationByOther';
    }
  >;
  defaultPriceConfig: ShippingDefaultPriceConfig;
};

export function ShippingStockOperationByOtherBody({ stockOperation, defaultPriceConfig }: Props) {
  const isManager = currentRoleSelectors.useIsManager();
  const transactionReason = toShippingReason(stockOperation.__typename);

  const medicines = groupMedicinesByMedicineId(stockOperation.operatedMedicines);

  const renderStockUnitPrice = (index: number, price: number) => {
    if (index === 0) {
      return (
        <>
          <Typography variant="body" isNumeric>
            {price}
          </Typography>
          <Typography variant="body2">円</Typography>
        </>
      );
    }
    return undefined;
  };

  // NOTE: その他出庫で、作成時に法人間の取引先を利用していた場合、その取引先の priceConfig を使う
  const stockOperationPriceConfig =
    stockOperation.shippingOriginOnCreated?.origin === 'externalCounterparty'
      ? stockOperation.shippingOriginOnCreated.priceConfig
      : defaultPriceConfig.external.stockOperationPriceConfig;

  return (
    <Box>
      <ShippingStockAlert message="出庫情報の編集は削除のみ行うことができます。削除した出庫情報は復元できません。" />
      <Stack px={6} pt={3} pb={4}>
        <HStack spacing={4} alignItems="baseline">
          <StockOperationReasonLabel
            transactionType="consumption"
            transactionReason={transactionReason}
            w="80px"
          />
          <StockOperationShippingTargetDateLabel targetDate={stockOperation.targetDate} />
          <StockOperationShippingCounterpartyNameLabel
            counterpartyName={stockOperation.shippingOriginOnCreated?.counterpartyName}
          />
          <TaxLabel w="100px" />
        </HStack>
        <StockOperationShippingNoteLabel note={stockOperation.note} />
        <StockOperationShippingTradeSettingAlert />
      </Stack>
      {medicines.map((medicine) => (
        <Box key={medicine.medicineId} px={6} py={3} borderTopWidth={1}>
          <Stack spacing={3}>
            <HStack justifyContent="space-between" alignItems="baseline">
              <Stack spacing={0.5}>
                <Typography variant="body" fontWeight="bold">
                  {medicine.name}
                </Typography>
                <Typography variant="body2">{medicine.usageGroup.shortUnitOutline}</Typography>
              </Stack>
              <HStack>
                <Typography variant="body" fontWeight="bold">
                  出庫数量
                </Typography>
                <HStack spacing={0}>
                  <Typography variant="body" fontWeight="bold">
                    {medicine.stockUnitQuantitySum}
                  </Typography>
                  <StockUnitSymbolIcon
                    name={medicine.stockUnitSymbol}
                    color="slate.700"
                    position="relative"
                    top="1px"
                    boxSize={4}
                  />
                </HStack>
              </HStack>
            </HStack>
            <Table size="sm" variant="unstyled">
              <Thead>
                <Tr>
                  <StyledTh w="128px">
                    <Typography variant="body2" fontWeight="bold">
                      単位薬価
                    </Typography>
                  </StyledTh>
                  <StyledTh w="128px">
                    <Typography variant="body2" fontWeight="bold">
                      出庫単価
                    </Typography>
                  </StyledTh>
                  <StyledTh w="128px">
                    <Typography variant="body2" fontWeight="bold">
                      出庫金額
                    </Typography>
                  </StyledTh>
                  <StyledTh w="128px">
                    <Typography variant="body2" fontWeight="bold">
                      税額
                    </Typography>
                  </StyledTh>
                  <StyledTh w="128px">
                    <HStack spacing={1}>
                      <Typography variant="body2" fontWeight="bold">
                        ロット番号
                      </Typography>
                    </HStack>
                  </StyledTh>
                  <StyledTh w="136px">
                    <Typography variant="body2" fontWeight="bold">
                      有効期限
                    </Typography>
                  </StyledTh>
                  <StyledTh w="184px" pl={4}>
                    <Typography variant="body2" fontWeight="bold">
                      出庫数量
                    </Typography>
                  </StyledTh>
                  <StyledTh pr={0} />
                </Tr>
              </Thead>
              <Tbody>
                {medicine.lots.map((lot, index) => (
                  <Tr key={lot.id}>
                    <StyledTd>
                      <HStack spacing={0.5}>
                        {renderStockUnitPrice(index, medicine.stockUnitPrice)}
                      </HStack>
                    </StyledTd>
                    <StyledTd>
                      {index === 0 && (
                        <HStack spacing={0.5}>
                          {isManager && !isNullOrUndefined(lot.costPrice) ? (
                            <Typography variant="body" isNumeric>
                              {lot.costPrice}
                            </Typography>
                          ) : (
                            <Typography variant="body">-</Typography>
                          )}
                          <Typography variant="body2">円</Typography>
                        </HStack>
                      )}
                    </StyledTd>
                    <StyledTd>
                      <HStack spacing={0.5}>
                        {isManager ? (
                          <Typography variant="body" isNumeric>
                            {calculateSubTotalAmount({
                              unitPrice: lot.costPrice ?? null,
                              stockUnitQuantity: lot.stockUnitQuantity,
                              stockOperationPriceConfig,
                            })}
                          </Typography>
                        ) : (
                          <Typography variant="body">-</Typography>
                        )}
                        <Typography variant="body2">円</Typography>
                      </HStack>
                    </StyledTd>
                    <StyledTd>
                      <HStack spacing={0.5}>
                        {(() => {
                          const subTotalTaxAmount = calculateSubTotalTaxAmount({
                            unitPrice: lot.costPrice ?? null,
                            stockUnitQuantity: lot.stockUnitQuantity,
                            stockOperationPriceConfig,
                          });

                          if (
                            !isManager ||
                            !stockOperationPriceConfig.shouldShowTax ||
                            typeof subTotalTaxAmount !== 'number'
                          ) {
                            return <Typography variant="body">-</Typography>;
                          }

                          return (
                            <Typography variant="body" isNumeric>
                              {subTotalTaxAmount}
                            </Typography>
                          );
                        })()}
                        <Typography variant="body2">円</Typography>
                      </HStack>
                    </StyledTd>
                    <StyledTd>
                      <Typography variant="body">{lot.lotNumber ?? '-'}</Typography>
                    </StyledTd>
                    <StyledTd>
                      <Typography variant="body">
                        {formatAWSDate(lot.expirationDate) ?? '-'}
                      </Typography>
                    </StyledTd>
                    <StyledTd pl={4}>
                      <Typography variant="body">{lot.stockUnitQuantity}</Typography>
                    </StyledTd>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Stack>
        </Box>
      ))}
    </Box>
  );
}
