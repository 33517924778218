import { createIcon } from '@chakra-ui/react';

export const CylinderUnitSymbolIcon = createIcon({
  displayName: 'Cylinder',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.83 8.54992H5.41996V11.7799H10.83V8.54992ZM6.23996 11.0699V9.27992H9.99996V11.0699H6.23996ZM3.23996 5.46992H3.16996L2.57996 4.99992L2.67996 4.92992C3.48006 4.29929 4.10356 3.47255 4.48996 2.52992V2.44992L5.32996 2.62992L5.27996 2.73992C5.20166 2.95952 5.1081 3.17338 4.99996 3.37992H7.99996V4.11992H5.99996C6.1526 4.4452 6.28616 4.7791 6.39996 5.11992V5.21992L5.58996 5.46992V5.36992C5.48016 4.94045 5.33981 4.51938 5.16996 4.10992H4.47996C4.12987 4.61712 3.71275 5.07461 3.23996 5.46992V5.46992ZM11.47 7.81992H4.77996V7.06992H11.47V7.81992ZM3.35996 5.50992H12.88V12.2299C12.88 12.6999 12.82 13.2299 12.1 13.2299H10.45L10.25 12.4199H11.81C11.8708 12.4449 11.9391 12.4449 12 12.4199C12.0236 12.3519 12.0236 12.2779 12 12.2099V6.24992H4.21996V13.2499H3.35996V5.50992ZM9.47996 3.37992H13.42V4.11992H10.87C11.01 4.39992 11.21 4.83992 11.33 5.11992V5.21992L10.5 5.47992V5.37992C10.43 5.11992 10.15 4.37992 10.04 4.08992H8.99996C8.68594 4.57125 8.31677 5.01425 7.89996 5.40992L7.82996 5.46992L7.20996 4.92992L7.29996 4.84992C8.03461 4.20301 8.61543 3.40012 8.99996 2.49992V2.41992L9.82996 2.62992V2.73992C9.72929 2.96162 9.6123 3.17554 9.47996 3.37992V3.37992Z"
        fill="currentColor"
      />
    </svg>
  ),
});
