import { useApolloClient, useQuery } from '@apollo/client';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { graphql } from '@/gql';
import { pharmacyEvents } from 'src/shared/constants/pharmacy';
import { CurrentPharmacySelect } from './CurrentPharmacySelect';
import { useCurrentPharmacy } from './hooks';
import { removeLocalStorageItems } from '../../../lib/recoil';
import { currentRoleActions } from '../../../store/currentRole';

const queryOperation = graphql(`
  query CurrentPharmacySelectOptions {
    pharmaciesAllowedToSignIn {
      edges {
        node {
          ...Pharmacy
        }
      }
    }
    onboardingPharmacies {
      id
      name
      musubiCode
    }
  }
`);

type Props = {
  children: JSX.Element;
};

export function PharmacyRoot({ children }: Props) {
  const { replace } = useRouter();
  const apolloClient = useApolloClient();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const { setCurrentPharmacy, resetCurrentPharmacy } = useCurrentPharmacy();
  const resetCurrentRole = currentRoleActions.useReset();

  const resetState = useCallback(async () => {
    resetCurrentPharmacy();
    resetCurrentRole();
    await apolloClient.clearStore();
    removeLocalStorageItems();
    replace({
      query: undefined,
    });
  }, [apolloClient, replace, resetCurrentPharmacy, resetCurrentRole]);

  useEffect(() => {
    const handleAuth = async ({ payload: { event } }: HubCapsule) => {
      if (event === 'signOut' || event === 'tokenRefresh_failure') {
        resetState();
      }
    };
    const handleCurrentPharmacy = async ({ payload: { event, data } }: HubCapsule) => {
      switch (event) {
        case pharmacyEvents.SET: {
          if (data) {
            const { name } = data;
            removeLocalStorageItems();
            showSuccessToast({
              id: 'sign-in',
              title: `${name}にログインしました`,
            });
            await setCurrentPharmacy(data);
          }
          return;
        }
        case pharmacyEvents.CLEAR: {
          resetState();
          return;
        }
        case pharmacyEvents.AUTHORIZATION_ERROR: {
          showErrorToast({
            id: 'authorization-error',
            title: '許可されていない操作が検出されました',
            description: '薬局選択をやり直してください',
          });
          resetState();
          return;
        }
        default: {
          resetState();
        }
      }
    };
    const hubListenerAuth = Hub.listen('auth', handleAuth);
    const hubListenerCurrentPharmacy = Hub.listen('currentPharmacy', handleCurrentPharmacy);
    return () => {
      hubListenerAuth();
      hubListenerCurrentPharmacy();
    };
  }, [resetState, setCurrentPharmacy]);

  const pharmacies = useQuery(queryOperation, {
    fetchPolicy: 'network-only',
  });

  const isPharmacySelected = !!currentPharmacy;
  if (!isPharmacySelected && !pharmacies.error) {
    return <CurrentPharmacySelect data={pharmacies.data} loading={pharmacies.loading} />;
  }
  return children;
}
