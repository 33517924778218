import { chakra } from '@chakra-ui/react';
import { NumberLikeText } from '@kkhs/hakari-ui';
import { formatAWSDate } from '@/shared/utils';
import { CellBase } from './CellBase';
import { Row } from '../../types';

export function PharmacyNameCell({
  pharmacy: { name },
  latestTransferRequestStoringTargetDate,
}: Row) {
  return (
    <CellBase pl={0}>
      <chakra.b data-testid="薬局名" style={{ fontSize: '12px' }}>
        {name}
      </chakra.b>
      <br />
      <NumberLikeText>{formatAWSDate(latestTransferRequestStoringTargetDate)}</NumberLikeText>
    </CellBase>
  );
}
