import { createIcon } from '@chakra-ui/react';

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 14 13',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-3.16471e-07 5.43722L7.80811 5.43722L4.45162 2.56024L6.06562 0.677246L13.0656 6.67725L6.06562 12.6772L4.45162 10.7943L7.80811 7.91727L-2.08065e-07 7.91727L-3.16471e-07 5.43722Z"
      fill="currentColor"
    />
  ),
});
