import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';
import { MAX_LENGTH_MAPPING_YJ_CODE, MIN_LENGTH_MAPPING_YJ_CODE } from '@/entities/medicalMaterial';

const costPriceSchema = z.optional(
  z
    .number()
    .max(99999999, '100,000,000未満の値を入力してください')
    .min(0, '0以上の値を入力してください'),
);

const storagesSchema = z.array(
  z.object({
    id: z.string().optional(),
    name: z.string().optional(),
  }),
);

export const medicalMaterialSchema = z.object({
  storages: storagesSchema,
  primaryWholesaleId: z.string(),
  medicinePackageUnitId: z.string(),
  stockUnitQuantity: z.number(),
  costPrice: costPriceSchema,
  mappingYjCode: z
    .string()
    .trim()
    .min(MIN_LENGTH_MAPPING_YJ_CODE, `${MIN_LENGTH_MAPPING_YJ_CODE}桁以上で入力してください`)
    .max(MAX_LENGTH_MAPPING_YJ_CODE, `${MAX_LENGTH_MAPPING_YJ_CODE}桁以内で入力してください`)
    .regex(/^[0-9a-zA-Z]*$/, '半角英数で入力してください'),
});

export type MedicalMaterialFieldValues = z.infer<typeof medicalMaterialSchema>;

export function toMedicalMaterialDefaultValues({
  defaultMedicinePackageUnitId,
}: {
  defaultMedicinePackageUnitId?: string;
}): DefaultValues<MedicalMaterialFieldValues> {
  return {
    storages: [{}],
    primaryWholesaleId: undefined,
    medicinePackageUnitId: defaultMedicinePackageUnitId,
    stockUnitQuantity: 0,
    costPrice: undefined,
    mappingYjCode: '',
  };
}
