import { Select, SelectInstanceType, SelectProps } from '@kkhs/hakari-ui';
import { forwardRef } from 'react';
import { useGroupPharmaciesQuery } from '@/gql/apollo';

type PharmacyId = string;
type Props = {
  ref?: React.Ref<HTMLSelectElement>;
  value?: PharmacyId | null;
} & Omit<SelectProps<PharmacyId>, 'options' | 'value'>;

/**
 * 同一法人内の店舗(自店舗を除く)を一件選択することができるコンポーネント
 */
export const GroupPharmacySelect = forwardRef<SelectInstanceType<PharmacyId>, Omit<Props, 'ref'>>(
  ({ value, ...rest }: Omit<Props, 'ref'>, ref) => {
    const { data } = useGroupPharmaciesQuery({
      fetchPolicy: 'cache-and-network',
    });
    const groupPharmacySelectOptions = data?.groupPharmacies.map((pharmacy) => ({
      value: pharmacy.id,
      label: pharmacy.name,
    }));
    const currentValue = groupPharmacySelectOptions?.find((opt) => opt.value === value) ?? null;
    return (
      <Select<PharmacyId>
        {...rest}
        ref={ref}
        options={groupPharmacySelectOptions}
        size="sm"
        isClearable
        value={currentValue}
      />
    );
  },
);
GroupPharmacySelect.displayName = 'GroupPharmacySelect';
