import { Center } from '@chakra-ui/react';
import { DropdownIndicatorProps } from 'react-select';
import { ChevronDownIcon } from '../../icons';
import { SelectOptionType } from '../types';

export function DropdownIndicator<T = string>(
  props: DropdownIndicatorProps<SelectOptionType<T>, false>,
) {
  const { getStyles } = props;
  const styles = getStyles('dropdownIndicator', props);
  return (
    <Center cursor="pointer">
      <ChevronDownIcon color="primary.500" w={styles.width as number} h={styles.height as number} />
    </Center>
  );
}
