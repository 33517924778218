import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * TODO: このフックを使っているコンポーネントを初期表示、あるいはリロードしたときは AnnounceKit の状態が取得できるが、
 * 別のコンポーネントから遷移してきた場合は AnnounceKit の状態が取得できない。
 * なぜなら、AnnounceKit 絡みの DOM は既に存在しているため、
 *  - body タグに `.announcekit-booster-bar` が追加された mutation の場合、 `.annoucekit-booster-bar` のスタイルを state に保管
 * が動作しないから。
 */
export const useStickyElement = () => {
  const [announceKitStyle, setAnnounceKitStyle] = useState<CSSStyleDeclaration | undefined>(
    undefined,
  );
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef<HTMLDivElement>(null);

  /**
   * スクロール時に ref で受け取った要素が画面上部に達した場合に、isSticky を true にする
   */
  const handleScroll = useCallback(() => {
    if (stickyRef.current) setIsSticky(stickyRef.current.getBoundingClientRect().top <= 0);
  }, []);

  // body タグを取得
  const body = document.getElementsByTagName('body').item(0);

  useEffect(() => {
    const annoucekitBar = body?.getElementsByClassName('announcekit-booster-bar');
    // AnnounceKit のバーが存在する場合、そのスタイルを state に保管
    if (annoucekitBar && annoucekitBar[0]) {
      setAnnounceKitStyle(getComputedStyle(annoucekitBar[0]));
    }

    const mutationObserver = new MutationObserver((mutationsList) => {
      // body タグに `.announcekit-booster-bar` が追加された mutation の場合、 `.annoucekit-booster-bar` のスタイルを state に保管
      mutationsList.forEach((mutation) => {
        const { target, addedNodes } = mutation;
        if (
          target instanceof Element &&
          addedNodes[0] instanceof Element &&
          target.tagName === 'BODY' &&
          addedNodes[0].classList.contains('announcekit-booster-bar')
        ) {
          setAnnounceKitStyle(getComputedStyle(addedNodes[0]));
        }
      });

      // `.annoucekit-boosted-bar` に変更が加えられた mutation の場合、変更後のスタイルを state に保管
      mutationsList.forEach(({ target }) => {
        if (
          target instanceof Element &&
          target.tagName === 'A' &&
          target.classList.contains('announcekit-booster-bar')
        ) {
          setAnnounceKitStyle(getComputedStyle(target));
        }
      });
    });

    // body タグに起こった監視を開始する
    if (body instanceof Element) {
      mutationObserver.observe(body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      mutationObserver.disconnect();
    };
  }, [body]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {
    isSticky,
    stickyRef,
    isAnnounceKitHidden:
      // announceKitのstyleがない場合 / styleのdisplayがnoneの場合は非表示
      isNullOrUndefined(announceKitStyle) || announceKitStyle?.display === 'none',
    announceKitHeight: announceKitStyle?.height,
  };
};
