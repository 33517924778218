import { useApolloClient } from '@apollo/client';
import { showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { UpdateStockOperationUserError } from '@/gql/apollo';
import { MedicineTransactionListItemsDocument } from '@/gql/docs';

type Props = {
  resetMutation: () => void;
  onClose: () => void;
};

export function useUpdateStoringStockOperation({ resetMutation, onClose }: Props) {
  const apolloClient = useApolloClient();

  const onError = useCallback(() => {
    showErrorToast({
      id: 'update-storing-stock-operation-error',
      title: 'エラーが発生しました',
    });
    resetMutation();
  }, [resetMutation]);
  const onCompleted = useCallback(
    async (userErrors: UpdateStockOperationUserError[]) => {
      if (userErrors.length > 0) {
        showErrorToast({
          id: 'update-storing-stock-operation-error',
          title: 'エラーが発生しました',
        });
        resetMutation();
      } else {
        showSuccessToast({
          id: 'update-storing-stock-operation-success',
          title: '変更を保存しました',
        });
        await apolloClient.refetchQueries({
          include: [MedicineTransactionListItemsDocument],
        });
        onClose();
      }
    },
    [apolloClient, onClose, resetMutation],
  );

  return { onError, onCompleted };
}
