import { createIcon } from '@chakra-ui/react';

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6.75L8 14.75L4 10.75L5.5 9.25L8 11.75L14.5 5.25L16 6.75Z"
      fill="currentColor"
    />
  ),
});
