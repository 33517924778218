import { createIcon } from '@chakra-ui/react';

export const ReceivingIcon = createIcon({
  displayName: 'ReceivingIcon',
  viewBox: '0 0 60 60',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2555 20.105L16.6061 11.1362H42.903L48.2555 20.105V48.625H11.2555V20.105ZM28.3805 13.125H17.7555L14.2555 19.5H28.3805V13.125ZM41.7555 13.125H31.1305V19.5H45.2555L41.7555 13.125ZM46.1305 21.625H13.3805V46.5H46.1305V21.625ZM23.2555 34.4592L21.2555 36.4592L26.2555 41.4592L39.2555 28.4592L37.2555 26.4592L26.2555 37.4592L23.2555 34.4592Z"
      fill="currentColor"
    />
  ),
});
