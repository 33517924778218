import { IconProps } from '@chakra-ui/react';
import {
  AdjustmentAttentionIcon,
  ColdAttentionIcon,
  ExpensiveAttentionIcon,
  InfrequentAttentionIcon,
  NarcoticAttentionIcon,
  NewAttentionIcon,
  PoisonAttentionIcon,
  PreMadeAttentionIcon,
  RecentRegisteredAttentionIcon,
  StimulantAttentionIcon,
  StockoutPreventionTargetAttentionIcon,
  SubdividedAttentionIcon,
  TransitionalMeasureAttentionIcon,
  TransitionalMeasureExpiredAttentionIcon,
} from '@kkhs/hakari-ui';
import { captureMessage } from '@sentry/nextjs';
import { MedicineAttention } from '@/gql/apollo';

export type AttentionIconName =
  | 'adjustment'
  | 'cold'
  | 'narcotic'
  | 'poison'
  | 'preMade'
  | 'stimulant'
  | 'stockoutPreventionTarget'
  | MedicineAttention;

type Props = {
  name: AttentionIconName;
} & IconProps;

export function AttentionIcon({ name, ...rest }: Props) {
  switch (name) {
    case 'adjustment':
      return <AdjustmentAttentionIcon {...rest} />;
    case 'cold':
      return <ColdAttentionIcon {...rest} />;
    case 'expensive':
      return <ExpensiveAttentionIcon {...rest} />;
    case 'infrequent':
      return <InfrequentAttentionIcon {...rest} />;
    case 'narcotic':
      return <NarcoticAttentionIcon {...rest} />;
    case 'new':
      return <NewAttentionIcon {...rest} />;
    case 'poison':
      return <PoisonAttentionIcon {...rest} />;
    case 'preMade':
      return <PreMadeAttentionIcon {...rest} />;
    case 'recentRegistered':
      return <RecentRegisteredAttentionIcon {...rest} />;
    case 'stimulant':
      return <StimulantAttentionIcon {...rest} />;
    case 'stockoutPreventionTarget':
      return <StockoutPreventionTargetAttentionIcon {...rest} />;
    case 'subdivided':
      return <SubdividedAttentionIcon {...rest} />;
    case 'transitionalMeasure':
      return <TransitionalMeasureAttentionIcon {...rest} />;
    case 'transitionalMeasureExpired':
      return <TransitionalMeasureExpiredAttentionIcon {...rest} />;
    default:
      captureMessage(`Invalid AttentionIcon name - ${name}`);
      return <div />;
  }
}
