import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { captureMessage } from '@sentry/nextjs';
import Big from 'big.js';
import { RoundingMethod, TaxCalculationTarget } from '@/gql/docs';
import { calculatePriceV2 } from '@/shared/utils';
import { TAX_RATE } from '../../constants';

/**
 * 取引先設定に応じて合計金額の税額を計算する
 * - taxRoundingMethod（税額端数処理方法） が設定されていない場合は undefined
 * - 税額計算対象（taxCalculationTarget）によって算出方法が変わる
 *   - operation: 算出した総額に税率をかけ、税額端数処理を実施
 *   - item: 明細ごとに税額を算出したものの合計に、税額端数処理を実施
 */
export function calculateTotalTaxAmount({
  operationMedicines,
  taxCalculationTarget,
  itemRoundingMethod,
  taxRoundingMethod,
  containerAmount = 0,
  feeAmount = 0,
}: {
  operationMedicines: { price?: number | null; stockUnitQuantity: number }[];
  taxCalculationTarget: TaxCalculationTarget | null;
  itemRoundingMethod: RoundingMethod | null;
  taxRoundingMethod: RoundingMethod | null;
  /** 容器代 */
  containerAmount?: number;
  /** 手数料 */
  feeAmount?: number;
}): number | null {
  if (!taxCalculationTarget) return null;

  // NOTE: taxCalculationTarget によって税額計算対象が変わる
  if (taxCalculationTarget === 'operation') {
    // 端数処理前の税額
    const taxBeforeRounding = operationMedicines
      .reduce(
        (acc, cur) =>
          !isNullOrUndefined(cur.price) &&
          !isNullOrUndefined(cur.stockUnitQuantity) &&
          !Number.isNaN(cur.price) &&
          !Number.isNaN(cur.stockUnitQuantity)
            ? acc.plus(
                calculatePriceV2(
                  new Big(cur.price).times(cur.stockUnitQuantity),
                  itemRoundingMethod,
                ),
              )
            : // NOTE: 明細端数処理（itemRoundingMethod）を適用する
              acc,
        new Big(0),
      )
      .times(TAX_RATE)
      .plus(new Big(containerAmount).plus(feeAmount).times(TAX_RATE));
    // 端数処理
    return calculatePriceV2(taxBeforeRounding, taxRoundingMethod).toNumber() ?? 0;
  }
  if (taxCalculationTarget === 'item') {
    // 端数処理前の税額合計
    const taxBeforeRounding = operationMedicines
      .reduce((acc, cur) => {
        if (
          isNullOrUndefined(cur.price) ||
          isNullOrUndefined(cur.stockUnitQuantity) ||
          Number.isNaN(cur.price) ||
          Number.isNaN(cur.stockUnitQuantity)
        )
          return acc;

        // 端数処理後の出庫金額
        const subTotalPrice = calculatePriceV2(
          new Big(cur.price).times(cur.stockUnitQuantity),
          itemRoundingMethod,
        );
        // 明細ごとの税額
        const subTotalTaxBeforeRounding = subTotalPrice.times(TAX_RATE);
        // 税額端数処理後の明細ごとの税額
        const subTotalTax = calculatePriceV2(subTotalTaxBeforeRounding, taxRoundingMethod);

        return acc.plus(subTotalTax);
      }, new Big(0))
      .plus(new Big(new Big(containerAmount).plus(feeAmount)).times(TAX_RATE));

    // 端数処理
    return calculatePriceV2(taxBeforeRounding, taxRoundingMethod).toNumber() ?? 0;
  }
  captureMessage('Invalid taxCalculationTarget or taxRoundingMethod.');
  return 0;
}
