import { createIcon } from '@chakra-ui/react';

export const MGUnitSymbolIcon = createIcon({
  displayName: 'MG',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.06002 3.00003H8.20002V13H7.30002V5.81003C7.30002 5.57003 7.30002 5.23003 7.30002 4.81003L5.17002 13H4.39002L2.28002 4.83003C2.28002 4.93003 2.28002 5.62003 2.28002 5.83003V13H1.40002V3.00003H2.53002L4.79002 11.43L7.06002 3.00003ZM12.2 7.80003V8.80003H14V12C13.7791 12.0824 13.5516 12.1459 13.32 12.19C13.0533 12.2434 12.782 12.2702 12.51 12.27C12.2358 12.2858 11.9612 12.247 11.702 12.1561C11.4428 12.0651 11.2043 11.9237 11 11.74C10.583 11.3333 10.2826 10.8222 10.13 10.26C9.93951 9.52244 9.85533 8.76142 9.88002 8.00003C9.86823 7.22806 9.97617 6.45892 10.2 5.72003C10.362 5.15263 10.6768 4.64066 11.11 4.24003C11.5188 3.88287 12.0475 3.69354 12.59 3.71003C12.8822 3.71186 13.1723 3.75909 13.45 3.85003C13.753 3.9507 14.0394 4.0956 14.3 4.28003L14.39 4.34003L14.73 3.47003L14.66 3.41003C14.3495 3.21134 14.013 3.05655 13.66 2.95003C13.3103 2.84179 12.9461 2.78783 12.58 2.79003C11.8542 2.76391 11.1436 3.00197 10.58 3.46003C10.0267 3.96274 9.61908 4.60526 9.40002 5.32003C9.12508 6.18642 8.99005 7.09111 9.00002 8.00003C8.98318 8.90717 9.10802 9.81138 9.37002 10.68C9.57089 11.3854 9.96181 12.0219 10.5 12.52C11.0401 12.9782 11.7323 13.2173 12.44 13.19C12.837 13.1913 13.2328 13.1477 13.62 13.06C14.0093 12.9814 14.3861 12.8502 14.74 12.67H14.8V7.80003H12.2Z"
        fill="currentColor"
      />
    </svg>
  ),
});
