import { InternalRefetchQueriesInclude } from '@apollo/client';
import { AlertDialogOverlay } from '@chakra-ui/react';
import { AlertDialog } from '@kkhs/hakari-ui';
import { useRef } from 'react';
import { DialogContent } from './DialogContent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

export function InternalCounterpartyCreateDialog({ isOpen, onClose, refetchQueries }: Props) {
  const cancelRef = useRef(null);
  return (
    <AlertDialog
      size="3xl"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay data-testid="create-internal-counterparty-dialog">
        <DialogContent onClose={onClose} cancelRef={cancelRef} refetchQueries={refetchQueries} />
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
