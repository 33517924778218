import {
  Box,
  Center,
  Table as ChakraTable,
  Flex,
  HStack,
  Spinner,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, useMemo, useState } from 'react';
import { TableSortIcon } from './TableSortIcon';
import { Typography } from '../../Typography';
import { RendererProps, TableProps } from '../types';

export { TableSortIcon } from './TableSortIcon';

function defaultLoadingRenderer({ fallbackText = 'データをロードしています。' }: RendererProps) {
  return (
    <Center h="300px">
      <HStack>
        <Spinner color="primary.500" emptyColor="gray.200" />
        <Typography variant="body">{fallbackText}</Typography>
      </HStack>
    </Center>
  );
}

function noRowsRenderer({ fallbackText = '対象の医薬品がありません。' }: RendererProps) {
  return (
    <Center h="300px">
      <Typography variant="body">{fallbackText}</Typography>
    </Center>
  );
}

export function Table<T extends object>({
  columns,
  data,
  sorting: defaultSorting,
  loading,
  loadingText,
  loadingRenderer,
  renderSubComponent,
  renderSubComponentWithoutTr,
  subComponentTdProps,
  noRowsText,
  tableBodyTrProps,
  tableHeadProps,
  ...rest
}: TableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting ?? []);
  const memoizedData = useMemo(() => data ?? [], [data]);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: memoizedData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    defaultColumn: {
      size: 0,
      minSize: 0,
    },
  });

  function renderBody() {
    if (loading)
      return (
        <Tr {...tableBodyTrProps}>
          <Td colSpan={columns.length}>
            {loadingRenderer?.() ?? defaultLoadingRenderer({ fallbackText: loadingText })}
          </Td>
        </Tr>
      );

    if (data?.length === 0)
      return (
        <Tr {...tableBodyTrProps}>
          <Td colSpan={columns.length}>{noRowsRenderer({ fallbackText: noRowsText })}</Td>
        </Tr>
      );
    return getRowModel().rows.map((row) => (
      <Fragment key={row.id}>
        <Tr
          _last={{
            // eslint-disable-next-line
            ...tableBodyTrProps?._last,
            '& > td': {
              borderBottomWidth: 1,
            },
          }}
          {...tableBodyTrProps}
        >
          {row.getVisibleCells().map((cell) => (
            <Td
              key={cell.id}
              verticalAlign="top"
              textAlign={cell.column.columnDef.meta?.alignRight ? 'right' : 'left'}
              px="6px"
              py="8px"
              borderBottomWidth={0}
              borderTopWidth={1}
              _first={{ paddingLeft: 4 }}
              _last={{ paddingRight: 4 }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </Tr>
        {renderSubComponent && (
          <Tr>
            <Td px={4} colSpan={row.getVisibleCells().length} {...subComponentTdProps}>
              {renderSubComponent({ row })}
            </Td>
          </Tr>
        )}
        {renderSubComponentWithoutTr && renderSubComponentWithoutTr({ row })}
      </Fragment>
    ));
  }

  return (
    <ChakraTable size="sm" bg="white" borderRadius="base" {...rest}>
      <Thead {...tableHeadProps}>
        {getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const sortProps = !header.column.columnDef.meta?.disableDefaultSort
                ? {
                    cursor: header.column.getCanSort() ? 'pointer' : 'auto',
                    onClick: header.column.getToggleSortingHandler(),
                  }
                : {};
              const isAlignRight = header.column.columnDef.meta?.alignRight;
              return (
                <Th
                  key={header.id}
                  color="slate.500"
                  fontWeight="normal"
                  lineHeight="120%"
                  verticalAlign="top"
                  style={{ width: header.column.columnDef.meta?.width }}
                  px="6px"
                  py="8px"
                  _first={{ paddingLeft: 4 }}
                  _last={{ paddingRight: 4 }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent={isAlignRight ? 'flex-end' : 'flex-start'}
                  >
                    <Box
                      display="inline-block"
                      style={{ textAlign: isAlignRight ? 'right' : 'left' }}
                      {...sortProps}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Box>
                    {!header.column.columnDef.meta?.disableDefaultSort && (
                      <Flex style={{ alignSelf: 'stretch', alignItems: 'center' }} {...sortProps}>
                        {header.column.getCanSort() && (
                          <Box ml={1}>
                            <TableSortIcon sortStatus={header.column.getIsSorted()} />
                          </Box>
                        )}
                      </Flex>
                    )}
                  </Flex>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>{renderBody()}</Tbody>
    </ChakraTable>
  );
}
