import { TableColumnHeaderProps, Th } from '@chakra-ui/react';

export function VirtualizedTh(props: TableColumnHeaderProps) {
  return (
    <Th
      as="div"
      pr="5px"
      pl="5px"
      _first={{ paddingLeft: 4 }}
      _last={{ paddingRight: 4 }}
      {...props}
    />
  );
}
