export async function execSequence<A, R>(
  arr: Array<A>,
  f: (a: A) => Promise<R>,
): Promise<Array<R>> {
  const ret: Array<R> = [];
  for (let i = 0; i < arr.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const r = await f(arr[i] as A);
    ret.push(r);
  }
  return ret;
}
