import { HStack } from '@chakra-ui/react';
import { WarningTriangleIcon } from '@kkhs/hakari-ui';
import { CellBase } from './CellBase';
import { Row } from '../../types';

export function UnitOutlineCell({
  medicine: { id, usageGroup: { shortUnitOutline } },
  ownMedicineId,
}: Row & {
  /** 自店舗の対象の医薬品ID */
  ownMedicineId: string;
}) {
  const isDifferent = ownMedicineId !== id;
  return (
    <CellBase data-testid="需要タイプ">
      {isDifferent ? (
        <HStack alignItems="flex-start" spacing={0.5}>
          <WarningTriangleIcon color="orange.400" boxSize="18px" />
          <b>{shortUnitOutline}</b>
        </HStack>
      ) : (
        shortUnitOutline
      )}
    </CellBase>
  );
}
