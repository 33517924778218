import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { captureMessage, withScope } from '@sentry/nextjs';
import { pharmacyEvents } from 'src/shared/constants/pharmacy';

export const createErrorLink = (): ApolloLink =>
  onError(({ networkError, graphQLErrors, operation, forward }) => {
    if (!networkError && !graphQLErrors) return;

    const { response } = operation.getContext();
    // 認証エラーの場合はログアウトさせる
    // ref: https://github.com/apollographql/apollo-link/issues/297#issuecomment-593443710
    if (response?.status === 401) {
      Auth.signOut();
      return;
    }

    let doForward = false;

    withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext('apollo-client-graphql-operation', {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      if (graphQLErrors) {
        graphQLErrors.forEach((graphQLError) => {
          const { message, path, ...rest } = graphQLError;
          // NOTE: errorType は AppSync が返す独自のフィールド
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorType = (rest as any)?.errorType;
          // eslint-disable-next-line no-console
          console.log(
            `[GraphQL error]: Message: ${message}, ErrorType: ${errorType}, Path: ${path}`,
          );
          if (errorType === 'Lambda:SdkClientException' && message.includes('Connection refused')) {
            captureMessage('Unable to execute HTTP request: Connection refused', {
              contexts: {
                'apollo-client-graphql-error': {
                  // NOTE: graphQLError.(locations|path) は配列なので toString する
                  error: graphQLError.toString(),
                  message: graphQLError.message,
                  extensions: graphQLError.extensions,
                },
              },
            });

            doForward = true;
          } else if (errorType === 'UnauthorizedException') {
            // NOTE: AppSync の認証エラーの場合
            Auth.signOut();
          } else if (errorType === 'AuthorizationError') {
            // NOTE: 薬局選択されていない場合
            Hub.dispatch('currentPharmacy', {
              event: pharmacyEvents.AUTHORIZATION_ERROR,
            });
          } else {
            captureMessage(`${graphQLError.message} ErrorType: ${errorType}`, {
              level: 'error',
              fingerprint: ['{{ default }}', '{{ transaction }}'],
              contexts: {
                'apollo-client-graphql-error': {
                  // NOTE: graphQLError.(locations|path) は配列なので toString する
                  error: graphQLError.toString(),
                  message: graphQLError.message,
                  extensions: graphQLError.extensions,
                },
              },
            });
          }
        });
      }

      if (networkError) {
        // NOTE: networkError に配列が入ることがあるので暫定対応
        const networkErrorString = JSON.stringify(networkError, null, 2);
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkErrorString}`);
        const networkErrorMessage = networkError.message ?? 'Network error';
        captureMessage(networkErrorMessage, {
          level: 'error',
          contexts: {
            'apollo-client-network-error': {
              error: networkErrorString,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              extensions: (networkError as any).extensions,
            },
          },
        });
      }
    });

    if (doForward) {
      operation.setContext({ ...operation.getContext(), delay: 100 });
      // eslint-disable-next-line consistent-return
      return forward(operation);
    }
  });
