import { createIcon } from '@chakra-ui/react';

export const SyringeUnitSymbolIcon = createIcon({
  displayName: 'Syringe',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1927_1711)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 7.5C11.93 7.41 14 6.79 14 4.42V1.5H13.2V4.44C13.2 5.74 12.34 6.63 9.90002 6.69L10 7.5ZM2.54996 1.69995C3.91996 2.09995 4.90996 2.68995 4.90996 2.68995L4.55995 3.38995C4.55995 3.38995 3.46995 2.76995 2.19995 2.39995L2.54996 1.69995ZM1.94997 3.32007C3.31997 3.73007 4.30997 4.31007 4.30997 4.31007L3.95998 5.01007C3.95998 5.01007 2.86998 4.39007 1.59998 4.02007L1.94997 3.32007ZM2.29993 6.75L2.46992 7.5C4.65992 7.38 7.21992 6.75 7.99992 2.76L7.24992 2.5C6.83992 4.38 6.02993 6.57 2.29993 6.75ZM9.54996 8.69995C10.92 9.09995 11.91 9.68995 11.91 9.68995L11.56 10.39C11.56 10.39 10.47 9.76995 9.19995 9.39995L9.54996 8.69995ZM11.31 11.3101C11.31 11.3101 10.32 10.7301 8.94997 10.3201L8.59998 11.0201C9.86998 11.3901 10.96 12.0101 10.96 12.0101L11.31 11.3101ZM9.46992 14.4999L9.29993 13.7499H9.30992C12.5899 13.5899 13.6099 11.8799 14.0899 10.1899L14.8599 10.3899C13.9399 13.8199 11.5399 14.3799 9.46992 14.4999ZM4.30997 9.8899C4.30997 9.8899 3.31997 9.2999 1.94997 8.8999L1.59998 9.5999C2.86998 9.9699 3.95998 10.5899 3.95998 10.5899L4.30997 9.8899ZM2.46992 14.5L2.29993 13.75C6.02993 13.57 6.83992 11.38 7.24992 9.5L7.99992 9.76C7.21992 13.75 4.65992 14.38 2.46992 14.5ZM14 8.1001H13.4V9.7001H14V8.1001ZM14.4 8.1001H15V9.7001H14.4V8.1001ZM9.19995 1.5H9.99995V5H9.19995V1.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1927_1711">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
});
