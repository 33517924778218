import { useGroupPharmaciesQuery } from '@/gql/apollo';

/**
 * 依頼ベースの法人内出庫で薬局IDを受け取り、薬局名を返却するカスタムフック
 * @param {string} pharmacyId - 薬局ID
 * @return {string} pharmacyName: 薬局名
 * @return {boolean} loading: 情報を取得中かどうか
 */

export function useGetTransferRequestingPharmacyName(pharmacyId: string) {
  const { data, loading } = useGroupPharmaciesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const pharmacyName = data?.groupPharmacies.find((pharmacy) => pharmacy.id === pharmacyId)?.name;

  return { pharmacyName, loading };
}
