import { UseRadioProps, Box, Circle, HStack, useRadio } from '@chakra-ui/react';

export type ItemProps = {
  label: React.ReactElement;
} & UseRadioProps;

export function RadioItem(props: ItemProps) {
  const { label } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <HStack
        {...checkbox}
        _checked={{
          bg: 'primary.50',
          '&[data-disabled]': {
            bg: 'gray.50',
          },
        }}
        _disabled={{
          opacity: 0.4,
          cursor: 'not-allowed',
        }}
        px={4}
        py={3}
        borderColor="gray.300"
        borderBottomWidth="1px"
        cursor="pointer"
      >
        <Circle
          {...checkbox}
          minW={4}
          minH={4}
          bg="white"
          borderColor="primary.600"
          borderWidth="2px"
          _checked={{
            bg: 'primary.600',
          }}
          _disabled={{
            borderColor: 'gray.100',
            bg: 'gray.100',
          }}
        >
          <Circle
            {...checkbox}
            minW={3}
            minH={3}
            _checked={{
              bg: 'primary.600',
              borderColor: 'white',
              borderWidth: '2px',
              '&[data-disabled]': {
                bg: 'gray.500',
              },
            }}
          />
        </Circle>
        <Box>{label}</Box>
      </HStack>
    </Box>
  );
}
