import { Maintenance } from './Maintenance';
import { MaintenanceDone } from './MaintenanceDone';
import { useMaintenanceStatus } from './hooks/useMaintenanceStatus';

type Props = {
  children: JSX.Element;
};

export function MaintenanceRoot({ children }: Props) {
  const maintenanceStatus = useMaintenanceStatus();

  switch (maintenanceStatus) {
    case 'DEFAULT':
      return children;
    case 'MAINTENANCE':
      return <Maintenance />;
    case 'MAINTENANCE_DONE':
      return (
        <MaintenanceDone
          onClickReturnButton={() => {
            window.location.reload();
          }}
        />
      );
    default:
      return children;
  }
}
