import { createIcon } from '@chakra-ui/react';

export const DeleteIcon = createIcon({
  displayName: 'DeleteIcon',
  viewBox: '0 0 16 18',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0H10V2H16V4H15V18H13H3H1V4H0V2H6V0ZM13 16V4H3V16H13ZM7 14V6H5V14H7ZM11 6V14H9V6H11Z"
      fill="currentColor"
    />
  ),
});
