import { createIcon } from '@chakra-ui/react';

export const ForecastAccuracyHighIcon = createIcon({
  displayName: 'ForecastAccuracyHighIcon',
  viewBox: '0 0 30 30',
  path: (
    <path
      d="M14.6 8.45c3.3 0 6.15 2.7 6.15 6.15s-2.85 6-6.15 6c-3.3 0-6.15-2.7-6.15-6s2.7-6.15 6.15-6.15zm0-2.25c-4.65 0-8.4 3.75-8.4 8.4S9.95 23 14.6 23s8.4-3.75 8.4-8.4-3.9-8.4-8.4-8.4z"
      fill="currentColor"
    />
  ),
});
