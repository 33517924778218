import { z } from 'zod';
import { TransferRequestType } from '@/gql/apollo';

export const schema = z.object({
  requestMedicines: z
    .array(
      z.object({
        requestType: z.literal(TransferRequestType.Take),
        medicineId: z.string(),
        medicineName: z.string(),
        unitOutline: z.string(),
        // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
        shortUnitOutline: z.string(),
        stockUnitSymbol: z.string(),
        stockUnitPrice: z.number(),
        stockUnitQuantity: z.number(),
        nearExpirationUnitQuantity: z.number().optional(),
        transferDate: z.date().optional(),
        targetPharmacyId: z.string(),
        unitQuantity: z.number().positive(),
        lotNumber: z.string().optional(),
        expirationDate: z.date().optional(),
        adoptionStoppedDate: z.string().optional(),
        requestMemo: z
          .string()
          .trim()
          .refine((val) => val.length <= 2000, '2,000文字以内で入力してください。'),
      }),
    )
    .min(1)
    .max(5),
});

export type FieldValues = z.infer<typeof schema>;
