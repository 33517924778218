import Big from 'big.js';
import { RoundingMethod } from '@/gql/apollo';

/**
 * 金額計算を行う
 *
 * @deprecated この関数はIEEE754の丸め誤差が考慮できていないため非推奨です。calculatePriceV2 を使用してください。
 */
export function calculatePrice(value: number, roundingMethod: RoundingMethod | null): number {
  if (roundingMethod === 'round') {
    return Math.round(value);
  }
  if (roundingMethod === 'roundDown') {
    return Math.floor(value);
  }
  if (roundingMethod === 'roundUp') {
    return Math.ceil(value);
  }
  return value;
}

export function calculatePriceV2(value: Big, roundingMethod: RoundingMethod | null): Big {
  if (roundingMethod === 'round') {
    return value.round(0, Big.roundHalfUp);
  }
  if (roundingMethod === 'roundDown') {
    return value.round(0, Big.roundDown);
  }
  if (roundingMethod === 'roundUp') {
    return value.round(0, Big.roundUp);
  }
  return value;
}
