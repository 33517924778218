import { Center, HStack } from '@chakra-ui/react';
import { AlertIconFilled, Typography } from '@kkhs/hakari-ui';

export function EmptyDialogBody() {
  return (
    <Center minH="421px">
      <HStack>
        <AlertIconFilled color="red.500" w={6} h={6} />
        <Typography variant="body" color="red.500" fontWeight="bold">
          読み込めませんでした。一度画面を閉じて、操作を再開してください。
        </Typography>
      </HStack>
    </Center>
  );
}
