import { createIcon } from '@chakra-ui/react';

export const AddCartIcon = createIcon({
  displayName: 'AddCartIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C14.7614 2 17.2614 3.11929 19.0711 4.92893L17.6569 6.34315C16.2091 4.89543 14.2091 4 12 4C7.58172 4 4 7.58172 4 12V13H6L3 16L0 13H2V12C2 6.47715 6.47715 2 12 2ZM12 22C9.23858 22 6.73857 20.8807 4.92893 19.0711L6.34315 17.6569C7.79086 19.1046 9.79086 20 12 20C16.4183 20 20 16.4183 20 12L20 11L18 11L21 8L24 11H22L22 12C22 17.5228 17.5228 22 12 22ZM15.973 8.02513C14.6062 6.65829 12.3901 6.65829 11.0233 8.02513L8.02513 11.0233C6.65829 12.3901 6.65829 14.6062 8.02513 15.973C9.39196 17.3398 11.608 17.3398 12.9749 15.973L15.973 12.9749C17.3398 11.608 17.3398 9.39196 15.973 8.02513ZM14.9123 9.08579C14.1313 8.30474 12.865 8.30474 12.0839 9.08578L9.08579 12.0839C8.30474 12.865 8.30474 14.1313 9.08579 14.9123C9.86683 15.6934 11.1332 15.6934 11.9142 14.9123L13.1478 13.6788L10.3195 10.8505L11.0235 10.1464L13.8518 12.9747L14.9123 11.9142C15.6934 11.1332 15.6934 9.86683 14.9123 9.08579Z"
      fill="currentColor"
    />
  ),
});
