import { Text, TextProps, useMultiStyleConfig } from '@chakra-ui/react';

type Props = {
  size?: 'md' | 'sm' | 'xs' | 'lg' | 'xl';
  inline?: boolean;
  isDigitSeparated?: boolean;
  children: string;
} & TextProps;

export function NumberLikeText({
  size = 'sm',
  inline,
  isDigitSeparated = false,
  children,
  ...chakraProps
}: Props) {
  const styles = useMultiStyleConfig('NumberText', { size });

  return (
    <Text {...chakraProps} sx={styles} as={inline ? 'span' : undefined}>
      {isDigitSeparated ? children.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : children}
    </Text>
  );
}
