import { createIcon } from '@chakra-ui/react';

export const StockIcon = createIcon({
  displayName: 'StockIcon',
  viewBox: '0 0 60 60',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4476 12C16.4122 12 16 12.6188 16 12.9424V19.4245H18.8952V14.0216H20.8952V19.4245H23.7904V14.0216H25.7904V19.4245H28.6856V14.0216H30.6856V19.4245H33.4901V14.0216H35.4901V19.4245H38.3853V14.0216H40.3853V19.4245H43.3711V12.9424C43.3711 12.6188 42.9589 12 41.9235 12H17.4476ZM43.3711 21.4245H16V28.9209H18.8952V23.5899H20.8952V28.9209H23.7904V23.5899H25.7904V28.9209H28.6856V23.5899H30.6856V28.9209H33.4901V23.5899H35.4901V28.9209H38.3853V23.5899H40.3853V28.9209H43.3711V21.4245ZM16 38.3453V30.9209H43.3711V38.3453H40.3853V33.0863H38.3853V38.3453H35.4901V33.0863H33.4901V38.3453H30.6856V33.0863H28.6856V38.3453H25.7904V33.0863H23.7904V38.3453H20.8952V33.0863H18.8952V38.3453H16ZM16 40.3453V46.8993C16 47.2229 16.4122 47.8417 17.4476 47.8417H41.9235C42.8036 47.8417 43.3131 47.2674 43.3711 46.9131V40.3453H16ZM14 12.9424C14 11.1078 15.7634 10 17.4476 10H41.9235C43.6077 10 45.3711 11.1078 45.3711 12.9424V46.9712V47.0161L45.3671 47.0609C45.2173 48.7247 43.555 49.8417 41.9235 49.8417H17.4476C15.7634 49.8417 14 48.7339 14 46.8993V12.9424ZM25.4249 45.0935H33.8555V43.0935H25.4249V45.0935Z"
    />
  ),
});
