export const checkboxTheme = {
  baseStyle: {
    control: {
      borderColor: 'primary.600',
      _disabled: {
        borderColor: 'gray.200',
        bg: 'transparent',
      },
      _checked: {
        bg: 'primary.600',
        borderColor: 'primary.600',
      },
      _indeterminate: {
        bg: 'primary.600',
        borderColor: 'primary.600',
      },
    },
  },
  defaultProps: {
    colorScheme: 'primary',
  },
};
