import { createIcon } from '@chakra-ui/react';

export const BallUnitSymbolIcon = createIcon({
  displayName: 'Ball',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.44 11.58L13.51 11.64L13.04 12.41L13 12.34C11.6204 11.2376 10.5805 9.76776 9.99999 8.09996V12.37C9.99999 12.9 9.87999 13.17 9.17999 13.17H7.83999L7.65999 12.33H9.05999C9.19999 12.33 9.19999 12.33 9.19999 12.14V5.13996H6.04999V4.40996H9.19999V2.45996H9.99999V4.45996H13.31V5.22996H9.99999V5.83996C10.4295 8.10078 11.6487 10.1353 13.44 11.58ZM8.99999 9.51996L8.66999 8.82996L8.56999 8.93996C7.63258 9.93466 6.57435 10.8081 5.41999 11.54H5.33999L5.81999 12.25L5.89999 12.19C7.04683 11.4409 8.08926 10.5431 8.99999 9.51996V9.51996ZM12 4.24996L12.08 4.32996L12.65 3.71996L12.59 3.64996C12.1659 3.21164 11.6966 2.81946 11.19 2.47996H11.12L10.57 3.09996L10.66 3.16996C11.1442 3.48073 11.5935 3.84282 12 4.24996V4.24996ZM7.81999 8.24996L8.52999 7.87996V7.78996C8.16431 7.04993 7.71829 6.3524 7.19999 5.70996L7.13999 5.63996L6.44999 5.99996L6.51999 6.08996C6.99607 6.74581 7.41431 7.44175 7.76999 8.16996L7.81999 8.24996ZM10.82 7.62996L10.75 7.70996L11.42 8.20996L11.48 8.13996C12.0579 7.51195 12.5216 6.78771 12.85 5.99996V5.90996L12.09 5.59996V5.69996C11.7862 6.41117 11.371 7.06942 10.86 7.64996L10.82 7.62996ZM6.55999 10.3V9.41996L6.40999 9.51996C5.95608 9.78732 5.48507 10.0245 4.99999 10.23V7.36996H6.24999V6.58996H4.99999V3.88996H6.38999V3.11996H2.60999V3.88996H4.14999V6.58996H2.73999V7.36996H4.14999V10.57C3.20999 10.91 2.89999 11.01 2.61999 11.09H2.48999L2.65999 11.95H2.75999C4.06712 11.5532 5.32513 11.0097 6.50999 10.33L6.55999 10.3Z"
        fill="currentColor"
      />
    </svg>
  ),
});
