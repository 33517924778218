import { createIcon } from '@chakra-ui/react';

export const InfrequentAttentionIcon = createIcon({
  displayName: 'InfrequentAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M7.16 9.66001C6.88 10.4 6.53 11.13 6.13 11.82V18.52H5.43V12.91C5.11 13.37 4.77 13.78 4.42 14.14C4.34 13.97 4.14 13.58 4 13.42C4.98 12.46 5.9 10.97 6.47 9.45001L7.16 9.66001ZM11.1 13.57C11.4 15.86 12 17.44 12.61 17.43C12.82 17.43 12.92 17.02 12.96 16.05C13.11 16.21 13.36 16.37 13.54 16.44C13.44 17.78 13.18 18.16 12.55 18.16C11.43 18.16 10.7 16.21 10.37 13.57H8.18V16.13C8.9 16.01 9.7 15.87 10.49 15.72L10.52 16.38C9.26 16.62 7.94 16.88 6.95 17.05L6.79 16.36L7.46 16.25V10.43L7.95 10.59C9.54 10.4 11.3 10.09 12.31 9.74001L12.89 10.31C12.33 10.48 11.65 10.64 10.92 10.78C10.92 11.52 10.97 12.23 11.03 12.9H13.36V13.57H11.1ZM7.08 17.62H11.32V18.28H7.08V17.62ZM10.3 12.9C10.23 12.26 10.19 11.59 10.18 10.91C9.51 11.02 8.83 11.11 8.18 11.19V12.9H10.3Z"
        fill="#5D6771"
      />
      <path
        d="M16.17 13.63C15.96 14.41 15.57 15.21 15.18 15.73C15.04 15.63 14.76 15.47 14.59 15.39C15.01 14.91 15.33 14.18 15.52 13.49L16.17 13.63ZM16.78 13.07H14.64V12.41H15.36V10.19H15.97V12.41H16.78V9.46002H17.45V10.65H19.02V11.3H17.45V12.41H19V13.07H17.45V15.49C17.45 15.81 17.4 15.99 17.17 16.09C16.94 16.19 16.61 16.2 16.11 16.2C16.08 16.01 16.01 15.77 15.92 15.59C16.26 15.59 16.56 15.59 16.66 15.59C16.75 15.59 16.78 15.56 16.78 15.48V13.07ZM18.9 15.24C18.33 16.93 17.09 17.99 15.18 18.58C15.1 18.39 14.93 18.13 14.76 17.97C16.56 17.5 17.73 16.54 18.26 15.04L18.7 15.18C18.56 14.75 18.29 14.12 17.99 13.65L18.56 13.46C18.88 13.96 19.21 14.61 19.34 15.04L18.86 15.23H18.9V15.24ZM21.04 17.22C20.54 17.73 19.71 18.27 18.99 18.57C18.88 18.44 18.65 18.22 18.49 18.09C19.21 17.81 20.01 17.28 20.41 16.85L21.04 17.22ZM20.87 11.49C20.94 11.19 21.01 10.84 21.07 10.53H19.19V9.89002H23.71V10.53H21.83C21.75 10.86 21.65 11.19 21.55 11.49H23.48V16.67H19.5V11.49H20.87ZM22.81 12.08H20.16V13.04H22.81V12.08ZM22.81 13.58H20.16V14.55H22.81V13.58ZM22.81 15.11H20.16V16.08H22.81V15.11ZM22.35 16.89C22.88 17.25 23.58 17.79 23.92 18.16L23.35 18.56C23.01 18.2 22.34 17.64 21.79 17.26L22.35 16.89Z"
        fill="#5D6771"
      />
    </g>
  ),
});
