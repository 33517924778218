import { Button, Img, Stack, VStack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';

type Props = {
  onClickReturnButton?: () => void;
};

export function MaintenanceDone(props: Props) {
  return (
    <Stack bg="base.default" minH="100vh" alignItems="center" justifyContent="center">
      <VStack
        bg="white"
        spacing={12}
        width="xl"
        alignItems="center"
        borderRadius="base"
        overflow="hidden"
        px={10}
        py={20}
      >
        <Img src="/assets/svg/hakari-logo.svg" alt="Musubi AI在庫管理" h="56px" w="182px" />
        <VStack spacing={8}>
          <Typography variant="h2">システムメンテナンスが完了しました</Typography>
          <Typography variant="body" align="center" lineHeight={6}>
            ご協力ありがとうございました。
          </Typography>
          <Button px={6} py={2} lineHeight={6} onClick={props?.onClickReturnButton}>
            元のページに戻る
          </Button>
        </VStack>
      </VStack>
    </Stack>
  );
}
