import { ArrowDownIcon, ArrowUpIcon, ChevronUpDownIcon } from '@kkhs/hakari-ui';
import { memo } from 'react';
import { SortDirection } from '@/gql/docs';

/**
 * ソートアイコン
 * アイコンをクリックすると API を叩き直す場合に使用する
 */
function SortIconComponent<T extends { direction: SortDirection; field: string }>({
  sortBy,
  fieldName,
}: {
  sortBy: T | undefined;
  fieldName: T['field'];
}) {
  const { field, direction } = sortBy ?? {};
  if (!field || field !== fieldName) {
    return <ChevronUpDownIcon boxSize={4} ml={1} color="primary.500" />;
  }
  return direction === 'asc' ? (
    <ArrowUpIcon boxSize={3} ml={1} color="primary.500" />
  ) : (
    <ArrowDownIcon boxSize={3} ml={1} color="primary.500" />
  );
}

const typedMemo: <T>(c: T) => T = memo;
export const SortIcon = typedMemo(SortIconComponent);
