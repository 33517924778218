const JST_OFFSET = 9;

/**
 * 実行環境で生成された Date オブジェクトを JST の Date オブジェクトにして返す
 */
export function convertToJst(date: Date) {
  // 実行環境と JST との差分（ミリ秒）
  const diffMsecFromJst = (new Date().getTimezoneOffset() + JST_OFFSET * 60) * 60 * 1000;
  const utcMsec = date.getTime();
  return new Date(utcMsec + diffMsecFromJst);
}
