import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createCurrentPharmacyLink, createHttpLink, createRetryWithDelayLink } from './apolloLinks';
import { createErrorLink } from './apolloLinks/errorLink';
import { inMemoryCacheConfig as configForAIZaikoKanri } from './inMemoryCacheConfig';
import { inMemoryCacheConfig as configForOrgManagement } from '../../orgManagement/lib/inMemoryCacheConfig';
import type { InMemoryCacheConfig as ConfigForAIZaikoKanri } from './inMemoryCacheConfig';
import type { InMemoryCacheConfig as ConfigForOrgManagement } from '../../orgManagement/lib/inMemoryCacheConfig';

const region = 'ap-northeast-1';

/* GraphQLクライアントはAI在庫機能と本部管理機能で共用のためconfigをマージして利用する */
const mergedInMemoryCacheConfig: ConfigForAIZaikoKanri & ConfigForOrgManagement = {
  ...configForAIZaikoKanri,
  typePolicies: {
    ...configForAIZaikoKanri.typePolicies,
    Query: {
      ...configForAIZaikoKanri.typePolicies.Query,
      fields: {
        ...configForAIZaikoKanri.typePolicies.Query.fields,
        ...configForOrgManagement.typePolicies.Query.fields,
      },
    },
  },
};

/**
 * GraphQL 向け Apollo Client
 */
export function createGraphQLClient({
  pharmacyId,
  apiUrl,
  stockSaleApiUrl,
}: {
  /** 選択中の薬局ID（薬局選択前の場合があるので optional） */
  pharmacyId?: string;
  /** API URL */
  apiUrl: string;
  /** 在庫売却 API URL */
  stockSaleApiUrl: string;
}) {
  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    cache: new InMemoryCache(mergedInMemoryCacheConfig),
    link: ApolloLink.from([
      createErrorLink(),
      createRetryWithDelayLink(),
      createAuthLink({
        auth: {
          jwtToken: async (): Promise<string> => {
            try {
              const session = await Auth.currentSession();
              return session.getIdToken().getJwtToken();
            } catch {
              await Auth.signOut();
              return '';
            }
          },
          type: AUTH_TYPE.OPENID_CONNECT,
        },
        region,
        url: apiUrl,
      }),
      createCurrentPharmacyLink(pharmacyId),
      createHttpLink({
        apiUrl,
        stockSaleApiUrl,
      }),
    ]),
  });
}
