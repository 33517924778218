import { convertToJst, jaLocale } from '@kkhs/hakari-utils';
import { format, parseISO } from 'date-fns';
import { AWSDateTime } from '../aws';

/**
 * AWSDateTime を `yyyy/MM/dd HH:mm`, `yyyy/MM/dd(E) HH:mm` などのフォーマットに変換する
 * format オプションを指定した場合は優先される
 */
export function formatAWSDateTime(
  awsDateTime: AWSDateTime | undefined | null,
  { showDayOfWeek, format: formatStr }: { showDayOfWeek?: boolean; format?: string } = {
    showDayOfWeek: false,
  },
): string {
  if (!awsDateTime) return '-';
  if (formatStr) {
    return format(convertToJst(parseISO(awsDateTime)), formatStr, {
      locale: jaLocale,
    });
  }
  if (showDayOfWeek) {
    return format(convertToJst(parseISO(awsDateTime)), 'yyyy/MM/dd(E) HH:mm', {
      locale: jaLocale,
    });
  }
  return format(convertToJst(parseISO(awsDateTime)), 'yyyy/MM/dd HH:mm', {
    locale: jaLocale,
  });
}
