import { TransactionReason } from '@/gql/apollo';
import { DisposalReason } from '../../models/disposalReason';

export const getTransactionSubreason = (
  reason: TransactionReason,
  { disposalReason }: { disposalReason?: DisposalReason | null },
) => {
  if (reason === 'disposal') {
    return disposalReason ?? null;
  }
  return null;
};
