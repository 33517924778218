import { AlertDialogFooter, HStack } from '@chakra-ui/react';
import { ShippingStockOperationDeleteButtonGroup } from './ShippingStockOperationDeleteButtonGroup';

type Props = {
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
  children?: React.ReactNode;
  loading: boolean;
};

export function ShippingStockOperationDeleteFooterLayout({
  onClose,
  cancelRef,
  children,
  loading,
}: Props) {
  return (
    <AlertDialogFooter justifyContent="space-between" alignItems="start" borderTopWidth={1}>
      <HStack spacing={2}>{children}</HStack>
      <ShippingStockOperationDeleteButtonGroup
        onClose={onClose}
        cancelRef={cancelRef}
        loading={loading}
      />
    </AlertDialogFooter>
  );
}
