import {
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverAnchor,
  useDisclosure,
  Stack,
  Button,
} from '@chakra-ui/react';
import { PlusSquareIcon, TextInput, Typography, useDebouncedState } from '@kkhs/hakari-ui';
import { UseFieldArrayPrepend, UseFieldArrayRemove } from 'react-hook-form';
import { AdoptedMedicineSearchResults } from './AdoptedMedicineSearchResults';
import { isLotCountMax } from '../../functions/isLotCountMax';
import { UpdateStoringStockOperationFieldValues } from '../schema';

type Props = {
  prependOperatedMedicine: UseFieldArrayPrepend<
    UpdateStoringStockOperationFieldValues,
    'operatedMedicines'
  >;
  removeOperatedMedicine: UseFieldArrayRemove;
  currentOperatedMedicines: UpdateStoringStockOperationFieldValues['operatedMedicines'];
};

export function SearchMedicine({
  prependOperatedMedicine,
  removeOperatedMedicine,
  currentOperatedMedicines,
}: Props) {
  const searchResultDisclosure = useDisclosure({
    id: 'search-result',
  });
  const [value, debouncedSetValue] = useDebouncedState('', 500);
  const isAddable = !isLotCountMax(currentOperatedMedicines);

  return (
    <HStack bg="gray.50" px={6} py={3} justifyContent="space-between" alignItems="flex-start">
      <Stack alignSelf="center" spacing={1}>
        <Popover
          isLazy
          gutter={0}
          autoFocus={false}
          returnFocusOnClose={false}
          isOpen={searchResultDisclosure.isOpen}
          onOpen={searchResultDisclosure.onOpen}
          onClose={searchResultDisclosure.onClose}
        >
          <PopoverTrigger>
            <HStack>
              <PlusSquareIcon color="slate.100" boxSize={7} />
              {isAddable ? (
                <PopoverAnchor>
                  <TextInput
                    size="sm"
                    w="800px"
                    placeholder="追加する医薬品名を入力してください"
                    _placeholder={{ fontSize: '15px' }}
                    onChange={(e) => debouncedSetValue(e.target.value)}
                  />
                </PopoverAnchor>
              ) : (
                <Typography variant="body" color="slate.500" fontWeight="bold">
                  同時に処理できるロット数は最大5つです
                </Typography>
              )}
            </HStack>
          </PopoverTrigger>
          {isAddable && (
            <PopoverContent w="800px">
              <PopoverBody overflowY="auto" maxH="340px" p={6}>
                <AdoptedMedicineSearchResults
                  medicineSearchKeyword={value}
                  currentOperatedMedicines={currentOperatedMedicines}
                  prependOperatedMedicine={prependOperatedMedicine}
                  onClose={searchResultDisclosure.onClose}
                />
              </PopoverBody>
            </PopoverContent>
          )}
        </Popover>
        {isAddable && (
          <Typography variant="body2" color="slate.500" pl={9}>
            同じ医薬品を追加する場合は「入力欄を追加」から入力してください
          </Typography>
        )}
      </Stack>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => removeOperatedMedicine()}
        isDisabled={currentOperatedMedicines.length === 0}
      >
        すべて削除する
      </Button>
    </HStack>
  );
}
