import { PropsWithChildren, ReactNode } from 'react';
import { components, GroupBase, SingleValueProps } from 'react-select';
import { SelectOptionType } from '../types';

type Props<T> = {
  renderSingleValueNotice?: (data: SelectOptionType<T>) => ReactNode;
} & PropsWithChildren<SingleValueProps<SelectOptionType<T>, false, GroupBase<SelectOptionType<T>>>>;

export function SingleValueOption<T>({ renderSingleValueNotice, children, ...rest }: Props<T>) {
  return (
    <components.SingleValue {...rest}>
      {children}
      {renderSingleValueNotice && renderSingleValueNotice(rest.data)}
    </components.SingleValue>
  );
}
