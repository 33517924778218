import {
  checkboxTheme,
  numberTextTheme,
  popoverTheme,
  typographyTheme,
  datepickerStyle,
} from './components';
import type { Theme as ChakraTheme } from '@chakra-ui/react';

export const theme = {
  styles: {
    global: {
      ...datepickerStyle,
    },
  },
  fonts: {
    heading: '"Noto Sans JP"',
    body: '"Noto Sans JP"',
  },
  fontSizes: {
    xs: '0.625rem', // 10px
    sm: '0.6875rem', // 11px
    md: '0.875rem', // 14px
  },
  colors: {
    primary: {
      50: '#f7fcfc',
      100: '#d5f1ef',
      200: '#abe3df',
      300: '#82d4ce',
      400: '#58c6be',
      500: '#2EB8AE',
      600: '#25938b',
      700: '#1c6e68',
      800: '#124a46',
      900: '#092523',
    },
    slate: {
      25: '#E6E9EB',
      50: '#D6D9DB',
      100: '#C2C6CA',
      200: '#AEB3B8',
      300: '#858D94',
      400: '#717A82',
      500: '#5D6771',
      600: '#48545F',
      700: '#34414D',
      800: '#242E36',
      900: '#0A0D0F',
    },
    orange: {
      50: '#FFECD2',
      100: '#FDDBAC',
      200: '#FCC87F',
      300: '#FBB552',
      400: '#FAA126',
      500: '#F19516',
      600: '#C17711',
      700: '#91590C',
      800: '#613B07',
      900: '#321D00',
    },
  },
  components: {
    Checkbox: checkboxTheme,
    NumberText: numberTextTheme,
    Popover: popoverTheme,
    Typography: typographyTheme,
  },
};

export type Theme = ChakraTheme & typeof theme;
