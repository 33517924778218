import { ExternalStockOperationPriceConfig, InternalStockOperationPriceConfig } from '@/gql/docs';

/**
 * 消費税表示が「表示する」かつ「明細ごと」の場合のみ、true を返す
 */
export function showTaxByItem(
  currentPriceConfig: ExternalStockOperationPriceConfig | InternalStockOperationPriceConfig,
) {
  if (currentPriceConfig.__typename === 'ExternalStockOperationPriceConfig') {
    return currentPriceConfig.shouldShowTax && currentPriceConfig.taxCalculationTarget === 'item';
  }
  if (currentPriceConfig.__typename === 'InternalStockOperationPriceConfig') {
    return false;
  }
  throw new Error('Invalid price config type');
}
