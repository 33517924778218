import { isNullOrUndefined } from '@kkhs/hakari-utils';
import Big from 'big.js';
import {
  ExternalStockOperationPriceConfig,
  InternalStockOperationPriceConfig,
  RoundingMethod,
  StockOperationReason,
} from '@/gql/docs';
import { calculatePriceV2 } from '@/shared/utils';
import { getPrice } from '../getPrice';

/**
 * 出庫処理の総額(税込)
 * - 明細端数処理（itemRoundingMethod）を適用したものを足し合わせ、
 *   総額端数処理（totalRoundingMethod）を適用する
 * - 法人内出庫の場合、出庫単価は在庫管理単位薬価を利用する
 */
export function calculateTotalAmount({
  operationMedicines,
  stockOperationPriceConfig,
  containerAmount = 0,
  feeAmount = 0,
  totalTaxAmount,
  reason,
}: {
  operationMedicines: {
    price?: number | null;
    /** 在庫管理単位薬価 */
    medicineStockUnitPrice: number;
    stockUnitQuantity: number;
  }[];
  stockOperationPriceConfig: ExternalStockOperationPriceConfig | InternalStockOperationPriceConfig;
  /** 容器代 */
  containerAmount?: number;
  /** 手数料 */
  feeAmount?: number;
  /** 税額 */
  totalTaxAmount: number | null;
  /** 区分 */
  reason: StockOperationReason;
}): number {
  const { itemRoundingMethod, totalRoundingMethod } = stockOperationPriceConfig;
  const beforeRoundingTotalAmount = operationMedicines
    .reduce((acc, cur) => {
      const price = getPrice({ operationMedicine: cur, reason });
      return !isNullOrUndefined(price) &&
        !isNullOrUndefined(cur.stockUnitQuantity) &&
        !Number.isNaN(price) &&
        !Number.isNaN(cur.stockUnitQuantity)
        ? acc.plus(
            calculatePriceV2(new Big(price).times(cur.stockUnitQuantity), itemRoundingMethod),
          )
        : // NOTE: 明細端数処理（itemRoundingMethod）を適用する
          acc;
    }, new Big(0))
    .plus(containerAmount)
    .plus(feeAmount)
    .plus(totalTaxAmount ?? 0);

  // NOTE: 総額端数処理（totalRoundingMethod）を適用する
  return calculatePriceV2(beforeRoundingTotalAmount, totalRoundingMethod).toNumber();
}

/**
 * 入庫処理の総額(税込)
 * - 明細端数処理（itemRoundingMethod）を適用したものを足し合わせ、
 *   総額端数処理（totalRoundingMethod）を適用する
 * - 入庫区分問わず常に原価を参照する (出庫とは異なるので注意)
 */
export function calculateTotalAmountForStoreStockOperation({
  operationMedicines,
  itemRoundingMethod,
  totalRoundingMethod,
  containerAmount = 0,
  feeAmount = 0,
  totalTaxAmount,
}: {
  operationMedicines: {
    price?: number | null;
    stockUnitQuantity: number;
  }[];
  itemRoundingMethod: RoundingMethod | null;
  totalRoundingMethod: RoundingMethod | null;
  /** 容器代 */
  containerAmount?: number;
  /** 手数料 */
  feeAmount?: number;
  /** 税額 */
  totalTaxAmount: number | null;
}) {
  const beforeRoundingTotalAmount = operationMedicines
    .reduce(
      (acc, { price, stockUnitQuantity }) =>
        !isNullOrUndefined(price) &&
        !isNullOrUndefined(stockUnitQuantity) &&
        !Number.isNaN(price) &&
        !Number.isNaN(stockUnitQuantity)
          ? acc.plus(calculatePriceV2(new Big(price).times(stockUnitQuantity), itemRoundingMethod))
          : // NOTE: 明細端数処理（itemRoundingMethod）を適用する
            acc,
      new Big(0),
    )
    .plus(containerAmount)
    .plus(feeAmount)
    .plus(totalTaxAmount ?? 0);

  // NOTE: 総額端数処理（totalRoundingMethod）を適用する
  return calculatePriceV2(beforeRoundingTotalAmount, totalRoundingMethod).toNumber();
}
