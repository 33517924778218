import { z } from 'zod';
import { demandTakeoverToMedicineSchema } from '@/entities/adoptedMedicine';

export const schema = z.object({
  adoptionStopped: z.union([z.literal('true'), z.literal('false')]),
  wholesaleId: z.string().optional(),
  medicinePackageUnitId: z.string(),
  storages: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
  ),
  // おすすめ発注の非表示期間
  orderRecommendSuspendTerm: z
    .object({
      isInf: z.boolean(),
      startDate: z.date(),
      // NOTE: isInf: true の場合は、endDate は undefined
      endDate: z.date().optional(),
    })
    .optional(),
  // NOTE: setValue('demandTakeoverToMedicine', undefined, { setDirty: true }) だと、うまく動かないため
  demandTakeoverToMedicine: demandTakeoverToMedicineSchema.nullish(),
  // 発注点設定
  orderPointSetting: z
    .object({
      mode: z.union([z.literal('auto'), z.literal('fixed'), z.literal('factor')]),
      fixedValue: z.number().nullable(),
      factor: z.number().nullable(),
    })
    .refine(({ mode, fixedValue, factor }) => {
      // mode が fixed, factor の場合は入力値が null の場合は invalid にする
      if (mode === 'fixed') {
        return fixedValue !== null && fixedValue >= 0 && fixedValue <= 999999.9;
      }
      if (mode === 'factor') {
        return factor !== null && factor >= 0 && factor <= 9.9;
      }
      return true;
    }),
});

export type FieldValues = z.infer<typeof schema>;
