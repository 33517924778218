import Encoding from 'encoding-japanese';

export class CsvFileReader {
  private reader: FileReader;

  constructor(private file: File) {
    this.reader = new FileReader();
  }

  isCsv(): boolean {
    return this.file.type === 'text/csv';
  }

  /**
   * 読み込まれたファイルの容量が引数で指定されたファイル容量以下かどうかをチェックします。
   *
   * @remarks
   * `sizeLimitMB` はメガバイトの単位で渡すこと
   */
  isWithinSizeLimit(sizeLimitMB: number): boolean {
    const fileSizeInMB = this.file.size / (1024 * 1024);
    return fileSizeInMB <= sizeLimitMB;
  }

  /**
   * 読み込まれたファイルのエンコーディングがUTF-8（ASCIIも含む）かどうかをチェックします。
   *
   * @remarks
   * アルファベットだけの場合などにASCIIと判定されますが、UTF-8として扱いたいのでASCIIも含めています。
   * UTF-8がASCIIと互換性を保ったエンコーディング方式なためASCIIをUTF-8として扱うリスクは無視できる想定です。
   *
   * @privateRemarks
   * JavaScriptは内部でUTF-16のエンコーディングとしてテキストを持つため、FileReader#readAsTextのようにテキストで読み込んでしまうと文字コードが変わってしまい正しく判別できなくなります。
   *
   * @see {@link https://github.com/polygonplanet/encoding.js/blob/master/README_ja.md#file-api-%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%9F%E3%83%95%E3%82%A1%E3%82%A4%E3%83%AB%E3%81%AE%E6%96%87%E5%AD%97%E3%82%B3%E3%83%BC%E3%83%89%E5%88%A4%E5%AE%9A%E5%A4%89%E6%8F%9B%E4%BE%8B}
   */
  isUtf8(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.reader.onload = (e: ProgressEvent<FileReader>) => {
        // ArrayBufferで読み込んでいるのでそれ以外は例外として弾く（これらはUint8Arrayに渡せない）
        if (!e.target?.result || typeof e.target?.result === 'string') {
          reject(new Error(`ArrayBufferで読み込む必要があります: ${e.target?.result}`));
          return;
        }
        const codes = new Uint8Array(e.target.result);
        const encoding = Encoding.detect(codes);
        resolve(encoding === 'UTF8' || encoding === 'ASCII');
      };
      this.reader.onerror = (e) => {
        reject(new Error(`ファイルの読み込みに失敗しました: ${JSON.stringify(e)}`));
      };
      this.reader.readAsArrayBuffer(this.file);
    });
  }

  /**
   * UTF-8でファイルを読み込み改行コードを数えます。CsvFileReader#isUtf8でエンコーディングをチェックしてから使うことを推奨します。
   *
   * @remarks
   * 行間や行末に改行がある場合も行数としてカウントするような実装になっています
   * ヘッダーはカウントしないので - 1
   */
  countLines(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.reader.onload = (e: ProgressEvent<FileReader>) => {
        const contents = e.target?.result;
        if (typeof contents !== 'string') {
          reject(new Error(`ファイルはテキスト形式ではありません: ${contents}`));
          return;
        }
        const lines = contents.split(/\r\n|\n|\r/);
        resolve(lines.length - 1);
      };
      this.reader.onerror = (e) => {
        reject(new Error(`ファイルの読み込みに失敗しました: ${JSON.stringify(e)}`));
      };
      this.reader.readAsText(this.file, 'utf-8');
    });
  }
}
