import { ParsedUrlQuery } from 'querystring';
import { useCallback, useEffect, useState } from 'react';
import {
  DeepPartial,
  FieldValues,
  SubmitHandler,
  UnpackNestedValue,
  UseFormProps,
} from 'react-hook-form';
import { z } from 'zod';
import { Query, useUrlQuery } from '../useUrlQuery';
import { useZodForm } from '../useZodForm';

type UseUrlSyncFormProps<TFieldValues extends FieldValues> = Omit<
  UseFormProps<TFieldValues>,
  'resolver'
> & {
  schema: z.Schema;
  parseQueryString: (query: ParsedUrlQuery) => UnpackNestedValue<TFieldValues> | undefined;
  buildQueryString: (
    query: ParsedUrlQuery,
    values: UnpackNestedValue<TFieldValues> | undefined,
  ) => Query;
  /** クエリストリングの値が変わった際の callback */
  onChangeQueryString?: (
    fieldValues:
      | UnpackNestedValue<TFieldValues>
      | UnpackNestedValue<DeepPartial<TFieldValues>>
      | undefined,
  ) => void;
};

export function useUrlSyncForm<TFieldValues extends FieldValues = FieldValues>(
  props: UseUrlSyncFormProps<TFieldValues>,
) {
  const {
    schema,
    parseQueryString,
    buildQueryString,
    defaultValues,
    onChangeQueryString,
    ...useFormProps
  } = props;
  const useFormReturn = useZodForm({
    ...useFormProps,
    schema,
    defaultValues,
  });
  const { reset } = useFormReturn;
  const { query, push } = useUrlQuery();

  const [fieldValues, setFieldValues] = useState(() => {
    const parsed = parseQueryString(query);
    return schema.safeParse(parsed).success ? parsed : defaultValues;
  });

  // NOTE: クエリストリングが更新されたら再検索する
  useEffect(() => {
    const parsed = parseQueryString(query);
    const nextValues = schema.safeParse(parsed).success ? parsed : defaultValues;
    setFieldValues(nextValues);

    reset(nextValues);

    onChangeQueryString?.(nextValues);
  }, [defaultValues, onChangeQueryString, parseQueryString, query, reset, schema]);

  const onSubmit: SubmitHandler<TFieldValues> = useCallback(
    (values) => {
      push(buildQueryString(query, values));
    },
    [buildQueryString, push, query],
  );

  return {
    useFormReturn,
    fieldValues,
    onSubmit,
  };
}
