import { TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  ADOPTED_MEDICAL_PRODUCT_MODAL_TABS,
  AdoptedMedicalProductType,
} from '@/entities/adoptedMedicalProduct';
import { HorizontalTab } from './HorizontalTab';
import { useAdoptedMedicalModalTabs } from './hooks';
import {
  MedicineTransactionListItemsTab,
  PatientsTab,
  useMedicineTransactionListItemsTabForm,
  AdoptedMedicalProductDetailTab,
  AdoptedMedicalProductSummaryTab,
  GroupPharmacyStocksTab,
  AdoptedMedicalProductUpdateTab,
  useGroupPharmacyStocksTabForm,
} from './tabs';

const TABLE_BODY_HEIGHT = 558;
const MEDICINE_SUMMARY_TAB_HEIGHT = 424;

type Props = {
  /** 対象の薬剤ID */
  medicineId: string;
  /** 単位 */
  stockUnitSymbol: string | undefined;
  /** 医薬品 or 医療材料 */
  medicalProductType: AdoptedMedicalProductType | undefined;
  /** 必須買いかどうか */
  isMustBuy?: boolean;
};

export function AdoptedMedicalModalBody({
  medicineId,
  stockUnitSymbol,
  medicalProductType,
  isMustBuy,
}: Props) {
  const scrollParentRef = useRef(null);
  const useGroupPharmacyStocksTabFormReturn = useGroupPharmacyStocksTabForm();
  const useMedicineTransactionsTabFormReturn = useMedicineTransactionListItemsTabForm();

  const { handleSelectIndex, renderDialog } = useAdoptedMedicalModalTabs();

  return (
    <>
      <TabList borderRightWidth={1} minWidth="200px" pt={6}>
        {ADOPTED_MEDICAL_PRODUCT_MODAL_TABS.map(({ key, value }, index) => (
          <HorizontalTab
            key={key}
            label={value}
            index={index}
            onClick={() => {
              handleSelectIndex(index, value, isMustBuy);
            }}
          />
        ))}
      </TabList>
      <TabPanels overflow="auto" ref={scrollParentRef}>
        <TabPanel bg="gray.50" h={MEDICINE_SUMMARY_TAB_HEIGHT} minH="full" p={6}>
          <AdoptedMedicalProductSummaryTab medicineId={medicineId} />
        </TabPanel>
        <TabPanel p={6} h={TABLE_BODY_HEIGHT}>
          <AdoptedMedicalProductDetailTab medicineId={medicineId} />
        </TabPanel>
        <TabPanel px={6}>
          {stockUnitSymbol && (
            <PatientsTab
              medicineId={medicineId}
              stockUnitSymbol={stockUnitSymbol}
              scrollParentRef={scrollParentRef}
            />
          )}
        </TabPanel>
        <TabPanel p={0}>
          <FormProvider {...useGroupPharmacyStocksTabFormReturn}>
            <GroupPharmacyStocksTab medicineId={medicineId} />
          </FormProvider>
        </TabPanel>
        <TabPanel p={0}>
          {stockUnitSymbol && (
            <FormProvider {...useMedicineTransactionsTabFormReturn}>
              <MedicineTransactionListItemsTab
                medicineId={medicineId}
                stockUnitSymbol={stockUnitSymbol}
                scrollParentRef={scrollParentRef}
              />
            </FormProvider>
          )}
        </TabPanel>
        <TabPanel p={6} h={TABLE_BODY_HEIGHT}>
          {medicalProductType && (
            <AdoptedMedicalProductUpdateTab medicineId={medicineId} type={medicalProductType} />
          )}
        </TabPanel>
      </TabPanels>
      {renderDialog()}
    </>
  );
}
