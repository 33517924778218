import { HStack, Tr, Td, chakra } from '@chakra-ui/react';
import { NumberLikeText } from '@kkhs/hakari-ui';
import { StockUnitSymbolIcon } from '@/entities/medicine';
import {
  isEditableMedicineTransaction,
  getTransactionReasonLabel,
} from '@/entities/stockOperation';
import { MedicineTransactionListItemsQuery } from '@/gql/docs';
import { formatAWSDate, formatAWSDateTime, isJstTodayAWSDateTime } from '@/shared/utils';
import { StockOperationEditButton } from './StockOperationEditButton';

const StyledTd = chakra(Td, {
  baseStyle: () => ({
    px: 1.5,
    py: 2,
    verticalAlign: 'top',
    lineHeight: '14px',
  }),
});

type Props = {
  stockUnitSymbol: string;
  node: MedicineTransactionListItemsQuery['medicineTransactionListItems']['edges'][number]['node'] & {
    transactionType: 'replenishment';
  };
};

export function MedicineTransactionReplenishmentRow({ stockUnitSymbol, node }: Props) {
  const stockUnitQuantityAfterTransactionText =
    node.stockUnitQuantityAfterTransaction >= 0
      ? `${node.stockUnitQuantityAfterTransaction}`
      : `△${Math.abs(node.stockUnitQuantityAfterTransaction)}`;
  return (
    <Tr key={node.id}>
      <StyledTd pl={0} data-testid="入庫日">
        {formatAWSDate(node.transaction.date, { showDayOfWeek: true })}
      </StyledTd>
      <StyledTd>入庫</StyledTd>
      <StyledTd whiteSpace="pre-wrap" data-testid="区分">
        {getTransactionReasonLabel({
          type: node.transactionType,
          reason: node.transaction.reason,
        })}
      </StyledTd>
      <StyledTd data-testid="入出庫先">{node.transactionTarget || '-'}</StyledTd>
      <StyledTd isNumeric>
        <HStack spacing={0} justifyContent="flex-end">
          <NumberLikeText data-testid="入出庫数量" isDigitSeparated>{`${Math.abs(
            node.transaction.unitQuantity,
          )}`}</NumberLikeText>
          <StockUnitSymbolIcon name={stockUnitSymbol} boxSize={4} />
        </HStack>
      </StyledTd>
      <StyledTd isNumeric>
        <HStack spacing={0} justifyContent="flex-end">
          <NumberLikeText data-testid="入出庫後の在庫数量" isDigitSeparated>
            {stockUnitQuantityAfterTransactionText}
          </NumberLikeText>
          <StockUnitSymbolIcon name={stockUnitSymbol} color="slate.500" boxSize={4} />
        </HStack>
      </StyledTd>
      <StyledTd data-testid="ロット番号">{node.transaction.lotNumber || '-'}</StyledTd>
      <StyledTd data-testid="メモ">{node.transaction.memo || '-'}</StyledTd>
      <StyledTd>
        <NumberLikeText whiteSpace="nowrap">
          {formatAWSDateTime(node.transaction.updatedAt, {
            format: isJstTodayAWSDateTime(node.transaction.updatedAt) ? 'HH:mm' : 'yyyy/MM/dd',
          })}
        </NumberLikeText>
      </StyledTd>
      <StyledTd pr={0}>
        {isEditableMedicineTransaction(node) && (
          <StockOperationEditButton stockOperationId={node.stockOperationId} />
        )}
      </StyledTd>
    </Tr>
  );
}
