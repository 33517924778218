import { Box, Button, Circle, HStack, StackProps } from '@chakra-ui/react';
import { isJstToday } from '@kkhs/hakari-utils';
import doFormat from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import ja from 'date-fns/locale/ja';
import { NumberLikeText } from '../NumberLikeText';
import { AlertIconFilled } from '../icons';

export type DateStepperItem = {
  key: number | string;
  date: Date;
  onClick: () => void;
  count?: number;
  failureCount?: number;
};

type Props = StackProps & {
  targetDate: Date;
  items: DateStepperItem[];
  format?: string;
  hideIsToday?: boolean;
};

export function DateStepper({
  targetDate,
  items,
  format = 'M/d(E)',
  hideIsToday,
  ...props
}: Props) {
  return (
    <HStack py={2} px={10} bg="slate.25" mx="auto" overflowX="auto" dir="rtl" {...props}>
      <HStack mx="auto" spacing={10}>
        {items.map(({ key, date, onClick, count, failureCount }) => {
          const isSelected = isSameDay(targetDate, date);
          return (
            <Box textAlign="center" key={key}>
              <Button
                size="xs"
                variant={isSelected ? 'solid' : 'ghost'}
                bg={isSelected ? 'white' : 'transparent'}
                borderRadius="full"
                onClick={onClick}
                py={4}
                _hover={{
                  bg: isSelected ? 'white' : 'slate.50',
                }}
                _active={{
                  bg: isSelected ? 'white' : 'slate.100',
                }}
                pointerEvents={isSelected ? 'none' : 'fill'}
                color="slate.700"
                fontWeight={isSelected ? 'bold' : 'normal'}
              >
                {!hideIsToday && isJstToday(date) && <NumberLikeText>本日</NumberLikeText>}
                <NumberLikeText>
                  {doFormat(date, format, {
                    locale: ja,
                  })}
                </NumberLikeText>
                {!!count && (
                  <Circle
                    display="inline-flex"
                    ml="1"
                    color="white"
                    p={1}
                    minW="20px"
                    minH="20px"
                    bg="slate.500"
                  >
                    <NumberLikeText inline color="white" size="xs" fontWeight="bold">
                      {count < 1000 ? `${count}` : '999+'}
                    </NumberLikeText>
                  </Circle>
                )}
                {!!failureCount && <AlertIconFilled color="red.500" w={5} h={5} ml={1} />}
              </Button>
            </Box>
          );
        })}
      </HStack>
    </HStack>
  );
}
