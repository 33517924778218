import { createIcon } from '@chakra-ui/react';

export const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeftIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 11.9999L16 18L14 20L6 11.9999L14 4L16 6L10 11.9999Z"
      fill="currentColor"
    />
  ),
});
