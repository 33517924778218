import { Stack, HStack, Box } from '@chakra-ui/react';
import { Typography, InfoPopover, InfoIcon } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import {
  ConvertedStockUnitQuantityWithSymbol,
  StockUnitQuantityWithSymbol,
} from '@/entities/medicine';
import { isFewPrescriptionFrequency, PrescriptionFrequencyType } from '@/entities/prescription';
import { MedicineSummaryTabQuery } from '@/gql/apollo';
import { formatAWSDate } from '@/shared/utils';
import { PRESCRIBED_UNIT_QUANTITY_MONTH_RANGE } from '../constants';

type Props = {
  stockUnitSymbol: string;
  prescriptionFrequencyType?: PrescriptionFrequencyType;
  orderPointForecast: MedicineSummaryTabQuery['orderPointForecast'];
};

export function StandardStockUnitQuantityContent({
  stockUnitSymbol,
  prescriptionFrequencyType,
  orderPointForecast,
}: Props) {
  if (isFewPrescriptionFrequency(prescriptionFrequencyType)) return null;

  return (
    <Stack
      borderBottom={prescriptionFrequencyType === 'fn' ? '1px' : 'none'}
      borderColor="gray.200"
      spacing={0}
      pb={prescriptionFrequencyType === 'fn' ? 4 : 0}
      data-testid="基準在庫量ブロック"
    >
      <HStack spacing={1}>
        <Typography variant="body">基準在庫量</Typography>
        <InfoPopover
          content={`特定患者を除く過去${PRESCRIBED_UNIT_QUANTITY_MONTH_RANGE}カ月間の処方最大量/日`}
        >
          <InfoIcon color="slate.500" boxSize={3} />
        </InfoPopover>
      </HStack>
      <Box>
        <StockUnitQuantityWithSymbol
          variant="h1"
          quantity={orderPointForecast?.baseStockUnitQuantity}
          stockUnitSymbol={stockUnitSymbol}
          spacing={0.5}
          dataTestId="在庫数量"
        />
        {!isNullOrUndefined(orderPointForecast) &&
          !isNullOrUndefined(orderPointForecast.convertedUnitQuantities) && (
            <ConvertedStockUnitQuantityWithSymbol
              fontSize="15px"
              convertedStockUnitQuantity={
                orderPointForecast.convertedUnitQuantities.baseStockUnitQuantity
              }
              convertedStockUnitSymbol={orderPointForecast.convertedUnitQuantities.stockUnitSymbol}
            />
          )}
        <Typography
          variant="body2"
          color="slate.500"
          textAlign="end"
          lineHeight={0.5}
          aria-label="基準在庫量の根拠となる日付"
        >
          {getPrescribedDate(orderPointForecast)}
        </Typography>
      </Box>
    </Stack>
  );
}

/**
 * 基準在庫量の根拠となる日付を取得する
 */
function getPrescribedDate(orderPointForecast: MedicineSummaryTabQuery['orderPointForecast']) {
  if (!orderPointForecast) {
    return '-';
  }
  const { mostPrescribedDate, mostPrescribedPeriodEndDate } = orderPointForecast;
  const isGxToaTrial = mostPrescribedPeriodEndDate !== null;
  return isGxToaTrial
    ? `${formatAWSDate(mostPrescribedDate)} - ${formatAWSDate(mostPrescribedPeriodEndDate)}`
    : `${formatAWSDate(mostPrescribedDate)}`;
}
