import { convertToJst, jaLocale } from '@kkhs/hakari-utils';
import { format, parseISO } from 'date-fns';
import { AWSDate } from '../aws';

/**
 * AWSDate を `yyyy/MM/dd`, `yyyy/MM/dd (E)` などのフォーマットに変換する
 * format オプションを指定した場合は優先される
 */
export function formatAWSDate(
  awsDate: AWSDate | undefined | null,
  { showDayOfWeek, format: formatStr }: { showDayOfWeek?: boolean; format?: string } = {
    showDayOfWeek: false,
  },
): string {
  if (!awsDate) return '-';
  if (formatStr) {
    return format(convertToJst(parseISO(awsDate)), formatStr, {
      locale: jaLocale,
    });
  }
  if (showDayOfWeek) {
    return format(convertToJst(parseISO(awsDate)), 'yyyy/MM/dd(E)', {
      locale: jaLocale,
    });
  }
  return awsDate.replaceAll('-', '/');
}
