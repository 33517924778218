import { parseISO } from 'date-fns';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { StockOperationTargetDatePickerForm } from '@/entities/stockOperation';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isDisabled?: boolean;
  control: UseFormReturn<T>['control'];
};

export function StoringStockOperationTargetDatePickerForm<T extends FieldValues>({
  name,
  isDisabled = false,
  control,
}: Props<T>) {
  const operationStartDate = currentPharmacySelectors.useValue()?.operationStartDate;
  const minDate = operationStartDate ? parseISO(operationStartDate) : undefined;

  return (
    <StockOperationTargetDatePickerForm
      name={name}
      isDisabled={isDisabled}
      control={control}
      minDate={minDate}
    />
  );
}
