import { Box, SystemStyleObject, useTabsContext, useTabsStyles } from '@chakra-ui/react';

type Props = {
  label: string;
  index: number;
  onClick: () => void;
};

export function HorizontalTab({ label, index, onClick }: Props) {
  const { tab } = useTabsStyles();
  const tabStyles: SystemStyleObject = {
    outline: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    ...tab,
  };
  const { selectedIndex } = useTabsContext();
  return (
    <Box
      position="relative"
      justifyContent="flex-start"
      color="slate.300"
      pl={8}
      role="tab"
      aria-selected={index === selectedIndex}
      onClick={onClick}
      _focus={{ boxShadow: 'none' }}
      _active={{ background: 'none' }}
      _selected={{
        color: 'primary.600',
        fontWeight: 'bold',
        background: 'primary.100',
        _before: {
          position: 'absolute',
          left: '22px',
          content: '""',
          width: '4px',
          height: '20px',
          background: 'primary.600',
          transitionDelay: '0.2s',
        },
      }}
      __css={tabStyles}
    >
      {label}
    </Box>
  );
}
