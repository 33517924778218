import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { ShippingStockOperationDeleteDialogQuery } from '@/gql/docs';

type Props = {
  feeAmount: Extract<
    ShippingStockOperationDeleteDialogQuery['stockOperation'],
    { __typename: 'ShippingStockOperationByExternalTrade' }
  >['feeAmount'];
};

export function StockOperationShippingFeeAmountLabel({ feeAmount }: Props) {
  return (
    <Stack w="100px" spacing={1}>
      <Typography variant="body" fontWeight="bold">
        手数料
      </Typography>
      <Typography variant="body">{!isNullOrUndefined(feeAmount) ? feeAmount : '-'}円</Typography>
    </Stack>
  );
}
