import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { UpdateStoringStockOperationByOtherInput } from '@/gql/docs';
import { dateToAWSDate } from '@/shared/utils';
import { updateStoringStockOperationByOtherSchema } from '../schema';

export const storingByOtherSchemaToInput = updateStoringStockOperationByOtherSchema.transform(
  ({
    id,
    targetDate,
    counterpartyId,
    counterpartyName,
    feeAmount,
    containerAmount,
    note,
    stockOperationPriceConfig: { shouldShowTax },
    operatedMedicines,
  }): UpdateStoringStockOperationByOtherInput => ({
    id,
    targetDate: dateToAWSDate(targetDate),
    counterpartyId,
    // NOTE: counterpartyId が無い場合に限り、counterpartyName を指定する
    counterpartyName: isNullOrUndefined(counterpartyId) ? counterpartyName : null,
    feeAmount,
    containerAmount,
    taxRate: shouldShowTax ? 'ten' : undefined,
    note,
    operatedMedicines: operatedMedicines.flatMap((item) =>
      item.lots.map((lot) => ({
        medicineId: item.medicineId,
        stockUnitQuantity: lot.stockUnitQuantity,
        costPrice: item.costPrice,
        lotNumber: lot.lotNumber,
        expirationDate: lot.expirationDate ? dateToAWSDate(lot.expirationDate) : undefined,
      })),
    ),
  }),
);
