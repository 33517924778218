import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';

type Props = {
  totalAmount: number | null;
  totalTaxAmount: number | null;
  showTax: boolean | undefined;
};

export function TotalAmount({ totalAmount, totalTaxAmount, showTax }: Props) {
  const amountTypographyProps = {
    as: 'span',
    variant: 'h1',
    ml: 2,
    mr: 0.5,
  } as const;
  const taxAmountTypographyProps = {
    as: 'span',
    variant: 'body',
    ml: 2,
    mr: 0.5,
  } as const;

  return (
    <Stack spacing={0} alignItems="flex-end">
      <Typography variant="body" fontWeight="bold">
        総額
        <Typography {...amountTypographyProps} isNumeric>
          {totalAmount ?? 0}
        </Typography>
        円
      </Typography>
      {showTax && (
        <Typography variant="body2" fontWeight="normal" lineHeight={0.5}>
          税額
          {!isNullOrUndefined(totalTaxAmount) ? (
            <Typography {...taxAmountTypographyProps} isNumeric>
              {totalTaxAmount}
            </Typography>
          ) : (
            <Typography {...taxAmountTypographyProps}>-</Typography>
          )}
          円
        </Typography>
      )}
    </Stack>
  );
}
