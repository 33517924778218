import {
  FormControl,
  FormLabel,
  HStack,
  Text,
  NumberInputField,
  NumberInput as ChakraNumberInput,
  Skeleton,
} from '@chakra-ui/react';
import { ErrorTooltip, Typography } from '@kkhs/hakari-ui';
import { useController, useFormContext } from 'react-hook-form';
import { usePharmacyShouldAutoUpdateCostPriceMaster } from '@/entities/currentPharmacy';
import { currentRoleSelectors } from '../../../../../store/currentRole';

type Props = {
  stockUnitSymbol?: string;
};

export function CostPriceForm({ stockUnitSymbol }: Props) {
  const isManagerRole = currentRoleSelectors.useIsManager();

  const { shouldAutoUpdateCostPriceMaster } = usePharmacyShouldAutoUpdateCostPriceMaster({
    fetchPolicy: 'cache-and-network',
  });

  const { control } = useFormContext<{ costPrice: number }>();
  const {
    field: { onChange, ...inputProps },
    fieldState: { error },
  } = useController<{ costPrice: number }>({
    name: 'costPrice',
    control,
  });
  // NOTE:
  //  - 原価更新設定が自動の場合は、原価フォームを表示しない
  //  - 原価設定ができるのは、manager role の場合のみ
  if (shouldAutoUpdateCostPriceMaster || !isManagerRole) {
    return null;
  }
  return (
    <FormControl>
      <FormLabel htmlFor="costPrice">
        <Typography variant="body2" fontWeight="bold">
          原価
        </Typography>
      </FormLabel>
      <HStack>
        <ErrorTooltip label={error?.message} isOpen={!!error?.message} placement="bottom-start">
          <ChakraNumberInput
            w="120px"
            isInvalid={!!error}
            onChange={(_, v) => {
              onChange(Number.isNaN(v) ? undefined : v);
            }}
            min={0}
            {...inputProps}
          >
            <NumberInputField
              _invalid={{ backgroundColor: 'red.50', border: '2px', borderColor: 'red.500' }}
              color="text.default"
              pr={1}
              textAlign="right"
            />
          </ChakraNumberInput>
        </ErrorTooltip>
        <Skeleton isLoaded={!!stockUnitSymbol}>
          <Text color="slate.500">{`円/${stockUnitSymbol}`}</Text>
        </Skeleton>
      </HStack>
    </FormControl>
  );
}
