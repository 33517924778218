import { createIcon } from '@chakra-ui/react';

export const OintmentSeparatelyIcon = createIcon({
  displayName: 'OintmentSeparatelyIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.758 6.75h9.152l-1.455 21.37v.026a.88.88 0 01-.896.88h-4.39a.969.969 0 01-.958-.872v-.001l-.26-3.842h2.316c.662 0 1.36-.523 1.36-1.354v-10.44c0-.673-.539-1.353-1.36-1.353h-3.21l-.3-4.414zm3.369 16.06h-2.279l-.689-10.146h2.968v10.147zM14.25 6.665c0-.687.495-1.414 1.36-1.414h9.448c.73 0 1.477.615 1.356 1.495l-1.46 21.43a2.375 2.375 0 01-1.827 2.284v2.817c0 .493-.26.889-.61 1.134-.337.237-.757.34-1.177.34H19.33c-.403 0-.812-.109-1.143-.331-.333-.224-.644-.613-.644-1.143v-2.831a2.47 2.47 0 01-1.827-2.184v-.003L14.25 6.689v-.025zm4.792 23.862v2.66a.607.607 0 00.287.064h2.011c.147 0 .24-.029.287-.051v-2.673h-2.585z"
      fill="currentColor"
    />
  ),
});
