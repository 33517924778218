import { createIcon } from '@chakra-ui/react';

export const CanUnitSymbolIcon = createIcon({
  displayName: 'Can',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.42002 7.30011H13.35V8.11011H8.42002V12.2201H11.54V9.33011H12.36V13.0001H3.63002V9.33011H4.45002V12.2201H7.58002V8.11011H2.65002V7.30011H7.58002V4.71011H5.29002C4.87017 5.56698 4.30132 6.34238 3.61002 7.00011L3.54002 7.06011L2.90002 6.52011L3.00002 6.45011C4.14476 5.36162 4.96516 3.97697 5.37002 2.45011V2.36011L6.12002 2.57011V2.67011C5.99002 3.07011 5.89002 3.35011 5.68002 3.88011H12.89V4.68011H8.42002V7.30011Z"
        fill="currentColor"
      />
    </svg>
  ),
});
