import {
  HStack,
  Stack,
  chakra,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { Typography, PlusSquareIcon, MinusSquareIcon } from '@kkhs/hakari-ui';
import { useFormContext, useFieldArray, UseFieldArrayRemove } from 'react-hook-form';
import { showTaxByItem } from '@/entities/stockOperationPriceConfig';
import { CostPriceForm } from './CostPriceForm';
import { CostPricePopover } from './CostPricePopover';
import { ExpirationDatePicker } from './ExpirationDatePicker';
import { LotStockSelect } from './LotStockSelect';
import { StockUnitQuantityForm } from './StockUnitQuantityForm';
import { SubTotalAmount } from './SubTotalAmount';
import { TotalStockUnitQuantity } from './TotalStockUnitQuantity';
import { currentRoleSelectors } from '../../../../store/currentRole';
import { SubTotalTaxAmount } from '../SubTotalTaxAmount';
import { UpdateStoringStockOperationFieldValues } from '../schema';

const StyledTh = chakra(Th, {
  baseStyle: () => ({
    p: 1,
    pl: 0,
  }),
});

const StyledTd = chakra(Td, {
  baseStyle: () => ({
    p: 0,
    pr: 2,
    pb: 1,
  }),
});

type Props = {
  currentOperatedMedicine: UpdateStoringStockOperationFieldValues['operatedMedicines'][number];
  rowIndex: number;
  currentPriceConfig: UpdateStoringStockOperationFieldValues['stockOperationPriceConfig'];
  isNotAddable: boolean;
  removeOperatedMedicine: UseFieldArrayRemove;
};

export function StoringMedicineRow({
  currentOperatedMedicine,
  rowIndex,
  currentPriceConfig,
  isNotAddable,
  removeOperatedMedicine,
}: Props) {
  const { control } = useFormContext<UpdateStoringStockOperationFieldValues>();
  const {
    fields,
    append: appendLot,
    remove: removeLot,
  } = useFieldArray({
    control,
    name: `operatedMedicines.${rowIndex}.lots`,
  });
  const isManager = currentRoleSelectors.useIsManager();
  const { medicineId, medicineName, shortUnitOutline, stockUnitSymbol, stockUnitPrice } =
    currentOperatedMedicine;

  // NOTE: 金額設定をもとに明細単位の税額を表示するか算出する。（法人間の場合は税額は表示しない。）
  const showTax = showTaxByItem(currentPriceConfig) && currentPriceConfig.type === 'external';

  return (
    <Stack px={6} py={3} borderTopWidth={1} spacing={0.5}>
      <Stack spacing={3}>
        <HStack justifyContent="space-between" alignItems="baseline">
          <Stack spacing={0.5}>
            <Typography variant="body" fontWeight="bold">
              {medicineName}
            </Typography>
            <Typography variant="body2">{shortUnitOutline}</Typography>
          </Stack>
          <TotalStockUnitQuantity stockUnitSymbol={stockUnitSymbol} rowIndex={rowIndex} />
        </HStack>
        <Table size="sm" variant="unstyled">
          <Thead>
            <Tr>
              <StyledTh w="128px">
                <Typography variant="body2" fontWeight="bold">
                  単位薬価
                </Typography>
              </StyledTh>
              <StyledTh w="128px">
                <HStack spacing={1}>
                  <Typography variant="body2" fontWeight="bold">
                    入庫原価
                  </Typography>
                  <CostPricePopover isManager={isManager} />
                </HStack>
              </StyledTh>
              <StyledTh w="136px">
                <Typography variant="body2" fontWeight="bold">
                  入庫金額
                </Typography>
              </StyledTh>
              {showTax && (
                <StyledTh w="136px">
                  <Typography variant="body2" fontWeight="bold">
                    税額
                  </Typography>
                </StyledTh>
              )}
              <StyledTh w="184px" pl={4}>
                <Typography variant="body2" fontWeight="bold">
                  ロット番号
                </Typography>
              </StyledTh>
              <StyledTh w="128px">
                <Typography variant="body2" fontWeight="bold">
                  有効期限
                </Typography>
              </StyledTh>
              <StyledTh w="188px" pl={4}>
                <Typography variant="body2" fontWeight="bold">
                  入庫数量
                </Typography>
              </StyledTh>
              <StyledTh pr={0} />
            </Tr>
          </Thead>
          <Tbody>
            {fields.map((field, lotIndex) => {
              const currentCostPrice = currentOperatedMedicine.costPrice ?? null;
              const currentStockUnitQuantity =
                currentOperatedMedicine.lots[lotIndex]?.stockUnitQuantity ?? null;

              return (
                <Tr key={field.id}>
                  <StyledTd>
                    {lotIndex === 0 && (
                      <HStack spacing={0.5}>
                        <Typography variant="body" isNumeric>
                          {stockUnitPrice}
                        </Typography>
                        <Typography variant="body2">円</Typography>
                      </HStack>
                    )}
                  </StyledTd>
                  <StyledTd verticalAlign="top">
                    {lotIndex === 0 && (
                      <CostPriceForm<UpdateStoringStockOperationFieldValues>
                        name={`operatedMedicines.${rowIndex}.costPrice`}
                        isManager={isManager}
                      />
                    )}
                  </StyledTd>
                  <StyledTd>
                    <SubTotalAmount
                      unitPrice={currentCostPrice}
                      stockUnitQuantity={currentStockUnitQuantity}
                      stockOperationPriceConfig={currentPriceConfig}
                      isManager={isManager}
                    />
                  </StyledTd>
                  {showTax && (
                    <StyledTd>
                      <SubTotalTaxAmount
                        unitPrice={currentCostPrice}
                        stockUnitQuantity={currentStockUnitQuantity}
                        stockOperationPriceConfig={currentPriceConfig}
                        isManager={isManager}
                      />
                    </StyledTd>
                  )}
                  <StyledTd pl={4}>
                    <LotStockSelect
                      medicineId={medicineId}
                      rowIndex={rowIndex}
                      lotIndex={lotIndex}
                      defaultLotNumber={field.defaultLotNumber}
                    />
                  </StyledTd>
                  <StyledTd>
                    <ExpirationDatePicker<UpdateStoringStockOperationFieldValues>
                      name={`operatedMedicines.${rowIndex}.lots.${lotIndex}.expirationDate`}
                    />
                  </StyledTd>
                  <StyledTd pl={4}>
                    <StockUnitQuantityForm<UpdateStoringStockOperationFieldValues>
                      name={`operatedMedicines.${rowIndex}.lots.${lotIndex}.stockUnitQuantity`}
                    />
                  </StyledTd>
                  <StyledTd textAlign="left" verticalAlign="middle" pr={1}>
                    <IconButton
                      aria-label="入力欄を一つ削除"
                      icon={<MinusSquareIcon color="slate.200" boxSize={4} />}
                      size="sm"
                      variant="unstyled"
                      onClick={() => removeLot(lotIndex)}
                    />
                  </StyledTd>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Stack>
      <HStack justifyContent="space-between">
        <HStack spacing={4}>
          <Button
            leftIcon={<PlusSquareIcon color="primary.300" boxSize={4} />}
            variant="link"
            size="sm"
            letterSpacing={1}
            onClick={() =>
              appendLot({ stockUnitQuantity: 0, lotNumber: null, expirationDate: null })
            }
            isDisabled={isNotAddable}
          >
            <Typography variant="body2" color="slate.500" fontWeight="normal">
              入力欄を追加
            </Typography>
          </Button>
          {isNotAddable && (
            <Typography variant="body2" color="slate.500">
              同時に処理できるロット数は最大5つです
            </Typography>
          )}
        </HStack>
        <Button variant="ghost" size="sm" onClick={() => removeOperatedMedicine(rowIndex)}>
          削除
        </Button>
      </HStack>
    </Stack>
  );
}
