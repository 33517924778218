import { createIcon } from '@chakra-ui/react';

export const SubtractPreMadeMedicineIcon = createIcon({
  displayName: 'SubtractPreMadeMedicineIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4V6V12.9996C19.2144 13.9118 20 15.3642 20 17C20 19.7614 17.7614 22 15 22C13.3642 22 11.9118 21.2144 10.9996 20H6V18H10.1C10.0344 17.6769 10 17.3425 10 17C10 14.2386 12.2386 12 15 12C15.3425 12 15.6769 12.0344 16 12.1V6L6 6V18L6 20H4V6V4H18ZM14 10V8L8 8V10V14H10V10H14ZM12 16H18V18H12V16Z"
      fill="currentColor"
    />
  ),
});
