import { useMutation } from '@apollo/client';
import { showErrorToast } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { graphql } from '@/gql';
import { dateToAWSDate } from '@/shared/utils';

const deleteMutationDoc = graphql(`
  mutation DeleteOrderRecommendSuspendTerm($input: DeleteOrderRecommendSuspendTermInput!) {
    deleteOrderRecommendSuspendTerm(input: $input) {
      errors {
        message
      }
    }
  }
`);

const updateMutationDoc = graphql(`
  mutation UpdateOrderRecommendSuspendTerm($input: UpdateOrderRecommendSuspendTermInput!) {
    updateOrderRecommendSuspendTerm(input: $input) {
      errors {
        message
      }
    }
  }
`);

type Props = {
  /** 対象の medicineId */
  medicineId: string;
};

export function useUpdateOrderRecommendSuspendTerm({ medicineId }: Props) {
  const [deleteOrderRecommendSuspendTerm, { loading: isOrderRecommendSuspendTermDeleting }] =
    useMutation(deleteMutationDoc, {
      onCompleted: (data) => {
        if ((data.deleteOrderRecommendSuspendTerm?.errors ?? []).length !== 0) {
          showErrorToast({
            id: 'delete-order-recommend-suspend-term-error',
            title: 'エラーが発生しました。',
          });
        }
      },
    });
  const [updateOrderRecommendSuspendTerm, { loading: isOrderRecommendSuspendTermUpdating }] =
    useMutation(updateMutationDoc, {
      onCompleted: (data) => {
        if ((data.updateOrderRecommendSuspendTerm?.errors ?? []).length !== 0) {
          showErrorToast({
            id: 'update-order-recommend-suspend-term-error',
            title: 'エラーが発生しました。',
          });
        }
      },
    });

  const execMutation = useCallback(
    async (orderRecommendSuspendTerm?: {
      startDate: Date;
      isInf: boolean;
      endDate?: Date | undefined;
    }) => {
      if (!orderRecommendSuspendTerm) {
        await deleteOrderRecommendSuspendTerm({
          variables: { input: { medicineId } },
        });
      } else {
        await updateOrderRecommendSuspendTerm({
          variables: {
            input: {
              medicineId,
              startDate: dateToAWSDate(orderRecommendSuspendTerm.startDate),
              endDate:
                !orderRecommendSuspendTerm.isInf && orderRecommendSuspendTerm.endDate
                  ? dateToAWSDate(orderRecommendSuspendTerm.endDate)
                  : undefined,
            },
          },
        });
      }
    },
    [deleteOrderRecommendSuspendTerm, medicineId, updateOrderRecommendSuspendTerm],
  );

  return {
    updateOrderRecommendSuspendTerm: execMutation,
    isSubmitting: isOrderRecommendSuspendTermDeleting || isOrderRecommendSuspendTermUpdating,
  };
}
