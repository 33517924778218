import { Container } from 'inversify';
import '@abraham/reflection';
import { IFeatureFlagRepository } from '@/repository/featureFlag/interface';
import { LaunchdarklyRepository } from '@/repository/featureFlag/launchdarkly';
import { MockFeatureFlagRepository } from '@/repository/featureFlag/mock';
import { DatadogRepository } from '@/repository/userMonitoring/datadog';
import { IUserMonitoring } from '@/repository/userMonitoring/interface';

const C = new Container();

const Keys = {
  USER_MONITORING: Symbol.for('USER_MONITORING'),
  FEATURE_FLAG: Symbol.for('FEATURE_FLAG'),
};
const K = Keys;

// コンテナに実装をバインドする
C.bind<IUserMonitoring>(K.USER_MONITORING).toConstantValue(DatadogRepository);

if (process.env.FEATURE_FLAG !== 'MOCK') {
  C.bind<IFeatureFlagRepository>(K.FEATURE_FLAG).toConstantValue(LaunchdarklyRepository);
} else {
  C.bind<IFeatureFlagRepository>(K.FEATURE_FLAG).toConstantValue(MockFeatureFlagRepository);
}

// バインドした実装をgetterでexportする
export const repositoryContainerGetter = {
  userMonitoring: () => C.get<IUserMonitoring>(K.USER_MONITORING),
  featureFlag: () => C.get<IFeatureFlagRepository>(K.FEATURE_FLAG),
};

// 実装をリバインドする
export const repositoryContainerSetter = {
  userMonitoring: (repository: IUserMonitoring) =>
    C.rebind<IUserMonitoring>(K.USER_MONITORING).toConstantValue(repository),
  featureFlag: (repository: IFeatureFlagRepository) =>
    C.rebind<IFeatureFlagRepository>(K.FEATURE_FLAG).toConstantValue(repository),
};
