import { Auth } from '@aws-amplify/auth';
import { I18n } from '@aws-amplify/core';
import { Button, VStack, FormControl, Link, Stack, Img } from '@chakra-ui/react';
import { ErrorTooltip, TextInput, Typography, useZodForm, showErrorToast } from '@kkhs/hakari-ui';
import { isMusubiProd } from '@/shared/utils';
import { defaultValues, FieldValues, schema } from './schema';
import {
  reconfigureAmplify,
  setUserPoolTypeToV1,
  setUserPoolTypeToV2,
} from '../../../../lib/amplify';

I18n.putVocabularies({
  ja: {
    'Incorrect username or password.': 'ユーザーIDまたはパスワードが正しくありません。',
  },
});
I18n.setLanguage('ja');

const resetPasswordUrl = isMusubiProd
  ? 'https://console.musubi.app/forgotPassword'
  : 'https://stg.console.musubi.app/forgotPassword';

const loginErrorToastId = 'login-error-toast-id';

type Props = {
  notification?: React.ReactNode;
};

export function Login({ notification }: Props) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useZodForm({
    mode: 'onBlur',
    defaultValues,
    schema,
  });

  const signIn = async ({ username, password }: FieldValues) => {
    try {
      // NOTE: v2 から試行する必要がある
      reconfigureAmplify('v2');
      await Auth.signIn(username, password)
        .then(() => setUserPoolTypeToV2())
        .catch(async () => {
          reconfigureAmplify('v1');
          await Auth.signIn(username, password);
          setUserPoolTypeToV1();
        });
    } catch (err) {
      if (err instanceof Error) {
        const message = I18n.get(err.message);
        showErrorToast({
          id: loginErrorToastId,
          title: message,
        });
      }
    }
  };

  return (
    <Stack bg="base.default" minH="100vh" alignItems="center" justifyContent="center">
      <VStack
        bg="white"
        w="380px"
        p={10}
        alignItems="stretch"
        spacing={6}
        borderRadius="md"
        overflow="hidden"
      >
        <VStack alignItems="center" spacing={10}>
          <Img src="/assets/svg/hakari-logo.svg" alt="logo" h="49.5px" w="156px" />
          {notification}
        </VStack>
        <form onSubmit={handleSubmit(signIn)}>
          <VStack spacing={6} alignItems="stretch">
            <Stack spacing={4}>
              <FormControl id="username">
                <ErrorTooltip
                  label={errors.username?.message}
                  isOpen={!!errors.username?.message}
                  placement="top-end"
                >
                  <TextInput
                    placeholder="ユーザーID"
                    isInvalid={!!errors.username}
                    {...register('username')}
                  />
                </ErrorTooltip>
              </FormControl>
              <FormControl id="password">
                <ErrorTooltip
                  label={errors.password?.message}
                  isOpen={!!errors.password?.message}
                  placement="top-end"
                >
                  <TextInput
                    type="password"
                    placeholder="パスワード"
                    isInvalid={!!errors.password}
                    {...register('password')}
                  />
                </ErrorTooltip>
              </FormControl>
            </Stack>
            <Typography variant="body" textAlign="center" color="slate.500">
              <Link style={{ textDecoration: 'underline' }} href={resetPasswordUrl} isExternal>
                パスワードをお忘れですか？
              </Link>
            </Typography>
            <Button type="submit" width="full" isLoading={isSubmitting} isDisabled={isSubmitting}>
              ログイン
            </Button>
          </VStack>
        </form>
      </VStack>
    </Stack>
  );
}
