import { Box, HStack } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { CheckIcon } from '../../icons';
import { SelectOptionType } from '../types';

type Props<T> = {
  renderOptionNotice?: (data: SelectOptionType<T>) => ReactNode;
} & PropsWithChildren<OptionProps<SelectOptionType<T>, false, GroupBase<SelectOptionType<T>>>>;

export function IconOption<T>(props: Props<T>) {
  const { data, isSelected, getStyles, renderOptionNotice } = props;
  const { fontSize } = getStyles('option', props);
  return (
    <components.Option {...props}>
      <HStack role="option">
        <CheckIcon
          color="primary.500"
          opacity={isSelected ? 1 : 0}
          boxSize={`calc(${fontSize} * 1.6)`}
        />
        <Box flex="1">
          {data.label}
          {renderOptionNotice && renderOptionNotice(data)}
        </Box>
      </HStack>
    </components.Option>
  );
}
