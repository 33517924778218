import { HStack, Tr, Td, chakra } from '@chakra-ui/react';
import { Link, NumberLikeText } from '@kkhs/hakari-ui';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { StockUnitSymbolIcon } from '@/entities/medicine';
import {
  isEditableMedicineTransaction,
  getTransactionReasonLabel,
} from '@/entities/stockOperation';
import { getTransactionSubreason } from '@/entities/stockOperation/functions/getTransactionSubreason';
import { MedicineTransactionListItemsQuery } from '@/gql/docs';
import { formatAWSDate, formatAWSDateTime, isJstTodayAWSDateTime } from '@/shared/utils';
import { ShippingStockOperationDeleteButton } from './ShippingStockOperationDeleteButton';

const StyledTd = chakra(Td, {
  baseStyle: () => ({
    px: 1.5,
    py: 2,
    verticalAlign: 'top',
    lineHeight: '14px',
  }),
});

type Props = {
  stockUnitSymbol: string;
  node: MedicineTransactionListItemsQuery['medicineTransactionListItems']['edges'][number]['node'] & {
    transactionType: 'consumption';
  };
};

function renderTransactionTarget(transactionTarget: string | null, patientId: string | null) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const targetText = transactionTarget || '-';
  if (!patientId) {
    return targetText;
  }
  return (
    <Link
      href={`/patient/detail/?patientId=${patientId}`}
      textDecoration="underline"
      target="_blank"
      onClick={() => {
        userMonitoringRepository.sendEvent({
          key: '医薬品詳細モーダル_入出庫履歴タブ_患者名リンク_CLICKED',
          contexts: { pharmacyId, musubiCode },
        });
      }}
    >
      {targetText}
    </Link>
  );
}

export function MedicineTransactionConsumptionRow({ stockUnitSymbol, node }: Props) {
  const stockUnitQuantityAfterTransactionText =
    node.stockUnitQuantityAfterTransaction >= 0
      ? `${node.stockUnitQuantityAfterTransaction}`
      : `△${Math.abs(node.stockUnitQuantityAfterTransaction)}`;
  return (
    <Tr key={node.id}>
      <StyledTd pl={0} data-testid="出庫日">
        {formatAWSDate(node.transaction.date, { showDayOfWeek: true })}
      </StyledTd>
      <StyledTd>出庫</StyledTd>
      <StyledTd whiteSpace="pre-wrap" data-testid="区分">
        {getTransactionReasonLabel({
          type: node.transactionType,
          reason: node.transaction.reason,
          subreason: getTransactionSubreason(node.transaction.reason, {
            disposalReason: node.transaction.disposalReason,
          }),
        })}
      </StyledTd>
      <StyledTd data-testid="入出庫先">
        {renderTransactionTarget(node.transactionTarget, node.patientId)}
      </StyledTd>
      <StyledTd isNumeric>
        <HStack spacing={0} justifyContent="flex-end">
          <NumberLikeText data-testid="入出庫数量" isDigitSeparated>{`△${Math.abs(
            node.transaction.unitQuantity,
          )}`}</NumberLikeText>
          <StockUnitSymbolIcon name={stockUnitSymbol} boxSize={4} />
        </HStack>
      </StyledTd>
      <StyledTd isNumeric>
        <HStack spacing={0} justifyContent="flex-end">
          <NumberLikeText data-testid="入出庫後の在庫数量" isDigitSeparated>
            {stockUnitQuantityAfterTransactionText}
          </NumberLikeText>
          <StockUnitSymbolIcon name={stockUnitSymbol} color="slate.500" boxSize={4} />
        </HStack>
      </StyledTd>
      <StyledTd data-testid="ロット番号">{node.transaction.lotNumber || '-'}</StyledTd>
      <StyledTd data-testid="メモ">{node.transaction.memo || '-'}</StyledTd>
      <StyledTd>
        <NumberLikeText whiteSpace="nowrap">
          {formatAWSDateTime(node.transaction.updatedAt, {
            format: isJstTodayAWSDateTime(node.transaction.updatedAt) ? 'HH:mm' : 'yyyy/MM/dd',
          })}
        </NumberLikeText>
      </StyledTd>
      <StyledTd pr={0}>
        {isEditableMedicineTransaction(node) && (
          <ShippingStockOperationDeleteButton stockOperationId={node.stockOperationId} />
        )}
      </StyledTd>
    </Tr>
  );
}
