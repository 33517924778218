import { MedicineAttentionsFragment, StockMedicineAttentionsFragment } from '@/gql/apollo';
import { AttentionIconName } from '../AttentionIcon';

// 順番は https://kakehashi.slack.com/archives/C01HK1FPRTR/p1664950050619599?thread_ts=1664791925.291639&cid=C01HK1FPRTR
// TODO: API側でスキーマ追加後に順次追加していく
export const toAttentionIconNames = (
  fragment: MedicineAttentionsFragment | StockMedicineAttentionsFragment,
): AttentionIconName[] => {
  const attentions: AttentionIconName[] = [];
  if (fragment.isNarcotic) attentions.push('narcotic');
  if (fragment.isStimulant) attentions.push('stimulant');
  if (fragment.isPoison) attentions.push('poison');
  if (fragment.isColdPlace || fragment.isRefrigeration || fragment.isFrozen)
    attentions.push('cold');

  if (fragment.attentions.includes('infrequent')) attentions.push('infrequent');
  if (fragment.attentions.includes('expensive')) attentions.push('expensive');
  if (fragment.attentions.includes('recentRegistered')) attentions.push('recentRegistered');
  if (fragment.attentions.includes('new')) attentions.push('new');
  if (fragment.attentions.includes('transitionalMeasure')) attentions.push('transitionalMeasure');
  if (fragment.attentions.includes('transitionalMeasureExpired'))
    attentions.push('transitionalMeasureExpired');
  if (fragment.attentions.includes('subdivided')) attentions.push('subdivided');
  if (fragment.attentions.includes('stockoutPreventionTarget'))
    attentions.push('stockoutPreventionTarget');
  return attentions;
};
