import { Box } from '@chakra-ui/react';
import { css } from '@emotion/css';

const loadingBarAnimation = css`
  background: linear-gradient(-90deg, #23d5ab, #ee7752, #e73c7e, #23a6d5, #000);
  background-size: 100% 100%;
  animation: gradient 1.5s cubic-bezier(0.86, 0, 0.18, 1) infinite;

  @keyframes gradient {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export function LoadingBar() {
  return (
    <Box w="140px" h="4px" position="relative" bg="gray.200" borderRadius="full" overflow="hidden">
      <Box
        w="100%"
        h="100%"
        position="absolute"
        borderRadius="full"
        left="0"
        className={loadingBarAnimation}
      />
    </Box>
  );
}
