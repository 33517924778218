import { createIcon } from '@chakra-ui/react';

export const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M11.1566 5.68701H5.59035C5.16856 5.68701 4.76405 5.85457 4.4658 6.15281C4.16755 6.45106 4 6.85558 4 7.27736V18.4098C4 18.8316 4.16755 19.2361 4.4658 19.5344C4.76405 19.8326 5.16856 20.0002 5.59035 20.0002H16.7228C17.1446 20.0002 17.5491 19.8326 17.8474 19.5344C18.1456 19.2361 18.3132 18.8316 18.3132 18.4098V12.8436"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M16.6625 4.49406C16.9789 4.17772 17.4079 4 17.8553 4C18.3027 4 18.7317 4.17772 19.048 4.49406C19.3644 4.8104 19.5421 5.23945 19.5421 5.68682C19.5421 6.1342 19.3644 6.56325 19.048 6.87959L11.4939 14.4338L8.31317 15.2289L9.10835 12.0482L16.6625 4.49406Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </>
  ),
});
