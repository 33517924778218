import { Center } from '@chakra-ui/react';
import { LoadingBar, showSuccessToast } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { useAuth, Login } from '@/features/auth';

type Props = {
  children: JSX.Element;
};

export function AuthRoot({ children }: Props) {
  const onSignOut = useCallback(() => {
    showSuccessToast({
      id: 'sign-out',
      title: 'ログアウトしました',
    });
  }, []);
  const { authStatus } = useAuth({
    onSignOut,
  });

  if (!authStatus) {
    return (
      <Center w="100%" h="100vh">
        <LoadingBar />
      </Center>
    );
  }
  if (authStatus !== 'signedIn') {
    return <Login />;
  }

  return children;
}
