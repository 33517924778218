import { Skeleton, Stack } from '@chakra-ui/react';
import { Select, SelectProps, Typography } from '@kkhs/hakari-ui';
import { MedicinePackageUnitFragment } from '@/gql/apollo';

type Props = {
  isLoading: boolean;
} & SelectProps<MedicinePackageUnitFragment>;

export function MedicinePackageUnitSelect({ isLoading, ...rest }: Props) {
  return (
    <Skeleton isLoaded={!isLoading}>
      {rest.options?.length === 1 ? (
        <Stack minH="40px">
          <Typography variant="body">{rest.options[0]?.label}</Typography>
        </Stack>
      ) : (
        <Select<MedicinePackageUnitFragment> {...rest} />
      )}
    </Skeleton>
  );
}
