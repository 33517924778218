import {
  Box,
  AlertDialogContent as ChakraAlertDialogContent,
  ModalContentProps,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { AlertDialogContext } from './AlertDialogContext';

type Props = {
  /** 破棄確認ダイアログを出す場合 true にする */
  shouldAlert: boolean;
  /** AlertDialog の一部ではなく、プレーンな div として使う場合 true にする */
  isNotAlertDialogRole?: boolean;
} & ModalContentProps;

export function AlertDialogContent({ shouldAlert, isNotAlertDialogRole = false, ...rest }: Props) {
  const { setShouldAlert } = useContext(AlertDialogContext);

  useEffect(() => {
    setShouldAlert?.(shouldAlert);
  }, [shouldAlert, setShouldAlert]);

  return isNotAlertDialogRole ? <Box {...rest} /> : <ChakraAlertDialogContent {...rest} />;
}
