import { Flex } from '@chakra-ui/react';
import { NumberLikeText, NumberText } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { ConvertedStockUnitQuantityWithSymbol, StockUnitSymbolIcon } from '@/entities/medicine';
import { formatAWSDate } from '@/shared/utils';
import { CellBase } from './CellBase';
import { Row } from '../../types';

export function LastPrescriptionDateCell({
  medicine: { stockUnitSymbol },
  lastPrescriptionDate,
  lastPrescriptionUnitQuantity,
  convertedOthersMedicineStockUnitQuantities,
}: Row) {
  return (
    <CellBase isNumeric data-testid="最終処方ブロック">
      <NumberLikeText>{formatAWSDate(lastPrescriptionDate)}</NumberLikeText>
      {lastPrescriptionUnitQuantity !== null && (
        <Flex justifyContent="flex-end">
          <NumberText inline>{lastPrescriptionUnitQuantity}</NumberText>
          <StockUnitSymbolIcon name={stockUnitSymbol} boxSize={4} />
        </Flex>
      )}
      {!isNullOrUndefined(convertedOthersMedicineStockUnitQuantities) &&
        !isNullOrUndefined(
          convertedOthersMedicineStockUnitQuantities.lastPrescriptionUnitQuantity,
        ) && (
          <ConvertedStockUnitQuantityWithSymbol
            fontSize="12px"
            paddingBottom={1}
            convertedStockUnitQuantity={
              convertedOthersMedicineStockUnitQuantities.lastPrescriptionUnitQuantity
            }
            convertedStockUnitSymbol={convertedOthersMedicineStockUnitQuantities.stockUnitSymbol}
          />
        )}
    </CellBase>
  );
}
