import { useApolloClient } from '@apollo/client';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useDisclosureWithTarget } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { AddAdoptedMedicineButton } from '@/entities/adoptedMedicalProduct';
import { GroupPharmacyStocksByMedicineDocument, OwnPharmacyMedicinesDocument } from '@/gql/apollo';
import { CellBase } from './CellBase';
import {
  RenderAdoptedMedicalMaterialCreateDialog,
  RenderAdoptedMedicineCreateDialog,
  RenderOwnTakeTransferRequestDialog,
  Row,
} from '../../types';

type Props = Row & {
  renderOwnTakeTransferRequestDialog: RenderOwnTakeTransferRequestDialog;
  renderAdoptedMedicineCreateDialog: RenderAdoptedMedicineCreateDialog;
  renderAdoptedMedicalMaterialCreateDialog: RenderAdoptedMedicalMaterialCreateDialog;
};

export function ActionCell({
  renderOwnTakeTransferRequestDialog,
  renderAdoptedMedicineCreateDialog,
  renderAdoptedMedicalMaterialCreateDialog,
  ...row
}: Props) {
  const apolloClient = useApolloClient();
  const ownTakeTransferStockDialogDisclosure = useDisclosureWithTarget<Row>({
    id: 'own-take-transfer-request-dialog',
  });
  const handleSuccess = useCallback(
    async () =>
      apolloClient.refetchQueries({
        include: [GroupPharmacyStocksByMedicineDocument, OwnPharmacyMedicinesDocument],
      }),
    [apolloClient],
  );
  const adoptedMedicineAddDialogDisclosure = useDisclosure({
    id: 'adopted-medicine-add-dialog',
  });
  const adoptedMedicalMaterialAddDialogDisclosure = useDisclosure({
    id: 'adopted-medical-material-add-dialog',
  });

  const handleClickOwnTakeRequest = useCallback(
    () => ownTakeTransferStockDialogDisclosure.onOpen(row),
    [ownTakeTransferStockDialogDisclosure, row],
  );

  return (
    <CellBase>
      {row.ownPharmacyMedicine?.isAdopted ? (
        <Button
          size="xs"
          variant="outline"
          fontSize="11px"
          mt={1}
          onClick={handleClickOwnTakeRequest}
        >
          小分依頼
        </Button>
      ) : (
        <AddAdoptedMedicineButton
          variant="normal"
          onClick={() => {
            if (row.ownPharmacyMedicine?.isMedicalMaterial) {
              adoptedMedicalMaterialAddDialogDisclosure.onOpen();
            } else {
              adoptedMedicineAddDialogDisclosure.onOpen();
            }
          }}
        />
      )}
      {/* TODO: 依頼先店舗に、モーダルで該当する店舗名をプリセットにする */}
      {renderOwnTakeTransferRequestDialog({
        isOpen: ownTakeTransferStockDialogDisclosure.isOpen,
        onClose: ownTakeTransferStockDialogDisclosure.onClose,
        onSuccess: handleSuccess,
        row,
      })}
      {renderAdoptedMedicineCreateDialog({
        isOpen: adoptedMedicineAddDialogDisclosure.isOpen,
        onClose: adoptedMedicineAddDialogDisclosure.onClose,
        onSuccess: handleSuccess,
        row,
      })}
      {renderAdoptedMedicalMaterialCreateDialog({
        isOpen: adoptedMedicalMaterialAddDialogDisclosure.isOpen,
        onClose: adoptedMedicalMaterialAddDialogDisclosure.onClose,
        onSuccess: handleSuccess,
        row,
      })}
    </CellBase>
  );
}
