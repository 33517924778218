import { FormControl, FormLabel } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { useMemo } from 'react';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { OrderableWholesaleSelect } from '../OrderableWholesaleSelect';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  noOptionsMessage: string;
  isDisabled?: boolean;
  defaultOrderableWholesaleName?: string | null;
  errorMessage?: string;
} & Pick<UseFormReturn<T>, 'control'>;

export function OrderableWholesaleSelectForm<T extends FieldValues>({
  name,
  label,
  noOptionsMessage,
  isDisabled = false,
  defaultOrderableWholesaleName = null,
  control,
  errorMessage,
}: Props<T>) {
  const defaultValue = useMemo(
    () =>
      defaultOrderableWholesaleName
        ? { value: defaultOrderableWholesaleName, label: defaultOrderableWholesaleName }
        : null,
    [defaultOrderableWholesaleName],
  );

  return (
    <FormControl w="240px">
      <FormLabel fontWeight="bold">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <OrderableWholesaleSelect
            size="sm"
            placeholder="-"
            medicineId=""
            noOptionsMessage={noOptionsMessage}
            defaultValue={defaultValue}
            onChange={(value) => {
              onChange(value);
            }}
            onClear={() => {
              onChange(null);
            }}
            onCreate={(newValue) => {
              onChange(newValue);
            }}
            isDisabled={isDisabled}
          />
        )}
      />
      {errorMessage && (
        <Typography variant="body2" color="red.500">
          {errorMessage}
        </Typography>
      )}
    </FormControl>
  );
}
