import { Switch as ChakraUiSwitch, SwitchProps } from '@chakra-ui/react';

type Props = SwitchProps;

const checkedStyle = {
  '.chakra-switch__track': {
    width: '32px',
    height: '16px',
  },
  '.chakra-switch__thumb': {
    width: '14px',
    height: '14px',
    position: 'relative',
    top: '1px',
    left: '3px',
  },
};

const unCheckedStyle = {
  '.chakra-switch__track': {
    height: '14px',
    background: 'rgba(0,0,0,0)',
    border: 'solid 1px',
    borderColor: 'gray.300',
    userSelect: 'none',
  },
  '.chakra-switch__thumb': {
    background: 'gray.300',
    width: '14px',
    height: '14px',
  },
};

export function Switch({ isChecked, ...rest }: Props) {
  return (
    <ChakraUiSwitch
      isChecked={isChecked}
      colorScheme="primary"
      sx={isChecked ? checkedStyle : unCheckedStyle}
      {...rest}
    />
  );
}
