import { Skeleton, useTheme } from '@chakra-ui/react';
import { SelectV2, SelectV2Props } from '@kkhs/hakari-ui';
import { forwardRef, useMemo } from 'react';
import { useMedicineLotStockSelectQuery } from '@/gql/apollo';
import { AWSDate } from '@/shared/utils';

type MedicineLotStockSelectOption = {
  lotNumber: string;
  oldestExpirationDate: AWSDate | null;
};

type Props = {
  /** 医薬品ID */
  medicineId: string;
  /** Ref */
  ref?: React.Ref<HTMLSelectElement>;
} & Omit<SelectV2Props<MedicineLotStockSelectOption>, 'options'>;

export const MedicineLotStockSelect = forwardRef<
  React.ElementRef<typeof SelectV2>,
  Omit<Props, 'ref'>
>(({ medicineId, ...rest }: Omit<Props, 'ref'>, ref) => {
  const { data, loading } = useMedicineLotStockSelectQuery({
    fetchPolicy: 'cache-and-network',
    variables: { medicineId },
  });
  const options = useMemo(
    () =>
      data?.medicineLotStocks.map((l) => ({
        value: {
          lotNumber: l.lotNumber,
          oldestExpirationDate: l.oldestExpirationDate,
        },
        label: l.lotNumber,
      })) ?? [],
    [data],
  );
  // Chakra UI のテーマで最も高い z-index を使用する
  const {
    zIndices: { tooltip },
  } = useTheme();
  return (
    <Skeleton isLoaded={!loading}>
      <SelectV2<MedicineLotStockSelectOption>
        {...rest}
        ref={ref}
        options={options}
        noOptionsMessage="ロット番号を入力してください"
        zIndex={tooltip}
      />
    </Skeleton>
  );
});
MedicineLotStockSelect.displayName = 'MedicineLotStockSelect';
