import { Img } from '@chakra-ui/react';
import { Link, LinkProps } from '../Link';

type Props = Pick<LinkProps, 'href' | 'w' | 'h'> & {
  /** 画像の src */
  src: string;
  /** 画像の alt */
  alt: string;
  /** クリック時のイベント */
  onClick?: () => void;
};

/** リンク付き画像コンポーネント */
export function ImgWithLink({ href, w, h, src, alt, onClick }: Props) {
  return (
    <Link href={href} _focus={{ boxShadow: 'none' }}>
      <Img src={src} alt={alt} w={w} h={h} onClick={onClick} />
    </Link>
  );
}
