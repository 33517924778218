import { ApolloError, useApolloClient } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { HubCapsule, Hub } from '@aws-amplify/core';
import { captureException, configureScope, setUser as setSentryUser } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useUserDataLazyQuery } from '@/gql/apollo';
import { removeLocalStorageItems } from '@/shared/lib/recoil';
import { authActions, authSelectors } from '../../../../store/auth';
import { AuthState } from '../../../../store/auth/types';
import { useLogout } from '../useLogout';

type Props = {
  onSignOut?: () => void;
};

export function useAuth({ onSignOut }: Props = { onSignOut: undefined }): {
  authStatus: AuthState['status'];
} {
  const updateStatus = authActions.useUpdateStatus();
  const setPharmacyStaff = authActions.useSetPharmacyStaff();
  const setOrganizationGroups = authActions.useSetOrganizationGroups();
  const router = useRouter();
  const logout = useLogout();

  /**
   * NOTE:
   *  クライアント証明書エラーページ遷移後にログアウトさせる。
   *  遷移してからログアウトしないとチラつくため、処理順を気をつける必要がある。
   */
  const moveToClientCertificateErrorPage = useCallback(async () => {
    await router.push('/error/client-certificate-error');
    await logout();
  }, [logout, router]);

  const [getUserData] = useUserDataLazyQuery({
    fetchPolicy: 'network-only',
    onError: (err) => {
      if (err instanceof ApolloError) {
        /**
         * NOTE:
         *  ネットワークエラーの場合、クライアント証明書エラーページに遷移させる。
         *  若干雑だが、クライアント証明書エラーとネットワークエラーを区別できない。
         *  他のプロダクトと仕様を合わせている。
         *  ref: https://kakehashi.slack.com/archives/C02624P95G8/p1676538776123149?thread_ts=1676527828.687339&cid=C02624P95G8
         *
         *  fetch API でネットワークエラーを判定する方法は以下の通り。
         *  ref: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
         */
        if (err.networkError instanceof TypeError) {
          moveToClientCertificateErrorPage();
        }
      }
    },
  });

  const apolloClient = useApolloClient();
  const resetAuthState = authActions.useReset();
  const resetState = useCallback(async () => {
    resetAuthState();
    await apolloClient.clearStore();
    removeLocalStorageItems();
    configureScope((scope) => scope.setUser(null));
    updateStatus('signIn');
  }, [apolloClient, resetAuthState, updateStatus]);

  const authenticate = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // 認証済みの場合
      if (user) {
        const { data } = await getUserData();
        const me = data?.me || undefined;
        setPharmacyStaff(me);
        // 組織グループ管理者でない場合(空配列)は undefined として扱う
        const organizationGroups = data?.ownAdministrationOrganizationGroups || undefined;
        setOrganizationGroups(organizationGroups);
        if (me) setSentryUser({ id: me.id });
      } else {
        resetState();
      }
    } catch (e) {
      resetState();
    }
  }, [getUserData, resetState, setOrganizationGroups, setPharmacyStaff]);

  const authStatus = authSelectors.useStatus();
  const shouldAuthenticate = authStatus !== 'signedIn';

  useEffect(() => {
    try {
      if (shouldAuthenticate) {
        authenticate();
      }
    } catch (e) {
      captureException(e);
    }

    const handleAuth = async ({ payload: { event } }: HubCapsule) => {
      if (event === 'signOut') {
        onSignOut?.();
        await resetState();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else if (event === 'signIn') {
        try {
          await authenticate();
        } catch (e) {
          captureException(e);
        }
      } else if (event === 'tokenRefresh_failure') {
        await resetState();
      }
    };
    const hubListenerAuth = Hub.listen('auth', handleAuth);
    return () => hubListenerAuth();
  }, [authenticate, onSignOut, resetState, shouldAuthenticate]);

  return { authStatus };
}
