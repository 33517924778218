// NOTE: recoil の local storage key prefix は `@kkhs/hakari-web-app` になっている。
//       recoil と判別できるようにしたいが、後方互換を考えると面倒なので、一旦は `@kkhs/hakari-web-app` を recoil 用とする。
//       そのため、このファイルでは `@kkhs/hakari-web-app` ではなく `web-app` とする
const PREFIX = 'web-app';

export const localStorageKeys = {
  USER_POOL_TYPE: `${PREFIX}/user-pool-type`,
  /** CP SSO で利用する UserPool 情報 */
  CP_SSO_USER_POOL: `${PREFIX}/cp-sso-user-pool`,
};
