import { HStack, Tag } from '@chakra-ui/react';
import { ExportIcon, Typography } from '@kkhs/hakari-ui';
import { TotalAmount } from './TotalAmount';
import { currentRoleSelectors } from '../../../../store/currentRole';
import {
  ShippingDefaultPriceConfig,
  ShippingStockOperation,
} from '../ShippingStockOperationDeleteDialog/ShippingDialogContent/types';
import { useTotalAmount } from '../ShippingStockOperationDeleteDialog/hooks/useTotalAmount';

type Props = {
  stockOperation?: ShippingStockOperation;
  defaultPriceConfig?: ShippingDefaultPriceConfig;
};

export function ShippingStockOperationDeleteHeading({ stockOperation, defaultPriceConfig }: Props) {
  const isManager = currentRoleSelectors.useIsManager();
  const { totalAmount, totalTaxAmount, showTax, showTotalAmount } = useTotalAmount({
    stockOperation,
    defaultPriceConfig,
  });

  return (
    <>
      <HStack spacing={4}>
        <HStack>
          <ExportIcon color="slate.300" boxSize={12} />
          <Typography variant="h2">在庫を減らす (出庫)</Typography>
        </HStack>
        <Tag w="46px" bg="white" borderWidth={1} borderColor="slate.300" borderRadius="none">
          <Typography
            align="center"
            variant="body"
            fontWeight="bold"
            color="slate.300"
            whiteSpace="nowrap"
          >
            編集
          </Typography>
        </Tag>
      </HStack>
      {showTotalAmount && isManager && (
        <TotalAmount totalAmount={totalAmount} totalTaxAmount={totalTaxAmount} showTax={showTax} />
      )}
    </>
  );
}
