import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

type Props = ChakraLinkProps & {
  /** リンク先のURL */
  href: string;
};

/** Buttonのネストに適したLinkコンポーネント */
export function ButtonLink(props: Props) {
  const { href, onClick } = props;
  return (
    <NextLink href={href}>
      <ChakraLink
        {...props}
        onClick={(e) => {
          (document.activeElement as HTMLElement).blur();
          onClick?.(e);
        }}
        _hover={{ textDecoration: 'none' }}
      />
    </NextLink>
  );
}
