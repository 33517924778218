import { WatchQueryFetchPolicy } from '@apollo/client';
import { useMemo } from 'react';
import { usePharmacyShouldAutoUpdateCostPriceMasterQuery } from '@/gql/apollo';
import { currentPharmacySelectors } from '../../store';
// ゆくゆく feature/pharmacy 配下に移動される

export function usePharmacyShouldAutoUpdateCostPriceMaster(options?: {
  fetchPolicy?: WatchQueryFetchPolicy;
}) {
  const currentPharmacy = currentPharmacySelectors.useValue();
  const { data, loading } = usePharmacyShouldAutoUpdateCostPriceMasterQuery(options);
  const shouldAutoUpdateCostPriceMaster = useMemo(
    () =>
      data?.pharmacies.edges.find((p) => p.node.id === currentPharmacy?.id)?.node
        .shouldAutoUpdateCostPriceMaster,
    [currentPharmacy?.id, data?.pharmacies.edges],
  );
  return { shouldAutoUpdateCostPriceMaster, loading };
}
