import { createIcon } from '@chakra-ui/react';

export const PlusSquareIcon = createIcon({
  displayName: 'PlusSquareIcon',
  viewBox: '0 0 16 17',
  path: (
    <>
      <rect
        x="1"
        y="1.5"
        width="14"
        height="14"
        rx="1"
        stroke="currentColor"
        strokeWidth="2"
        fill="transparent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9.5V12.5H9V9.5H12V7.5H9V4.5H7V7.5H4V9.5H7Z"
        fill="currentColor"
      />
    </>
  ),
});
