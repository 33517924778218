import { Center } from '@chakra-ui/react';
import { ChevronDownIcon } from '../../../icons';

export function DropdownIndicator() {
  return (
    <Center pr="10px">
      <ChevronDownIcon color="primary.500" boxSize={4} />
    </Center>
  );
}
