import { TableBodyProps, Tbody } from '@chakra-ui/react';

type Props = TableBodyProps & {
  /** ローディング中かどうか */
  isLoading?: boolean;
  /** ローディングコンポーネントのレンダラ */
  loadingRenderer?: () => React.ReactNode;
};

export function VirtualizedTbody({ isLoading = false, loadingRenderer, children, ...rest }: Props) {
  if (isLoading) {
    return (
      <Tbody as="div" p={0} {...rest}>
        {loadingRenderer?.()}
      </Tbody>
    );
  }
  return (
    <Tbody
      as="div"
      p={0}
      sx={{
        '& [role="row"]': { bg: '#fff', _hover: { bg: 'hoveredTableRow.default' } },
        '& [role="row"][data-ischecked="true"]': {
          bg: 'primary.50',
          _hover: { bg: 'hoveredTableRow.isChecked' },
        },
      }}
      {...rest}
    >
      {children}
    </Tbody>
  );
}
