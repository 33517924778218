import { HStack, Stack } from '@chakra-ui/react';
import { ErrorTooltip, RequiredTag, TextInput, Typography } from '@kkhs/hakari-ui';
import { FieldPath, FieldValues, UseFormReturn, useController } from 'react-hook-form';
import { MAX_LENGTH_MAPPING_YJ_CODE } from '../../constants';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  control: UseFormReturn<T>['control'];
  isDisabled?: boolean;
  isRequired?: boolean;
};

export function MedicalMaterialMappingYjCodeForm<T extends FieldValues>({
  name,
  control,
  isDisabled = false,
  isRequired = false,
}: Props<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <Stack spacing={2}>
      <HStack spacing={0.5}>
        <Typography variant="body2" fontWeight="bold">
          連携用YJコード
        </Typography>
        {isRequired && <RequiredTag />}
      </HStack>
      <ErrorTooltip label={error?.message} isOpen={!!error?.message}>
        <TextInput
          w="164px"
          placeholder={`1-${MAX_LENGTH_MAPPING_YJ_CODE}桁の半角英数で入力`}
          value={value}
          onChange={({ target }) => {
            if (!target.value.match(/^[0-9a-zA-Z]*$/)) {
              return;
            }
            onChange(target.value);
          }}
          isDisabled={isDisabled}
          maxLength={MAX_LENGTH_MAPPING_YJ_CODE}
          px={1}
        />
      </ErrorTooltip>
    </Stack>
  );
}
