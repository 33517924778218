import { createIcon } from '@chakra-ui/react';

export const MLUnitSymbolIcon = createIcon({
  displayName: 'ML',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.57 6.24C8.89702 6.73996 9.04831 7.33455 9 7.93V13H8.1V8C8.133 7.58315 8.04258 7.16581 7.84 6.8C7.77216 6.69251 7.67742 6.60461 7.56517 6.54497C7.45292 6.48534 7.32705 6.45605 7.2 6.46C7.01542 6.45017 6.83173 6.4917 6.66933 6.57996C6.50692 6.66822 6.37216 6.79977 6.28 6.96C6.05005 7.4353 5.95329 7.96406 6 8.49V13H5.1V8.06C5.12396 7.61723 5.01233 7.17768 4.78 6.8C4.71055 6.69172 4.61408 6.60341 4.50009 6.54379C4.38611 6.48417 4.25855 6.45529 4.13 6.46C3.94118 6.4454 3.75246 6.48943 3.5896 6.58608C3.42673 6.68272 3.29766 6.82727 3.22 7C2.98542 7.49958 2.87561 8.04863 2.9 8.6V13H2V5.8H2.76L2.83 6.62C2.94742 6.40912 3.09573 6.21699 3.27 6.05C3.40106 5.92114 3.55795 5.82154 3.73032 5.75776C3.90269 5.69398 4.08663 5.66748 4.27 5.68C4.45167 5.66789 4.63392 5.69265 4.80577 5.7528C4.97762 5.81295 5.13553 5.90724 5.27 6.03C5.46826 6.22271 5.61891 6.45895 5.71 6.72C5.83283 6.44728 6.00973 6.20234 6.23 6C6.54945 5.7701 6.93675 5.65391 7.33 5.67C7.56704 5.66018 7.80302 5.70673 8.01858 5.80581C8.23414 5.9049 8.42311 6.0537 8.57 6.24ZM11.1 12.1V3H10.2V13H14.2V12.1H11.1Z"
        fill="currentColor"
      />
    </svg>
  ),
});
