import { toUnique } from '@/shared/utils';
import { StoringStockOperation } from '../types';

/**
 * 入出庫医薬品(operatedMedicines)を医薬品のID(medicineId)単位でまとめた形式で返す
 */
// TODO: テスト追加
// TODO: fragmentをpropsとして受け取れるようにする
export function toOperatedMedicines(stockOperation: StoringStockOperation) {
  const uniqueMedicineIds = toUnique(
    stockOperation.operatedMedicines.map((medicine) => medicine.medicineId),
  );
  return uniqueMedicineIds.map((medicineId) => {
    const uniqueMedicines = stockOperation.operatedMedicines.filter(
      (m) => m.medicineId === medicineId,
    );

    if (uniqueMedicines[0] === undefined) return undefined;
    return {
      /** stock_operated_medicines.id */
      id: uniqueMedicines[0].id,
      medicineId: uniqueMedicines[0].medicineId,
      medicineName: uniqueMedicines[0].name,
      unitOutline: uniqueMedicines[0].usageGroup.unitOutline,
      // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
      shortUnitOutline: uniqueMedicines[0].usageGroup.shortUnitOutline,
      stockUnitSymbol: uniqueMedicines[0].stockUnitSymbol,
      stockUnitPrice: uniqueMedicines[0].stockUnitPrice,
      costPrice: uniqueMedicines[0].costPrice,
      lots: uniqueMedicines.map((medicine) => ({
        defaultLotNumber: medicine.lotNumber ?? undefined,
        lotNumber: medicine.lotNumber ?? null,
        expirationDate: medicine.expirationDate ? new Date(medicine.expirationDate) : null,
        stockUnitQuantity: medicine.stockUnitQuantity,
      })),
    };
  });
}
