import { createIcon } from '@chakra-ui/react';

export const ContainerUnitSymbolIcon = createIcon({
  displayName: 'Container',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.52001 6.08995H0.900015V3.51995H3.90001V2.44995H4.53001V3.51995H7.53001V6.08995H6.94001V4.28995H1.52001V6.08995ZM5.45001 4.84995L5.38001 4.74995L4.94001 5.23995V5.30995C5.50181 6.14521 6.19787 6.88181 7.00001 7.48995L7.10001 7.56995L7.46001 6.88995H7.39001C6.62785 6.32997 5.97101 5.63928 5.45001 4.84995ZM1.75001 13V9.39995C1.54001 9.52995 1.34001 9.63995 1.01001 9.80995H0.910015L0.640015 9.13995H0.720015C2.08212 8.57216 3.21545 7.56553 3.94001 6.27995V6.21995H4.51002V6.27995C5.23687 7.54304 6.38863 8.50698 7.76002 8.99995H7.84001L7.57001 9.79995H7.47001C7.14001 9.62995 6.95001 9.51995 6.74001 9.39995V13H1.75001ZM2.36001 9.83995V12.25H6.12001V9.83995H2.36001ZM4.28001 6.99995C3.75988 7.82063 3.07967 8.52805 2.28001 9.07995H6.28001C5.47243 8.53744 4.79045 7.82819 4.28001 6.99995ZM1.12001 6.74995L1.00001 6.79995L1.26001 7.41995L1.31001 7.52995L1.42001 7.45995C2.24445 6.89559 2.92862 6.14985 3.42001 5.27995V5.20995L2.96001 4.73995L3.00001 4.85995C2.54934 5.64346 1.90112 6.29512 1.12001 6.74995ZM15.24 9.74995H15.31L15.05 10.51L14.94 10.46L14.71 10.32V13H12V9.32995H13.44C12.9408 8.8767 12.5216 8.34255 12.2 7.74995H11.29C10.9802 8.33152 10.5859 8.86397 10.12 9.32995H11.44V13H8.76001V10.43L8.55001 10.55L8.44001 10.61L8.22002 9.89995H8.30001C9.21708 9.39918 9.99144 8.673 10.55 7.78995H8.21001V6.99995H11C11.1131 6.74404 11.2067 6.47995 11.28 6.20995V6.07995L11.89 6.19995L11.83 6.38995C11.78 6.57995 11.73 6.76995 11.65 6.99995H15.28V7.77995H13C13.588 8.59508 14.354 9.26541 15.24 9.73995V9.74995ZM14.14 12.3V10.05H12.53V12.29L14.14 12.3ZM10.86 12.3V10.05H9.34001V12.29L10.86 12.3ZM14.77 5.88995H12V2.88995H14.73L14.77 5.88995ZM14.2 5.14995V3.57995H12.62V5.13995L14.2 5.14995ZM11.38 5.88995H8.72001V2.88995H11.38V5.88995ZM10.8 5.14995V3.57995H9.30001V5.13995L10.8 5.14995Z"
        fill="currentColor"
      />
    </svg>
  ),
});
