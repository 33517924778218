import { createIcon } from '@chakra-ui/react';

export const PatientIcon = createIcon({
  displayName: 'PatientIcon',
  viewBox: '0 0 11 11',
  path: (
    <path
      d="M1.8655 8.15497C3.1306 7.64035 4.34211 7.38304 5.5 7.38304C6.65789 7.38304 7.85867 7.64035 9.10234 8.15497C10.3674 8.64815 11 9.30214 11 10.117V11.5H0V10.117C0 9.30214 0.621832 8.64815 1.8655 8.15497ZM7.42982 5.19591C6.89376 5.73197 6.25049 6 5.5 6C4.74951 6 4.10624 5.73197 3.57018 5.19591C3.03411 4.65984 2.76608 4.01657 2.76608 3.26608C2.76608 2.51559 3.03411 1.87232 3.57018 1.33626C4.10624 0.778752 4.74951 0.5 5.5 0.5C6.25049 0.5 6.89376 0.778752 7.42982 1.33626C7.96589 1.87232 8.23392 2.51559 8.23392 3.26608C8.23392 4.01657 7.96589 4.65984 7.42982 5.19591Z"
      fill="currentColor"
    />
  ),
});
