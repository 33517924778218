import { useMemo } from 'react';
import { useOwnPharmacyMedicinesQuery } from '@/gql/apollo';
import { AWSDate } from '@/shared/utils';

type Props = {
  groupPharmacyStocksMedicineIds: string[];
};

export function useOwnPharmacyMedicineMap({ groupPharmacyStocksMedicineIds }: Props) {
  const { data: medicinesData, loading: isAdoptedMedicinesloading } = useOwnPharmacyMedicinesQuery({
    skip: groupPharmacyStocksMedicineIds.length === 0,
    variables: {
      medicineIds: groupPharmacyStocksMedicineIds,
    },
  });
  /** key: medicineId, value: { isAdopted, adoptionStoppedDate } の Map */
  const ownPharmacyMedicineMap = useMemo(
    () =>
      medicinesData?.medicines.reduce<{
        [key: string]: {
          isAdopted: boolean;
          adoptionStoppedDate: AWSDate | null;
          isMedicalMaterial: boolean;
        };
      }>((acc, cur) => {
        acc[cur.id] = {
          isAdopted: cur.isAdopted,
          adoptionStoppedDate: cur.adoptionStoppedDate,
          isMedicalMaterial: cur.isMedicalMaterial,
        };
        return acc;
      }, {}),
    [medicinesData?.medicines],
  );
  return {
    ownPharmacyMedicineMap,
    isLoading: isAdoptedMedicinesloading,
  };
}
