import { InternalRefetchQueriesInclude } from '@apollo/client';
import { AlertDialogBody, AlertDialogFooter, Button, ButtonGroup } from '@chakra-ui/react';
import { useZodForm, showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { useCallback, useEffect } from 'react';
import { DefaultValues, FormProvider } from 'react-hook-form';
import {
  StockOperationExternalDefaultSettingFragment,
  useCreateExternalCounterpartyMutation,
} from '@/gql/apollo';
import { DialogContent } from './DialogContent';
import {
  CreateExternalCounterpartyFieldValues as FormData,
  createExternalCounterpartySchema as formSchema,
} from './schema';

function toDefaultValues(
  fragment: StockOperationExternalDefaultSettingFragment | null,
): DefaultValues<FormData> {
  return {
    defaultPriceRatePercent: fragment?.stockOperationPriceConfig.defaultPriceRate
      ? fragment.stockOperationPriceConfig.defaultPriceRate * 100
      : 100,
    itemRoundingMethod: fragment?.stockOperationPriceConfig.itemRoundingMethod ?? 'none',
    totalRoundingMethod: fragment?.stockOperationPriceConfig.totalRoundingMethod ?? 'round',
    slipTitle: fragment?.stockOperationPriceConfig.slipTitle,
    shouldShowTax: fragment?.stockOperationPriceConfig?.shouldShowTax
      ? ('true' as const)
      : ('false' as const),
    taxCalculationTarget: fragment?.stockOperationPriceConfig.taxCalculationTarget ?? 'operation',
    taxRoundingMethod: fragment?.stockOperationPriceConfig?.taxRoundingMethod ?? 'none',
    isTotalRoundingMethodDisabled: false,
    containerAmount: fragment?.stockOperationPriceConfig.containerAmount ?? 0,
    containerAmountEnabled: fragment?.stockOperationPriceConfig.containerAmount !== null,
    feeAmount: fragment?.stockOperationPriceConfig.feeAmount ?? 0,
    feeAmountEnabled: fragment?.stockOperationPriceConfig.feeAmount !== null,
  } as const;
}

type Props = {
  onClose: () => void;
  cancelRef: React.MutableRefObject<null>;
  refetchQueries?: InternalRefetchQueriesInclude;
  fragment: StockOperationExternalDefaultSettingFragment | null;
};

export function LoadedDialogContent({ onClose, cancelRef, refetchQueries, fragment }: Props) {
  const useFormReturn = useZodForm({
    schema: formSchema,
    defaultValues: toDefaultValues(fragment),
  });
  const {
    handleSubmit,
    reset,
    setFocus,
    formState: { isValid, isSubmitting },
  } = useFormReturn;

  // 入力フォームのフォーカスを一番上のフィールドに合わせることで、スクロール位置をトップにしている
  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const [mutation, { loading }] = useCreateExternalCounterpartyMutation({
    onCompleted: (d) => {
      if ((d.createExternalCounterparty.errors?.length ?? 0) !== 0) {
        showErrorToast({
          id: 'create-external-counterparty-error',
          title: d.createExternalCounterparty.errors?.[0]?.message ?? 'エラーが発生しました',
        });
        return;
      }
      showSuccessToast({
        id: 'create-external-counterparty-success',
        title: '取引先を登録しました',
      });
      reset();
      onClose();
    },
    refetchQueries,
  });

  const submit = useCallback(
    async ({
      name,
      zipCode,
      address,
      buildingName,
      phoneNumber,
      faxNumber,
      pharmacyLicenseNumber,
      shouldShowTax,
      itemRoundingMethod,
      totalRoundingMethod,
      taxRoundingMethod,
      isTotalRoundingMethodDisabled,
      defaultPriceRatePercent,
      taxCalculationTarget,
      feeAmount,
      feeAmountEnabled,
      containerAmount,
      containerAmountEnabled,
      slipTitle,
    }: FormData) => {
      await mutation({
        variables: {
          input: {
            name,
            zipCode,
            address,
            buildingName,
            phoneNumber,
            faxNumber,
            pharmacyLicenseNumber,
            shouldShowTax: shouldShowTax === 'true',
            itemRoundingMethod: itemRoundingMethod === 'none' ? undefined : itemRoundingMethod,
            totalRoundingMethod: isTotalRoundingMethodDisabled ? undefined : totalRoundingMethod,
            taxRoundingMethod:
              // NOTE: 税額表示しない場合、または、税額端数処理をしない場合は、undefined
              shouldShowTax === 'false' || taxRoundingMethod === 'none'
                ? undefined
                : taxRoundingMethod,
            defaultPriceRate: defaultPriceRatePercent / 100,
            taxCalculationTarget:
              // NOTE: 税額表示しない場合、undefined
              shouldShowTax === 'false' ? undefined : taxCalculationTarget,
            feeAmount: feeAmountEnabled ? feeAmount : undefined,
            containerAmount: containerAmountEnabled ? containerAmount : undefined,
            slipTitle,
            status: 'active',
          },
        },
      });
    },
    [mutation],
  );

  return (
    <>
      <AlertDialogBody p="0">
        <FormProvider {...useFormReturn}>
          <DialogContent loading={false} />
        </FormProvider>
      </AlertDialogBody>
      <AlertDialogFooter borderTop="1px" borderColor="gray.200" py={6}>
        <ButtonGroup ml="auto">
          <Button
            ref={cancelRef}
            variant="ghost"
            onClick={onClose}
            isDisabled={isSubmitting || loading}
          >
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit(submit)}
            ml={3}
            isDisabled={!isValid}
            isLoading={isSubmitting || loading}
          >
            登録する
          </Button>
        </ButtonGroup>
      </AlertDialogFooter>
    </>
  );
}
