import { forwardRef } from 'react';
import { ForwardRefType } from '../types';

export const CheckIcon: ForwardRefType<'svg'> = forwardRef(
  ({ color = 'currentColor', ...rest }, ref) => (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      style={{ ...rest.style, flexShrink: 0 }}
      ref={ref}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.75L8 14.75L4 10.75L5.5 9.25L8 11.75L14.5 5.25L16 6.75Z"
        fill={color}
      />
    </svg>
  ),
);

CheckIcon.displayName = 'CheckIcon';
