import {
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  HStack,
  Stack,
  Box,
  Divider,
  AlertDialogHeader,
  Tag,
} from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { useFormContext } from 'react-hook-form';
import { DemandTakeoverToMedicineFragment } from '@/gql/apollo';
import { DemandTakeoverToMedicinesTable } from './DemandTakeoverToMedicinesTable';

type Props = {
  onClose: () => void;
  cancelRef: React.MutableRefObject<null>;
  sourceMedicine: {
    id: string;
    name: string;
    unitOutline: string;
    // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
    shortUnitOutline: string;
  };
};

export function DialogContent({ onClose, cancelRef, sourceMedicine }: Props) {
  const { setValue } = useFormContext<{
    demandTakeoverToMedicine: {
      id: string;
      name: string;
      unitOutline: string;
      // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
      shortUnitOutline: string;
    };
  }>();
  const handleSelectMedicine = (fragment: DemandTakeoverToMedicineFragment) => {
    setValue(
      'demandTakeoverToMedicine',
      {
        id: fragment.id,
        name: fragment.medicine.medicineName,
        unitOutline: fragment.medicine.usageGroup.unitOutline,
        // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
        shortUnitOutline: fragment.medicine.usageGroup.shortUnitOutline,
      },
      { shouldDirty: true },
    );
    onClose();
  };
  return (
    <AlertDialogContent minW="1040px" minH="496px" h="80vh">
      <AlertDialogHeader borderBottom="1px" borderColor="gray.200">
        <Typography variant="h2">発注おすすめの対象を切り替える</Typography>
      </AlertDialogHeader>
      <AlertDialogBody p={0} flexGrow={1} overflowY="auto" display="flex" flexDirection="column">
        <Stack px={6} py={4} spacing={4}>
          <Typography variant="body">
            同効薬に、需要予測 (おすすめ発注の対象になるための情報)
            を引き継ぎ、おすすめ発注の対象を切り替えます。
            <br />
            切り替え先の医薬品を指定してください。
          </Typography>
          <Stack bg="gray.100" px={4} py={3} borderColor="gray.200" borderWidth={1} spacing={1}>
            <HStack>
              <Typography variant="body2" fontWeight="bold">
                切り替え元
              </Typography>
              <Tag bg="slate.300" color="white" size="sm">
                おすすめ発注対象外にします
              </Tag>
            </HStack>
            <Box>
              <Typography variant="body" fontWeight="bold">
                {sourceMedicine.name}
              </Typography>
              {/* 短縮需要タイプを表示 */}
              <Typography variant="body2">{sourceMedicine.shortUnitOutline}</Typography>
            </Box>
          </Stack>
        </Stack>
        <DemandTakeoverToMedicinesTable
          onSelect={handleSelectMedicine}
          medicineId={sourceMedicine.id}
        />
      </AlertDialogBody>
      <Divider borderColor="gray.300" />
      <AlertDialogFooter pr={6}>
        <Button ref={cancelRef} variant="ghost" onClick={onClose}>
          閉じる
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}
