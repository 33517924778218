import { useCallback, useEffect, useState } from 'react';
import { ScrollbarPresenceParams } from 'react-virtualized';

type Props = {
  /** テーブルのローディング状態 */
  isLoading: boolean;
};

export function useVirtualizedTableScrollbarSize({ isLoading }: Props) {
  const [scrollbarSize, setScrollbarSize] = useState<number | undefined>();

  useEffect(() => {
    if (isLoading === true) {
      setScrollbarSize(undefined);
    }
  }, [isLoading]);

  const onScrollbarPresenceChange = useCallback((v: ScrollbarPresenceParams) => {
    if (v.vertical) {
      setScrollbarSize(v.size);
    } else {
      setScrollbarSize(undefined);
    }
  }, []);

  return {
    scrollbarSize,
    onScrollbarPresenceChange,
  };
}
