import { HStack, Td, Tr } from '@chakra-ui/react';
import {
  NumberLikeText,
  Typography,
  PatientIcon,
  FewPrescriptionFrequencyIcon,
  FnPatientIcon,
  Link,
} from '@kkhs/hakari-ui';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { StockUnitQuantityWithSymbol } from '@/entities/medicine';
import { PrescriptionFrequencyType } from '@/entities/prescription';
import { PatientByMedicineListItemsQuery } from '@/gql/apollo';
import { formatAWSDate } from '@/shared/utils';

type Props = {
  node: PatientByMedicineListItemsQuery['patientByMedicineListItems']['edges'][0]['node'];
  stockUnitSymbol: string;
  prescriptionFrequencyType?: PrescriptionFrequencyType;
};

function renderPatientIcon(prescriptionFrequencyType?: PrescriptionFrequencyType) {
  if (prescriptionFrequencyType === 'f1' || prescriptionFrequencyType === 'f2') {
    return <FewPrescriptionFrequencyIcon color="red.500" boxSize="18px" />;
  }
  if (prescriptionFrequencyType === 'fn') {
    return <FnPatientIcon color="blue.600" boxSize="18px" />;
  }
  return <PatientIcon color="gray.300" p="3px" boxSize="18px" />;
}

export function PatientsTr({ node, stockUnitSymbol, prescriptionFrequencyType }: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  return (
    <Tr key={node.id}>
      <Td pl={0} pr={1.5}>
        <HStack spacing={1} align="flex-start">
          {renderPatientIcon(prescriptionFrequencyType)}
          <Link
            href={`/patient/detail/?patientId=${node.patient.id}`}
            textDecoration="underline"
            target="_blank"
            onClick={() => {
              userMonitoringRepository.sendEvent({
                key: '医薬品詳細モーダルの処方患者タブで患者詳細リンクをClick',
                contexts: { pharmacyId, musubiCode },
              });
            }}
          >
            <Typography variant="body2">{node.patient.name}</Typography>
          </Link>
        </HStack>
      </Td>
      <Td verticalAlign="top" px={1.5}>
        <NumberLikeText data-testid="患者コード">{node.patient.code}</NumberLikeText>
      </Td>
      <Td verticalAlign="top" px={1.5}>
        <NumberLikeText data-testid="前回来局日" whiteSpace="nowrap">
          {formatAWSDate(node.lastPrescriptionDate, { showDayOfWeek: true })}
        </NumberLikeText>
      </Td>
      <Td data-testid="前回処方量" verticalAlign="top" px={1.5} isNumeric>
        <StockUnitQuantityWithSymbol
          variant="body2"
          quantity={node.lastPrescriptionUnitQuantity}
          stockUnitSymbol={stockUnitSymbol}
        />
      </Td>
      <Td verticalAlign="top" px={1.5}>
        <NumberLikeText whiteSpace="nowrap">
          {formatAWSDate(node.prescriptionForecastDate, { showDayOfWeek: true })}
        </NumberLikeText>
      </Td>
      <Td verticalAlign="top" px={1.5} isNumeric>
        <StockUnitQuantityWithSymbol
          variant="body2"
          quantity={node.prescriptionForecastUnitQuantity ?? undefined}
          stockUnitSymbol={stockUnitSymbol}
        />
      </Td>
      <Td verticalAlign="top" pl={1.5} pr={0}>
        <Typography variant="body2">{node.patient.memo ?? '-'}</Typography>
      </Td>
    </Tr>
  );
}
