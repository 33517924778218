import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { Query, useUrlQuery } from '../../../hooks';

/**
 * Tabs で利用するカスタムフック
 * - query string に保持した tabIndex を返却する
 * - タブが切り替わった際に query string を更新する callback を返却する
 */
export function useTabs({
  keyName = 'tab',
  defaultIndex = 0,
  replace = false,
}: { keyName?: string; defaultIndex?: number; replace?: boolean } = {}) {
  const router = useRouter();

  const [tabIndex, setTabIndex] = useState<number>(defaultIndex);

  const { push, replace: routerReplace } = useUrlQuery();
  const onChangeTab = useCallback(
    (index: number, query: Query | undefined = {}) => {
      setTabIndex(index);
      if (replace) {
        routerReplace({ [keyName]: index, ...query });
      } else {
        push({ [keyName]: index, ...query });
      }
    },
    [keyName, push, replace, routerReplace],
  );

  useEffect(() => {
    if (router.query[keyName]) {
      setTabIndex(Number(router.query[keyName]));
    }
  }, [keyName, router.query, setTabIndex]);

  return { tabIndex, onChangeTab };
}
