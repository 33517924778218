type Node = {
  isEditable: boolean;
  stockOperationId: string | null;
};

export function isEditableMedicineTransaction(node: Node): node is Node & {
  stockOperationId: NonNullable<Node['stockOperationId']>;
} {
  if (node.stockOperationId === null) {
    return false;
  }
  return node.isEditable;
}
