import { useApolloClient } from '@apollo/client';
import { configureScope } from '@sentry/nextjs';
import { useCallback } from 'react';
import { CurrentPharmacy, currentPharmacyActions } from '@/entities/currentPharmacy';

export function useCurrentPharmacy(): {
  setCurrentPharmacy: (state: CurrentPharmacy) => Promise<void>;
  resetCurrentPharmacy: () => void;
} {
  const apolloClient = useApolloClient();
  const setCurrentPharmacyState = currentPharmacyActions.useSetState();

  const setCurrentPharmacy = useCallback(
    async (state: CurrentPharmacy) => {
      // 念のためログイン時にも初期化する
      await apolloClient.resetStore();
      setCurrentPharmacyState(state);
      configureScope((scope) => {
        scope.setTag('pharmacy.id', state.id);
        scope.setTag('pharmacy.musubi_code', state.musubiCode);
        scope.setTag('pharmacy.name', state.name);
      });
    },
    [apolloClient, setCurrentPharmacyState],
  );

  const resetCurrentPharmacyState = currentPharmacyActions.useReset();
  const resetCurrentPharmacy = useCallback(() => {
    resetCurrentPharmacyState();
    configureScope((scope) => {
      scope.setTag('pharmacy.id', null);
      scope.setTag('pharmacy.musubi_code', null);
      scope.setTag('pharmacy.name', null);
    });
  }, [resetCurrentPharmacyState]);

  return {
    setCurrentPharmacy,
    resetCurrentPharmacy,
  };
}
