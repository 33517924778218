import { createIcon } from '@chakra-ui/react';

export const PairUnitSymbolIcon = createIcon({
  displayName: 'Pair',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.42996 11.6599C6.34977 10.7779 6.16514 9.90846 5.87996 9.06993V8.96993L6.62996 8.73993V8.82993C6.94494 9.67179 7.17606 10.5427 7.31996 11.4299V11.5199L6.42996 11.7799V11.6599ZM3.24996 8.92993C3.21754 9.94177 2.99712 10.9387 2.59996 11.8699V11.9499L3.26996 12.4499V12.3399C3.70089 11.2915 3.96085 10.1807 4.03996 9.04993V8.99993L3.22996 8.85993L3.24996 8.92993ZM12.69 12.1699H13.43V12.9399H6.42996V12.1699H7.80996V2.83993H12.69V12.1699ZM8.61996 9.67993V12.1699H11.86V9.67993H8.61996ZM8.61996 6.61993V8.93993H11.86V6.61993H8.61996ZM8.61996 3.61993V5.84993H11.86V3.62993L8.61996 3.61993ZM6.84996 8.76993V8.87993L7.58996 8.47993V8.39993C7.32769 7.61541 6.9824 6.86113 6.55996 6.14993V6.06993L5.86996 6.38993V6.48993C6.01484 6.73191 6.14508 6.98236 6.25996 7.23993C5.69996 7.30993 5.01996 7.35993 4.56996 7.38993C5.28996 6.28993 5.79996 5.46993 6.72996 3.83993V3.74993L5.99996 3.39993V3.47993C5.60757 4.28714 5.17022 5.07171 4.68996 5.82993L4.09996 5.23993C4.63996 4.37993 5.35996 2.84993 5.36996 2.82993V2.73993L4.58996 2.42993V2.51993C4.27865 3.27447 3.92131 4.00919 3.51996 4.71993L3.31996 4.55993L3.14996 4.41993L3.06996 4.34993L2.65996 4.85993V4.93993L2.69996 4.99993C3.26418 5.4628 3.78622 5.97481 4.25996 6.52993V6.52993C4.15996 6.68993 4.02996 6.89993 3.67996 7.40993H2.67996H2.57996V8.17993H2.79996C3.31996 8.17993 3.85996 8.17993 4.58996 8.09993V13.1599H5.43996V8.07993H5.50996C5.76996 8.07993 6.11996 8.02993 6.56996 7.96993C6.67996 8.24993 6.74996 8.44993 6.84996 8.76993Z"
        fill="currentColor"
      />
    </svg>
  ),
});
