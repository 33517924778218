import { createIcon } from '@chakra-ui/react';

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
        fill="currentColor"
      />
      <path d="M10 2H14L15 5H9L10 2Z" fill="currentColor" />
      <path d="M14 22L10 22L9 19L15 19L14 22Z" fill="currentColor" />
      <path
        d="M4.33984 18.7321L2.33984 15.268L4.43792 12.902L7.43792 18.0981L4.33984 18.7321Z"
        fill="currentColor"
      />
      <path
        d="M19.6604 5.268L21.6604 8.7321L19.5623 11.0981L16.5623 5.90198L19.6604 5.268Z"
        fill="currentColor"
      />
      <path
        d="M21.6602 15.2679L19.6602 18.732L16.5621 18.098L19.5621 12.9019L21.6602 15.2679Z"
        fill="currentColor"
      />
      <path
        d="M2.3396 8.732L4.3396 5.2679L7.43768 5.90187L4.43768 11.098L2.3396 8.732Z"
        fill="currentColor"
      />
      <circle cx="12" cy="12" r="2" fill="currentColor" />
    </g>
  ),
});
