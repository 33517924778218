import {
  AsyncCreatableSelect,
  AsyncCreatableSelectProps,
  SelectInstanceType,
} from '@kkhs/hakari-ui';
import { forwardRef } from 'react';
import { z } from 'zod';
import {
  useExternalCounterpartySelectOptionsLazyQuery,
  ExternalCounterpartySelectOptionFragment,
} from '@/gql/apollo';
import { assertExternalCounterpartySelectOptionFragment } from './assertExternalCounterpartySelectOptionFragment';

const schema = z
  .string()
  .trim()
  .transform((v) => v.slice(0, 120));

type UserInputSelectOption = {
  type: 'userInput';
  id: null;
  name: string;
};
export type ExternalCounterpartySelectOption =
  | (ExternalCounterpartySelectOptionFragment & {
      type: 'externalCounterparty';
    })
  | UserInputSelectOption;

type Props = {
  ref?: React.Ref<HTMLSelectElement>;
} & Omit<AsyncCreatableSelectProps<ExternalCounterpartySelectOption>, 'loadOptions'>;

/**
 * 取引先を一件選択することができるコンポーネント
 */
export const ExternalCounterpartySelect = forwardRef<
  SelectInstanceType<ExternalCounterpartySelectOption>,
  Omit<Props, 'ref'>
>(({ ...rest }: Omit<Props, 'ref'>, ref) => {
  const [fetch] = useExternalCounterpartySelectOptionsLazyQuery();

  return (
    <AsyncCreatableSelect<ExternalCounterpartySelectOption>
      {...rest}
      ref={ref}
      noOptionsMessage="取引先名称を入力してください"
      defaultOptions
      formatCreateLabel={(inputValue: string) => `"${inputValue}"を入力する`}
      loadOptions={async (inputValue: string) => {
        const parsed = schema.parse(inputValue);

        const result = await fetch({
          variables: { name: parsed },
        });
        return (
          result.data?.counterparties.edges.map(({ node: fragment }) => {
            assertExternalCounterpartySelectOptionFragment(fragment);
            return {
              label: fragment.name,
              value: {
                type: 'externalCounterparty',
                ...fragment,
              },
            };
          }) ?? []
        );
      }}
    />
  );
});
ExternalCounterpartySelect.displayName = 'ExternalCounterpartySelect';
