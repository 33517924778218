import {
  AlertDialogHeader,
  HStack,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Button,
  Center,
  Stack,
  Spinner,
} from '@chakra-ui/react';
import { ExportIcon, Typography } from '@kkhs/hakari-ui';
import { TransactionType } from '@/gql/apollo';
import { StoringStockOperationUpdateHeading } from '../StoringStockOperationUpdateHeading';

type Props = {
  transactionType: TransactionType;
  onClose: () => void;
};

export function LoadingDialogContent({ transactionType, onClose }: Props) {
  return (
    <>
      <AlertDialogHeader display="flex" borderBottomWidth={1} justifyContent="space-between">
        {transactionType === 'replenishment' ? (
          <StoringStockOperationUpdateHeading />
        ) : (
          <HStack>
            <ExportIcon color="slate.300" boxSize={12} />
            <Typography variant="h2">在庫を減らす (出庫)</Typography>
          </HStack>
        )}
        <Stack spacing={0} alignItems="flex-end">
          <Typography variant="body" fontWeight="bold">
            総額
            <Typography as="span" variant="h1" ml={2} mr={0.5}>
              -
            </Typography>
            円
          </Typography>
          <Typography variant="body2" fontWeight="normal" lineHeight={0.5}>
            税額
            <Typography as="span" variant="body" ml={2} mr={0.5}>
              -
            </Typography>
            円
          </Typography>
        </Stack>
      </AlertDialogHeader>
      <AlertDialogBody display="flex" minH="421px">
        <Center flex={1}>
          {/* TODO: https://github.com/kkhs/hakari-frontend/issues/10992  */}
          <Spinner size="lg" thickness="3px" color="primary.500" emptyColor="gray.200" />
        </Center>
      </AlertDialogBody>
      <AlertDialogFooter borderTopWidth={1}>
        <ButtonGroup>
          <Button w="118px" variant="ghost" onClick={onClose}>
            キャンセル
          </Button>
          <Button w="146px" isDisabled>
            変更を保存する
          </Button>
        </ButtonGroup>
      </AlertDialogFooter>
    </>
  );
}
