import { Auth } from '@aws-amplify/auth';
import { captureException } from '@sentry/nextjs';
import { useCallback } from 'react';
import { removeLocalStorageItems } from '../../../../lib/recoil';

export const useLogout = (): (() => Promise<void>) => {
  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      removeLocalStorageItems();
    } catch (e) {
      captureException(e);
    }
  }, []);
  return logout;
};
