import { chakra } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import {
  isStockoutPreventionEnabled,
  isStockoutPreventionLabelReflected,
} from '@/entities/adoptedMedicine';
import { isFewPrescriptionFrequency, PrescriptionFrequencyType } from '@/entities/prescription';
import { MedicineSummaryTabQuery } from '@/gql/docs';

type Props = {
  /** 処方頻度タイプ */
  prescriptionFrequencyType?: PrescriptionFrequencyType;
  /** 発注点情報 */
  orderPointForecast: Omit<
    NonNullable<MedicineSummaryTabQuery['orderPointForecast']>,
    'convertedUnitQuantities'
  >;
  /** 欠品注意ラベルがあるかのフラグ */
  hasStockoutPreventionLabel: boolean;
};

export function OrderPointForecastCaptionByAuto({
  prescriptionFrequencyType,
  hasStockoutPreventionLabel,
  orderPointForecast,
}: Props) {
  const {
    stockoutPreventionFactor,
    baseStockUnitQuantity,
    safetyFactor,
    identifiedPatientsPrescriptionForecastUnitQuantity,
  } = orderPointForecast;
  // mode が auto の場合、かつ少数処方の場合のみここに入る
  if (isFewPrescriptionFrequency(prescriptionFrequencyType)) {
    return (
      <Typography variant="body2" color="slate.500">
        少数処方の医薬品です。10日以内に来局予定のある処方患者の予測処方量が発注点になります。
        {/* 欠品注意ラベルが設定されている or 欠品注意係数が設定されている */}
        {(hasStockoutPreventionLabel || !isNullOrUndefined(stockoutPreventionFactor)) && (
          <chakra.span fontWeight="bold">
            ※少数処方には欠品注意ラベルの係数2は適用されません。
          </chakra.span>
        )}
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="body2" color="slate.500">
        {/* 基準在庫量 × 安全係数 */}
        {`基準在庫量 ${baseStockUnitQuantity}×${safetyFactor}`}
        {/** 欠品係数 */}
        {isStockoutPreventionEnabled(stockoutPreventionFactor) &&
          `×${stockoutPreventionFactor}(欠品注意ラベル)`}
        {/* 特定患者の予測処方量 */}
        {prescriptionFrequencyType === 'fn' &&
          !isNullOrUndefined(identifiedPatientsPrescriptionForecastUnitQuantity) &&
          `＋特定患者の予測処方量 ${identifiedPatientsPrescriptionForecastUnitQuantity}`}
      </Typography>
      {/* 欠品注意ラベル付与当日、解除当日の表示文言 */}
      {!isStockoutPreventionLabelReflected({
        hasStockoutPreventionLabel,
        stockoutPreventionFactor,
      }) && (
        <Typography variant="body2" color="slate.500" fontWeight="bold" whiteSpace="nowrap">
          ※欠品注意ラベル設定は明日から反映されます。
        </Typography>
      )}
    </>
  );
}
