import { AdoptedMedicalProductModal } from './AdoptedMedicalProductModal';

type Props = {
  children: JSX.Element;
};

export function AdoptedMedicalProductModalRoot({ children }: Props) {
  return (
    <>
      {children}
      <AdoptedMedicalProductModal />
    </>
  );
}
