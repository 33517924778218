import {
  ButtonGroup as ChakraButtonGroup,
  ButtonGroupProps as ChakraButtonGroupProps,
} from '@chakra-ui/react';

type Props = ChakraButtonGroupProps;

/**
 * ButtonGroup をラップしたコンポーネント
 * - theme で拡張できなかったため
 */
export function ButtonGroup(props: Props) {
  return <ChakraButtonGroup spacing={4} {...props} />;
}
