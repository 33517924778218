import {
  createStandaloneToast,
  UseToastOptions,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Box,
} from '@chakra-ui/react';
import { CheckCircleIcon, AlertIconFilled } from '../icons';

const DEFAULT_DURATION = 3000;

const { toast } = createStandaloneToast();

const showToast = ({
  id,
  title,
  description,
  status = 'success',
  duration = DEFAULT_DURATION,
  containerStyle,
  icon,
}: {
  id: string;
  title: UseToastOptions['title'];
  description?: UseToastOptions['description'];
  status?: UseToastOptions['status'];
  duration?: UseToastOptions['duration'];
  containerStyle?: UseToastOptions['containerStyle'];
  icon?: UseToastOptions['icon'];
  render?: UseToastOptions['render'];
}): void => {
  const ids = id
    ? {
        root: `toast-${id}`,
        title: `toast-${id}-title`,
        description: `toast-${id}-description`,
      }
    : undefined;
  if (!toast.isActive(id)) {
    toast({
      id,
      title,
      description,
      status,
      duration,
      isClosable: true,
      containerStyle,
      icon,
      render: ({ onClose }) => (
        <Alert
          addRole={false}
          status={status}
          variant="solid"
          alignItems="start"
          borderRadius="md"
          boxShadow="lg"
          paddingEnd={8}
          textAlign="start"
          width="auto"
        >
          <AlertIcon>{icon}</AlertIcon>
          <Box maxWidth="100%">
            {title && <AlertTitle id={ids?.title}>{title}</AlertTitle>}
            {description && (
              <AlertDescription id={ids?.description} display="block">
                {description}
              </AlertDescription>
            )}
          </Box>
          <CloseButton
            size="sm"
            position="absolute"
            alignSelf="center"
            insetEnd="9px"
            onClick={onClose}
          />
        </Alert>
      ),
    });
  }
};

export const showSuccessToast = ({
  id,
  title,
  description,
  duration = DEFAULT_DURATION,
  containerStyle,
}: {
  id: string;
  title: UseToastOptions['title'];
  description?: UseToastOptions['description'];
  duration?: UseToastOptions['duration'];
  containerStyle?: UseToastOptions['containerStyle'];
}): void => {
  showToast({
    id,
    title,
    description,
    duration,
    containerStyle,
    icon: <CheckCircleIcon width="24px" height="24px" color="white" />,
  });
};

export const showErrorToast = ({
  id,
  title,
  description,
  duration = DEFAULT_DURATION,
  containerStyle,
}: {
  id: string;
  title: UseToastOptions['title'];
  description?: UseToastOptions['description'];
  duration?: UseToastOptions['duration'];
  containerStyle?: UseToastOptions['containerStyle'];
}): void => {
  showToast({
    id,
    title,
    description,
    duration,
    containerStyle,
    status: 'error',
    icon: <AlertIconFilled width="24px" height="24px" color="white" />,
  });
};
