import { createIcon } from '@chakra-ui/react';

export const TabletUnitSymbolIcon = createIcon({
  displayName: 'Tablet',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.35999 8.67996L7.12999 8.82996V8.96996C7.00761 9.70716 6.81335 10.4306 6.54999 11.13V11.23L5.80999 11V10.91C6.03311 10.2246 6.20033 9.52232 6.30999 8.80996L6.35999 8.67996ZM3.29999 11.37L4.07999 11.27V11.17C4.02274 10.404 3.89894 9.64446 3.70999 8.89996V8.80996L2.89999 8.99996V9.09996C3.09362 9.82312 3.21752 10.5632 3.26999 11.31L3.29999 11.37ZM10.72 11.99V9.51996H13.11V8.74996H10.72V6.99996H12.88V6.22996H7.59999V6.99996H9.90999V11.63C9.36509 11.2496 8.90253 10.7632 8.54999 10.2C8.70394 9.50994 8.79101 8.80669 8.80999 8.09996V7.99996L7.99999 7.92996V7.99996C8.00081 9.39526 7.65733 10.7692 6.99999 12V11.45H6.87999C6.49999 11.54 6.25999 11.6 5.44999 11.73V8.28996H7.10999V7.55996H5.41999V6.31996H6.72999V5.56996H3.20999C4.0241 4.96932 4.70505 4.20665 5.20999 3.32996C5.67988 4.01422 6.24952 4.62431 6.89999 5.13996L6.99999 5.19996L7.38999 4.60996V5.92996H8.19999V4.40996H12.3V5.92996H13.11V3.64996H10.72V2.45996H9.88999V3.64996H7.36999V4.46996C6.65114 3.94068 6.04016 3.27879 5.56999 2.51996V2.51996H4.74999V2.57996C4.24077 3.55565 3.4946 4.38778 2.57999 4.99996H2.48999L2.99999 5.74996V5.68996L3.15999 5.58996V6.31996H4.60999V7.55996H2.65999V8.28996H4.65999V11.84L2.74999 12.11H2.63999L2.79999 12.89H2.88999C4.23548 12.7233 5.57082 12.4829 6.88999 12.17C6.7815 12.3478 6.66123 12.5182 6.52999 12.68L6.45999 12.77L7.17999 13.23L7.23999 13.16C7.72239 12.5306 8.09435 11.8239 8.33999 11.07C9.50999 12.63 10.58 12.89 12.6 12.89H13.39L13.53 12.11H12.53C11.9245 12.1616 11.3147 12.1246 10.72 12V11.99Z"
        fill="currentColor"
      />
      <path
        d="M6.35999 8.67996L7.12999 8.82996V8.96996C7.00761 9.70716 6.81335 10.4306 6.54999 11.13V11.23L5.80999 11V10.91C6.03311 10.2246 6.20033 9.52232 6.30999 8.80996L6.35999 8.67996ZM3.29999 11.37L4.07999 11.27V11.17C4.02274 10.404 3.89894 9.64446 3.70999 8.89996V8.80996L2.89999 8.99996V9.09996C3.09362 9.82312 3.21752 10.5632 3.26999 11.31L3.29999 11.37ZM10.72 11.99V9.51996H13.11V8.74996H10.72V6.99996H12.88V6.22996H7.59999V6.99996H9.90999V11.63C9.36509 11.2496 8.90253 10.7632 8.54999 10.2C8.70394 9.50994 8.79101 8.80669 8.80999 8.09996V7.99996L7.99999 7.92996V7.99996C8.00081 9.39526 7.65733 10.7692 6.99999 12V11.45H6.87999C6.49999 11.54 6.25999 11.6 5.44999 11.73V8.28996H7.10999V7.55996H5.41999V6.31996H6.72999V5.56996H3.20999C4.0241 4.96932 4.70505 4.20665 5.20999 3.32996C5.67988 4.01422 6.24952 4.62431 6.89999 5.13996L6.99999 5.19996L7.38999 4.60996V5.92996H8.19999V4.40996H12.3V5.92996H13.11V3.64996H10.72V2.45996H9.88999V3.64996H7.36999V4.46996C6.65114 3.94068 6.04016 3.27879 5.56999 2.51996V2.51996H4.74999V2.57996C4.24077 3.55565 3.4946 4.38778 2.57999 4.99996H2.48999L2.99999 5.74996V5.68996L3.15999 5.58996V6.31996H4.60999V7.55996H2.65999V8.28996H4.65999V11.84L2.74999 12.11H2.63999L2.79999 12.89H2.88999C4.23548 12.7233 5.57082 12.4829 6.88999 12.17C6.7815 12.3478 6.66123 12.5182 6.52999 12.68L6.45999 12.77L7.17999 13.23L7.23999 13.16C7.72239 12.5306 8.09435 11.8239 8.33999 11.07C9.50999 12.63 10.58 12.89 12.6 12.89H13.39L13.53 12.11H12.53C11.9245 12.1616 11.3147 12.1246 10.72 12V11.99Z"
        fill="currentColor"
      />
    </svg>
  ),
});
