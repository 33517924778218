import { RoundingMethod } from '@/gql/docs';

type Props = {
  shouldShowTax: boolean;
  itemRoundingMethod: RoundingMethod | null;
  taxRoundingMethod: RoundingMethod | null;
};

// NOTE: 「明細端数処理する & 消費税表示なしの場合」 or 「明細端数処理する & 消費税端数処理するの場合」の場合、非活性
// バイブル: https://docs.google.com/spreadsheets/d/18E6KiZEn2pisQSQnFSpidIJiiVnHzKv_J2K2DFxHSkY/edit
export function getIsTotalRoundingMethodDisabled({
  shouldShowTax,
  itemRoundingMethod,
  taxRoundingMethod,
}: Props) {
  return (
    // 明細端数処理する & 消費税表示なしの場合
    (itemRoundingMethod !== null && shouldShowTax === false && taxRoundingMethod === null) ||
    // 明細端数処理する & 消費税端数処理するの場合
    (itemRoundingMethod !== null && shouldShowTax === true && taxRoundingMethod !== null)
  );
}
