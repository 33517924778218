import { createIcon } from '@chakra-ui/react';

export const ChevronDownIcon = createIcon({
  displayName: 'ChevronDownIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 14L18 8L20 10L11.9999 18L4 10L6 8L11.9999 14Z"
      fill="currentColor"
    />
  ),
});
