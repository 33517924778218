import { createIcon } from '@chakra-ui/react';

export const AdjustmentAttentionIcon = createIcon({
  displayName: 'AdjustmentAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M13.08 8.45V12.55H12.32V11.95H6.07999V12.57H5.34V8.45H6.07999V11.22H8.75V7.81H5.72V4.39H6.45999V7.09H8.75V3.5H9.51001V7.09H11.91V4.39H12.68V7.81H9.51001V11.22H12.32V8.45H13.08Z"
        fill="#5D6771"
      />
      <path
        d="M17.73 6.04C17.49 6.53 17.21 7.02 16.89 7.48V12.57H16.16V8.41C15.84 8.79 15.5 9.12 15.17 9.39C15.08 9.25 14.84 8.9 14.69 8.75C15.61 8.04 16.49 6.95 17.02 5.81L17.73 6.04ZM17.29 4.92H15.03V4.24H17.29V3.44H18.02V4.24H20.73V3.44H21.46V4.24H23.78V4.92H21.46V5.83H20.73V4.92H18.02V5.83H17.29V4.92ZM23.85 6.98H22.88V11.64C22.88 12.08 22.76 12.29 22.43 12.4C22.1 12.52 21.55 12.52 20.69 12.52C20.65 12.32 20.53 12.03 20.41 11.82C21.1 11.85 21.73 11.85 21.91 11.84C22.09 11.83 22.15 11.78 22.15 11.63V6.98H17.9V6.29H23.85V6.98ZM18.72 10.73V11.32H18.02V7.91H21.19V10.73H18.72ZM18.72 8.54V10.1H20.5V8.54H18.72Z"
        fill="#5D6771"
      />
      <path
        d="M7.81 17.71H4.60001V17.1H7.81V17.71ZM5.60001 23.99V24.46H5V21.11H7.54999V23.99H5.60001ZM7.54999 19.04H5.01001V18.45H7.54999V19.04ZM5.01001 19.77H7.54999V20.37H5.01001V19.77ZM7.53 16.39H5.07001V15.79H7.53V16.39ZM5.60001 21.72V23.39H6.92999V21.72H5.60001ZM13.44 23.63C13.44 24.07 13.35 24.31 13.07 24.43C12.8 24.55 12.3 24.56 11.56 24.56C11.53 24.37 11.43 24.03 11.34 23.83C11.87 23.86 12.39 23.85 12.56 23.83C12.71 23.83 12.76 23.79 12.76 23.62V16.5H8.98999V19.41C8.98999 20.96 8.87999 23.16 8.04999 24.62C7.93999 24.5 7.64 24.29 7.47 24.21C8.25 22.83 8.31 20.88 8.31 19.41V15.85H13.44V23.62V23.63ZM10.53 19.09V18.13H9.5V17.56H10.53V16.7H11.14V17.56H12.2V18.13H11.14V19.09H12.35V19.66H9.42001V19.09H10.53ZM10.13 23V23.39H9.56V20.43H12.14V23H10.13ZM10.13 21V22.43H11.57V21H10.13Z"
        fill="#5D6771"
      />
      <path
        d="M16.94 19.4C16.44 19.91 15.79 20.41 15.25 20.68C15.16 20.54 14.96 20.32 14.81 20.21C15.42 19.96 16.16 19.45 16.65 18.96H15.27V17.14H16.94V16.63H14.98V16.09H16.94V15.44H17.61V16.09H19.56V16.63H17.61V17.14H19.32V17.95C20 17.34 20.52 16.43 20.79 15.45L21.47 15.6C21.37 15.93 21.25 16.25 21.11 16.56H23.86V17.16H23.22C22.99 17.95 22.64 18.6 22.16 19.13C22.66 19.56 23.28 19.93 24.03 20.16C23.89 20.3 23.7 20.57 23.6 20.75C22.84 20.47 22.22 20.06 21.71 19.58C21.15 20.06 20.46 20.42 19.67 20.71C19.6 20.57 19.38 20.29 19.23 20.16C20.04 19.92 20.72 19.57 21.26 19.1C20.9 18.68 20.62 18.22 20.41 17.77C20.22 18.04 20.01 18.28 19.79 18.49C19.7 18.37 19.48 18.14 19.32 18.01V18.96H17.67C18.07 19.15 19.11 19.67 19.39 19.83L19.03 20.32C18.74 20.12 18.09 19.74 17.61 19.47V20.68H16.94V19.4ZM19.73 23.73H23.9V24.35H14.9V23.73H16.54V22.01H17.24V23.73H19.02V21.56H15.57V20.95H23.26V21.56H19.74V22.33H22.61V22.91H19.74V23.73H19.73ZM15.88 18.47H16.94V17.63H15.88V18.47ZM17.61 17.63V18.47H18.69V17.63H17.61ZM20.8 17.16L20.78 17.19C20.99 17.69 21.29 18.2 21.7 18.66C22.05 18.24 22.32 17.75 22.5 17.16H20.8Z"
        fill="#5D6771"
      />
    </g>
  ),
});
