import { HStack, Th, Thead, Tr } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { PatientByMedicineListItemsSort, PatientByMedicineListItemsSortField } from '@/gql/apollo';
import { SortIcon } from '@/shared/ui';

type Props = {
  sortBy?: PatientByMedicineListItemsSort;
  handleSortBy: (fieldName: PatientByMedicineListItemsSortField) => void;
};

export function PatientsTableHeader({ handleSortBy, sortBy }: Props) {
  return (
    <Thead>
      <Tr>
        <Th w="144px" pl={0} pr={1.5}>
          <Typography variant="caption" color="slate.300">
            患者氏名
          </Typography>
        </Th>
        <Th w="88px" px={1.5}>
          <Typography variant="caption" color="slate.300">
            患者コード
          </Typography>
        </Th>
        <Th w="112px" px={1.5} onClick={() => handleSortBy('lastPrescriptionDate')}>
          <HStack spacing={0.5}>
            <Typography variant="caption" color="slate.300">
              前回来局日
            </Typography>
            <SortIcon<PatientByMedicineListItemsSort>
              sortBy={sortBy}
              fieldName="lastPrescriptionDate"
            />
          </HStack>
        </Th>
        <Th w="100px" px={1.5} isNumeric>
          <Typography variant="caption" color="slate.300">
            前回処方量
          </Typography>
        </Th>
        <Th w="112px" px={1.5} onClick={() => handleSortBy('prescriptionForecastDate')}>
          <HStack spacing={0.5}>
            <Typography variant="caption" color="slate.300">
              次回来局予定日
            </Typography>
            <SortIcon<PatientByMedicineListItemsSort>
              sortBy={sortBy}
              fieldName="prescriptionForecastDate"
            />
          </HStack>
        </Th>
        <Th w="100px" px={1.5} isNumeric>
          <Typography variant="caption" color="slate.300">
            次回予測処方量
          </Typography>
        </Th>
        <Th w="248px" pl={1.5} pr={0}>
          <Typography variant="caption" color="slate.300">
            患者メモ
          </Typography>
        </Th>
      </Tr>
    </Thead>
  );
}
