import { Td, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import {
  ActionCell,
  ImmovableDaysCell,
  LastPrescriptionDateCell,
  NearExpirationUnitQuantityCell,
  OldestExpirationDateCell,
  PharmacyNameCell,
  StockUnitQuantityCell,
  SurplusStockUnitQuantityCell,
  UnitOutlineCell,
} from './cells';
import { TransferStatusLinkCell } from './cells/TransferStatusLinkCell';
import {
  RenderAdoptedMedicalMaterialCreateDialog,
  RenderAdoptedMedicineCreateDialog,
  RenderOwnTakeTransferRequestDialog,
  Row as RowType,
} from '../types';

type Props = {
  row: RowType;
  index: number;
  medicineId: string;
  renderOwnTakeTransferRequestDialog: RenderOwnTakeTransferRequestDialog;
  renderAdoptedMedicineCreateDialog: RenderAdoptedMedicineCreateDialog;
  renderAdoptedMedicalMaterialCreateDialog: RenderAdoptedMedicalMaterialCreateDialog;
};

export function Row({
  row,
  index,
  medicineId,
  renderOwnTakeTransferRequestDialog,
  renderAdoptedMedicineCreateDialog,
  renderAdoptedMedicalMaterialCreateDialog,
}: Props) {
  const [isHovered, setIsHovered] = useState(false);

  const hoveredProps = {
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
    sx: {
      bg: isHovered ? 'hoveredTableRow.default' : 'transparent',
      '&:last-child > td': { borderBottomWidth: 0 },
    },
  };

  return (
    <>
      <Tr {...hoveredProps} data-testid={`group-pharmacy-row-${index}`}>
        <PharmacyNameCell {...row} />
        <UnitOutlineCell {...row} ownMedicineId={medicineId} />
        <ImmovableDaysCell {...row} />
        <StockUnitQuantityCell {...row} />
        <SurplusStockUnitQuantityCell {...row} />
        <LastPrescriptionDateCell {...row} />
        <NearExpirationUnitQuantityCell {...row} />
        <OldestExpirationDateCell {...row} />
        <ActionCell
          {...row}
          renderOwnTakeTransferRequestDialog={renderOwnTakeTransferRequestDialog}
          renderAdoptedMedicineCreateDialog={renderAdoptedMedicineCreateDialog}
          renderAdoptedMedicalMaterialCreateDialog={renderAdoptedMedicalMaterialCreateDialog}
        />
      </Tr>
      <Tr {...hoveredProps}>
        <TransferStatusLinkCell {...row} />
        <Td />
      </Tr>
    </>
  );
}
