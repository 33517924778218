import { Stack, Wrap } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { useMemo } from 'react';
import { getMedicineStorageMethodLabels } from '@/entities/medicine';
import { MedicineStorageMethodFragment } from '@/gql/apollo';

type Props = {
  value: MedicineStorageMethodFragment;
};

export function StorageMethodItemLabel({ value }: Props) {
  const storageMethods = useMemo(() => getMedicineStorageMethodLabels(value), [value]);

  return (
    <Stack spacing={0.5}>
      <Typography variant="body2" color="slate.500">
        保管方法
      </Typography>
      {storageMethods.length > 0 ? (
        <Wrap spacing={0}>
          <Typography data-testid="保管方法" variant="body">
            {storageMethods.map((storageMethod) => storageMethod).join('、')}
          </Typography>
        </Wrap>
      ) : (
        <Typography data-testid="保管方法" variant="body">
          -
        </Typography>
      )}
    </Stack>
  );
}
