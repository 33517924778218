import { IconProps } from '@chakra-ui/react';
import {
  CMUnitSymbolIcon,
  CM2UnitSymbolIcon,
  MBQUnitSymbolIcon,
  MGUnitSymbolIcon,
  MLOrGUnitSymbolIcon,
  GUnitSymbolIcon,
  MLUnitSymbolIcon,
  CassetteUnitSymbolIcon,
  CapsuleUnitSymbolIcon,
  KitUnitSymbolIcon,
  SyringeUnitSymbolIcon,
  SheetUnitSymbolIcon,
  SetUnitSymbolIcon,
  BlisterUnitSymbolIcon,
  PillUnitSymbolIcon,
  PieceUnitSymbolIcon,
  PackUnitSymbolIcon,
  TimesUnitSymbolIcon,
  ContainerUnitSymbolIcon,
  RollUnitSymbolIcon,
  StickUnitSymbolIcon,
  FilmUnitSymbolIcon,
  BallUnitSymbolIcon,
  BottleUnitSymbolIcon,
  CylinderUnitSymbolIcon,
  TubeUnitSymbolIcon,
  PairUnitSymbolIcon,
  CanUnitSymbolIcon,
  BagUnitSymbolIcon,
  TabletUnitSymbolIcon,
} from '@kkhs/hakari-ui';
import { captureMessage } from '@sentry/nextjs';

type Props = {
  name: string;
} & IconProps;

type UnitSymbolName =
  | 'CM'
  | 'MBQ'
  | 'MG'
  | 'ML又はG'
  | 'g'
  | 'mL'
  | 'カセット'
  | 'カプセル'
  | 'キット'
  | 'シリンジ'
  | 'シート'
  | 'セット'
  | 'ブリスター'
  | '丸'
  | '個'
  | '包'
  | '回分'
  | '容器'
  | '巻'
  | '本'
  | '枚'
  | '球'
  | '瓶'
  | '筒'
  | '管'
  | '組'
  | '缶'
  | '袋'
  | '錠'
  | 'cm2';

export function StockUnitSymbolIcon({ name, ...rest }: Props) {
  switch (name as UnitSymbolName) {
    case 'CM':
      return <CMUnitSymbolIcon {...rest} />;
    case 'MBQ':
      return <MBQUnitSymbolIcon {...rest} />;
    case 'MG':
      return <MGUnitSymbolIcon {...rest} />;
    case 'ML又はG':
      return <MLOrGUnitSymbolIcon {...rest} />;
    case 'g':
      return <GUnitSymbolIcon {...rest} />;
    case 'mL':
      return <MLUnitSymbolIcon {...rest} />;
    case 'カセット':
      return <CassetteUnitSymbolIcon {...rest} />;
    case 'カプセル':
      return <CapsuleUnitSymbolIcon {...rest} />;
    case 'キット':
      return <KitUnitSymbolIcon {...rest} />;
    case 'シリンジ':
      return <SyringeUnitSymbolIcon {...rest} />;
    case 'シート':
      return <SheetUnitSymbolIcon {...rest} />;
    case 'セット':
      return <SetUnitSymbolIcon {...rest} />;
    case 'ブリスター':
      return <BlisterUnitSymbolIcon {...rest} />;
    case '丸':
      return <PillUnitSymbolIcon {...rest} />;
    case '個':
      return <PieceUnitSymbolIcon {...rest} />;
    case '包':
      return <PackUnitSymbolIcon {...rest} />;
    case '回分':
      return <TimesUnitSymbolIcon {...rest} />;
    case '容器':
      return <ContainerUnitSymbolIcon {...rest} />;
    case '巻':
      return <RollUnitSymbolIcon {...rest} />;
    case '本':
      return <StickUnitSymbolIcon {...rest} />;
    case '枚':
      return <FilmUnitSymbolIcon {...rest} />;
    case '球':
      return <BallUnitSymbolIcon {...rest} />;
    case '瓶':
      return <BottleUnitSymbolIcon {...rest} />;
    case '筒':
      return <CylinderUnitSymbolIcon {...rest} />;
    case '管':
      return <TubeUnitSymbolIcon {...rest} />;
    case '組':
      return <PairUnitSymbolIcon {...rest} />;
    case '缶':
      return <CanUnitSymbolIcon {...rest} />;
    case '袋':
      return <BagUnitSymbolIcon {...rest} />;
    case '錠':
      return <TabletUnitSymbolIcon {...rest} />;
    case 'cm2':
      return <CM2UnitSymbolIcon {...rest} />;
    default:
      captureMessage(`Invalid StockUnitSymbol name - ${name}`);
      return <div />;
  }
}
