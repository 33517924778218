import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';

const costPriceSchema = z.optional(
  z
    .number()
    .max(99999999, '100,000,000未満の値を入力してください')
    .min(0, '0以上の値を入力してください'),
);

const storagesSchema = z.array(
  z.object({
    id: z.string().optional(),
    name: z.string().optional(),
  }),
);

export const schema = z.object({
  storages: storagesSchema,
  wholesaleId: z.string(),
  medicinePackageUnitId: z.string(),
  unitQuantity: z.number(),
  costPrice: costPriceSchema,
});

export type FieldValues = z.infer<typeof schema>;

export function toDefaultValues({
  defaultMedicinePackageUnitId,
}: {
  defaultMedicinePackageUnitId?: string;
}): DefaultValues<FieldValues> {
  return {
    storages: [{}],
    wholesaleId: undefined,
    medicinePackageUnitId: defaultMedicinePackageUnitId,
    unitQuantity: 0,
    costPrice: undefined,
  };
}
