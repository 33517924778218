import { useLazyQuery } from '@apollo/client';
import { Center } from '@chakra-ui/react';
import { LoadingBar } from '@kkhs/hakari-ui';
import { graphql } from '@/gql';
import { useCurrentRoleQuery } from '@/gql/apollo';
import { currentRoleActions, currentRoleSelectors } from '../../../store/currentRole';
import { ogasRegistrationInfoActions } from '../../../store/ogasRegistrationInfo';

const queryDoc = graphql(`
  query CurrentPharmacyRoot {
    ogasRegistrationInfo {
      hasOgasRegistration
      parentGroupId
    }
  }
`);

type Props = {
  children: JSX.Element;
};

export function CurrentPharmacyRoot({ children }: Props) {
  const currentRole = currentRoleSelectors.useValue();

  const setCurrentRoleState = currentRoleActions.useSetState();
  const setOgasRegistrationInfo = ogasRegistrationInfoActions.useSetState();

  const [fetchOgasRegistrationInfo] = useLazyQuery(queryDoc, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ ogasRegistrationInfo }) => {
      setOgasRegistrationInfo(ogasRegistrationInfo);
    },
  });

  useCurrentRoleQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      if (d.pharmacyStaffMembership) {
        const { role } = d.pharmacyStaffMembership;
        setCurrentRoleState(role);
      }
      fetchOgasRegistrationInfo();
    },
  });

  if (!currentRole) {
    return (
      <Center w="100%" h="100vh">
        <LoadingBar />
      </Center>
    );
  }
  return children;
}
