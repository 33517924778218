import { createIcon } from '@chakra-ui/react';

export const CapsuleUnitSymbolIcon = createIcon({
  displayName: 'Capsule',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1927_1699)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79993 1.1001H4.57993C4.57993 1.5301 4.55993 1.9201 4.52993 2.3001H7.27993V3.6801C7.27993 4.2201 7.25993 6.0201 6.99993 6.6601C6.73993 7.3101 5.89993 7.3101 5.58993 7.3101C5.03438 7.3101 4.71822 7.2569 4.48283 7.21729L4.43993 7.2101L4.29993 7.1801V6.3601L4.41993 6.3801C4.82993 6.4501 5.18993 6.5101 5.64993 6.5101C6.16993 6.5101 6.24993 6.2701 6.29993 6.1201C6.43993 5.6801 6.49993 4.3601 6.49993 3.6701V3.1001H4.45993C3.87993 6.9301 1.70993 7.5001 1.70993 7.5001L1.29993 6.8001C1.29993 6.8001 3.08993 6.3001 3.63993 3.1001H1.49993V2.3001H3.73993C3.77993 1.9301 3.79993 1.5401 3.79993 1.1001ZM9.19993 10.8701C9.19993 12.0301 9.04993 13.0201 7.79993 14.1001L8.39993 14.7001C9.51993 13.6301 9.99993 12.7301 9.99993 10.8401V9.1001H9.19993V10.8701ZM12 13.7401V9.1001H11.2V14.7001C13.73 14.4301 14.46 13.0401 14.8 11.4001L14.0999 11.1001C13.8899 12.3101 13.37 13.3801 12 13.7401ZM14.1 1.1001C13.44 1.1001 12.9 1.6401 12.9 2.3001H8.5V3.1001H13.2C13.1 4.1501 12.71 6.5901 9.2 6.9501L9.47 7.7001C11.25 7.4401 13.48 6.6401 13.92 3.4601C13.95 3.4601 13.98 3.4701 14.01 3.4801C14.04 3.4901 14.07 3.5001 14.1 3.5001C14.76 3.5001 15.3 2.9601 15.3 2.3001C15.3 1.6401 14.76 1.1001 14.1 1.1001ZM14.1 2.9001C13.77 2.9001 13.5 2.6301 13.5 2.3001C13.5 1.9701 13.77 1.7001 14.1 1.7001C14.43 1.7001 14.7 1.9701 14.7 2.3001C14.7 2.6301 14.43 2.9001 14.1 2.9001ZM4.79995 12.7L4.39995 12.1C5.64995 11.5 5.97995 10.85 6.09995 10.33L2.99995 10.61V12.88C2.99995 13.63 3.13995 13.7 3.82995 13.7H6.99995V14.5H3.85995C2.83995 14.5 2.19995 14.26 2.19995 13.05V10.68L1.19995 10.77V9.97L2.19995 9.89V8.5H2.99995V9.82L6.99995 9.5L6.97995 9.62C6.78995 11.15 6.15995 12.08 4.79995 12.7Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1927_1699">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
});
