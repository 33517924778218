import { HStack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { StockUnitSymbolIcon } from '@/entities/medicine';

type Props = {
  value: number;
  stockUnitSymbol: string;
  valueDataTestId?: string;
  iconDataTestId?: string;
};

export function OrderPointUnitQuantityWithSymbol({
  value,
  stockUnitSymbol,
  valueDataTestId,
  iconDataTestId,
}: Props) {
  return (
    <HStack justifyContent="flex-end" spacing={0}>
      <HStack spacing={0.5}>
        <Typography variant="body2">発</Typography>
        {value < 0 ? (
          <Typography variant="body2" data-testid={valueDataTestId}>
            -
          </Typography>
        ) : (
          <Typography variant="body2" isNumeric data-testid={valueDataTestId}>
            {value}
          </Typography>
        )}
      </HStack>
      <StockUnitSymbolIcon data-testid={iconDataTestId} name={stockUnitSymbol} boxSize={4} />
    </HStack>
  );
}
