import { Stack } from '@chakra-ui/react';
import { NumberLikeText, Typography } from '@kkhs/hakari-ui';
import { AWSDate, formatAWSDate } from '@/shared/utils';

type Props = {
  label: string;
  value?: AWSDate;
};

export function DateItemLabel({ label, value }: Props) {
  return (
    <Stack spacing={0.5}>
      <Typography variant="body2" color="slate.500">
        {label}
      </Typography>
      <NumberLikeText size="md">{formatAWSDate(value)}</NumberLikeText>
    </Stack>
  );
}
