import { cssVar, extendTheme, theme as defaultTheme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import {
  theme as hakariUiTheme,
  checkboxTheme,
  numberTextTheme,
  popoverTheme,
  typographyTheme,
} from '@kkhs/hakari-ui/src/theme';
import { reactBigCalendarStyle } from './reactBigCalendarStyle';
import { requestedDeliveryDatePickerCalendarStyle } from './requestedDeliveryDatePickerCalendarStyle';
// NOTE:
//  "chakra-cli tokens './src/style/theme.ts'" でエラーになるので対応
//  @kkhs/hakari-ui パッケージの main が src/index.ts のため
//  turborepo などの導入時に解決すると思われる
//  ref: https://github.com/chakra-ui/chakra-ui/issues/4550

export const theme = extendTheme({
  ...hakariUiTheme,
  styles: {
    global: {
      ...hakariUiTheme.styles.global,
      body: {
        color: 'slate.700',
      },
      ...reactBigCalendarStyle,
      ...requestedDeliveryDatePickerCalendarStyle,
    },
  },
  fonts: {
    heading: '"Noto Sans JP"',
    body: '"Noto Sans JP"',
  },
  fontSizes: {
    xs: '0.625rem', // 10px
    sm: '0.6875rem', // 11px
    md: '0.875rem', // 14px
  },
  shadows: {
    popover: '0px 5px 16px -5px rgba(55, 74, 79, 0.25)',
  },
  colors: {
    ...hakariUiTheme.colors,
    gray: {
      50: '#FAFAFA',
      100: '#F5F5F7',
      200: '#DDDDDE',
      300: '#C4C4C6',
      400: '#ACACAD',
      500: '#8A8A8A',
      600: '#676768',
      700: '#454545',
      800: '#222223',
      900: '#111111',
    },
    primary: {
      50: '#f7fcfc',
      100: '#d5f1ef',
      200: '#abe3df',
      300: '#82d4ce',
      400: '#58c6be',
      500: '#2EB8AE',
      600: '#25938b',
      700: '#1c6e68',
      800: '#124a46',
      900: '#092523',
    },
    /**
     * Primaryカラースキームのコントラスト比を一部コンポーネントで改善するための一時的な措置
     */
    primaryAlt: {
      50: '#f7fcfc',
      100: '#d5f1ef',
      200: '#abe3df',
      300: '#82d4ce',
      400: '#58c6be',
      500: '#25938b',
      600: '#1c6e68',
      700: '#124a46',
      800: '#092523',
      900: '#092523',
    },
    red: {
      50: '#fbecea',
      100: '#f7d8d4',
      200: '#efb2a9',
      300: '#e68b7f',
      400: '#de6554',
      500: '#d63e29',
      600: '#c13825',
      700: '#962b1d',
      800: '#6b1f15',
      900: '#40130c',
    },
    blue: {
      50: '#F4FAFE',
      100: '#D6EEFF',
      200: '#AAD8FA',
      300: '#7BC2F5',
      400: '#53B1F5',
      500: '#279FF5',
      600: '#1F84CC',
      700: '#17669E',
      800: '#114B75',
      900: '#0C3552',
    },
    slate: {
      25: '#E6E9EB',
      50: '#D6D9DB',
      100: '#C2C6CA',
      200: '#AEB3B8',
      300: '#858D94',
      400: '#717A82',
      500: '#5D6771',
      600: '#48545F',
      700: '#34414D',
      800: '#242E36',
      900: '#0A0D0F',
    },
    yellow: {
      50: '#fef9e6',
      100: '#fbedb3',
      200: '#f8e280',
      300: '#f5d64d',
      400: '#f2ca1a',
      500: '#f0c400',
      600: '#c09d00',
      700: '#907600',
      800: '#604e00',
      900: '#302700',
    },
    base: {
      light: '#FAFAFA',
      default: '#F5F5F7',
    },
    bg: {
      default: '#F5F5F7',
    },
    text: {
      light: '#6A7581',
      default: '#34414D',
    },
    hoveredTableRow: {
      default: '#FAFBFB',
      isChecked: '#F2F8F8',
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: 'text.default',
      },
      defaultProps: {
        size: 'lg',
      },
      sizes: {
        lg: {
          fontSize: '2xl',
        },
        md: {
          fontSize: 'lg',
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'text.default',
        fontSize: 'md',
      },
      variants: {
        xsLabel: {
          color: 'slate.500',
          fontSize: 'xs',
          lineHeight: 0.9,
        },
      },
    },
    NumberText: numberTextTheme,
    Button: {
      defaultProps: {
        colorScheme: 'primaryAlt',
      },
      variants: {
        outline: {
          color: 'primaryAlt.500',
          borderColor: 'primaryAlt.500',
        },
        ghost: {
          color: 'primaryAlt.500',
        },
        attention: {
          color: 'red.500',
          bg: 'red.50',
          border: '1px',
          borderColor: 'red.500',
          borderRadius: 'base',
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          color: 'text.light',
          _selected: {
            fontWeight: 'bold',
          },
        },
      },
      variants: {
        switch: {
          tablist: {
            borderRadius: 'full',
            display: 'inline-flex',
            border: '1px solid',
            borderColor: 'gray.300',
            p: 1,
          },
          tab: {
            color: 'slate.300',
            borderRadius: 'full',
            py: 1,
            _selected: {
              color: 'primary.600',
              bg: 'primary.100',
              border: '1px solid',
              borderColor: 'primary.600',
            },
          },
          tabpanels: {
            mt: 4,
          },
          tabpanel: {
            p: 0,
          },
        },
      },
    },
    Checkbox: checkboxTheme,
    Tooltip: {
      baseStyle: {
        color: 'text.default',
        [cssVar('popper-arrow-bg').variable]: 'colors.white',
        bg: 'white',
      },
    },
    Table: {
      baseStyle: {
        tbody: {
          tr: {
            _hover: {
              backgroundColor: 'hoveredTableRow.default',
            },
            '&[data-ischecked="true"]': {
              backgroundColor: 'primary.50',
              _hover: {
                backgroundColor: 'hoveredTableRow.isChecked',
              },
            },
          },
        },
      },
      sizes: {
        sm: {
          th: {
            fontSize: 'xs',
            p: 2,
            fontWeight: 'normal',
          },
          td: {
            fontSize: 'sm',
            p: 2,
            verticalAlign: 'baseline',
          },
        },
      },
      variants: {
        simple: {
          table: {
            tableLayout: 'fixed',
          },
          th: {
            color: 'slate.500',
            borderColor: 'gray.200',
          },
          td: {
            borderColor: 'gray.200',
          },
        },
        adjustStock: {
          th: {
            pl: 0,
            pr: 4,
            pt: 0,
            pb: 1,
            fontSize: 'sm',
          },
          td: {
            p: 0,
            pr: 4,
            verticalAlign: 'baseline',
            fontSize: 'sm',
          },
        },
        resolveError: {
          table: {
            border: '1px solid',
            borderColor: 'gray.200',
            tableLayout: 'fixed',
          },
          thead: {
            border: '1px solid',
            borderColor: 'gray.200',
            bg: 'gray.50',
          },
          th: {
            _first: {
              pl: 4,
            },
            _last: {
              pr: 4,
            },
          },
          td: {
            _first: {
              pl: 4,
            },
            _last: {
              pr: 4,
            },
          },
        },
      },
    },
    Fade: {
      defaultProps: {
        enter: {
          duration: 0.3,
        },
        exit: {
          duration: 0.3,
        },
      },
    },
    // SuccessToastの背景色を上書き
    // https://stackoverflow.com/questions/69531448/how-to-change-the-background-color-of-the-chakra-ui-toast-component
    Alert: {
      variants: {
        solid: (props: StyleFunctionProps) => {
          const { colorScheme } = props;
          if (colorScheme !== 'green') {
            return defaultTheme.components.Alert.variants?.solid(props);
          }
          return {
            container: {
              bg: '#4BB24F',
            },
          };
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: 'gray.300',
            _invalid: {
              bg: 'red.50',
              borderColor: 'red.500',
            },
          },
        },
      },
    },
    // ref: https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/form-label.ts
    FormLabel: {
      baseStyle: {
        mb: 1,
      },
    },
    Popover: popoverTheme,
    Typography: typographyTheme,
  },
});

export type Theme = typeof theme;
