import { useTheme } from '@chakra-ui/react';
import { GroupBase, StylesConfig } from 'react-select';
import { Theme } from '../../../../theme';
import { OptionType } from '../types';

export function useSelectStyles(): StylesConfig<OptionType, boolean, GroupBase<OptionType>> {
  const theme = useTheme<Theme>();

  return {
    control: (base) => ({
      ...base,
      backgroundColor: theme.colors.white,
      borderWidth: '1px',
      borderColor: theme.colors.gray[300],
      borderRadius: theme.radii.base,
      cursor: 'pointer',
      minHeight: '32px',
    }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: 'nowrap',
      paddingLeft: theme.space[2],
      paddingRight: theme.space[2],
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      width: 'auto',
    }),
    menu: (base) => ({
      ...base,
      width: 'auto',
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: theme.colors.white,
      padding: theme.space[3],
      borderRadius: theme.radii.base,
      boxShadow: theme.shadows.md,
      maxHeight: '500px',
    }),
    option: (base) => ({
      ...base,
      lineHeight: '14px',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '& ~ &': {
        marginTop: theme.space[4],
      },
    }),
    group: (base) => ({
      ...base,
      '& ~ &': {
        '&::before': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '1px',
          backgroundColor: theme.colors.gray[200],
          margin: `${theme.space[4]} 0`,
        },
      },
    }),
  };
}
