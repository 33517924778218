import { createIcon } from '@chakra-ui/react';

export const ClosedMedicineBoxIcon = createIcon({
  displayName: 'ClosedMedicineBoxIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00001 4H18L20 9V22H4V9L6.00001 4ZM16.6459 6L17.4459 8H16H8H6.55407L7.35407 6H16.6459ZM8 10H6V20H18V10H16C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10ZM14 10H10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10Z"
      fill="currentColor"
    />
  ),
});
