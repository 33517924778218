import { Typography } from '@kkhs/hakari-ui';
import { useRouter } from 'next/router';
import { ORDER_PATH } from '@/shared/constants';

export function PrimaryMedicinePackageUnitFormAlert() {
  const { pathname } = useRouter();
  // NOTE: 発注画面の場合のみ、アラートテキストを表示する
  if (pathname !== ORDER_PATH) {
    return null;
  }
  return (
    <Typography
      variant="body"
      color="red.500"
      date-testid="primary-medicine-package-unit-from-alert"
    >
      変更内容は、既に発注画面に表示されている規格容量には反映されません。
      <br />
      発注時に、「更新する」ボタンを押すか規格容量を手動で変更してください。
    </Typography>
  );
}
