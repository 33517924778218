import { Img, Stack, VStack } from '@chakra-ui/react';
import { Link, Typography } from '@kkhs/hakari-ui';

export function Maintenance() {
  return (
    <Stack bg="base.default" minH="100vh" alignItems="center" justifyContent="center">
      <VStack
        bg="white"
        spacing={12}
        width="xl"
        alignItems="center"
        borderRadius="base"
        overflow="hidden"
        px={10}
        py={20}
      >
        <Img src="/assets/svg/hakari-logo.svg" alt="Musubi AI在庫管理" h="56px" w="182px" />
        <VStack spacing={6}>
          <Typography variant="h2">システムメンテナンス中です</Typography>
          <Typography variant="body" align="center" lineHeight={6}>
            現在システムメンテナンスを行っているため、利用を停止しています。
            <br />
            ご不便をおかけいたしますが、ご理解の程よろしくお願い申し上げます。
          </Typography>
          <Typography variant="body" align="center" lineHeight={6}>
            詳しい情報やお問い合わせは
            <Link
              href="https://support.musubi.app/hc/ja/articles/26904844251033"
              target="_blank"
              rel="noopener"
              color="primary.600"
              fontWeight="bold"
              textDecoration="underline"
            >
              こちら
            </Link>
            をご確認下さい。
          </Typography>
        </VStack>
      </VStack>
    </Stack>
  );
}
