import { createIcon } from '@chakra-ui/react';

export const InjectionIcon = createIcon({
  displayName: 'InjectionIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 1.25a.75.75 0 01.75.75v8.38h.01c.4 0 .79.136 1.083.43.293.292.429.682.429 1.081v2.294h.01c.4 0 .79.136 1.083.428.292.293.429.684.429 1.082V27.88h.771a.75.75 0 110 1.5h-9.13a.75.75 0 010-1.5h.771V15.695c0-.398.137-.789.43-1.082a1.509 1.509 0 011.081-.428h.011V11.89c0-.399.136-.789.43-1.082a1.509 1.509 0 011.081-.429h.011V2a.75.75 0 01.75-.75zm-1.537 14.435H22.293V27.88h-4.587v-2.304h1.533a.75.75 0 000-1.5h-1.533v-1.543h1.533a.75.75 0 000-1.5h-1.533v-1.544h1.533a.75.75 0 000-1.5h-1.533v-2.304h.757zm2.309-1.5h-1.544V11.88h1.543v2.304zm-1.544 16.728a.75.75 0 00-1.5 0v5.337h-1.532a.75.75 0 000 1.5h7.608a.75.75 0 000-1.5h-1.532v-5.337a.75.75 0 00-1.5 0v5.337h-1.544v-5.337z"
      fill="currentColor"
    />
  ),
});
