import { createIcon } from '@chakra-ui/react';

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6V0H6V6H0V10H6V16H10V10H16V6H10Z"
      fill="currentColor"
    />
  ),
});
