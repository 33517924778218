import { createIcon } from '@chakra-ui/react';

export const StockTransferExportIcon = createIcon({
  displayName: 'StockTransferExportIcon',
  viewBox: '0 0 48 48',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9999 20V27.9999L12 27.9999L12 8H35.9999V16L39.9998 20H35.9999ZM21.9999 23.9999H25.9999L25.9999 19.9999H29.9999V16L25.9999 16V12L21.9999 12V16H17.9999V19.9999H21.9999V23.9999ZM12 20V16L8 20H12ZM12 39.9999V20H8V39.9999H12ZM30 36L27 32.9999L29.9999 29.9999L36 36L40.0002 40.0001H33.9999L33.9999 40L16 40L16 36L30 36ZM39.9999 20V31.9999H35.9999V20H39.9999Z"
      fill="currentColor"
    />
  ),
});
