/* eslint-disable no-underscore-dangle,react/no-array-index-key */
import { Combobox, Transition } from '@headlessui/react';
import * as Portal from '@radix-ui/react-portal';
import {
  Fragment,
  ElementRef,
  forwardRef,
  useState,
  MouseEvent,
  ReactNode,
  Ref,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useGetStyles } from './hooks';
import {
  root,
  button,
  input,
  clearIndicator,
  dropdownIndicator,
  textWrapper,
  displayValue,
  displayValueText,
  options as optionsClass,
  optionText,
  option as optionClass,
  checkIcon,
  noOption,
  spinner,
} from './index.css';
import { SpinnerV2 } from '../SpinnerV2';
import { CheckIcon, ChevronDownIcon, CloseIcon } from '../icons';

type SelectV2Option<T = string> = {
  value: T;
  label: string;
};

export type SelectV2Props<T = string> = {
  size?: 'md' | 'sm' | 'xs';
  options?: SelectV2Option<T>[];
  defaultValue?: SelectV2Option<T>;
  placeholder?: string;
  noOptionsMessage?: string;
  disabled?: boolean;
  isWordWrapped?: boolean;
  hiddenDropdownIndicator?: boolean;
  isInvalid?: boolean;
  isNumeric?: boolean;
  hasChanged?: boolean;
  isDynamicOptionWidth?: boolean;
  isLoading?: boolean;
  zIndex?: number;
  renderSingleValueNotice?: (data: SelectV2Option<T>) => ReactNode;
  renderOptionNotice?: (data: SelectV2Option<T>) => ReactNode;
  onMouseOver?: () => void;
  onChange: (data: SelectV2Option<T>['value']) => void;
  onClear?: () => void;
  onCreate?: (newValue: string) => void;
  loadOptions?: (inputValue: string) => Promise<SelectV2Option<T>[]>;
};

/**
 * API Reference
 * https://headlessui.com/react/combobox#component-api
 */

function _Select<T = string>(
  {
    size = 'md',
    options: defaultOptions,
    defaultValue,
    placeholder = '選択してください',
    noOptionsMessage = '選択肢がありません',
    disabled = false,
    isWordWrapped = false,
    hiddenDropdownIndicator = false,
    isInvalid = false,
    isNumeric = false,
    hasChanged = false,
    isDynamicOptionWidth = false,
    isLoading = false,
    zIndex = 9999,
    renderSingleValueNotice,
    renderOptionNotice,
    onMouseOver,
    onChange,
    onClear,
    onCreate,
    loadOptions,
  }: SelectV2Props<T>,
  ref: Ref<ElementRef<typeof Combobox>>,
) {
  const [selectedValue, setSelectedValue] = useState<SelectV2Option<T> | null>(
    defaultValue ?? null,
  );
  const [query, setQuery] = useState<string>('');
  const loadOptionsOnMount = !!(defaultOptions === undefined && loadOptions);
  const [isLoadingOption, setIsLoadingOption] = useState(loadOptionsOnMount);
  const handleInput = useCallback(
    async (q: string) => {
      setQuery(q);

      if (loadOptions) {
        setIsLoadingOption(true);
        try {
          setOptions(await loadOptions(q));
        } finally {
          setIsLoadingOption(false);
        }
      }
    },
    [loadOptions],
  );

  const [options, setOptions] = useState<SelectV2Option<T>[]>(defaultOptions ?? []);

  useEffect(() => {
    (async () => {
      if (loadOptionsOnMount) {
        try {
          setOptions(await loadOptions(''));
        } finally {
          setIsLoadingOption(false);
        }
      }
    })();
    // NOTE: loadOptionsがメモ化されていないとマウント時（初回表示時）以外で、この処理が実行されてしまうので、それを防ぐために依存配列には含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredOptions = loadOptions
    ? options
    : options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));

  const isCreatable = !!onCreate;
  const handleChange = (v: SelectV2Option<T>) => {
    setSelectedValue(v);
    handleInput('');
    if (v.value !== null) {
      onChange(v.value);
    } else {
      // NOTE: isCreatable で入力値を作成した場合
      onCreate?.(v.label);
    }
  };
  const isClearable = !!onClear;
  const handleClearValue = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setSelectedValue(null);
    handleInput('');
    onClear?.();
  };
  const rootRef = useRef<HTMLDivElement>(null);
  const { styles, update } = useGetStyles({ ref: rootRef, zIndex });

  return (
    <div onMouseOver={onMouseOver} onFocus={onMouseOver} onTouchStart={onMouseOver}>
      <Combobox
        ref={ref}
        value={selectedValue}
        onChange={handleChange}
        disabled={isLoading || disabled}
      >
        {({ open }) => (
          <div ref={rootRef} className={root({ size })}>
            {/*
          ボタンでInputを囲うことで、Inputにフォーカスがある場合にOptionを開ける
          https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969
        */}
            <Combobox.Button
              as="div"
              // Optionが開いている場合は、ボタンを押してもOptionを閉じないようにする
              onClick={(e) => {
                update();
                if (open) {
                  e.preventDefault();
                }
              }}
              className={button({ disabled, hasChanged, isInvalid, isLoading })}
            >
              <div className={textWrapper}>
                <div
                  className={displayValue({
                    size,
                    isPlaceholder: !selectedValue,
                    isWordWrapped,
                    isNumeric,
                  })}
                  data-testid="select-display-value"
                >
                  {selectedValue && query.length === 0 ? (
                    <div className={displayValueText}>{selectedValue.label}</div>
                  ) : (
                    placeholder
                  )}
                  {renderSingleValueNotice && selectedValue && query.length === 0 && (
                    <div>{renderSingleValueNotice(selectedValue)}</div>
                  )}
                </div>
                <Combobox.Input
                  className={input({
                    size,
                    isInputting: query.length > 0,
                    isInvalidWithInputting: isInvalid && query.length > 0,
                  })}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e.target.value)}
                />
              </div>
              {isClearable && selectedValue && (
                <CloseIcon
                  onClick={handleClearValue}
                  className={clearIndicator({ size })}
                  data-testid="select-clear-button"
                />
              )}
              {!hiddenDropdownIndicator && (
                <ChevronDownIcon className={dropdownIndicator({ size })} />
              )}
              {isLoading && (
                <div className={spinner}>
                  <SpinnerV2 size={size} data-testid="select-spinner" />
                </div>
              )}
            </Combobox.Button>
            <Portal.Root>
              <Transition as={Fragment} afterLeave={() => setQuery('')}>
                <Combobox.Options
                  style={styles}
                  className={optionsClass({ size, isDynamicOptionWidth })}
                >
                  {isCreatable &&
                    query.length > 0 &&
                    !options.some((option) => option.label === query) && (
                      <Combobox.Option value={{ value: null, label: query }}>
                        {({ selected }) => (
                          <div
                            className={optionClass({ isSelected: selected })}
                            data-testid="create-value-option"
                          >
                            <div className={checkIcon({ size })}>{selected && <CheckIcon />}</div>
                            &quot;{query}&quot; を作成
                          </div>
                        )}
                      </Combobox.Option>
                    )}
                  {(() => {
                    if (isLoadingOption) {
                      return <div className={noOption({ size })}>Loading...</div>;
                    }

                    if (
                      (filteredOptions.length === 0 && !isCreatable) ||
                      (filteredOptions.length === 0 && isCreatable && query === '')
                    ) {
                      return <div className={noOption({ size })}>{noOptionsMessage}</div>;
                    }

                    return (
                      // NOTE: 本来避けたいが、optionに何が入ってくるかわからないのでkeyにindexを使う
                      filteredOptions.map((option, i) => (
                        <Combobox.Option key={i} value={option}>
                          {({ selected }) => {
                            // NOTE: 初期レンダリング時の selected の判定が提供されていないので、defaultValue を設定した際の判定を自前で行っている
                            const defaultSelected = selectedValue?.value === option.value;
                            const isSelected = defaultSelected || selected;
                            return (
                              <div className={optionClass({ isSelected, isNumeric })}>
                                <div className={checkIcon({ size })}>
                                  {isSelected && <CheckIcon />}
                                </div>
                                <div className={optionText({ isDynamicOptionWidth })}>
                                  {option.label}
                                  {renderOptionNotice && <div>{renderOptionNotice(option)}</div>}
                                </div>
                              </div>
                            );
                          }}
                        </Combobox.Option>
                      ))
                    );
                  })()}
                </Combobox.Options>
              </Transition>
            </Portal.Root>
          </div>
        )}
      </Combobox>
    </div>
  );
}

/**
 * MEMO: 使う側で value を状態管理する際に key を指定しないと再描画されない
 */
export const SelectV2 = forwardRef(_Select) as <T = string>(
  props: SelectV2Props<T> & { ref?: Ref<ElementRef<typeof Combobox>> },
) => JSX.Element;
