import { z } from 'zod';
import { MAX_LENGTH_MAPPING_YJ_CODE, MIN_LENGTH_MAPPING_YJ_CODE } from '@/entities/medicalMaterial';

export const schema = z.object({
  /** 採用中 または 採用停止 */
  adoptionStopped: z.union([z.literal('true'), z.literal('false')]),
  /** 店舗設定卸 */
  wholesaleId: z.string().optional(),
  /** 連携用YJコード */
  mappingYjCode: z
    .string()
    .trim()
    .min(MIN_LENGTH_MAPPING_YJ_CODE, `${MIN_LENGTH_MAPPING_YJ_CODE}桁以上で入力してください`)
    .max(MAX_LENGTH_MAPPING_YJ_CODE, `${MAX_LENGTH_MAPPING_YJ_CODE}桁以内で入力してください`)
    .regex(/^[0-9a-zA-Z]*$/, '半角英数で入力してください'),
  /** 優先規格容量 */
  medicinePackageUnitId: z.string(),
  /** 置き場所 */
  storages: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
  ),
  /** おすすめ発注の非表示期間 */
  orderRecommendSuspendTerm: z
    .object({
      isInf: z.boolean(),
      startDate: z.date(),
      // NOTE: isInf: true の場合は、endDate は undefined
      endDate: z.date().optional(),
    })
    .optional(),
  /** 発注点設定 */
  orderPointSetting: z
    .object({
      mode: z.union([z.literal('auto'), z.literal('fixed'), z.literal('factor')]),
      fixedValue: z.number().nullable(),
      factor: z.number().nullable(),
    })
    .refine(({ mode, fixedValue, factor }) => {
      // mode が fixed, factor の場合は入力値が null の場合は invalid にする
      if (mode === 'fixed') {
        return fixedValue !== null && fixedValue >= 0 && fixedValue <= 999999.9;
      }
      if (mode === 'factor') {
        return factor !== null && factor >= 0 && factor <= 9.9;
      }
      return true;
    }),
});

export type FieldValues = z.infer<typeof schema>;
