import ReactSelect, { GroupBase } from 'react-select';
import { DropdownIndicator } from './DropdownIndicator';
import { Option } from './Option';
import { Placeholder } from './Placeholder';
import { ValueContainer } from './ValueContainer';
import { OptionType } from './types';
import { useSelectStyles } from './useSelectStyles';

type Props = {
  /**
   * 選択肢
   */
  options: OptionType[] | GroupBase<OptionType>[];
  /**
   * 選択肢を変更した際のコールバック関数
   */
  onChange: (value: OptionType[]) => void;
  /**
   * 選択肢を変更した際のコールバック関数
   */
  value?: OptionType[];
};

/**
 * 複数チェックが可能なセレクトボックス
 */
export function CheckableSelect({ options, onChange, value }: Props) {
  const styles = useSelectStyles();

  return (
    <ReactSelect<OptionType, boolean, GroupBase<OptionType>>
      value={value}
      placeholder={<Placeholder />}
      components={{
        Option,
        ValueContainer,
        DropdownIndicator,
      }}
      options={options}
      onChange={(data) => {
        if (!Array.isArray(data)) {
          throw new Error('data is not array');
        }

        onChange(data);
      }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isSearchable={false}
      isClearable={false}
      styles={styles}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      unstyled
      isMulti
    />
  );
}
