import { createIcon } from '@chakra-ui/react';

export const PowderSachetIcon = createIcon({
  displayName: 'PowderSachetIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.668 7.66a.279.279 0 00-.068.186v24.08c0 .171.115.245.204.245h18.392c.023 0 .074-.013.127-.068a.267.267 0 00.077-.178V7.845c0-.171-.115-.245-.204-.245H10.804a.174.174 0 00-.136.06zM9.4 7.845c0-.726.549-1.446 1.404-1.446h18.392c.763 0 1.404.623 1.404 1.446v24.08c0 .75-.619 1.445-1.404 1.445H10.804c-.763 0-1.404-.623-1.404-1.446V7.845zm4.863 3.386c-.752 0-1.44.673-1.44 1.639V18.1l8.762-1.032a.6.6 0 01.16.002l5.433.822V12.87c0-.966-.69-1.64-1.441-1.64H14.263zm14.115 7.344V12.87c0-1.521-1.125-2.838-2.641-2.838H14.263c-1.516 0-2.64 1.317-2.64 2.838v15.075c0 1.522 1.124 2.839 2.64 2.839h11.474c1.516 0 2.64-1.317 2.64-2.839v-9.343a.56.56 0 000-.026zm-1.2.53l-5.533-.837-8.823 1.04v8.636c0 .966.69 1.639 1.44 1.639h11.475c.751 0 1.44-.673 1.44-1.639v-8.84z"
      fill="currentColor"
    />
  ),
});
