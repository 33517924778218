import { createIcon } from '@chakra-ui/react';

export const AttentionLabelIcon = createIcon({
  displayName: 'AttentionLabelIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99951 3.00001C2.03829 2.49588 2.50865 2.02945 2.99956 2L11.9995 2.00003L21.4999 11.5C22.2809 12.2811 22.2809 13.719 21.4999 14.5L14.4999 21.5C13.7188 22.2811 12.2809 22.2811 11.4999 21.5L1.99952 12L1.99951 3.00001ZM3.99948 11L9.49988 16.5002L16.4999 9.50018L10.9995 4L3.99948 4.00003L3.99948 11ZM12.9999 20L10.9999 18.0001L17.9999 11.0002L19.9999 13L12.9999 20ZM7.49963 9.00006C8.32806 9.00006 8.99963 8.32849 8.99963 7.50006C8.99963 6.67163 8.32806 6.00006 7.49963 6.00006C6.67121 6.00006 5.99963 6.67163 5.99963 7.50006C5.99963 8.32849 6.67121 9.00006 7.49963 9.00006Z"
        fill="currentColor"
      />
    </svg>
  ),
});
