import { createIcon } from '@chakra-ui/react';

export const TransitionalMeasureExpiredAttentionIcon = createIcon({
  displayName: 'TransitionalMeasureExpiredAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M5.75999 9.22C5.61999 10.16 5.38 11.12 5.05 11.76C4.93 11.67 4.63 11.53 4.47 11.48C4.8 10.87 5.01 9.98 5.12 9.1L5.75999 9.22ZM6.85001 8.42V12.58H6.19V8.46C5.62 8.49 5.08 8.52 4.62 8.55L4.56 7.89L5.22 7.86C5.43 7.59 5.66 7.27 5.88 6.94C5.57 6.5 5.05 5.94 4.59 5.53L4.97 5.04C5.07 5.12 5.15999 5.21 5.25999 5.3C5.59999 4.74 5.95 3.99 6.16 3.44L6.81 3.7C6.47 4.36 6.04999 5.15 5.67999 5.71C5.89999 5.93 6.09999 6.16 6.25999 6.36C6.64999 5.74 7.01 5.1 7.27 4.57L7.89999 4.85C7.32999 5.8 6.57 6.97 5.91 7.83L7.39 7.77C7.25 7.46 7.09 7.14 6.92 6.87L7.44 6.66C7.85 7.3 8.25 8.16 8.38 8.7L7.82001 8.95C7.78001 8.78 7.72 8.58 7.64 8.37L6.85001 8.42ZM7.72 9.03C7.99 9.6 8.26 10.36 8.36 10.85L7.78999 11.04C7.68999 10.54 7.43999 9.79 7.17999 9.21L7.72 9.03ZM11.36 11.6H13.77V12.27H8.09V11.6H10.63V9.97H8.73V9.3H10.63V7.86H11.36V9.3H13.33V9.97H11.36V11.6ZM13.33 4.14C12.94 5.23 12.32 6.09 11.54 6.77C12.21 7.22 13 7.55 13.88 7.74C13.73 7.9 13.53 8.17 13.42 8.36C12.5 8.11 11.67 7.73 10.97 7.21C10.25 7.72 9.42 8.1 8.56 8.38C8.46 8.19 8.28 7.92 8.13 7.77C8.94 7.54 9.71999 7.2 10.4 6.74C9.80999 6.18 9.33 5.51 8.98 4.74L9.38 4.61H8.35001V3.95H12.69L12.83 3.92L13.33 4.14ZM9.67 4.61C9.97 5.26 10.41 5.84 10.96 6.32C11.52 5.84 11.99 5.27 12.32 4.61H9.67Z"
        fill="#5D6771"
      />
      <path
        d="M16.85 10.56C17.32 11.3 18.2 11.64 19.37 11.68C20.47 11.73 22.77 11.7 23.97 11.62C23.88 11.79 23.76 12.13 23.73 12.35C22.61 12.4 20.5 12.42 19.38 12.37C18.07 12.32 17.17 11.97 16.55 11.19C16.11 11.62 15.65 12.04 15.16 12.47L14.77 11.76C15.19 11.45 15.7 11.03 16.15 10.61V8.04999H14.88V7.34999H16.85V10.56ZM16.55 5.76999C16.25 5.26999 15.58 4.57999 14.97 4.09999L15.54 3.67999C16.15 4.13999 16.84 4.81999 17.15 5.30999L16.55 5.76999ZM18.47 11.16H17.81V6.85999H18.59V3.78999H22.68V6.85999H23.45V10.44C23.45 10.78 23.39 10.96 23.14 11.06C22.9 11.17 22.52 11.17 21.92 11.17C21.89 11 21.8 10.74 21.72 10.57C22.14 10.57 22.51 10.57 22.61 10.57C22.73 10.57 22.77 10.52 22.77 10.43V7.43999H18.47V11.16ZM19.24 6.84999H20.22V5.16999H22.01V4.35999H19.24V6.84999ZM21.9 10.19H19.89V10.58H19.31V8.07999H21.9V10.19ZM21.32 8.60999H19.89V9.66999H21.32V8.60999ZM22.01 6.84999V5.68999H20.78V6.84999H22.01Z"
        fill="#5D6771"
      />
      <path
        d="M7.32999 23.27C7.92999 23.51 8.69999 23.59 9.68999 23.59C10.18 23.6 13.22 23.6 13.84 23.57C13.71 23.75 13.58 24.09 13.53 24.31H9.70999C7.57999 24.31 6.39998 23.98 5.66998 22.62C5.54998 23.38 5.37999 24.04 5.07999 24.58C4.95999 24.49 4.65999 24.3 4.48999 24.22C5.05999 23.22 5.15998 21.66 5.17998 19.91L5.85999 19.95C5.84999 20.44 5.82999 20.92 5.79999 21.37C6.00999 22.03 6.27999 22.51 6.65999 22.86V19.19H4.73999V18.53H6.51999V17.3H4.92998V16.64H6.51999V15.44H7.20999V16.64H8.76999V17.3H7.20999V18.53H8.96999V18.96C9.85999 18.45 10.33 17.67 10.47 16.49H9.07999V15.85H13.45C13.45 15.85 13.45 16.06 13.44 16.16C13.35 17.94 13.26 18.64 13.04 18.89C12.89 19.06 12.71 19.12 12.46 19.14C12.23 19.17 11.81 19.16 11.37 19.14C11.35 18.94 11.28 18.68 11.18 18.5C11.59 18.54 11.98 18.54 12.13 18.54C12.27 18.54 12.37 18.54 12.43 18.45C12.56 18.3 12.65 17.79 12.72 16.49H11.19C10.99 17.98 10.37 18.91 9.29999 19.54C9.21999 19.45 9.04998 19.3 8.89998 19.19H7.32999V20.61H8.87999V21.27H7.32999V23.27ZM9.40999 19.69H13.22V22.77H9.40999V19.69ZM10.11 22.15H12.49V20.31H10.11V22.15Z"
        fill="#5D6771"
      />
      <path
        d="M16.85 22.56C17.32 23.3 18.2 23.64 19.37 23.68C20.47 23.73 22.77 23.7 23.97 23.62C23.88 23.79 23.76 24.13 23.73 24.35C22.61 24.4 20.5 24.42 19.38 24.37C18.07 24.32 17.17 23.97 16.55 23.19C16.11 23.62 15.65 24.04 15.16 24.47L14.77 23.76C15.19 23.45 15.7 23.03 16.15 22.61V20.05H14.88V19.35H16.85V22.56ZM16.55 17.77C16.25 17.27 15.58 16.58 14.97 16.1L15.54 15.68C16.15 16.14 16.84 16.82 17.15 17.31L16.55 17.77ZM18.47 23.16H17.81V18.85H18.59V15.78H22.68V18.85H23.45V22.43C23.45 22.77 23.39 22.95 23.14 23.05C22.9 23.16 22.52 23.16 21.92 23.16C21.89 22.99 21.8 22.73 21.72 22.56C22.14 22.56 22.51 22.56 22.61 22.56C22.73 22.56 22.77 22.51 22.77 22.42V19.43H18.47V23.15V23.16ZM19.24 18.85H20.22V17.17H22.01V16.36H19.24V18.85ZM21.9 22.19H19.89V22.58H19.31V20.08H21.9V22.19ZM21.32 20.61H19.89V21.67H21.32V20.61ZM22.01 18.85V17.69H20.78V18.85H22.01Z"
        fill="#5D6771"
      />
    </g>
  ),
});
