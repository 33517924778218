import { jstNow } from '@kkhs/hakari-utils';
import { captureMessage } from '@sentry/nextjs';
import calver from 'calver';
import { addDays, format } from 'date-fns';

export class Calver {
  isValid: boolean;

  version: string;

  calendar = 0;

  minor = 0;

  readonly format: string = 'yyyy.0m.0d.minor';

  constructor(version: string) {
    const splittedVersion = version.split('.');
    this.isValid =
      calver.isValid(this.format, version) &&
      // minorがなくてもパースされてしまうため、lengthが4以上かをチェックする
      splittedVersion.length >= 4;
    this.version = version;

    if (this.isValid) {
      /**
       * NOTE: 下記処理では、次の部分を取得しています。
       *  this.calendar: 2023.02.01.0 -> 20230201 (日付部分)
       *  this.minor: 2023.02.01.0 -> 0 (minor バージョン部分)
       */
      this.calendar = parseInt(splittedVersion.slice(0, 3).join(''), 10);
      this.minor = parseInt(splittedVersion.slice(3, 4).join(''), 10);
    }
  }

  isLaterThan(calVer: Calver): boolean {
    if (!(this.isValid && calVer.isValid)) {
      if (this.version !== undefined && calVer.version !== undefined) {
        captureMessage(`Wrong format version found: ${this.version}, ${calVer.version}`);
      }
      return false;
    }

    if (this.calendar > calVer.calendar) {
      return true;
    }
    if (calVer.calendar === this.calendar) {
      return this.minor > calVer.minor;
    }
    return false;
  }

  isFuture(): boolean {
    const tomorrow = addDays(jstNow(), 1);
    const tomorrowCalendar = parseInt(format(tomorrow, 'yyyyMMdd'), 10);
    return this.calendar >= tomorrowCalendar;
  }
}
