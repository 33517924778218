import { useRouter } from 'next/router';
import { useCallback } from 'react';

export type Query = {
  [key: string]: string | number | null;
};

export const useUrlQuery = () => {
  const { query, replace: routerReplace, push: routerPush } = useRouter();

  const replace = useCallback(
    (newQuery: Query) => {
      routerReplace(
        {
          query: newQuery,
        },
        undefined,
        { shallow: true, scroll: false },
      );
    },
    [routerReplace],
  );
  const push = useCallback(
    (newQuery: Query) => {
      routerPush(
        {
          query: newQuery,
        },
        undefined,
        { shallow: true, scroll: false },
      );
    },
    [routerPush],
  );

  return { query, replace, push };
};
