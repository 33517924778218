import { atom } from 'recoil';
import { recoilAtomKeys } from '@/shared/constants';
import { localStorageEffect } from '@/shared/lib/recoil';
import { CurrentPharmacy } from './types';

export type CurrentPharmacyState = CurrentPharmacy | undefined;

export const currentPharmacyStateAtom = atom<CurrentPharmacyState>({
  key: recoilAtomKeys.CURRENT_PHARMACY_STATE,
  default: undefined,
  effects_UNSTABLE: [
    localStorageEffect<CurrentPharmacyState>(recoilAtomKeys.CURRENT_PHARMACY_STATE),
  ],
});
