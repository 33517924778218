/**
 * recoil がセットするローカルストレージの値を削除する
 */
export const removeLocalStorageItems = () => {
  Object.keys(localStorage).forEach((key) => {
    // TODO: recoilAtomKeys, recoilSelectorKeys を廃止できたら、
    //       以下コメントアウトした箇所の正規表現を利用する
    // if (/^(atom|selector)\/@kkhs\/hakari-web-app\//.test(key)) {
    if (/@kkhs\/hakari-web-app\//.test(key)) {
      localStorage.removeItem(key);
    }
  });
};
