import { createIcon } from '@chakra-ui/react';

export const ExpensiveAttentionIcon = createIcon({
  displayName: 'ExpensiveAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M13.1 10.36V11.01H4.48001V10.36H8.35999V9.41H9.10999V10.36H13.1ZM12.72 17.66C12.72 18.06 12.63 18.28 12.32 18.41C11.99 18.52 11.47 18.52 10.64 18.52C10.6 18.32 10.5 18.02 10.4 17.83C11.04 17.85 11.6 17.85 11.78 17.83C11.95 17.83 11.98 17.78 11.98 17.64V14.87H5.63V18.54H4.92001V14.24H12.72V17.66ZM11.47 11.57V13.61H6.12V11.57H11.47ZM10.73 12.1H6.82999V13.07H10.73V12.1ZM7.56 17.62V18.12H6.89999V15.51H10.68V17.62H7.56ZM7.56 16.05V17.07H10.02V16.05H7.56Z"
        fill="#5D6771"
      />
      <path
        d="M15.99 17.89V18.36H15.35V15.6C15.19 15.69 15.04 15.76 14.88 15.83C14.79 15.67 14.61 15.43 14.48 15.3C15.22 15.02 15.97 14.54 16.59 13.93C16.27 13.7 15.95 13.47 15.66 13.26C15.45 13.47 15.24 13.66 15.03 13.83C14.92 13.71 14.67 13.49 14.53 13.39C15.28 12.86 15.97 12.04 16.34 11.16L16.97 11.32C16.88 11.52 16.78 11.71 16.67 11.9H17.96L18.07 11.87L18.5 12.06C18.27 12.73 17.93 13.32 17.51 13.84C18.1 14.28 18.65 14.71 19 15.05L18.56 15.57C18.49 15.5 18.4 15.42 18.31 15.33V17.89H15.99ZM15.42 11.74H14.8V10.31H16.5V9.42001H17.19V10.31H18.89V11.74H18.25V10.91H15.42V11.74ZM18.25 15.28C17.93 14.99 17.52 14.66 17.09 14.32C16.73 14.69 16.32 15.01 15.91 15.28H18.25ZM17.65 15.87H15.99V17.29H17.65V15.87ZM16.33 12.44C16.24 12.56 16.15 12.69 16.05 12.8C16.36 13.01 16.68 13.24 17.01 13.47C17.27 13.15 17.49 12.81 17.66 12.44H16.33ZM20.86 17.21C20.36 17.72 19.51 18.23 18.79 18.51C18.67 18.38 18.46 18.16 18.3 18.02C19.02 17.76 19.84 17.27 20.22 16.84L20.86 17.21ZM20.69 11.47C20.77 11.17 20.85 10.83 20.9 10.52H19.01V9.87001H23.63V10.52H21.7C21.6 10.84 21.5 11.18 21.4 11.47H23.37V16.64H19.39V11.47H20.69ZM22.67 12.06H20.06V13.02H22.67V12.06ZM22.67 13.56H20.06V14.52H22.67V13.56ZM22.67 15.08H20.06V16.05H22.67V15.08ZM22.23 16.88C22.81 17.24 23.55 17.79 23.92 18.16L23.35 18.55C22.99 18.19 22.26 17.62 21.67 17.24L22.23 16.88Z"
        fill="#5D6771"
      />
    </g>
  ),
});
