import { useRecoilValue } from 'recoil';
import { organizationGroupsSelector } from '../organizationGroupsSelector';

export const useOrganizationGroups = () => {
  const organizationGroups = useRecoilValue(organizationGroupsSelector);
  const isOrganizationGroupsAdmin = organizationGroups && organizationGroups.length > 0;
  return {
    organizationGroups,
    isOrganizationGroupsAdmin,
  };
};
