var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"qIKeJ7YFcVCeALRGEsSPR"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = 'https://55ca4433fee4471d91dbb2214f02706d@o476423.ingest.sentry.io/5524756';

Sentry.init({
  dsn: ['dev', 'stg', 'onb', 'demo', 'prod'].includes(process.env.APP_ENV) ? SENTRY_DSN : '',
  integrations: [],
  environment: process.env.APP_ENV ?? 'local',
});
