import { createContext } from 'react';

type AlertDialogContextType = {
  /** Alert を表示すべきかどうか */
  shouldAlert: boolean;
  /** shouldAlert を更新する関数 */
  setShouldAlert?: (nextValue: boolean) => void;
  /** AlertDialog を閉じる関数 */
  handleClose?: () => void;
};

const defaultValue = {
  shouldAlert: false,
};

export const AlertDialogContext = createContext<AlertDialogContextType>(defaultValue);
