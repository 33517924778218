import { Stack, FormLabel } from '@chakra-ui/react';
import { Typography, InfoIcon, InfoPopover } from '@kkhs/hakari-ui';
import { DemandTakeoverToMedicineFormContent } from './DemandTakeoverToMedicineFormContent';

type Props = {
  defaultDemandTakeoverMedicineId?: string;
  sourceMedicine: {
    id: string;
    name: string;
    unitOutline: string;
    // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
    shortUnitOutline: string;
  };
};

// TODO: react-hook-form の依存を取り除く
// See: https://github.com/kkhs/hakari-frontend/issues/11110
export function DemandTakeoverToMedicineForm({
  defaultDemandTakeoverMedicineId,
  sourceMedicine,
}: Props) {
  return (
    <Stack spacing={2} align="flex-start">
      <FormLabel htmlFor="wholesaleId" display="flex" alignItems="center">
        <Typography variant="body2" fontWeight="bold">
          おすすめ発注の対象を切り替える
        </Typography>
        <InfoPopover
          w="270px"
          content={
            <Typography variant="body2" color="slate.500">
              同効薬に、需要予測 (おすすめ発注の対象になるための情報)
              を引き継ぐことで、おすすめ発注の対象を切り替えます。
              <br />
              切り替え後は、この医薬品はおすすめ発注の対象外になります。
            </Typography>
          }
        >
          <InfoIcon ml={1} boxSize={3} color="slate.500" />
        </InfoPopover>
      </FormLabel>
      <DemandTakeoverToMedicineFormContent
        defaultDemandTakeoverMedicineId={defaultDemandTakeoverMedicineId}
        sourceMedicine={sourceMedicine}
      />
    </Stack>
  );
}
