import { createIcon } from '@chakra-ui/react';

export const MBQUnitSymbolIcon = createIcon({
  displayName: 'MBQ',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1903 13.1568C13.0652 13.186 12.935 13.2 12.8 13.2C11.47 13.2 10.6 11.84 10.6 8.00005C10.6 4.16005 11.47 2.80005 12.8 2.80005C14.13 2.80005 15 4.16005 15 8.00005C15 10.6464 14.5868 12.1149 13.8946 12.7722L14.2 14H13.4L13.1903 13.1568ZM12.9739 12.2868L12.4 9.97998H13.2L13.66 11.8293C14.071 11.2432 14.3 10.0592 14.3 8.00005C14.3 4.78005 13.74 3.70005 12.8 3.70005C11.86 3.70005 11.3 4.78005 11.3 8.00005C11.3 11.22 11.86 12.3 12.8 12.3C12.8595 12.3 12.9175 12.2957 12.9739 12.2868Z"
        fill="currentColor"
      />
      <path
        d="M2.03 3L3.49 11.53L4.96 3H6V13H5.2V6.11C5.2 5.87 5.21 5.33 5.22 4.9L3.87 13H3.09L1.78 4.93C1.78 4.97204 1.78353 5.15371 1.78763 5.3643C1.79328 5.65458 1.8 5.9999 1.8 6.11V13H1V3H2.03Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81005 7.14001C9.67005 7.44001 9.48005 7.65001 9.24005 7.77001H9.23005C9.53005 7.91001 9.76005 8.13001 9.91005 8.44001C10.1 8.80001 10.19 9.59001 10.19 10.18C10.19 10.94 10.04 11.85 9.75005 12.3C9.45005 12.76 9.01005 13 8.45005 13H6.80005V3.01001H8.30005C9.48005 3.01001 10.07 4.02001 10.07 5.43001C10.07 6.00001 9.98005 6.77001 9.81005 7.14001ZM8.31005 8.19001H7.50005V12.21H8.38005C8.73005 12.21 8.99005 11.98 9.18005 11.7C9.37005 11.41 9.50005 10.74 9.50005 10.15C9.50005 9.56001 9.36005 8.83001 9.15005 8.57001C8.94005 8.31001 8.66005 8.19001 8.31005 8.19001ZM9.08005 4.22001C8.91005 4.00001 8.62005 3.81001 8.23005 3.81001L8.24005 3.80001H7.51005V7.39001H8.27005C9.26005 7.39001 9.37005 6.58001 9.37005 5.48001C9.37005 5.01001 9.25005 4.45001 9.08005 4.22001Z"
        fill="currentColor"
      />
    </svg>
  ),
});
