export const typographyTheme = {
  defaultProps: {
    variant: 'body',
  },
  variants: {
    caption: {
      fontSize: '0.625rem', // 10px
    },
    body2: {
      fontSize: '0.6875rem', // 11px
    },
    body: {
      fontSize: '0.875rem', // 14px
    },
    h3: {
      fontSize: '1rem', // 16px
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.125rem', // 18px
      fontWeight: 'bold',
    },
    h1: {
      fontSize: '1.5rem', // 24px
      fontWeight: 'bold',
    },
  },
};
