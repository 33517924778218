import { CSSObject, Text, TextProps } from '@chakra-ui/react';
import { decimalFormat } from '@kkhs/hakari-utils';
import { useMemo } from 'react';

type Props = {
  /** children */
  children: number;
  /**
   * key: 文字数、value: fontSize の mapping
   * - 指定された key の max 文字数以上の場合は、max 文字数の fontSize が適用される
   * - min 文字数も max 文字数の場合と同様
   */
  mapping: { [count: number]: string };
  variant?: string; // NOTE: 各パッケージで固有のものを定義できるよう string にしておく
  maximumFractionDigits?: 1 | 2 | 3 | 4 | 5; // NOTE: 小数部に使用する最大桁数
} & Omit<TextProps, 'fontSize'>;

/**
 * 文字数に応じたフォントサイズを取得する
 *
 * {桁数: フォントサイズ} の mapping から、value の文字数以下の桁数のうち最大のものを取得する
 * value の文字数以下の桁数が見つからない場合は、最小の桁数のフォントサイズを返す
 *
 * @param value
 * @param conditions {桁数: フォントサイズ}
 * @returns フォントサイズ
 */
function getFontSizeByStringLength(
  value: string,
  conditions: CSSObject, // FIXME: useStyleConfigの返り値にあわせているが、実態は Record<number, string> にしたい
) {
  // MEMO: 条件となる `{桁数: フォントサイズ}[]` の配列を、桁数の大きい順に並び替える
  const entries = Object.entries(conditions).sort((a, b) => Number(b[0]) - Number(a[0]));
  const matchedCondition = entries.find((entry) => value.length >= Number(entry[0]));
  const defaultFontSize = entries[entries.length - 1]?.[1];

  return matchedCondition ? matchedCondition[1] : defaultFontSize;
}

export function DynamicSizedNumberText({
  children,
  mapping: fontSizeMapping,
  maximumFractionDigits = 3,
  ...rest
}: Props) {
  const formattedValue = decimalFormat(children, {
    maximumFractionDigits,
  });
  const fontSize = useMemo(
    () => getFontSizeByStringLength(formattedValue, fontSizeMapping),
    [fontSizeMapping, formattedValue],
  );

  return (
    <Text fontSize={fontSize} {...rest}>
      {formattedValue}
    </Text>
  );
}
