import {
  InternalCounterpartySelectOptionFragment,
  InternalCounterpartySelectOptionsQuery,
} from '@/gql/apollo';

export function assertInternalCounterpartySelectOptionFragment(
  node: InternalCounterpartySelectOptionsQuery['counterparties']['edges'][number]['node'],
): asserts node is InternalCounterpartySelectOptionFragment {
  if (node.__typename === 'InternalCounterparty') return;
  throw new Error(`typename is invalid: ${node.__typename}`);
}
