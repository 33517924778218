import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { graphql } from '@/gql';
import { StoringStockOperationDocument, StoringStockOperationQuery } from '@/gql/apollo';
import { StoringStockOperation } from '../../StoringDialogContent/types';

const priceConfigOnCreatedQueryDoc = graphql(`
  query StoringStockOperationUpdateDialog_Counterparty($counterpartyId: ID!) {
    counterparties(filter: { id: $counterpartyId }, first: 1) {
      edges {
        node {
          ... on ExternalCounterparty {
            id
            name
            stockOperationPriceConfig {
              defaultPriceRate
              shouldShowTax
              taxRoundingMethod
              taxCalculationTarget
              itemRoundingMethod
              totalRoundingMethod
              containerAmount
              feeAmount
              slipTitle
            }
          }
        }
      }
    }
  }
`);

type Args = {
  isOpen: boolean;
  stockOperationId: string;
};

export function useStoringStockOperation({ isOpen, stockOperationId }: Args) {
  const { data, loading } = useQuery<StoringStockOperationQuery>(StoringStockOperationDocument, {
    variables: { stockOperationId },
    fetchPolicy: 'no-cache',
    skip: !isOpen,
  });

  const counterpartyIdOnCreated = useMemo(
    () =>
      data?.stockOperation.__typename === 'StoringStockOperationByExternalTrade' ||
      data?.stockOperation.__typename === 'StoringStockOperationByOther'
        ? data?.stockOperation.counterpartyId
        : undefined,
    [data],
  );

  const { data: priceConfigOnCreatedData, loading: loadingPriceConfig } = useQuery(
    priceConfigOnCreatedQueryDoc,
    {
      variables: { counterpartyId: counterpartyIdOnCreated! },
      skip: !counterpartyIdOnCreated,
    },
  );

  const storingStockOperation: StoringStockOperation | undefined = useMemo(() => {
    // NOTE: 小分け入庫、法人内入庫の場合は、作成時の金額設定は利用しないのでそのまま返す
    if (
      data?.stockOperation.__typename === 'StoringStockOperationBySubdividedPurchase' ||
      data?.stockOperation.__typename === 'StoringStockOperationByTransfer' ||
      data?.stockOperation.__typename === 'StoringStockOperationByTransferRequest'
    ) {
      return data.stockOperation;
    }
    // NOTE: 法人間入庫、その他入庫の場合は、作成時の取引先の金額設定がある場合はを取得する
    if (
      data?.stockOperation.__typename === 'StoringStockOperationByExternalTrade' ||
      data?.stockOperation.__typename === 'StoringStockOperationByOther'
    ) {
      // NOTE: 作成時の取引先（法人間取引先）がある場合は、その金額設定を利用する
      if (counterpartyIdOnCreated) {
        // NOTE: 金額設定の取得前は undefined を返す
        const counterpartyOnCreated =
          priceConfigOnCreatedData?.counterparties.edges[0]?.node?.__typename ===
          'ExternalCounterparty'
            ? priceConfigOnCreatedData.counterparties.edges[0].node
            : undefined;
        if (counterpartyOnCreated && counterpartyOnCreated.stockOperationPriceConfig) {
          return {
            ...data.stockOperation,
            storingOriginOnCreated: {
              origin: 'externalCounterparty',
              counterpartyId: counterpartyOnCreated.id,
              counterpartyName: counterpartyOnCreated.name,
              priceConfig: { type: 'external', ...counterpartyOnCreated.stockOperationPriceConfig },
            },
          } as const;
        }
      }
      if (data.stockOperation.counterpartyName) {
        return {
          ...data.stockOperation,
          storingOriginOnCreated: {
            origin: 'userInput',
            counterpartyId: null,
            counterpartyName: data.stockOperation.counterpartyName,
          },
        } as const;
      }
      return {
        ...data.stockOperation,
        storingOriginOnCreated: null,
      } as const;
    }
    return undefined;
  }, [counterpartyIdOnCreated, data, priceConfigOnCreatedData]);

  const defaultPriceConfig = useMemo(() => {
    const internalDefaultPriceConfig =
      data?.stockOperationInternalDefaultSetting?.__typename ===
      'StockOperationInternalDefaultSetting'
        ? data.stockOperationInternalDefaultSetting.stockOperationPriceConfig
        : null;
    const externalDefaultPriceConfig =
      data?.stockOperationExternalDefaultSetting?.__typename ===
      'StockOperationExternalDefaultSetting'
        ? data.stockOperationExternalDefaultSetting.stockOperationPriceConfig
        : null;
    return internalDefaultPriceConfig && externalDefaultPriceConfig
      ? ({
          internal: { type: 'internal', ...internalDefaultPriceConfig },
          external: { type: 'external', ...externalDefaultPriceConfig },
        } as const)
      : undefined;
  }, [data]);

  return {
    loading: loading || loadingPriceConfig,
    defaultPriceConfig,
    storingStockOperation,
  };
}
