import { createIcon } from '@chakra-ui/react';

export const StimulantAttentionIcon = createIcon({
  displayName: 'StimulantAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M12.18 17.81C12.56 17.81 12.62 17.69 12.66 16.72C12.82 16.84 13.12 16.95 13.33 16.98C13.24 18.2 13.02 18.47 12.26 18.47H10.66C9.72002 18.47 9.49002 18.27 9.49002 17.54V16.4H8.06002C7.78002 17.64 6.97002 18.23 4.65002 18.54C4.59002 18.37 4.41002 18.05 4.27002 17.9C6.36002 17.69 7.05002 17.29 7.30002 16.4H6.10002V12.32H11.6V16.4H10.22V17.53C10.22 17.77 10.29 17.81 10.73 17.81H12.18ZM5.39002 12.98H4.70002V10.99H6.22002C6.02002 10.65 5.67002 10.19 5.35002 9.85L5.97002 9.57C6.34002 9.92 6.75002 10.43 6.94002 10.77L6.50002 10.99H8.62002C8.50002 10.61 8.19002 10.06 7.89002 9.63L8.53002 9.41C8.83002 9.81 9.16002 10.37 9.29002 10.73L8.64002 10.99H10.48C10.84 10.58 11.29 9.94 11.53 9.48L12.3 9.75C11.99 10.18 11.63 10.63 11.3 10.99H12.98V12.98H12.27V11.61H5.39002V12.98ZM6.79002 13.53H10.89V12.83H6.79002V13.53ZM6.79002 14.7H10.89V14H6.79002V14.7ZM6.79002 15.91H10.89V15.18H6.79002V15.91Z"
        fill="#5D6771"
      />
      <path
        d="M23.79 17.66V18.28H19.03V17.66H21.05V16.51H19.51V15.92H21.05V14.95H19.8C19.62 15.28 19.42 15.58 19.21 15.82C19.13 15.74 18.95 15.63 18.81 15.54V18.35H18.19V17.8H15.47V18.48H14.88V11.72H16V10.47H14.71V9.83002H18.91V10.47H17.64V11.72H18.81V15.34C19.24 14.83 19.57 14.12 19.77 13.41L20.38 13.53C20.3 13.81 20.2 14.08 20.09 14.34H21.05V13.45H21.72V14.34H23.51V14.95H21.72V15.92H23.35V16.51H21.72V17.66H23.79ZM16.53 13.1C16.53 13.7 16.39 14.47 15.76 15C15.7 14.93 15.56 14.81 15.47 14.75V15.6H18.19V14.48H17.62C17.22 14.48 17.09 14.39 17.09 13.9V12.35H16.53V13.1ZM15.47 12.35V14.68C16 14.2 16.1 13.59 16.1 13.09V12.35H15.47ZM18.19 17.19V16.19H15.47V17.19H18.19ZM16.52 11.72H17.1V10.47H16.52V11.72ZM18.19 12.35H17.52V13.89C17.52 14.02 17.54 14.03 17.66 14.03H18.04C18.14 14.03 18.17 14.02 18.19 14.01V12.35ZM23.3 13.25H19.39V9.80002H23.3V13.25ZM22.62 10.39H20.03V11.25H22.62V10.39ZM22.62 11.79H20.03V12.66H22.62V11.79Z"
        fill="#5D6771"
      />
    </g>
  ),
});
