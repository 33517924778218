import { NumberLikeText } from '@kkhs/hakari-ui';
import { formatAWSDate } from '@/shared/utils';
import { CellBase } from './CellBase';
import { Row } from '../../types';

export function OldestExpirationDateCell({ oldestExpirationDate }: Row) {
  return (
    <CellBase isNumeric>
      <NumberLikeText>{formatAWSDate(oldestExpirationDate)}</NumberLikeText>
    </CellBase>
  );
}
