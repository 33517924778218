import { createIcon } from '@chakra-ui/react';

export const LiquidSeparatelyIcon = createIcon({
  displayName: 'LiquidSeparatelyIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.869 8l.75-.54a.924.924 0 00-1.498-.002L19.87 8zm0 0l-.748-.542-.007.01-.018.024a68.225 68.225 0 00-1.227 1.789 74.948 74.948 0 00-2.739 4.459c-.996 1.763-2.001 3.738-2.76 5.677-.753 1.923-1.294 3.884-1.294 5.597 0 1.873.502 4.062 1.881 5.804 1.409 1.777 3.654 2.988 6.912 2.988s5.503-1.21 6.911-2.988c1.38-1.742 1.881-3.93 1.881-5.804v-.008c-.015-1.71-.56-3.668-1.316-5.59-.761-1.938-1.765-3.912-2.757-5.675a76.032 76.032 0 00-3.945-6.246l-.018-.025-.006-.009-.75.54zm-.46 2.302c.166-.251.32-.48.457-.682a74.116 74.116 0 013.112 5.027c.969 1.72 1.929 3.614 2.648 5.444.724 1.844 1.175 3.553 1.188 4.927 0 1.581-.43 3.325-1.482 4.653-1.024 1.293-2.713 2.288-5.463 2.288s-4.44-.995-5.464-2.288c-1.053-1.33-1.481-3.075-1.481-4.657 0-1.371.443-3.079 1.165-4.924.717-1.83 1.678-3.722 2.65-5.441a73.107 73.107 0 012.67-4.347zm-3.88 14.694a.924.924 0 10-1.847.036c.03 1.538.516 3.004 1.56 4.137 1.048 1.136 2.58 1.852 4.544 2.028a.924.924 0 00.165-1.84c-1.598-.142-2.67-.703-3.35-1.44-.685-.742-1.05-1.747-1.072-2.921z"
      fill="currentColor"
    />
  ),
});
