import { createIcon } from '@chakra-ui/react';

export const ArrowDownIcon = createIcon({
  displayName: 'ArrowDownIcon',
  viewBox: '0 0 12 11',
  path: (
    <path
      d="M2.70711 4.79289L2 4.08579L0.585786 5.5L1.29289 6.20711L2.70711 4.79289ZM6 9.5L5.29289 10.2071L6 10.9142L6.70711 10.2071L6 9.5ZM10.7071 6.20711L11.4142 5.5L10 4.08579L9.29289 4.79289L10.7071 6.20711ZM7 1.5V0.5H5V1.5H7ZM1.29289 6.20711L5.29289 10.2071L6.70711 8.79289L2.70711 4.79289L1.29289 6.20711ZM6.70711 10.2071L10.7071 6.20711L9.29289 4.79289L5.29289 8.79289L6.70711 10.2071ZM7 9.5V1.5H5V9.5H7Z"
      fill="currentColor"
    />
  ),
});
