import { HStack } from '@chakra-ui/react';
import { DynamicSizedNumberText, Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { StockOperationExternalPriceConfig } from '@/entities/counterparty';
import { calculateSubTotalTaxAmount } from '@/entities/stockOperation';

type Props = {
  /** 単位薬価 */
  unitPrice: number | null;
  /** 入庫数量 */
  stockUnitQuantity: number | null;
  /** 入庫金額処理設定 */
  stockOperationPriceConfig: StockOperationExternalPriceConfig;
  /** 閲覧者が管理者権限かどうか */
  isManager: boolean;
};

export function SubTotalTaxAmount({
  unitPrice,
  stockUnitQuantity,
  stockOperationPriceConfig,
  isManager,
}: Props) {
  const subTotalTaxAmount = calculateSubTotalTaxAmount({
    unitPrice,
    stockUnitQuantity,
    stockOperationPriceConfig,
  });

  if (!isManager) {
    return <Typography variant="body2">-</Typography>;
  }
  return (
    <HStack spacing={1}>
      {!isNullOrUndefined(subTotalTaxAmount) ? (
        <DynamicSizedNumberText variant="body" mapping={{ 13: '15px', 14: '11px' }}>
          {subTotalTaxAmount}
        </DynamicSizedNumberText>
      ) : (
        <Typography variant="body2">-</Typography>
      )}
      <Typography variant="body2">円</Typography>
    </HStack>
  );
}
