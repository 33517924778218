import { createIcon } from '@chakra-ui/react';

export const ArrowUpIcon = createIcon({
  displayName: 'ArrowUpIcon',
  viewBox: '0 0 12 11',
  path: (
    <path
      d="M9.29289 6.20711L10 6.91421L11.4142 5.5L10.7071 4.79289L9.29289 6.20711ZM6 1.5L6.70711 0.792894L6 0.0857868L5.29289 0.792894L6 1.5ZM1.29289 4.79289L0.585786 5.5L2 6.91421L2.70711 6.20711L1.29289 4.79289ZM5 9.5L5 10.5L7 10.5L7 9.5L5 9.5ZM10.7071 4.79289L6.70711 0.792894L5.29289 2.20711L9.29289 6.20711L10.7071 4.79289ZM5.29289 0.792894L1.29289 4.79289L2.70711 6.20711L6.70711 2.20711L5.29289 0.792894ZM5 1.5L5 9.5L7 9.5L7 1.5L5 1.5Z"
      fill="currentColor"
    />
  ),
});
