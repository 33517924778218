/**
 * RequestedDeliveryDatePicker（納品希望日のDatePicker）で
 * カレンダーのコンテナの下にボーダーを追加するためのスタイル
 */
export const requestedDeliveryDatePickerCalendarStyle = {
  '.requested-delivery-datepicker .react-datepicker__month-container': {
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
};
