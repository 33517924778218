import { BaseMutationOptions } from '@apollo/client';
import {
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  HStack,
  Stack,
  Box,
  Divider,
  AlertDialogHeader,
} from '@chakra-ui/react';
import { Typography, useZodForm } from '@kkhs/hakari-ui';
import { FormProvider } from 'react-hook-form';
import { InitialCostPriceInputControl } from '@/entities/adoptedMedicalProduct';
import { usePharmacyShouldAutoUpdateCostPriceMaster } from '@/entities/currentPharmacy';
import { MedicalMaterialMappingYjCodeForm } from '@/entities/medicalMaterial';
import { MedicinePackageUnitSelectControl } from '@/entities/medicinePackageUnit';
import { OrderableWholesaleSelectV2Form } from '@/entities/orderableWholesale';
import { OrganizationManagementProposedWholesale } from '@/entities/organizationManagement';
import { InitialStockUnitQuantityForm } from '@/entities/stock';
import { StoragesForm } from '@/entities/storage';
import { Actions } from '@/repository/userMonitoring/interface';
import { useAdoptedMedicalMaterialAddButtonProps } from './hooks';
import {
  MedicalMaterialFieldValues,
  medicalMaterialSchema,
  toMedicalMaterialDefaultValues,
} from './schema';
import { AdoptedMedicalMaterialAddTarget } from './types';
import { currentRoleSelectors } from '../../../../store/currentRole';
import { ogasRegistrationInfoSelectors } from '../../../../store/ogasRegistrationInfo';

type Props = {
  onClose: () => void;
  /** 採用薬登録成功後の callback */
  onSuccess?: () => void;
  /** 採用薬登録失敗後の callback */
  onFailure?: () => void;
  target: AdoptedMedicalMaterialAddTarget;
  refetchQueries: BaseMutationOptions['refetchQueries'];
  cancelRef: React.MutableRefObject<null>;
  dialogDescription?: React.ReactChild;
  userMonitoringKey?:
    | Actions['医薬品詳細モーダルの他店舗の在庫タブから医薬品を登録']['key']
    | Actions['在庫一覧画面の他店舗の在庫から医薬品を登録']['key']
    | Actions['在庫を計上する画面から医薬品を登録']['key']
    | Actions['必須買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['安心買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['特定の医薬品発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['アプリ発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['採用薬登録画面から医薬品を登録']['key'];
};

export function AdoptedMedicalMaterialAddDialogContent({
  onClose,
  onSuccess,
  onFailure,
  target,
  refetchQueries,
  cancelRef,
  dialogDescription,
  userMonitoringKey,
}: Props) {
  const useFormReturn = useZodForm({
    schema: medicalMaterialSchema,
    defaultValues: toMedicalMaterialDefaultValues({
      defaultMedicinePackageUnitId: target.defaultMedicinePackageUnitId,
    }),
  });
  const { control, watch, setValue } = useFormReturn;
  const currentMedicinePackageUnitId = watch('medicinePackageUnitId');

  const { onClickAdd, isSubmitting, isDisabled } = useAdoptedMedicalMaterialAddButtonProps({
    onClose,
    onSuccess,
    onFailure,
    target,
    refetchQueries,
    useFormReturn,
    userMonitoringKey,
  });

  const ogasRegistrationInfo = ogasRegistrationInfoSelectors.useValue();
  const isManagerRole = currentRoleSelectors.useIsManager();
  const { shouldAutoUpdateCostPriceMaster } = usePharmacyShouldAutoUpdateCostPriceMaster({
    fetchPolicy: 'cache-and-network',
  });
  // NOTE:
  //  - 原価更新設定が自動の場合は、原価フォームを表示しない
  //  - 原価設定ができるのは、manager role の場合のみ
  // 本来であれば、schema から分けたいが一旦はここで分岐させる
  const showInitialCostPriceForm = isManagerRole && shouldAutoUpdateCostPriceMaster === false;

  return (
    <FormProvider {...useFormReturn}>
      <AlertDialogContent minW="1140px" minH="496px">
        <AlertDialogHeader px={6} py={4}>
          <Typography variant="h2">医薬品を登録する</Typography>
        </AlertDialogHeader>
        <Divider borderColor="gray.300" />
        <AlertDialogBody p={0}>
          <Stack spacing={4} py={5}>
            <Stack px={6} spacing={4}>
              {dialogDescription}
              <Stack spacing={1}>
                <Typography variant="body2" fontWeight="bold" mt={1}>
                  医薬品名
                </Typography>
                <Typography variant="h2" fontWeight="bold">
                  {target.medicineName}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2" fontWeight="bold">
                  需要タイプ
                </Typography>
                <Typography variant="body">{target.shortUnitOutline}</Typography>
              </Stack>
              <Stack spacing={3}>
                <Stack w="720px">
                  <MedicinePackageUnitSelectControl<MedicalMaterialFieldValues>
                    name="medicinePackageUnitId"
                    control={control}
                    currentMedicinePackageUnitId={currentMedicinePackageUnitId}
                    medicineId={target.id}
                    showRequired={!target.defaultMedicinePackageUnitId}
                    onComplete={(orderableMedicinePackageUnits) => {
                      if (
                        orderableMedicinePackageUnits.length === 1 &&
                        orderableMedicinePackageUnits[0]
                      ) {
                        setValue('medicinePackageUnitId', orderableMedicinePackageUnits[0].id);
                      }
                    }}
                  />
                </Stack>
                {ogasRegistrationInfo?.hasOgasRegistration && (
                  <OrganizationManagementProposedWholesale
                    medicineId={target.id}
                    medicinePackageUnitId={currentMedicinePackageUnitId}
                  />
                )}
                <HStack spacing={6} mt={1} alignItems="baseline">
                  <Stack w="300px" spacing={1}>
                    <OrderableWholesaleSelectV2Form
                      control={control}
                      label="店舗設定卸"
                      name="primaryWholesaleId"
                      medicineId={target.id}
                      isRequired
                    />
                  </Stack>
                  <Box>
                    <InitialStockUnitQuantityForm<MedicalMaterialFieldValues>
                      name="stockUnitQuantity"
                      control={control}
                      stockUnitSymbol={target.stockUnitSymbol}
                    />
                  </Box>
                  <MedicalMaterialMappingYjCodeForm<MedicalMaterialFieldValues>
                    name="mappingYjCode"
                    control={control}
                    isRequired
                  />
                  <Box>
                    <StoragesForm />
                  </Box>
                </HStack>
                {showInitialCostPriceForm && (
                  <InitialCostPriceInputControl<MedicalMaterialFieldValues>
                    name="costPrice"
                    control={control}
                    stockUnitSymbol={target.stockUnitSymbol}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </AlertDialogBody>
        <Divider borderColor="gray.300" />
        <AlertDialogFooter pr={6}>
          <Button ref={cancelRef} variant="ghost" onClick={onClose}>
            戻る
          </Button>
          <Button
            onClick={onClickAdd}
            ml={5}
            w="160px"
            isLoading={isSubmitting}
            isDisabled={isDisabled}
          >
            登録して完了する
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </FormProvider>
  );
}
