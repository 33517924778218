import { OrderPointForecast } from '@/gql/docs';
import { isStockoutPreventionEnabled } from '../isStockoutPreventionEnabled';

type Args = {
  /** 欠品注意ラベルがあるかのフラグ */
  hasStockoutPreventionLabel: boolean;
  /** 欠品係数 */
  stockoutPreventionFactor: OrderPointForecast['stockoutPreventionFactor'];
};

/**
 * 発注点の計算において欠品係数が反映されているかどうかを返す
 * 補足: 欠品注意ラベルを付与した当日、もしくは解除した当日は欠品係数が反映されない
 * Figma: https://www.figma.com/file/LvRpHS5DZXZekMhTPAmpy4/%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3_%EF%BC%88%E7%99%BA%E6%B3%A8%E4%BB%95%E6%A7%98No.5%EF%BC%89%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AF%E6%AC%A0%E5%93%81%E6%B3%A8%E6%84%8F%E3%81%AE%E5%8C%BB%E8%96%AC%E5%93%81%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E5%A4%9A%E3%82%81%E3%81%AB%E7%99%BA%E6%B3%A8%E3%81%A7%E3%81%8D%E3%82%8B?node-id=904%3A79379&t=Q0aPFyet9BdPlK0Z-0
 */
export function isStockoutPreventionLabelReflected({
  hasStockoutPreventionLabel,
  stockoutPreventionFactor,
}: Args) {
  const isEnabled = isStockoutPreventionEnabled(stockoutPreventionFactor);
  /** 欠品注意ラベルを付与した当日かのフラグ */
  const isDateAdded = hasStockoutPreventionLabel && !isEnabled;
  /** 欠品注意ラベルを解除した当日かのフラグ */
  const isDateRemoved = !hasStockoutPreventionLabel && isEnabled;

  return !isDateAdded && !isDateRemoved;
}
