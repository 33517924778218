import { createIcon } from '@chakra-ui/react';

export const ChevronRightIcon = createIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 12.0001L8 6L10 4L18 12.0001L10 20L8 18L14 12.0001Z"
      fill="currentColor"
    />
  ),
});
