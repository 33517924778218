import { useQuery } from '@apollo/client';
import { Center, HStack, Spinner } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { graphql } from '@/gql';
import { AdoptedMedicineUpdateTabContent } from './AdoptedMedicineUpdateTabContent';

const queryDoc = graphql(`
  query AdoptedMedicineUpdateTab($medicineId: ID!) {
    adoptedMedicines(filter: { medicineId: $medicineId }, first: 1) {
      edges {
        node {
          ...AdoptedMedicineUpdateTabContent_AdoptedMedicine
        }
      }
    }
    # 直近の発注予約を取得
    nearestOrderPlans: medicineOrders(
      filter: { medicineId: $medicineId, statusList: [waiting, preProcessing, processing] }
      sortBy: { direction: desc, field: targetDate }
      first: 1
    ) {
      edges {
        node {
          id
          targetDate
        }
      }
    }
  }
`);

type Props = {
  /** 対象の medicineId */
  medicineId: string;
  /** mutation 成功後の callback */
  onAfterMutation: () => Promise<void>;
};

export function AdoptedMedicineUpdateTab({ medicineId, onAfterMutation }: Props) {
  const { data, loading: isLoading } = useQuery(queryDoc, {
    variables: { medicineId },
    fetchPolicy: 'network-only',
  });

  if (isLoading) {
    return (
      <Center h="100%">
        <HStack>
          <Spinner color="primary.500" emptyColor="gray.200" />
        </HStack>
      </Center>
    );
  }
  if (!data?.adoptedMedicines.edges[0]?.node) {
    return (
      <Center h="100%">
        <HStack>
          <Typography variant="body">
            医薬品情報を取得できませんでした。リロードしてください。
          </Typography>
        </HStack>
      </Center>
    );
  }
  return (
    <AdoptedMedicineUpdateTabContent
      fragment={data.adoptedMedicines.edges[0].node}
      latestOrderPlanDate={data?.nearestOrderPlans.edges[0]?.node.targetDate}
      onAfterMutation={onAfterMutation}
    />
  );
}
