import { createIcon } from '@chakra-ui/react';

export const StocktakeIcon = createIcon({
  displayName: 'StocktakeIcon',
  viewBox: '0 0 60 60',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8061 12.4455C14.7551 12.4455 13.9031 13.2983 13.9031 14.3503V46.7312C13.9031 47.7832 14.7551 48.636 15.8061 48.636H43.1939C44.2449 48.636 45.0969 47.7832 45.0969 46.7312V14.3503C45.0969 13.2983 44.2449 12.4455 43.1939 12.4455H15.8061ZM12 14.3503C12 12.2464 13.7041 10.5408 15.8061 10.5408H43.1939C45.2959 10.5408 47 12.2464 47 14.3503V46.7312C47 48.8352 45.2959 50.5408 43.1939 50.5408H15.8061C13.7041 50.5408 12 48.8352 12 46.7312V14.3503ZM25.6604 21.2356L21.7505 25.6381L18.9765 23.417L20.1653 21.9296L21.5285 23.0211L24.238 19.9702L25.6604 21.2356ZM40.2565 23.2116H28.6726V21.3068H40.2565V23.2116ZM25.6604 29.5172L21.7505 33.9197L18.9765 31.6985L20.1653 30.2112L21.5285 31.3026L24.238 28.2517L25.6604 29.5172ZM40.2565 31.4932H28.6726V29.5884H40.2565V31.4932ZM25.6604 37.7988L21.7505 42.2013L18.9765 39.9801L20.1653 38.4927L21.5285 39.5842L24.238 36.5333L25.6604 37.7988ZM40.2565 39.7747H28.6726V37.87H40.2565V39.7747Z"
      fill="white"
    />
  ),
});
