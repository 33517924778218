import { createIcon } from '@chakra-ui/react';

export const CapsulePtpIcon = createIcon({
  displayName: 'CapsulePtpIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.58 9.65c-.534 0-.93.426-.93.842v19.016c0 .416.396.842.93.842h16.84c.534 0 .93-.426.93-.842V10.492c0-.416-.396-.842-.93-.842H11.58zm-2.23.842c0-1.203 1.049-2.142 2.23-2.142h16.84c1.181 0 2.23.939 2.23 2.142v19.016c0 1.204-1.049 2.142-2.23 2.142H11.58c-1.181 0-2.23-.938-2.23-2.142V10.492zm4.45 2.825a.668.668 0 00-.65.671c0 .376.306.672.65.672h3.4c.344 0 .65-.296.65-.672a.668.668 0 00-.65-.671h-3.4zm-1.95.671c0-1.074.869-1.971 1.95-1.971h3.4c1.081 0 1.95.897 1.95 1.971s-.869 1.972-1.95 1.972h-3.4c-1.081 0-1.95-.898-1.95-1.972zm10.3 0c0-.375.306-.671.65-.671h3.4c.338 0 .64.286.65.653-.034.415-.371.69-.65.69h-3.4a.668.668 0 01-.65-.672zm.65-1.971c-1.081 0-1.95.897-1.95 1.971s.869 1.972 1.95 1.972h3.4c1.058 0 1.89-.91 1.949-1.934l.001-.038c0-1.074-.869-1.971-1.95-1.971h-3.4zm-9 7.311a.668.668 0 00-.65.672c0 .376.306.672.65.672h3.4c.344 0 .65-.296.65-.672a.668.668 0 00-.65-.672h-3.4zM11.85 20c0-1.074.869-1.972 1.95-1.972h3.4c1.081 0 1.95.898 1.95 1.972 0 1.074-.869 1.972-1.95 1.972h-3.4c-1.081 0-1.95-.898-1.95-1.972zm10.3 0c0-.376.306-.672.65-.672h3.4c.338 0 .64.287.65.654-.034.415-.371.69-.65.69h-3.4a.668.668 0 01-.65-.672zm.65-1.972c-1.081 0-1.95.898-1.95 1.972 0 1.074.869 1.972 1.95 1.972h3.4c1.058 0 1.89-.91 1.949-1.934L28.15 20c0-1.074-.869-1.972-1.95-1.972h-3.4zm-9 7.312a.668.668 0 00-.65.672c0 .375.306.671.65.671h3.4c.344 0 .65-.296.65-.671 0-.309-.285-.64-.673-.672h-3.376zm-1.95.672c0-1.074.869-1.972 1.95-1.972h3.4l.038.001c1.03.062 1.912.919 1.912 1.97 0 1.075-.869 1.972-1.95 1.972h-3.4c-1.081 0-1.95-.897-1.95-1.971zm10.3 0c0-.376.306-.672.65-.672h3.4c.338 0 .64.286.65.653-.034.415-.371.69-.65.69h-3.4a.668.668 0 01-.65-.671zm.65-1.972c-1.081 0-1.95.898-1.95 1.972 0 1.074.869 1.971 1.95 1.971h3.4c1.058 0 1.89-.91 1.949-1.934l.001-.025v-.012c0-1.074-.869-1.972-1.95-1.972h-3.4z"
      fill="currentColor"
    />
  ),
});
