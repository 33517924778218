import { PrescriptionFrequencyType } from '@/entities/prescription';
import { MedicineSummaryTabQuery } from '@/gql/apollo';
import { OrderPointSetting } from '@/gql/docs';
import { NoOrderPointForecastCaption } from './NoOrderPointForecastCaption';
import { OrderPointForecastCaptionByAuto } from './OrderPointForecastCaptionByAuto';
import { OrderPointForecastCaptionByFactor } from './OrderPointForecastCaptionByFactor';
import { OrderPointForecastCaptionByFixed } from './OrderPointForecastCaptionByFixed';

type Props = {
  /** 処方頻度タイプ */
  prescriptionFrequencyType?: PrescriptionFrequencyType;
  /** 発注点情報 */
  orderPointForecast: Omit<
    NonNullable<MedicineSummaryTabQuery['orderPointForecast']>,
    'convertedUnitQuantities'
  > | null;
  /** 欠品注意ラベルがあるかのフラグ */
  hasStockoutPreventionLabel: boolean;
  /** 発注点設定モード */
  orderPointSettingMode: OrderPointSetting['mode'];
};

function OrderPointForecastCaptionInner({
  prescriptionFrequencyType,
  orderPointForecast,
  hasStockoutPreventionLabel,
  orderPointSettingMode,
}: Props) {
  if (!orderPointForecast) {
    return (
      <NoOrderPointForecastCaption
        prescriptionFrequencyType={prescriptionFrequencyType}
        hasStockoutPreventionLabel={hasStockoutPreventionLabel}
      />
    );
  }

  if (orderPointSettingMode === 'auto') {
    return (
      <OrderPointForecastCaptionByAuto
        prescriptionFrequencyType={prescriptionFrequencyType}
        orderPointForecast={orderPointForecast}
        hasStockoutPreventionLabel={hasStockoutPreventionLabel}
      />
    );
  }

  if (orderPointSettingMode === 'fixed') {
    return (
      <OrderPointForecastCaptionByFixed
        prescriptionFrequencyType={prescriptionFrequencyType}
        orderPointForecast={orderPointForecast}
        hasStockoutPreventionLabel={hasStockoutPreventionLabel}
      />
    );
  }

  if (orderPointSettingMode === 'factor') {
    return (
      <OrderPointForecastCaptionByFactor
        prescriptionFrequencyType={prescriptionFrequencyType}
        orderPointForecast={orderPointForecast}
        hasStockoutPreventionLabel={hasStockoutPreventionLabel}
      />
    );
  }

  throw new Error('invalid orderPointSettingMode');
}

export function OrderPointForecastCaption(props: Props) {
  return (
    <div data-testid="order-point-forecast-caption">
      <OrderPointForecastCaptionInner {...props} />
    </div>
  );
}
