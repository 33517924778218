import { AlertDialog, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { MutableRefObject, useRef } from 'react';
import { TransactionType } from '@/gql/docs';
import { EmptyDialogContent } from './EmptyDialogContent';
import { LoadingDialogContent } from './LoadingDialogContent';
import { StoringDialogContent } from './StoringDialogContent';
import { StoringStockOperation } from './StoringDialogContent/types';
import { useStoringStockOperation } from './hooks';
import { UpdateStoringStockOperationFieldValues } from '../schema';

function renderDialogContent({
  onClose,
  cancelRef,
  loading,
  transactionType,
  storingStockOperation,
  defaultPriceConfig,
  renderStockOperationDeleteFooter,
  renderStockOperationDeleteAlert,
  externalCounterpartyCreateDialogLink,
  internalCounterpartyCreateDialogLink,
}: {
  onClose: () => void;
  cancelRef: MutableRefObject<null>;
  loading: boolean;
  transactionType: TransactionType;
  storingStockOperation?: StoringStockOperation;
  defaultPriceConfig?: UpdateStoringStockOperationFieldValues['defaultPriceConfig'];
  renderStockOperationDeleteFooter: (props: {
    stockOperationId: string;
    cancelRef: React.RefObject<HTMLButtonElement>;
    onClose: () => void;
  }) => React.ReactNode;
  renderStockOperationDeleteAlert: () => React.ReactNode;
  externalCounterpartyCreateDialogLink: React.ReactElement;
  internalCounterpartyCreateDialogLink: React.ReactElement;
}) {
  if (loading) {
    return <LoadingDialogContent onClose={onClose} transactionType={transactionType} />;
  }
  if (
    // NOTE: デフォルトの金額設定を取得できていない場合は、ローディングとして扱う
    defaultPriceConfig &&
    storingStockOperation
  ) {
    return (
      <StoringDialogContent
        onClose={onClose}
        cancelRef={cancelRef}
        stockOperation={storingStockOperation}
        defaultPriceConfig={defaultPriceConfig}
        renderStockOperationDeleteFooter={renderStockOperationDeleteFooter}
        renderStockOperationDeleteAlert={renderStockOperationDeleteAlert}
        externalCounterpartyCreateDialogLink={externalCounterpartyCreateDialogLink}
        internalCounterpartyCreateDialogLink={internalCounterpartyCreateDialogLink}
      />
    );
  }
  return <EmptyDialogContent onClose={onClose} transactionType={transactionType} />;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  transactionType: TransactionType;
  stockOperationId: string;
  renderStockOperationDeleteFooter: (props: {
    stockOperationId: string;
    cancelRef: React.RefObject<HTMLButtonElement>;
    onClose: () => void;
  }) => React.ReactNode;
  renderStockOperationDeleteAlert: () => React.ReactNode;
  externalCounterpartyCreateDialogLink: React.ReactElement;
  internalCounterpartyCreateDialogLink: React.ReactElement;
};

export function StoringStockOperationUpdateDialog({
  isOpen,
  onClose,
  transactionType,
  stockOperationId,
  renderStockOperationDeleteFooter,
  renderStockOperationDeleteAlert,
  externalCounterpartyCreateDialogLink,
  internalCounterpartyCreateDialogLink,
}: Props) {
  const cancelRef = useRef(null);
  const { loading, storingStockOperation, defaultPriceConfig } = useStoringStockOperation({
    isOpen,
    stockOperationId,
  });

  return (
    <AlertDialog
      size="6xl"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          {renderDialogContent({
            onClose,
            cancelRef,
            loading,
            transactionType,
            storingStockOperation,
            defaultPriceConfig,
            renderStockOperationDeleteFooter,
            renderStockOperationDeleteAlert,
            externalCounterpartyCreateDialogLink,
            internalCounterpartyCreateDialogLink,
          })}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
