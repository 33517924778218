import { createIcon } from '@chakra-ui/react';

export const FeatureIcon = createIcon({
  displayName: 'FeatureIcon',
  viewBox: '0 0 20 19',
  path: (
    <>
      <path
        d="M14.8261 8.88014L15.2199 8.48216C15.2199 8.48216 15.825 7.96244 16.5149 8.19807C17.7161 8.60815 19.1039 8.16415 20.0664 7.20043C20.3402 6.92662 20.6727 6.37724 20.6727 6.37724C20.7109 6.32514 20.7242 6.25912 20.7085 6.19612L20.634 5.88963C20.62 5.8321 20.5831 5.78302 20.5322 5.75274C20.4813 5.72304 20.4201 5.71518 20.3638 5.73153L17.9996 6.38938C17.9118 6.41361 17.8179 6.38089 17.7646 6.30762L16.6785 4.81143C16.6452 4.76599 16.6312 4.70905 16.6391 4.65392L16.8578 3.06446C16.8693 2.97785 16.9311 2.90757 17.0153 2.88396L19.5122 2.18917C19.6248 2.15765 19.6933 2.04135 19.6654 1.92687L19.5667 1.52528C19.5533 1.46833 19.517 1.41987 19.4673 1.38959C19.4673 1.38959 19.2716 1.25995 19.1293 1.18668C17.7112 0.454329 15.9255 0.681505 14.7365 1.87116C13.8581 2.74947 13.5141 3.9525 13.6752 5.0949C13.7994 5.97321 13.7412 6.44024 13.362 6.84125C13.3275 6.87699 13.2185 6.98601 13.0501 7.15139L14.8261 8.88014Z"
        fill="currentColor"
      />
      <path
        d="M9.06068 11.0281C6.41058 13.5958 3.51997 16.3895 3.51997 16.3895C3.42123 16.484 3.3649 16.6142 3.36308 16.7505C3.36126 16.8862 3.41518 17.0177 3.5109 17.114L4.74905 18.3521C4.84597 18.4484 4.97681 18.5017 5.11312 18.5C5.24942 18.4988 5.37902 18.4418 5.47292 18.3437L10.8743 12.8793L9.06068 11.0281Z"
        fill="currentColor"
      />
      <path
        d="M20.305 17.074C20.4013 16.9777 20.4546 16.8469 20.4528 16.7106C20.4516 16.5743 20.3947 16.4447 20.2966 16.3501L9.68218 6.02046L7.97824 7.72502L18.3424 18.3037C18.4369 18.4024 18.5665 18.4587 18.7028 18.4606C18.8391 18.4624 18.97 18.4085 19.0669 18.3122L20.305 17.074Z"
        fill="currentColor"
      />
      <path
        d="M10.7204 0.840148C9.4423 0.341638 7.37553 0.269531 6.17859 1.46589C5.23604 2.40844 3.85739 3.78771 3.85739 3.78771C3.36613 4.27835 3.82227 4.76538 3.45941 5.12762C3.09658 5.49045 2.51686 5.12762 2.1904 5.45412L0.993454 6.65161C0.823227 6.82121 0.823227 7.09743 0.993454 7.26766L2.66107 8.93528C2.8313 9.1055 3.10752 9.1055 3.2777 8.93528L4.47465 7.73833C4.80177 7.41183 4.43828 6.83215 4.80177 6.4699C5.29058 5.98047 5.82425 6.06347 6.13257 6.37116L6.74862 6.98722L7.41616 7.16287L9.1201 5.45893L8.92382 4.81198C8.38046 4.26803 8.30292 3.63865 8.64338 3.29823C9.17038 2.77123 10.0408 2.00984 10.8561 1.68334C11.3758 1.47558 11.2189 0.958272 10.7204 0.840148ZM8.40891 3.58716C8.40647 3.58899 8.63605 3.3049 8.64032 3.30125L8.40891 3.58716Z"
        fill="currentColor"
      />
    </>
  ),
});
