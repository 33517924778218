import { createIcon } from '@chakra-ui/react';

export const AlertIcon = createIcon({
  displayName: 'AlertIcon',
  viewBox: '0 0 18 18',
  path: (
    <>
      <path
        d="M9.89998 13.5H8.09998V11.7H9.89998V13.5ZM9.89998 9.9H8.09998V4.5H9.89998V9.9Z"
        fill="currentColor"
      />
      <path
        d="M1 9C1 4.58428 4.58428 1 9 1C13.4157 1 17 4.58428 17 9C17 13.4157 13.4157 17 9 17C4.58428 17 1 13.4157 1 9Z"
        stroke="currentColor"
        strokeWidth="2"
        fill="transparent"
      />
    </>
  ),
});
