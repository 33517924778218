import { IconProps } from '@chakra-ui/react';
import {
  TabletPtpIcon,
  CapsulePtpIcon,
  TabletSeparatelyIcon,
  CapsuleSeparatelyIcon,
  PowderSeparatelyIcon,
  PowderSachetIcon,
  OintmentForDispensingIcon,
  OintmentSeparatelyIcon,
  LiquidSeparatelyIcon,
  InjectionIcon,
  SuppositoryIcon,
  PatchIcon,
  OtherIcon,
} from '@kkhs/hakari-ui';
import { MedicineType } from '@/gql/docs';

type Props = {
  type?: MedicineType;
} & IconProps;

export function MedicineTypeIcon({ type, ...rest }: Props) {
  switch (type) {
    case 'tabletPTP':
      return <TabletPtpIcon {...rest} />;
    case 'capsulePTP':
      return <CapsulePtpIcon {...rest} />;
    case 'tabletSeparately':
      return <TabletSeparatelyIcon {...rest} />;
    case 'capsuleSeparately':
      return <CapsuleSeparatelyIcon {...rest} />;
    case 'powderSeparately':
      return <PowderSeparatelyIcon {...rest} />;
    case 'powderSachet':
      return <PowderSachetIcon {...rest} />;
    case 'ointmentSeparately':
      return <OintmentSeparatelyIcon {...rest} />;
    case 'ointmentForDispensing':
      return <OintmentForDispensingIcon {...rest} />;
    case 'liquidSeparately':
      return <LiquidSeparatelyIcon {...rest} />;
    // MEMO: 「液剤」と「液剤（調剤用）」はアイコンが同じ
    case 'liquidForDispensing':
      return <LiquidSeparatelyIcon {...rest} />;
    case 'injection':
      return <InjectionIcon {...rest} />;
    case 'suppository':
      return <SuppositoryIcon {...rest} />;
    case 'patch':
      return <PatchIcon {...rest} />;
    case 'other':
      return <OtherIcon {...rest} />;
    default:
      return <OtherIcon {...rest} />;
  }
}
