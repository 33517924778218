import { Stack, Image } from '@chakra-ui/react';
import { Typography, InfoPopover } from '@kkhs/hakari-ui';
import { ReactNode } from 'react';
import { isFewPrescriptionFrequency, PrescriptionFrequencyType } from '@/entities/prescription';

type Props = {
  children: ReactNode;
  prescriptionFrequencyType?: PrescriptionFrequencyType;
  defaultIsOpen?: boolean;
};

export function MedicineOrderPointInfoPopover({
  children,
  prescriptionFrequencyType,
  defaultIsOpen = false,
}: Props) {
  return (
    <InfoPopover
      defaultIsOpen={defaultIsOpen}
      w="364px"
      content={
        isFewPrescriptionFrequency(prescriptionFrequencyType) ? (
          <Typography variant="caption" color="slate.500">
            少数処方は過去4か月で1人または2人の患者に処方が出た医薬品です。
            <br />
            10日以内に来局予定がある場合、該当する処方患者の予測処方量が発注点となります。
          </Typography>
        ) : (
          <Stack spacing={4} py={1}>
            <Stack spacing={1}>
              <Typography variant="body2">10日以内に特定患者の来局予定なし</Typography>
              <Image src="/assets/png/medicine-modal/img-order-point.png" alt="img-order-point" />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2">10日以内に特定患者の来局予定あり</Typography>
              <Image
                src="/assets/png/medicine-modal/img-fn-patient-order-point.png"
                alt="img-fn-patient-order-point"
              />
              <Typography variant="body2" color="slate.500">
                通常処方：過去4か月で3人以上の患者に処方が出た医薬品です。
              </Typography>
            </Stack>
          </Stack>
        )
      }
    >
      {children}
    </InfoPopover>
  );
}
