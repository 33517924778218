import { Center, Skeleton, Stack, Tbody, Td, Tr } from '@chakra-ui/react';
import { PrescriptionFrequencyType } from '@/entities/prescription';
import { PatientByMedicineListItemsQuery } from '@/gql/apollo';
import { PatientsTr } from './PatientsTr';

type Props = {
  edges: PatientByMedicineListItemsQuery['patientByMedicineListItems']['edges'];
  stockUnitSymbol: string;
  isLoading: boolean;
  prescriptionTypeMap?: { [key: string]: PrescriptionFrequencyType | undefined };
};

export function PatientsTableBody({
  edges,
  stockUnitSymbol,
  isLoading,
  prescriptionTypeMap,
}: Props) {
  if (isLoading) {
    return (
      <Tbody>
        <Tr>
          <Td colSpan={7} p="0" borderBottom="none">
            <Center bg="white" h="60">
              <Stack w="full" p="5">
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
                <Skeleton height="30px" />
              </Stack>
            </Center>
          </Td>
        </Tr>
      </Tbody>
    );
  }

  return (
    <Tbody>
      {edges.map(({ node }) => (
        <PatientsTr
          key={node.id}
          node={node}
          stockUnitSymbol={stockUnitSymbol}
          prescriptionFrequencyType={prescriptionTypeMap?.[node.patient.id]}
        />
      ))}
    </Tbody>
  );
}
