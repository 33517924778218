import { Skeleton } from '@chakra-ui/react';
import { CreatableSelect, CreatableSelectProps, SelectInstanceType } from '@kkhs/hakari-ui';
import { forwardRef, useMemo } from 'react';
import { useOrderableWholesalesQuery } from '@/gql/apollo';

type Props = {
  medicineId: string;
  noOptionsMessage: string;
  ref?: React.Ref<HTMLSelectElement>;
  ['aria-labelledby']?: string;
} & Omit<CreatableSelectProps, 'options'>;

export const OrderableWholesaleSelect = forwardRef<SelectInstanceType, Omit<Props, 'ref'>>(
  ({ medicineId, noOptionsMessage, defaultValue, ...rest }: Omit<Props, 'ref'>, ref) => {
    const { data, loading } = useOrderableWholesalesQuery({
      variables: { medicineId },
    });
    const options = useMemo(
      () =>
        data?.orderableWholesales.map((w) => ({
          value: w.shortName,
          label: w.shortName,
        })),
      [data],
    );
    return (
      <Skeleton isLoaded={!loading} data-testid="orderable-wholesale-select">
        <CreatableSelect
          {...rest}
          ref={ref}
          options={options}
          defaultValue={defaultValue}
          noOptionsMessage={noOptionsMessage}
          formatCreateLabel={(inputValue: string) => `"${inputValue}" を追加`}
        />
      </Skeleton>
    );
  },
);
OrderableWholesaleSelect.displayName = 'OrderableWholesaleSelect';
