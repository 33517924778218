import { createIcon } from '@chakra-ui/react';

export const PoisonAttentionIcon = createIcon({
  displayName: 'PoisonAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M16.98 15.88C16.95 16.23 16.91 16.53 16.88 16.79H18.16V17.37H16.78C16.68 17.82 16.57 18.08 16.44 18.22C16.25 18.43 16.03 18.49 15.71 18.51C15.41 18.54 14.81 18.51 14.23 18.48C14.21 18.33 14.14 18.09 14.05 17.94C14.65 18 15.22 18 15.42 18C15.63 18 15.73 17.98 15.82 17.9C15.91 17.81 15.98 17.65 16.05 17.37H11.16C11.11 17.57 11.06 17.76 11.02 17.93L10.3 17.88C10.46 17.35 10.63 16.63 10.8 15.88H9.45001V15.29H10.93C11.04 14.79 11.14 14.3 11.22 13.86H17.11C17.11 13.86 17.11 14.07 17.11 14.17C17.09 14.58 17.06 14.95 17.04 15.29H18.57V15.88H16.99H16.98ZM18.3 13.33H9.72V12.75H13.61V12.01H10.71V11.46H13.61V10.78H10.14V10.22H13.61V9.39999H14.34V10.22H18V10.78H14.34V11.46H17.38V12.01H14.34V12.75H18.3V13.33ZM11.51 15.88C11.44 16.19 11.37 16.5 11.3 16.79H13.31C13.36 16.52 13.41 16.21 13.47 15.88H11.51ZM11.82 14.42C11.76 14.7 11.7 15 11.64 15.29H13.56C13.61 14.99 13.65 14.7 13.68 14.42H11.82ZM16.16 16.79C16.19 16.54 16.23 16.24 16.26 15.88H14.14C14.09 16.21 14.03 16.52 13.98 16.79H16.16ZM16.31 15.29C16.33 15.02 16.35 14.74 16.37 14.42H14.35C14.32 14.7 14.27 15 14.23 15.29H16.31Z"
        fill="#5D6771"
      />
    </g>
  ),
});
