import { GridItem, Input, Skeleton, Text } from '@chakra-ui/react';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isLoading?: boolean;
} & Pick<UseFormReturn<T>, 'register' | 'formState'>;

export function BuildingNameForm<T extends FieldValues>({
  name,
  isLoading = false,
  register,
  formState: { errors },
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Text fontWeight="bold">建物名</Text>
      </GridItem>
      <GridItem>
        <Skeleton isLoaded={!isLoading} borderRadius="md">
          <Input {...register(name)} placeholder="◯◯ビル00階" />
          {errors?.buildingName?.message && (
            <Text fontSize="sm" color="red.500">
              {errors.buildingName.message}
            </Text>
          )}
        </Skeleton>
      </GridItem>
    </>
  );
}
