import { WatchQueryFetchPolicy, useQuery } from '@apollo/client';
import { graphql } from '@/gql';
import { GroupPharmacyStocksByMedicineQueryVariables } from '@/gql/docs';

type Props = {
  medicineId: string;
  fetchPolicy: WatchQueryFetchPolicy;
  filter: GroupPharmacyStocksByMedicineQueryVariables['filter'];
  skipFetch?: boolean;
};

const queryDoc = graphql(`
  query GroupPharmacyStocksByMedicine(
    $medicineId: ID!
    $filter: OthersMedicineStockListItemsFilter!
  ) {
    groupPharmacyStocks: othersMedicineStockListItemsV2(medicineId: $medicineId, filter: $filter) {
      othersMedicineStockListItems {
        id
        medicine {
          id
          pharmacyId
          medicineType
          medicineName
          makerName
          stockUnitSymbol
          stockUnitQuantity
          stockUnitPrice
          medicinePackageUnitCount
          isAdopted
          adoptionStoppedDate
          usageGroup {
            id
            unitOutline
            # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
            shortUnitOutline
          }
          ...MedicineAttentions
          # MEMO: 他店舗の在庫一覧で未採用の場合に 採用薬追加ダイアログ or 採用医療材料追加ダイアログ を出し分けるのに使う
          isMedicalMaterial
        }
        pharmacy {
          id
          name
        }
        lastPrescriptionDate
        lastPrescriptionUnitQuantity
        immovablyDays
        nearExpirationUnitQuantity
        oldestExpirationDate
        # 余剰在庫数量（= 在庫数量 - 発注点）
        surplusStockUnitQuantity
        # 融通依頼による直近の入庫対象日 (自薬局がもらった側。入庫まで完了したものに限られる。)
        latestTransferRequestStoringTargetDate
        # 融通依頼ステータスのラベル表示ができるデータ
        transferRequestStatusLabels
        # レセコン入力単位での数量と単位
        convertedOthersMedicineStockUnitQuantities {
          # レセコン入力単位(単位)
          stockUnitSymbol
          # 在庫数（YJコード+需要タイプごとの在庫数の合計）
          stockUnitQuantity
          # 最終処方量
          lastPrescriptionUnitQuantity
          # 有効期限間近数（有効期限切れを含む）
          nearExpirationUnitQuantity
          # 余剰在庫数量（= 在庫数量 - 発注点）
          surplusStockUnitQuantity
        }
      }
      hasMore
    }
  }
`);

export type RefetchType = ReturnType<typeof useGroupPharmacyStocksByMedicine>['refetch'];

export function useGroupPharmacyStocksByMedicine({
  medicineId,
  fetchPolicy,
  filter,
  skipFetch = false,
}: Props) {
  const { data, loading, refetch } = useQuery(queryDoc, {
    fetchPolicy,
    variables: { medicineId, filter },
    notifyOnNetworkStatusChange: true,
    skip: skipFetch,
  });

  return { groupPharmacyStocks: data?.groupPharmacyStocks, isLoading: loading, refetch };
}
