// TODO: 別途自動生成やメンテ方針を検討する
// https://github.com/kkhs/hakari-frontend/issues/7893
export const ORDER_PATH = '/order';
export const AI_PHARMARKET_POLICY_PATH = '/ai-pharmarket-policy';

/** 患者情報の来局予定タブのクエリ文字列の index */
export const PATIENT_SCHEDULE_TAB_INDEX = 0;

/** 患者情報の来局履歴タブのクエリ文字列の index */
export const PATIENT_HISTORY_TAB_INDEX = 1;
