import * as Switch from '@radix-ui/react-switch';
import { forwardRef } from 'react';
import { root, thumb } from './index.css';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';

type Props = Omit<ComponentPropsWithoutRef<typeof Switch.Root>, 'className'>;

/**
 * API Reference
 * https://www.radix-ui.com/docs/primitives/components/switch#api-reference
 */
export const SwitchV2 = forwardRef<ElementRef<typeof Switch.Root>, Props>((props, ref) => (
  <Switch.Root className={root} {...props} ref={ref}>
    <Switch.Thumb className={thumb} />
  </Switch.Root>
));

SwitchV2.displayName = 'SwitchV2';
