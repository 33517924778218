import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { ShippingStockOperationDeleteDialogQuery } from '@/gql/docs';
import { formatAWSDate } from '@/shared/utils';

type Props = {
  targetDate: Extract<
    ShippingStockOperationDeleteDialogQuery['stockOperation'],
    { __typename: 'ShippingStockOperationByExternalTrade' }
  >['targetDate'];
};

export function StockOperationShippingTargetDateLabel({ targetDate }: Props) {
  return (
    <Stack w="100px" spacing={1}>
      <Typography variant="body" fontWeight="bold">
        出庫対象日
      </Typography>
      <Typography variant="body">{formatAWSDate(targetDate, { showDayOfWeek: true })}</Typography>
    </Stack>
  );
}
