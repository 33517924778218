import { Wrap, WrapItem } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { MedicineAttentionsFragment, StockMedicineAttentionsFragment } from '@/gql/apollo';
import { AttentionIcon } from './AttentionIcon';
import { toAttentionIconNames } from './toAttentionIconNames';

type Props = {
  /** 対象の取扱注意 */
  fragment: MedicineAttentionsFragment | StockMedicineAttentionsFragment;
};

export function MedicineAttentionIcons({ fragment }: Props) {
  const attentions = toAttentionIconNames(fragment);
  if (attentions.length === 0) {
    return <Typography variant="body">-</Typography>;
  }
  return (
    <Wrap spacing="2px">
      {attentions.map((item) => (
        <WrapItem key={item} data-testid={item}>
          <AttentionIcon name={item} h="28px" w="28px" />
        </WrapItem>
      ))}
    </Wrap>
  );
}
