import {
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverAnchor,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchInput, Typography, PlusSquareIcon } from '@kkhs/hakari-ui';
import { useState } from 'react';
import { FieldArrayWithId, UseFieldArrayPrepend } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { SearchResults } from './SearchResults';
import { FieldValues } from '../schema';

type Props = {
  addMedicine: UseFieldArrayPrepend<FieldValues, 'requestMedicines'>;
  fields: FieldArrayWithId<FieldValues[], `${number}.requestMedicines`, 'id'>[];
};

export function SearchRequestMedicine({ addMedicine, fields }: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const [value, setValue] = useState('');
  const searchResultDisclosure = useDisclosure({
    id: 'search-result',
  });

  const handleChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // 医薬品名が入力されている場合、datadogにログを送信する
    if (e.target.value) {
      userMonitoringRepository.sendEvent({
        key: '共通_医薬品名_SEARCHED',
        contexts: {
          pharmacyId,
          musubiCode,
          画面名: '共通_小分け依頼を出すダイアログ',
          検索キーワード: e.target.value,
        },
      });
    }

    setValue(e.target.value);
  }, 500);

  return (
    <Box>
      <Popover
        isLazy
        gutter={0}
        autoFocus={false}
        returnFocusOnClose={false}
        isOpen={searchResultDisclosure.isOpen}
        onOpen={searchResultDisclosure.onOpen}
        onClose={searchResultDisclosure.onClose}
      >
        <PopoverTrigger>
          <HStack>
            <PlusSquareIcon color="slate.100" boxSize={7} />
            {fields.length < 5 ? (
              <PopoverAnchor>
                <SearchInput
                  size="sm"
                  bg="white"
                  w="714px"
                  placeholder="追加する医薬品名を入力してください"
                  onChange={handleChange}
                />
              </PopoverAnchor>
            ) : (
              <Typography variant="body" color="slate.500">
                同時に処理できる医薬品数は5つまでです
              </Typography>
            )}
          </HStack>
        </PopoverTrigger>
        <PopoverContent w="714px" maxH="50vh" overflowY="auto">
          <PopoverBody p={6}>
            <SearchResults
              medicineSearchKeyword={value}
              addMedicine={addMedicine}
              onClose={searchResultDisclosure.onClose}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
