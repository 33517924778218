import { Center, Spinner } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { jstNow } from '@kkhs/hakari-utils';
import { format, subMonths } from 'date-fns';
import { useMedicineSummaryTabQuery } from '@/gql/apollo';
import { MedicineSummaryTabContent } from './MedicineSummaryTabContent';
import { PRESCRIBED_UNIT_QUANTITY_MONTH_RANGE } from './constants';

export type MedicineSummaryTabProps = {
  medicineId: string;
};

export function AdoptedMedicalProductSummaryTab({ medicineId }: MedicineSummaryTabProps) {
  const today = jstNow();
  const fromYearMonth = format(
    subMonths(today, PRESCRIBED_UNIT_QUANTITY_MONTH_RANGE - 1),
    'yyyy-MM',
  );
  const toYearMonth = format(today, 'yyyy-MM');

  const { data, loading: isLoading } = useMedicineSummaryTabQuery({
    variables: {
      medicineId,
      fromYearMonth,
      toYearMonth,
    },
  });
  if (isLoading) {
    return (
      <Center h="100%">
        <Spinner color="primary.500" emptyColor="gray.200" />
      </Center>
    );
  }
  /**
   * NOTE:
   * medicines は Query.Medicines で取得したもので、採用実績の有無に関わらず、存在する全ての医薬品を返却するものです。
   * adoptedMedicines は Query.AdoptedMedicines で取得したもので、一度でも採用実績のある（採用した後に採用停止にしたものも含む）医薬品を返却するものです。
   * 以下のコンポーネントで必要な OrderPointSetting（発注点設定）は adoptedMedicines に含まれているため、Query.Medicines と Query.AdoptedMedicines の両方の存在確認をしています。
   */
  if (!data || !data.medicines[0] || !data.adoptedMedicines.edges[0]) {
    return (
      <Center h="100%">
        <Typography variant="body">
          医薬品情報を取得できませんでした。リロードしてください。
        </Typography>
      </Center>
    );
  }

  const {
    prescriptionFrequencySummary,
    identifiedPatientListItems,
    currentMonthMedicinePurchaseUnitQuantity,
    orderPlans,
    monthlyPrescribedUnitQuantities,
    orderPointForecast,
  } = data;

  return (
    <MedicineSummaryTabContent
      medicine={data.medicines[0]}
      orderPointSettingMode={data.adoptedMedicines.edges[0].node.orderPointSetting.mode}
      convertedAdoptedMedicineUnitQuantities={
        data.adoptedMedicines.edges[0].node.convertedAdoptedMedicineUnitQuantities
      }
      prescriptionFrequencySummary={prescriptionFrequencySummary}
      identifiedPatientListItems={identifiedPatientListItems}
      currentMonthMedicinePurchaseUnitQuantity={currentMonthMedicinePurchaseUnitQuantity}
      orderPlans={orderPlans}
      monthlyPrescribedUnitQuantities={monthlyPrescribedUnitQuantities}
      orderPointForecast={orderPointForecast}
    />
  );
}
