import {
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import {
  TotalAmount,
  StockOperationNoteForm,
  StockOperationReasonLabel,
} from '@/entities/stockOperation';
import { toStoringReason } from './toStoringReason';
import { StoringStockOperation } from './types';
import { useStoringDialogContent } from './useStoringDialogContent';
import { useStoringStockOperationForm } from './useStoringStockOperationForm';
import { currentRoleSelectors } from '../../../../../store/currentRole';
import { isLotCountMax } from '../../../functions';
import { CounterpartySettingAlert } from '../../CounterpartySettingAlert';
import { SearchMedicine } from '../../SearchMedicine';
import { StoringMedicineRow } from '../../StoringMedicineRow';
import { StoringStockOperationTargetDatePickerForm } from '../../StoringStockOperationTargetDatePickerForm';
import { StoringStockOperationUpdateHeading } from '../../StoringStockOperationUpdateHeading';
import { UpdateStoringStockOperationFieldValues } from '../../schema';

type Props = {
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
  stockOperation: StoringStockOperation;
  defaultPriceConfig: UpdateStoringStockOperationFieldValues['defaultPriceConfig'];
  renderStockOperationDeleteFooter: (props: {
    stockOperationId: string;
    cancelRef: React.RefObject<HTMLButtonElement>;
    onClose: () => void;
  }) => React.ReactNode;
  renderStockOperationDeleteAlert: () => React.ReactNode;
  externalCounterpartyCreateDialogLink: React.ReactElement;
  internalCounterpartyCreateDialogLink: React.ReactElement;
};

export function StoringDialogContent({
  onClose,
  cancelRef,
  stockOperation,
  defaultPriceConfig,
  renderStockOperationDeleteAlert,
  renderStockOperationDeleteFooter,
  externalCounterpartyCreateDialogLink,
  internalCounterpartyCreateDialogLink,
}: Props) {
  const isManager = currentRoleSelectors.useIsManager();
  const useFormReturn = useStoringStockOperationForm({
    stockOperation,
    defaultPriceConfig,
  });
  const {
    renderCounterpartyForm,
    renderStoringButtonGroup,
    totalAmount,
    currentPriceConfig,
    currentOperatedMedicines,
  } = useStoringDialogContent({
    stockOperation,
    useFormReturn,
    internalCounterpartyCreateDialogLink,
    externalCounterpartyCreateDialogLink,
  });
  const storingReason = toStoringReason(stockOperation.__typename);
  const { control } = useFormReturn;
  const {
    fields,
    prepend: prependOperatedMedicine,
    remove: removeOperatedMedicine,
  } = useFieldArray({
    control,
    name: 'operatedMedicines',
  });
  const isEmptyMedicines = useMemo(() => fields.length === 0, [fields.length]);

  return (
    <FormProvider {...useFormReturn}>
      <AlertDialogHeader display="flex" borderBottomWidth={1} justifyContent="space-between">
        <StoringStockOperationUpdateHeading />
        <TotalAmount
          amount={totalAmount.amount}
          taxAmount={totalAmount.taxAmount}
          showTax={
            // NOTE: 税額表示は external の場合のみ
            currentPriceConfig.type === 'external' && currentPriceConfig.shouldShowTax
          }
          isManager={isManager}
        />
      </AlertDialogHeader>
      <AlertDialogBody p={0}>
        <Stack borderBottomWidth={1} px={6} py={3}>
          <HStack spacing={4} alignItems="baseline">
            <StockOperationReasonLabel
              transactionType="replenishment"
              transactionReason={storingReason}
            />
            <StoringStockOperationTargetDatePickerForm<UpdateStoringStockOperationFieldValues>
              name="targetDate"
              isDisabled={isEmptyMedicines}
              control={control}
            />
            {renderCounterpartyForm()}
          </HStack>
          <StockOperationNoteForm<UpdateStoringStockOperationFieldValues>
            name="note"
            isDisabled={isEmptyMedicines}
            control={control}
          />
          <CounterpartySettingAlert />
        </Stack>
        <SearchMedicine
          prependOperatedMedicine={prependOperatedMedicine}
          removeOperatedMedicine={removeOperatedMedicine}
          currentOperatedMedicines={currentOperatedMedicines}
        />
        {isEmptyMedicines
          ? renderStockOperationDeleteAlert()
          : fields.map((field, rowIndex) => {
              const currentOperatedMedicine = currentOperatedMedicines[rowIndex];
              return (
                currentOperatedMedicine && (
                  <StoringMedicineRow
                    key={field.id}
                    currentOperatedMedicine={currentOperatedMedicine}
                    rowIndex={rowIndex}
                    currentPriceConfig={currentPriceConfig}
                    isNotAddable={isLotCountMax(currentOperatedMedicines)}
                    removeOperatedMedicine={removeOperatedMedicine}
                  />
                )
              );
            })}
      </AlertDialogBody>
      {isEmptyMedicines ? (
        renderStockOperationDeleteFooter({
          stockOperationId: stockOperation.id,
          cancelRef,
          onClose,
        })
      ) : (
        <AlertDialogFooter borderTopWidth={1}>
          {renderStoringButtonGroup(cancelRef, onClose)}
        </AlertDialogFooter>
      )}
    </FormProvider>
  );
}
