import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';

export const schema = z
  .object({
    organizationGroupId: z.string().nullable(),
  })
  .strict();

export type FieldValues = z.infer<typeof schema>;

export const defaultValues: DefaultValues<FieldValues> = {
  organizationGroupId: undefined,
};
