import { useApolloClient } from '@apollo/client';
import { captureMessage } from '@sentry/nextjs';
import { useCallback } from 'react';
import { AdoptedMedicalProductType } from '@/entities/adoptedMedicalProduct';
import { AdoptedMedicalMaterialUpdateTab } from '@/features/adoptedMedicalMaterialUpdate';
import { AdoptedMedicineUpdateTab } from '@/features/adoptedMedicineUpdate';
import { documentsToRefetch } from './constants';

type Props = {
  medicineId: string;
  type: AdoptedMedicalProductType;
};

export function AdoptedMedicalProductUpdateTab({ medicineId, type }: Props) {
  const apolloClient = useApolloClient();
  const onAfterMutation = useCallback(async () => {
    await apolloClient.refetchQueries({
      include: documentsToRefetch,
    });
  }, [apolloClient]);
  if (type === 'medicine') {
    return <AdoptedMedicineUpdateTab medicineId={medicineId} onAfterMutation={onAfterMutation} />;
  }
  if (type === 'medicalMaterial') {
    return (
      <AdoptedMedicalMaterialUpdateTab medicineId={medicineId} onAfterMutation={onAfterMutation} />
    );
  }
  captureMessage(`unknown AdoptedMedicalProductType - ${type}`);
  return null;
}
