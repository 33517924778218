import { createIcon } from '@chakra-ui/react';

export const OpenedMedicineBoxIcon = createIcon({
  displayName: 'OpenedMedicineBoxIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2H16V4H8V2ZM6 4.00002V0H18V4L18.1176 4.29412L22 3L24 8L20 9.33333V22H4V9.33333L0 8L2 3L5.88236 4.29412L6 4.00002ZM16.4 6L17.2 8H16H14V10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10V8H8H6.8L7.6 6H16.4ZM6 10H8C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10H18V20H6V10ZM19.3736 7.43395L21.3553 6.77339L20.8434 5.49371L18.8617 6.15428L19.3736 7.43395ZM4.62642 7.43396L5.13829 6.15428L3.15658 5.49371L2.64471 6.77339L4.62642 7.43396Z"
      fill="currentColor"
    />
  ),
});
