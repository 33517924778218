import {
  Checkbox,
  GridItem,
  HStack,
  NumberInput,
  NumberInputField,
  Skeleton,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { ErrorTooltip, Typography } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  containerAmountEnabledFieldName: FieldPath<T>;
  containerAmountFieldName: FieldPath<T>;
  isLoading?: boolean;
  containerAmountEnabled: boolean;
} & Pick<UseFormReturn<T>, 'control'>;

/**
 * 容器代の有無と初期値を設定するフォーム
 */
export function ContainerAmountConfigForm<T extends FieldValues>({
  containerAmountEnabledFieldName,
  containerAmountFieldName,
  isLoading = false,
  containerAmountEnabled,
  control,
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Typography variant="body" fontWeight="bold">
          容器代
        </Typography>
      </GridItem>
      <GridItem>
        <HStack spacing={6}>
          <Skeleton isLoaded={!isLoading}>
            <Controller
              name={containerAmountEnabledFieldName}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  isChecked={value}
                  onChange={(ev) => {
                    onChange(ev.target.checked);
                  }}
                >
                  あり
                </Checkbox>
              )}
            />
          </Skeleton>
          <VStack spacing={0} alignItems="flex-start">
            <Typography variant="body2" color="slate.500">
              取引先に容器代を請求する場合に利用します。
            </Typography>
            <Typography variant="body2" color="slate.500">
              容器代の設定を行うと出庫額に容器代を加えた総額が伝票に印字されます。
            </Typography>
          </VStack>
        </HStack>
        <HStack pt="4">
          <Stack spacing={1}>
            <Typography
              variant="body2"
              fontWeight="bold"
              color={!containerAmountEnabled ? 'gray.200' : undefined}
            >
              初期値
            </Typography>
            <Skeleton isLoaded={!isLoading}>
              <Controller
                name={containerAmountFieldName}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumberInput
                    isDisabled={!containerAmountEnabled}
                    min={0}
                    size="sm"
                    defaultValue={field.value}
                    isInvalid={!!error?.message}
                    onChange={(_, valueAsNumber) => {
                      field.onChange(Number.isNaN(valueAsNumber) ? undefined : valueAsNumber);
                    }}
                  >
                    <HStack>
                      <ErrorTooltip
                        label={error?.message}
                        isOpen={!!error?.message}
                        placement="bottom-start"
                      >
                        <NumberInputField
                          autoFocus
                          textAlign="right"
                          paddingInlineEnd={4}
                          bg={error ? 'red.50' : 'white'}
                          w="100px"
                          rounded="base"
                          fontSize="md"
                        />
                      </ErrorTooltip>
                      <Typography
                        variant="body"
                        color={!containerAmountEnabled ? 'gray.200' : undefined}
                      >
                        円
                      </Typography>
                    </HStack>
                  </NumberInput>
                )}
              />
            </Skeleton>
          </Stack>
        </HStack>
      </GridItem>
    </>
  );
}
