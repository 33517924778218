import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  AlertDialogHeader,
  HStack,
  ButtonGroup,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { Typography } from '../../Typography';
import { AlertIcon } from '../../icons';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
  submitButtonText?: string;
};

export function DiscardConfirmationDialog({
  isOpen,
  onClose,
  onDiscard,
  submitButtonText = '変更内容を破棄して閉じる',
}: Props) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      size="md"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader pt={6}>
            <HStack>
              <AlertIcon color="red.500" w={8} h={8} />
              <Typography variant="body" color="red.500" fontWeight="bold">
                保存されていない変更内容があります
              </Typography>
            </HStack>
          </AlertDialogHeader>
          <AlertDialogBody>
            <Typography variant="body">
              保存されていない変更内容を破棄してもよろしいですか？
            </Typography>
          </AlertDialogBody>
          <AlertDialogFooter marginTop={4} paddingBottom={6}>
            <ButtonGroup spacing={4}>
              <Button ref={cancelRef} onClick={onClose} variant="ghost" color="primary.600">
                キャンセル
              </Button>
              <Button onClick={onDiscard}>{submitButtonText}</Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
