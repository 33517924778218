import { Box, Checkbox, HStack, Input, Stack } from '@chakra-ui/react';
import { InfoIcon, Typography, InfoPopover, DatePicker } from '@kkhs/hakari-ui';
import { jaLocale } from '@kkhs/hakari-utils';
import { addYears, format } from 'date-fns';

type Props = {
  /** 次回在庫変動まで発注おすすめを非表示にするかのフラグ */
  shouldHideOrderRecommendUntilNextTransaction: boolean;
  /** おすすめ発注非表示期間 */
  orderRecommendSuspendTerm?: {
    isInf: boolean;
    startDate: Date;
    endDate?: Date;
  };
  /** 採用停止中かどうか */
  nextAdoptionStopped: boolean;
  /** 「表示しない期間を設定する」押下時の処理 */
  onChangeSuspendTermEnabled: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** おすすめ発注非表示期間変更時の処理 */
  onChangeSuspendTerm: (date: Date | null) => void;
  /** 「今後表示しない」押下時の処理 */
  onChangeSuspendInfTermEnabled: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function OrderRecommendSuspendTermField({
  shouldHideOrderRecommendUntilNextTransaction,
  orderRecommendSuspendTerm,
  nextAdoptionStopped,
  onChangeSuspendTermEnabled,
  onChangeSuspendTerm,
  onChangeSuspendInfTermEnabled,
}: Props) {
  return (
    <Stack spacing={2}>
      <HStack alignItems="center" spacing={1} mb={1}>
        <Typography variant="body2" fontWeight="bold" as="span">
          おすすめ発注の非表示期間の設定
        </Typography>
        <InfoPopover
          w="278px"
          content={
            <Stack spacing={3}>
              <Typography variant="body2">
                設定された非表示期間中は、おすすめ発注画面に表示されません。
              </Typography>
              <Typography variant="body2">
                「次回の入出庫までおすすめ発注に表示されません。」と表示されている場合は、非表示期間終了後も、入出庫があるまではおすすめ発注画面上に表示されません。
              </Typography>
            </Stack>
          }
        >
          <InfoIcon boxSize={3} color="slate.500" />
        </InfoPopover>
      </HStack>
      <Stack spacing={2} align="flex-start">
        <Checkbox
          isDisabled={nextAdoptionStopped}
          isChecked={!!orderRecommendSuspendTerm}
          onChange={onChangeSuspendTermEnabled}
        >
          <Typography variant="body">表示しない期間を設定する</Typography>
        </Checkbox>
        <HStack spacing={6}>
          <Box>
            <DatePicker
              value={`${format(
                orderRecommendSuspendTerm?.startDate
                  ? new Date(orderRecommendSuspendTerm.startDate)
                  : new Date(),
                'yyyy/MM/dd(E)',
                {
                  locale: jaLocale,
                },
              )} 〜 ${
                orderRecommendSuspendTerm?.endDate
                  ? format(orderRecommendSuspendTerm.endDate, 'yyyy/MM/dd(E)', {
                      locale: jaLocale,
                    })
                  : ''
              }`}
              minDate={
                orderRecommendSuspendTerm?.startDate
                  ? new Date(orderRecommendSuspendTerm.startDate)
                  : new Date()
              }
              maxDate={addYears(new Date(), 20)}
              selected={orderRecommendSuspendTerm?.endDate}
              onChange={onChangeSuspendTerm}
              customInput={<Input />}
              disabled={
                nextAdoptionStopped ||
                !orderRecommendSuspendTerm ||
                orderRecommendSuspendTerm?.isInf
              }
            />
          </Box>
          <Checkbox
            defaultChecked={orderRecommendSuspendTerm?.isInf}
            onChange={onChangeSuspendInfTermEnabled}
            isDisabled={nextAdoptionStopped || !orderRecommendSuspendTerm}
          >
            <Typography variant="body2">今後表示しない</Typography>
          </Checkbox>
        </HStack>
        {shouldHideOrderRecommendUntilNextTransaction && (
          <Typography variant="body" color="red.500" pt={1}>
            次回の入出庫までおすすめ発注に表示されません。
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
