import { StockSalePriceConfig as GeneratedStockSalePriceConfig } from '@/gql/docs';

// ファルマ売却では各フィールドの値が以下の固定値をとる
// GraphQLスキーマで固定値を返してくれればこのような型定義は不要
export const stockSalePriceConfig = {
  itemRoundingMethod: 'roundDown',
  shouldShowTax: true,
  taxCalculationTarget: 'operation',
  taxRoundingMethod: 'round',
  totalRoundingMethod: null,
} as const;

export type StockSalePriceConfig = typeof stockSalePriceConfig;

export function isStockSalePriceConfig(
  config: GeneratedStockSalePriceConfig,
): config is StockSalePriceConfig {
  return (
    config.itemRoundingMethod === stockSalePriceConfig.itemRoundingMethod &&
    config.shouldShowTax === stockSalePriceConfig.shouldShowTax &&
    config.taxCalculationTarget === stockSalePriceConfig.taxCalculationTarget &&
    config.taxRoundingMethod === stockSalePriceConfig.taxRoundingMethod &&
    config.totalRoundingMethod === stockSalePriceConfig.totalRoundingMethod
  );
}
