import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { isFewPrescriptionFrequency, PrescriptionFrequencyType } from '@/entities/prescription';
import { MedicineSummaryTabQuery } from '@/gql/docs';

type Props = {
  /** 処方頻度タイプ */
  prescriptionFrequencyType?: PrescriptionFrequencyType;
  /** 発注点情報 */
  orderPointForecast: Omit<
    NonNullable<MedicineSummaryTabQuery['orderPointForecast']>,
    'convertedUnitQuantities'
  >;
  /** 欠品注意ラベルがあるかのフラグ */
  hasStockoutPreventionLabel: boolean;
};

export function OrderPointForecastCaptionByFixed({
  prescriptionFrequencyType,
  hasStockoutPreventionLabel,
  orderPointForecast,
}: Props) {
  const { stockoutPreventionFactor, baseStockUnitQuantity, safetyFactor } = orderPointForecast;
  const stockoutPreventionText = isFewPrescriptionFrequency(prescriptionFrequencyType)
    ? '※少数処方には欠品注意ラベルの係数2は適用されません。'
    : '※固定発注点には欠品注意ラベルの係数2は適用されません。';

  return (
    <Typography variant="body2" color="slate.500">
      {/* 基準在庫量 × 安全係数 */}
      {`基準在庫量 ${baseStockUnitQuantity}×係数 ${safetyFactor}`}
      {/** 欠品係数 */}
      {(hasStockoutPreventionLabel || !isNullOrUndefined(stockoutPreventionFactor)) &&
        stockoutPreventionText}
    </Typography>
  );
}
