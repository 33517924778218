import { Circle } from '@chakra-ui/react';

type Props = {
  /** 表示件数 */
  count: number;
};

/** 件数を表示する円形のバッジ */
export function CountBadge({ count }: Props) {
  return (
    <Circle
      display="inline-flex"
      ml="1"
      color="white"
      minW="2.5em"
      minH="2.5em"
      bg="slate.500"
      fontSize="xx-small"
    >
      {count < 100 ? count : '99+'}
    </Circle>
  );
}
