import { Button } from '@chakra-ui/react';
import { ButtonGroup } from '@kkhs/hakari-ui';

type Props = {
  onClose: () => void;
  onSubmit?: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
  isDisabledCancelButton: boolean;
  isDisabledSubmitButton: boolean;
  isLoadingSubmitButton: boolean;
};

export function ShippingStockOperationDeleteButtonGroupLayout({
  onClose,
  onSubmit,
  cancelRef,
  isDisabledCancelButton,
  isDisabledSubmitButton,
  isLoadingSubmitButton,
}: Props) {
  return (
    <ButtonGroup>
      <Button
        w="118px"
        variant="ghost"
        onClick={onClose}
        ref={cancelRef}
        isDisabled={isDisabledCancelButton}
      >
        キャンセル
      </Button>
      <Button
        w="104px"
        backgroundColor="red.500"
        _hover={{ bg: 'red.500' }}
        onClick={onSubmit}
        isLoading={isLoadingSubmitButton}
        isDisabled={isDisabledSubmitButton}
      >
        削除する
      </Button>
    </ButtonGroup>
  );
}
