import { atom } from 'recoil';
import { recoilAtomKeys } from '@/shared/constants';
import { AuthState } from './types';

/**
 * 認証ステータス、ログインユーザ情報, OGAS組織グループ情報を保持する
 */
export const authAtom = atom<AuthState>({
  key: recoilAtomKeys.AUTH_STATE,
  default: { pharmacyStaff: undefined, organizationGroups: undefined },
});
