import { createIcon } from '@chakra-ui/react';

export const NormalPrescriptionPatientIcon = createIcon({
  displayName: 'NormalPrescriptionPatientIcon',
  viewBox: '0 0 25 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.001 12C15.2101 12 17.001 10.2091 17.001 8C17.001 5.79086 15.2101 4 13.001 4C10.7919 4 9.001 5.79086 9.001 8C9.001 10.2091 10.7919 12 13.001 12ZM13.001 20H19.001C19.001 15 18.001 14 13.001 14C8.001 14 7.001 15 7.001 20H13.001ZM2.00002 11C2.00002 12.6569 3.34317 14 5.00002 14C6.65688 14 8.00002 12.6569 8.00002 11C8.00002 9.34315 6.65688 8 5.00002 8C3.34317 8 2.00002 9.34315 2.00002 11ZM4.00002 11C4.00002 11.5523 4.44774 12 5.00002 12C5.55231 12 6.00002 11.5523 6.00002 11C6.00002 10.4477 5.55231 10 5.00002 10C4.44774 10 4.00002 10.4477 4.00002 11ZM4.99959 19.9992H5.00017C5.00024 18.1445 5.19139 16.672 5.66232 15.5228C5.44324 15.5064 5.22142 15.5002 4.99959 15.5002C3 15.5002 0.998291 16 0.998291 19.9992H4.99959ZM20.999 14C22.6559 14 23.999 12.6569 23.999 11C23.999 9.34315 22.6559 8 20.999 8C19.3422 8 17.999 9.34315 17.999 11C17.999 12.6569 19.3422 14 20.999 14ZM20.999 12C21.5513 12 21.999 11.5523 21.999 11C21.999 10.4477 21.5513 10 20.999 10C20.4468 10 19.999 10.4477 19.999 11C19.999 11.5523 20.4468 12 20.999 12ZM20.9989 19.9992H20.9995H25.0008C25.0008 16.0002 23 15.5002 20.9995 15.5002C20.7776 15.5002 20.5558 15.5064 20.3367 15.5227C20.8077 16.672 20.9988 18.1445 20.9989 19.9992Z"
      fill="currentColor"
    />
  ),
});
