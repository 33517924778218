import { createIcon } from '@chakra-ui/react';

export const NarcoticAttentionIcon = createIcon({
  displayName: 'NarcoticAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M5.73001 13.25C5.73001 14.75 5.61001 16.98 4.81001 18.48C4.69001 18.34 4.36001 18.11 4.20001 18.03C4.93001 16.63 5.00001 14.66 5.00001 13.25V10.56H8.83001V9.42001H9.59001V10.56H13.37V11.26H5.73001V13.25ZM9.20001 15.92C9.01001 15.65 8.56001 15.15 8.18001 14.75V18.52H7.51001V14.71C7.13001 15.71 6.62001 16.62 6.07001 17.17C5.96001 17.01 5.74001 16.78 5.59001 16.66C6.30001 16.04 6.97001 14.81 7.35001 13.6H6.02001V12.96H7.51001V11.5H8.18001V12.96H9.51001V13.6H8.18001V13.98C8.54001 14.29 9.39001 15.11 9.61001 15.33L9.20001 15.92ZM11.98 13.6C12.36 14.76 13.01 15.98 13.63 16.6C13.48 16.71 13.25 16.94 13.13 17.11C12.62 16.51 12.12 15.49 11.75 14.45V18.52H11.08V14.6C10.66 15.65 10.09 16.62 9.51001 17.2C9.39001 17.05 9.17001 16.82 9.03001 16.7C9.76001 16.07 10.5 14.82 10.92 13.6H9.73001V12.96H11.08V11.5H11.75V12.96H13.36V13.6H11.98Z"
        fill="#5D6771"
      />
      <path
        d="M20.34 16.15C21.22 16.9 22.6 17.51 23.91 17.79C23.75 17.93 23.55 18.19 23.47 18.37C22.06 18 20.59 17.23 19.66 16.28V18.52H18.94V16.28C17.99 17.22 16.51 18.02 15.17 18.42C15.09 18.24 14.88 17.98 14.72 17.85C15.94 17.53 17.3 16.89 18.2 16.15H14.94V15.51H18.94V14.93H17.52V11.75H18.74C18.84 11.49 18.95 11.19 19.01 10.97L19.72 11.1C19.62 11.33 19.53 11.55 19.43 11.75H21.17V14.93H19.66V15.51H23.72V16.15H20.34ZM17.33 14.1C16.6 14.46 15.82 14.83 15.18 15.11L14.87 14.5C15.46 14.27 16.32 13.89 17.17 13.52L17.33 14.1ZM17.23 10.73H14.94V10.09H17.23V9.41H17.97V10.09H20.64V9.41H21.38V10.09H23.74V10.73H21.38V11.5H20.64V10.73H17.97V11.5H17.23V10.73ZM15.73 11.45C16.26 11.81 16.86 12.36 17.12 12.77L16.57 13.19C16.31 12.79 15.72 12.21 15.2 11.83L15.73 11.45ZM18.19 13.08H20.48V12.28H18.19V13.08ZM18.19 14.4H20.48V13.58H18.19V14.4ZM23.3 15.13C22.85 14.74 21.94 14.19 21.21 13.83L21.63 13.37C22.35 13.69 23.27 14.22 23.73 14.6L23.3 15.13ZM23.5 11.71C23.04 12.2 22.43 12.73 21.95 13.06L21.4 12.76C21.87 12.41 22.51 11.81 22.87 11.38L23.5 11.71Z"
        fill="#5D6771"
      />
    </g>
  ),
});
