import { createIcon } from '@chakra-ui/react';

export const CsvDownloadIcon = createIcon({
  displayName: 'CsvDownloadIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H4V22H6H18H20V8V7L15 2H14H6ZM18 20V8H14V4H6V20H18ZM13 9H11V13.5L9 11.5L7.5 13L12 17.5L16.5 13L15 11.5L13 13.5V9Z"
      fill="currentColor"
    />
  ),
});
