import { useQuery } from '@apollo/client';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { Typography, useZodForm } from '@kkhs/hakari-ui';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { graphql } from '@/gql';
import { TransactionType } from '@/gql/apollo';
import { dateToAWSDate } from '@/shared/utils';
import { MedicineTransactionListItemsTable } from './MedicineTransactionListItemsTable';
import { SearchSection } from './SearchSection';
import { defaultValues, FieldValues, schema } from './schema';

type Props = {
  medicineId: string;
  stockUnitSymbol: string;
  scrollParentRef: React.MutableRefObject<null>;
};

const queryDoc = graphql(`
  query medicineTransactionListItems(
    $medicineId: ID!
    $filter: MedicineTransactionListItemsFilter!
    $cursor: String
  ) {
    medicineTransactionListItems(
      medicineId: $medicineId
      filter: $filter
      first: 50
      after: $cursor
    ) {
      edges {
        node {
          id
          transaction {
            id
            date
            transactionType
            reason
            disposalReason
            unitQuantity
            lotNumber
            memo
            updatedAt
          }
          stockUnitQuantityAfterTransaction
          transactionTarget
          isEditable
          stockOperationId
          patientId
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export function useMedicineTransactionListItemsTabForm() {
  return useZodForm({
    schema,
    defaultValues,
  });
}

export function MedicineTransactionListItemsTab({
  medicineId,
  stockUnitSymbol,
  scrollParentRef,
}: Props) {
  const { watch } = useFormContext<FieldValues>();
  const currentValues = watch();
  const medicineTransactionListItemFilter = useMemo(() => {
    const { consumptionChecked, replenishmenthecked, fromDate, toDate } = currentValues;
    const transactionTypes: TransactionType[] = [];
    if (consumptionChecked) {
      transactionTypes.push('consumption');
    }
    if (replenishmenthecked) {
      transactionTypes.push('replenishment');
    }

    return {
      transactionTypes,
      fromDate: fromDate ? dateToAWSDate(fromDate) : undefined,
      toDate: toDate ? dateToAWSDate(toDate) : undefined,
    };
  }, [currentValues]);

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(queryDoc, {
    fetchPolicy: 'cache-and-network',
    variables: {
      medicineId,
      filter: medicineTransactionListItemFilter,
    },
  });
  const loadMore = () =>
    fetchMore({
      variables: {
        cursor: data?.medicineTransactionListItems.pageInfo.endCursor,
      },
    });
  const hasMore = data?.medicineTransactionListItems.pageInfo.hasNextPage;
  const edges = data?.medicineTransactionListItems.edges ?? [];

  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;
  const { pathname } = useRouter();

  useEffect(() => {
    userMonitoringRepository.sendEvent({
      key: '医薬品詳細モーダルの入出庫履歴タブを開く',
      contexts: {
        pharmacyId,
        musubiCode,
        開いたURL: pathname,
      },
    });
  }, [musubiCode, pharmacyId, userMonitoringRepository, pathname]);

  return (
    <HStack alignItems="baseline" spacing={0}>
      <Stack spacing={0} flex={1}>
        <SearchSection />
        {isLoading || edges.length > 0 ? (
          <Box px={6} pb={4} pt={2}>
            <MedicineTransactionListItemsTable
              hasMore={hasMore}
              loadMore={loadMore}
              edges={edges}
              scrollParentRef={scrollParentRef}
              isLoading={isLoading}
              stockUnitSymbol={stockUnitSymbol}
            />
          </Box>
        ) : (
          // TODO: 実際のディスプレイのサイズに合わせて調整
          <Stack align="center" pt="130px">
            <Typography variant="body">入出庫履歴はありません</Typography>
          </Stack>
        )}
      </Stack>
    </HStack>
  );
}
