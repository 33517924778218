import { NumberInput } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { MAX_UNIT_QUANTITY } from '@/entities/stockOperation';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
};

export function StockUnitQuantityForm<T extends FieldValues>({ name }: Props<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <NumberInput
          size="sm"
          w="104px"
          options={{
            defaultValue: value ?? 0,
            onChange: (_, numValue) => onChange(Number.isFinite(numValue) ? numValue : 0),
            precision: 3,
            max: MAX_UNIT_QUANTITY,
          }}
        />
      )}
    />
  );
}
