import { useMutation } from '@apollo/client';
import { showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { UseFormReturn } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import {
  useStopOrRestartAdoptedMedicalProduct,
  useUpdateOrderRecommendSuspendTerm,
} from '@/entities/adoptedMedicalProduct';
import { getOrderPointSettingInput } from '@/entities/adoptedMedicine';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { checkDuplicatedStorageNames } from '@/entities/storage';
import { graphql } from '@/gql';
import { FieldValues } from '../../schema';

const updateMutationDoc = graphql(`
  mutation UpdateAdoptedMedicalMaterial($input: UpdateAdoptedMedicalMaterialInput!) {
    updateAdoptedMedicalMaterial(input: $input) {
      userErrors {
        ... on ValidationError {
          message
          path
        }
        ... on DuplicateMappingYjCodeError {
          message
          path
        }
        ... on MappingYjCodeConflictWithMedicineYjCodeError {
          message
          path
        }
      }
    }
  }
`);

type Args = {
  medicineId: string;
  /** mutation 成功後の callback */
  onAfterMutation: () => Promise<void>;
  useFormReturn: UseFormReturn<FieldValues>;
};

export function useAdoptedMedicalMaterialUpdateButtonProps({
  medicineId,
  onAfterMutation,
  useFormReturn: {
    handleSubmit,
    reset,
    setError,
    formState: { isValid, isDirty, dirtyFields, isSubmitting },
  },
}: Args) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;
  const { stopOrRestartAdoptedMedicalProduct, isSubmitting: isStoppingOrRestarting } =
    useStopOrRestartAdoptedMedicalProduct({
      medicineId,
      onAfterMutation,
      sendEvent: (isStop: boolean) => {
        userMonitoringRepository.sendEvent({
          key: isStop
            ? '医薬品詳細モーダル_医薬品の採用設定タブ_医薬品の採用停止_SUBMITTED'
            : '医薬品詳細モーダル_医薬品の採用設定タブ_医薬品の採用停止解除_SUBMITTED',
          contexts: { pharmacyId, musubiCode },
        });
      },
    });
  const { updateOrderRecommendSuspendTerm, isSubmitting: isUpdateOrderRecommendSuspendTerm } =
    useUpdateOrderRecommendSuspendTerm({
      medicineId,
    });
  const [updateAdoptedMedicalMaterial, { loading: isUpdating }] = useMutation(updateMutationDoc, {
    onCompleted: async (data) => {
      const userError = data.updateAdoptedMedicalMaterial.userErrors?.[0];
      if (userError?.__typename === 'ValidationError') {
        showErrorToast({
          id: 'update-adopted-medical-material-error',
          title: 'エラーが発生しました。',
        });
        return;
      }
      if (userError?.__typename === 'DuplicateMappingYjCodeError') {
        showErrorToast({
          id: 'update-adopted-medical-material-duplicate-mapping-yj-code-error',
          title: '指定した連携用YJコードは既に他の医療材料で使用されています。',
        });
        return;
      }
      if (userError?.__typename === 'MappingYjCodeConflictWithMedicineYjCodeError') {
        showErrorToast({
          id: 'update-adopted-medical-material-mapping-yj-code-conflict-with-medicine-yj-code-error',
          title: '指定した連携用YJコードは他の医薬品のYJコードとして使用されています。',
        });
        return;
      }
      showSuccessToast({
        id: 'update-adopted-medical-material-success',
        title: '採用薬を編集しました。',
      });
      await onAfterMutation();
    },
    onError: () =>
      showErrorToast({
        id: 'update-adopted-medical-material-error',
        title: 'エラーが発生しました。',
      }),
  });

  const onClickUpdate = handleSubmit(
    async ({
      adoptionStopped,
      wholesaleId,
      medicinePackageUnitId,
      storages,
      orderRecommendSuspendTerm,
      mappingYjCode,
      orderPointSetting,
    }) => {
      if (dirtyFields.adoptionStopped) {
        await stopOrRestartAdoptedMedicalProduct(adoptionStopped);
      }

      // 発注おすすめ停止期間
      if (dirtyFields.orderRecommendSuspendTerm) {
        await updateOrderRecommendSuspendTerm(orderRecommendSuspendTerm);
      }

      if (
        dirtyFields.wholesaleId ||
        dirtyFields.medicinePackageUnitId ||
        dirtyFields.storages ||
        dirtyFields.orderRecommendSuspendTerm ||
        dirtyFields.mappingYjCode ||
        dirtyFields.orderPointSetting
      ) {
        // model 配下に移動できないか検討する
        const filteredStorages = storages.filter(
          (s): s is { id: string; name: string } => !!s.id && !!s.name,
        );
        if (filteredStorages !== null) {
          const duplicatedIndexes = checkDuplicatedStorageNames(filteredStorages);
          if (duplicatedIndexes.length > 0) {
            duplicatedIndexes.forEach((i) => {
              setError(`storages.${Number(i)}.id`, {
                type: 'custom',
                message: '置き場所が重複しています。',
              });
            });
            return;
          }
        }

        await updateAdoptedMedicalMaterial({
          variables: {
            input: {
              medicineId,
              primaryWholesaleId: wholesaleId,
              medicinePackageUnitId,
              storageIds: filteredStorages?.map((s) => s.id),
              mappingYjCode,
              orderPointSetting: getOrderPointSettingInput(orderPointSetting),
            },
          },
        });
      }

      // TODO: 各 Mutation が失敗した時には reset しないようにする
      reset({
        adoptionStopped,
        wholesaleId,
        medicinePackageUnitId,
        storages,
        orderRecommendSuspendTerm,
        mappingYjCode,
        orderPointSetting,
      });
    },
  );

  return {
    onClickUpdate,
    isSubmitting:
      isSubmitting || isUpdating || isStoppingOrRestarting || isUpdateOrderRecommendSuspendTerm,
    isDisabled: !isDirty || !isValid,
  };
}
