import { LoadedShippingStockOperationDeleteButtonGroup } from './LoadedShippingStockOperationDeleteButtonGroup';
import { LoadingShippingStockOperationDeleteButtonGroup } from './LoadingShippingStockOperationDeleteButtonGroup';

type Props = {
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
  loading: boolean;
};

export function ShippingStockOperationDeleteButtonGroup({ onClose, cancelRef, loading }: Props) {
  if (loading) {
    return (
      <LoadingShippingStockOperationDeleteButtonGroup cancelRef={cancelRef} onClose={onClose} />
    );
  }
  return <LoadedShippingStockOperationDeleteButtonGroup cancelRef={cancelRef} onClose={onClose} />;
}
