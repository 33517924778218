import { captureMessage } from '@sentry/nextjs';
import { useCallback, useEffect } from 'react';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacyActions, currentPharmacySelectors } from '@/entities/currentPharmacy';
import { recoilAtomKeys } from '@/shared/constants';
import { authActions } from '../../../store/auth';

type Props = {
  children: React.ReactNode;
};

export function PharmacyRecoilRoot({ children }: Props) {
  const copyAuthState = authActions.useCopyState();
  const copyCurrentPharmacyState = currentPharmacyActions.useCopyState();
  const currentPharmacy = currentPharmacySelectors.useValue();

  const initializeState = useCallback(
    (mutableSnapshot: MutableSnapshot) => {
      copyAuthState(mutableSnapshot);
      copyCurrentPharmacyState(mutableSnapshot);
    },
    [copyAuthState, copyCurrentPharmacyState],
  );

  useEffect(() => {
    repositoryContainerGetter.userMonitoring().updateUserAttribute({
      pharmacyId: currentPharmacy?.id,
      musubiCode: currentPharmacy?.musubiCode,
    });
  }, [currentPharmacy?.id, currentPharmacy?.musubiCode]);

  const handleChangeStorage = useCallback(
    ({ key, newValue }: StorageEvent) => {
      if (key !== recoilAtomKeys.CURRENT_PHARMACY_STATE) return;

      // NOTE: removeItem の場合は newValue が null になる
      if (newValue === null) {
        window.location.reload();
      } else {
        try {
          const newCurrentPharmacy = JSON.parse(newValue);
          if (newCurrentPharmacy.id !== currentPharmacy?.id) {
            window.location.reload();
          }
        } catch (error) {
          captureMessage('localStorageEffect JSON parse error', {
            level: 'warning',
            contexts: {
              error: {
                detail: JSON.stringify(error, null, 2),
              },
            },
          });
        }
      }
    },
    [currentPharmacy?.id],
  );

  useEffect(() => {
    window.addEventListener('storage', handleChangeStorage);
    return () => window.removeEventListener('storage', handleChangeStorage);
  }, [handleChangeStorage]);

  return <RecoilRoot initializeState={initializeState}>{children}</RecoilRoot>;
}
