import { chakra } from '@chakra-ui/react';
import { DynamicSizedNumberText } from '@kkhs/hakari-ui';
import { isNullOrUndefined, round } from '@kkhs/hakari-utils';
import { ConvertedStockUnitQuantityWithSymbol, StockUnitSymbolIcon } from '@/entities/medicine';
import { OrderPointUnitQuantityWithSymbol } from './OrderPointUnitQuantityWithSymbol';
import { Row } from '../../../types';
import { CellBase } from '../CellBase';

const mapping = {
  9: '12px',
  8: '16px',
};

export function StockUnitQuantityCell({
  medicine: { stockUnitSymbol, stockUnitQuantity },
  surplusStockUnitQuantity,
  convertedOthersMedicineStockUnitQuantities,
}: Row) {
  // NOTE: 発注点 = 現在の在庫数 - 余剰在庫数
  const orderPointUnitQuantity = !isNullOrUndefined(surplusStockUnitQuantity)
    ? round(stockUnitQuantity - surplusStockUnitQuantity, 3)
    : null;

  const convertedOrderPointUnitQuantity =
    !isNullOrUndefined(convertedOthersMedicineStockUnitQuantities) &&
    !isNullOrUndefined(convertedOthersMedicineStockUnitQuantities.surplusStockUnitQuantity)
      ? round(
          convertedOthersMedicineStockUnitQuantities.stockUnitQuantity -
            convertedOthersMedicineStockUnitQuantities.surplusStockUnitQuantity,
          3,
        )
      : null;

  return (
    <CellBase isNumeric data-testid="在庫数量&発注点ブロック">
      <chakra.span>
        <DynamicSizedNumberText
          mapping={mapping}
          fontWeight="bold"
          as="span"
          verticalAlign="middle"
          data-testid="在庫数量"
        >
          {stockUnitQuantity}
        </DynamicSizedNumberText>
        <StockUnitSymbolIcon name={stockUnitSymbol} boxSize={4} />
      </chakra.span>
      {!isNullOrUndefined(convertedOthersMedicineStockUnitQuantities) && (
        <ConvertedStockUnitQuantityWithSymbol
          fontSize="12px"
          paddingBottom={1}
          convertedStockUnitQuantity={convertedOthersMedicineStockUnitQuantities.stockUnitQuantity}
          convertedStockUnitSymbol={convertedOthersMedicineStockUnitQuantities.stockUnitSymbol}
        />
      )}
      {!isNullOrUndefined(orderPointUnitQuantity) && (
        <>
          <OrderPointUnitQuantityWithSymbol
            value={orderPointUnitQuantity}
            stockUnitSymbol={stockUnitSymbol}
            valueDataTestId="発注点"
          />
          {!isNullOrUndefined(convertedOthersMedicineStockUnitQuantities) &&
            !isNullOrUndefined(convertedOrderPointUnitQuantity) && (
              <ConvertedStockUnitQuantityWithSymbol
                fontSize="12px"
                paddingBottom={1}
                convertedStockUnitQuantity={convertedOrderPointUnitQuantity}
                convertedStockUnitSymbol={
                  convertedOthersMedicineStockUnitQuantities.stockUnitSymbol
                }
                showHyphenForQuantity={convertedOrderPointUnitQuantity < 0}
                dataTestId="レセコン変換発注点"
              />
            )}
        </>
      )}
    </CellBase>
  );
}
