import { Table, Thead, Tr, Th, Tbody, Td, HStack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { round } from '@kkhs/hakari-utils';
import { MedicineDetailTabQuery } from '@/gql/apollo';
import { formatAWSDate } from '@/shared/utils';

type Props = {
  orderableMedicinePackageUnits: MedicineDetailTabQuery['orderableMedicinePackageUnits'];
  stockUnitPrice: number;
};

export function MedicinePackageUnitTable({ orderableMedicinePackageUnits, stockUnitPrice }: Props) {
  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th w="120px" pl={4}>
            JANコード
          </Th>
          <Th w="136px">販売元メーカー</Th>
          <Th w="252px">規格容量</Th>
          <Th w="88px">販売開始</Th>
          <Th w="88px">発売中止</Th>
          <Th w="88px">製造中止</Th>
          <Th w="120px" pr={4} isNumeric>
            包装薬価
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {orderableMedicinePackageUnits.map(
          ({
            id,
            oneBoxQuantity,
            janCode,
            medicineSupplier,
            outline,
            salesStartDate,
            salesStopDate,
            discontinuedDate,
          }) => (
            <Tr key={id}>
              <Td pl={4}>{janCode}</Td>
              <Td>{medicineSupplier.name}</Td>
              <Td>{outline}</Td>
              <Td>{salesStartDate ? formatAWSDate(salesStartDate) : '-'}</Td>
              <Td>{salesStopDate ? formatAWSDate(salesStopDate) : '-'}</Td>
              <Td>{discontinuedDate ? formatAWSDate(discontinuedDate) : '-'}</Td>
              <Td pr={4} isNumeric>
                {/* TODO: https://github.com/kkhs/hakari-backend/issues/959 */}
                <HStack spacing={0.5} justifyContent="flex-end">
                  <Typography variant="body2" isNumeric>
                    {round(oneBoxQuantity * stockUnitPrice, 2)}
                  </Typography>
                  <Typography variant="caption">円</Typography>
                </HStack>
              </Td>
            </Tr>
          ),
        )}
      </Tbody>
    </Table>
  );
}
