/* eslint-disable no-underscore-dangle */
import { Box } from '@chakra-ui/react';
import { forwardRef, ReactElement, Ref } from 'react';
import RSAsyncSelect, { AsyncProps } from 'react-select/async';
import { useSelectStyles } from '../hooks';
import { ClearIndicator, DropdownIndicator } from '../indicators';
import { IconOption } from '../option/IconOption';
import { SelectOptionType, SelectProps, SelectInstanceType } from '../types';

export type AsyncSelectProps<T = string> = Omit<SelectProps<T>, 'options'> & {
  loadOptions: AsyncProps<
    SelectOptionType<T>,
    false,
    { options: SelectOptionType<T>[] }
  >['loadOptions'];
  defaultOptions?: AsyncProps<
    SelectOptionType<T>,
    false,
    { options: SelectOptionType<T>[] }
  >['defaultOptions'];
};

function _AsyncSelect<T = string>(
  {
    size = 'md',
    onMouseOver,
    onChange,
    onClear,
    isDisabled = false,
    isWordWrapped = false,
    hiddenDropdownIndicator = false,
    placeholder = '選択してください',
    noOptionsMessage = '選択肢がありません',
    isInvalid = false,
    isClearable = true,
    defaultOptions = true,
    menuPortalTarget = document.body,
    ...rest
  }: Omit<AsyncSelectProps<T>, 'ref'>,
  ref?: Ref<SelectInstanceType<T>>,
) {
  const selectStyles = useSelectStyles<T>({ size, isWordWrapped, isInvalid });
  return (
    <Box onMouseOver={onMouseOver} onTouchStart={onMouseOver}>
      <RSAsyncSelect<SelectOptionType<T>>
        {...rest}
        ref={ref}
        placeholder={placeholder}
        components={{
          DropdownIndicator: hiddenDropdownIndicator ? undefined : DropdownIndicator,
          ClearIndicator,
          Option: IconOption,
        }}
        styles={selectStyles}
        menuPortalTarget={menuPortalTarget}
        onChange={(opt, actionMeta) => {
          if (actionMeta.action === 'clear') {
            onClear?.();
          } else if (opt) {
            onChange(opt.value);
          }
        }}
        noOptionsMessage={() => noOptionsMessage}
        isDisabled={isDisabled}
        isClearable={isClearable}
        defaultOptions={defaultOptions}
      />
    </Box>
  );
}

export const AsyncSelect = forwardRef(_AsyncSelect) as <T = string>(
  props: Omit<AsyncSelectProps<T>, 'ref'> & {
    ref?: Ref<SelectInstanceType<T>>;
  },
) => ReactElement;
