import { isNullOrUndefined } from '@kkhs/hakari-utils';
import Big from 'big.js';
import {
  ExternalStockOperationPriceConfig,
  InternalStockOperationPriceConfig,
  StockSalePriceConfig,
} from '@/gql/docs';
import { calculatePriceV2 } from '@/shared/utils';

/**
 * 明細の出庫金額を返す
 */
export function calculateSubTotalAmount({
  unitPrice,
  stockUnitQuantity,
  stockOperationPriceConfig: { itemRoundingMethod },
}: {
  unitPrice: number | null;
  stockUnitQuantity: number | null;
  stockOperationPriceConfig:
    | ExternalStockOperationPriceConfig
    | InternalStockOperationPriceConfig
    | StockSalePriceConfig;
}) {
  if (
    isNullOrUndefined(unitPrice) ||
    isNullOrUndefined(stockUnitQuantity) ||
    Number.isNaN(stockUnitQuantity) ||
    Number.isNaN(unitPrice)
  )
    return undefined;
  // NOTE: 端数処理。itemRoundingMethod を利用する
  return calculatePriceV2(
    new Big(stockUnitQuantity).times(unitPrice),
    itemRoundingMethod,
  ).toNumber();
}
