import { TableRowProps, Tr } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { ElementRef, ReactElement, cloneElement, forwardRef } from 'react';

type Props = {
  customComponent: (row: Row<unknown> | undefined) => ReactElement | undefined;
  row: Row<unknown> | undefined;
} & TableRowProps;

export const TbodyTr = forwardRef<ElementRef<typeof Tr>, Props>(
  ({ customComponent, row, ...rest }, ref) =>
    cloneElement(customComponent?.(row) || <Tr />, { ...rest, ref }),
);

TbodyTr.displayName = 'TbodyTr';
