import { GridItem, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { RadioGroup } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isLoading?: boolean;
} & Pick<UseFormReturn<T>, 'control'>;

export function ItemRoundingMethodForm<T extends FieldValues>({
  name,
  isLoading = false,
  control,
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Text fontWeight="bold">明細表示</Text>
      </GridItem>
      <GridItem>
        <HStack>
          <Stack spacing={1}>
            <Text fontSize="sm" fontWeight="bold">
              明細端数処理
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    options={[
                      { value: 'none', label: '処理しない' },
                      { value: 'round', label: '四捨五入' },
                      { value: 'roundDown', label: '切り捨て' },
                      { value: 'roundUp', label: '切り上げ' },
                    ]}
                    value={value}
                    onChange={onChange}
                    itemMinWidth="28"
                  />
                )}
              />
            </Skeleton>
          </Stack>
        </HStack>
      </GridItem>
    </>
  );
}
