import { Center, HStack, Spinner } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { useMedicineDetailTabQuery } from '@/gql/apollo';
import { MedicineDetailTabContent } from './MedicineDetailTabContent';

type Props = {
  medicineId: string;
};

export function AdoptedMedicalProductDetailTab({ medicineId }: Props) {
  const { data, loading: isLoading } = useMedicineDetailTabQuery({
    variables: { medicineId },
  });
  if (isLoading) {
    return (
      <Center h="100%">
        <HStack>
          <Spinner color="primary.500" emptyColor="gray.200" />
        </HStack>
      </Center>
    );
  }
  if (!data?.adoptedMedicines.edges[0]?.node || !data?.orderableMedicinePackageUnits) {
    return (
      <Center h="100%">
        <HStack>
          <Typography variant="body">
            医薬品情報を取得できませんでした。リロードしてください。
          </Typography>
        </HStack>
      </Center>
    );
  }

  const medicineCommonName = data?.medicineUnifiedGroup?.commonName;

  return (
    <MedicineDetailTabContent
      fragment={data.adoptedMedicines.edges[0].node}
      medicineCommonName={medicineCommonName}
      orderableMedicinePackageUnits={data.orderableMedicinePackageUnits}
    />
  );
}
