import { createIcon } from '@chakra-ui/react';

export const WarningOutlineIcon = createIcon({
  displayName: 'WarningOutlineIcon',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 24C4 12.96 12.96 4 24 4C35.04 4 44 12.96 44 24C44 35.04 35.04 44 24 44C12.96 44 4 35.04 4 24ZM8 24C8 15.168 15.168 8 24 8C32.832 8 40 15.168 40 24C40 32.832 32.832 40 24 40C15.168 40 8 32.832 8 24Z"
        fill="currentColor"
      />
      <path d="M27 36H21V30H27V36ZM27 26H21V12H27V26Z" fill="currentColor" />
    </>
  ),
});
