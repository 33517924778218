import { GridItem, Input, Skeleton, Text } from '@chakra-ui/react';
import { RequiredTag } from '@kkhs/hakari-ui';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isLoading?: boolean;
} & Pick<UseFormReturn<T>, 'register' | 'formState'>;

export function CounterpartyNameForm<T extends FieldValues>({
  name,
  isLoading = false,
  register,
  formState: { errors },
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Text fontWeight="bold">
          取引先名称
          <RequiredTag ml={2} />
        </Text>
      </GridItem>
      <GridItem>
        <Skeleton isLoaded={!isLoading} borderRadius="md">
          <Input {...register(name)} placeholder="株式会社カケハシ" />
          {errors?.name?.message ? (
            <Text fontSize="sm" color="red.500">
              {errors.name.message}
            </Text>
          ) : (
            <Text fontSize="sm" color="text.light">
              32文字以内で入力してください
            </Text>
          )}
        </Skeleton>
      </GridItem>
    </>
  );
}
