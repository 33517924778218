import { createIcon } from '@chakra-ui/react';

export const CassetteUnitSymbolIcon = createIcon({
  displayName: 'Cassette',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1927_1693)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.46003 3.1001H6.50003V3.6701C6.50003 4.3601 6.44003 5.6801 6.30003 6.1201C6.25003 6.2701 6.17003 6.5101 5.65003 6.5101C5.19003 6.5101 4.83003 6.4501 4.42003 6.3801L4.30003 6.3601V7.1801L4.44003 7.2101L4.48294 7.21729C4.71833 7.2569 5.03449 7.3101 5.59003 7.3101C5.90003 7.3101 6.74003 7.3101 7.00003 6.6601C7.26003 6.0201 7.28003 4.2201 7.28003 3.6801V2.3001H4.53003C4.56003 1.9201 4.58003 1.5301 4.58003 1.1001H3.78003C3.78003 1.5401 3.76003 1.9301 3.72003 2.3001H1.48003V3.1001H3.62003C3.07003 6.3001 1.28003 6.8001 1.28003 6.8001L1.69003 7.5001C1.69003 7.5001 3.86003 6.9301 4.44003 3.1001H4.46003ZM10.0001 3.2101V5.4801C10.0001 6.2301 10.1401 6.3001 10.8301 6.3001H14.0001V7.1001H10.8601C9.84007 7.1001 9.20007 6.8601 9.20007 5.6501V3.2801L8.20007 3.3701V2.5701L9.20007 2.4901V1.1001H10.0001V2.4201L14.0001 2.1001L13.9801 2.2201C13.7901 3.7501 13.1601 4.6801 11.8001 5.3001L11.4001 4.7001C12.6501 4.1001 12.9801 3.4501 13.1001 2.9301L10.0001 3.2101ZM4.24998 11.6C4.24998 11.6 4.10998 10.62 3.59998 9.48005L4.34998 9.30005C4.89998 10.57 4.99998 11.42 4.99998 11.42L4.24998 11.6ZM2.75 9.7002C3.3 10.9702 3.4 11.8202 3.4 11.8202L2.65 12.0002C2.65 12.0002 2.51 11.0202 2 9.8802L2.75 9.7002ZM2.30005 13.75L2.47005 14.5C4.66005 14.38 6.82005 13.75 7.60005 9.76L6.85005 9.5L6.82835 9.59969C6.42456 11.4566 5.9643 13.5732 2.30005 13.75ZM9.20007 8.5H10.0001V10.5C11.7801 11.05 13.4001 12.1 13.4001 12.1L13.0001 12.7C13.0001 12.7 11.3901 11.66 10.0001 11.3V14.5H9.20007V8.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1927_1693">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
});
