import { createIcon } from '@chakra-ui/react';

export const BottleUnitSymbolIcon = createIcon({
  displayName: 'Bottle',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.15 2.99999V2.90999L3.81 2.53999L3.86 2.61999C4.24493 3.22298 4.58261 3.85487 4.87 4.50999V4.59999L4.18 4.99999V4.90999C3.88115 4.25032 3.537 3.61212 3.15 2.99999V2.99999ZM8 8.82999H6.78V13.19H6V8.82999H4.73C4.72974 10.3768 4.25122 11.8857 3.36 13.15L3.3 13.23L2.62 12.65L2.68 12.57C3.5421 11.5193 3.98708 10.1879 3.93 8.82999H2.68V7.99999H3.93V5.75999H2.83V4.99999H5.68C6.07721 4.21777 6.41159 3.40521 6.68 2.56999V2.48999L7.52 2.66999V2.77999C7.23972 3.54118 6.90899 4.28283 6.53 4.99999H7.8V5.77999H6.8V7.99999H8V8.82999ZM4.73 5.75999V7.99999H6V5.75999H4.73ZM7.78 2.99999H13.38V3.77999H9.47999C9.47999 3.99999 9.48 4.28999 9.42 4.58999C9.36 4.88999 9.37 5.27999 9.35 5.52999H12.35V5.62999L12.07 11.88C12.0515 11.9623 12.0515 12.0477 12.07 12.13C12.1409 12.1668 12.2203 12.184 12.3 12.18C12.52 12.18 12.55 12.13 12.58 11.9C12.61 11.67 12.64 11.2 12.66 10.41V10.28L13.37 10.52V10.59C13.37 12.65 13.12 12.94 12.3 12.94C12.1458 12.9558 11.99 12.94 11.8422 12.8936C11.6943 12.8472 11.5575 12.7711 11.44 12.67C11.3041 12.5124 11.2358 12.3076 11.25 12.1C9.9308 12.3984 8.59514 12.6188 7.25 12.76L7.07 11.95L7.61 11.9H7.74C8.30064 9.23132 8.61198 6.5163 8.67 3.78999H7.78V2.99999ZM11.28 11.35L11.53 6.34999H9.29C9.19253 8.19476 8.95856 10.0298 8.59 11.84C9.46 11.67 10.27 11.54 11.28 11.32V11.35ZM10.13 7.17999L10.08 7.09999L9.41 7.42999L9.46 7.51999C9.87591 8.25855 10.2112 9.03972 10.46 9.84999V9.95999L11.15 9.62999V9.54999C10.8962 8.72563 10.5542 7.93104 10.13 7.17999V7.17999Z"
        fill="currentColor"
      />
    </svg>
  ),
});
