import { useDisclosure, useTabsContext } from '@chakra-ui/react';
import { DiscardConfirmationDialog, useAlertDialog } from '@kkhs/hakari-ui';
import { useState } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';

export function useAdoptedMedicalModalTabs() {
  const discardConfirmationDialogDisclosure = useDisclosure({ id: 'discard-confirmation' });
  const [attemptTabIndexToMove, setAttemptTabIndexToMove] = useState(0);
  const { shouldAlert, setShouldAlert } = useAlertDialog();
  const { setSelectedIndex, selectedIndex } = useTabsContext();
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const handleSelectIndex = (index: number, タブ名: string, isMustBuy?: boolean) => {
    // 現在開いているタブのインデックスと同じなら何もしない
    if (selectedIndex === index) return;
    // 開こうとしたタブのインデックスを保持
    setAttemptTabIndexToMove(index);
    // AlertDialogContext の shouldAlert が true なら破棄確認ダイアログを開く そうでなければタブを切り替える
    if (shouldAlert) {
      discardConfirmationDialogDisclosure.onOpen();
      return;
    }
    // 必須買い経由で医薬品モーダルを開いた時だけログを送信する
    if (isMustBuy) {
      userMonitoringRepository.sendEvent({
        key: `おすすめ発注必須買い_医薬品詳細モーダルのタブ_CLICKED`,
        contexts: { pharmacyId, musubiCode, タブ名 },
      });
    }
    setSelectedIndex(index);
  };

  // 破棄確認ダイアログの確定ボタンを押すと、破棄確認ダイアログを閉じて、タブを切り替える
  const handleDiscard = () => {
    setSelectedIndex(attemptTabIndexToMove);
    discardConfirmationDialogDisclosure.onClose();
    setShouldAlert?.(false);
  };

  const renderDialog = () => (
    <DiscardConfirmationDialog
      isOpen={discardConfirmationDialogDisclosure.isOpen}
      onClose={discardConfirmationDialogDisclosure.onClose}
      onDiscard={handleDiscard}
      submitButtonText="変更内容を破棄して移動する"
    />
  );

  return {
    handleSelectIndex,
    renderDialog,
  };
}
