import { ChakraProvider } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/nextjs';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { useDisplaySizeLogger } from '@/features/monitoring';
import { initAmplify } from '@/shared/lib/amplify';
import { removeLocalStorageItems } from '@/shared/lib/recoil';
import { AdoptedMedicalProductModalRoot } from './AdoptedMedicalProductModalRoot';
import { ApolloRoot } from './ApolloRoot';
import { AuthRoot } from './AuthRoot';
import { CommonUserMonitoringRoot } from './CommonUserMonitoringRoot';
import { CurrentPharmacyRoot } from './CurrentPharmacyRoot';
import { FeatureFlagRoot } from './FeatureFlagRoot';
import { MaintenanceRoot } from './MaintenanceRoot';
import { OrganizationManagementRoot } from './OrganizationManagementRoot';
import { PharmacyRecoilRoot } from './PharmacyRecoilRoot';
import { PharmacyRoot } from './PharmacyRoot';
import { RootRecoilRoot } from './RootRecoilRoot';
import { VersionControlRoot } from './VersionControlRoot';
import { useIdentifyPathname } from './useIdentifyPathname';
import { theme } from '../style';

initAmplify();

type Props = {
  children: React.ReactElement;
};

export function AppRoot({ children }: Props) {
  useEffect(() => {
    const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
    userMonitoringRepository.init();
  }, []);

  const { isSsoPathname, isPublicErrorPathname, isOrganizationGroupAdmin } = useIdentifyPathname();

  useDisplaySizeLogger();
  return (
    <ErrorBoundary
      showDialog
      dialogOptions={{
        title: '予期せぬエラーが発生しました😢',
      }}
      onError={() => {
        // NOTE: ここに入ってくるエラーは想定外なので、localStorage の値が悪さをする恐れがあるため
        removeLocalStorageItems();
      }}
    >
      <ChakraProvider theme={theme}>
        {isPublicErrorPathname ? (
          children
        ) : (
          <RootRecoilRoot>
            <LDProvider
              clientSideID={process.env.LAUNCHDARKLY_CLIENT_SIDE_ID}
              options={{ streaming: true }}
            >
              <ApolloRoot>
                {isSsoPathname ? (
                  children
                ) : (
                  <AuthRoot>
                    {isOrganizationGroupAdmin ? (
                      <OrganizationManagementRoot>{children}</OrganizationManagementRoot>
                    ) : (
                      <PharmacyRoot>
                        <PharmacyRecoilRoot>
                          <CommonUserMonitoringRoot>
                            <FeatureFlagRoot>
                              <MaintenanceRoot>
                                <CurrentPharmacyRoot>
                                  <AdoptedMedicalProductModalRoot>
                                    <VersionControlRoot>{children}</VersionControlRoot>
                                  </AdoptedMedicalProductModalRoot>
                                </CurrentPharmacyRoot>
                              </MaintenanceRoot>
                            </FeatureFlagRoot>
                          </CommonUserMonitoringRoot>
                        </PharmacyRecoilRoot>
                      </PharmacyRoot>
                    )}
                  </AuthRoot>
                )}
              </ApolloRoot>
            </LDProvider>
          </RootRecoilRoot>
        )}
      </ChakraProvider>
    </ErrorBoundary>
  );
}
