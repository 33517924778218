import { MedicinePackageUnitFragment } from '@/gql/apollo';
import { AWSDate, formatAWSDate } from '@/shared/utils';

const getDiscontinuedDateLabel = (discontinuedDate: AWSDate): string =>
  discontinuedDate === '1900-01-01' ? '' : formatAWSDate(discontinuedDate);

export function getMedicinePackageUnitLabel(
  fragment: MedicinePackageUnitFragment,
  { isShowGtinCode }: { isShowGtinCode: boolean } = { isShowGtinCode: false },
): string {
  const gtinCode = isShowGtinCode ? ` / GS1コード ${fragment.gtinCode}` : '';
  return `${fragment.outline} (${fragment.medicineSupplier.name}${gtinCode})${
    fragment.discontinuedDate
      ? `\n製造中止 ${getDiscontinuedDateLabel(fragment.discontinuedDate)}`
      : ''
  }`;
}
