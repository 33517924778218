import { HStack, StackProps, Text, TextProps } from '@chakra-ui/react';
import { StockUnitSymbolIcon } from '../StockUnitSymbolIcon';

/**
 * TODO: convertedStockUnitSymbol は type StockUnitSymbol を export して指定
 */
type Props = Pick<StackProps, 'paddingBottom'> &
  Pick<TextProps, 'fontSize' | 'fontWeight'> & {
    /** 数量 */
    convertedStockUnitQuantity: number;
    /** 単位 */
    convertedStockUnitSymbol: string;
    dataTestId?: string;
    /** 数量の表示をハイフンにするかどうか */
    showHyphenForQuantity?: boolean;
  };

export function ConvertedStockUnitQuantityWithSymbol({
  convertedStockUnitQuantity,
  convertedStockUnitSymbol,
  fontSize,
  fontWeight,
  paddingBottom,
  dataTestId,
  showHyphenForQuantity = false,
}: Props) {
  const textProps = { fontSize, fontWeight };
  return (
    <HStack spacing={0} justifyContent="flex-end" paddingBottom={paddingBottom}>
      <Text {...textProps}>(</Text>
      <Text {...textProps} data-testid={dataTestId}>
        {showHyphenForQuantity ? '-' : convertedStockUnitQuantity}
      </Text>
      <StockUnitSymbolIcon
        name={convertedStockUnitSymbol}
        color="slate.500"
        position="relative"
        top="1px"
        boxSize={4}
      />
      <Text {...textProps}>)</Text>
    </HStack>
  );
}
