import { AlertDialogBody, AlertDialogHeader } from '@chakra-ui/react';
import { StockSalePriceConfig } from '@/gql/docs';
import { ShippingDefaultPriceConfig, ShippingStockOperation } from './types';
import { useShippingDialogContent } from './useShippingDialogContent';
import { ShippingStockOperationDeleteFooter } from '../../ShippingStockOperationDeleteFooter';
import { ShippingStockOperationDeleteHeading } from '../../ShippingStockOperationDeleteHeading';

type Props = {
  onClose: () => void;
  loading: boolean;
  cancelRef: React.RefObject<HTMLButtonElement>;
  stockOperation?: ShippingStockOperation;
  defaultPriceConfig?: ShippingDefaultPriceConfig;
  stockSalePriceConfig?: StockSalePriceConfig;
};

export function ShippingDialogContent({
  onClose,
  loading,
  cancelRef,
  stockOperation,
  defaultPriceConfig,
  stockSalePriceConfig,
}: Props) {
  const { renderDialogContent } = useShippingDialogContent({
    stockOperation,
    defaultPriceConfig,
    stockSalePriceConfig,
    loading,
  });

  return (
    <>
      <AlertDialogHeader display="flex" borderBottomWidth={1} justifyContent="space-between">
        <ShippingStockOperationDeleteHeading
          stockOperation={stockOperation}
          defaultPriceConfig={defaultPriceConfig}
        />
      </AlertDialogHeader>
      <AlertDialogBody p={0}>{renderDialogContent()}</AlertDialogBody>
      <ShippingStockOperationDeleteFooter
        stockOperationId={stockOperation?.id}
        typename={stockOperation?.__typename}
        onClose={onClose}
        cancelRef={cancelRef}
        loading={loading}
      />
    </>
  );
}
