import { createIcon } from '@chakra-ui/react';

export const BagUnitSymbolIcon = createIcon({
  displayName: 'Bag',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.21001 4.15993L11.4 4.04993H11.35C10.8636 3.66687 10.3412 3.33196 9.79001 3.04993L9.66001 2.99993L10.21 2.48993H10.27C10.8292 2.76327 11.3559 3.09871 11.84 3.48993L11.93 3.54993L11.44 3.99993H13.19V4.71993L9.53001 4.90993C9.76518 5.35939 10.0896 5.75615 10.4833 6.07596C10.877 6.39577 11.3319 6.63191 11.82 6.76993C12.12 6.76993 12.29 6.26993 12.51 5.62993V5.48993L13.26 5.83993V5.91993C12.94 6.84993 12.69 7.57993 12.02 7.57993C10.84 7.57993 9.44001 6.47993 8.65001 4.95993L5.79001 5.10993V4.37993L8.39001 4.23993C8.22449 3.7091 8.09421 3.16793 8.00001 2.61993V2.49993L8.81001 2.43993V2.52993C8.9011 3.08277 9.03483 3.62773 9.21001 4.15993V4.15993ZM10.27 10.8599C11.0299 10.4515 11.7411 9.95837 12.39 9.38993L12.48 9.30993L11.8 8.77993L11.74 8.83993C11.1026 9.38225 10.4164 9.86431 9.69001 10.2799C9.25858 9.79195 8.91971 9.22943 8.69001 8.61993H13.35V7.82993H8.35001V6.90993H7.54001V7.82993H2.66001V8.61993H6.75001C5.56826 9.6467 4.14854 10.3617 2.62001 10.6999H2.51001L2.83001 11.5099H2.91001C3.79708 11.2802 4.65325 10.9445 5.46001 10.5099V12.3399C5.15001 12.3399 4.73001 12.3399 3.93001 12.4299H3.82001L3.95001 13.2499H4.00001C5.73825 13.1495 7.46838 12.9391 9.18001 12.6199H9.27001V11.8799H9.15001C8.07001 12.0799 7.47001 12.1399 6.28001 12.2599V9.99993C6.87475 9.60836 7.41887 9.14486 7.90001 8.61993V8.61993C8.27001 9.66993 9.14001 11.9999 13 13.2199H13.08L13.51 12.3599H13.39C12.2469 12.1111 11.1782 11.5973 10.27 10.8599V10.8599ZM5.05001 7.54993V4.22993C5.43792 3.7866 5.77346 3.30006 6.05001 2.77993L6.11001 2.67993L5.40001 2.42993V2.49993C4.73845 3.62028 3.81549 4.56382 2.71001 5.24993H2.64001L2.94001 6.03993L3.00001 5.99993C3.44524 5.76636 3.84491 5.45475 4.18001 5.07993V7.54993H5.05001Z"
        fill="currentColor"
      />
      <path
        d="M9.21001 4.15993L11.4 4.04993H11.35C10.8636 3.66687 10.3412 3.33196 9.79001 3.04993L9.66001 2.99993L10.21 2.48993H10.27C10.8292 2.76327 11.3559 3.09871 11.84 3.48993L11.93 3.54993L11.44 3.99993H13.19V4.71993L9.53001 4.90993C9.76518 5.35939 10.0896 5.75615 10.4833 6.07596C10.877 6.39577 11.3319 6.63191 11.82 6.76993C12.12 6.76993 12.29 6.26993 12.51 5.62993V5.48993L13.26 5.83993V5.91993C12.94 6.84993 12.69 7.57993 12.02 7.57993C10.84 7.57993 9.44001 6.47993 8.65001 4.95993L5.79001 5.10993V4.37993L8.39001 4.23993C8.22449 3.7091 8.09421 3.16793 8.00001 2.61993V2.49993L8.81001 2.43993V2.52993C8.9011 3.08277 9.03483 3.62773 9.21001 4.15993V4.15993ZM10.27 10.8599C11.0299 10.4515 11.7411 9.95837 12.39 9.38993L12.48 9.30993L11.8 8.77993L11.74 8.83993C11.1026 9.38225 10.4164 9.86431 9.69001 10.2799C9.25858 9.79195 8.91971 9.22943 8.69001 8.61993H13.35V7.82993H8.35001V6.90993H7.54001V7.82993H2.66001V8.61993H6.75001C5.56826 9.6467 4.14854 10.3617 2.62001 10.6999H2.51001L2.83001 11.5099H2.91001C3.79708 11.2802 4.65325 10.9445 5.46001 10.5099V12.3399C5.15001 12.3399 4.73001 12.3399 3.93001 12.4299H3.82001L3.95001 13.2499H4.00001C5.73825 13.1495 7.46838 12.9391 9.18001 12.6199H9.27001V11.8799H9.15001C8.07001 12.0799 7.47001 12.1399 6.28001 12.2599V9.99993C6.87475 9.60836 7.41887 9.14486 7.90001 8.61993V8.61993C8.27001 9.66993 9.14001 11.9999 13 13.2199H13.08L13.51 12.3599H13.39C12.2469 12.1111 11.1782 11.5973 10.27 10.8599V10.8599ZM5.05001 7.54993V4.22993C5.43792 3.7866 5.77346 3.30006 6.05001 2.77993L6.11001 2.67993L5.40001 2.42993V2.49993C4.73845 3.62028 3.81549 4.56382 2.71001 5.24993H2.64001L2.94001 6.03993L3.00001 5.99993C3.44524 5.76636 3.84491 5.45475 4.18001 5.07993V7.54993H5.05001Z"
        fill="currentColor"
      />
    </svg>
  ),
});
