import { useRecoilValue } from 'recoil';
import { CurrentPharmacyState, currentPharmacyStateAtom } from '../currentPharmacyStateAtom';

type CurrentPharmacySelectors = {
  useValue: () => CurrentPharmacyState;
};

export const currentPharmacySelectors: CurrentPharmacySelectors = {
  useValue: () => useRecoilValue(currentPharmacyStateAtom),
};
