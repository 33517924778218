import { zodResolver } from '@hookform/resolvers/zod';
import { UseFormProps, useForm } from 'react-hook-form';
import { z } from 'zod';

export function useZodForm<TSchema extends z.ZodType>(
  props: Omit<UseFormProps<TSchema['_input']>, 'resolver'> & {
    schema: TSchema;
  },
) {
  const form = useForm<TSchema['_input']>({
    // NOTE: デフォルトの mode を `onChange` にしている
    mode: 'onChange',
    resolver: zodResolver(props.schema, undefined),
    ...props,
  });

  return form;
}
