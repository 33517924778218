import { useForceUpdate } from './hooks';
import pkg from '../../../../package.json';

type Props = {
  children: JSX.Element;
};

export function VersionControlRoot({ children }: Props) {
  useForceUpdate(pkg.version);
  return children;
}
