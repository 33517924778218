import { createIcon } from '@chakra-ui/react';

export const PreMadeAttentionIcon = createIcon({
  displayName: 'PreMadeAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M13.13 13.34C12.6 14.21 11.95 15.16 11.34 15.77L10.74 15.39C11.11 14.99 11.55 14.41 11.91 13.83H9.07001V17.6C9.07001 18.06 8.95001 18.27 8.60001 18.38C8.25001 18.49 7.67002 18.5 6.80002 18.5C6.75002 18.29 6.62002 17.99 6.52002 17.78C7.21002 17.81 7.88002 17.8 8.08002 17.79C8.26002 17.78 8.32001 17.74 8.32001 17.59V13.83H4.20001V13.12H9.38C8.53 12.69 7.39003 12.17 6.50003 11.79L6.88 11.24C7.43 11.46 8.08 11.73 8.69 12C9.32 11.64 10.05 11.12 10.59 10.63H5.14001V9.93999H11.31L11.48 9.89999L12.02 10.31C11.31 11.02 10.32 11.77 9.42001 12.34C9.83001 12.52 10.22 12.71 10.5 12.86L10.33 13.12H12.39L12.54 13.06L13.13 13.34V13.34Z"
        fill="#5D6771"
      />
      <path
        d="M22.96 15.91C22.39 16.3 21.67 16.69 21.05 16.97C21.73 17.45 22.59 17.78 23.61 17.95C23.46 18.09 23.28 18.37 23.19 18.55C21.16 18.14 19.77 17.12 19.01 15.51C18.6 15.82 18.1 16.11 17.56 16.37V17.58C18.28 17.47 19.07 17.34 19.85 17.22L19.89 17.82L15.95 18.46L15.83 17.84C16.13 17.8 16.47 17.75 16.85 17.69V16.68C16.19 16.94 15.5 17.14 14.86 17.28C14.78 17.11 14.59 16.87 14.45 16.73C15.7 16.5 17.12 16.03 18.09 15.44H14.62V14.82H18.66V14.23H19.4V14.82H23.45V15.44H19.67C19.91 15.88 20.21 16.26 20.57 16.6C21.19 16.3 21.96 15.86 22.42 15.52L22.96 15.91V15.91ZM16.82 11.79H14.58V11.25H16.82V10.63H15.64C15.52 10.86 15.39 11.07 15.26 11.24C15.14 11.15 14.87 11.02 14.73 10.95C15.07 10.55 15.35 9.98999 15.52 9.43999L16.11 9.57999C16.05 9.75999 15.97 9.92999 15.9 10.11H16.82V9.39999H17.46V10.11H19.24V10.63H17.46V11.25H19.59V11.79H17.46V12.32H19.29V13.53C19.29 13.81 19.26 13.95 19.04 14.03C18.84 14.12 18.54 14.12 18.1 14.12C18.07 13.97 17.97 13.78 17.9 13.64C18.23 13.65 18.48 13.65 18.57 13.64C18.66 13.64 18.68 13.61 18.68 13.53V12.82H17.46V14.44H16.82V12.82H15.67V14.18H15.07V12.32H16.82V11.79V11.79ZM20.79 13.13H20.12V9.78999H20.79V13.13ZM23.07 13.64C23.07 14.02 22.99 14.2 22.7 14.31C22.42 14.42 21.96 14.42 21.26 14.42C21.23 14.23 21.13 13.98 21.03 13.8C21.57 13.82 22.05 13.82 22.21 13.8C22.34 13.8 22.39 13.75 22.39 13.63V9.48999H23.07V13.63V13.64Z"
        fill="#5D6771"
      />
    </g>
  ),
});
