import { Flex, IconProps } from '@chakra-ui/react';
import format from 'date-fns/format';
import { NumberLikeText } from '../NumberLikeText';
import { Typography } from '../Typography';
import { AdoptionStoppedIcon } from '../icons';

type Props = {
  iconProps?: IconProps;
  /** 採用薬停止日を渡したら日付が表示される */
  adoptionStoppedDate?: string;
};

/** 採用停止を示すアイコンとテキストを組み合わせたラベル */
export function AdoptionStoppedLabel({
  iconProps = { w: '18px', h: '18px' },
  adoptionStoppedDate,
}: Props) {
  return (
    <Flex alignItems="center">
      <AdoptionStoppedIcon color="red.500" mr={0.5} {...iconProps} />
      <Typography variant="body2" lineHeight={1.27} color="red.500" whiteSpace="nowrap">
        採用停止
      </Typography>
      {adoptionStoppedDate && (
        <NumberLikeText color="red.500" ml={1}>
          {`${format(new Date(adoptionStoppedDate), 'yyyy/MM/dd')}〜`}
        </NumberLikeText>
      )}
    </Flex>
  );
}
