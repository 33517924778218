import { useMutation } from '@apollo/client';
import { showErrorToast } from '@kkhs/hakari-ui';
import { captureMessage } from '@sentry/nextjs';
import { RefObject } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { graphql } from '@/gql';
import { StockOperationUpdateButtonGroup } from '../../../../StockOperationUpdateButtonGroup';
import {
  UpdateStoringStockOperationByOtherFieldValues,
  storingByOtherSchemaToInput,
} from '../../../../schema';
import { useUpdateStoringStockOperation } from '../useUpdateStoringStockOperation';

const mutationDoc = graphql(`
  mutation UpdateStoringStockOperationByOther($input: UpdateStoringStockOperationByOtherInput!) {
    updateStoringStockOperationByOther(input: $input) {
      medicines {
        ...Medicine
      }
      userErrors {
        ... on ValidationError {
          message
          path
        }
        ... on StockOperationNotExistError {
          message
          path
          id
        }
        ... on ClosedStockOperationError {
          message
          path
          latestStockCloseDate
        }
      }
    }
  }
`);

type Props = {
  cancelRef: RefObject<HTMLButtonElement>;
  onClose: () => void;
};

export function StoringByOtherUpdateButtonGroup({ cancelRef, onClose }: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = useFormContext<UpdateStoringStockOperationByOtherFieldValues>();
  const [updateStoringStockOperationByOther, { loading, called, reset }] = useMutation(mutationDoc);
  const { onError, onCompleted } = useUpdateStoringStockOperation({
    resetMutation: reset,
    onClose,
  });

  const submit: SubmitHandler<UpdateStoringStockOperationByOtherFieldValues> = async (values) => {
    const input = storingByOtherSchemaToInput.safeParse(values);
    if (!input.success) {
      showErrorToast({
        id: 'update-storing-stock-operation-by-other-error',
        title: 'エラーが発生しました',
      });
      captureMessage(
        'parse error - SchemaToInput in StoringDialogContent/StoringByOtherUpdateButtonGroup',
      );
    } else {
      await updateStoringStockOperationByOther({
        variables: {
          input: input.data,
        },
        onError,
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
          const { userErrors } = data.updateStoringStockOperationByOther;
          if (userErrors.length > 0) {
            if (userErrors[0]?.__typename === 'ClosedStockOperationError') {
              showErrorToast({
                id: 'closed-stocks-operation-error',
                title: '棚卸日以前のため編集・削除できません',
              });
              // Datadogにログを送信
              userMonitoringRepository.sendEvent({
                key: '棚卸以前の日付で在庫調整のその他入庫区分を編集',
                contexts: { pharmacyId, musubiCode },
              });
              reset();
              return;
            }
          }
          onCompleted(userErrors);
        },
      });
    }
  };
  return (
    <StockOperationUpdateButtonGroup
      cancelRef={cancelRef}
      onSubmit={handleSubmit(submit)}
      onClose={onClose}
      isDisabled={loading || !isValid || !isDirty}
      isLoading={loading || called}
    />
  );
}
