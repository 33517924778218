import { ShippingStockOperationTypename } from '@/entities/stockOperation';
import { TransactionReason } from '@/gql/docs';

/**
 * 出庫区分
 */
type ShippingReason = 'disposal' | 'transfer' | 'tradeWithCompany' | 'other';

export function toShippingReason(
  typename: ShippingStockOperationTypename,
): Extract<TransactionReason, ShippingReason> {
  switch (typename) {
    // 廃棄
    case 'ShippingStockOperationByDisposal':
      return 'disposal';
    // 法人内(調整ベース)
    case 'ShippingStockOperationByTransfer':
      return 'transfer';
    // 法人内(依頼ベース)
    case 'ShippingStockOperationByTransferRequest':
      return 'transfer';
    // 法人間
    case 'ShippingStockOperationByExternalTrade':
      return 'tradeWithCompany';
    // 法人間(ファルマーケット売却)
    case 'ShippingStockOperationByStockSale':
      return 'tradeWithCompany';
    // その他
    case 'ShippingStockOperationByOther':
      return 'other';
    default:
      throw new Error(`unknown typename: ${typename}`);
  }
}
