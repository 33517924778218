import { ReactElement } from 'react';
import { ValueContainerProps, components } from 'react-select';
import { Typography } from '../../../Typography';
import { OptionType } from '../types';

export function ValueContainer(props: ValueContainerProps<OptionType>) {
  const { children, hasValue, getValue, options } = props;

  if (!hasValue) return <components.ValueContainer {...props} />;

  /**
   * ValueContainerProps の children は配列になっており
   * [1]番目にはオプションを開閉するために必要な input が格納されている
   * 「複数の選択肢を `、` で繋げた文字列にすること」「react-select デフォルトのオプションの開閉挙動を保つこと」
   * の両方を実現するために、children[1] は確実に存在するという前提で処理を行う
   */
  const ReactSelectChildren = children as ReactElement[];
  const input = ReactSelectChildren[1];

  const valueList = getValue();

  // options にはグループ化されたオプションが含まれる可能性があるため、平坦化する
  const flattenOptions = options
    .map((option) => {
      if ('options' in option) {
        return option.options;
      }
      return option;
    })
    .flat();

  /** 選択した項目を options の順番に並び替え、`、` で繋げる */
  const valueStr = valueList
    .map((value) => value.label)
    .sort(
      (a, b) =>
        flattenOptions.findIndex((option) => option.label === a) -
        flattenOptions.findIndex((option) => option.label === b),
    )
    .join('、');

  return (
    <components.ValueContainer {...props}>
      <Typography
        variant="body2"
        color="slate.900"
        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {valueStr}
      </Typography>
      {input}
    </components.ValueContainer>
  );
}
