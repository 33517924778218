import { createIcon } from '@chakra-ui/react';

export const DemandTakeoverIcon = createIcon({
  displayName: 'DemandTakeoverIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3072 10.6539L12.6149 6.34614L10.7687 4.5L4.61473 10.6539L8.3072 10.6539Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6919 13.3461L11.3843 17.6539L13.2304 19.5L19.3844 13.3461L15.6919 13.3461Z"
        fill="currentColor"
      />
      <path
        d="M7.07764 10.6533L19.3853 10.6533L19.3853 8.19178L7.07764 8.19178L7.07764 10.6533Z"
        fill="currentColor"
      />
      <path
        d="M16.9233 13.3467L4.61565 13.3467L4.61565 15.8082L16.9233 15.8082L16.9233 13.3467Z"
        fill="currentColor"
      />
    </>
  ),
});
