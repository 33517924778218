import { createIcon } from '@chakra-ui/react';

export const ThreeDotsIcon = createIcon({
  displayName: 'ThreeDotsIcon',
  viewBox: '0 0 22 22',
  path: (
    <path
      fill="currentColor"
      d="M9.82812 14.8281C10.1667 14.4896 10.5573 14.3203 11 14.3203C11.4427 14.3203 11.8333 14.4896 12.1719 14.8281C12.5104 15.1667 12.6797 15.5573 12.6797 16C12.6797 16.4427 12.5104 16.8333 12.1719 17.1719C11.8333 17.5104 11.4427 17.6797 11 17.6797C10.5573 17.6797 10.1667 17.5104 9.82812 17.1719C9.48958 16.8333 9.32031 16.4427 9.32031 16C9.32031 15.5573 9.48958 15.1667 9.82812 14.8281ZM9.82812 9.82812C10.1667 9.48958 10.5573 9.32031 11 9.32031C11.4427 9.32031 11.8333 9.48958 12.1719 9.82812C12.5104 10.1667 12.6797 10.5573 12.6797 11C12.6797 11.4427 12.5104 11.8333 12.1719 12.1719C11.8333 12.5104 11.4427 12.6797 11 12.6797C10.5573 12.6797 10.1667 12.5104 9.82812 12.1719C9.48958 11.8333 9.32031 11.4427 9.32031 11C9.32031 10.5573 9.48958 10.1667 9.82812 9.82812ZM12.1719 7.17188C11.8333 7.51042 11.4427 7.67969 11 7.67969C10.5573 7.67969 10.1667 7.51042 9.82812 7.17188C9.48958 6.83333 9.32031 6.44271 9.32031 6C9.32031 5.55729 9.48958 5.16667 9.82812 4.82812C10.1667 4.48958 10.5573 4.32031 11 4.32031C11.4427 4.32031 11.8333 4.48958 12.1719 4.82812C12.5104 5.16667 12.6797 5.55729 12.6797 6C12.6797 6.44271 12.5104 6.83333 12.1719 7.17188Z"
    />
  ),
});
