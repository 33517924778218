import { HStack, Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { PRIMARY_WHOLESALE_NOT_CONFIGURED_TEXT } from '@/entities/primaryWholesale';
import { MedicineDetailTabQuery, MedicineDetailTabFragment } from '@/gql/apollo';
import { MedicinePackageUnitTable } from './MedicinePackageUnitTable';
import {
  DateItemLabel,
  TextItemLabel,
  NumberItemLabel,
  AttentionItemLabel,
  StorageMethodItemLabel,
} from '../itemLabels';

type Props = {
  /** 対象の medicine */
  fragment: MedicineDetailTabFragment;
  /** 医薬品の一般名 */
  medicineCommonName?: string;
  /** 包装単位情報 */
  orderableMedicinePackageUnits: MedicineDetailTabQuery['orderableMedicinePackageUnits'];
};

export function MedicineDetailTabContent({
  fragment: { medicine, medicinePackageUnit },
  medicineCommonName,
  orderableMedicinePackageUnits,
}: Props) {
  return (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Typography variant="body" fontWeight="bold">
          医薬品基本情報
        </Typography>
        <HStack
          spacing={2}
          alignItems="flex-start"
          borderWidth="1px"
          borderColor="gray.200"
          borderRadius="base"
          p={6}
        >
          <Stack
            overflowY="auto"
            spacing={2}
            flex={1}
            pr={6}
            borderRight="1px"
            borderColor="gray.200"
            minH="inherit"
          >
            <TextItemLabel label="一般名" value={medicineCommonName} />
            <TextItemLabel label="YJコード" value={medicine.yjCode} />
            <NumberItemLabel label="薬価" value={medicine.standardUnitPrice} unitSymbol="円" />
            <TextItemLabel label="剤形" value={medicine.dosageForm} />
          </Stack>
          <Stack
            overflowY="auto"
            spacing={2}
            flex={1}
            pl={6}
            pr={6}
            borderRight="1px"
            borderColor="gray.200"
            minH="inherit"
          >
            <DateItemLabel
              label="経過措置期限日付"
              value={medicine.transitionalMeasureExpireDate ?? undefined}
            />
            <TextItemLabel label="製造販売元メーカー" value={medicine.makerName} />
            <StorageMethodItemLabel value={medicinePackageUnit} />
            <TextItemLabel
              label="保管方法備考"
              value={medicinePackageUnit.otherStorageCautions ?? undefined}
            />
          </Stack>
          <Stack overflowY="auto" overflowX="hidden" spacing={2} flex={1} pl={6}>
            <AttentionItemLabel
              label="注意ラベル"
              value={{
                attentions: medicine.attentions,
                isNarcotic: medicine.isNarcotic,
                isStimulant: medicine.isStimulant,
                isPoison: medicine.isPoison,
                isColdPlace: medicine.isColdPlace,
                isRefrigeration: medicine.isRefrigeration,
                isFrozen: medicine.isFrozen,
              }}
            />
            <TextItemLabel
              label="店舗設定卸"
              value={medicine.primaryWholesale?.shortName ?? PRIMARY_WHOLESALE_NOT_CONFIGURED_TEXT}
            />
          </Stack>
        </HStack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="body" fontWeight="bold">
          包装単位情報
        </Typography>
        <HStack spacing={2} borderWidth="1px 1px 0px 1px" borderColor="gray.200" overflowY="auto">
          <MedicinePackageUnitTable
            orderableMedicinePackageUnits={orderableMedicinePackageUnits}
            stockUnitPrice={medicine.stockUnitPrice}
          />
        </HStack>
      </Stack>
    </Stack>
  );
}
