import { createIcon } from '@chakra-ui/react';

export const HideOrderRecommendIcon = createIcon({
  displayName: 'HideOrderRecommendIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2923_1574)">
        <path
          d="M12 17C14.76 17 17 14.76 17 12C17 11.35 16.87 10.74 16.64 10.17L19.56 7.25C21.07 8.51 22.26 10.14 22.99 12C21.26 16.39 16.99 19.5 11.99 19.5C10.59 19.5 9.25 19.25 8.01 18.8L10.17 16.64C10.74 16.87 11.35 17 12 17ZM2 19.73L4.28 17.45L4.74 16.99C3.08 15.7 1.78 13.98 0.999999 12C2.73 7.61 7 4.5 12 4.5C13.55 4.5 15.03 4.8 16.38 5.34L16.8 4.92L19.73 2L21 3.27L3.27 21L2 19.73ZM7.53 14.2L9.08 12.65C9.03 12.44 9 12.22 9 12C9 10.34 10.34 9 12 9C12.22 9 12.44 9.03 12.65 9.08L14.2 7.53C13.53 7.2 12.79 7 12 7C9.24 7 7 9.24 7 12C7 12.79 7.2 13.53 7.53 14.2ZM11.84 14.98L14.99 11.83L15.01 11.99C15.01 13.65 13.67 14.99 12.01 14.99L11.84 14.98Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2923_1574">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 24)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
