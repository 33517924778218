import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { graphql } from '@/gql';

const queryDoc = graphql(`
  query ShippingStockOperationDeleteDialog($stockOperationId: ID!) {
    stockOperation(id: $stockOperationId) {
      __typename
      # 出庫
      # 廃棄
      ... on ShippingStockOperationByDisposal {
        id
        targetDate
        note
        disposalReason
        operatedMedicines {
          id
          medicineId
          name
          usageGroup {
            id
            unitOutline
            # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
            shortUnitOutline
          }
          stockUnitPrice
          stockUnitSymbol
          stockUnitQuantity
          lotNumber
          expirationDate
        }
      }
      # 法人内出庫
      ... on ShippingStockOperationByTransfer {
        id
        targetDate
        counterpartyId
        counterpartyName
        note
        operatedMedicines {
          id
          medicineId
          name
          usageGroup {
            id
            unitOutline
            # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
            shortUnitOutline
          }
          stockUnitPrice
          stockUnitSymbol
          stockUnitQuantity
          costPrice
          lotNumber
          expirationDate
        }
      }
      # 法人間出庫
      ... on ShippingStockOperationByExternalTrade {
        id
        targetDate
        counterpartyId
        counterpartyName
        feeAmount
        containerAmount
        taxRate
        note
        operatedMedicines {
          id
          medicineId
          name
          usageGroup {
            id
            unitOutline
            # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
            shortUnitOutline
          }
          stockUnitPrice
          stockUnitSymbol
          stockUnitQuantity
          costPrice
          lotNumber
          expirationDate
        }
      }
      # 法人間(ファルマーケット売却)
      ... on ShippingStockOperationByStockSale {
        id
        targetDate
        stockSaleCounterpartyName: counterpartyName
        stockSaleTaxRate: taxRate
        operatedMedicines {
          id
          medicineId
          name
          usageGroup {
            id
            unitOutline
            # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
            shortUnitOutline
          }
          stockUnitPrice
          stockUnitSymbol
          stockUnitQuantity
          costPrice
          lotNumber
          expirationDate
        }
        stockSalePriceConfig {
          shouldShowTax
          taxRoundingMethod
          taxCalculationTarget
          itemRoundingMethod
          totalRoundingMethod
        }
      }
      # その他出庫
      ... on ShippingStockOperationByOther {
        id
        targetDate
        counterpartyId
        counterpartyName
        taxRate
        note
        operatedMedicines {
          id
          medicineId
          name
          usageGroup {
            id
            unitOutline
            # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
            shortUnitOutline
          }
          stockUnitPrice
          stockUnitSymbol
          stockUnitQuantity
          costPrice
          lotNumber
          expirationDate
        }
      }
      # 法人内出庫（依頼ベース）
      ... on ShippingStockOperationByTransferRequest {
        id
        targetDate
        pharmacyId
        note
        operatedMedicines {
          id
          medicineId
          name
          usageGroup {
            id
            unitOutline
            # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
            shortUnitOutline
          }
          stockUnitPrice
          stockUnitSymbol
          stockUnitQuantity
          costPrice
          lotNumber
          expirationDate
        }
      }
    }
    stockOperationInternalDefaultSetting: stockOperationDefaultSetting(counterpartyType: internal) {
      __typename
      ... on StockOperationInternalDefaultSetting {
        id
        stockOperationPriceConfig {
          ... on InternalStockOperationPriceConfig {
            totalRoundingMethod
            itemRoundingMethod
            slipTitle
          }
        }
      }
    }
    stockOperationExternalDefaultSetting: stockOperationDefaultSetting(counterpartyType: external) {
      __typename
      ... on StockOperationExternalDefaultSetting {
        id
        stockOperationPriceConfig {
          ... on ExternalStockOperationPriceConfig {
            defaultPriceRate
            shouldShowTax
            totalRoundingMethod
            itemRoundingMethod
            taxRoundingMethod
            taxCalculationTarget
            containerAmount
            feeAmount
            slipTitle
          }
        }
      }
    }
  }
`);

const priceConfigOnCreatedQueryDoc = graphql(`
  query ShippingStockOperationDeleteDialog_Counterparty($counterpartyId: ID!) {
    counterparties(filter: { id: $counterpartyId }, first: 1) {
      edges {
        node {
          ... on ExternalCounterparty {
            id
            name
            stockOperationPriceConfig {
              defaultPriceRate
              shouldShowTax
              taxRoundingMethod
              taxCalculationTarget
              itemRoundingMethod
              totalRoundingMethod
              containerAmount
              feeAmount
              slipTitle
            }
          }
        }
      }
    }
  }
`);

type Args = {
  isOpen: boolean;
  stockOperationId: string;
};

export function useShippingStockOperation({ isOpen, stockOperationId }: Args) {
  const { data, loading } = useQuery(queryDoc, {
    variables: { stockOperationId },
    fetchPolicy: 'no-cache',
    skip: !isOpen,
  });

  const counterpartyIdOnCreated = useMemo(
    () =>
      data?.stockOperation.__typename === 'ShippingStockOperationByExternalTrade' ||
      data?.stockOperation.__typename === 'ShippingStockOperationByOther'
        ? data?.stockOperation.counterpartyId
        : undefined,
    [data],
  );

  const { data: priceConfigOnCreatedData, loading: loadingPriceConfig } = useQuery(
    priceConfigOnCreatedQueryDoc,
    {
      variables: { counterpartyId: counterpartyIdOnCreated! },
      skip: !counterpartyIdOnCreated,
    },
  );

  const shippingStockOperation = useMemo(() => {
    // NOTE: 廃棄、法人内出庫, 法人間出庫(ファルマ売却)の場合は、作成時の金額設定は利用しないのでそのまま返す
    if (
      data?.stockOperation.__typename === 'ShippingStockOperationByDisposal' ||
      data?.stockOperation.__typename === 'ShippingStockOperationByTransfer' ||
      data?.stockOperation.__typename === 'ShippingStockOperationByTransferRequest' ||
      data?.stockOperation.__typename === 'ShippingStockOperationByStockSale'
    ) {
      return data.stockOperation;
    }
    // NOTE: 法人間出庫、その他出庫の場合は、作成時の取引先の金額設定がある場合は金額設定を取得する
    if (
      data?.stockOperation.__typename === 'ShippingStockOperationByExternalTrade' ||
      data?.stockOperation.__typename === 'ShippingStockOperationByOther'
    ) {
      // NOTE: 作成時の取引先（法人間取引先）がある場合は、その金額設定を利用する
      if (counterpartyIdOnCreated) {
        // NOTE: 金額設定の取得前は undefined を返す
        const counterpartyOnCreated =
          priceConfigOnCreatedData?.counterparties.edges[0]?.node?.__typename ===
          'ExternalCounterparty'
            ? priceConfigOnCreatedData.counterparties.edges[0].node
            : undefined;
        if (counterpartyOnCreated && counterpartyOnCreated.stockOperationPriceConfig) {
          return {
            ...data.stockOperation,
            shippingOriginOnCreated: {
              origin: 'externalCounterparty',
              counterpartyId: counterpartyOnCreated.id,
              counterpartyName: counterpartyOnCreated.name,
              priceConfig: { type: 'external', ...counterpartyOnCreated.stockOperationPriceConfig },
            },
          } as const;
        }
      }
      if (data.stockOperation.counterpartyName) {
        return {
          ...data.stockOperation,
          shippingOriginOnCreated: {
            origin: 'userInput',
            counterpartyId: null,
            counterpartyName: data.stockOperation.counterpartyName,
            priceConfig: null,
          },
        } as const;
      }

      return {
        ...data.stockOperation,
        shippingOriginOnCreated: null,
      } as const;
    }

    return undefined;
  }, [counterpartyIdOnCreated, data, priceConfigOnCreatedData]);

  const defaultPriceConfig = useMemo(() => {
    const internalDefaultPriceConfig =
      data?.stockOperationInternalDefaultSetting?.__typename ===
      'StockOperationInternalDefaultSetting'
        ? data.stockOperationInternalDefaultSetting.stockOperationPriceConfig
        : null;
    const externalDefaultPriceConfig =
      data?.stockOperationExternalDefaultSetting?.__typename ===
      'StockOperationExternalDefaultSetting'
        ? data.stockOperationExternalDefaultSetting.stockOperationPriceConfig
        : null;
    return internalDefaultPriceConfig && externalDefaultPriceConfig
      ? ({
          internal: { type: 'internal', stockOperationPriceConfig: internalDefaultPriceConfig },
          external: { type: 'external', stockOperationPriceConfig: externalDefaultPriceConfig },
        } as const)
      : undefined;
  }, [data]);

  const stockSalePriceConfig =
    data?.stockOperation.__typename === 'ShippingStockOperationByStockSale'
      ? data.stockOperation.stockSalePriceConfig
      : undefined;

  return {
    loading: loading || loadingPriceConfig,
    defaultPriceConfig,
    shippingStockOperation,
    stockSalePriceConfig,
  };
}
