import { FormControl, HStack, FormLabel } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { useMemo } from 'react';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import {
  InternalCounterpartySelect,
  InternalCounterpartySelectOption,
} from '../InternalCounterpartySelect';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isDisabled?: boolean;
  defaultCounterpartyId?: string | null;
  defaultCounterpartyName?: string | null;
  onAfterChange: (value: InternalCounterpartySelectOption) => void;
  onAfterClear: () => void;
  onAfterCreate: (newValue: string) => void;
  formLabelRightElement: React.ReactElement;
} & Pick<UseFormReturn<T>, 'control' | 'formState'>;

export function InternalCounterpartySelectForm<T extends FieldValues>({
  name,
  isDisabled = false,
  defaultCounterpartyId = null,
  defaultCounterpartyName = null,
  onAfterChange,
  onAfterClear,
  onAfterCreate,
  control,
  formState: { errors },
  formLabelRightElement,
}: Props<T>) {
  const defaultValue = useMemo(() => {
    if (isNullOrUndefined(defaultCounterpartyName)) {
      return null;
    }
    return {
      value: { id: defaultCounterpartyId, name: defaultCounterpartyName },
      label: defaultCounterpartyName,
    };
  }, [defaultCounterpartyId, defaultCounterpartyName]);

  return (
    <FormControl w="240px">
      <HStack justify="space-between">
        <FormLabel fontWeight="bold">入庫元</FormLabel>
        {formLabelRightElement}
      </HStack>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <InternalCounterpartySelect
            size="sm"
            placeholder="-"
            defaultValue={defaultValue}
            onChange={(value) => {
              onChange(value.id);
              onAfterChange(value);
            }}
            onClear={() => {
              onChange(null);
              onAfterClear();
            }}
            onCreate={(newValue) => {
              onChange(null);
              onAfterCreate(newValue);
            }}
            isDisabled={isDisabled}
          />
        )}
      />
      {errors?.counterpartyName?.message && (
        <Typography variant="body2" color="red.500">
          {errors.counterpartyName.message}
        </Typography>
      )}
    </FormControl>
  );
}
