import { createIcon } from '@chakra-ui/react';

export const SuppositoryIcon = createIcon({
  displayName: 'SuppositoryIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.736 5.391l-.694.61-.692-.613a.923.923 0 011.386.003zm2.26 27.617l.914.131.023-.16.064-.458a525.732 525.732 0 00.933-6.932c.506-3.97 1.026-8.494 1.026-10.395 0-1.07-.374-2.224-.863-3.296-.496-1.088-1.15-2.174-1.789-3.128a37.337 37.337 0 00-2.506-3.307l-.045-.052-.012-.014-.005-.006-.694.61-.692-.613-.005.006-.012.014-.046.052-.166.194a37.359 37.359 0 00-2.365 3.113c-.646.954-1.306 2.04-1.807 3.128-.494 1.072-.873 2.227-.873 3.3 0 1.9.52 6.423 1.026 10.394a528.672 528.672 0 00.933 6.932l.065.457.023.16.914-.13-.914.13a.924.924 0 00.914.793h5.958c.46 0 .85-.337.915-.792l-.915-.131zm-5.157-.924h4.355l.205-1.472c.19-1.382.445-3.262.699-5.256.512-4.022 1.01-8.406 1.01-10.162 0-.68-.249-1.55-.696-2.53-.44-.964-1.034-1.958-1.642-2.865a35.5 35.5 0 00-1.671-2.28l-.061-.076-.064.08a35.491 35.491 0 00-1.689 2.28c-.614.907-1.214 1.9-1.659 2.864-.45.98-.703 1.85-.703 2.527 0 1.756.5 6.14 1.012 10.161a522.814 522.814 0 00.904 6.73z"
      fill="currentColor"
    />
  ),
});
