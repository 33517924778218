import { createIcon } from '@chakra-ui/react';

export const PackUnitSymbolIcon = createIcon({
  displayName: 'Pack',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.73 10.3799L13.6 10.6499V10.7299C13.44 12.5299 13.4 12.9999 12.1 12.9999H5.41001C4.91001 12.9999 4.33001 12.8699 4.33001 11.9099V8.41994H9.26001V6.48994H4.54001V5.76994C4.0978 6.26876 3.60891 6.72417 3.08001 7.12994L3.00001 7.17994L2.39001 6.57994L2.49001 6.50994C3.89171 5.4981 4.97995 4.11181 5.63001 2.50994V2.43994L6.49001 2.64994V2.74994C6.34714 3.09939 6.18351 3.44 6.00001 3.76994H12.91V3.99994C12.937 5.77701 12.8501 7.55397 12.65 9.31994C12.43 10.2099 11.94 10.3699 11.08 10.3699H10.1L9.87001 9.46994H11.05C11.62 9.46994 11.78 9.41994 11.85 8.88994C11.9829 7.45402 12.033 6.01163 12 4.56994H5.48001C5.20717 4.97113 4.90994 5.35519 4.59001 5.71994H10.1V9.19994H5.16001V11.9399C5.16001 12.1799 5.24001 12.2099 5.46001 12.2099H12C12.6 12.2099 12.64 12.2099 12.73 10.5099V10.3799Z"
        fill="currentColor"
      />
    </svg>
  ),
});
