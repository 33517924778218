import { useCallback } from 'react';
import { MutableSnapshot, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { currentPharmacyStateAtom } from '../currentPharmacyStateAtom';
import { CurrentPharmacy } from '../types';

type CurrentPharmacyActions = {
  useSetState: () => (pharmacy: CurrentPharmacy) => void;
  /** RecoilRoot 間での値の同期に利用する */
  useCopyState: () => (snapshot: MutableSnapshot) => void;
  useReset: () => () => void;
};

export const currentPharmacyActions: CurrentPharmacyActions = {
  useSetState: () => useSetRecoilState(currentPharmacyStateAtom),
  useCopyState: () => {
    const currentPharmacy = useRecoilValue(currentPharmacyStateAtom);
    return useCallback(
      ({ set }: MutableSnapshot) => set(currentPharmacyStateAtom, currentPharmacy),
      [currentPharmacy],
    );
  },
  useReset: () => useResetRecoilState(currentPharmacyStateAtom),
};
