import { Tr, Tbody, Td } from '@chakra-ui/react';
import { getGenericTypeLabel } from '@/entities/medicine';
import { DemandTakeoverToMedicineFragment } from '@/gql/apollo';
import { DemandTakeoverToMedicinesTableActionCell } from './DemandTakeoverToMedicinesTableActionCell';

type Props = {
  fragments?: DemandTakeoverToMedicineFragment[];
  isLoading: boolean;
  onSelect: (fragment: DemandTakeoverToMedicineFragment) => void;
};

export function DemandTakeoverToMedicinesTableBody({ fragments, isLoading, onSelect }: Props) {
  if (isLoading) {
    // TODO: 別途実装する
    return null;
  }
  if (fragments?.length === 0) {
    // TODO: 別途実装する
    return null;
  }
  return (
    <Tbody>
      {fragments?.map((fragment) => (
        <Tr key={fragment.id} bg={fragment.isTakable ? 'gray.50' : 'white'}>
          <Td verticalAlign="top" pl={6}>
            {fragment.medicine.medicineName}
          </Td>
          {/* 短縮された需要タイプを表示 */}
          <Td verticalAlign="top">{fragment.medicine.usageGroup.shortUnitOutline}</Td>
          <Td verticalAlign="top">{fragment.medicine.makerName}</Td>
          <Td verticalAlign="top">
            {getGenericTypeLabel(fragment.medicine.genericType, { isShort: true })}
          </Td>
          <Td verticalAlign="top" isNumeric>
            {fragment.medicine.stockUnitPrice}円
          </Td>
          <DemandTakeoverToMedicinesTableActionCell fragment={fragment} onSelect={onSelect} />
        </Tr>
      ))}
    </Tbody>
  );
}
