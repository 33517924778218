import { Box, HStack } from '@chakra-ui/react';
import {
  Typography,
  FewPrescriptionFrequencyIcon,
  FnPatientIcon,
  useSortBy,
} from '@kkhs/hakari-ui';
import { useMemo } from 'react';
import { getPrescriptionFrequencyType, PrescriptionFrequencyType } from '@/entities/prescription';
import {
  PatientByMedicineListItemsSort,
  useIdentifiedPatientsQuery,
  usePatientByMedicineListItemsQuery,
} from '@/gql/apollo';
import { PatientsTable } from './PatientsTable';

type Props = {
  medicineId: string;
  stockUnitSymbol: string;
  scrollParentRef: React.MutableRefObject<null>;
};

export function PatientsTab({ medicineId, stockUnitSymbol, scrollParentRef }: Props) {
  const { sortBy, handleSortBy } = useSortBy<PatientByMedicineListItemsSort>({
    initialState: {
      direction: 'desc',
      field: 'lastPrescriptionDate',
    },
  });
  const {
    data,
    loading: isLoading,
    fetchMore,
  } = usePatientByMedicineListItemsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      medicineId,
      sortBy,
    },
  });

  const identifiedPatientsQueryResult = useIdentifiedPatientsQuery({
    variables: { medicineId },
    fetchPolicy: 'network-only',
  });
  const prescriptionTypeMap = useMemo(() => {
    const prescriptionFrequencyType = getPrescriptionFrequencyType({
      ...identifiedPatientsQueryResult.data?.prescriptionFrequencySummary,
    });
    return identifiedPatientsQueryResult.data?.identifiedPatients.reduce<{
      [key: string]: PrescriptionFrequencyType | undefined;
    }>((acc, cur) => {
      acc[cur.patient.id] = prescriptionFrequencyType;
      return acc;
    }, {});
  }, [
    identifiedPatientsQueryResult.data?.identifiedPatients,
    identifiedPatientsQueryResult.data?.prescriptionFrequencySummary,
  ]);

  const edges = data?.patientByMedicineListItems.edges ?? [];
  const hasMore = data?.patientByMedicineListItems.pageInfo.hasNextPage;
  const loadMore = () =>
    fetchMore?.({
      variables: {
        cursor: data?.patientByMedicineListItems.pageInfo.endCursor,
      },
    });

  return (
    <Box bg="white" borderRadius="md" overflow="hidden">
      <HStack justifyContent="flex-end">
        <HStack>
          <HStack spacing={0.5}>
            <FnPatientIcon color="blue.600" boxSize="18px" />
            <Typography variant="caption" color="slate.500">
              :特定患者
            </Typography>
          </HStack>
          <HStack spacing={0.5}>
            <FewPrescriptionFrequencyIcon color="red.500" boxSize="18px" />
            <Typography variant="caption" color="slate.500">
              :少数処方
            </Typography>
          </HStack>
        </HStack>
      </HStack>
      <PatientsTable
        hasMore={hasMore}
        loadMore={loadMore}
        scrollParentRef={scrollParentRef}
        edges={edges}
        isLoading={isLoading}
        stockUnitSymbol={stockUnitSymbol}
        prescriptionTypeMap={prescriptionTypeMap}
        sortBy={sortBy}
        handleSortBy={handleSortBy}
      />
    </Box>
  );
}
