import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

export type LinkProps = ChakraLinkProps & {
  /** リンク先のURL */
  href: string;
};

export function Link(props: LinkProps) {
  const { href, onClick } = props;
  return (
    <NextLink href={href}>
      <ChakraLink
        {...props}
        onClick={(event) => {
          if (onClick) {
            // クリック時の処理があれば実行する
            onClick(event);
          }
          (document.activeElement as HTMLElement).blur();
        }}
      />
    </NextLink>
  );
}
