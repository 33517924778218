import { Box, Center, Spinner, Table, TableProps } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroller';

type Props = React.ComponentProps<typeof InfiniteScroll> & {
  /** chakra-ui の TableProps */
  tableProps?: TableProps;
};

const defaultTableProps = { size: 'sm' };

export function InfiniteScrollTable({ children, tableProps = defaultTableProps, ...rest }: Props) {
  return (
    <Box bg="white" borderRadius="md" overflow="hidden">
      <InfiniteScroll
        loader={
          <Center key="loader" py={4}>
            <Spinner color="primary.500" />
          </Center>
        }
        {...rest}
      >
        <Table {...tableProps}>{children}</Table>
      </InfiniteScroll>
    </Box>
  );
}
