import { ModalContent, ModalOverlay } from '@chakra-ui/react';
import { AlertDialog } from '@kkhs/hakari-ui';
import { useRef } from 'react';
import {
  adoptedMedicalProductModalActions,
  adoptedMedicalProductModalSelectors,
} from '@/entities/adoptedMedicalProduct';
import { AdoptedMedicalProductModalContent } from './AdoptedMedicalProductModalContent';

export function AdoptedMedicalProductModal() {
  const onClose = adoptedMedicalProductModalActions.useClose();
  const { medicineId, isOpen, defaultTabKey, isMustBuy } =
    adoptedMedicalProductModalSelectors.useValue();
  const cancelRef = useRef(null);
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} size="6xl" leastDestructiveRef={cancelRef}>
      <ModalOverlay data-testid="採用薬モーダル" />
      {medicineId ? (
        <AdoptedMedicalProductModalContent
          medicineId={medicineId}
          defaultTabKey={defaultTabKey}
          isMustBuy={isMustBuy}
        />
      ) : (
        <ModalContent>Not Found</ModalContent>
      )}
    </AlertDialog>
  );
}
