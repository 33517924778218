import { createIcon } from '@chakra-ui/react';

export const StockTransferImportIcon = createIcon({
  displayName: 'StockTransferImportIcon',
  viewBox: '0 0 48 48',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.9999 20V27.9999L12 27.9999L12 8H35.9999V16L39.9998 20H35.9999ZM21.9999 23.9999H25.9999L25.9999 19.9999H29.9999V16L25.9999 16V12L21.9999 12V16H17.9999V19.9999H21.9999V23.9999ZM12 20V16L8 20H12ZM12 39.9999V20H8V39.9999H12ZM26.0005 36L29.0005 33L26.0005 30L20.0004 36H20V36.0004L16.0003 40.0001H22.0005L22.0006 40L40 40V36L26.0005 36ZM39.9999 20V31.9999H35.9999V20H39.9999Z"
      fill="currentColor"
    />
  ),
});
