import { createIcon } from '@chakra-ui/react';

export const AddPreMadeMedicineIcon = createIcon({
  displayName: 'AddPreMadeMedicineIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4V6V12.9996C19.2144 13.9118 20 15.3642 20 17C20 19.7614 17.7614 22 15 22C13.3642 22 11.9118 21.2144 10.9996 20H6H4V6V4H18ZM12 18H14V20H16V18H18V16H16V14H14V16H12V18ZM16 12.1C15.6769 12.0344 15.3425 12 15 12C12.2386 12 10 14.2386 10 17C10 17.3425 10.0344 17.6769 10.1 18H6V6L16 6V12.1ZM14 10V8L8 8V10V14H10V10H14Z"
      fill="currentColor"
    />
  ),
});
