import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { RequiredTag, Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { useMemo, useState } from 'react';
import { FieldPath, FieldValues, UseFormReturn, useController } from 'react-hook-form';
import { getMedicinePackageUnitLabel } from '@/entities/medicinePackageUnit';
import {
  MedicinePackageUnitFragment,
  OrderableMedicinePackageUnitsQuery,
  useOrderableMedicinePackageUnitsQuery,
} from '@/gql/apollo';
import { MedicinePackageUnitSelect } from './MedicinePackageUnitSelect';
import { PrimaryMedicinePackageUnitFormAlert } from './PrimaryMedicinePackageUnitFormAlert';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;

  // YJコードに対して需要タイプは複数存在するため、需要タイプとそれにひもづく規格容量が選択されるまではundefinedをとる
  medicineId?: string;
  currentMedicinePackageUnitId?: string;

  // 複数件の包装単位がある場合、必須タグを表示するか
  showRequired?: boolean;

  control: UseFormReturn<T>['control'];
  onComplete?: (
    orderableMedicinePackageUnits: OrderableMedicinePackageUnitsQuery['orderableMedicinePackageUnits'],
  ) => void;
};

function getMedicinePackageUnitOptionType(fragment: MedicinePackageUnitFragment) {
  return {
    value: fragment,
    label: getMedicinePackageUnitLabel(fragment, { isShowGtinCode: true }),
  };
}

export function MedicinePackageUnitSelectControl<T extends FieldValues>({
  name,
  currentMedicinePackageUnitId,
  medicineId,
  showRequired = false,
  control,
  onComplete,
}: Props<T>) {
  const [medicinePackageUnitCount, setMedicinePackageUnitCount] = useState<number | undefined>(
    undefined,
  );
  const {
    field: { onChange },
  } = useController({ control, name });

  const { data, loading } = useOrderableMedicinePackageUnitsQuery({
    // NOTE: medicineId が undefined の場合は、skip している
    variables: { medicineId: medicineId as string },
    skip: isNullOrUndefined(medicineId),
    onCompleted: ({ orderableMedicinePackageUnits }) => {
      setMedicinePackageUnitCount(orderableMedicinePackageUnits.length);
      onComplete?.(orderableMedicinePackageUnits);
    },
  });
  const options = useMemo(
    () => data?.orderableMedicinePackageUnits.map(getMedicinePackageUnitOptionType),
    [data],
  );

  const showRequiredTag = showRequired && medicinePackageUnitCount !== 1;

  return (
    <Stack spacing={1}>
      <FormControl isRequired={showRequiredTag}>
        <FormLabel
          htmlFor="medicinePackageUnitId"
          requiredIndicator={showRequiredTag ? <RequiredTag ml={2} mt={0} /> : undefined}
          as="div"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body2" fontWeight="bold" as="span">
            優先規格容量
          </Typography>
        </FormLabel>
        {isNullOrUndefined(medicineId) ? (
          <Stack minH="40px">
            <Typography variant="body">-</Typography>
          </Stack>
        ) : (
          <MedicinePackageUnitSelect
            isLoading={loading}
            options={options}
            onChange={({ id }) => onChange(id)}
            value={options?.find((o) => o.value.id === currentMedicinePackageUnitId) ?? null}
          />
        )}
      </FormControl>
      {medicinePackageUnitCount !== 1 && <PrimaryMedicinePackageUnitFormAlert />}
    </Stack>
  );
}
