import { forwardRef } from 'react';
import { ForwardRefType } from '../types';

export const CloseIcon: ForwardRefType<'svg'> = forwardRef(
  ({ color = 'currentColor', ...rest }, ref) => (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      style={{ ...rest.style, flexShrink: 0 }}
      ref={ref}
    >
      <path
        d="M7 6L6 7L11 12L6 17L7 18L12 13L17 18L18 17L13 12L18 7L17 6L12 11L7 6Z"
        fill={color}
      />
    </svg>
  ),
);

CloseIcon.displayName = 'CloseIcon';
