import { Stack } from '@chakra-ui/react';
import { Typography, ErrorTooltip, TextInput } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isDisabled?: boolean;
  control: UseFormReturn<T>['control'];
};

export function StockOperationNoteForm<T extends FieldValues>({
  name,
  isDisabled = false,
  control,
}: Props<T>) {
  return (
    <Stack spacing={1}>
      <Typography variant="body" fontWeight="bold">
        備考
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <ErrorTooltip label={error?.message} isOpen={!!error?.message} placement="bottom-start">
            <TextInput
              size="sm"
              w="800px"
              placeholder="-"
              value={value}
              onChange={onChange}
              isDisabled={isDisabled}
            />
          </ErrorTooltip>
        )}
      />
    </Stack>
  );
}
