import { createIcon } from '@chakra-ui/react';

export const ChevronDoubleRightIcon = createIcon({
  displayName: 'ChevronDoubleRightIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74228e-08 2L6 8.00008L6.11959e-07 14L2 16L10.0001 8.00008L2 -8.74228e-08L8.74228e-08 2ZM6 2L12 8.00008L6 14L8 16L16.0001 8.00008L8 -3.49691e-07L6 2Z"
      fill="currentColor"
    />
  ),
});
