import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { ShippingStockOperationDeleteDialogQuery } from '@/gql/docs';

type Props = {
  note: Extract<
    ShippingStockOperationDeleteDialogQuery['stockOperation'],
    { __typename: 'ShippingStockOperationByExternalTrade' }
  >['note'];
};

export function StockOperationShippingNoteLabel({ note }: Props) {
  return (
    <Stack spacing={1}>
      <Typography variant="body" fontWeight="bold">
        備考
      </Typography>
      <Typography variant="body">{note || '-'}</Typography>
    </Stack>
  );
}
