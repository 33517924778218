import { FormControl, FormLabel, HStack, Skeleton, Stack } from '@chakra-ui/react';
import { NumberInput, RequiredTag, Typography } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { StockUnitSymbolIcon } from '@/entities/medicine';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  control: UseFormReturn<T>['control'];
  stockUnitSymbol?: string;
};

export function InitialStockUnitQuantityForm<T extends FieldValues>({
  name,
  control,
  stockUnitSymbol,
}: Props<T>) {
  return (
    <FormControl isRequired>
      <Stack spacing={2}>
        <FormLabel
          htmlFor="unitQuantity"
          requiredIndicator={<RequiredTag ml={2} mt={0} />}
          as="div"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body2" fontWeight="bold" as="span">
            初期在庫数量
          </Typography>
        </FormLabel>
        <HStack>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <NumberInput
                options={{
                  onChange: (_, value) => field.onChange(value),
                  precision: 3,
                  max: 9999999.999,
                }}
              />
            )}
          />
          <Skeleton isLoaded={!!stockUnitSymbol} width="16px" minHeight="16px">
            {stockUnitSymbol && <StockUnitSymbolIcon name={stockUnitSymbol} />}
          </Skeleton>
        </HStack>
      </Stack>
    </FormControl>
  );
}
