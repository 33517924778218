import { captureMessage } from '@sentry/nextjs';
import { AtomEffect, DefaultValue } from 'recoil';

export const localStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }): void => {
    if (typeof window !== 'undefined') {
      const savedValue = localStorage.getItem(key);
      if (savedValue !== null) {
        try {
          setSelf(JSON.parse(savedValue));
        } catch (error) {
          captureMessage('localStorageEffect JSON parse error', {
            level: 'warning',
            contexts: {
              error: {
                detail: JSON.stringify(error, null, 2),
              },
            },
          });
        }
      }

      onSet((newValue: T | DefaultValue, _: T | DefaultValue, isReset: boolean) =>
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue)),
      );
    }
  };
