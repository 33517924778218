import 'react-datepicker/dist/react-datepicker.css';
import { jaLocale } from '@kkhs/hakari-utils';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

registerLocale('ja', jaLocale);

type ReactDatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> = React.ComponentProps<typeof ReactDatePicker<CustomModifierNames, WithRange>>;

export type DatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> = Omit<ReactDatePickerProps<CustomModifierNames, WithRange>, 'locale'>;

export function DatePicker(props: DatePickerProps) {
  return <ReactDatePicker locale="ja" {...props} />;
}
