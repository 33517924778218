import { GridItem, Input, Skeleton, Text } from '@chakra-ui/react';
import { FieldValues, FieldPath, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isLoading?: boolean;
} & Pick<UseFormReturn<T>, 'register' | 'formState'>;

export function PharmacyLicenseNumberForm<T extends FieldValues>({
  name,
  isLoading = false,
  register,
  formState: { errors },
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Text fontWeight="bold">薬局開設許可番号</Text>
      </GridItem>
      <GridItem>
        <Skeleton isLoaded={!isLoading} borderRadius="md">
          <Input {...register(name)} placeholder="0000000000号" />
          {errors?.pharmacyLicenseNumber?.message && (
            <Text fontSize="sm" color="red.500">
              {errors.pharmacyLicenseNumber.message}
            </Text>
          )}
        </Skeleton>
      </GridItem>
    </>
  );
}
