import { CURRENT_PHARMACY_STATE_KEY } from './pharmacy';
import { CURRENT_ROLE_STATE_KEY } from './role';

/** recoilAtomKeyPrefix を使っていく */
export const recoilAtomKeys = {
  AUTH_STATE: '@kkhs/hakari-web-app/auth-state',
  CURRENT_PHARMACY_STATE: CURRENT_PHARMACY_STATE_KEY,
  CURRENT_ROLE_STATE: CURRENT_ROLE_STATE_KEY,
  OGAS_REGISTRATION_INFO_STATE: '@kkhs/hakari-web-app/ogas-registration-info-state',
  UI_MANUAL_ORDER_CONTAINER_STATE: '@kkhs/hakari-web-app/ui/manual-order-container-state',
  UI_ORDER_CANDIDATES_CONTAINER_STATE: '@kkhs/hakari-web-app/ui/order-candidates-container-state',
  UI_STORAGE_CONTAINER_STATE: '@kkhs/hakari-web-app/ui/storage-container-state',
  UI_WORKING_STOCKTAKE_MEDICINE_CONTAINER_STATE:
    '@kkhs/hakari-web-app/ui/working-stocktake-medicine-container-state',
};

/** recoilAtomSelectorKeyPrefix を使っていく */
export const recoilSelectorKeys = {
  AUTH_STATUS: '@kkhs/hakari-web-app/auth/status',
  AUTH_PHARMACY_STAFF: '@kkhs/hakari-web-app/auth/pharmacy-staff',
  AUTH_ORGANIZATION_GROUPS: '@kkhs/hakari-web-app/auth/organization-groups',
  CURRENT_ROLE: '@kkhs/hakari-web-app/current-role',
  OGAS_REGISTRATION_INFO: '@kkhs/hakari-web-app/ogas-registration-info',
  UI_MANUAL_ORDER_CONTAINER_TABLE_DATA_LIST:
    '@kkhs/hakari-web-app/ui/manual-order-container/table-data-list',
  UI_ORDER_CANDIDATES_CONTAINER_TABLE_DATA_LIST:
    '@kkhs/hakari-web-app/ui/order-candidates-container/table-data-list',
  UI_STORAGE_CONTAINER_QUERY_FILTER: '@kkhs/hakari-web-app/ui/storage-container/query-filter',
  UI_WORKING_STOCKTAKE_MEDICINE_UNIT_QUANTITY:
    '@kkhs/hakari-web-app/ui/working-stocktake-medicine-container/unit-quantity',
};

export const recoilAtomKeyPrefix = {
  UI_AI_LEARNING_CONTAINER: 'atom/@kkhs/hakari-web-app/ui/AiLearningContainer',
  UI_MANUAL_ORDER_CONTAINER: 'atom/@kkhs/hakari-web-app/ui/ManualOrderContainer',
  UI_ORDER_CANDIDATES_CONTAINER: 'atom/@kkhs/hakari-web-app/ui/OrderCandidatesContainer',
  UI_ORDER_PROPOSALS_CONTAINER: 'atom/@kkhs/hakari-web-app/ui/OrderProposalsContainer',
  UI_ORDER_PROPOSALS_CONTAINER_MUST_BUY_TAB:
    'atom/@kkhs/hakari-web-app/ui/OrderProposalsContainer/MustBuyTab',
  UI_ORDER_PROPOSALS_CONTAINER_SAFE_BUY_TAB:
    'atom/@kkhs/hakari-web-app/ui/OrderProposalsContainer/SafeBuyTab',
  UI_STOCK_REPORT_CONTAINER: 'atom/@kkhs/hakari-web-app/ui/StockReportContainer',
  UI_MEDICINE_RECEIVINGS_CONTAINER: 'atom/@kkhs/hakari-web-app/ui/MedicineReceivingsContainer',
  // entities
  ADOPTED_MEDICAL_PRODUCT: 'atom/@kkhs/hakari-web-app/entities/AdoptedMedicalProduct',
};

export const recoilSelectorKeyPrefix = {
  UI_AI_LEARNING_CONTAINER: 'selector/@kkhs/hakari-web-app/ui/AiLearningContainer',
  UI_MANUAL_ORDER_CONTAINER: 'selector/@kkhs/hakari-web-app/ui/ManualOrderContainer',
  UI_ORDER_CANDIDATES_CONTAINER: 'selector/@kkhs/hakari-web-app/ui/OrderCandidatesContainer',
  UI_ORDER_PROPOSALS_CONTAINER: 'selector/@kkhs/hakari-web-app/ui/OrderProposalsContainer',
  UI_ORDER_PROPOSALS_CONTAINER_MUST_BUY_TAB:
    'selector/@kkhs/hakari-web-app/ui/OrderProposalsContainer/MustBuyTab',
  UI_ORDER_PROPOSALS_CONTAINER_SAFE_BUY_TAB:
    'selector/@kkhs/hakari-web-app/ui/OrderProposalsContainer/SafeBuyTab',
  UI_STOCK_REPORT_CONTAINER: 'selector/@kkhs/hakari-web-app/ui/StockReportContainer',
  // entities
  ADOPTED_MEDICAL_PRODUCT: 'selector/@kkhs/hakari-web-app/entities/AdoptedMedicalProduct',
};
