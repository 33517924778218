import { Button, Stack, HStack, Box, Skeleton } from '@chakra-ui/react';
import {
  ErrorTooltip,
  NumberInput,
  NumberText,
  RequiredTag,
  TextInput,
  Typography,
  ChevronDoubleRightIcon,
  AdoptionStoppedLabel,
  DatePicker,
} from '@kkhs/hakari-ui';
import { jstNow } from '@kkhs/hakari-utils';
import addYears from 'date-fns/addYears';
import { Controller, FieldArrayWithId, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { GroupPharmacyWithStockUnitQuantitySelect } from '@/entities/groupPharmacy';
import { useOwnStockUnitQuantityQuery } from '@/gql/apollo';
import { FieldValues } from '../schema';

type Props = {
  field: FieldArrayWithId<FieldValues, 'requestMedicines', 'id'>;
  index: number;
  remove: UseFieldArrayRemove;
};

export function MedicineRow({ field, index, remove }: Props) {
  const TODAY = jstNow();

  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<FieldValues>();

  const { data, loading } = useOwnStockUnitQuantityQuery({
    variables: { medicineIds: [field.medicineId] },
  });
  const stockUnitQuantity = data?.medicines[0]?.stockUnitQuantity ?? 0;

  const requestMedicines = watch('requestMedicines');
  const requestUnitQuantity = requestMedicines[index]?.unitQuantity;
  const remainingQuantity = stockUnitQuantity + (requestUnitQuantity ?? 0);
  return (
    <Stack key={field.medicineId} px={6} py={3}>
      <HStack justifyContent="space-between">
        <Stack spacing={0}>
          <Typography variant="body" fontWeight="bold">
            {field.medicineName}
          </Typography>
          <Typography variant="body2" pb={1}>
            {field.shortUnitOutline}
          </Typography>
          {field.adoptionStoppedDate && <AdoptionStoppedLabel />}
        </Stack>
        <Stack spacing={1} alignItems="flex-end">
          <HStack spacing={4}>
            <Typography variant="body" fontWeight="bold">
              希望数量
              <RequiredTag ml={1} />
            </Typography>
            <Controller
              name={`requestMedicines.${index}.unitQuantity`}
              control={control}
              render={({ field: { onChange } }) => (
                <NumberInput
                  size="sm"
                  options={{
                    onChange: (_, valueNumber) => onChange(valueNumber),
                    min: 0.001,
                    max: 999999.999,
                    precision: 3,
                  }}
                />
              )}
            />
          </HStack>
          <HStack spacing={1}>
            <Typography variant="body2">自店舗の在庫数量</Typography>
            {loading ? (
              <Skeleton w="100px" h="18px" />
            ) : (
              <>
                <HStack spacing={0}>
                  <NumberText>{stockUnitQuantity}</NumberText>
                  <Typography variant="body2">{field.stockUnitSymbol}</Typography>
                </HStack>
                <ChevronDoubleRightIcon w={2} h={2} mt={1} color="gray.300" />
                <HStack spacing={0}>
                  <NumberText>{remainingQuantity}</NumberText>
                  <Typography variant="body2">{field.stockUnitSymbol}</Typography>
                </HStack>
              </>
            )}
          </HStack>
        </Stack>
      </HStack>
      <HStack spacing={6} alignItems="baseline" h="54px">
        <Stack spacing={1.5}>
          <Typography variant="body2" fontWeight="bold">
            融通希望日
          </Typography>
          <Controller
            name={`requestMedicines.${index}.transferDate`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                placeholderText="-"
                selected={value}
                minDate={TODAY}
                maxDate={addYears(TODAY, 20)}
                onChange={(v) => onChange(v ?? undefined)}
                customInput={<TextInput size="sm" />}
                dateFormat="yyyy/MM/dd"
              />
            )}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="body2" fontWeight="bold">
            依頼先店舗
            <RequiredTag ml={1} />
          </Typography>
          <Controller
            name={`requestMedicines.${index}.targetPharmacyId`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box w="300px">
                <GroupPharmacyWithStockUnitQuantitySelect
                  onChange={onChange}
                  value={value}
                  medicineId={field.medicineId}
                  onClear={() => onChange(undefined)}
                  placeholder="選択してください"
                />
              </Box>
            )}
          />
        </Stack>
        <Stack spacing={3}>
          <Typography variant="body2" fontWeight="bold">
            単位薬価
          </Typography>
          <HStack alignItems="baseline" spacing={0.5}>
            <NumberText>{field.stockUnitPrice}</NumberText>
            <Typography variant="body2">円</Typography>
          </HStack>
        </Stack>
      </HStack>
      <HStack justifyContent="space-between" alignItems="flex-end">
        <Stack spacing={1.5}>
          <Typography variant="body2" fontWeight="bold">
            依頼メモ
          </Typography>
          <ErrorTooltip
            placement="top"
            isOpen={!!errors.requestMedicines?.[index]?.requestMemo?.message}
            label={errors.requestMedicines?.[index]?.requestMemo?.message}
          >
            <TextInput size="sm" w="750px" {...register(`requestMedicines.${index}.requestMemo`)} />
          </ErrorTooltip>
        </Stack>
        <Button variant="ghost" size="md" h={8} onClick={() => remove(index)}>
          削除
        </Button>
      </HStack>
    </Stack>
  );
}
