import { Checkbox } from '@chakra-ui/react';
import { GroupBase, OptionProps, components } from 'react-select';
import { Typography } from '../../../Typography';
import { OptionType } from '../types';

export function Option(props: OptionProps<OptionType, boolean, GroupBase<OptionType>>) {
  const { data, isSelected } = props;

  return (
    <>
      <components.Option {...props}>
        <Checkbox isChecked={isSelected} pointerEvents="none" spacing={1}>
          <Typography variant="body2" color="slate.700">
            {data.label}
          </Typography>
        </Checkbox>
      </components.Option>
      {data.note !== undefined && (
        <Typography variant="caption" color="slate.300" pt={1}>
          {data.note}
        </Typography>
      )}
    </>
  );
}
