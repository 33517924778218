import { chakra } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isFewPrescriptionFrequency, PrescriptionFrequencyType } from '@/entities/prescription';

type Props = {
  /** 処方頻度タイプ */
  prescriptionFrequencyType?: PrescriptionFrequencyType;
  /** 欠品注意ラベルがあるかのフラグ */
  hasStockoutPreventionLabel: boolean;
};

export function NoOrderPointForecastCaption({
  prescriptionFrequencyType,
  hasStockoutPreventionLabel,
}: Props) {
  // forecast からの連携データがない かつ 小数処方 の場合
  if (isFewPrescriptionFrequency(prescriptionFrequencyType)) {
    return (
      <Typography variant="body2" color="slate.500">
        少数処方の医薬品です。10日以内に来局予定のある処方患者の予測処方量が発注点になります。
        {hasStockoutPreventionLabel && (
          <chakra.span fontWeight="bold">
            ※少数処方には欠品注意ラベルの係数2は適用されません。
          </chakra.span>
        )}
      </Typography>
    );
  }
  return null;
}
