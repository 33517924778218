import { AlertDialog, AlertDialogOverlay } from '@chakra-ui/react';
import { useRef } from 'react';
import { DialogContent } from './DialogContent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  sourceMedicine: {
    id: string;
    name: string;
    unitOutline: string;
    // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
    shortUnitOutline: string;
  };
};

export function DemandTakeoverToMedicinesDialog({ isOpen, onClose, sourceMedicine }: Props) {
  const cancelRef = useRef(null);
  return (
    <AlertDialog
      size="lg"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      scrollBehavior="outside"
    >
      <AlertDialogOverlay>
        <DialogContent onClose={onClose} cancelRef={cancelRef} sourceMedicine={sourceMedicine} />
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
