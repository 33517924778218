import {
  GridItem,
  HStack,
  NumberInput,
  NumberInputField,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { ErrorTooltip, RequiredTag, Typography } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isLoading?: boolean;
} & Pick<UseFormReturn<T>, 'control' | 'trigger'>;

export function DefaultPriceRateForm<T extends FieldValues>({
  name,
  isLoading = false,
  control,
  trigger,
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Typography variant="body" fontWeight="bold">
          出庫額算出率
          <RequiredTag ml={2} />
        </Typography>
      </GridItem>
      <GridItem>
        <Skeleton isLoaded={!isLoading} borderRadius="md">
          <HStack spacing={5}>
            <VStack alignItems="flex-start">
              <HStack>
                <Controller
                  name={name}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <HStack>
                      <NumberInput
                        min={0}
                        size="sm"
                        defaultValue={field.value}
                        isInvalid={!!error?.message}
                        onChange={(_, valueAsNumber) => {
                          field.onChange(Number.isNaN(valueAsNumber) ? undefined : valueAsNumber);
                          trigger();
                        }}
                      >
                        <ErrorTooltip
                          label={error?.message}
                          isOpen={!!error?.message}
                          placement="top-start"
                        >
                          <NumberInputField
                            ref={field.ref}
                            textAlign="right"
                            paddingInlineEnd={4}
                            bg={error ? 'red.50' : 'white'}
                            w="60px"
                            rounded="base"
                            fontSize="md"
                          />
                        </ErrorTooltip>
                      </NumberInput>
                      <Typography variant="body">%</Typography>
                    </HStack>
                  )}
                />
                <VStack alignItems="flex-start" spacing={0}>
                  <Typography variant="body2" color="slate.500">
                    出庫額（単位薬価×出庫数×出庫額算出率）の計算に利用します。
                  </Typography>
                  <Typography variant="body2" color="slate.500">
                    出庫額は出庫手続き時に編集できます。
                  </Typography>
                </VStack>
              </HStack>
            </VStack>
          </HStack>
        </Skeleton>
      </GridItem>
    </>
  );
}
