import { ModalBody, ModalFooter, Button, Tabs, ModalContent } from '@chakra-ui/react';
import { useAlertDialog } from '@kkhs/hakari-ui';
import {
  ADOPTED_MEDICAL_PRODUCT_MODAL_TABS,
  AdoptedMedicalProductModalTabKey,
  adoptedMedicalProductModalActions,
} from '@/entities/adoptedMedicalProduct';
import { AdoptedMedicalModalBody } from './AdoptedMedicalModalBody';
import { AdoptedMedicalModalHeader } from './AdoptedMedicalModalHeader';
import { useAdoptedMedicalModalContent } from './useAdoptedMedicalModalContent';

type Props = {
  /** 対象の薬剤ID */
  medicineId: string;
  /** 初期に開くタブのインデックス */
  defaultTabKey?: AdoptedMedicalProductModalTabKey;
  /** 必須買いかどうか */
  isMustBuy?: boolean;
};

export function AdoptedMedicalProductModalContent({ medicineId, defaultTabKey, isMustBuy }: Props) {
  const { handleClose: handleCloseModal, shouldAlert } = useAlertDialog();
  const onClose = adoptedMedicalProductModalActions.useClose();

  const handleClose = () => {
    if (shouldAlert) {
      handleCloseModal?.();
    } else {
      onClose();
    }
  };

  const { adoptedMedicalProduct, stockUnitSymbol, medicalProductType } =
    useAdoptedMedicalModalContent({
      medicineId,
    });

  return (
    <ModalContent h="89vh" mb="unset">
      <AdoptedMedicalModalHeader fragment={adoptedMedicalProduct} />
      <ModalBody borderBottom="1px" borderColor="gray.200" p={0} overflow="hidden">
        <Tabs
          colorScheme="primary"
          isLazy
          h="100%"
          orientation="vertical"
          variant="unstyled"
          defaultIndex={ADOPTED_MEDICAL_PRODUCT_MODAL_TABS.findIndex(
            ({ key }) => key === defaultTabKey,
          )}
        >
          <AdoptedMedicalModalBody
            medicineId={medicineId}
            stockUnitSymbol={stockUnitSymbol}
            medicalProductType={medicalProductType}
            isMustBuy={isMustBuy}
          />
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" color="primary.600" onClick={handleClose}>
          閉じる
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
