import { Center } from '@chakra-ui/react';
import { ClearIndicatorProps } from 'react-select';
import { CrossIcon } from '../../icons';
import { SelectOptionType } from '../types';

export function ClearIndicator<T = number>(props: ClearIndicatorProps<SelectOptionType<T>, false>) {
  const {
    innerProps: { ref, ...restInnerProps },
    getStyles,
  } = props;
  const styles = getStyles('clearIndicator', props);
  return (
    <Center {...restInnerProps} ref={ref} cursor="pointer" mr={2}>
      <CrossIcon
        // NOTE: test idが入っている理由
        //       Selectで使用時に展開ボタンもsvgなため、テストで区別がつけられないため
        data-testid="クリアボタン"
        color="slate.300"
        _hover={{ color: 'slate.500' }}
        w={styles.width as number}
        h={styles.height as number}
      />
    </Center>
  );
}
