import { Stack } from '@chakra-ui/react';
import { Typography, RequiredTag, TextInput, DatePicker } from '@kkhs/hakari-ui';
import { jstNow } from '@kkhs/hakari-utils';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isDisabled?: boolean;
  control: UseFormReturn<T>['control'];
  minDate?: Date | undefined;
};

export function StockOperationTargetDatePickerForm<T extends FieldValues>({
  name,
  isDisabled = false,
  control,
  minDate,
}: Props<T>) {
  return (
    // MEMO: カレンダーを開いた際に DOM が崩れないように height を指定
    <Stack spacing={1} h="54px">
      <Typography variant="body" fontWeight="bold">
        入庫対象日
        <RequiredTag ml={1} />
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            selected={value}
            onChange={onChange}
            customInput={<TextInput size="sm" w="160px" />}
            dateFormat="yyyy/MM/dd"
            maxDate={jstNow()}
            minDate={minDate}
            disabled={isDisabled}
          />
        )}
      />
    </Stack>
  );
}
