import { IconButton, Stack, HStack, Box, Divider, Center, Spinner } from '@chakra-ui/react';
import { Typography, PlusIcon } from '@kkhs/hakari-ui';
import { UseFieldArrayPrepend } from 'react-hook-form';
import { useSearchGroupPharmacyStockOperationMedicineQuery } from '@/gql/apollo';
import { FieldValues } from '../schema';

type Props = {
  medicineSearchKeyword: string;
  addMedicine: UseFieldArrayPrepend<FieldValues, 'requestMedicines'>;
  onClose: () => void;
};

export function SearchResults({ medicineSearchKeyword, addMedicine, onClose }: Props) {
  const { data, loading } = useSearchGroupPharmacyStockOperationMedicineQuery({
    variables: { filter: { medicineSearchKeyword } },
  });

  if (loading) {
    return (
      <Center>
        <Spinner color="primary.500" />
      </Center>
    );
  }
  if (!data?.adoptedMedicines.edges.length) {
    return (
      <Typography variant="body" color="slate.300" textAlign="center" py={4}>
        該当する医薬品はありません
      </Typography>
    );
  }
  return (
    <Stack divider={<Divider borderColor="gray.200" />}>
      {data.adoptedMedicines.edges.map(({ node }) => (
        <HStack key={node.medicine.id}>
          <Box flex="1">
            <Typography variant="body">{node.medicine.medicineName}</Typography>
            <Typography variant="body">{node.medicine.usageGroup.shortUnitOutline}</Typography>
          </Box>
          <IconButton
            icon={<PlusIcon boxSize={3} />}
            size="xs"
            aria-label="医薬品を追加"
            ml={2}
            onClick={() => {
              addMedicine({
                requestType: 'take',
                medicineId: node.medicine.id,
                medicineName: node.medicine.medicineName,
                unitOutline: node.medicine.usageGroup.unitOutline,
                // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
                shortUnitOutline: node.medicine.usageGroup.shortUnitOutline,
                stockUnitSymbol: node.medicine.stockUnitSymbol,
                stockUnitQuantity: node.medicine.stockUnitQuantity,
                stockUnitPrice: node.medicine.stockUnitPrice,
                nearExpirationUnitQuantity: undefined,
                transferDate: undefined,
                targetPharmacyId: undefined,
                unitQuantity: 0,
                lotNumber: undefined,
                expirationDate: undefined,
                requestMemo: '',
                adoptionStoppedDate: node.medicine.adoptionStoppedDate ?? undefined,
              });
              onClose();
            }}
          />
        </HStack>
      ))}
    </Stack>
  );
}
