import { createIcon } from '@chakra-ui/react';

export const AdoptionStoppedIcon = createIcon({
  displayName: 'AdoptionStoppedIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68198 1.61091C10.8299 -0.536971 14.3123 -0.536971 16.4602 1.61091C18.2237 3.37449 18.5393 6.03775 17.4068 8.12328C21.1542 8.78805 24 12.0617 24 16C24 20.4183 20.4183 24 16 24C12.0617 24 8.78809 21.1542 8.12331 17.4068C6.03778 18.5393 3.3745 18.2237 1.61091 16.4602C-0.536971 14.3123 -0.536971 10.8299 1.61091 8.68198L8.68198 1.61091ZM15.0459 7.97487L14.9529 8.0679C13.3345 8.27947 11.8682 8.97476 10.704 10.0039L6.9107 6.21062L10.0962 3.02513C11.463 1.65829 13.6791 1.65829 15.0459 3.02513C16.4128 4.39196 16.4128 6.60804 15.0459 7.97487ZM6.20359 6.91773L9.99729 10.7114C8.9718 11.8745 8.27905 13.338 8.06793 14.9529L7.97487 15.0459C6.60804 16.4128 4.39196 16.4128 3.02513 15.0459C1.65829 13.6791 1.65829 11.463 3.02513 10.0962L6.20359 6.91773ZM19 11.5L20.5 13L17.5 16L20.5 19L19 20.5358L16 17.5L13 20.5L11.4657 19L14.5 16L11.4657 13L13 11.5L16 14.5L19 11.5Z"
        fill="currentColor"
      />
    </svg>
  ),
});
