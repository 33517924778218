import { createIcon } from '@chakra-ui/react';

export const ForecastAccuracyUnknownIcon = createIcon({
  displayName: 'ForecastAccuracyUnknownIcon',
  viewBox: '0 0 30 30',
  path: (
    <path
      d="M14.2788 22.5408H16.4647V20.2315H14.2788V22.5408ZM15.3718 8.54077C13.0493 8.54077 11 10.7057 11 13.1593H13.1859C13.1859 11.8604 14.1422 10.85 15.3718 10.85C16.6013 10.85 17.5577 11.8604 17.5577 13.1593C17.5577 15.4686 14.2788 15.1799 14.2788 19.0769H16.4647C16.4647 16.4789 19.7435 16.1903 19.7435 13.1593C19.8802 10.7057 17.8309 8.54077 15.3718 8.54077Z"
      fill="currentColor"
    />
  ),
});
