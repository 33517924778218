import { HStack, Stack } from '@chakra-ui/react';
import { NumberText, Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';

type Props = {
  label: string;
  value?: number;
  unitSymbol: string;
};

export function NumberItemLabel({ label, value, unitSymbol }: Props) {
  return (
    <Stack spacing={0.5}>
      <Typography variant="body2" color="slate.500">
        {label}
      </Typography>
      {!isNullOrUndefined(value) ? (
        <HStack spacing={0.5}>
          <NumberText size="md">{value}</NumberText>
          <Typography variant="body">{unitSymbol}</Typography>
        </HStack>
      ) : (
        <Typography variant="body">-</Typography>
      )}
    </Stack>
  );
}
