import { Box, HStack, Table, Th, Thead, Tr } from '@chakra-ui/react';
import { InfoIcon, InfoPopover } from '@kkhs/hakari-ui';
import { useOwnPharmacyMedicineMap } from '@/entities/medicine';
import { GroupPharmacyStocksByMedicineQuery } from '@/gql/docs';
import { GroupPharmacyStocksByMedicineTableBody } from './GroupPharmacyStocksByMedicineTableBody';
import {
  RenderAdoptedMedicalMaterialCreateDialog,
  RenderAdoptedMedicineCreateDialog,
  RenderOwnTakeTransferRequestDialog,
} from './types';

export type {
  RenderOwnTakeTransferRequestDialog,
  RenderAdoptedMedicineCreateDialog,
  RenderAdoptedMedicalMaterialCreateDialog,
};

type Props = {
  medicineId: string;
  groupPharmacyStocks: GroupPharmacyStocksByMedicineQuery['groupPharmacyStocks']['othersMedicineStockListItems'];
  isLoading: boolean;
  noRowsRenderer: () => JSX.Element;
  renderOwnTakeTransferRequestDialog: RenderOwnTakeTransferRequestDialog;
  renderAdoptedMedicineCreateDialog: RenderAdoptedMedicineCreateDialog;
  renderAdoptedMedicalMaterialCreateDialog: RenderAdoptedMedicalMaterialCreateDialog;
};

export function GroupPharmacyStocksByMedicineTable({
  medicineId,
  groupPharmacyStocks,
  isLoading: isGroupPharmacyStocksLoading,
  noRowsRenderer,
  renderOwnTakeTransferRequestDialog,
  renderAdoptedMedicineCreateDialog,
  renderAdoptedMedicalMaterialCreateDialog,
}: Props) {
  const groupPharmacyStocksMedicineIds =
    groupPharmacyStocks.map(({ medicine }) => medicine.id) ?? [];
  const { ownPharmacyMedicineMap, isLoading: isAdoptedMedicinesloading } =
    useOwnPharmacyMedicineMap({ groupPharmacyStocksMedicineIds });

  const listItems = groupPharmacyStocks.map((listItem) => ({
    ...listItem,
    ownPharmacyMedicine: ownPharmacyMedicineMap?.[listItem.medicine.id],
  }));

  const isLoading = isGroupPharmacyStocksLoading || isAdoptedMedicinesloading;

  if (!isLoading && listItems?.length === 0) {
    return noRowsRenderer();
  }

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th w="156px" pl={0} pr={1} verticalAlign="top" lineHeight="1.2">
            薬局名
            <br />
            直近の融通日
          </Th>
          <Th w="149px" pl={1} pr={1} verticalAlign="top" lineHeight="1.2">
            需要タイプ
          </Th>
          <Th w="64px" pl={1} pr={1} verticalAlign="top" isNumeric>
            <HStack spacing={1} justifyContent="flex-end" alignItems="flex-start">
              <Box whiteSpace="nowrap" lineHeight="1.2">
                不動在庫
                <br />
                日数
              </Box>
              <InfoPopover
                content="不動期間が90日以上の場合に不動在庫ありと表示します"
                closeOnScroll
              >
                <InfoIcon boxSize={3} />
              </InfoPopover>
            </HStack>
          </Th>
          <Th w="94px" pl={1} pr={1} lineHeight="1.2" isNumeric>
            在庫数量
            <br />
            発注点
          </Th>
          <Th w="94px" pl={1} pr={1} verticalAlign="top" isNumeric>
            <HStack spacing={1} justifyContent="flex-end" alignItems="flex-start">
              <Box whiteSpace="nowrap" lineHeight="1.2">
                余剰在庫数
              </Box>
              <InfoPopover content="余剰在庫数＝在庫数量ー発注点" closeOnScroll>
                <InfoIcon boxSize={3} />
              </InfoPopover>
            </HStack>
          </Th>
          <Th w="75px" pl={1} pr={1} verticalAlign="top" lineHeight="1.2" isNumeric>
            最終処方
          </Th>
          <Th w="108px" pl={1} pr={1} verticalAlign="top" lineHeight="1.2" isNumeric>
            期限間近/期限切れ数
          </Th>
          <Th w="82px" pl={1} pr={1} verticalAlign="top" lineHeight="1.2" isNumeric>
            直近の有効期限
          </Th>
          <Th w="65px" pl={1} pr={0} />
        </Tr>
      </Thead>
      <GroupPharmacyStocksByMedicineTableBody
        rows={listItems}
        isLoading={isLoading}
        medicineId={medicineId}
        renderOwnTakeTransferRequestDialog={renderOwnTakeTransferRequestDialog}
        renderAdoptedMedicineCreateDialog={renderAdoptedMedicineCreateDialog}
        renderAdoptedMedicalMaterialCreateDialog={renderAdoptedMedicalMaterialCreateDialog}
      />
    </Table>
  );
}
