import { Checkbox } from '@chakra-ui/react';
import { Typography, useZodForm } from '@kkhs/hakari-ui';
import { ComponentProps, useMemo } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { ShippingStockOperationTypename } from '@/entities/stockOperation';
import { ShippingStockOperationDeleteFooterLayout } from './ShippingStockOperationDeleteFooterLayout';
import { schema } from './schema';

type Props = Omit<ComponentProps<typeof ShippingStockOperationDeleteFooterLayout>, 'loading'> & {
  stockOperationId: string;
  typename: ShippingStockOperationTypename;
};

export function LoadedShippingStockOperationDeleteFooter({
  stockOperationId,
  typename,
  onClose,
  cancelRef,
}: Props) {
  const useFormReturn = useZodForm({
    schema,
    defaultValues: {
      id: stockOperationId,
      isAlertChecked: false,
    },
  });
  const { control } = useFormReturn;

  const deleteConfirmMessage = useMemo(() => {
    switch (typename) {
      // NOTE: 廃棄・その他出庫・法人間出庫(ファルマ売却)の場合
      case 'ShippingStockOperationByDisposal':
      case 'ShippingStockOperationByOther':
      case 'ShippingStockOperationByStockSale':
        return '削除した出庫情報は復元不可であることを了承しました。';
      // NOTE: 法人内出庫(調整ベース・依頼ベース)・法人間出庫の場合
      case 'ShippingStockOperationByTransfer':
      case 'ShippingStockOperationByTransferRequest':
      case 'ShippingStockOperationByExternalTrade':
        return '削除した出庫情報と伝票は復元不可であることを了承しました。';
      default:
        return '';
    }
  }, [typename]);

  return (
    <FormProvider {...useFormReturn}>
      <ShippingStockOperationDeleteFooterLayout
        onClose={onClose}
        cancelRef={cancelRef}
        loading={false}
      >
        <Controller
          name="isAlertChecked"
          control={control}
          render={({ field: { onChange } }) => (
            <Checkbox onChange={(e) => onChange(e.target.checked)}>
              <Typography variant="body" fontWeight="bold">
                {deleteConfirmMessage}
              </Typography>
            </Checkbox>
          )}
        />
      </ShippingStockOperationDeleteFooterLayout>
    </FormProvider>
  );
}
