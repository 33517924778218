import {
  AdoptedMedicinesDocument,
  StocksDocument,
  AdoptedMedicineCostPriceListItemsDocument,
  OrderCandidatesDocument,
  StockMedicineReportsDocument,
  StockMedicineReportsByManagerDocument,
  StockMonthlyPrescribedAmountReportsDocument,
  StockMonthlyPrescribedAmountReportsByManagerDocument,
  StockDailyAmountReportsDocument,
  StockDailyAmountReportsByManagerDocument,
  CurrentMedicineRelatedItemsDocument,
  MedicineOrderCandidateManualSearchListItemsDocument,
} from '@/gql/apollo';
import { AdoptedMedicalProductModalContentDocument } from '@/gql/docs';

/**
 * NOTE: 本来は採用薬モーダルを表示するタイミングで指定すべきだが、過去の経緯でこちらに定義している
 *
 * 採用停止/解除、おすすめ対象外ラベルの表示に利用している Query を refetch する必要があるのでこちらに定義しておく。
 */
export const documentsToRefetch = [
  AdoptedMedicinesDocument,
  StocksDocument,
  AdoptedMedicineCostPriceListItemsDocument,
  OrderCandidatesDocument,
  MedicineOrderCandidateManualSearchListItemsDocument,
  StockMedicineReportsDocument,
  StockMedicineReportsByManagerDocument,
  StockMonthlyPrescribedAmountReportsDocument,
  StockMonthlyPrescribedAmountReportsByManagerDocument,
  StockDailyAmountReportsDocument,
  StockDailyAmountReportsByManagerDocument,
  CurrentMedicineRelatedItemsDocument,
  AdoptedMedicalProductModalContentDocument,
];
