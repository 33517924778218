import { createIcon } from '@chakra-ui/react';

export const OtherIcon = createIcon({
  displayName: 'OtherIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      d="M18.945 18.945c.305-.304.657-.457 1.055-.457.398 0 .75.153 1.055.457.304.305.457.657.457 1.055 0 .398-.153.75-.457 1.055-.305.304-.657.457-1.055.457-.398 0-.75-.153-1.055-.457-.304-.305-.457-.657-.457-1.055 0-.398.153-.75.457-1.055zm4.5 0c.305-.304.657-.457 1.055-.457.398 0 .75.153 1.055.457.304.305.457.657.457 1.055 0 .398-.153.75-.457 1.055-.305.304-.657.457-1.055.457-.398 0-.75-.153-1.055-.457-.304-.305-.457-.657-.457-1.055 0-.398.153-.75.457-1.055zm-9 0c.305-.304.657-.457 1.055-.457.398 0 .75.153 1.055.457.304.305.457.657.457 1.055 0 .398-.153.75-.457 1.055-.305.304-.657.457-1.055.457-.398 0-.75-.153-1.055-.457-.304-.305-.457-.657-.457-1.055 0-.398.153-.75.457-1.055z"
      fill="currentColor"
    />
  ),
});
