import { ProposedWholesales } from '../types';

/** 指定した規格容量の本部推奨卸を返す */
export function getProposedWholesale(
  medicinePackageUnitId: string,
  proposedWholesales: ProposedWholesales,
) {
  return proposedWholesales.find((w) => w.medicinePackageUnitId === medicinePackageUnitId)
    ?.wholesale;
}
