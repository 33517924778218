import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';

type Props = {
  amount: number;
  taxAmount: number | null;
  showTax: boolean | undefined;
  isManager: boolean;
};

export function TotalAmount({ amount, taxAmount, showTax, isManager }: Props) {
  const amountTypographyProps = {
    as: 'span',
    variant: 'h1',
    ml: 2,
    mr: 0.5,
  } as const;
  const taxAmountTypographyProps = {
    as: 'span',
    variant: 'body',
    ml: 2,
    mr: 0.5,
  } as const;
  return (
    <Stack spacing={0} alignItems="flex-end">
      <Typography variant="body" fontWeight="bold">
        総額
        {isManager ? (
          <Typography {...amountTypographyProps} isNumeric>
            {amount}
          </Typography>
        ) : (
          <Typography {...amountTypographyProps}>-</Typography>
        )}
        円
      </Typography>
      {showTax && (
        <Typography variant="body2" fontWeight="normal" lineHeight={0.5}>
          税額
          {!isNullOrUndefined(taxAmount) && isManager ? (
            <Typography {...taxAmountTypographyProps} isNumeric>
              {taxAmount}
            </Typography>
          ) : (
            <Typography {...taxAmountTypographyProps}>-</Typography>
          )}
          円
        </Typography>
      )}
    </Stack>
  );
}
