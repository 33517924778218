import { Stack, Image } from '@chakra-ui/react';
import { Typography, InfoPopover } from '@kkhs/hakari-ui';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  defaultIsOpen?: boolean;
};

export function FnPatientInfoPopover({ children, defaultIsOpen = false }: Props) {
  return (
    <InfoPopover
      defaultIsOpen={defaultIsOpen}
      w="360px"
      content={
        <Stack>
          <Image src="/assets/png/medicine-modal/img-fn-patient.png" alt="img-fn-patient" />
          <Typography variant="caption" color="slate.500">
            通常処方の中で、他の患者に比べて極端に処方量が多い患者のことを特定患者と呼びます。それぞれの患者の次回来局予定日の10日前におすすめに表示します。
          </Typography>
          <Typography variant="caption" color="slate.500">
            通常処方：過去4か月で3人以上の患者に処方が出た医薬品です。
          </Typography>
        </Stack>
      }
    >
      {children}
    </InfoPopover>
  );
}
