/* eslint-disable no-empty-function,no-useless-constructor */
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import Big from 'big.js';
import { RoundingMethod } from '@/gql/docs';
import { calculatePriceV2 } from '@/shared/utils';
import { ファルマ売却明細金額, StockSalePriceConfig } from '.';

/**
 * ファルマ売却の伝票粒度での総額と内税額を計算する
 *
 * 税額の算出方法は以下:
 * - 明細粒度の小計の税額は丸めない
 * - 伝票粒度で合算した後に小数点第一位で四捨五入し整数に丸める
 *
 * 総額の算出方法は以下:
 * - 明細粒度の小計を切り捨てる
 * - 伝票粒度で合算し税額と足し合わせる（丸めない）
 *
 * @see {@link https://kakehashigroup.esa.io/posts/3995}
 */
export class ファルマ売却伝票金額 {
  constructor(private 明細: ファルマ売却明細金額[]) {}

  明細件数を取得() {
    return this.明細.length;
  }

  税込み合計金額を計算(config: StockSalePriceConfig) {
    const { itemRoundingMethod, taxRoundingMethod, totalRoundingMethod } = config;
    const 合計金額 = this.合計金額を計算(itemRoundingMethod);
    const 税額 = this.税額を計算(taxRoundingMethod);
    return {
      合計金額: calculatePriceV2(合計金額.plus(税額), totalRoundingMethod).toNumber(),
      内税: 税額.toNumber(),
    };
  }

  private 合計金額を計算(小計の丸め込み方法: RoundingMethod | null) {
    return sum(this.明細.map((i) => i.小計を計算(小計の丸め込み方法)));
  }

  private 税額を計算(税額の丸め込み方法: RoundingMethod | null) {
    const 税額の合計 = sum(this.明細.map((i) => i.税額を計算()));
    return calculatePriceV2(税額の合計, 税額の丸め込み方法);
  }

  static create(
    items: {
      金額?: number | null;
      数量: number;
    }[],
  ) {
    const 計算対象 = items.filter(
      (
        item,
      ): item is {
        金額: number;
        数量: number;
      } => !isNullOrUndefined(item.金額) && !Number.isNaN(item.金額) && !Number.isNaN(item.数量),
    );
    return new ファルマ売却伝票金額(
      計算対象.map((item) => ファルマ売却明細金額.create(item.金額, item.数量)),
    );
  }
}

const sum = (arr: Big[]) => arr.reduce((acc, cur) => acc.plus(cur), new Big(0));
