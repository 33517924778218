import { createIcon } from '@chakra-ui/react';

export const CostPriceMasterSettingIcon = createIcon({
  displayName: 'CostPriceMasterSettingIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.6667L11 9H9L10 11H9V12H10.5L11 13H9V14H11V16H13V14H15V13H13L13.5 12H15V11H14L15 9H13L12 11.6667Z"
      fill="currentCursor"
    />,
    <path
      key="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
      fill="currentCursor"
    />,
    <path key="2" d="M10 2H14L15 5H9L10 2Z" fill="currentCursor" />,
    <path key="3" d="M14 22L10 22L9 19L15 19L14 22Z" fill="currentCursor" />,
    <path
      key="4"
      d="M4.33984 18.7321L2.33984 15.268L4.43792 12.902L7.43792 18.0981L4.33984 18.7321Z"
      fill="currentCursor"
    />,
    <path
      key="5"
      d="M19.6603 5.26788L21.6603 8.73198L19.5623 11.098L16.5623 5.90186L19.6603 5.26788Z"
      fill="currentCursor"
    />,
    <path
      key="6"
      d="M21.6602 15.2679L19.6602 18.732L16.5621 18.098L19.5621 12.9019L21.6602 15.2679Z"
      fill="currentCursor"
    />,
    <path
      key="7"
      d="M2.33966 8.73212L4.33966 5.26802L7.43774 5.90199L4.43774 11.0981L2.33966 8.73212Z"
      fill="currentCursor"
    />,
  ],
});
