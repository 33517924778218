import { LDFlagSet, LDProvider, useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { IFeatureFlagRepository as I } from '@/repository/featureFlag/interface';

export const LaunchdarklyRepository: I = {
  useSetupFeatureFlag: () => {
    const ldClient = useLDClient();
    return {
      setContext: ({ pharmacy }) => {
        ldClient?.identify({
          key: pharmacy.musubiCode,
          name: `${pharmacy.id}: ${pharmacy.name}`,
        });
      },
      setupDone: !!ldClient,
    };
  },
  useFlags: () => {
    const flags = useFlags();
    const result: { [K in keyof I.FlagSet]-?: I.FlagSet[K] } = {
      appMinimumVersion: optionalValue(flags, 'appMinimumVersion'),
      isEnableMaintenanceMode: optionalValue(flags, 'isEnableMaintenanceMode'),
      tmpChangePrescriptionForecastDateEnabled: optionalValue(
        flags,
        'tmpChangePrescriptionForecastDateEnabled',
      ),
      tmpDataImportEnabled: optionalValue(flags, 'tmpDataImportEnabled'),
      tmpEstimateDeliveryPriceEnabled: optionalValue(flags, 'tmpEstimateDeliveryPriceEnabled'),
      tmpManageStockEnabled: optionalValue(flags, 'tmpManageStockEnabled'),
      tmpPharmacySettingEnabled: optionalValue(flags, 'tmpPharmacySettingEnabled'),
      tmpPreMadeEnabled: optionalValue(flags, 'tmpPreMadeEnabled'),
      tmpReportEnabled: optionalValue(flags, 'tmpReportEnabled'),
      tmpWholesaleSettingEnabled: optionalValue(flags, 'tmpWholesaleSettingEnabled'),
      tmpClientCertificateEnabled: optionalValue(flags, 'tmpClientCertificateEnabled'),
      tmpEditMedicineTransactionsEnabled: optionalValue(
        flags,
        'tmpEditMedicineTransactionsEnabled',
      ),
      tmpTradingSummaryByWholesaleEnabled: optionalValue(
        flags,
        'tmpTradingSummaryByWholesaleEnabled',
      ),
      tmpPharmarketInfobar: optionalValue(flags, 'tmpPharmarketInfobar'),
      tmpProposedWholesaleEnabled: optionalValue(flags, 'tmpProposedWholesaleEnabled'),
      tmpShippingStockDialogV2Enabled: optionalValue(flags, 'tmpShippingStockDialogV2Enabled'),
      tmpOnboardingPageEnabled: optionalValue(flags, 'tmpOnboardingPageEnabled'),
      tmpHideOrderProposalEditInfoEnabled: optionalValue(
        flags,
        'tmpHideOrderProposalEditInfoEnabled',
      ),
      tmpSuzukenModelTargetFilterEnabled: optionalValue(
        flags,
        'tmpSuzukenModelTargetFilterEnabled',
      ),
      tmpStoringStockDialogV2Enabled: optionalValue(flags, 'tmpStoringStockDialogV2Enabled'),
    };
    return result;
  },
  Provider: ({ children }) => (
    <LDProvider
      clientSideID={process.env.LAUNCHDARKLY_CLIENT_SIDE_ID}
      options={{ streaming: true }}
    >
      {children}
    </LDProvider>
  ),
};

function optionalValue<K extends keyof I.FlagSet>(
  flags: LDFlagSet,
  key: K,
): NonNullable<I.FlagSet[K]> {
  return (flags[key] as I.FlagSet[K]) ?? (I.DefaultFlagSet[key] as (typeof I.DefaultFlagSet)[K]);
}
