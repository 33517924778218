import { FormControl, HStack, Stack, Box, Button, IconButton, FormLabel } from '@chakra-ui/react';
import { ErrorTooltip, Typography, MinusSquareIcon, PlusSquareIcon } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { StorageSelect } from '../StorageSelect';

export function StoragesForm() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<{ storages: { id: string; name: string }[] }>();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'storages',
    keyName: 'key',
  });
  const changeStorage = useCallback(
    async (i: number, nextValue: { id: string; name: string }) => {
      update(i, nextValue);
      await trigger([`storages.${i}`]);
    },
    [trigger, update],
  );
  const deleteStorage = useCallback(
    async (i: number) => {
      remove(i);
      await trigger(['storages']);
    },
    [remove, trigger],
  );
  const addStorage = useCallback(async () => {
    await trigger(['storages']);
    append({});
  }, [append, trigger]);
  return (
    <Stack spacing={1}>
      <FormLabel>
        <Typography variant="body2" fontWeight="bold" as="span">
          置き場所
        </Typography>
      </FormLabel>
      <Stack spacing={3} flex="1">
        {fields.map((storage, i) => (
          <FormControl key={storage.key}>
            <HStack>
              <ErrorTooltip
                placement="top"
                label={errors.storages?.[i]?.id?.message}
                isOpen={!!errors.storages?.[i]}
              >
                <HStack spacing={2}>
                  <Box w="300px">
                    <StorageSelect
                      defaultValue={
                        storage.id
                          ? {
                              value: { id: storage.id, name: storage.name },
                              label: storage.name,
                            }
                          : undefined
                      }
                      onChange={(v) => changeStorage(i, v)}
                      isInvalid={!!errors?.storages?.[i]}
                      placeholder="入力してください"
                    />
                  </Box>
                  <IconButton
                    aria-label="置き場所を一つ削除"
                    icon={<MinusSquareIcon color="gray.300" boxSize={4} />}
                    size="sm"
                    variant="unstyled"
                    onClick={() => deleteStorage(i)}
                  />
                </HStack>
              </ErrorTooltip>
            </HStack>
          </FormControl>
        ))}
        <Button
          leftIcon={<PlusSquareIcon color="primary.300" w={5} h={5} />}
          color="slate.300"
          size="sm"
          variant="link"
          alignSelf="flex-start"
          letterSpacing={1}
          mt={1.5}
          onClick={addStorage}
        >
          新規の置き場所を追加
        </Button>
      </Stack>
    </Stack>
  );
}
