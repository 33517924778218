import { Text, TextProps, useMultiStyleConfig } from '@chakra-ui/react';

type Props = {
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  inline?: boolean;
  children: number | undefined | null;
} & Omit<TextProps, 'children'>;

export function NumberText({ size = 'sm', inline, children, ...chakraProps }: Props) {
  const styles = useMultiStyleConfig('NumberText', { size });

  return (
    <Text {...chakraProps} sx={styles} as={inline ? 'span' : undefined}>
      {children !== undefined && children !== null && !Number.isNaN(children)
        ? children.toLocaleString()
        : '-'}
    </Text>
  );
}
