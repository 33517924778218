import { SelectV2, SelectV2Props } from '@kkhs/hakari-ui';
import { forwardRef } from 'react';
import { z } from 'zod';
import { useStoragesLazyQuery } from '@/gql/apollo';

const schema = z
  .string()
  .trim()
  .transform((v) => v.slice(0, 1000));

type StorageOption = { id: string; name: string };

type Props = {
  /** Ref */
  ref?: React.Ref<HTMLSelectElement>;
} & Omit<SelectV2Props<StorageOption>, 'loadOptions'>;

export const StorageSelect = forwardRef<React.ElementRef<typeof SelectV2>, Omit<Props, 'ref'>>(
  ({ ...rest }: Omit<Props, 'ref'>, ref) => {
    const [fetch] = useStoragesLazyQuery();
    return (
      <SelectV2<StorageOption>
        {...rest}
        ref={ref}
        noOptionsMessage="置き場所を入力してください"
        loadOptions={async (inputValue: string) => {
          const parsed = schema.parse(inputValue);

          const result = await fetch({
            variables: {
              filter: {
                name: parsed,
              },
            },
          });
          return (
            result.data?.storageListItems.edges.map(({ node }) => ({
              value: { id: node.storage.id, name: node.storage.name },
              label: node.storage.name,
            })) ?? []
          );
        }}
      />
    );
  },
);
StorageSelect.displayName = 'StorageSelect';
