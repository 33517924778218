import { Stack } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { ShippingStockOperationDeleteDialogQuery } from '@/gql/docs';

type Props = {
  containerAmount: Extract<
    ShippingStockOperationDeleteDialogQuery['stockOperation'],
    { __typename: 'ShippingStockOperationByExternalTrade' }
  >['containerAmount'];
};

export function StockOperationShippingContainerAmountLabel({ containerAmount }: Props) {
  return (
    <Stack w="100px" spacing={1}>
      <Typography variant="body" fontWeight="bold">
        容器代
      </Typography>
      <Typography variant="body">
        {!isNullOrUndefined(containerAmount) ? containerAmount : '-'}円
      </Typography>
    </Stack>
  );
}
