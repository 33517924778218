import { GridItem, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { RadioGroup, InfoIcon, InfoPopover } from '@kkhs/hakari-ui';
import { Controller, FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isLoading?: boolean;
  showInfo?: boolean;
  isTotalRoundingMethodDisabled: boolean;
} & Pick<UseFormReturn<T>, 'control'>;

export function TotalRoundingMethodForm<T extends FieldValues>({
  name,
  isLoading = false,
  showInfo = false,
  isTotalRoundingMethodDisabled,
  control,
}: Props<T>) {
  return (
    <>
      <GridItem>
        <HStack spacing={1} alignItems="center">
          <Text fontWeight="bold">総額表示</Text>
          {showInfo && (
            <InfoPopover content="明細の合算後に端数が発生する場合に本内容が適用されます。" w="60">
              <InfoIcon color="slate.500" boxSize={4} />
            </InfoPopover>
          )}
        </HStack>
      </GridItem>
      <GridItem>
        <HStack>
          <Stack spacing={1}>
            <Text
              fontSize="sm"
              fontWeight="bold"
              color={isTotalRoundingMethodDisabled ? 'gray.200' : undefined}
            >
              総額端数処理
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    options={[
                      { value: 'round', label: '四捨五入' },
                      { value: 'roundDown', label: '切り捨て' },
                      { value: 'roundUp', label: '切り上げ' },
                    ]}
                    isDisabled={isTotalRoundingMethodDisabled}
                    value={value}
                    onChange={onChange}
                    itemMinWidth="28"
                  />
                )}
              />
            </Skeleton>
          </Stack>
        </HStack>
      </GridItem>
    </>
  );
}
