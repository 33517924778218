import { createIcon } from '@chakra-ui/react';

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M23.3555 10.6055V13.3945H6.08984L13.9922 21.3633L12 23.3555L0.644531 12L12 0.644531L13.9922 2.63672L6.08984 10.6055H23.3555Z"
      fill="currentColor"
    />
  ),
});
