import { ShippingStockOperationDeleteButtonGroupLayout } from './ShippingStockOperationDeleteButtonGroupLayout';

type Props = {
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
};

export function LoadingShippingStockOperationDeleteButtonGroup({ onClose, cancelRef }: Props) {
  return (
    <ShippingStockOperationDeleteButtonGroupLayout
      onClose={onClose}
      cancelRef={cancelRef}
      isDisabledCancelButton
      isDisabledSubmitButton
      isLoadingSubmitButton={false}
    />
  );
}
