import { Center } from '@chakra-ui/react';
import { LoadingBar } from '@kkhs/hakari-ui';
import { useInitializeFeatureFlags } from './hooks';

type Props = {
  children: JSX.Element;
};

export function FeatureFlagRoot({ children }: Props) {
  const featureFlagsInitialized = useInitializeFeatureFlags();

  if (!featureFlagsInitialized) {
    return (
      <Center w="100%" h="100vh">
        <LoadingBar />
      </Center>
    );
  }
  return children;
}
