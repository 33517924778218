import { Stack, HStack } from '@chakra-ui/react';
import { Typography, InfoIcon, PatientIcon, Link } from '@kkhs/hakari-ui';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { StockUnitQuantityWithSymbol } from '@/entities/medicine';
import { PrescriptionFrequencyType } from '@/entities/prescription';
import { MedicineSummaryTabQuery } from '@/gql/apollo';
import { formatAWSDate } from '@/shared/utils';
import { FnPatientInfoPopover } from '../FnPatientInfoPopover';

type Props = {
  stockUnitSymbol: string;
  prescriptionFrequencyType?: PrescriptionFrequencyType;
  identifiedPatients: MedicineSummaryTabQuery['identifiedPatientListItems'];
};

export function IdentifiedPatientContent({
  stockUnitSymbol,
  prescriptionFrequencyType,
  identifiedPatients,
}: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  if (!prescriptionFrequencyType) return null;
  return (
    <Stack spacing={2}>
      <HStack spacing={1}>
        {prescriptionFrequencyType === 'fn' ? (
          <>
            <Typography variant="body">特定患者</Typography>
            <FnPatientInfoPopover>
              <InfoIcon color="slate.500" boxSize={3} />
            </FnPatientInfoPopover>
          </>
        ) : (
          <Typography variant="body">処方患者</Typography>
        )}
      </HStack>
      {identifiedPatients.map(
        ({ patient, lastPrescriptionSummary, prescriptionForecastSummary }) => (
          <Stack key={patient.id} spacing={0.5}>
            <HStack>
              <PatientIcon color="slate.300" boxSize={3} />
              <Link
                href={`/patient/detail/?patientId=${patient.id}`}
                textDecoration="underline"
                target="_blank"
                onClick={() => {
                  userMonitoringRepository.sendEvent({
                    key: '医薬品詳細モーダルの概要タブで患者詳細リンクをClick',
                    contexts: { pharmacyId, musubiCode },
                  });
                }}
              >
                <Typography variant="body" fontWeight="bold">
                  {patient.name}
                </Typography>
              </Link>
            </HStack>
            <Stack spacing={0}>
              <HStack>
                <Typography variant="body2" color="slate.500" whiteSpace="nowrap">
                  {`前回来局日 ${formatAWSDate(lastPrescriptionSummary?.date, {
                    showDayOfWeek: true,
                  })}`}
                </Typography>
                <StockUnitQuantityWithSymbol
                  variant="body2"
                  color="slate.500"
                  quantity={lastPrescriptionSummary?.stockUnitQuantity}
                  stockUnitSymbol={stockUnitSymbol}
                  spacing={0.5}
                />
              </HStack>
              <HStack>
                <Typography variant="body2" color="slate.500" whiteSpace="nowrap">
                  {`来局予定日 ${formatAWSDate(prescriptionForecastSummary?.date, {
                    showDayOfWeek: true,
                  })}`}
                </Typography>
                <StockUnitQuantityWithSymbol
                  variant="body2"
                  color="slate.500"
                  quantity={prescriptionForecastSummary?.stockUnitQuantity}
                  stockUnitSymbol={stockUnitSymbol}
                  spacing={0.5}
                />
              </HStack>
            </Stack>
          </Stack>
        ),
      )}
    </Stack>
  );
}
