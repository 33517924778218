import { InfoIcon, InfoPopover, Typography } from '@kkhs/hakari-ui';

export function CostPricePopover({ isManager }: { isManager: boolean }) {
  return (
    <InfoPopover
      w="270px"
      content={
        <Typography variant="body2" color="slate.500" fontWeight="normal" letterSpacing={0}>
          {isManager
            ? '空欄で確定した場合は、入庫原価なしとして登録します。'
            : '入庫原価の編集が必要な場合は、管理者にお問い合わせください。'}
        </Typography>
      }
    >
      <InfoIcon color="slate.500" boxSize="12px" />
    </InfoPopover>
  );
}
