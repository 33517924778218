import { FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { RequiredTag, Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getMedicinePackageUnitLabel } from '@/entities/medicinePackageUnit';
import { MedicinePackageUnitFragment, useOrderableMedicinePackageUnitsQuery } from '@/gql/apollo';
import { MedicinePackageUnitSelect } from './MedicinePackageUnitSelect';
import { PrimaryMedicinePackageUnitFormAlert } from './PrimaryMedicinePackageUnitFormAlert';

type Props = {
  medicineId?: string;
  /** 複数件の包装単位がある場合、必須タグを表示するか */
  showRequired?: boolean;
};

function getMedicinePackageUnitOptionType(fragment: MedicinePackageUnitFragment) {
  return {
    value: fragment,
    label: getMedicinePackageUnitLabel(fragment, { isShowGtinCode: true }),
  };
}

export function MedicinePackageUnitForm({ medicineId, showRequired = false }: Props) {
  const { control, setValue, watch } = useFormContext<{ medicinePackageUnitId: string }>();
  const [medicinePackageUnitCount, setMedicinePackageUnitCount] = useState<number | undefined>(
    undefined,
  );

  const currentValue = watch('medicinePackageUnitId');

  const { data, loading } = useOrderableMedicinePackageUnitsQuery({
    // NOTE: medicineId が undefined の場合は、skip している
    variables: { medicineId: medicineId as string },
    skip: isNullOrUndefined(medicineId),
    onCompleted: ({ orderableMedicinePackageUnits }) => {
      setMedicinePackageUnitCount(orderableMedicinePackageUnits.length);
      if (orderableMedicinePackageUnits.length === 1 && orderableMedicinePackageUnits[0]) {
        setValue('medicinePackageUnitId', orderableMedicinePackageUnits[0].id);
      }
    },
  });
  const options = useMemo(
    () => data?.orderableMedicinePackageUnits.map(getMedicinePackageUnitOptionType),
    [data],
  );

  const showRequiredTag = showRequired && medicinePackageUnitCount !== 1;

  return (
    <Stack spacing={1}>
      <FormControl isRequired={showRequiredTag}>
        <FormLabel
          htmlFor="medicinePackageUnitId"
          requiredIndicator={showRequiredTag ? <RequiredTag ml={2} mt={0} /> : undefined}
          as="div"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body2" fontWeight="bold" as="span">
            優先規格容量
          </Typography>
        </FormLabel>
        {isNullOrUndefined(medicineId) ? (
          <Stack minH="40px">
            <Typography variant="body">-</Typography>
          </Stack>
        ) : (
          <Controller
            name="medicinePackageUnitId"
            control={control}
            render={({ field: { onChange } }) => {
              const value = options?.find((o) => o.value.id === currentValue) ?? null;
              return (
                <MedicinePackageUnitSelect
                  isLoading={loading}
                  options={options}
                  onChange={({ id }) => onChange(id)}
                  value={value}
                />
              );
            }}
          />
        )}
      </FormControl>
      {medicinePackageUnitCount !== 1 && <PrimaryMedicinePackageUnitFormAlert />}
    </Stack>
  );
}
