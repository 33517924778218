import { IconProps } from '@chakra-ui/react';
import * as React from 'react';
import * as icons from '../forecastAccuracy';

type Props = {
  value: number | undefined;
} & IconProps;

const ForecastAccuracyIconRenderFunction: React.ForwardRefRenderFunction<SVGSVGElement, Props> =
  function ForecastAccuracyIconRenderFunction({ value, ...rest }, ref) {
    if (value === undefined) {
      return <icons.ForecastAccuracyUnknownIcon color="slate.300" ref={ref} {...rest} />;
    }
    if (value >= 0.7) {
      return <icons.ForecastAccuracyHighIcon color="primary.500" ref={ref} {...rest} />;
    }
    if (value >= 0.4) {
      return <icons.ForecastAccuracyMiddleIcon color="yellow.500" ref={ref} {...rest} />;
    }
    return <icons.ForecastAccuracyLowIcon color="red.500" ref={ref} {...rest} />;
  };

export const ForecastAccuracyIcon = React.forwardRef<SVGSVGElement, Props>(
  ForecastAccuracyIconRenderFunction,
);
