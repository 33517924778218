import { AlertDialogBody, AlertDialogFooter, Button, ButtonGroup } from '@chakra-ui/react';
import { useZodForm } from '@kkhs/hakari-ui';
import { DefaultValues, FormProvider } from 'react-hook-form';
import { DialogContent } from './DialogContent';
import {
  CreateExternalCounterpartyFieldValues as FormData,
  createExternalCounterpartySchema as formSchema,
} from './schema';

const defaultValues: DefaultValues<FormData> = {
  name: '',
  zipCode: null,
  address: null,
  buildingName: null,
  phoneNumber: null,
  faxNumber: null,
  pharmacyLicenseNumber: null,
  shouldShowTax: 'true',
  totalRoundingMethod: 'round',
  itemRoundingMethod: 'none',
  taxRoundingMethod: 'none',
  isTotalRoundingMethodDisabled: false,
  defaultPriceRatePercent: 100,
  taxCalculationTarget: 'operation',
  feeAmount: 0,
  feeAmountEnabled: false,
  containerAmount: 0,
  containerAmountEnabled: false,
  slipTitle: '',
};

type Props = {
  onClose: () => void;
  cancelRef: React.MutableRefObject<null>;
};

export function LoadingDialogContent({ onClose, cancelRef }: Props) {
  const useFormReturn = useZodForm({
    schema: formSchema,
    defaultValues,
  });
  return (
    <>
      <AlertDialogBody p="0">
        <FormProvider {...useFormReturn}>
          <DialogContent loading />
        </FormProvider>
      </AlertDialogBody>
      <AlertDialogFooter borderTop="1px" borderColor="gray.200" py={6}>
        <ButtonGroup ml="auto">
          <Button ref={cancelRef} variant="ghost" onClick={onClose}>
            キャンセル
          </Button>
          <Button ml={3} isDisabled>
            登録する
          </Button>
        </ButtonGroup>
      </AlertDialogFooter>
    </>
  );
}
