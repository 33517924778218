import { useQuery } from '@apollo/client';
import { Box, Stack, Divider, HStack, IconButton, Center, Spinner } from '@chakra-ui/react';
import { Typography, AdoptionStoppedLabel, PlusIcon } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { UseFieldArrayPrepend } from 'react-hook-form';
import { graphql } from '@/gql';
import { AdoptedMedicineSearchResultsQuery } from '@/gql/docs';
import { filterByAdjustmentSearchResults } from './filterByAdjustmentSearchResults';
import { UpdateStoringStockOperationFieldValues } from '../../schema';

const queryDoc = graphql(`
  query AdoptedMedicineSearchResults($filter: AdoptedMedicinesFilter!) {
    adoptedMedicines(filter: $filter, first: 50) {
      edges {
        node {
          id
          medicine {
            id
            pharmacyId
            medicineName
            usageGroup {
              id
              unitOutline
              # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
              shortUnitOutline
            }
            stockUnitSymbol
            stockUnitPrice
            adoptionStoppedDate
          }
        }
      }
    }
  }
`);

type Props = {
  medicineSearchKeyword: string;
  currentOperatedMedicines: UpdateStoringStockOperationFieldValues['operatedMedicines'];
  prependOperatedMedicine: UseFieldArrayPrepend<
    UpdateStoringStockOperationFieldValues,
    'operatedMedicines'
  >;
  onClose: () => void;
};

export function AdoptedMedicineSearchResults({
  medicineSearchKeyword,
  currentOperatedMedicines,
  prependOperatedMedicine,
  onClose,
}: Props) {
  const { data, loading } = useQuery(queryDoc, {
    variables: { filter: { medicineSearchKeyword } },
  });
  const onAddMedicine = useCallback(
    (node: AdoptedMedicineSearchResultsQuery['adoptedMedicines']['edges'][number]['node']) => {
      prependOperatedMedicine({
        id: null,
        medicineId: node.medicine.id,
        medicineName: node.medicine.medicineName,
        unitOutline: node.medicine.usageGroup.unitOutline,
        // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
        shortUnitOutline: node.medicine.usageGroup.shortUnitOutline,
        stockUnitSymbol: node.medicine.stockUnitSymbol,
        stockUnitPrice: node.medicine.stockUnitPrice,
        costPrice: null,
        lots: [
          {
            defaultLotNumber: undefined,
            lotNumber: null,
            expirationDate: null,
            stockUnitQuantity: 1,
          },
        ],
      });
      onClose();
    },
    [onClose, prependOperatedMedicine],
  );

  if (loading) {
    return (
      <Center>
        <Spinner color="primary.500" />
      </Center>
    );
  }

  const filterdResults = filterByAdjustmentSearchResults({ data, currentOperatedMedicines });

  if (!filterdResults?.length) {
    return (
      <Typography variant="body" color="slate.300" textAlign="center" py={4}>
        該当する医薬品はありません
      </Typography>
    );
  }
  return (
    <Stack divider={<Divider borderColor="gray.200" />}>
      {filterdResults.map(({ node }) => (
        <HStack key={node.id}>
          <Stack flex={1} spacing={1}>
            <Box>
              <Typography variant="body">{node.medicine.medicineName}</Typography>
              <Typography variant="body">{node.medicine.usageGroup.shortUnitOutline}</Typography>
            </Box>
            {node.medicine.adoptionStoppedDate && <AdoptionStoppedLabel />}
          </Stack>
          <IconButton
            size="xs"
            aria-label="医薬品を追加"
            ml={2}
            onClick={() => onAddMedicine(node)}
            icon={<PlusIcon boxSize={3} />}
          />
        </HStack>
      ))}
    </Stack>
  );
}
