import { createIcon } from '@chakra-ui/react';

export const ForecastAccuracyLowIcon = createIcon({
  displayName: 'ForecastAccuracyLowIcon',
  viewBox: '0 0 30 30',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2356 14.9329L7.99979 20.1686L9.69684 21.8657L14.9326 16.6299L20.1684 21.8657L21.8655 20.1686L16.6297 14.9329L21.8655 9.69709L20.1684 8.00003L14.9326 13.2358L9.69684 8.00003L7.99979 9.69709L13.2356 14.9329Z"
      fill="currentColor"
    />
  ),
});
