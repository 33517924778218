import { TextInput, DatePicker } from '@kkhs/hakari-ui';
import { subYears, addYears, endOfMonth } from 'date-fns';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
};

export function ExpirationDatePicker<T extends FieldValues>({ name }: Props<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          selected={value}
          placeholderText="-"
          dateFormat="yyyy/MM/dd"
          customInput={<TextInput size="sm" />}
          // TODO: 範囲について AC 確認する & テスト追加する
          minDate={subYears(new Date(), 20)}
          maxDate={addYears(new Date(), 20)}
          onChange={(v) => {
            if (!v) return;
            onChange(endOfMonth(v));
          }}
          onYearChange={(v) => onChange(endOfMonth(v))}
          showMonthYearPicker
        />
      )}
    />
  );
}
