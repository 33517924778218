import { BaseMutationOptions } from '@apollo/client';
import { AlertDialog, AlertDialogOverlay } from '@chakra-ui/react';
import { useRef } from 'react';
import { Actions } from '@/repository/userMonitoring/interface';
import { AdoptedMedicalMaterialAddDialogContent } from './AdoptedMedicalMaterialAddDialogContent';
import { AdoptedMedicalMaterialAddTarget } from './types';

export type { AdoptedMedicalMaterialAddTarget };

type Props = {
  isOpen: boolean;
  onClose: () => void;
  /** 採用薬登録成功後の callback */
  onSuccess?: () => void;
  /** 採用薬登録失敗後の callback */
  onFailure?: () => void;
  target: AdoptedMedicalMaterialAddTarget | undefined;
  refetchQueries?: BaseMutationOptions['refetchQueries'];
  dialogDescription?: React.ReactChild;
  userMonitoringKey?:
    | Actions['医薬品詳細モーダルの他店舗の在庫タブから医薬品を登録']['key']
    | Actions['在庫一覧画面の他店舗の在庫から医薬品を登録']['key']
    | Actions['在庫を計上する画面から医薬品を登録']['key']
    | Actions['必須買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['安心買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['特定の医薬品発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['アプリ発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['採用薬登録画面から医薬品を登録']['key'];
};

export function AdoptedMedicalMaterialAddDialog({
  isOpen,
  onClose,
  onSuccess,
  onFailure,
  target,
  refetchQueries,
  dialogDescription,
  userMonitoringKey,
}: Props) {
  const cancelRef = useRef(null);
  return (
    <AlertDialog
      size="lg"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay data-testid="add-adopted-medicine-form-dialog">
        {target && (
          <AdoptedMedicalMaterialAddDialogContent
            onClose={onClose}
            onSuccess={onSuccess}
            onFailure={onFailure}
            target={target}
            refetchQueries={refetchQueries}
            cancelRef={cancelRef}
            dialogDescription={dialogDescription}
            userMonitoringKey={userMonitoringKey}
          />
        )}
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
