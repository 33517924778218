import { relayStylePagination } from '@apollo/client/utilities';
// fixme: satisfies使えるようになったらinMemoryCacheConfigに対して使う
// import type { InMemoryCacheConfig as BaseConfig } from '@apollo/client';

export type InMemoryCacheConfig = typeof inMemoryCacheConfig;

/**
 * 本部管理機能で使用する Apollo Client の InMemoryCache の設定
 *
 * フィールドの追加削除があった場合は、web-app/src/lib/apolloClient.tsx の方も修正してください。
 */
export const inMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        orgManagementProposedWholesaleSettingListItems: relayStylePagination(['filter']),
      },
    },
  },
}; // satisfies BaseConfig;
