import { z } from 'zod';

/** 削除スキーマ */
export const schema = z.object({
  /** stock_operations.id */
  id: z.string(),
  isAlertChecked: z.boolean().refine((data) => data),
});

export type FieldValues = z.infer<typeof schema>;
