import { useDemandTakeoverMedicinesQuery } from '@/gql/apollo';

type Props = {
  medicineId?: string;
  onLoaded: (demandTakeoverMedicine?: {
    id: string;
    medicineName: string;
    usageGroup: {
      id: string;
      unitOutline: string;
      // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
      shortUnitOutline: string;
    };
  }) => void;
};

export function useDemandTakeoverMedicine({ medicineId, onLoaded }: Props) {
  const { loading: isLoading } = useDemandTakeoverMedicinesQuery({
    skip: !medicineId,
    variables: {
      // NOTE: medicineId が undefined の場合は、skip: true になるので
      medicineId: medicineId as string,
    },
    onCompleted: (d) => {
      onLoaded(d.demandTakeoverMedicines[0]);
    },
  });

  return {
    isLoading,
  };
}
