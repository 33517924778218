import { createIcon } from '@chakra-ui/react';

export const SubdividedAttentionIcon = createIcon({
  displayName: 'SubdividedAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M7.06006 12.0308C6.74006 13.4808 6.16007 15.2708 5.26007 16.3808C5.08007 16.2508 4.76006 16.0708 4.56006 15.9908C5.45006 14.9408 6.00005 13.2208 6.24005 11.8808L7.06006 12.0308ZM9.64008 9.56078V17.5108C9.64008 18.0608 9.49008 18.2808 9.14008 18.4108C8.78008 18.5308 8.16007 18.5508 7.23007 18.5408C7.18007 18.3308 7.04007 17.9808 6.92007 17.7708C7.65007 17.8008 8.37006 17.7908 8.56006 17.7708C8.77006 17.7708 8.85007 17.7008 8.85007 17.5008V9.55078H9.64008V9.56078ZM11.9401 11.8108C12.8201 13.2108 13.6601 15.0308 13.9101 16.2208L13.1301 16.5508C12.8901 15.3708 12.0901 13.5108 11.2401 12.0808L11.9401 11.8108Z"
        fill="#5D6771"
      />
      <path
        d="M18.6401 9.84156C17.9201 11.5216 16.7601 13.0116 15.5001 13.9516C15.3701 13.7916 15.0501 13.5116 14.8701 13.3816C16.1301 12.5316 17.2401 11.1516 17.8601 9.62156L18.6401 9.84156ZM22.7001 13.1716C22.7001 13.1716 22.6901 13.4316 22.6801 13.5316C22.5401 16.6016 22.3901 17.7516 22.0501 18.1316C21.8401 18.3816 21.6301 18.4416 21.2801 18.4816C20.9501 18.5116 20.3301 18.4816 19.7101 18.4616C19.6901 18.2416 19.6001 17.9416 19.4601 17.7216C20.1101 17.7816 20.7301 17.7916 20.9601 17.7916C21.1701 17.7916 21.2901 17.7716 21.3801 17.6616C21.6401 17.4116 21.7801 16.4116 21.9001 13.8916H19.3201C19.0601 15.8616 18.4201 17.6716 15.8801 18.5916C15.7801 18.4016 15.5701 18.1016 15.4001 17.9416C17.7501 17.1616 18.3001 15.5816 18.5301 13.8916H16.4901V13.1716H22.7001ZM21.3101 9.60156C21.9601 10.9416 23.2401 12.4416 24.3201 13.2016C24.1301 13.3616 23.8501 13.6516 23.7101 13.8616C22.6101 12.9716 21.3501 11.3616 20.6101 9.88156L21.3101 9.60156Z"
        fill="#5D6771"
      />
      <path d="M21 0H24C26.2091 0 28 1.79086 28 4V7L24.5 3.5L21 0Z" fill="#25938B" />
    </g>
  ),
});
