import { createIcon } from '@chakra-ui/react';

export const ExportIcon = createIcon({
  displayName: 'ExportIcon',
  viewBox: '0 0 48 48',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 18L26 12.0002V24H22L22 11.9999L16 18L13 15L23.9998 3.99976L35 15L32 18ZM40 36V33V28L36 24H31L36 29V36H12L12 29L17 24H12L8 28V33V36V40H12H36H40V36ZM32 28H16V32H32V28Z"
      fill="currentColor"
    />
  ),
});
