import { useMutation } from '@apollo/client';
import { showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { graphql } from '@/gql';

const stopMutationDoc = graphql(`
  mutation StopAdoptedMedicalProduct($medicineId: ID!) {
    stopAdoptedMedicalProducts: stopAdoptedMedicines(input: { medicineIds: [$medicineId] }) {
      userErrors {
        ... on ValidationError {
          message
          path
        }
      }
      medicines {
        id
        pharmacyId
        medicineName
        adoptionStoppedDate
      }
    }
  }
`);

const restartMutationDoc = graphql(`
  mutation RestartAdoptedMedicalProduct($medicineId: ID!) {
    restartAdoptedMedicalProducts: restartAdoptedMedicines(input: { medicineIds: [$medicineId] }) {
      userErrors {
        ... on ValidationError {
          message
          path
        }
      }
      medicines {
        id
        pharmacyId
        medicineName
        adoptionStoppedDate
      }
    }
  }
`);

type Props = {
  /** 対象の medicineId */
  medicineId: string;
  /** mutation 成功後の callback */
  onAfterMutation: () => Promise<void>;
  /** Datadogに送るイベント実行関数 */
  sendEvent?: (isStop: boolean) => void;
};

export function useStopOrRestartAdoptedMedicalProduct({
  medicineId,
  onAfterMutation,
  sendEvent,
}: Props) {
  const [stopAdoptedMedicalProduct, { loading: isStopSubmitting }] = useMutation(stopMutationDoc, {
    variables: { medicineId },
    onError: () => {
      showErrorToast({
        id: 'stop-adopted-medicine-error',
        title: 'エラーが発生しました。',
      });
    },
    onCompleted: ({ stopAdoptedMedicalProducts: { userErrors, medicines } }) => {
      const userError = userErrors[0];
      if (userError && userError.__typename === 'ValidationError') {
        showErrorToast({
          id: 'stop-adopted-medicine-validation-error',
          title: 'エラーが発生しました。',
        });
        return;
      }
      if (sendEvent) {
        sendEvent(true);
      }
      showSuccessToast({
        id: 'stop-adopted-medical-product',
        title: '下記の医薬品を採用停止にしました',
        description: medicines?.[0]?.medicineName,
        duration: 6000,
      });
    },
  });
  const [restartAdoptedMedicalProduct, { loading: isRestartSubmitting }] = useMutation(
    restartMutationDoc,
    {
      variables: { medicineId },
      onError: () => {
        showErrorToast({
          id: 'restart-adopted-medical-product-error',
          title: 'エラーが発生しました。',
        });
      },
      onCompleted: ({ restartAdoptedMedicalProducts: { userErrors, medicines } }) => {
        const userError = userErrors[0];
        if (userError && userError.__typename === 'ValidationError') {
          showErrorToast({
            id: 'restart-adopted-medical-product-validation-error',
            title: 'エラーが発生しました。',
          });
          return;
        }
        if (sendEvent) {
          sendEvent(false);
        }
        showSuccessToast({
          id: 'restart-adopted-medical-product',
          title: '下記の採用薬の停止を解除しました',
          description: medicines?.[0]?.medicineName,
          duration: 6000,
        });
      },
    },
  );

  const stopOrRestartAdoptedMedicalProduct = useCallback(
    async (adoptionStopped: 'true' | 'false') => {
      if (adoptionStopped === 'true') {
        await stopAdoptedMedicalProduct();
      } else if (adoptionStopped === 'false') {
        await restartAdoptedMedicalProduct();
      }
      await onAfterMutation();
    },
    [onAfterMutation, restartAdoptedMedicalProduct, stopAdoptedMedicalProduct],
  );

  return {
    stopOrRestartAdoptedMedicalProduct,
    isSubmitting: isStopSubmitting || isRestartSubmitting,
  };
}
