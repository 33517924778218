import { Box, Checkbox, CheckboxGroup, HStack, Input, Text } from '@chakra-ui/react';
import { Typography, DatePicker, InfoIcon, InfoPopover } from '@kkhs/hakari-ui';
import { jstNow } from '@kkhs/hakari-utils';
import { max, addDays, subDays, addYears } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { FieldValues } from '../schema';

export function SearchSection() {
  const currentPharmacy = currentPharmacySelectors.useValue();
  const {
    register,
    control,
    watch,
    formState: { isSubmitting },
  } = useFormContext<FieldValues>();
  const [toDate, fromDate] = watch(['toDate', 'fromDate']);

  const MAX_DATE = addYears(jstNow(), 20);

  const renderContent = () => (
    <Typography variant="body2" color="slate.500" lineHeight="14px">
      編集/削除ができる入出庫履歴は、在庫調整を行なった下記の区分のみです。
      <br />
      <br />
      <b>編集と削除ができる区分</b>
      <br />
      ・小分け購入
      <br />
      ・法人内入庫
      <br />
      ・法人間入庫
      <br />
      ・その他入庫
      <br />
      <br />
      <b>削除ができる区分</b>
      <br />
      ・廃棄
      <br />
      ・法人内出庫
      <br />
      ・法人間出庫
      <br />
      ・その他出庫
    </Typography>
  );

  return (
    <HStack justifyContent="space-between" bgColor="gray.100" py={4} px={6}>
      <HStack spacing={6}>
        <CheckboxGroup>
          <HStack spacing={4}>
            <Checkbox size="md" isDisabled={isSubmitting} {...register('replenishmenthecked')}>
              <Text fontSize="sm">入庫</Text>
            </Checkbox>
            <Checkbox size="md" isDisabled={isSubmitting} {...register('consumptionChecked')}>
              <Text fontSize="sm">出庫</Text>
            </Checkbox>
          </HStack>
        </CheckboxGroup>
        <HStack>
          <Text fontSize="sm" color="slate.300">
            期間
          </Text>
          <HStack divider={<Box w="8px" h="2px" bg="gray.200" flex="none" />}>
            <Controller
              name="fromDate"
              control={control}
              render={({ field: { onChange, value } }) => {
                const minDate = currentPharmacy?.operationStartDate
                  ? max(
                      toDate
                        ? [new Date(currentPharmacy.operationStartDate), subDays(toDate, 30)]
                        : [new Date(currentPharmacy.operationStartDate)],
                    )
                  : undefined;
                const maxDate = toDate ?? MAX_DATE;

                const handleDateChange = (date: Date | null) => onChange(date ?? undefined);
                return (
                  <DatePicker
                    dateFormat="yyyy/MM/dd(E)"
                    selected={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleDateChange}
                    customInput={
                      <Input size="sm" w="120px" isDisabled={isSubmitting} bgColor="white" />
                    }
                    selectsStart
                    disabled={isSubmitting}
                    isClearable
                  />
                );
              }}
            />
            <Controller
              name="toDate"
              control={control}
              render={({ field: { onChange, value } }) => {
                const minDate = currentPharmacy?.operationStartDate
                  ? max(
                      fromDate
                        ? [fromDate, new Date(currentPharmacy?.operationStartDate)]
                        : [new Date(currentPharmacy?.operationStartDate)],
                    )
                  : undefined;
                const maxDate = fromDate ? addDays(fromDate, 30) : MAX_DATE;

                const handleDateChange = (date: Date | null) => onChange(date ?? undefined);
                return (
                  <DatePicker
                    dateFormat="yyyy/MM/dd(E)"
                    customInput={
                      <Input size="sm" w="120px" isDisabled={isSubmitting} bgColor="white" />
                    }
                    selected={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleDateChange}
                    selectsStart
                    disabled={isSubmitting}
                    isClearable
                  />
                );
              }}
            />
          </HStack>
        </HStack>
      </HStack>
      <HStack spacing={1}>
        <InfoPopover w="270px" content={renderContent()}>
          <InfoIcon color="slate.500" boxSize={3} />
        </InfoPopover>
        <Typography variant="body2" color="slate.500" lineHeight="14px">
          編集/削除が可能な入出庫履歴について
        </Typography>
      </HStack>
    </HStack>
  );
}
