import { GenericType } from '@/gql/docs';

export const getGenericTypeLabel = (
  genericType: GenericType,
  { isShort }: { isShort: boolean } = { isShort: false },
) => {
  switch (genericType) {
    case 'brandNameWithoutGeneric':
      return isShort ? '先(後なし)' : '先発医薬品(後発品なし)';
    case 'brandNameWithGeneric':
      return isShort ? '先(後あり)' : '先発医薬品(後発品あり)';
    case 'generic':
      return isShort ? '後' : '後発医薬品';
    default:
      return 'その他';
  }
};
