import { isNullOrUndefined } from '@kkhs/hakari-utils';
import Big from 'big.js';
import { ExternalStockOperationPriceConfig, StockSalePriceConfig } from '@/gql/docs';
import { calculatePriceV2 } from '@/shared/utils';
import { TAX_RATE } from '../../constants';
import { calculateSubTotalAmount } from '../calculateSubTotalAmount';

/**
 * 明細の税額を返す
 */
export function calculateSubTotalTaxAmount({
  unitPrice,
  stockUnitQuantity,
  stockOperationPriceConfig,
}: {
  unitPrice: number | null;
  stockUnitQuantity: number | null;
  stockOperationPriceConfig: ExternalStockOperationPriceConfig | StockSalePriceConfig;
}) {
  const { taxCalculationTarget, taxRoundingMethod } = stockOperationPriceConfig;

  if (
    isNullOrUndefined(unitPrice) ||
    isNullOrUndefined(stockUnitQuantity) ||
    Number.isNaN(stockUnitQuantity) ||
    Number.isNaN(unitPrice)
  )
    return undefined;
  if (taxCalculationTarget !== 'item') return null;

  const subTotalAmount = calculateSubTotalAmount({
    unitPrice,
    stockUnitQuantity,
    stockOperationPriceConfig,
  });

  if (subTotalAmount === null || subTotalAmount === undefined) return null;

  // NOTE: 端数処理。taxRoundingMethod を利用する
  return calculatePriceV2(new Big(subTotalAmount).times(TAX_RATE), taxRoundingMethod).toNumber();
}
