import { createIcon } from '@chakra-ui/react';

export const TransitionalMeasureAttentionIcon = createIcon({
  displayName: 'TransitionalMeasureAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M5.78999 9.22C5.64999 10.16 5.41 11.12 5.08 11.76C4.96 11.67 4.66 11.53 4.5 11.48C4.83 10.87 5.03999 9.98 5.14999 9.1L5.78999 9.22ZM6.88 8.42V12.58H6.22V8.46C5.65 8.49 5.10999 8.52 4.64999 8.55L4.59 7.89L5.25 7.86C5.46 7.59 5.69 7.27 5.91 6.94C5.6 6.5 5.08 5.94 4.62 5.53L5 5.04C5.1 5.12 5.18999 5.21 5.28999 5.3C5.62999 4.74 5.98 3.99 6.19 3.44L6.84 3.7C6.5 4.36 6.08001 5.15 5.71001 5.71C5.93001 5.93 6.12999 6.16 6.28999 6.36C6.67999 5.74 7.04 5.1 7.3 4.57L7.92999 4.85C7.35999 5.8 6.6 6.97 5.94 7.83L7.42 7.77C7.28 7.46 7.12 7.14 6.95 6.87L7.47 6.66C7.88 7.3 8.28 8.16 8.41 8.7L7.85001 8.95C7.81001 8.78 7.75 8.58 7.67 8.37L6.88 8.42ZM7.75 9.03C8.02 9.6 8.29 10.36 8.39 10.85L7.82001 11.04C7.72001 10.54 7.47001 9.79 7.21001 9.21L7.75 9.03ZM11.39 11.6H13.8V12.27H8.12V11.6H10.66V9.97H8.75999V9.3H10.66V7.86H11.39V9.3H13.36V9.97H11.39V11.6ZM13.36 4.14C12.97 5.23 12.35 6.09 11.57 6.77C12.24 7.22 13.03 7.55 13.91 7.74C13.76 7.9 13.56 8.17 13.45 8.36C12.53 8.11 11.7 7.73 11 7.21C10.28 7.72 9.45 8.1 8.59 8.38C8.49 8.19 8.31 7.92 8.16 7.77C8.97 7.54 9.74999 7.2 10.43 6.74C9.83999 6.18 9.35999 5.51 9.00999 4.74L9.41 4.61H8.38V3.95H12.72L12.86 3.92L13.36 4.14ZM9.7 4.61C10 5.26 10.44 5.84 10.99 6.32C11.55 5.84 12.02 5.27 12.35 4.61H9.7Z"
        fill="#5D6771"
      />
      <path
        d="M16.88 10.56C17.35 11.3 18.23 11.64 19.4 11.68C20.5 11.73 22.8 11.7 24 11.62C23.91 11.79 23.79 12.13 23.76 12.35C22.64 12.4 20.53 12.42 19.41 12.37C18.1 12.32 17.2 11.97 16.58 11.19C16.14 11.62 15.68 12.04 15.19 12.47L14.8 11.76C15.22 11.45 15.73 11.03 16.18 10.61V8.04999H14.91V7.34999H16.88V10.56ZM16.58 5.76999C16.28 5.26999 15.61 4.57999 15 4.09999L15.57 3.67999C16.18 4.13999 16.87 4.81999 17.18 5.30999L16.58 5.76999ZM18.5 11.16H17.84V6.85999H18.62V3.78999H22.71V6.85999H23.48V10.44C23.48 10.78 23.42 10.96 23.17 11.06C22.93 11.17 22.55 11.17 21.95 11.17C21.92 11 21.83 10.74 21.75 10.57C22.17 10.57 22.54 10.57 22.64 10.57C22.76 10.57 22.8 10.52 22.8 10.43V7.43999H18.5V11.16ZM19.27 6.84999H20.25V5.16999H22.04V4.35999H19.27V6.84999ZM21.93 10.19H19.92V10.58H19.34V8.07999H21.93V10.19ZM21.35 8.60999H19.92V9.66999H21.35V8.60999ZM22.04 6.84999V5.68999H20.81V6.84999H22.04Z"
        fill="#5D6771"
      />
      <path
        d="M7.79001 20.51L6.68001 20.85V23.62C6.68001 24.04 6.58002 24.23 6.32002 24.34C6.07002 24.46 5.66002 24.48 4.97002 24.48C4.95002 24.29 4.84002 24 4.75002 23.8C5.22002 23.82 5.66001 23.8 5.78001 23.8C5.92001 23.8 5.97002 23.75 5.97002 23.61V21.05L4.79001 21.4L4.58002 20.67C4.97002 20.58 5.45002 20.46 5.97002 20.31V18.13H4.70001V17.43H5.97002V15.43H6.68001V17.43H7.68001V18.13H6.68001V20.11L7.70001 19.83L7.79001 20.5V20.51ZM13.76 18.71V19.39H7.88002V18.71H9.34001V17.4H8.15001V16.74H9.34001V15.44H10.05V16.74H11.63V15.44H12.35V16.74H13.58V17.4H12.35V18.71H13.76ZM8.71002 20.18H13.14V24.54H12.41V24.12H9.43001V24.56H8.71002V20.18ZM9.43001 20.82V21.84H12.41V20.82H9.43001ZM12.41 23.5V22.45H9.43001V23.5H12.41ZM10.05 18.71H11.63V17.4H10.05V18.71Z"
        fill="#5D6771"
      />
      <path
        d="M18.92 19.54C18.95 19.36 18.99 19.16 19.02 18.96H15.13V18.37H19.11C19.13 18.19 19.15 18 19.17 17.83H15.59V15.85H23.3V17.83H19.92C19.89 18 19.87 18.19 19.84 18.37H23.71V18.96H19.74C19.71 19.17 19.67 19.36 19.64 19.54H22.88V22.99H17.43V19.54H18.92ZM23.96 24.15H16.42V24.57H15.68V19.69H16.42V23.55H23.96V24.15ZM17.88 16.4H16.29V17.29H17.88V16.4ZM22.17 20.01H18.12V20.55H22.17V20.01ZM22.17 20.99H18.12V21.54H22.17V20.99ZM22.17 21.99H18.12V22.55H22.17V21.99ZM18.57 17.29H20.2V16.4H18.57V17.29ZM20.89 17.29H22.57V16.4H20.89V17.29Z"
        fill="#5D6771"
      />
    </g>
  ),
});
