import { Amplify } from '@aws-amplify/core';
import { captureMessage } from '@sentry/nextjs';
import { localStorageKeys } from '@/shared/constants';

type UserPoolConfigFields = {
  userPoolId: string;
  userPoolWebClientId: string;
};

const userPoolConfig: {
  v1: UserPoolConfigFields;
  v2: UserPoolConfigFields;
} = {
  v1: {
    userPoolId: process.env.V1_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.V1_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  v2: {
    userPoolId: process.env.V2_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.V2_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
};

type CpSsoOauthConfig = {
  domain: string;
  scope: ['openid', 'aws.cognito.signin.user.admin'];
  redirectSignIn: string | null;
  redirectSignOut: string | null;
  responseType: 'code';
};

export const cpSsoOauthConfigBase: Omit<CpSsoOauthConfig, 'domain'> = {
  scope: ['openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: process.env.SSO_REDIRECT_SIGN_IN_URL,
  redirectSignOut: process.env.SSO_REDIRECT_SIGN_OUT_URL,
  responseType: 'code',
};

export type UserPoolType = keyof typeof userPoolConfig;

// NOTE: ローカルストレージに保持する値
const LOCAL_STORAGE_V2_USER_POOL_TYPE_VALUE = 'v2';
const LOCAL_STORAGE_V1_USER_POOL_TYPE_VALUE = 'v1';

export function setUserPoolTypeToV2(): void {
  localStorage.setItem(localStorageKeys.USER_POOL_TYPE, LOCAL_STORAGE_V2_USER_POOL_TYPE_VALUE);
}
export function setUserPoolTypeToV1(): void {
  localStorage.setItem(localStorageKeys.USER_POOL_TYPE, LOCAL_STORAGE_V1_USER_POOL_TYPE_VALUE);
}

function configureAmplify(type: UserPoolType, oauthConfig?: CpSsoOauthConfig): void {
  const config = type === 'v2' ? userPoolConfig.v2 : userPoolConfig.v1;
  Amplify.configure({
    Auth: {
      region: 'ap-northeast-1',
      ...config,
      oauth: oauthConfig,
    },
  });
}

export function reconfigureAmplify(type: UserPoolType, oauthConfig?: CpSsoOauthConfig): void {
  configureAmplify(type, oauthConfig);
}

export function initAmplify(): void {
  if (typeof window !== 'undefined') {
    const localStorageItem = localStorage.getItem(localStorageKeys.USER_POOL_TYPE);
    // NOTE: ローカルストレージに v2 と保存されていた場合は v2 で初期化する
    const userPoolType = localStorageItem === LOCAL_STORAGE_V2_USER_POOL_TYPE_VALUE ? 'v2' : 'v1';
    configureAmplify(userPoolType);
  }
}

export function initAmplifyOnCpSSO(): void {
  if (typeof window !== 'undefined') {
    try {
      const cpSsoUserPool = JSON.parse(
        localStorage.getItem(localStorageKeys.CP_SSO_USER_POOL) ?? '{}',
      );
      // TODO: 想定外の値が入っていないかチェックすべき
      const userPoolType = cpSsoUserPool.version;
      const userPoolDomain = cpSsoUserPool.domain;
      configureAmplify(userPoolType, {
        ...cpSsoOauthConfigBase,
        domain: userPoolDomain,
      });
    } catch (e) {
      // NOTE: ここに入ることは想定外
      captureMessage(`Unexpected - ${e}`);
    }
  }
}
