import { atom, selector, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Role } from '@/gql/apollo';
import { recoilAtomKeys, recoilSelectorKeys } from '@/shared/constants';
import { localStorageEffect } from '../../lib/recoil';

export type CurrentRoleState = Role | undefined;

type CurrentRoleSelectors = {
  useValue: () => CurrentRoleState;
  useIsManager: () => boolean;
};

type CurrentRoleActions = {
  useSetState: () => (role: Role) => void;
  useReset: () => () => void;
};

export const currentRoleStateAtom = atom<CurrentRoleState>({
  key: recoilAtomKeys.CURRENT_ROLE_STATE,
  default: undefined,
  effects_UNSTABLE: [localStorageEffect<CurrentRoleState>(recoilAtomKeys.CURRENT_ROLE_STATE)],
});

const currentRoleSelector = selector<CurrentRoleState>({
  key: recoilSelectorKeys.CURRENT_ROLE,
  get: ({ get }) => get(currentRoleStateAtom),
});

export const currentRoleSelectors: CurrentRoleSelectors = {
  useValue: () => useRecoilValue(currentRoleSelector),
  useIsManager: () => useRecoilValue(currentRoleSelector) === 'manager',
};

export const currentRoleActions: CurrentRoleActions = {
  useSetState: () => useSetRecoilState(currentRoleStateAtom),
  useReset: () => useResetRecoilState(currentRoleStateAtom),
};
