import { useEffect } from 'react';

type Props = {
  shouldAlert: boolean;
};

/**
 * shouldAlert: true の場合のみ、ブラウザのアラートを出す
 */
export function useBeforeUnload({ shouldAlert }: Props) {
  useEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent) => {
      if (shouldAlert) {
        e.returnValue = '';
      }
    };

    // NOTE: safari では beforeunload は対応していない
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [shouldAlert]);
}
