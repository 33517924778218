import { IconButton, useDisclosure } from '@chakra-ui/react';
import { DeleteIcon } from '@kkhs/hakari-ui';
import { ShippingStockOperationDeleteDialog } from '@/features/shippingStockOperationDelete';

type Props = {
  stockOperationId: string;
};

export function ShippingStockOperationDeleteButton({ stockOperationId }: Props) {
  const deleteShippingOperationDialogDisclosure = useDisclosure({
    id: 'delete-shipping-operation-dialog-disclosure',
  });

  return (
    <>
      <IconButton
        size="xs"
        variant="unstyled"
        icon={<DeleteIcon boxSize="18px" color="primary.600" />}
        aria-label="入出庫履歴削除"
        onClick={deleteShippingOperationDialogDisclosure.onOpen}
      />
      <ShippingStockOperationDeleteDialog
        isOpen={deleteShippingOperationDialogDisclosure.isOpen}
        onClose={deleteShippingOperationDialogDisclosure.onClose}
        stockOperationId={stockOperationId}
      />
    </>
  );
}
