import { BaseMutationOptions, useMutation } from '@apollo/client';
import { showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { useCallback } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { checkDuplicatedStorageNames } from '@/entities/storage';
import { graphql } from '@/gql';
import { CreateAdoptedMedicalMaterialInput } from '@/gql/docs';
import { Actions } from '@/repository/userMonitoring/interface';
import { currentRoleSelectors } from '../../../../../../store/currentRole';
import { MedicalMaterialFieldValues } from '../../schema';

const addAdoptedMedicalMaterialMutationDoc = graphql(`
  mutation AddAdoptedMedicalMaterial($input: CreateAdoptedMedicalMaterialInput!) {
    addAdoptedMedicalMaterial: createAdoptedMedicalMaterial(input: $input) {
      userErrors {
        ... on ValidationError {
          message
          path
        }
        ... on AdoptedMedicineAlreadyExistError {
          message
          path
        }
        ... on DuplicateMappingYjCodeError {
          message
          path
        }
        ... on MappingYjCodeConflictWithMedicineYjCodeError {
          message
          path
        }
        ... on ClosedStockOperationError{
          message
          path
        }
      }
    }
  }
`);

type Props = {
  onClose: () => void;
  /** 採用薬登録成功後の callback */
  onSuccess?: () => void;
  /** 採用薬登録失敗後の callback */
  onFailure?: () => void;
  target: { id: string; medicineName: string; defaultMedicinePackageUnitId?: string };
  refetchQueries: BaseMutationOptions['refetchQueries'];
  useFormReturn: UseFormReturn<MedicalMaterialFieldValues>;
  userMonitoringKey?:
    | Actions['医薬品詳細モーダルの他店舗の在庫タブから医薬品を登録']['key']
    | Actions['在庫一覧画面の他店舗の在庫から医薬品を登録']['key']
    | Actions['在庫を計上する画面から医薬品を登録']['key']
    | Actions['必須買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['安心買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['特定の医薬品発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['アプリ発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['採用薬登録画面から医薬品を登録']['key'];
};

export const useAdoptedMedicalMaterialAddButtonProps = ({
  onClose,
  onSuccess,
  onFailure,
  target,
  refetchQueries,
  useFormReturn: { setError, handleSubmit, formState: { isValid } },
  userMonitoringKey,
}: Props) => {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const isManagerRole = currentRoleSelectors.useIsManager();
  const [createAdoptedMedicalMaterial, { loading: isAdopting }] = useMutation(
    addAdoptedMedicalMaterialMutationDoc,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const userError = data.addAdoptedMedicalMaterial.userErrors?.[0];
        if (userError?.__typename === 'ValidationError') {
          showErrorToast({
            id: 'create-adopted-medical-material-validation-error',
            title: 'エラーが発生しました。',
          });
          onFailure?.();
          return;
        }
        if (userError?.__typename === 'AdoptedMedicineAlreadyExistError') {
          showErrorToast({
            id: 'create-adopted-medical-material-adopted-medicine-already-exist-error',
            title: '既に採用薬として登録されている医療材料です。',
          });
          onFailure?.();
          return;
        }
        if (userError?.__typename === 'DuplicateMappingYjCodeError') {
          showErrorToast({
            id: 'create-adopted-medical-material-duplicate-mapping-yj-code-error',
            title: '指定した連携用YJコードは既に他の医療材料で使用されています。',
          });
          onFailure?.();
          return;
        }
        if (userError?.__typename === 'MappingYjCodeConflictWithMedicineYjCodeError') {
          showErrorToast({
            id: 'create-adopted-medical-material-mapping-yj-code-conflict-with-medicine-yj-code-error',
            title: '指定した連携用YJコードは他の医薬品のYJコードとして使用されています。',
          });
          onFailure?.();
          return;
        }
        if (userError?.__typename === 'ClosedStockOperationError') {
          showErrorToast({
            id: 'closed-stock-operation-error',
            title: '棚卸日当日は、初期在庫数量が0以外の登録はできません',
          });
          onFailure?.();
          userMonitoringRepository.sendEvent({
            key: '棚卸日当日に在庫1以上で採用薬を新規登録',
            contexts: { pharmacyId, musubiCode },
          });
          return;
        }
        showSuccessToast({
          id: 'create-adopted-medical-material-success',
          title: '採用薬に追加しました',
        });
        onSuccess?.();
        onClose();
      },
      onError: () => {
        showErrorToast({
          id: 'create-adopted-medical-material-error',
          title: 'エラーが発生しました。',
        });
        onFailure?.();
      },
      refetchQueries,
    },
  );

  const onValid: SubmitHandler<MedicalMaterialFieldValues> = useCallback(
    async ({
      storages,
      primaryWholesaleId,
      medicinePackageUnitId,
      stockUnitQuantity,
      costPrice,
      mappingYjCode,
    }) => {
      // 置き場所を重複して設定していた場合は setError する
      const filteredStorages = storages.filter(
        (s): s is { id: string; name: string } => !!s.id && !!s.name,
      );

      if (filteredStorages !== null) {
        const duplicatedIndexes = checkDuplicatedStorageNames(filteredStorages);
        if (duplicatedIndexes.length > 0) {
          duplicatedIndexes.forEach((i) => {
            setError(`storages.${Number(i)}.id`, {
              type: 'custom',
              message: '置き場所が重複しています。',
            });
          });
          return;
        }
      }

      const createAdoptedMedicalMaterialInput: CreateAdoptedMedicalMaterialInput = {
        medicineId: target.id,
        primaryWholesaleId,
        medicinePackageUnitId,
        storageIds: filteredStorages?.map((s) => s.id),
        stockUnitQuantity,
        mappingYjCode,
      };

      await createAdoptedMedicalMaterial({
        variables: {
          // NOTE:
          //   管理者権限でなくても指定できてしまう。これは好ましくない。
          //   GraphQL Schema で表現すべき。また、backend の実装におけるチェックも入っていないため危険
          //   したがって、フロントエンドでは権限によって input を分岐させる。
          input: isManagerRole
            ? {
                ...createAdoptedMedicalMaterialInput,
                costPrice,
              }
            : createAdoptedMedicalMaterialInput,
        },
      });

      if (userMonitoringKey !== undefined) {
        userMonitoringRepository.sendEvent({
          key: userMonitoringKey,
          contexts: { pharmacyId, musubiCode },
        });
      }
    },
    [
      target.id,
      createAdoptedMedicalMaterial,
      isManagerRole,
      userMonitoringRepository,
      userMonitoringKey,
      pharmacyId,
      musubiCode,
      setError,
    ],
  );
  return {
    onClickAdd: handleSubmit(onValid),
    isSubmitting: isAdopting,
    isDisabled: !isValid,
  };
};
