import { captureMessage } from '@sentry/nextjs';
import { ShippingDetailReportReason, TransactionReason, TransactionType } from '@/gql/docs';
import { DisposalReason, disposalReasonTexts } from '../../models/disposalReason';

type Props = {
  type: TransactionType;
  reason: TransactionReason | ShippingDetailReportReason;
  subreason?: DisposalReason | null;
};

const mapper = (type: '出庫' | '入庫') => ({
  prescription: '処方',
  disposal: '廃棄',
  return: '返品',
  transfer: `法人内${type}`,
  externalTrade: `法人間${type}`,
  tradeWithCompany: `法人間${type}`,
  purchaseFromWholesale: '仕入',
  subdividedPurchase: '小分け購入',
  premade: '予製作成',
  stocktake: '棚卸',
  initial: '採用薬登録',
  kakehashiAdjustment: `調整\n(カケハシ)`,
  other: `その他${type}`,
});

/**
 * 区分と小区分から、小区分のテキストを返す
 * @param reason
 * @param subreason
 * @returns 小区分のラベル。小区分がない場合はnull
 */
export const getSubreasonLabel = (
  reason: TransactionReason | ShippingDetailReportReason,
  subreason: DisposalReason | null,
) => {
  if (subreason === null) {
    return null;
  }
  if (reason === 'disposal') {
    return disposalReasonTexts[subreason];
  }
  return null;
};

export function getTransactionReasonLabel({ type, reason, subreason }: Props): string {
  const transactionTypeLabel = type === 'consumption' ? '出庫' : '入庫';
  const labelObject = mapper(transactionTypeLabel);
  const subreasonText = getSubreasonLabel(reason, subreason ?? null);

  // 不明な reason の場合は Sentry にエラーを送信し、`その他出庫` or `その他入庫` というラベルを返す
  if (!Object.keys(labelObject).includes(reason)) {
    captureMessage('Invalid TransactionReason.');
    return `その他${transactionTypeLabel}`;
  }

  if (!subreasonText) {
    return labelObject[reason];
  }

  // subreasonとlabelObjectのテキストを結合する
  return `${labelObject[reason]} (${subreasonText})`;
}
