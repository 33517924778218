import { HStack } from '@chakra-ui/react';
import { AlertIcon, Typography } from '@kkhs/hakari-ui';

export function CounterpartySettingAlert() {
  return (
    <HStack pb={1}>
      <AlertIcon color="red.500" boxSize={4} />
      <Typography variant="body2">取引先ごとの設定は、最新の取引先設定が適用されます。</Typography>
    </HStack>
  );
}
