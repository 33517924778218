import { z } from 'zod';

export const medicineSearchKeywordSchema = z.optional(
  z
    .string()
    .trim()
    .refine((val) => val.length <= 1000, '1,000文字以内で入力してください。'),
);

export const yjCodeSchema = z.string().trim().min(0).max(12);
