import { createIcon } from '@chakra-ui/react';

export const StickUnitSymbolIcon = createIcon({
  displayName: 'Stick',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.46 10.41C11.4906 9.27345 9.906 7.57398 8.90995 5.52995H13.38V4.73995H8.37995V2.44995H7.57995V4.73995H2.63995V5.52995H7.07995C6.10462 7.59257 4.52 9.30634 2.53995 10.44L2.44995 10.5L3.01995 11.27L3.09995 11.21C5.01028 9.97008 6.55647 8.24443 7.57995 6.20995V10.21H4.99995V11H7.57995V13.2H8.40995V11H11V10.23H8.40995V6.22995C9.44749 8.25455 11.0126 9.96132 12.94 11.17H13.02L13.55 10.44L13.46 10.41Z"
        fill="currentColor"
      />
    </svg>
  ),
});
