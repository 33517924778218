import { Button, Flex, Stack } from '@chakra-ui/react';
import { useZodForm, AlertDialogContent } from '@kkhs/hakari-ui';
import { endOfMonth } from 'date-fns';
import { FormProvider, useFieldArray, useWatch } from 'react-hook-form';
import {
  DemandTakeoverToMedicineForm,
  StopOrRestartForm,
  OrderRecommendSuspendTermField,
  OrderPointSettingField,
} from '@/entities/adoptedMedicalProduct';
import { MedicinePackageUnitSelectControl } from '@/entities/medicinePackageUnit';
import { OrderableWholesaleSelectV2Form } from '@/entities/orderableWholesale';
import { StorageSelects } from '@/entities/storage';
import { FragmentType, getFragment, graphql } from '@/gql';
import { AdoptedMedicineUpdateTabContent_AdoptedMedicineFragment } from '@/gql/docs';
import { AWSDate } from '@/shared/utils';
import { useAdoptedMedicineUpdateButtonProps } from './hooks';
import { FieldValues, schema } from './schema';

const fragmentDoc = graphql(`
  fragment AdoptedMedicineUpdateTabContent_AdoptedMedicine on AdoptedMedicine {
    medicine {
      id
      pharmacyId
      medicineName
      usageGroup {
        id
        unitOutline
        # HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
        shortUnitOutline
      }
      defaultPrimaryWholesale: primaryWholesale {
        id
        ...OrderableWholesaleSelectV2Form_Wholesale
      }
      adoptionStoppedDate
      demandTakeoverMedicineId
      stockUnitSymbol
    }
    medicinePackageUnit {
      id
    }
    # おすすめ発注の非表示期間
    orderRecommendSuspendTerm {
      ... on OrderRecommendSuspendScopeTerm {
        __typename
        startDate
        endDate
      }
      ... on OrderRecommendSuspendInfTerm {
        __typename
        startDate
      }
    }
    # 置き場所
    storages {
      id
      name
    }
    shouldHideOrderRecommendUntilNextTransaction
    # 発注点設定
    orderPointSetting {
      mode
      fixedValue
      factor
    }
  }
`);

function toOrderRecommendSuspendTermFormValue(
  fragment: AdoptedMedicineUpdateTabContent_AdoptedMedicineFragment,
) {
  if (!fragment.orderRecommendSuspendTerm) return undefined;
  if (fragment.orderRecommendSuspendTerm.__typename === 'OrderRecommendSuspendInfTerm') {
    return {
      isInf: true,
      startDate: new Date(fragment.orderRecommendSuspendTerm.startDate),
    };
  }
  if (fragment.orderRecommendSuspendTerm.__typename === 'OrderRecommendSuspendScopeTerm') {
    return {
      isInf: false,
      startDate: new Date(fragment.orderRecommendSuspendTerm.startDate),
      endDate: new Date(fragment.orderRecommendSuspendTerm.endDate),
    };
  }
  return undefined;
}

type Props = {
  fragment: FragmentType<typeof fragmentDoc>;
  /** 直近の発注予約日 */
  latestOrderPlanDate?: AWSDate;
  /** mutation 成功後の callback */
  onAfterMutation: () => Promise<void>;
};

// TODO: fragmentをこのコンポーネントで定義する
// See: https://github.com/kkhs/hakari-frontend/pull/11250#discussion_r1236596471
export function AdoptedMedicineUpdateTabContent({
  fragment: _fragment,
  latestOrderPlanDate,
  onAfterMutation,
}: Props) {
  const fragment = getFragment(fragmentDoc, _fragment);
  const defaultValues: FieldValues = {
    adoptionStopped: fragment.medicine.adoptionStoppedDate ? 'true' : 'false',
    wholesaleId: fragment.medicine.defaultPrimaryWholesale?.id,
    medicinePackageUnitId: fragment.medicinePackageUnit.id,
    storages: fragment.storages.map((s) => ({ id: s.id, name: s.name })),
    orderRecommendSuspendTerm: toOrderRecommendSuspendTermFormValue(fragment),
    orderPointSetting: {
      mode: fragment.orderPointSetting.mode,
      fixedValue: fragment.orderPointSetting.fixedValue,
      factor: fragment.orderPointSetting.factor,
    },
  };
  const useFormReturn = useZodForm({
    schema,
    defaultValues,
  });
  const { control, formState, setValue, trigger } = useFormReturn;
  const [adoptionStopped, medicinePackageUnitId, orderRecommendSuspendTerm, orderPointSetting] =
    useWatch({
      control,
      name: [
        'adoptionStopped',
        'medicinePackageUnitId',
        'orderRecommendSuspendTerm',
        'orderPointSetting',
      ],
    });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'storages',
    keyName: 'key',
  });

  const { onClickUpdate, isSubmitting, isDisabled } = useAdoptedMedicineUpdateButtonProps({
    medicineId: fragment.medicine.id,
    onAfterMutation,
    useFormReturn,
  });

  return (
    <AlertDialogContent shouldAlert={formState.isDirty} isNotAlertDialogRole>
      <FormProvider {...useFormReturn}>
        <Stack spacing={6} pb={6}>
          <StopOrRestartForm
            control={control}
            formState={formState}
            name="adoptionStopped"
            currentAdoptionStopped={!!fragment.medicine.adoptionStoppedDate}
            nextAdoptionStopped={adoptionStopped === 'true'}
            latestOrderPlanDate={latestOrderPlanDate}
          />
          <OrderableWholesaleSelectV2Form
            control={control}
            label="店舗設定卸"
            name="wholesaleId"
            medicineId={fragment.medicine.id}
            defaultWholesale={fragment.medicine.defaultPrimaryWholesale ?? undefined}
          />
          <MedicinePackageUnitSelectControl
            name="medicinePackageUnitId"
            currentMedicinePackageUnitId={medicinePackageUnitId}
            medicineId={fragment.medicine.id}
            showRequired={false}
            control={control}
            onComplete={(orderableMedicinePackageUnits) => {
              if (orderableMedicinePackageUnits.length === 1 && orderableMedicinePackageUnits[0]) {
                setValue('medicinePackageUnitId', orderableMedicinePackageUnits[0].id);
              }
            }}
          />
          <StorageSelects<FieldValues, 'storages'>
            fields={fields}
            errors={formState.errors.storages ?? []}
            changeStorage={async (i, nextValue) => {
              update(i, nextValue);
              await trigger([`storages.${i}`]);
            }}
            addStorage={async () => {
              await trigger(['storages']);
              append({});
            }}
            deleteStorage={async (i) => {
              remove(i);
              await trigger(['storages']);
            }}
          />
          <OrderRecommendSuspendTermField
            shouldHideOrderRecommendUntilNextTransaction={
              fragment.shouldHideOrderRecommendUntilNextTransaction
            }
            nextAdoptionStopped={adoptionStopped === 'true'}
            orderRecommendSuspendTerm={orderRecommendSuspendTerm}
            onChangeSuspendTermEnabled={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.checked) {
                setValue('orderRecommendSuspendTerm', undefined, { shouldDirty: true });
              } else {
                setValue(
                  'orderRecommendSuspendTerm',
                  {
                    isInf: false,
                    startDate: new Date(),
                    endDate: endOfMonth(new Date()),
                  },
                  { shouldDirty: true },
                );
              }
            }}
            onChangeSuspendTerm={(date: Date | null) => {
              if (!date) return;
              setValue(
                'orderRecommendSuspendTerm',
                orderRecommendSuspendTerm
                  ? {
                      ...orderRecommendSuspendTerm,
                      endDate: date,
                    }
                  : undefined,
                { shouldDirty: true },
              );
            }}
            onChangeSuspendInfTermEnabled={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setValue(
                  'orderRecommendSuspendTerm',
                  orderRecommendSuspendTerm
                    ? {
                        ...orderRecommendSuspendTerm,
                        isInf: true,
                        endDate: undefined,
                      }
                    : undefined,
                  { shouldDirty: true },
                );
              } else {
                setValue(
                  'orderRecommendSuspendTerm',
                  orderRecommendSuspendTerm
                    ? {
                        ...orderRecommendSuspendTerm,
                        isInf: false,
                        endDate: endOfMonth(orderRecommendSuspendTerm.startDate),
                      }
                    : undefined,
                  { shouldDirty: true },
                );
              }
            }}
          />
          <DemandTakeoverToMedicineForm
            defaultDemandTakeoverMedicineId={
              fragment.medicine.demandTakeoverMedicineId ?? undefined
            }
            sourceMedicine={{
              id: fragment.medicine.id,
              name: fragment.medicine.medicineName,
              unitOutline: fragment.medicine.usageGroup.unitOutline,
              // HAK-13688 で一時的に追加されたフィールド（検証完了後に削除予定）
              shortUnitOutline: fragment.medicine.usageGroup.shortUnitOutline,
            }}
          />
          <OrderPointSettingField
            stockUnitSymbol={fragment.medicine.stockUnitSymbol}
            orderPointSettingMode={{
              value: orderPointSetting.mode,
              onChange: (mode) => {
                // NOTE: mode を変更した場合は fixedValue、factorを初期値に戻す
                setValue(
                  'orderPointSetting.fixedValue',
                  defaultValues.orderPointSetting.fixedValue,
                );
                setValue('orderPointSetting.factor', defaultValues.orderPointSetting.factor);
                setValue('orderPointSetting.mode', mode, { shouldDirty: true });
                // バリデーションを実行する
                trigger();
              },
            }}
            fixedValue={{
              defaultValue: orderPointSetting.fixedValue,
              onChange: (value) => {
                setValue('orderPointSetting.fixedValue', value, { shouldDirty: true });
                // バリデーションを実行する
                trigger();
              },
            }}
            factor={{
              defaultValue: orderPointSetting.factor,
              onChange: (value) => {
                setValue('orderPointSetting.factor', value, { shouldDirty: true });
                // バリデーションを実行する
                trigger();
              },
            }}
          />
        </Stack>
        <Flex position="absolute" bottom={6} right={6}>
          <Button onClick={onClickUpdate} isLoading={isSubmitting} isDisabled={isDisabled}>
            変更を保存する
          </Button>
        </Flex>
      </FormProvider>
    </AlertDialogContent>
  );
}
