import { createIcon } from '@chakra-ui/react';

export const PowderSeparatelyIcon = createIcon({
  displayName: 'PowderSeparatelyIcon',
  viewBox: '0 0 40 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.997 20.795l-14.88-11.76L5.973 20.772l2.457 3.305 3.264 2.32 3.733.846 3.663.775 4.61-.699 3.444-.62 2.373-.768 2.954-1.181 2.525-3.955zm-26.97.223l12.112-10.053 12.864 10.166-1.535 2.405-2.46.984-2.228.721-3.324.598-4.321.655-3.38-.715L12.32 25l-2.826-2.01-1.468-1.973zm8.356-5.266v1.353h1.4v-1.353h-1.4zm2.706-1.353v-1.354h1.4V14.4h-1.4zm-5.413 4.06v1.353h1.4V18.46h-1.4zm-2.707 4.06v-1.353h1.4v1.353h-1.4zm9.474-6.09v1.353h1.4V16.43h-1.4zm-2.03 4.737v-1.354h1.4v1.354h-1.4zm-2.707 1.353v1.353h1.4V22.52h-1.4z"
      fill="currentColor"
    />
  ),
});
