import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { Calver } from '../Calver';
import { useWaitingTime } from '../useWaitingTime';

/**
 * package.json のバージョン情報と feature flag の情報をもとにリロードを制御するフック
 * NOTE: form 操作時に強制リロードをキャンセルされた際に、再度リロード処理を行うために、
 * pathname の変更があった場合にも処理されるようにしています
 * @param {string} currentVersion 現在クライアントで利用されているアプリケーションのバージョン情報
 */
export function useForceUpdate(currentVersion: string) {
  const { pathname } = useRouter();

  const { useFlags } = repositoryContainerGetter.featureFlag();
  const flags = useFlags();
  const waitingTime = useWaitingTime();

  useEffect(() => {
    const appMinimumVersionString = flags?.appMinimumVersion || '';
    const minimumVersion = new Calver(appMinimumVersionString);
    const currentAppVersion = new Calver(currentVersion);
    if (!minimumVersion.isFuture() && minimumVersion.isLaterThan(currentAppVersion)) {
      const id = setTimeout(() => {
        window.location.reload();
      }, waitingTime * 1000);
      return () => {
        clearTimeout(id);
      };
    }
    return () => {};
  }, [currentVersion, pathname, flags?.appMinimumVersion, waitingTime]);
}
