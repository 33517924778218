import { AlertDialogFooter, Checkbox, HStack } from '@chakra-ui/react';
import { Typography, useZodForm } from '@kkhs/hakari-ui';
import { Controller, FormProvider } from 'react-hook-form';
import { StockOperationDeleteButtonGroup } from './StockOperationDeleteButtonGroup';
import { schema } from './schema';

type Props = {
  stockOperationId: string;
  cancelRef: React.RefObject<HTMLButtonElement>;
  onSuccess: () => Promise<void>;
  onCancel: () => void;
};

export function StockOperationDeleteFooter({
  stockOperationId,
  cancelRef,
  onSuccess,
  onCancel,
}: Props) {
  const useFormReturn = useZodForm({
    schema,
    defaultValues: {
      id: stockOperationId,
      isAlertChecked: false,
    },
  });
  const { control } = useFormReturn;

  return (
    <FormProvider {...useFormReturn}>
      <AlertDialogFooter justifyContent="space-between" alignItems="start" borderTopWidth={1}>
        <HStack spacing={2}>
          <Controller
            name="isAlertChecked"
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox onChange={(e) => onChange(e.target.checked)}>
                <Typography variant="body" fontWeight="bold">
                  削除した入庫情報は復元不可であることを了承しました。
                </Typography>
              </Checkbox>
            )}
          />
        </HStack>
        <StockOperationDeleteButtonGroup
          cancelRef={cancelRef}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      </AlertDialogFooter>
    </FormProvider>
  );
}
