import { Box, chakra, Th, Thead, Tr } from '@chakra-ui/react';
import { InfiniteScrollTable } from '@kkhs/hakari-ui';
import { MedicineTransactionListItemsQuery } from '@/gql/docs';
import { MedicineTransactionListItemsTableBody } from './MedicineTransactionListItemsTableBody';

const StyledTh = chakra(Th, {
  baseStyle: () => ({
    px: 1.5,
    py: 2,
    fontWeight: 'normal',
  }),
});

type Props = {
  hasMore?: boolean;
  loadMore: () => void;
  edges: MedicineTransactionListItemsQuery['medicineTransactionListItems']['edges'];
  scrollParentRef: React.MutableRefObject<null>;
  stockUnitSymbol: string;
  isLoading: boolean;
};

export function MedicineTransactionListItemsTable({
  hasMore,
  loadMore,
  edges,
  scrollParentRef,
  stockUnitSymbol,
  isLoading,
}: Props) {
  return (
    <Box bg="white" borderRadius="md" overflow="hidden">
      <InfiniteScrollTable
        hasMore={hasMore}
        loadMore={loadMore}
        useWindow={false}
        getScrollParent={() => scrollParentRef.current}
        tableProps={{
          style: {
            tableLayout: 'fixed',
            fontSize: '11px',
          },
        }}
      >
        <Thead>
          <Tr>
            <StyledTh w="94px" pl={0}>
              入出庫日
            </StyledTh>
            <StyledTh w="44px">入出庫</StyledTh>
            <StyledTh w="72px">入出庫区分</StyledTh>
            <StyledTh w="136px">入出庫先</StyledTh>
            <StyledTh w="108px" isNumeric>
              入出庫数量
            </StyledTh>
            <StyledTh w="102px" whiteSpace="nowrap" isNumeric>
              入出庫後の在庫数量
            </StyledTh>
            <StyledTh w="108px">ロット番号</StyledTh>
            <StyledTh>備考</StyledTh>
            <StyledTh w="76px">最終更新</StyledTh>
            <StyledTh w="30px" pr={0} />
          </Tr>
        </Thead>
        <MedicineTransactionListItemsTableBody
          edges={edges}
          isLoading={isLoading}
          stockUnitSymbol={stockUnitSymbol}
        />
      </InfiniteScrollTable>
    </Box>
  );
}
