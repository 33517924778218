import { FormHelperText } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { RefObject, useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  InternalCounterpartySelectForm,
  ExternalCounterpartySelectForm,
  assertStockOperationExternalPriceConfig,
  ExternalCounterpartySelectOption,
} from '@/entities/counterparty';
import { GroupPharmacySelectForm } from '@/entities/groupPharmacy';
import { OrderableWholesaleSelectForm } from '@/entities/orderableWholesale';
import {
  TaxLabel,
  calculateTotalTaxAmount,
  calculateTotalAmountForStoreStockOperation,
  ExternalCounterpartyAndOrderableWholesaleSelectForm,
  ExternalCounterpartyAndOrderableWholesaleSelectOption,
} from '@/entities/stockOperation';
import { ContainerAmountForm, FeeAmountForm } from '@/entities/stockOperationPriceConfig';
import { ExternalStockOperationPriceConfig } from '@/gql/docs';
import {
  UpdateStoringStockOperationBySubdividedPurchaseFieldValues,
  UpdateStoringStockOperationFieldValues,
  UpdateStoringStockOperationByOtherFieldValues,
  UpdateStoringStockOperationByTransferFieldValues,
  UpdateStoringStockOperationByTransferRequestFieldValues,
  UpdateStoringStockOperationByExternalTradeFieldValues,
} from '../../../schema';
import { StoringStockOperation } from '../types';
import {
  StoringBySubdividedPurchaseUpdateButtonGroup,
  StoringByTransferUpdateButtonGroup,
  StoringByTransferRequestUpdateButtonGroup,
  StoringByExternalTradeUpdateButtonGroup,
  StoringByOtherUpdateButtonGroup,
} from '../updateButtonGroups';

type Args = {
  stockOperation: StoringStockOperation;
  useFormReturn: UseFormReturn<UpdateStoringStockOperationFieldValues>;
  externalCounterpartyCreateDialogLink: React.ReactElement;
  internalCounterpartyCreateDialogLink: React.ReactElement;
};

export function useStoringDialogContent({
  stockOperation,
  useFormReturn: { control, setValue, formState },
  externalCounterpartyCreateDialogLink,
  internalCounterpartyCreateDialogLink,
}: Args) {
  // NOTE: 結果を配列として受け取る記法の場合は値が更新されないため、operatedMedicines単体で受け取る
  // NOTE: 更新処理後にoperatedMedicinesがundefinedになる可能性があるためチェックを追加している
  const operatedMedicines =
    useWatch({
      control,
      name: 'operatedMedicines',
    }) ?? Array<StoringStockOperation['operatedMedicines']>();
  const [
    stockOperationPriceConfig,
    feeAmount,
    containerAmount,
    defaultPriceConfig,
    storingOriginOnCreated,
  ] = useWatch({
    control,
    name: [
      'stockOperationPriceConfig',
      'feeAmount',
      'containerAmount',
      'defaultPriceConfig',
      'storingOriginOnCreated',
    ],
  });
  const totalAmount = useMemo(() => {
    // NOTE: 税額計算をするのは external の場合のみ
    const taxAmount =
      stockOperationPriceConfig.type === 'external'
        ? calculateTotalTaxAmount({
            operationMedicines: operatedMedicines.flatMap((item) =>
              item.lots.map((lot) => ({
                price: item.costPrice,
                stockUnitQuantity: lot.stockUnitQuantity,
              })),
            ),
            taxCalculationTarget: stockOperationPriceConfig.taxCalculationTarget,
            itemRoundingMethod: stockOperationPriceConfig.itemRoundingMethod,
            taxRoundingMethod: stockOperationPriceConfig.taxRoundingMethod,
            feeAmount: feeAmount ?? undefined,
            containerAmount: containerAmount ?? undefined,
          })
        : null;
    return {
      amount: calculateTotalAmountForStoreStockOperation({
        operationMedicines: operatedMedicines.flatMap((item) =>
          item.lots.map((lot) => ({
            price: item.costPrice,
            stockUnitQuantity: lot.stockUnitQuantity,
          })),
        ),
        totalTaxAmount: taxAmount,
        itemRoundingMethod: stockOperationPriceConfig.itemRoundingMethod,
        totalRoundingMethod: stockOperationPriceConfig.totalRoundingMethod,
        feeAmount: feeAmount ?? undefined,
        containerAmount: containerAmount ?? undefined,
      }),
      taxAmount,
    };
  }, [operatedMedicines, stockOperationPriceConfig, feeAmount, containerAmount]);
  const isDisabled = useMemo(() => operatedMedicines.length === 0, [operatedMedicines.length]);

  switch (stockOperation.__typename) {
    case 'StoringStockOperationBySubdividedPurchase': {
      return {
        renderCounterpartyForm: () => (
          <>
            <OrderableWholesaleSelectForm<UpdateStoringStockOperationBySubdividedPurchaseFieldValues>
              // NOTE: 小分けは卸名が入るため
              name="counterpartyName"
              label="入庫元"
              noOptionsMessage="入庫元を入力してください"
              isDisabled={isDisabled}
              defaultOrderableWholesaleName={stockOperation.counterpartyName}
              // NOTE: useFormReturn の値を小分けの FieldValues に合わせる
              control={
                control as UseFormReturn<UpdateStoringStockOperationBySubdividedPurchaseFieldValues>['control']
              }
              errorMessage={
                (
                  formState as UseFormReturn<UpdateStoringStockOperationBySubdividedPurchaseFieldValues>['formState']
                ).errors?.counterpartyName?.message
              }
            />
            <FeeAmountForm<UpdateStoringStockOperationBySubdividedPurchaseFieldValues>
              name="feeAmount"
              isDisabled={isDisabled}
              control={
                control as UseFormReturn<UpdateStoringStockOperationBySubdividedPurchaseFieldValues>['control']
              }
            />
            <ContainerAmountForm<UpdateStoringStockOperationBySubdividedPurchaseFieldValues>
              name="containerAmount"
              isDisabled={isDisabled}
              control={
                control as UseFormReturn<UpdateStoringStockOperationBySubdividedPurchaseFieldValues>['control']
              }
            />
            <TaxLabel />
          </>
        ),
        renderStoringButtonGroup: (
          cancelRef: RefObject<HTMLButtonElement>,
          onClose: () => void,
        ) => (
          <StoringBySubdividedPurchaseUpdateButtonGroup cancelRef={cancelRef} onClose={onClose} />
        ),
        totalAmount,
        currentPriceConfig: stockOperationPriceConfig,
        currentOperatedMedicines: operatedMedicines,
      };
    }
    case 'StoringStockOperationByTransfer': {
      return {
        renderCounterpartyForm: () => (
          <InternalCounterpartySelectForm<UpdateStoringStockOperationByTransferFieldValues>
            name="counterpartyId"
            isDisabled={isDisabled}
            defaultCounterpartyId={stockOperation.counterpartyId}
            defaultCounterpartyName={stockOperation.counterpartyName}
            control={
              control as UseFormReturn<UpdateStoringStockOperationByTransferFieldValues>['control']
            }
            formState={
              formState as UseFormReturn<UpdateStoringStockOperationByTransferFieldValues>['formState']
            }
            onAfterChange={(value) => {
              if (isNullOrUndefined(value.id)) {
                setValue('counterpartyName', value.name, { shouldValidate: true });
              } else {
                setValue('counterpartyName', null, { shouldValidate: true });
              }
            }}
            onAfterClear={() => {
              setValue('counterpartyName', null, { shouldValidate: true });
            }}
            onAfterCreate={(newValue) => {
              setValue('counterpartyName', newValue, { shouldValidate: true });
            }}
            formLabelRightElement={internalCounterpartyCreateDialogLink}
          />
        ),
        renderStoringButtonGroup: (
          cancelRef: RefObject<HTMLButtonElement>,
          onClose: () => void,
        ) => <StoringByTransferUpdateButtonGroup cancelRef={cancelRef} onClose={onClose} />,
        totalAmount,
        currentPriceConfig: stockOperationPriceConfig,
        currentOperatedMedicines: operatedMedicines,
      };
    }
    case 'StoringStockOperationByTransferRequest': {
      return {
        renderCounterpartyForm: () => (
          <GroupPharmacySelectForm<UpdateStoringStockOperationByTransferRequestFieldValues>
            label="入庫元"
            name="pharmacyId"
            isDisabled={isDisabled}
            control={
              control as UseFormReturn<UpdateStoringStockOperationByTransferRequestFieldValues>['control']
            }
          />
        ),
        renderStoringButtonGroup: (
          cancelRef: RefObject<HTMLButtonElement>,
          onClose: () => void,
        ) => <StoringByTransferRequestUpdateButtonGroup cancelRef={cancelRef} onClose={onClose} />,
        totalAmount,
        currentPriceConfig: stockOperationPriceConfig,
        currentOperatedMedicines: operatedMedicines,
      };
    }
    case 'StoringStockOperationByExternalTrade': {
      assertStockOperationExternalPriceConfig(stockOperationPriceConfig);
      return {
        renderCounterpartyForm: () => {
          const updateStockOperationPriceConfig = (
            nextPriceConfig?: ExternalStockOperationPriceConfig | null,
          ) => {
            // NOTE: 引数が指定されなかった場合、デフォルトの stockOperationExternalDefaultSetting をセットする
            const config = nextPriceConfig ?? defaultPriceConfig.external;
            setValue('stockOperationPriceConfig', { type: 'external', ...config });
            setValue('feeAmount', config.feeAmount);
            setValue('containerAmount', config.containerAmount);
          };
          const errorMessage = (
            formState as UseFormReturn<UpdateStoringStockOperationByExternalTradeFieldValues>['formState']
          ).errors.counterpartyName?.message;
          // NOTE: 入庫元の初期値を設定する
          const toDefaultValue = (
            value: UpdateStoringStockOperationByExternalTradeFieldValues['storingOriginOnCreated'],
          ): ExternalCounterpartySelectOption | null => {
            if (!value) return null;
            if (value.origin === 'externalCounterparty') {
              return {
                type: 'externalCounterparty',
                id: value.counterpartyId,
                name: value.counterpartyName,
                stockOperationPriceConfig: value.priceConfig,
              };
            }
            if (value.origin === 'userInput') {
              return {
                type: 'userInput',
                id: null,
                name: value.counterpartyName,
              };
            }
            return null;
          };
          return (
            <>
              <ExternalCounterpartySelectForm<UpdateStoringStockOperationByExternalTradeFieldValues>
                name="counterpartyId"
                label="入庫元"
                isDisabled={isDisabled}
                defaultValue={toDefaultValue(
                  storingOriginOnCreated as UpdateStoringStockOperationByExternalTradeFieldValues['storingOriginOnCreated'],
                )}
                onChange={(value) => {
                  if (value.type === 'externalCounterparty') {
                    setValue('counterpartyId', value.id, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    setValue('counterpartyName', null, { shouldDirty: true, shouldValidate: true });
                    updateStockOperationPriceConfig(value.stockOperationPriceConfig);
                  }
                  if (value.type === 'userInput') {
                    setValue('counterpartyId', null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    setValue('counterpartyName', value.name, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    updateStockOperationPriceConfig();
                  }
                }}
                onAfterClear={() => {
                  setValue('counterpartyName', null, { shouldDirty: true, shouldValidate: true });
                  updateStockOperationPriceConfig();
                }}
                onAfterCreate={(newValue) => {
                  setValue('counterpartyName', newValue, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  updateStockOperationPriceConfig();
                }}
                helperElement={
                  errorMessage ? (
                    <Typography variant="body2" color="red.500">
                      {errorMessage}
                    </Typography>
                  ) : (
                    <FormHelperText color="slate.300" mt={1}>
                      選択した取引先の設定を適用します。
                      <br />
                      未入力の場合はデフォルト設定を適用します。
                    </FormHelperText>
                  )
                }
                formLabelRightElement={externalCounterpartyCreateDialogLink}
                control={
                  control as UseFormReturn<UpdateStoringStockOperationByExternalTradeFieldValues>['control']
                }
              />
              {!isNullOrUndefined(stockOperationPriceConfig.feeAmount) && (
                <FeeAmountForm<UpdateStoringStockOperationByExternalTradeFieldValues>
                  name="feeAmount"
                  isDisabled={isDisabled}
                  control={
                    control as UseFormReturn<UpdateStoringStockOperationByExternalTradeFieldValues>['control']
                  }
                />
              )}
              {!isNullOrUndefined(stockOperationPriceConfig.containerAmount) && (
                <ContainerAmountForm<UpdateStoringStockOperationByExternalTradeFieldValues>
                  name="containerAmount"
                  isDisabled={isDisabled}
                  control={
                    control as UseFormReturn<UpdateStoringStockOperationByExternalTradeFieldValues>['control']
                  }
                />
              )}
              {stockOperationPriceConfig.shouldShowTax && <TaxLabel w="100px" />}
            </>
          );
        },
        renderStoringButtonGroup: (
          cancelRef: RefObject<HTMLButtonElement>,
          onClose: () => void,
        ) => <StoringByExternalTradeUpdateButtonGroup cancelRef={cancelRef} onClose={onClose} />,
        totalAmount,
        currentPriceConfig: stockOperationPriceConfig,
        currentOperatedMedicines: operatedMedicines,
      };
    }
    case 'StoringStockOperationByOther': {
      assertStockOperationExternalPriceConfig(stockOperationPriceConfig);
      const updateStockOperationPriceConfig = (
        nextPriceConfig?: ExternalStockOperationPriceConfig | null,
      ) => {
        // NOTE: 引数が指定されなかった場合、デフォルトの stockOperationExternalDefaultSetting をセットする
        const config = nextPriceConfig ?? defaultPriceConfig.external;
        setValue('stockOperationPriceConfig', { type: 'external', ...config });
        // NOTE: 反映されるのは税額や端数のみで、手数料や容器代は考慮しない (特に「その他」の場合)
        setValue('feeAmount', null);
        setValue('containerAmount', null);
      };
      return {
        renderCounterpartyForm: () => {
          const errorMessage = (
            formState as UseFormReturn<UpdateStoringStockOperationByOtherFieldValues>['formState']
          ).errors.counterpartyName?.message;
          // NOTE: 入庫元の初期値を設定する
          const toDefaultValue = (
            value: UpdateStoringStockOperationByOtherFieldValues['storingOriginOnCreated'],
          ): ExternalCounterpartyAndOrderableWholesaleSelectOption | null => {
            if (!value) return null;
            if (value.origin === 'externalCounterparty') {
              return {
                type: 'externalCounterparty',
                id: value.counterpartyId,
                name: value.counterpartyName,
                stockOperationPriceConfig: value.priceConfig,
              };
            }
            if (value.origin === 'userInput') {
              return {
                type: 'userInput',
                id: null,
                name: value.counterpartyName,
              };
            }
            return null;
          };
          return (
            <>
              <ExternalCounterpartyAndOrderableWholesaleSelectForm<UpdateStoringStockOperationByOtherFieldValues>
                name="counterpartyId"
                label="入庫元"
                isDisabled={isDisabled}
                defaultValue={toDefaultValue(
                  storingOriginOnCreated as UpdateStoringStockOperationByOtherFieldValues['storingOriginOnCreated'],
                )}
                onChange={(value) => {
                  if (value.type === 'externalCounterparty') {
                    // 取引先設定が存在する場合 (法人間)
                    setValue('counterpartyId', value.id, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    setValue('counterpartyName', null, { shouldDirty: true, shouldValidate: true });
                    updateStockOperationPriceConfig(value.stockOperationPriceConfig);
                  } else if (value.type === 'orderableWholesale' || value.type === 'userInput') {
                    // 取引先設定が存在しない場合 (卸/ユーザ入力)
                    setValue('counterpartyId', null, { shouldDirty: true, shouldValidate: true });
                    setValue('counterpartyName', value.name, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    updateStockOperationPriceConfig();
                  }
                }}
                onAfterClear={() => {
                  setValue('counterpartyName', null, { shouldDirty: true, shouldValidate: true });
                  updateStockOperationPriceConfig();
                }}
                onAfterCreate={(newValue) => {
                  setValue('counterpartyName', newValue, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  updateStockOperationPriceConfig();
                }}
                // NOTE: useFormReturn の値を「その他入庫」の FieldValues に合わせる
                control={
                  control as UseFormReturn<UpdateStoringStockOperationByOtherFieldValues>['control']
                }
                helperElement={
                  errorMessage ? (
                    <Typography variant="body2" color="red.500">
                      {errorMessage}
                    </Typography>
                  ) : (
                    <FormHelperText color="slate.300" mt={1}>
                      選択した取引先の設定を適用します。
                      <br />
                      未入力の場合はデフォルト設定を適用します。
                    </FormHelperText>
                  )
                }
                noOptionsMessage="入庫元を入力してください"
                formLabelRightElement={externalCounterpartyCreateDialogLink}
              />
              {stockOperationPriceConfig.shouldShowTax && <TaxLabel w="100px" />}
            </>
          );
        },
        renderStoringButtonGroup: (
          cancelRef: RefObject<HTMLButtonElement>,
          onClose: () => void,
        ) => <StoringByOtherUpdateButtonGroup cancelRef={cancelRef} onClose={onClose} />,
        totalAmount,
        currentPriceConfig: stockOperationPriceConfig,
        currentOperatedMedicines: operatedMedicines,
      };
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new Error(`unreachable: ${(stockOperation as any)?.__typename}`);
  }
}
