import { Stack, StackProps } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';

type Props = StackProps;

export function TaxLabel(props: Props) {
  return (
    <Stack spacing={1} {...props}>
      <Typography variant="body" fontWeight="bold">
        税率区分
      </Typography>
      <Typography variant="body">10%</Typography>
    </Stack>
  );
}
