import { Stack, Grid, Divider } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AddressForm,
  BuildingNameForm,
  CounterpartyNameForm,
  PharmacyLicenseNumberForm,
  PhoneAndFaxNumberForm,
  ZipCodeForm,
} from '@/entities/counterparty';
import {
  getIsTotalRoundingMethodDisabled,
  ContainerAmountConfigForm,
  DefaultPriceRateForm,
  FeeAmountConfigForm,
  ItemRoundingMethodForm,
  SlipTitleForm,
  TaxForm,
  TotalRoundingMethodForm,
} from '@/entities/stockOperationPriceConfig';
import { CreateExternalCounterpartyFieldValues } from './schema';

type Props = { loading: boolean };

export function DialogContent({ loading }: Props) {
  const { control, register, setValue, formState, watch, trigger } =
    useFormContext<CreateExternalCounterpartyFieldValues>();
  const [
    shouldShowTax,
    feeAmountEnabled,
    containerAmountEnabled,
    isTotalRoundingMethodDisabled,
    taxRoundingMethod,
    itemRoundingMethod,
  ] = watch([
    'shouldShowTax',
    'feeAmountEnabled',
    'containerAmountEnabled',
    'isTotalRoundingMethodDisabled',
    'taxRoundingMethod',
    'itemRoundingMethod',
  ]);
  const onAfterChangeShouldShowTax = useCallback(
    (nextValue: 'true' | 'false') => {
      // NOTE: 消費税表示しない場合は、消費税端数処理は処理しないに設定
      if (nextValue === 'false') {
        setValue('taxRoundingMethod', 'none');
      }
    },
    [setValue],
  );
  useEffect(() => {
    setValue(
      'isTotalRoundingMethodDisabled',
      getIsTotalRoundingMethodDisabled({
        shouldShowTax: shouldShowTax === 'true',
        itemRoundingMethod: itemRoundingMethod === 'none' ? null : itemRoundingMethod,
        taxRoundingMethod: taxRoundingMethod === 'none' ? null : taxRoundingMethod,
      }),
    );
  }, [shouldShowTax, itemRoundingMethod, setValue, taxRoundingMethod]);
  return (
    <Stack spacing={6} p={6}>
      <Stack spacing={4} divider={<Divider borderColor="gray.200" />}>
        <Grid templateColumns="160px 1fr" alignItems="baseline" rowGap={4}>
          <CounterpartyNameForm<CreateExternalCounterpartyFieldValues>
            name="name"
            isLoading={loading}
            register={register}
            formState={formState}
          />
          <ZipCodeForm<CreateExternalCounterpartyFieldValues>
            name="zipCode"
            isLoading={loading}
            register={register}
            formState={formState}
          />
          <AddressForm<CreateExternalCounterpartyFieldValues>
            name="address"
            isLoading={loading}
            register={register}
            formState={formState}
          />
          <BuildingNameForm<CreateExternalCounterpartyFieldValues>
            name="buildingName"
            isLoading={loading}
            register={register}
            formState={formState}
          />
          <PhoneAndFaxNumberForm<CreateExternalCounterpartyFieldValues>
            phoneNumberFieldName="phoneNumber"
            faxNumberFieldName="faxNumber"
            isLoading={loading}
            register={register}
            formState={formState}
          />
          <PharmacyLicenseNumberForm<CreateExternalCounterpartyFieldValues>
            name="pharmacyLicenseNumber"
            isLoading={loading}
            register={register}
            formState={formState}
          />
        </Grid>
        <Grid templateColumns="160px 1fr" alignItems="baseline" rowGap={6}>
          <DefaultPriceRateForm<CreateExternalCounterpartyFieldValues>
            name="defaultPriceRatePercent"
            isLoading={loading}
            control={control}
            trigger={trigger}
          />
          <TaxForm<CreateExternalCounterpartyFieldValues>
            shouldShowTaxFieldName="shouldShowTax"
            taxCalculationTargetFieldName="taxCalculationTarget"
            taxRoundingMethodFieldName="taxRoundingMethod"
            showCalculationTarget
            isLoading={loading}
            isDisabled={shouldShowTax === 'false'}
            onAfterChangeShouldShowTax={onAfterChangeShouldShowTax}
            control={control}
          />
          <ItemRoundingMethodForm<CreateExternalCounterpartyFieldValues>
            name="itemRoundingMethod"
            isLoading={loading}
            control={control}
          />
          <TotalRoundingMethodForm<CreateExternalCounterpartyFieldValues>
            name="totalRoundingMethod"
            showInfo
            isLoading={loading}
            isTotalRoundingMethodDisabled={isTotalRoundingMethodDisabled}
            control={control}
          />
          <FeeAmountConfigForm<CreateExternalCounterpartyFieldValues>
            feeAmountEnabledFieldName="feeAmountEnabled"
            feeAmountFieldName="feeAmount"
            isLoading={loading}
            feeAmountEnabled={feeAmountEnabled}
            control={control}
          />
          <ContainerAmountConfigForm<CreateExternalCounterpartyFieldValues>
            containerAmountEnabledFieldName="containerAmountEnabled"
            containerAmountFieldName="containerAmount"
            isLoading={loading}
            containerAmountEnabled={containerAmountEnabled}
            control={control}
          />
          <SlipTitleForm<CreateExternalCounterpartyFieldValues>
            name="slipTitle"
            isLoading={loading}
            register={register}
            formState={formState}
          />
        </Grid>
      </Stack>
    </Stack>
  );
}
