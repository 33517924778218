import {
  ExternalCounterpartySelectOptionFragment,
  ExternalCounterpartySelectOptionsQuery,
} from '@/gql/apollo';

export function assertExternalCounterpartySelectOptionFragment(
  node: ExternalCounterpartySelectOptionsQuery['counterparties']['edges'][number]['node'],
): asserts node is ExternalCounterpartySelectOptionFragment {
  if (node.__typename === 'ExternalCounterparty') return;
  throw new Error(`typename is invalid: ${node.__typename}`);
}
