import { createIcon } from '@chakra-ui/react';

export const KitUnitSymbolIcon = createIcon({
  displayName: 'Kit',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 2.90005L3.81 2.80005L3.91233 4.75182L5.97 4.3999L6 5.2999L3.95939 5.6496L4.10432 8.41397L6.37 8.03003L6.4 8.93003L4.15117 9.30763L4.35 13.1L3.54 13.2L3.34305 9.44332L1.04 9.83003L1 8.94003L3.29626 8.55091L3.15141 5.78807L1.04 6.1499L1 5.2499L3.10433 4.89001L3 2.90005Z"
        fill="currentColor"
      />
      <path
        d="M11.78 3V6.08487C13.2008 6.77871 15.49 8.63 15.49 8.63L15.2 9.55C15.2 9.55 13.22 7.79 11.78 7.02V13H11V3H11.78Z"
        fill="currentColor"
      />
      <path
        d="M8.87997 9.29997L8.18997 9.47997H8.17997C8.09997 8.02997 7.92997 6.89997 7.83997 6.42997L8.47997 6.21997C8.62996 6.96997 8.80997 8.46997 8.87997 9.29997Z"
        fill="currentColor"
      />
      <path d="M9.42002 6.40991V6.41325L9.41002 6.40991H9.42002Z" fill="currentColor" />
      <path
        d="M9.42002 6.41325L10.19 6.66991C10.19 11.0699 9.57002 12.0299 7.33002 12.9999L7.02002 12.1099C8.87957 11.3501 9.41976 11.0601 9.42002 6.41325Z"
        fill="currentColor"
      />
      <path
        d="M7.25998 6.6499C7.45998 7.5099 7.60998 8.4499 7.69998 9.6199L7.01998 9.7999C6.94998 8.5099 6.77998 7.6999 6.59998 6.8799L7.25998 6.6499Z"
        fill="currentColor"
      />
    </svg>
  ),
});
