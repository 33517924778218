import { useCallback, useState } from 'react';

type SortDirection = 'asc' | 'desc';

type Props<T extends { direction: SortDirection; field: string }> = {
  initialState?: T;
};

export const useSortBy = <T extends { direction: SortDirection; field: string }>({
  initialState,
}: Props<T> = {}): {
  sortBy: T | undefined;
  handleSortBy: (fieldName: T['field']) => void;
} => {
  const [sort, setSort] = useState<T | undefined>(initialState);
  const handleSortBy = useCallback(
    (fieldName: T['field']) => {
      const sortBy = {
        field: fieldName,
        direction: (sort?.field && sort.field === fieldName && sort.direction === 'asc'
          ? 'desc'
          : 'asc') as SortDirection,
      };
      setSort(sortBy as T);
    },
    [sort?.direction, sort?.field],
  );
  return { sortBy: sort, handleSortBy };
};
