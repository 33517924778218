import { Box, FormControl, FormLabel, Skeleton } from '@chakra-ui/react';
import { RequiredTag, Select, Typography } from '@kkhs/hakari-ui';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  PrimaryWholesaleSelectOptionFragment,
  usePrimaryWholesaleSelectOptionsQuery,
} from '@/gql/apollo';

type Props = {
  medicineId?: string;
  defaultWholesale?: PrimaryWholesaleSelectOptionFragment;
  /** 必須ラベルを表示するか */
  isRequired?: boolean;
};

export function PrimaryWholesaleForm({ medicineId, defaultWholesale, isRequired = false }: Props) {
  const { control } = useFormContext<{ wholesaleId: string }>();
  const {
    field: { onChange, ...fieldProps },
  } = useController({
    name: 'wholesaleId',
    control,
  });

  const { data, loading } = usePrimaryWholesaleSelectOptionsQuery({
    variables: { medicineId },
  });
  const options = useMemo(
    () =>
      data?.orderableWholesales.map((fragment) => ({
        value: fragment,
        label: fragment.shortName,
      })),
    [data],
  );

  const currentValue = useMemo(
    () => options?.find((o) => o.value.id === fieldProps.value),
    [fieldProps.value, options],
  );
  const defaultValue = useMemo(
    () =>
      defaultWholesale
        ? {
            value: defaultWholesale,
            label: defaultWholesale.shortName,
          }
        : undefined,
    [defaultWholesale],
  );

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel
        htmlFor="wholesaleId"
        requiredIndicator={isRequired ? <RequiredTag ml={2} mt={0} /> : undefined}
        display="flex"
        alignItems="center"
      >
        <Typography variant="body2" fontWeight="bold" as="span">
          店舗設定卸
        </Typography>
      </FormLabel>
      <Box w="300px">
        <Skeleton isLoaded={!loading}>
          <Select<PrimaryWholesaleSelectOptionFragment>
            options={options}
            {...fieldProps}
            value={currentValue}
            defaultValue={defaultValue}
            onChange={({ id }) => onChange(id)}
          />
        </Skeleton>
      </Box>
    </FormControl>
  );
}
