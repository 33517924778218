import { Select, SelectInstanceType, SelectProps } from '@kkhs/hakari-ui';
import { forwardRef } from 'react';
import { useGroupPharmacyStockListItemsQuery } from '@/gql/apollo';

type PharmacyId = string;
type Props = {
  ref?: React.Ref<HTMLSelectElement>;
  value?: PharmacyId | null;
  medicineId: string;
} & Omit<SelectProps<PharmacyId>, 'options' | 'value'>;

/**
 * 同一法人内の店舗(自店舗を除く)を一件選択することができるコンポーネント
 * ラベルには、店舗名に加えてその店舗で保持している医薬品の在庫数を併記する
 */
export const GroupPharmacyWithStockUnitQuantitySelect = forwardRef<
  SelectInstanceType<PharmacyId>,
  Omit<Props, 'ref'>
>(({ value, medicineId, ...rest }: Omit<Props, 'ref'>, ref) => {
  const { data } = useGroupPharmacyStockListItemsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      medicineId,
    },
  });

  const groupPharmacySelectOptions = data?.medicineStockListItemsV2.edges
    .filter(({ node }) => node.medicine.isAdopted)
    .map(({ node }) => {
      const quantityText = ` (${node.medicine.stockUnitQuantity} ${node.medicine.stockUnitSymbol})`;
      return {
        value: node.pharmacy.id,
        label: `${node.pharmacy.name}${quantityText}`,
      };
    });
  const currentValue = groupPharmacySelectOptions?.find((opt) => opt.value === value) ?? null;
  return (
    <Select<PharmacyId>
      {...rest}
      ref={ref}
      options={groupPharmacySelectOptions}
      size="sm"
      isClearable
      value={currentValue}
    />
  );
});
GroupPharmacyWithStockUnitQuantitySelect.displayName = 'GroupPharmacyWithStockUnitQuantitySelect';
