import { InternalRefetchQueriesInclude } from '@apollo/client';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { useMemo, useRef } from 'react';
import { useStockOperationExternalDefaultSettingQuery } from '@/gql/apollo';
import { LoadedDialogContent } from './LoadedDialogContent';
import { LoadingDialogContent } from './LoadingDialogContent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refetchQueries?: InternalRefetchQueriesInclude;
};

export function ExternalCounterpartyCreateDialog({ isOpen, onClose, refetchQueries }: Props) {
  const cancelRef = useRef(null);
  const { data, loading } = useStockOperationExternalDefaultSettingQuery({
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {},
  });

  const fragment = useMemo(
    () =>
      data?.stockOperationDefaultSetting?.__typename === 'StockOperationExternalDefaultSetting'
        ? data.stockOperationDefaultSetting
        : null,
    [data?.stockOperationDefaultSetting],
  );

  return (
    <AlertDialog
      size="3xl"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay data-testid="create-external-counterparty-dialog">
        <AlertDialogContent>
          <AlertDialogHeader borderBottom="1px" borderColor="gray.200">
            <Typography variant="h2">取引先情報（法人間）</Typography>
          </AlertDialogHeader>

          {loading ? (
            <LoadingDialogContent onClose={onClose} cancelRef={cancelRef} />
          ) : (
            <LoadedDialogContent
              onClose={onClose}
              cancelRef={cancelRef}
              refetchQueries={refetchQueries}
              fragment={fragment}
            />
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
