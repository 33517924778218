import { z } from 'zod';
import { RoundingMethod, TaxCalculationTarget } from '@/gql/apollo';

export const roundingMethodSchema = z.nativeEnum(RoundingMethod);

export const taxCalculationTargetSchema = z.nativeEnum(TaxCalculationTarget);

export const stockOperationInternalPriceConfigSchema = z.object({
  type: z.literal('internal'),
  /** 総額端数処理方法 */
  totalRoundingMethod: roundingMethodSchema.nullable(),
  /** 明細端数処理方法 */
  itemRoundingMethod: roundingMethodSchema.nullable(),
  /** 伝票名称 */
  slipTitle: z.string(),
});
export type StockOperationInternalPriceConfig = z.infer<
  typeof stockOperationInternalPriceConfigSchema
>;

export const stockOperationExternalPriceConfigSchema = z.object({
  type: z.literal('external'),
  /** 出庫単価算出率 */
  defaultPriceRate: z.number(),
  /** 税額を表示するかどうか */
  shouldShowTax: z.boolean(),
  /** 総額端数処理方法 */
  totalRoundingMethod: roundingMethodSchema.nullable(),
  /** 明細端数処理方法 */
  itemRoundingMethod: roundingMethodSchema.nullable(),
  /** 税額端数処理方法 */
  taxRoundingMethod: roundingMethodSchema.nullable(),
  /** 税額計算対象（operation or item） */
  taxCalculationTarget: taxCalculationTargetSchema.nullable(),
  /** 容器代（税別） */
  containerAmount: z.number().nullable(),
  /** 手数料（税別） */
  feeAmount: z.number().nullable(),
  /** 伝票名称 */
  slipTitle: z.string(),
});
export type StockOperationExternalPriceConfig = z.infer<
  typeof stockOperationExternalPriceConfigSchema
>;

/**
 * NOTE: 入出庫履歴の編集では上記の stockOperationInternalPriceConfigSchema と stockOperationExternalPriceConfigSchema に分離します。
 *       既存の処理が下記スキーマのみを利用しているので、ゆくゆくは上記の通り法人間と法人内で分かれるように既存部分をリファクタリングする。
 */

export const stockOperationPriceConfigSchema = z.object({
  /** 出庫単価算出率 */
  defaultPriceRate: z.number(),
  /** 税額を表示するかどうか */
  shouldShowTax: z.boolean(),
  /** 総額端数処理方法 */
  totalRoundingMethod: roundingMethodSchema.nullable(),
  /** 明細端数処理方法 */
  itemRoundingMethod: roundingMethodSchema.nullable(),
  /** 税額端数処理方法 */
  taxRoundingMethod: roundingMethodSchema.nullable(),
  /** 税額計算対象（operation or item） */
  taxCalculationTarget: taxCalculationTargetSchema.nullable(),
  /** 容器代（税別） */
  containerAmount: z.number().nullable(),
  /** 手数料（税別） */
  feeAmount: z.number().nullable(),
  /** 伝票名称 */
  slipTitle: z.string(),
});

export const counterpartyNameSchema = z
  .string()
  .min(1, '取引先名称を入力してください')
  .max(32, '取引先名称は32文字以内で入力してください');
export const counterpartyZipCodeSchema = z
  .string()
  .max(10, '郵便番号は10文字以内で入力してください')
  .regex(/^([0-9-]*)$/, '利用できる文字は半角数字または-です')
  .nullable();
export const counterpartyAddressSchema = z
  .string()
  .max(80, '住所は80文字以内で入力してください')
  .nullable();
export const counterpartyBuildingNameSchema = z
  .string()
  .max(30, '建物名は30文字以内で入力してください')
  .nullable();
export const counterpartyPhoneNumberSchema = z
  .string()
  .max(15, '電話番号は15文字以内で入力してください')
  .regex(/^[0-9-+]*$/, '利用できる文字は半角数字または-,+です')
  .nullable();
export const counterpartyFaxNumberSchema = z
  .string()
  .max(15, 'FAX番号は15文字以内で入力してください')
  .regex(/^[0-9-+]*$/, '利用できる文字は半角数字または-,+です')
  .nullable();
export const counterpartyPharmacyLicenseNumberSchema = z
  .string()
  .max(30, '薬局開設許可番号は30文字以内で入力してください')
  .nullable();
