import { Text, TextProps, useMultiStyleConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Variant = 'h1' | 'h2' | 'h3' | 'body' | 'body2' | 'caption';

type Props = (
  | {
      /**
       *  Variant - Typographyの種類
       *
       * - h1: 見出し
       * - h2: 中見出し
       * - h3: 小見出し
       * - body: 一般的なテキスト
       * - body2: bodyよりも重要度が劣るテキスト
       * - caption: コンテンツとして重要でないテキスト
       * */
      variant: Variant;
      /**
       *  isNumeric - 数値をchildrenに取るか否か
       * */
      isNumeric: true;
      children?: number;
    }
  | {
      variant: Variant;
      isNumeric?: false;
      children: ReactNode;
    }
) &
  Omit<TextProps, 'fontSize' | 'variant' | 'children'>;

const variantFontSizeMapper = {
  h1: 'xl',
  h2: 'lg',
  // NOTE: numberTextTheme に lg と md の間のサイズがないので、一旦 lg を当てておく
  h3: 'lg',
  body: 'md',
  body2: 'sm',
  caption: 'xs',
} as const;

function renderNumericChildren(children: number | undefined) {
  return children !== undefined && !Number.isNaN(children) ? children.toLocaleString() : '-';
}

export function Typography({ variant = 'body', isNumeric, children, ...rest }: Props) {
  const styles = useMultiStyleConfig('Typography', { variant });
  const numericStyles = useMultiStyleConfig('NumberText', { size: variantFontSizeMapper[variant] });

  const textProps = {
    sx: isNumeric ? numericStyles : styles,
    children: isNumeric ? renderNumericChildren(children) : children,
  };

  return <Text {...textProps} {...rest} />;
}
