import { z } from 'zod';

export const containerAmountSchema = z
  .number()
  .int('整数を入力してください')
  .min(0, '容器代には0以上の値を入力してください')
  .max(99999, '容器代は99999以下で入力してください');
export const feeAmountSchema = z
  .number()
  .int('整数を入力してください')
  .min(0, '手数料には0以上の値を入力してください')
  .max(9999999, '手数料は9999999以下で入力してください');
export const slipTitleSchema = z
  .string()
  .min(1, '入力必須の項目です')
  .max(20, '伝票名称は20文字以内で入力してください');
