import { AWSDate } from './types';

const awsDateRegex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export const brandAWSDate = (value: string) => {
  if (!awsDateRegex.test(value)) {
    throw new Error('引数のフォーマットが不正です');
  }
  return value as AWSDate;
};
