import { HStack, Link, StackProps } from '@chakra-ui/react';
import { Typography } from '@kkhs/hakari-ui';
import { StockUnitSymbolIcon } from '../StockUnitSymbolIcon';

function getFontWeight(
  fontWeight: StackProps['fontWeight'] | undefined,
  variant: 'h1' | 'h2' | 'body' | 'body2',
) {
  if (fontWeight) {
    return fontWeight;
  }

  return variant === 'body2' ? 'initial' : 'bold';
}

/**
 * TODO: stockUnitSymbol は type StockUnitSymbol を export して指定,color でChakraUI で型指定する
 * https://github.com/kkhs/hakari-frontend/issues/6385
 */
type Props = {
  /** 文字サイズ */
  variant?: 'h1' | 'h2' | 'body' | 'body2';
  /** 数量 */
  quantity?: number;
  /** 単位 */
  stockUnitSymbol: string;
  /** color */
  color?: string;
  /** 高さ */
  height?: StackProps['height'];
  /** spacing */
  spacing?: StackProps['spacing'];
  /** font weight */
  fontWeight?: StackProps['fontWeight'];
  /** 遷移Link */
  link?: string;
  /** 遷移Linkクリック時に実行されるコールバック関数 */
  onClickLink?: () => void;
  dataTestId?: string;
  /** 数量の表示をハイフンにするかどうか */
  showHyphenForQuantity?: boolean;
};

export function StockUnitQuantityWithSymbol({
  variant = 'body',
  quantity,
  stockUnitSymbol,
  color,
  height = 'auto',
  spacing = 0,
  fontWeight,
  link,
  onClickLink,
  // TODO: https://kakehashi.atlassian.net/browse/HAK-16695 で修正
  dataTestId = '在庫数量',
  showHyphenForQuantity = false,
}: Props) {
  const typographyProps = showHyphenForQuantity
    ? {
        variant,
        fontWeight: getFontWeight(fontWeight, variant),
        color: color ?? 'slate.700',
        children: '-',
      }
    : {
        variant,
        fontWeight: getFontWeight(fontWeight, variant),
        color: color ?? 'slate.700',
        // NOTE: isNumeric が true の場合、quantity が undefined の場合は `-` を表示するというロジックが Typography コンポーネントにある
        isNumeric: true,
        children: quantity,
      };

  return (
    <HStack spacing={spacing} justifyContent="flex-end" h={height}>
      {link ? (
        <Link href={link} textDecoration="underline" target="_blank" onClick={onClickLink}>
          <Typography data-testid={dataTestId} {...typographyProps} />
        </Link>
      ) : (
        <Typography data-testid={dataTestId} {...typographyProps} />
      )}
      <StockUnitSymbolIcon
        name={stockUnitSymbol}
        color={color ?? 'slate.500'}
        position="relative"
        top="1px"
        boxSize={4}
      />
    </HStack>
  );
}
