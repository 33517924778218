export const disposalReasons = {
  uncategorized: 'uncategorized',
  expired: 'expired',
  dispensingError: 'dispensingError',
} as const;

export const disposalReasonTexts: { [key in DisposalReason]: string } = {
  uncategorized: '指定なし',
  dispensingError: '調剤廃棄',
  expired: '期限切れ',
};

export type DisposalReason = (typeof disposalReasons)[keyof typeof disposalReasons];
