import {
  AsyncCreatableSelect,
  AsyncCreatableSelectProps,
  SelectInstanceType,
} from '@kkhs/hakari-ui';
import { forwardRef } from 'react';
import {
  useExternalCounterpartyAndOrderableWholesaleSelectOptionsForOthersLazyQuery,
  ExternalCounterpartySelectOptionFragment,
} from '@/gql/apollo';

type ExternalCounterpartySelectOption = ExternalCounterpartySelectOptionFragment & {
  type: 'externalCounterparty';
};
type OrderableWholesaleSelectOption = {
  type: 'orderableWholesale';
  id: string;
  name: string;
  stockOperationPriceConfig: null;
};
type UserInputSelectOption = {
  type: 'userInput';
  id: null;
  name: string;
};

export type ExternalCounterpartyAndOrderableWholesaleSelectOption =
  | ExternalCounterpartySelectOption
  | OrderableWholesaleSelectOption
  | UserInputSelectOption;

type Props = {
  medicineId?: string;
  noOptionsMessage: string;
  ref?: React.Ref<HTMLSelectElement>;
} & Omit<
  AsyncCreatableSelectProps<ExternalCounterpartyAndOrderableWholesaleSelectOption>,
  'loadOptions'
>;

export const ExternalCounterpartyAndOrderableWholesaleSelect = forwardRef<
  SelectInstanceType<ExternalCounterpartyAndOrderableWholesaleSelectOption>,
  Omit<Props, 'ref'>
>(({ medicineId, noOptionsMessage, defaultValue, ...rest }: Omit<Props, 'ref'>, ref) => {
  const [fetch] = useExternalCounterpartyAndOrderableWholesaleSelectOptionsForOthersLazyQuery();

  return (
    <AsyncCreatableSelect<ExternalCounterpartyAndOrderableWholesaleSelectOption>
      {...rest}
      ref={ref}
      defaultValue={defaultValue}
      noOptionsMessage={noOptionsMessage}
      formatCreateLabel={(inputValue: string) => `"${inputValue}" を追加`}
      loadOptions={async (inputValue: string) => {
        const result = await fetch({
          variables: { medicineId, name: inputValue },
        });
        const options: {
          label: string;
          value: ExternalCounterpartyAndOrderableWholesaleSelectOption;
        }[] =
          result.data?.orderableWholesales.map((wholesale) => ({
            label: wholesale.shortName,
            value: {
              type: 'orderableWholesale',
              id: wholesale.id,
              name: wholesale.shortName,
              stockOperationPriceConfig: null,
            },
          })) ?? [];
        result.data?.counterparties.edges.forEach(({ node }) => {
          if (node.__typename === 'ExternalCounterparty') {
            const fragment = node;
            options.push({
              label: node.name,
              value: {
                ...fragment,
                type: 'externalCounterparty',
              },
            });
          }
        });
        return options;
      }}
    />
  );
});
ExternalCounterpartyAndOrderableWholesaleSelect.displayName =
  'ExternalCounterpartyAndOrderableWholesaleSelect';
