import { isNullOrUndefined } from '@kkhs/hakari-utils';

export type PrescriptionFrequencyType = 'f1' | 'f2' | 'fn';

export function getPrescriptionFrequencyType({
  prescribedPatientsNumber,
  hasIdentifiedPatient,
}: {
  prescribedPatientsNumber?: number;
  hasIdentifiedPatient?: boolean;
}): PrescriptionFrequencyType | undefined {
  if (isNullOrUndefined(prescribedPatientsNumber) || isNullOrUndefined(hasIdentifiedPatient)) {
    return undefined;
  }
  if (prescribedPatientsNumber === 1) return 'f1';
  if (prescribedPatientsNumber === 2) return 'f2';
  if (hasIdentifiedPatient) return 'fn';
  return undefined;
}
