import { Tooltip, TooltipProps, useTheme } from '@chakra-ui/react';
import { Theme } from '../../../theme';

type Props = TooltipProps;

export function InfoTooltip(props: Props) {
  const {
    colors: { primary, slate },
  } = useTheme<Theme>();

  return (
    <Tooltip
      bg={primary[100]}
      color={slate[700]}
      borderRadius="base"
      border="1px solid"
      borderColor={primary[600]}
      arrowShadowColor={primary[600]}
      p={2}
      fontWeight="bold"
      hasArrow
      {...props}
    />
  );
}
