import { HStack } from '@chakra-ui/react';
import { AlertIcon, Typography } from '@kkhs/hakari-ui';

type Props = {
  nextAdoptionStopped: boolean;
};

export function AdoptionChangeAlert({ nextAdoptionStopped }: Props) {
  if (nextAdoptionStopped) {
    return (
      <HStack lineHeight="14px">
        <AlertIcon color="red.500" boxSize={5} />
        <Typography variant="body2" color="red.500">
          採用を停止した医薬品は、
          <br />
          おすすめ発注・在庫の自動引き落としの対象になりません。
        </Typography>
      </HStack>
    );
  }
  return (
    <HStack lineHeight="14px">
      <AlertIcon color="red.500" boxSize={5} />
      <Typography variant="body2" color="red.500">
        採用を解除した医薬品は、
        <br />
        おすすめ発注・在庫の自動引き落としの対象になります。
      </Typography>
    </HStack>
  );
}
