import { ShippingStockOperatedMedicineByDisposal, StockOperatedMedicine } from '@/gql/apollo';

export type ShippingOperatedMedicine<
  T extends ShippingStockOperatedMedicineByDisposal | StockOperatedMedicine,
> = {
  medicineId: T['medicineId'];
  name: T['name'];
  stockUnitPrice: T['stockUnitPrice'];
  stockUnitSymbol: T['stockUnitSymbol'];
  stockUnitQuantitySum: number;
  usageGroup: T['usageGroup'];
  lots: {
    id: T['id'];
    stockUnitQuantity: T['stockUnitQuantity'];
    costPrice: StockOperatedMedicine['costPrice'] | undefined;
    lotNumber: T['lotNumber'];
    expirationDate: T['expirationDate'];
  }[];
};

/**
 * medicineIdが同じものは同一テーブル内で表示するため
 * operatedMedicinesのmedicineIdが同じものをグループ化して返す
 *
 * @param stockOperationMedicines
 * @returns OperatedMedicine[]
 */
export function groupMedicinesByMedicineId<
  T extends ShippingStockOperatedMedicineByDisposal | StockOperatedMedicine,
>(stockOperationMedicines: Array<T>) {
  const shippingOperatedMedicineMap = stockOperationMedicines.reduce((map, item) => {
    const {
      id,
      medicineId,
      name,
      usageGroup,
      stockUnitPrice,
      stockUnitSymbol,
      stockUnitQuantity,
      lotNumber,
      expirationDate,
    } = item;
    // NOTE: costPriceはStockOperatedMedicine型にのみあるので、itemからcostPriceがあれば代入
    const costPrice = 'costPrice' in item ? item.costPrice : undefined;
    const value = map.get(medicineId);

    if (!value) {
      map.set(medicineId, {
        medicineId,
        name,
        stockUnitPrice,
        stockUnitSymbol,
        stockUnitQuantitySum: stockUnitQuantity,
        usageGroup,
        lots: [
          {
            id,
            stockUnitQuantity,
            costPrice,
            lotNumber,
            expirationDate,
          },
        ],
      });
    } else {
      value.stockUnitQuantitySum += stockUnitQuantity;
      value.lots.push({
        id,
        stockUnitQuantity,
        costPrice,
        lotNumber,
        expirationDate,
      });
    }
    return map;
  }, new Map<string, ShippingOperatedMedicine<T>>());
  return Array.from(shippingOperatedMedicineMap.values());
}
