import { createIcon } from '@chakra-ui/react';

export const PieceUnitSymbolIcon = createIcon({
  displayName: 'Piece',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2301 2.76988H5.91006V12.9999H13.2301V2.76988ZM6.73006 12.2499V3.50988H12.4201V12.2499H6.73006ZM10.0001 3.76988H9.15006V5.28988H7.00006V5.99988H9.15006V7.39988H7.50006V11.3399H11.6801V7.39988H10.0001V5.99988H12.1001V5.28988H10.0001V3.76988ZM8.30006 10.6299V8.12988H10.9001V10.6299H8.30006ZM3.30006 8.14988L2.81006 7.35988V7.30988C3.92097 5.90132 4.67257 4.24367 5.00006 2.47988V2.37988L5.79006 2.54988V2.64988C5.59276 3.48964 5.32859 4.31227 5.00006 5.10988V13.1899H4.16006V6.88988C3.92881 7.28667 3.65739 7.65862 3.35006 7.99988L3.30006 8.14988Z"
        fill="currentColor"
      />
    </svg>
  ),
});
