import { createIcon } from '@chakra-ui/react';

export const StockoutPreventionTargetAttentionIcon = createIcon({
  displayName: 'StockoutAttentionIcon',
  viewBox: '0 0 28 28',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4C1 2.34 2.34 1 4 1H24C25.66 1 27 2.34 27 4V24C27 25.66 25.66 27 24 27H4C2.34 27 1 25.66 1 24V4ZM0 4C0 1.79 1.79 0 4 0H24C26.21 0 28 1.79 28 4V24C28 26.21 26.21 28 24 28H4C1.79 28 0 26.21 0 24V4Z"
        fill="#C4C4C6"
      />
      <path
        d="M16.65 20.93V20.25H21.55V20.93H16.65ZM16.65 19.75V19.09H21.55V19.75H16.65ZM22.3 18.57H15.93V21.44H22.3V18.57ZM21.47 16.46L20.7 16.29C20.58 16.61 20.35 17.08 20.17 17.39L20.84 17.57C21.03 17.27 21.25 16.88 21.47 16.46ZM17.44 16.3L16.76 16.46C16.96 16.8 17.14 17.25 17.21 17.57L17.92 17.38C17.85 17.09 17.65 16.64 17.44 16.3ZM19.44 15.1H18.68V16.19H19.44V15.1ZM23.4 17.46H14.8V18.07H23.4V17.46ZM22.88 15.77H15.25V16.38H22.88V15.77ZM16.54 22.18L15.92 21.94C15.66 22.61 15.19 23.29 14.5 23.68L15.08 24.08C15.82 23.64 16.26 22.9 16.54 22.18ZM21.89 21.86L21.32 22.2C22 22.72 22.74 23.48 23.07 24.01L23.68 23.63C23.34 23.09 22.57 22.36 21.89 21.86ZM18.53 21.43L18.11 21.83C18.68 22.14 19.38 22.61 19.74 22.93L20.18 22.48C19.81 22.16 19.09 21.72 18.53 21.43ZM17.78 23.31V22.02H17.05V23.31C17.05 24.03 17.31 24.22 18.34 24.22C18.55 24.22 20.01 24.22 20.23 24.22C21.04 24.22 21.27 23.95 21.35 22.83C21.16 22.78 20.86 22.68 20.7 22.58C20.65 23.46 20.59 23.58 20.16 23.58C19.84 23.58 18.63 23.58 18.39 23.58C17.87 23.58 17.78 23.54 17.78 23.31Z"
        fill="#5D6771"
      />
      <path
        d="M8.5701 15.66C9.40009 16.1 10.4001 16.8 10.8601 17.3L11.3901 16.67C10.9001 16.18 9.88009 15.52 9.06009 15.1L8.5701 15.66ZM7.66009 20.15V20.87H12.9501V20.15H7.66009ZM6.95009 23.3V24.02H13.5301V23.3H6.95009ZM7.29009 17.28V18H13.3201V17.28H7.29009ZM9.8601 17.54V23.63H10.6301V17.54H9.8601ZM4.88009 15.75C5.55009 16.04 6.36009 16.51 6.76009 16.88L7.20009 16.25C6.79009 15.9 5.95009 15.45 5.29009 15.2L4.88009 15.75ZM4.30009 18.48C4.98009 18.73 5.83009 19.15 6.25009 19.48L6.65009 18.84C6.22009 18.52 5.36009 18.13 4.69009 17.91L4.30009 18.48ZM4.67009 23.68L5.30009 24.19C5.89009 23.26 6.59009 22.01 7.12009 20.95L6.58009 20.46C6.00009 21.6 5.21009 22.92 4.67009 23.68Z"
        fill="#5D6771"
      />
      <path
        d="M14.9 8.76H18.43V13.02H17.7V12.57H15.61V13.11H14.9V8.76ZM15.61 9.48V11.85H17.7V9.48H15.61ZM21.79 7.71H16.35V4.4H21.79V7.71ZM21.04 5.11H17.07V7H21.04V5.11ZM23.24 8.76V13.04H22.51V12.57H20.24V13.11H19.52V8.76H23.24ZM22.51 11.85V9.48H20.24V11.85H22.51Z"
        fill="#5D6771"
      />
      <path
        d="M9.49102 7.71C9.49102 8.7 10.1124 11.32 13.59 12.41C13.4197 12.58 13.1892 12.92 13.0689 13.11C10.2727 12.13 9.27055 9.98 9.07011 9.03C8.86967 9.98 7.87748 12.06 5.06128 13.11C4.97108 12.95 4.7105 12.62 4.55014 12.47C7.98772 11.34 8.64917 8.75 8.64917 7.71V6.44H6.77505C6.33407 7.4 5.80292 8.24 5.22164 8.89C5.07131 8.76 4.71051 8.52 4.50005 8.41C5.56239 7.37 6.36413 5.68 6.82514 3.9L7.63693 4.08C7.4866 4.64 7.2962 5.18 7.09576 5.7H12.7382L12.8685 5.67L13.4598 5.87C13.109 6.99 12.6079 8.21 12.0968 9.01L11.4253 8.71C11.7761 8.14 12.1469 7.26 12.4175 6.44H9.49102V7.71Z"
        fill="#5D6771"
      />
      <path d="M21 0H24C26.2091 0 28 1.79086 28 4V7L24.5 3.5L21 0Z" fill="#25938B" />
    </g>
  ),
});
