import {
  HStack,
  IconButton,
  Input,
  InputProps,
  useNumberInput,
  UseNumberInputProps,
} from '@chakra-ui/react';
import { PlusIcon, MinusIcon } from '../icons';

type Props = {
  options?: UseNumberInputProps;
} & Pick<InputProps, 'size' | 'w'>;

export function NumberInput({ options, size = 'md', w = '7em' }: Props) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    max: +10000,
    precision: 0,
    isReadOnly: false,
    ...options,
  });
  return (
    <HStack>
      <IconButton
        size="xs"
        aria-label="希望数量を減らす"
        borderRadius="md"
        icon={<MinusIcon />}
        {...getDecrementButtonProps()}
      />
      <Input w={w} textAlign="right" px={1} size={size} borderRadius="base" {...getInputProps()} />
      <IconButton
        size="xs"
        aria-label="希望数量を増やす"
        borderRadius="md"
        icon={<PlusIcon />}
        {...getIncrementButtonProps()}
      />
    </HStack>
  );
}
