import { GridItem, Input, Skeleton, Text } from '@chakra-ui/react';
import { ErrorTooltip, RequiredTag } from '@kkhs/hakari-ui';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = {
  name: FieldPath<T>;
  isLoading?: boolean;
} & Pick<UseFormReturn<T>, 'register' | 'formState'>;

export function SlipTitleForm<T extends FieldValues>({
  name,
  isLoading = false,
  register,
  formState: { errors },
}: Props<T>) {
  return (
    <>
      <GridItem>
        <Text fontWeight="bold">
          伝票名称
          <RequiredTag ml={2} />
        </Text>
      </GridItem>
      <GridItem w="160px">
        <Skeleton isLoaded={!isLoading} borderRadius="md">
          <ErrorTooltip
            label={errors.slipTitle?.message}
            isOpen={!!errors.slipTitle?.message}
            placement="bottom-start"
          >
            <Input {...register(name)} placeholder="納品書" />
          </ErrorTooltip>
        </Skeleton>
      </GridItem>
    </>
  );
}
