import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';

const errorMessages = {
  username: {
    required: 'ユーザーIDを入力してください。',
    invalid: 'ユーザーIDを正しく入力してください。',
  },
  password: {
    required: 'パスワードを入力してください。',
  },
};

const emailSchema = z.string().min(1);
const v2UsernameSchema = z.string().regex(/^[0-9a-zA-Z.\-_]+#[0-9a-zA-Z.\-_]+$/u);

const passwordSchema = z.string().trim().min(1, { message: errorMessages.password.required });
const usernameSchema = z
  .string()
  .trim()
  .min(1, { message: errorMessages.username.required })
  .refine((val) => {
    if (val.includes('@')) {
      return emailSchema.safeParse(val).success;
    }
    return v2UsernameSchema.safeParse(val).success;
  }, errorMessages.username.invalid);

export const schema = z.object({
  username: usernameSchema,
  password: passwordSchema,
});

export type FieldValues = z.infer<typeof schema>;

export const defaultValues: DefaultValues<FieldValues> = { username: '', password: '' };
