type Props = {
  /** 貯蔵方法:室温 フラグ */
  roomTemperatureFlag: boolean;
  /** 貯蔵方法:冷所 フラグ */
  coldPlaceFlag: boolean;
  /** 貯蔵方法:冷蔵 フラグ */
  refrigerationFlag: boolean;
  /** 貯蔵方法:冷凍 フラグ */
  freezingFlag: boolean;
  /** 保管場所:暗所 フラグ */
  darkPlaceFlag: boolean;
  /** 保管場所:遮光 フラグ */
  shadeFlag: boolean;
  /** 保管容器:気密容器 フラグ */
  airtightContainerFlag: boolean;
  /** 保管容器:密封容器 フラグ */
  sealedContainerFlag: boolean;
  /** 危険物フラグ */
  dangerousFlag: boolean;
  /** 温度上限 */
  upperTemperatureLimit?: number | null;
  /** 温度下限 */
  lowerTemperatureLimit?: number | null;
};

export function getMedicineStorageMethodLabels({
  roomTemperatureFlag,
  coldPlaceFlag,
  refrigerationFlag,
  freezingFlag,
  darkPlaceFlag,
  shadeFlag,
  airtightContainerFlag,
  sealedContainerFlag,
  dangerousFlag,
  upperTemperatureLimit,
  lowerTemperatureLimit,
}: Props): string[] {
  const labels: string[] = [];
  if (roomTemperatureFlag) labels.push('室温');
  if (coldPlaceFlag) labels.push('冷所');
  if (refrigerationFlag) labels.push('冷蔵');
  if (freezingFlag) labels.push('冷凍');
  if (darkPlaceFlag) labels.push('暗所');
  if (shadeFlag) labels.push('遮光');
  if (airtightContainerFlag) labels.push('気密容器');
  if (sealedContainerFlag) labels.push('密封容器');
  if (dangerousFlag) labels.push('危険物');
  if (typeof upperTemperatureLimit === 'number' && typeof lowerTemperatureLimit === 'number') {
    labels.push(`温度上下限 ${lowerTemperatureLimit}℃～${upperTemperatureLimit}℃`);
  } else if (typeof lowerTemperatureLimit === 'number') {
    labels.push(`温度下限 ${lowerTemperatureLimit}℃～`);
  } else if (typeof upperTemperatureLimit === 'number') {
    labels.push(`温度上限 ～${upperTemperatureLimit}℃`);
  }
  return labels;
}
