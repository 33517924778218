import { Button, HStack, VStack } from '@chakra-ui/react';
import { AlertIconFilled, Typography } from '@kkhs/hakari-ui';
import { ReactNode } from 'react';

type ButtonProps = {
  variant: 'small' | 'normal' | 'showErrorLabel';
  onClick: () => void;
};

/**
 * @ref https://www.figma.com/design/6SU6Q3yZ9kYrNM7hhi8XZn/%E6%8E%A1%E7%94%A8%E8%96%AC%E7%99%BB%E9%8C%B2%E5%B0%8E%E7%B7%9A%E3%81%AE%E5%B0%8E%E7%B7%9A%E6%95%B4%E7%90%86?node-id=71-22427&t=FeanmjZi2YNcABfe-0
 */
const ButtonComponent: Record<
  ButtonProps['variant'],
  (props: { onClick: () => void }) => ReactNode
> = {
  small: (props) => (
    <Button size="sm" variant="outline" h="24px" w="38px" {...props}>
      登録
    </Button>
  ),
  normal: (props) => (
    <Button size="sm" variant="outline" h="24px" w="64px" {...props}>
      登録する
    </Button>
  ),
  showErrorLabel: (props) => (
    <VStack alignItems="center" spacing={2}>
      <HStack spacing={1}>
        <AlertIconFilled color="red.500" w="18px" h="18px" />
        <Typography variant="caption" color="red.500">
          採用薬未登録
        </Typography>
      </HStack>
      <Button size="sm" variant="outline" w="60px" h="24px" {...props}>
        登録する
      </Button>
    </VStack>
  ),
};

export function AddAdoptedMedicineButton({ variant, ...rest }: ButtonProps) {
  return <>{ButtonComponent[variant](rest)}</>;
}
