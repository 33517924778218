import { OrderPointSettingInput } from '@/gql/docs';

/**
 * OrderPointSettingInput を受け取り、OrderPointSettingInput.mode に応じて、API のバリデーションに則った値を返却する関数です
 * auto → (null, null)
 * fixed → (>=0, null)
 * factor → (null, >=0)
 * @param OrderPointSettingInput
 * @returns OrderPointSettingInput
 */
export function getOrderPointSettingInput({
  mode,
  fixedValue,
  factor,
}: OrderPointSettingInput): OrderPointSettingInput {
  switch (mode) {
    case 'fixed':
      return {
        mode,
        fixedValue,
        factor: null,
      };
    case 'factor':
      return {
        mode,
        fixedValue: null,
        factor,
      };
    case 'auto':
    default:
      return {
        mode,
        fixedValue: null,
        factor: null,
      };
  }
}
