import { createIcon } from '@chakra-ui/react';

export const TubeUnitSymbolIcon = createIcon({
  displayName: 'Tube',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.05006 9.5499H11.8001V7.0699H4.23006V12.9999H12.5801V10.3499H5.05006V9.5499ZM11.0001 7.7999V8.7999H5.05006V7.7999H11.0001ZM5.05006 12.2699V11.0699H11.7701V12.2699H5.05006ZM3.12006 5.4899L2.56006 4.8899L2.65006 4.8299C3.42936 4.22364 4.03618 3.42373 4.41006 2.5099V2.3999L5.26006 2.5499L5.15006 2.7799C5.06342 2.98134 4.96663 3.17826 4.86006 3.3699H7.86006V4.0999H6.00006C6.14802 4.47587 6.27488 4.8598 6.38006 5.2499V5.3599L5.57006 5.4799V5.3899C5.46528 4.94922 5.32482 4.5178 5.15006 4.0999H4.45006C4.11156 4.61356 3.68898 5.06656 3.20006 5.4399L3.12006 5.4899ZM9.46006 3.3699H13.4601V4.0999H10.8301C11.0258 4.47209 11.1897 4.86013 11.3201 5.2599V5.3699L10.5101 5.4799V5.3999C10.3801 5.0499 10.3401 4.9299 9.96006 4.0999H9.00006C8.76871 4.47125 8.4859 4.80793 8.16006 5.0999H8.42006V5.7699H13.1601V7.9999H12.3101V6.4699H3.77006V7.9999H3.00006V5.7299H7.61006V5.0599H7.85006L7.36006 4.6899L7.45006 4.6099C8.12439 4.04645 8.63832 3.31522 8.94006 2.4899V2.4099L9.77006 2.5799L9.72006 2.6899C9.64576 2.92111 9.55897 3.14811 9.46006 3.3699V3.3699Z"
        fill="currentColor"
      />
    </svg>
  ),
});
