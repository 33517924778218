import { chakra } from '@chakra-ui/react';
import { DynamicSizedNumberText } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { ConvertedStockUnitQuantityWithSymbol, StockUnitSymbolIcon } from '@/entities/medicine';
import { CellBase } from './CellBase';
import { Row } from '../../types';

const mapping = {
  9: '12px',
  8: '16px',
};

export function SurplusStockUnitQuantityCell(props: Row) {
  return (
    <CellBase isNumeric data-testid="余剰在庫ブロック">
      {renderContent(props)}
    </CellBase>
  );
}

function renderContent({
  medicine: { stockUnitSymbol },
  surplusStockUnitQuantity,
  convertedOthersMedicineStockUnitQuantities,
}: Row) {
  if (isNullOrUndefined(surplusStockUnitQuantity)) {
    return '数量不明';
  }
  if (surplusStockUnitQuantity <= 0) {
    return '余剰在庫なし';
  }
  return (
    <>
      <chakra.span>
        <DynamicSizedNumberText
          mapping={mapping}
          fontWeight="bold"
          as="span"
          verticalAlign="middle"
        >
          {surplusStockUnitQuantity}
        </DynamicSizedNumberText>
        <StockUnitSymbolIcon name={stockUnitSymbol} boxSize={4} />
      </chakra.span>
      {!isNullOrUndefined(convertedOthersMedicineStockUnitQuantities) &&
        !isNullOrUndefined(convertedOthersMedicineStockUnitQuantities.surplusStockUnitQuantity) && (
          <ConvertedStockUnitQuantityWithSymbol
            fontSize="12px"
            paddingBottom={1}
            convertedStockUnitQuantity={
              convertedOthersMedicineStockUnitQuantities.surplusStockUnitQuantity
            }
            convertedStockUnitSymbol={convertedOthersMedicineStockUnitQuantities.stockUnitSymbol}
          />
        )}
    </>
  );
}
