import {
  AsyncCreatableSelect,
  AsyncCreatableSelectProps,
  SelectInstanceType,
} from '@kkhs/hakari-ui';
import { forwardRef } from 'react';
import {
  useInternalCounterpartySelectOptionsLazyQuery,
  InternalCounterpartySelectOptionFragment,
} from '@/gql/apollo';
import { assertInternalCounterpartySelectOptionFragment } from './assertInternalCounterpartySelectOptionFragment';

// MEMO: defaultValue に Creatable で作成した値を設定したい場合に id: null を指定する
/** Creatable で作成された値 */
type CreatedInternalCounterpartySelectOption = { id: null; name: string };

export type InternalCounterpartySelectOption =
  | InternalCounterpartySelectOptionFragment
  | CreatedInternalCounterpartySelectOption;

type Props = {
  ref?: React.Ref<HTMLSelectElement>;
} & Omit<AsyncCreatableSelectProps<InternalCounterpartySelectOption>, 'loadOptions'>;

/**
 * 取引先を一件選択することができるコンポーネント
 */
export const InternalCounterpartySelect = forwardRef<
  SelectInstanceType<InternalCounterpartySelectOption>,
  Omit<Props, 'ref'>
>(({ ...rest }: Omit<Props, 'ref'>, ref) => {
  const [fetch] = useInternalCounterpartySelectOptionsLazyQuery();

  return (
    <AsyncCreatableSelect<InternalCounterpartySelectOption>
      {...rest}
      ref={ref}
      noOptionsMessage="取引先名称を入力してください"
      defaultOptions
      formatCreateLabel={(inputValue: string) => `"${inputValue}"を入力する`}
      loadOptions={async (inputValue: string) => {
        const result = await fetch({
          variables: { name: inputValue },
        });
        return (
          result.data?.counterparties.edges.map(({ node: fragment }) => {
            assertInternalCounterpartySelectOptionFragment(fragment);
            return {
              label: fragment.name,
              value: fragment,
            };
          }) ?? []
        );
      }}
    />
  );
});
InternalCounterpartySelect.displayName = 'InternalCounterpartySelect';
