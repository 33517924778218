import { useMutation } from '@apollo/client';
import { showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { graphql } from '@/gql';
import { MedicineTransactionListItemsDocument } from '@/gql/docs';
import { ShippingStockOperationDeleteButtonGroupLayout } from './ShippingStockOperationDeleteButtonGroupLayout';
import { FieldValues } from '../schema';

const mutationDoc = graphql(`
  mutation DeleteShippingStockOperation($input: DeleteStockOperationInput!) {
    deleteStockOperation(input: $input) {
      userErrors {
        ... on ValidationError {
          message
        }
        ... on StockOperationNotExistError {
          message
        }
        ... on ClosedStockOperationError {
          message
          path
          latestStockCloseDate
        }
      }
    }
  }
`);

type Props = {
  onClose: () => void;
  cancelRef: React.RefObject<HTMLButtonElement>;
};

export function LoadedShippingStockOperationDeleteButtonGroup({ onClose, cancelRef }: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext<FieldValues>();
  const [deleteShippingStockOperation, { loading, called, reset }] = useMutation(mutationDoc, {
    onError: () => {
      showErrorToast({
        id: 'delete-stock-operation-error',
        title: 'エラーが発生しました',
      });
      reset();
    },
    onCompleted: async (d) => {
      if (d.deleteStockOperation.userErrors.length > 0) {
        if (d.deleteStockOperation.userErrors[0]?.__typename === 'ClosedStockOperationError') {
          showErrorToast({
            id: 'delete-closed-stock-operation-error',
            title: '棚卸日以前のため削除できません',
          });
          // Datadogにログを送信
          userMonitoringRepository.sendEvent({
            key: '棚卸日以前の日付で在庫調整の出庫区分を削除',
            contexts: { pharmacyId, musubiCode },
          });

          reset();
          return;
        }
        showErrorToast({
          id: 'delete-stock-operation-error',
          title: 'エラーが発生しました',
        });
        reset();
      } else {
        showSuccessToast({
          id: 'delete-stock-operation-success',
          title: '入出庫履歴を削除しました',
        });
        onClose();
      }
    },
  });

  const submit: SubmitHandler<FieldValues> = async ({ id }) => {
    await deleteShippingStockOperation({
      variables: {
        input: { id },
      },
      refetchQueries: [MedicineTransactionListItemsDocument],
    });
  };
  return (
    <ShippingStockOperationDeleteButtonGroupLayout
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      cancelRef={cancelRef}
      isDisabledCancelButton={loading}
      isDisabledSubmitButton={loading || !isValid}
      isLoadingSubmitButton={loading || called}
    />
  );
}
