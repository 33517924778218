import { createIcon } from '@chakra-ui/react';

export const ChevronUpDownIcon = createIcon({
  displayName: 'ChevronUpDownIcon',
  viewBox: '0 0 16 16',
  path: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12L5 9L4 10L8 14L12 10L11 9L8 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4L11 7L12 6L8 2L4 6L5 7L8 4Z"
        fill="currentColor"
      />
    </g>
  ),
});
