import { Box, Table, Thead, Tr, Th, HStack } from '@chakra-ui/react';
import { SearchInput, useZodForm } from '@kkhs/hakari-ui';
import { useDebouncedCallback } from 'use-debounce';
import { z } from 'zod';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { medicineSearchKeywordSchema } from '@/entities/medicine';
import { DemandTakeoverToMedicineFragment } from '@/gql/apollo';
import { DemandTakeoverToMedicinesTableBody } from './DemandTakeoverToMedicinesTableBody';
import { useDemandTakeoverToMedicines } from './hooks';

const schema = z
  .object({
    medicineSearchKeyword: medicineSearchKeywordSchema,
  })
  .strict();

type Props = {
  onSelect: (fragment: DemandTakeoverToMedicineFragment) => void;
  medicineId: string;
};

export function DemandTakeoverToMedicinesTable({ onSelect, medicineId }: Props) {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const { register, watch } = useZodForm({
    schema,
    defaultValues: {
      medicineSearchKeyword: '',
    },
  });

  const medicineSearchKeyword = watch('medicineSearchKeyword');

  const { demandTakeoverToMedicines, isLoading } = useDemandTakeoverToMedicines({
    medicineId,
    medicineSearchKeyword,
  });

  const { onChange, ...rest } = register('medicineSearchKeyword');

  const handleChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // 医薬品名が入力されている場合、datadogにログを送信する
    if (e.target.value) {
      userMonitoringRepository.sendEvent({
        key: '共通_医薬品名_SEARCHED',
        contexts: {
          pharmacyId,
          musubiCode,
          画面名: '共通_医薬品詳細モーダル_発注おすすめの対象を切り替えるダイアログ',
          検索キーワード: e.target.value,
        },
      });
    }
    onChange(e);
  }, 500);

  return (
    <>
      <HStack
        bg="gray.50"
        px={6}
        py={3}
        borderColor="gray.200"
        borderTopWidth={1}
        borderBottomWidth={1}
      >
        <Box w="320px">
          <SearchInput
            bg="white"
            placeholder="医薬品名、メーカー"
            size="sm"
            onChange={handleChange}
            {...rest}
          />
        </Box>
      </HStack>
      <Box overflow="auto">
        <Table size="sm">
          <Thead position="sticky" top="0" bg="white" zIndex={1}>
            <Tr>
              <Th pl={6}>医薬品名</Th>
              <Th>需要タイプ</Th>
              <Th w="160px">製造販売元メーカー</Th>
              <Th w="72px">先発後発</Th>
              <Th w="88px" isNumeric>
                単位薬価
              </Th>
              <Th w="168px" pl={6} pr={6} />
            </Tr>
          </Thead>
          <DemandTakeoverToMedicinesTableBody
            fragments={demandTakeoverToMedicines}
            isLoading={isLoading}
            onSelect={onSelect}
          />
        </Table>
      </Box>
    </>
  );
}
