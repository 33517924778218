import { createIcon } from '@chakra-ui/react';

export const TimesUnitSymbolIcon = createIcon({
  displayName: 'Times',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00002 10.7H6.00002V5.3H3.00002V10.7ZM5.30002 6.1V9.9H3.70002V6.1H5.30002ZM7.60002 3H1.40002V13H7.60002V3ZM2.10002 12.2V3.8H6.90002V12.2H2.10002Z"
        fill="currentColor"
      />
      <path
        d="M15.32 6.93998V6.99998L15 7.68998L14.9 7.58998C13.7091 6.21084 12.823 4.59554 12.3 2.84998V2.75998L12.93 2.47998V2.58998C13.3751 4.20712 14.1938 5.69713 15.32 6.93998V6.93998Z"
        fill="currentColor"
      />
      <path
        d="M9.33998 6.64996H14.07V6.74996C14.1346 8.61973 13.9938 10.4909 13.65 12.33C13.634 12.4617 13.5919 12.589 13.5262 12.7044C13.4605 12.8197 13.3725 12.9208 13.2673 13.0018C13.1621 13.0828 13.0419 13.142 12.9136 13.176C12.7853 13.21 12.6515 13.2182 12.52 13.2H11.52L11.41 12.28H12.62C12.87 12.28 13.05 12.28 13.15 11.8C13.3816 10.3484 13.4886 8.87974 13.47 7.40996H11.57C11.4 10.1 10.85 11.9 8.63998 13.11L8.53998 13.17L8.25998 12.41H8.33998C10.34 11.34 10.8 9.77996 10.9 7.40996H9.19998V6.82996C9.00998 7.05996 8.80998 7.27996 8.58998 7.49996L8.48997 7.58996L8.09998 6.85996L8.15998 6.80996C9.33826 5.65918 10.1461 4.18278 10.48 2.56996V2.45996L11.1 2.71996V2.79996C10.7835 4.19038 10.1844 5.50086 9.33998 6.64996Z"
        fill="currentColor"
      />
    </svg>
  ),
});
