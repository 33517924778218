import { HStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { OrganizationGroupSelect } from './OrganizationGroupSelect';
import { FieldValues } from './schema';

type Props = {
  defaultOrganizationGroupId: string | null;
};

export function SearchSection({ defaultOrganizationGroupId }: Props) {
  const { control, setValue } = useFormContext<FieldValues>();

  const onClear = useCallback(() => {
    setValue('organizationGroupId', null);
  }, [setValue]);

  return (
    <HStack justifyContent="space-between" bgColor="gray.100" py={4} px={6}>
      <OrganizationGroupSelect<FieldValues>
        name="organizationGroupId"
        control={control}
        defaultOrganizationGroupId={defaultOrganizationGroupId}
        onClear={onClear}
      />
    </HStack>
  );
}
