import { BaseMutationOptions, useMutation } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useZodForm, showErrorToast, showSuccessToast } from '@kkhs/hakari-ui';
import { isNullOrUndefined } from '@kkhs/hakari-utils';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { checkDuplicatedStorageNames } from '@/entities/storage';
import { graphql } from '@/gql';
import { useCreateCostPriceMasterMutation } from '@/gql/apollo';
import { Actions } from '@/repository/userMonitoring/interface';
import { dateToAWSDate } from '@/shared/utils';
import { FieldValues, schema, toDefaultValues } from '../schema';

type Props = {
  onClose: () => void;
  /** 採用薬登録成功後の callback */
  onSuccess?: () => void;
  /** 採用薬登録失敗後の callback */
  onFailure?: () => void;
  target: { id: string; medicineName: string; defaultMedicinePackageUnitId?: string };
  refetchQueries: BaseMutationOptions['refetchQueries'];
  userMonitoringKey?:
    | Actions['医薬品詳細モーダルの他店舗の在庫タブから医薬品を登録']['key']
    | Actions['在庫を計上する画面から医薬品を登録']['key']
    | Actions['必須買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['安心買い発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['特定の医薬品発注の不動在庫日数ポップオーバーから医薬品を登録']['key']
    | Actions['アプリ発注の不動在庫日数ポップオーバーから医薬品を登録']['key'];
};

const createAdoptedMedicineMutationDoc = graphql(`
  mutation CreateAdoptedMedicine($input: CreateAdoptedMedicineInput!) {
    createAdoptedMedicine(input: $input) {
      userErrors {
        ... on ValidationError {
          message
          path
        }
        ... on AdoptedMedicineAlreadyExistError {
          message
          path
        }
        ... on ClosedStockOperationError {
          message
          path
        }
      }
    }
  }
`);

export const useForm = ({
  onClose,
  onSuccess,
  onFailure,
  target,
  refetchQueries,
  userMonitoringKey,
}: Props) => {
  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  const useFormReturn = useZodForm({
    schema,
    defaultValues: toDefaultValues({
      defaultMedicinePackageUnitId: target.defaultMedicinePackageUnitId,
    }),
  });

  const {
    setError,
    handleSubmit,
    formState: { isValid },
  } = useFormReturn;

  const [createAdoptedMedicineMutation, { loading: isAdopting }] = useMutation(
    createAdoptedMedicineMutationDoc,
    {
      notifyOnNetworkStatusChange: true,
      onError: () => {
        showErrorToast({
          id: 'create-adopted-medicine-error',
          title: 'エラーが発生しました',
        });
      },
      refetchQueries,
    },
  );

  const createAdoptedMedicine = useCallback(
    async ({ storages, wholesaleId, medicinePackageUnitId, unitQuantity }: FieldValues) => {
      const filteredStorages = storages.filter(
        (s): s is { id: string; name: string } => !!s.id && !!s.name,
      );
      // 置き場所を重複して設定していた場合は setError する
      if (filteredStorages !== null) {
        const duplicatedIndexes = checkDuplicatedStorageNames(filteredStorages);
        if (duplicatedIndexes.length > 0) {
          duplicatedIndexes.forEach((i) => {
            setError(`storages.${Number(i)}.id`, {
              type: 'custom',
              message: '置き場所が重複しています。',
            });
          });
          return Promise.reject();
        }
      }

      const { data } = await createAdoptedMedicineMutation({
        variables: {
          input: {
            medicineId: target.id,
            wholesaleId,
            medicinePackageUnitId,
            avoidImpulseBuy: false,
            storageIds: filteredStorages?.map((s) => s.id),
            unitQuantity,
          },
        },
      });

      const errors = data?.createAdoptedMedicine.userErrors;
      if (!isNullOrUndefined(errors) && errors.length > 0) {
        return Promise.reject(new Error(JSON.stringify(errors)));
      }

      if (!isNullOrUndefined(userMonitoringKey)) {
        userMonitoringRepository.sendEvent({
          key: userMonitoringKey,
          contexts: { pharmacyId, musubiCode },
        });
      }
      return Promise.resolve();
    },
    [
      createAdoptedMedicineMutation,
      target.id,
      userMonitoringRepository,
      userMonitoringKey,
      pharmacyId,
      musubiCode,
      setError,
    ],
  );

  const [createCostPriceMaster, { loading: isCreatingCostPriceMaster }] =
    useCreateCostPriceMasterMutation({
      onCompleted: (data) => {
        if (data.createCostPriceMaster.errors?.length !== 0) {
          showErrorToast({
            id: 'create-cost-price-master-error',
            title: '原価設定でエラーが発生しました',
          });
        }
      },
    });

  const onValid: SubmitHandler<FieldValues> = useCallback(
    async ({ storages, wholesaleId, medicinePackageUnitId, unitQuantity, costPrice }) => {
      try {
        await createAdoptedMedicine({ storages, wholesaleId, medicinePackageUnitId, unitQuantity });

        if (costPrice !== undefined) {
          await createCostPriceMaster({
            variables: {
              input: {
                costPrice,
                medicineId: target.id,
                fromDate: dateToAWSDate(new Date()),
              },
            },
          });
        }
        showSuccessToast({
          id: 'create-adopted-medicine-success',
          title: '採用薬に追加しました',
        });
        onSuccess?.();
        onClose();
      } catch (e) {
        if (e instanceof Error) {
          const userErrors: ResultOf<
            typeof createAdoptedMedicineMutationDoc
          >['createAdoptedMedicine']['userErrors'] = JSON.parse(e.message);
          if (userErrors[0]?.__typename === 'ClosedStockOperationError') {
            showErrorToast({
              id: 'create-adopted-medicine-closed-stock-operation-error',
              title: '棚卸日当日は、初期在庫数量が0以外の登録はできません',
            });
            userMonitoringRepository.sendEvent({
              key: '棚卸日当日に在庫1以上で採用薬を新規登録',
              contexts: { pharmacyId, musubiCode },
            });
          } else {
            showErrorToast({
              id: 'create-adopted-medicine-error',
              title: 'エラーが発生しました',
            });
          }
          onFailure?.();
        }
      }
    },
    [
      createAdoptedMedicine,
      onSuccess,
      onClose,
      createCostPriceMaster,
      target.id,
      onFailure,
      userMonitoringRepository,
      pharmacyId,
      musubiCode,
    ],
  );
  return {
    submit: handleSubmit(onValid),
    isSubmitting: isAdopting || isCreatingCostPriceMaster,
    isValid,
    useFormReturn,
  };
};
