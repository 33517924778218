import { createIcon } from '@chakra-ui/react';

export const SetUnitSymbolIcon = createIcon({
  displayName: 'Set',
  viewBox: '0 0 16 16',
  path: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6 8.64L15.3 9.55C14.2141 8.59427 13.0425 7.74066 11.8 7V13H11V3H11.8V6.09C13.137 6.83014 14.4083 7.68325 15.6 8.64V8.64ZM2.79995 3H1.99995V5.59L0.699951 5.76V6.66L1.99995 6.47V10.73C1.99995 12.38 2.59995 13 3.68995 13H6.19995V12.2H3.64995C3.10995 12.2 2.79995 12.04 2.79995 10.73V6.35L5.50995 6C5.50095 6.59154 5.36085 7.17372 5.09974 7.70459C4.83863 8.23545 4.46301 8.70179 3.99995 9.07L4.33995 9.93C5.60995 8.93 6.20995 7.55 6.38995 5.12V5L2.79995 5.48V3ZM8.99995 9.3C8.92995 8.47 8.74995 6.97 8.59995 6.22L7.95995 6.43C8.13988 7.43841 8.25677 8.45705 8.30995 9.48L8.99995 9.3ZM7.12995 12.11L7.43995 13C9.67995 12 10.3 11.07 10.3 6.67L9.51995 6.41C9.51995 11.05 8.99995 11.34 7.12995 12.11ZM7.35995 6.65L6.69995 6.88C6.93271 7.83772 7.07335 8.8155 7.11995 9.8L7.79995 9.62C7.73515 8.61959 7.58799 7.62623 7.35995 6.65V6.65Z"
        fill="currentColor"
      />
    </svg>
  ),
});
