import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { AlertDialogContext } from './AlertDialogContext';
import { DiscardConfirmationDialog } from './DiscardConfirmationDialog';
import { useBeforeUnload } from '../../hooks';

export function AlertDialog({
  children,
  onClose,
  ...rest
}: AlertDialogProps): ReturnType<typeof ChakraAlertDialog> {
  const [shouldAlert, setShouldAlert] = useState(false);
  const discardConfirmationDialog = useDisclosure({ id: 'discard-confirmation' });

  useBeforeUnload({ shouldAlert });

  const handleClose = useCallback(() => {
    if (shouldAlert) {
      discardConfirmationDialog.onOpen();
    } else {
      onClose();
    }
  }, [discardConfirmationDialog, onClose, shouldAlert]);

  const alertDialogContext = useMemo(
    () => ({ shouldAlert, setShouldAlert, handleClose }),
    [shouldAlert, handleClose],
  );

  const handleDiscard = useCallback(() => {
    setShouldAlert(false);
    discardConfirmationDialog.onClose();
    onClose();
  }, [discardConfirmationDialog, onClose]);

  return (
    <AlertDialogContext.Provider value={alertDialogContext}>
      <ChakraAlertDialog onClose={handleClose} {...rest}>
        {children}
        <DiscardConfirmationDialog
          isOpen={discardConfirmationDialog.isOpen}
          onClose={discardConfirmationDialog.onClose}
          onDiscard={handleDiscard}
        />
      </ChakraAlertDialog>
    </AlertDialogContext.Provider>
  );
}
