import { forwardRef, Input, InputProps } from '@chakra-ui/react';

export const TextInput = forwardRef(({ ...props }: InputProps, ref) => (
  <Input
    bg="white"
    borderWidth="1px"
    borderColor="gray.300"
    borderRadius="base"
    px="2.5"
    py="1"
    _invalid={{ bg: 'red.50', borderColor: 'red.500' }}
    ref={ref}
    {...props}
  />
));
